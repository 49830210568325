import { UsersFilterState } from '../components/types'

const USERS_PAGE_LABELS = {
  users: 'Users',
  customerPortalLoginError:
    'Please Login to customer portal into the same browser on separate tab and try again.',
}

const USERS_ACTIONS_LABELS = {
  selectAction: 'Select Action',
  goToCustomer: 'Go to Customer Portal',
  resetPassword: 'Reset Password',
  deleteUser: 'Delete User',
  addUser: 'Add User',
}

const RESET_PASSWORD_LABELS = {
  resetPassword: 'Reset Password',
  resetPasswordText: (id: number) =>
    `Would you like to reset user password with id ${id}?`,
  cancel: 'Cancel',
  success: 'Password reset successfully.',
}

const DELETE_USER_LABELS = {
  deleteUser: 'Delete User',
  deleteUserText: (name: string) =>
    `Are you sure that you want to delete user "${name}"? `,
  confirm: 'Delete',
  cancel: 'Cancel',
  success: 'User deleted successfully',
}

const ROLES = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Account owner',
    value: 'Account owner',
  },
  {
    label: 'Admin user',
    value: 'Admin user',
  },
  {
    label: 'Operator user',
    value: 'Operator user',
  },
]

const filterInitialState: UsersFilterState = {
  email: '',
  roleName: '',
  organizationName: '',
  pageNum: 0,
  pageSize: 10,
  sort: 'organizationName',
  sortDirection: 'asc',
}

const FILTER_LABELS = {
  role: 'Role',
  organizationName: 'Organization Name',
  applyFilter: 'Apply Filter',
  resetFilter: 'Reset Filter',
  usersFilter: 'Users Filter',
  email: 'Email',
}

export {
  USERS_PAGE_LABELS,
  USERS_ACTIONS_LABELS,
  RESET_PASSWORD_LABELS,
  DELETE_USER_LABELS,
  ROLES,
  FILTER_LABELS,
  filterInitialState,
}
