import { useEffect, useState } from 'react'
import {
  getConformationText,
  getSelectedDeviceFirmware,
} from '../../services/firmwareUpdate-service'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import map from 'lodash/map'
import { connect } from 'react-redux'
import ConfirmModal from '../../../../components/_shared/confirmModal/ConfirmModal'
import { checkConnection } from '../../../../services/root/root-service'
import actions from '../../services/firmwareUpdate-actions'
import { FIRMWARE_UPDATES_LABELS } from '../../services/firmwareUpdate-constants'
import styles from './../../style'

const CancelFirmwareButton = withStyles(styles)((props) => {
  const {
    classes,
    cancelUpdateFirmware,
    selected,
    error,
    setSelected,
    resetError,
    devices,
  } = props
  const [open, setOpen] = useState(false)
  const [deviceFirmwares, setDeviceFirmwares] = useState({})
  const [conformationText, setConformationText] = useState('')
  const isButtonDisabled = selected.length === 0

  useEffect(() => {
    const firmwares = getSelectedDeviceFirmware(devices, selected)

    setDeviceFirmwares(firmwares)
    setConformationText(getConformationText(selected.length > 1))
    // eslint-disable-next-line
  }, [selected])

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    resetError()
    setOpen(false)
  }

  const onSubmit = () => {
    cancelUpdateFirmware(map(deviceFirmwares, 'deviceFirmwareId'), onClose)

    setSelected([])
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={onOpen}
        disabled={isButtonDisabled}
        className={classes.cancelButton}
        color="primary"
        id="cancel-update-button"
      >
        {FIRMWARE_UPDATES_LABELS.cancelUpdate}
      </Button>
      <ConfirmModal
        isOpen={open}
        error={error}
        onClose={onClose}
        onSubmit={onSubmit}
        confirmText={conformationText}
        modalTitle={FIRMWARE_UPDATES_LABELS.cancelUpdate}
        actionLabel={FIRMWARE_UPDATES_LABELS.confirm}
        closeLabel={FIRMWARE_UPDATES_LABELS.close}
      />
    </>
  )
})

const mapDispatchToProps = (dispatch) => {
  return {
    cancelUpdateFirmware: (deviceFirmwareId, onSuccess) =>
      dispatch(
        checkConnection(() =>
          actions.cancelUpdateFirmware(deviceFirmwareId, onSuccess)
        )
      ),
    resetError: () => dispatch(actions.resetError()),
  }
}

export default connect(null, mapDispatchToProps)(CancelFirmwareButton)
