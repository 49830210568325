import { DEVICE_MODE_OPTIONS, LABELS } from '../services/uvgiEmulator-constants'

import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CustomSelect from '../../../../components/_shared/customSelect/CustomSelect'
import styles from '../../style'
import { validateNumericFields } from '../../_shared/services/emulator-service'

const MainEventOperationsComponent = (props) => {
  const {
    classes,
    isSubmitted,
    changeValue,
    mode,
    messageFrequency,
    outputPower,
    timeout,
  } = props
  const isTimeoutNotValid = isSubmitted && !validateNumericFields(timeout)
  const isOutputPowerNotValid =
    isSubmitted && !validateNumericFields(outputPower)
  const isMessageFrequencyNotValid =
    isSubmitted && !validateNumericFields(messageFrequency)

  const _messageModeTemplate = (item) => item.label

  const onChangeValue = (event) => {
    changeValue(event, event.target.name)
  }

  return (
    <div className={classes.firmWareUpdateContainer}>
      <TextField
        id="outlined-basic"
        required
        error={isTimeoutNotValid}
        value={timeout}
        name="timeout"
        type="number"
        onChange={onChangeValue}
        label={LABELS.timeout}
        variant="outlined"
      />

      <TextField
        id="outlined-basic"
        required
        value={outputPower}
        error={isOutputPowerNotValid}
        name="outputPower"
        type="number"
        onChange={onChangeValue}
        label={LABELS.outputPower}
        variant="outlined"
      />

      <CustomSelect
        options={DEVICE_MODE_OPTIONS}
        isRequired={true}
        name="mode"
        label={LABELS.mode}
        onChange={onChangeValue}
        error={!mode && isSubmitted}
        value={mode}
        valueName="value"
        template={_messageModeTemplate}
      />
      <TextField
        id="outlined-basic"
        required
        value={messageFrequency}
        name="messageFrequency"
        type="number"
        onChange={onChangeValue}
        error={isMessageFrequencyNotValid}
        inputProps={{ maxLength: 24 }}
        label={LABELS.messageFrequency}
        variant="outlined"
      />
    </div>
  )
}

export default withStyles(styles)(MainEventOperationsComponent)
