import { NetEmulatorContainer } from 'containers/EventsEmulator/NET_DEVICES/NetEmulatorContainer'
import { DEVICE_TYPE_MAPPER } from '../../../../services/constants'
import ARCEmulatorContainer from '../../ARC/ARCEmulatorContainer'
import ExtEmulatorContainer from '../../EXT_DEVICES/ExtEmulatorContainer'
import { EVENTS_CODES } from '../../UVGI/services/uvgiEmulator-constants'
import UVGIEmulatorContainer from '../../UVGI/UVGIEmulatorContainer'

const TYPES = {
  powerOn: 'power on',
  powerOff: 'power off',
  countdownStart: 'countdown start',
  countdownInterrupted: 'countdown interrupted',
  countdownResume: 'countdown resume',
  countdownCancel: 'countdown cancel',
  countdownFinish: 'countdown finish',
  cycleStart: 'cycle start',
  cycleInterrupted: 'cycle interrupted',
  cycleResume: 'cycle resume',
  cycleCancel: 'cycle cancel',
  cycleFinish: 'cycle finish',
  init: 'Initialization of new device message',
  firmwareStart: 'firmware update start',
  firmwareSuccess: 'firmware update success',
  firmwareFail: 'firmware update fail',
  firmwareStatistics: 'firmware statistics',
  requestParameters: 'request parameters',
}

const FORM_LABELS = {
  DEVICE_NAME: 'Device Name',
  LOCATION: 'Location',
  MESSAGE_TYPE: 'Message Type',
  DURATION: 'Duration',
  DEVICE_LOCATION_ONLY: 'Device Location Only',
  TRANSACTION_ID: 'Transaction Id (CCCCC:00000:00000)',
  TRANSACTION_ID_UVGI:
    'Transaction Id (CCCCC:00000:00000 or CCCCC:00000:000000000)',
  SERIAL_NUMBER: 'Serial Number was changed to A9',
  TIME_LABEL: 'Time:',
  USE_CURRENT_TIME: 'Use current time',
  SUBMIT: 'Submit',
}

const EXTENDED_MODE = {
  specialMode1: 'Special mode 1',
  specialMode2: 'Special mode 2',
  checkboxLabel: 'Extended mode',
}

const EMULATOR_TITLE = 'Events Emulator'

const INDEXES = {
  arc: 'arc',
  uvgi: 'uvgi',
}

const FEATURE_KEYS = {
  arc: 'ARC_DEVICES',
  beam: 'UVGI_DEVICES',
  vive: 'FAR_UVC',
  ext: 'EXT_DEVICE',
  net: 'NETWORK_DEVICES',
}

const devicesTypesConfig = [
  {
    id: FEATURE_KEYS.arc,
    component: () => <ARCEmulatorContainer />,
    index: DEVICE_TYPE_MAPPER.UVI_ARC,
  },
  {
    id: FEATURE_KEYS.beam,
    component: () => <UVGIEmulatorContainer />,
    index: DEVICE_TYPE_MAPPER.UR_UVGI,
  },
  {
    id: FEATURE_KEYS.vive,
    component: () => <UVGIEmulatorContainer />,
    index: DEVICE_TYPE_MAPPER.FAR_UVC,
  },
  {
    id: FEATURE_KEYS.ext,
    component: () => <ExtEmulatorContainer />,
    index: DEVICE_TYPE_MAPPER.EXT_DEVICE,
  },
  {
    id: FEATURE_KEYS.net,
    component: () => <NetEmulatorContainer />,
    index: DEVICE_TYPE_MAPPER.NET_DEVICE,
  },
]

const TYPES_OPTIONS = [
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Boolean',
    value: 'boolean',
  },
]

const BOOL_OPTIONS = [
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
]

const LIMIT_ERROR = 'Row limit is 10 items'

const META_INFO_LABELS_EVENTS = {
  tableColumnsLabels: ['Key', 'Type', 'Value', ''],
  tableTitle: 'Meta Info',
}

const META_INFO_LABELS = {
  key: 'Key',
  type: 'Type',
  value: 'Value',
}

const UPDATE_FAIL_CAUSE_OPTIONS = [
  {
    label: 'DOWNLOAD_FAIL',
    value: 'DOWNLOAD_FAIL',
  },
  {
    label: 'INVALID_BUNDLE',
    value: 'INVALID_BUNDLE',
  },
  {
    label: 'INSTALL_FAILURE',
    value: 'INSTALL_FAILURE',
  },
]

const REQUIRED_META_INFO_ROWS = [
  {
    key: 'updateFailCause',
    options: UPDATE_FAIL_CAUSE_OPTIONS,
    messageType: EVENTS_CODES.evt417,
  },
]

const deeps = ['area', 'property', 'building', 'zone', 'room', 'placement']

const ORGANIZATION_PLACEHOLDER = 'Organization'

const COMMENT_FIELD_LABELS = {
  label: 'Comment',
  name: 'comment',
}

export {
  FORM_LABELS,
  TYPES,
  EXTENDED_MODE,
  EMULATOR_TITLE,
  INDEXES,
  devicesTypesConfig,
  TYPES_OPTIONS,
  BOOL_OPTIONS,
  LIMIT_ERROR,
  META_INFO_LABELS_EVENTS,
  META_INFO_LABELS,
  UPDATE_FAIL_CAUSE_OPTIONS,
  REQUIRED_META_INFO_ROWS,
  deeps,
  FEATURE_KEYS,
  ORGANIZATION_PLACEHOLDER,
  COMMENT_FIELD_LABELS,
}
