import { Button, TextField, Typography } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import EditableUVGIFields from '../../../../_shared/EditableUVGIFields'
import { EDIT_DEVICE } from '../../services/deviceDetails-constants'
import DeviceDetailsData from '../DeviceDetailsData'
import style from './style'

const EditUVGIDevice = (props) => {
  const { state, errors, onChange, classes, onSubmit } = props

  return (
    <>
      <DeviceDetailsData
        imei={state.imei}
        serialNumber={state.serialNumber}
        deviceType={state.deviceType}
        deviceId={state.id}
        removed={state.removed}
        isEdit
      >
        <div className={classes.formWrapper}>
          <Typography variant="h4">{EDIT_DEVICE.pageTitle}</Typography>
          <TextField
            name="loraId"
            label={EDIT_DEVICE.loraId}
            value={state.loraId || ''}
            onChange={onChange}
            variant="outlined"
            disabled={state.removed}
          />
          <TextField
            name="bluetoothId"
            label={EDIT_DEVICE.bluetoothId}
            value={state.bluetoothId || ''}
            error={errors.bluetoothId}
            onChange={onChange}
            variant="outlined"
            disabled={state.removed}
          />
          <EditableUVGIFields
            state={state}
            errors={errors}
            changeTextField={onChange}
          />
          {!state.removed && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submitButton}
              onClick={onSubmit}
            >
              {EDIT_DEVICE.save}
            </Button>
          )}
        </div>
      </DeviceDetailsData>
    </>
  )
}

export default withStyles(style)(EditUVGIDevice)
