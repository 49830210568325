import TextField from '@material-ui/core/TextField'
import { LABELS } from '../services/extEmulator-constants'

const MessageMeta = (props) => {
  const { changeValue, isSubmitted, messageMeta } = props

  const isMetaInfoNotValid = isSubmitted && !messageMeta

  const onChangeValue = (event) => {
    changeValue(event, event.target.name)
  }

  return (
    <TextField
      id="outlined-basic"
      value={messageMeta}
      name="metaInfo"
      onChange={onChangeValue}
      error={isMetaInfoNotValid}
      required
      multiline
      label={LABELS.messageMeta}
      variant="outlined"
    />
  )
}

export default MessageMeta
