export let HOST = process.env.REACT_APP_API_URL || ''
export let GDPA_API_URL = process.env.REACT_APP_GDPA_API_URL || ''
export const COOKIE_DOMAIN =
  process.env.REACT_APP_AUTH_COOKIE_DOMAIN || 'localhost'

// @todo: remove this when we remove this package from java.
// This is a temporary solution since right now we can't add env variables in build time.
if (!HOST && window.location?.origin) {
  HOST = `${window.location.origin}`
}

if (!GDPA_API_URL && window.location?.origin) {
  switch (window.location.origin) {
    case 'https://new-staging-factory.rzero.com':
      GDPA_API_URL = 'https://gdp.rzerostg.com'
      break
    case 'https://factory.rzero.com':
      GDPA_API_URL = 'https://gdp.rzero.com'
      break
    default:
      GDPA_API_URL = 'http://localhost:3002'
  }
}

// will be required soon
// export let GDPA_GRAPHQL_URL = `${GDPA_API_URL}/graphql`

const STATUS_FILTER = {
  completed: 'Completed',
  cancelled: 'Cancelled',
  paused: 'Interrupted',
  in_progress: 'In Progress',
}

const DATE_FILTER = {
  today: 'Today',
  week: 'Past 7 days',
  month: 'Past Month',
}

const MONTH = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const BULK_UPLOAD_LABELS = {
  bulkUpload: 'Bulk Upload',
  downloadExample: 'Download Template',
  upload: 'Upload',
  bulkUploadDevices: 'Bulk Upload Devices',
  bulkUploadLocations: 'Bulk Upload Locations',
  fileName: 'File Name',
  seeFailedDevices: 'See Failed Devices',
}

const getBulkLoadMessage = (failed, total) =>
  `BULK UPLOAD: total - ${total}, failed - ${failed}`

const EXPORT_LOC_TREE_LABELS = {
  exportLocationTree: 'Export to Google Sheets',
}

export {
  STATUS_FILTER,
  DATE_FILTER,
  MONTH,
  BULK_UPLOAD_LABELS,
  EXPORT_LOC_TREE_LABELS,
  getBulkLoadMessage,
}
