const eventsEmulatorActions = {}

eventsEmulatorActions.actionTypes = {
  SET_TAB: '{EVENTS EMULATOR} Set Tab',

  GET_ORGANIZATIONS: '{EVENTS EMULATOR} Get Organizations',
  GET_ORGANIZATIONS_SUCCEEDED: '{EVENTS EMULATOR} Get Organizations Succeeded',
  GET_ORGANIZATIONS_FAILED: '{EVENTS EMULATOR} Get Organizations Failed',

  SET_ORGANIZATION: '{EVENTS EMULATOR} Set Organization',
}

eventsEmulatorActions.setTab = (tab) => ({
  type: eventsEmulatorActions.actionTypes.SET_TAB,
  payload: {
    tab,
  },
})

eventsEmulatorActions.getOrganizations = () => ({
  type: eventsEmulatorActions.actionTypes.GET_ORGANIZATIONS,
})

eventsEmulatorActions.getOrganizationsSucceeded = (organizationsList) => ({
  type: eventsEmulatorActions.actionTypes.GET_ORGANIZATIONS_SUCCEEDED,
  payload: {
    organizationsList,
  },
})

eventsEmulatorActions.getOrganizationsFailed = (message) => ({
  type: eventsEmulatorActions.actionTypes.GET_ORGANIZATIONS_FAILED,
  payload: {
    message,
  },
})

eventsEmulatorActions.setOrganization = (selectedOrganization) => ({
  type: eventsEmulatorActions.actionTypes.SET_ORGANIZATION,
  payload: {
    selectedOrganization,
  },
})

export default eventsEmulatorActions
