import {
  ADD_LOCATIONS_BULK_SUCCEEDED,
  ADD_LOCATION_SUCCEEDED,
  DELETE_LOCATION_WITH_SUBLOCATIONS_SUCCEEDED,
  UPDATE_LOCATION_SUCCEEDED,
} from '../../../../services/organizationDetails-actions'
import {
  EXPAND_NODE,
  LOAD_NODES_SUCCEEEDED,
  SHRINK_NODE,
} from './customTree-actions'
import {
  changeExpanded,
  prepareProperties,
  shrinkNode,
  updateTree,
  updateTreeBulk,
} from './customTree-service'

import { Location } from 'containers/Organizations/components/Details/services/organizationDetails-types'
import { createReducer } from 'redux-act'

export const customTreeReducer = createReducer<{ nodes: Location[] }>(
  {
    [LOAD_NODES_SUCCEEEDED.getType()]: (state, payload) => {
      const { list } = payload

      return {
        ...state,
        nodes: prepareProperties({ nodes: payload.list }),
      }
    },
    [EXPAND_NODE.getType()]: (state, payload) => {
      const { parentId, data } = payload

      const nodes = changeExpanded(parentId, data, state.nodes)

      return {
        ...state,
        nodes,
      }
    },
    [SHRINK_NODE.getType()]: (state, payload) => {
      const { id } = payload
      const nodes = shrinkNode(id, state.nodes)

      return {
        ...state,
        nodes,
      }
    },
    [ADD_LOCATION_SUCCEEDED.getType()]: (state, payload) => {
      const { location } = payload
      const nodes = updateTree(state.nodes, location, 'add')

      return {
        ...state,
        nodes,
      }
    },
    [ADD_LOCATIONS_BULK_SUCCEEDED.getType()]: (state, payload) => {
      const { response } = payload
      const nodes = updateTreeBulk(state.nodes, response)

      return {
        ...state,
        nodes,
      }
    },
    [DELETE_LOCATION_WITH_SUBLOCATIONS_SUCCEEDED.getType()]: (
      state,
      payload
    ) => {
      const { id, parentId } = payload
      const nodes = updateTree(
        state.nodes,
        { id, parentId } as Location,
        'delete'
      )

      return {
        ...state,
        nodes,
      }
    },
    [UPDATE_LOCATION_SUCCEEDED.getType()]: (state, payload) => {
      const { location } = payload
      const nodes = updateTree(state.nodes, location, 'edit')

      return {
        ...state,
        nodes,
      }
    },
  },
  {
    nodes: [],
  }
)
