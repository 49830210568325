import { Button, withStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { checkConnection } from '../../../../services/root/root-service'
import actions from '../../services/organization-actions'
import { IDP_MODAL_LABELS } from '../../services/organization-constants'
import IdpModal from './IdpModal'
import style from './style'

const IdpButton = (props) => {
  const {
    organization,
    getIdpData,
    idpData,
    saveIdpData,
    deleteIdpData,
    resetError,
    error,
    classes,
    isFeaturesReadOnly,
  } = props
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      getIdpData(organization.id)
    }
    // eslint-disable-next-line
  }, [open])

  const openModal = (event) => {
    event.stopPropagation()

    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)

    resetError()
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.featuresButton}
        onClick={openModal}
      >
        {IDP_MODAL_LABELS.idpButton}
      </Button>
      <IdpModal
        isOpen={open}
        isReadOnly={false}
        onClose={closeModal}
        idpData={idpData}
        id={organization.id}
        saveIdpData={saveIdpData}
        error={error}
        isLoading={isFeaturesReadOnly}
        deleteIdpData={deleteIdpData}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  idpData: state.organizationReducer.idpData,
  error: state.organizationReducer.error,
  isFeaturesReadOnly: state.organizationReducer.isFeaturesReadOnly,
})

const mapDispatchToProps = (dispatch) => ({
  getIdpData: (id) => dispatch(checkConnection(() => actions.getIdpData(id))),
  saveIdpData: (id, body, onSuccess) =>
    dispatch(checkConnection(() => actions.saveIdpData(id, body, onSuccess))),
  deleteIdpData: (id) =>
    dispatch(checkConnection(() => actions.deleteIdpData(id))),
  resetError: () => dispatch(actions.resetError()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(IdpButton))
