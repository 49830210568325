import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { DEVICE_STATISTIC_LABELS } from '../services/uvgiEmulator-constants'
import styles from './motionPIR/style'

const CertificatesInfoBlock = (props) => {
  const { changeValue, blockData, classes, isSubmitted } = props
  const { certificateId, publicKeyHash, privateKeyHash, certificateHash } =
    blockData

  const onChangeValue = (event) => {
    changeValue(
      {
        ...blockData,
        [event.target.name]: event.target.value,
      },
      'certificatesInfo'
    )
  }

  return (
    <>
      <Typography className={classes.title} variant="h5" component="div">
        {DEVICE_STATISTIC_LABELS.certificatesInfo}
      </Typography>
      <TextField
        id="outlined-basic"
        value={certificateId}
        name="certificateId"
        onChange={onChangeValue}
        required
        error={isSubmitted && !certificateId}
        label={DEVICE_STATISTIC_LABELS.certificateId}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        value={publicKeyHash}
        name="publicKeyHash"
        onChange={onChangeValue}
        required
        error={isSubmitted && !publicKeyHash}
        label={DEVICE_STATISTIC_LABELS.publicKeyHash}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        value={privateKeyHash}
        name="privateKeyHash"
        onChange={onChangeValue}
        required
        error={isSubmitted && !privateKeyHash}
        label={DEVICE_STATISTIC_LABELS.privateKeyHash}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        value={certificateHash}
        name="certificateHash"
        onChange={onChangeValue}
        required
        error={isSubmitted && !certificateHash}
        label={DEVICE_STATISTIC_LABELS.certificateHash}
        variant="outlined"
      />
    </>
  )
}

export default withStyles(styles)(CertificatesInfoBlock)
