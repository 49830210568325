import React, { MouseEventHandler } from 'react'

import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { DEVICE_TYPE } from 'services/constants'
import { useAppSelector } from 'utils/reduxHelpers'
import ConfirmModal from '../../../../../components/_shared/confirmModal/ConfirmModal'
import { checkConnection } from '../../../../../services/root/root-service'
import devicesListActions from '../services/devicesList-actions'
import { DEVICE_DELETE_MODAL } from '../services/devicesList-constants'
import { getDeleteConfirmText } from '../services/devicesList-service'
import { useStyles } from './style'

type DeleteDeviceActionProps = {
  id: string
  serialNumber: string
}

const DeleteDeviceAction = ({ id, serialNumber }: DeleteDeviceActionProps) => {
  const style = useStyles()
  const dispatch = useDispatch()

  const error = useAppSelector(
    (state) => state.devicesReducer.devicesListReducer.error
  )
  const deviceType = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.deviceType
  )

  const canDelete = useAppSelector(
    (state) => state.devicesReducer.devicesListReducer.abilities.delete
  )

  const removed = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.removed
  )

  const deleteDevice = (id: string, deviceType: string) =>
    dispatch(
      checkConnection(() =>
        devicesListActions.deleteDevice(
          id,
          DEVICE_TYPE[deviceType as keyof typeof DEVICE_TYPE].apiPath,
          handleClose
        )
      )
    )
  const resetError = () => dispatch(devicesListActions.resetError())
  const confirmText = getDeleteConfirmText(serialNumber)
  const [open, setOpen] = React.useState(false)

  const handleOpen: MouseEventHandler = (event) => {
    event.stopPropagation()

    resetError()
    setOpen(true)
  }

  const handleClose: MouseEventHandler = (event) => {
    event?.stopPropagation()

    setOpen(false)
  }

  const onClick: MouseEventHandler = (event) => {
    event.stopPropagation()

    deleteDevice(id, deviceType)
  }

  if (!canDelete || removed) {
    return null
  }

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleOpen}
        className={style.deviceActionButton}
        id="remove-device-btn"
      >
        Remove Device
      </Button>
      <ConfirmModal
        isOpen={open}
        onClose={handleClose}
        onSubmit={onClick}
        error={error}
        confirmText={confirmText}
        modalTitle={DEVICE_DELETE_MODAL.modalTitle}
        actionLabel={DEVICE_DELETE_MODAL.actionLabel}
        closeLabel={DEVICE_DELETE_MODAL.closeLabel}
      />
    </div>
  )
}

export default DeleteDeviceAction
