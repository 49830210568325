const firmwareUpdateActions = {}

firmwareUpdateActions.actionTypes = {
  LOAD_DEVICES: '{Firmware Update} Load Devices',
  LOAD_DEVICES_SUCCEEDED: '{Firmware Update} Load Devices Succeeded',
  LOAD_DEVICES_FAILED: '{Firmware Update} Load Devices Failed',

  LOAD_FIRMWARE_VERSIONS: '{Firmware Update} Load Firmware Versions',
  LOAD_FIRMWARE_VERSIONS_SUCCEEDED:
    '{Firmware Update} Load Firmware Versions Succeeded',
  LOAD_FIRMWARE_VERSIONS_FAILED:
    '{Firmware Update} Load Firmware Versions Failed',

  LOAD_FIRMWARE_TYPES: '{Firmware Update} Load Firmware Types',
  LOAD_FIRMWARE_TYPES_SUCCEEDED:
    '{Firmware Update} Load Firmware Types Succeeded',
  LOAD_FIRMWARE_TYPES_FAILED: '{Firmware Update} Load Firmware Types Failed',

  LOAD_DEVICES_TYPES: '{Firmware Update} Load Devices Types',
  LOAD_DEVICES_TYPES_SUCCEEDED:
    '{Firmware Update} Load Devices Types Succeeded',
  LOAD_DEVICES_TYPES_FAILED: '{Firmware Update} Load Devices Types Failed',

  SCHEDULE_UPDATE_FIRMWARE_VERSIONS:
    '{Firmware Update} Schedule Update Firmware Versions',
  SCHEDULE_UPDATE_FIRMWARE_VERSIONS_SUCCEEDED:
    '{Firmware Update} Schedule Update Firmware Versions Succeeded',
  SCHEDULE_UPDATE_FIRMWARE_VERSIONS_FAILED:
    '{Firmware Update} Schedule Update Firmware Versions Failed',

  CANCEL_UPDATE_FIRMWARE: '{Firmware Update} Cancel Update Firmware',
  CANCEL_UPDATE_FIRMWARE_SUCCEEDED:
    '{Firmware Update} Cancel Update Firmware Succeeded',
  CANCEL_UPDATE_FIRMWARE_FAILED:
    '{Firmware Update} Cancel Update Firmware Failed',

  LOAD_ORGANIZATIONS: '{Firmware Update} Load Organizations',
  LOAD_ORGANIZATIONS_SUCCEEDED:
    '{Firmware Update} Load Organizations Succeeded',
  LOAD_ORGANIZATIONS_FAILED: '{Firmware Update} Load Organizations Failed',

  APPLY_DEVICE_FILTER: '{Devices} Apply Device Filter',

  SET_PAGE: '{Firmware Update} Set Page',
  SET_ROWS_PER_PAGE: '{Firmware Update} Set Rows Per Page For Devices',
  GO_TO_NEXT_PAGE: '{Firmware Update} Go To Next Page',
  GO_TO_PREVIOUS_PAGE: '{Firmware Update} Go To Previous Page',

  RESET_TABLE_STATE_WITHOUT_RELOADING:
    '{Firmware Update} Reset table state without reloading data',
  RESET_ERROR: '{Firmware Update} Reset error',
}

firmwareUpdateActions.resetTableState = function () {
  return {
    type: firmwareUpdateActions.actionTypes.RESET_TABLE_STATE_WITHOUT_RELOADING,
  }
}

firmwareUpdateActions.resetError = function () {
  return {
    type: firmwareUpdateActions.actionTypes.RESET_ERROR,
  }
}

firmwareUpdateActions.applyDeviceFilter = function () {
  return {
    type: firmwareUpdateActions.actionTypes.APPLY_DEVICE_FILTER,
  }
}

firmwareUpdateActions.goToNextPage = function (page) {
  return {
    type: firmwareUpdateActions.actionTypes.GO_TO_NEXT_PAGE,
    payload: {
      page,
    },
  }
}

firmwareUpdateActions.goToPreviousPage = function (page) {
  return {
    type: firmwareUpdateActions.actionTypes.GO_TO_PREVIOUS_PAGE,
    payload: {
      page,
    },
  }
}

firmwareUpdateActions.setRowsPerPage = (rowsPerPage) => {
  return {
    type: firmwareUpdateActions.actionTypes.SET_ROWS_PER_PAGE,
    payload: {
      rowsPerPage,
    },
  }
}

firmwareUpdateActions.setPage = (page) => {
  return {
    type: firmwareUpdateActions.actionTypes.SET_PAGE,
    payload: {
      page,
    },
  }
}

firmwareUpdateActions.loadDevices = (query) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_DEVICES,
    payload: {
      query,
    },
  }
}

firmwareUpdateActions.loadDevicesSuccess = (devices) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_DEVICES_SUCCEEDED,
    payload: {
      devices,
    },
  }
}

firmwareUpdateActions.loadDevicesFailed = (message) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

firmwareUpdateActions.loadFirmwareVersions = (firmwareType) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_VERSIONS,
    payload: {
      firmwareType,
    },
  }
}

firmwareUpdateActions.loadFirmwareVersionsSuccess = (versions) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_VERSIONS_SUCCEEDED,
    payload: {
      versions,
    },
  }
}

firmwareUpdateActions.loadFirmwareVersionsFailed = (message) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_VERSIONS_FAILED,
    payload: {
      message,
    },
  }
}

firmwareUpdateActions.scheduleUpdateFirmwareVersions = (
  versionId,
  devicesIds,
  onSuccess
) => {
  return {
    type: firmwareUpdateActions.actionTypes.SCHEDULE_UPDATE_FIRMWARE_VERSIONS,
    payload: {
      versionId,
      devicesIds,
      onSuccess,
    },
  }
}

firmwareUpdateActions.scheduleUpdateFirmwareVersionsSuccess = (versions) => {
  return {
    type: firmwareUpdateActions.actionTypes
      .SCHEDULE_UPDATE_FIRMWARE_VERSIONS_SUCCEEDED,
    payload: {
      versions,
    },
  }
}

firmwareUpdateActions.scheduleUpdateFirmwareVersionsFailed = (message) => {
  return {
    type: firmwareUpdateActions.actionTypes
      .SCHEDULE_UPDATE_FIRMWARE_VERSIONS_FAILED,
    payload: {
      message,
    },
  }
}

firmwareUpdateActions.cancelUpdateFirmware = (deviceFirmwareIds, onSuccess) => {
  return {
    type: firmwareUpdateActions.actionTypes.CANCEL_UPDATE_FIRMWARE,
    payload: {
      deviceFirmwareIds,
      onSuccess,
    },
  }
}

firmwareUpdateActions.cancelUpdateFirmwareSuccess = (versions) => {
  return {
    type: firmwareUpdateActions.actionTypes.CANCEL_UPDATE_FIRMWARE_SUCCEEDED,
    payload: {
      versions,
    },
  }
}

firmwareUpdateActions.cancelUpdateFirmwareFailed = (message) => {
  return {
    type: firmwareUpdateActions.actionTypes.CANCEL_UPDATE_FIRMWARE_FAILED,
    payload: {
      message,
    },
  }
}

firmwareUpdateActions.loadFirmwareTypes = () => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_TYPES,
  }
}

firmwareUpdateActions.loadFirmwareTypesSuccess = (types) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_TYPES_SUCCEEDED,
    payload: {
      types,
    },
  }
}

firmwareUpdateActions.loadFirmwareTypesFailed = (message) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_TYPES_FAILED,
    payload: {
      message,
    },
  }
}

firmwareUpdateActions.loadDevicesTypes = () => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_DEVICES_TYPES,
  }
}

firmwareUpdateActions.loadDevicesTypesSuccess = (types) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_DEVICES_TYPES_SUCCEEDED,
    payload: {
      types,
    },
  }
}

firmwareUpdateActions.loadDevicesTypesFailed = (message) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_DEVICES_TYPES_FAILED,
    payload: {
      message,
    },
  }
}

firmwareUpdateActions.loadOrganizations = () => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_ORGANIZATIONS,
  }
}

firmwareUpdateActions.loadOrganizationsSuccess = (organizations) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_ORGANIZATIONS_SUCCEEDED,
    payload: {
      organizations,
    },
  }
}

firmwareUpdateActions.loadOrganizationsFailed = (message) => {
  return {
    type: firmwareUpdateActions.actionTypes.LOAD_ORGANIZATIONS_FAILED,
    payload: {
      message,
    },
  }
}

export default firmwareUpdateActions
