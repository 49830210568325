import { UNKNOWN_ID, UNKNOWN_LOCATION } from '../../../../services/constants'
import {
  isAllValuesFilledAndPositive,
  isTransactionIdValid,
  pushParams,
  validateNumericFields,
} from '../../_shared/services/emulator-service'
import { EVENT_TYPE_VALUES, TYPES } from './arcEmulator-constants'

import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import repeat from 'lodash/repeat'

const commonRequiredFields = [
  'deviceName',
  'locationId',
  'messageTypeText',
  'deviceDateTime',
]

const startRequiredFields = [...commonRequiredFields, 'duration']

const fieldsFirmwareRequired = [
  ...commonRequiredFields,

  'firmwareUpdateStatus',
  'firmwareUpdateTime',
  'firmwareUpdateType',
  'firmwareUpdateVersion',
]

const fieldsStatisticsRequired = [
  ...commonRequiredFields,
  'bulbChangeDate',
  'deviceRevision',
  'softwareRevision',
]

const fieldsStatisticsForRequest = [
  ...commonRequiredFields,
  'bulbChangeDate',
  'deviceRevision',
  'softwareRevision',
  'bulbCyclesSinceChange',
  'powerCyclesSinceChange',
  'abuseEvents',
]

const fieldsStatisticsNumeric = [
  'bulbCyclesSinceChange',
  'powerCyclesSinceChange',
  'abuseEvents',
]

const requestParametersRequired = [
  'deviceName',
  'messageTypeText',
  'deviceDateTime',
  'imei',
]

const requestParametersForRequest = [
  'deviceName',
  'messageTypeText',
  'deviceDateTime',
  'imei',
  'softwareRevision',
]

const isAllFieldsFilled = (fields, values) => {
  let isTransactionIDValid = true

  if (fields.indexOf('transactionId') !== -1) {
    isTransactionIDValid = isTransactionIdValid(values.transactionId)
  }

  const isNumericFieldsValid = validateNumericFields(
    values.specialMode1,
    values.specialMode2
  )

  return (
    isTransactionIDValid &&
    isNumericFieldsValid &&
    fields.every((item) => {
      return values[item]
    })
  )
}

const validateStatisticsFields = (fields, values, handler) => {
  const isNumbersValuesValid = isAllValuesFilledAndPositive(
    fieldsStatisticsNumeric,
    values
  )

  return isNumbersValuesValid && handler(fields, values)
}

const dataProcessing = (values, message, defaultValue, handler) => {
  let processedData = [...defaultValue]

  switch (message) {
    case TYPES.firmwareStart:
    case TYPES.firmwareSuccess:
    case TYPES.firmwareFail:
      processedData = handler(fieldsFirmwareRequired, values, defaultValue)
      break
    case TYPES.statistics:
      processedData = handler(fieldsStatisticsForRequest, values, defaultValue)
      break
    case TYPES.cycleStart:
    case TYPES.countdownStart:
      processedData = handler(startRequiredFields, values, defaultValue)
      break
    case TYPES.requestParameters:
      processedData = handler(requestParametersForRequest, values, defaultValue)
      break
    default:
      processedData = handler(commonRequiredFields, values, defaultValue)
      break
  }

  return processedData
}

const validateFields = (values, message, handler) => {
  switch (message) {
    case TYPES.firmwareStart:
    case TYPES.firmwareSuccess:
    case TYPES.firmwareFail:
      return handler(fieldsFirmwareRequired, values)
    case TYPES.statistics:
      return validateStatisticsFields(fieldsStatisticsRequired, values, handler)
    case TYPES.cycleStart:
    case TYPES.countdownStart:
      return handler(startRequiredFields, values)
    case TYPES.requestParameters:
      return handler(requestParametersRequired, values)
    default:
      return handler(commonRequiredFields, values)
  }
}

const prepareRequestParams = (values, message) => {
  const params = []

  if (values.transactionId) {
    params.push(`transactionId=${values.transactionId}`)
  }

  if (values.imei !== values.imeiCopy) {
    values.deviceName = EVENT_TYPE_VALUES.A9
  }

  params.push(`specialMode1=${values.specialMode1}`)

  params.push(`specialMode2=${values.specialMode2}`)

  return dataProcessing(values, message, params, pushParams)
}

const validateValues = (values, message) => {
  return validateFields(values, message, isAllFieldsFilled)
}

const getPlacementsFromTree = (tree) => {
  const children = concat([], tree.children)
  const placements = []

  var traverse = function (current, deep) {
    map(current, (item) => {
      item.name = repeat('- ', deep) + item.name
      item.subheader = !item.selected

      placements.push(item)

      if (item.children && !!item.children.length) {
        let nextDeep = deep + 1

        traverse(item.children, nextDeep)
      }
    })
  }

  traverse(children, 0)

  placements.push({ id: UNKNOWN_ID, name: UNKNOWN_LOCATION, children: [] })

  return placements
}

const getDeviceImei = (devices, serialNumber) => {
  const device = find(devices, ['serialNumber', serialNumber])
  const { imei } = device
  const parts = [imei.substr(0, 5), imei.substr(5, 5), imei.substr(10, 5)]

  const filteredArray = filter(parts, (item) => {
    return item !== ''
  })

  return filteredArray.join(':')
}

const getDeviceBySerialNumber = (devices, serialNumber) => {
  return find(devices, ['serialNumber', serialNumber])
}

const getCommDevicesInfo = (rows) =>
  map(rows, (item) => {
    delete item.isCurrentTime

    return item
  })

export {
  validateValues,
  prepareRequestParams,
  getPlacementsFromTree,
  getDeviceImei,
  getCommDevicesInfo,
  getDeviceBySerialNumber,
}
