import organizationActions, {
  SAVE_ORGANIZATION_DATA_ERROR,
  SAVE_ORGANIZATION_DATA_INIT,
  SAVE_ORGANIZATION_DATA_SUCCESS,
  TIMEZONES_BY_ZIP_ERROR,
  TIMEZONES_BY_ZIP_INIT,
  TIMEZONES_BY_ZIP_SUCCESS,
} from './organization-actions'
import {
  moveSelectedOrganization,
  prepareOrganizations,
} from './organization-service'
import { Feature, Timezone } from './organization-types'

import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import { createReducer } from 'redux-act'
import { Organization } from 'types/organizationTypes'
import { UserProfile } from 'types/userTypes'
import { PAGES } from '../../../services/constants'
import rootActions from '../../../services/root/root-actions'
import { initialValueIdpData } from './organization-constants'

const initialState = {
  organizations: [] as Organization[],
  selectedAccountOwner: {} as Partial<UserProfile>,
  admins: [] as UserProfile[],
  isLoading: false,
  error: '',
  isCurrentOwnerLoaded: false,
  loadOrganizationError: '',
  isReadOnly: false,
  isFeaturesAvailable: false,
  isFeaturesReadOnly: false,
  isUpdatingFeature: false,
  addOrganizationError: '',
  addOrganizationSending: false,
  timezones: [] as Timezone[],
  isLoadingTimezones: false,
  features: [] as Feature[],
  selected: -1,
}

export const organizationReducer = createReducer(
  {
    [rootActions.actionTypes.SETUP_PERMISSIONS]: (state, payload) => {
      const { permissions } = payload

      return {
        ...state,
        isReadOnly: !permissions[PAGES.organizations].write,
        isFeaturesAvailable: permissions[PAGES.featureToggling].read,
        isFeaturesReadOnly: !permissions[PAGES.featureToggling].write,
      }
    },
    [organizationActions.actionTypes.LOAD_ORGANIZATIONS]: (state, payload) => {
      return {
        ...state,
        loadOrganizationError: '',
        isLoading: true,
      }
    },
    [organizationActions.actionTypes.GET_FEATURES]: (state, payload) => {
      return {
        ...state,
        loadOrganizationError: '',
      }
    },
    [organizationActions.actionTypes.OFF_FEATURE_FOR_ORGANIZATION]: (
      state
    ) => ({
      ...state,
      isUpdatingFeature: true,
    }),
    [organizationActions.actionTypes.ON_FEATURE_FOR_ORGANIZATION]: (state) => ({
      ...state,
      isUpdatingFeature: true,
    }),
    [organizationActions.actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS]: (
      state
    ) => ({
      ...state,
      isLoading: true,
    }),
    [organizationActions.actionTypes.GET_ACCOUNT_OWNER]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [organizationActions.actionTypes.LOAD_ORGANIZATIONS_FAILED]: (
      state,
      payload
    ) => ({
      ...state,
      loadOrganizationError: payload.message,
      isLoading: false,
    }),
    [organizationActions.actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [organizationActions.actionTypes.GET_ACCOUNT_OWNER_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [organizationActions.actionTypes.CHANGE_FEATURE_FOR_ORGANIZATION_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [organizationActions.actionTypes.GET_FEATURES_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [organizationActions.actionTypes.GET_IDP_DATA_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [organizationActions.actionTypes.SAVE_IDP_DATA_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [organizationActions.actionTypes.DELETE_IDP_DATA_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [organizationActions.actionTypes.CHANGE_FEATURE_FOR_ORGANIZATION_SUCCEEDED]:
      (state, payload) => {
        const { id, switchState } = payload

        const changedIndex = findIndex(state.features, ['id', id])
        const features = cloneDeep(state.features)
        features[changedIndex].enabled = switchState

        return {
          ...state,
          features,
          error: payload.message,
          isLoading: false,
          isUpdatingFeature: false,
        }
      },
    [organizationActions.actionTypes.LOAD_ORGANIZATIONS_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        organizations: prepareOrganizations(payload.organizations),
        selectedAccountOwner: {},
        isLoading: false,
        loadOrganizationError: '',
      }
    },
    [organizationActions.actionTypes.GET_ACCOUNT_OWNER_SUCCEEDED]: (
      state,
      payload
    ) => ({
      ...state,
      selectedAccountOwner: payload.account[0],
      isLoading: false,
    }),
    [organizationActions.actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS_SUCCEEDED]: (
      state,
      payload
    ) => ({
      ...state,
      admins: payload.admins,
      isLoading: false,
    }),
    [organizationActions.actionTypes.MOVE_SELECTED]: (state, payload) => ({
      ...state,
      organizations: concat(
        [],
        moveSelectedOrganization(state.organizations, payload.type)
      ),
      selectedAccountOwner: {},
      isLoading: false,
    }),
    [organizationActions.actionTypes.CHANGE_SELECTED_BY_ID]: (
      state,
      payload
    ) => ({
      ...state,
      selected: payload.id,
      selectedAccountOwner: {},
      isLoading: false,
    }),
    [organizationActions.actionTypes.GET_FEATURES_SUCCEEDED]: (
      state,
      payload
    ) => ({
      ...state,
      features: payload.data,
      isLoading: false,
    }),
    [organizationActions.actionTypes.GET_IDP_DATA_SUCCEEDED]: (
      state,
      { id, data }
    ) => {
      const idpData = isEmpty(data)
        ? { ...initialValueIdpData, organizationId: id }
        : data

      return {
        ...state,
        idpData,
        isLoading: false,
      }
    },
    [organizationActions.actionTypes.RESET_ERROR]: (state, payload) => ({
      ...state,
      error: '',
    }),
    [TIMEZONES_BY_ZIP_INIT.getType()]: (state) => ({
      ...state,
      timezones: [],
      isLoadingTimezones: true,
    }),
    [TIMEZONES_BY_ZIP_ERROR.getType()]: (state) => ({
      ...state,
      timezones: [],
      isLoadingTimezones: false,
    }),
    [TIMEZONES_BY_ZIP_SUCCESS.getType()]: (state, payload) => ({
      ...state,
      timezones: payload,
      isLoadingTimezones: false,
    }),
    [SAVE_ORGANIZATION_DATA_INIT.getType()]: (state) => ({
      ...state,
      addOrganizationSending: true,
      addOrganizationError: '',
    }),
    [SAVE_ORGANIZATION_DATA_ERROR.getType()]: (state, payload) => ({
      ...state,
      addOrganizationSending: false,
      addOrganizationError: payload,
    }),
    [SAVE_ORGANIZATION_DATA_SUCCESS.getType()]: (state) => ({
      ...state,
      addOrganizationSending: false,
      addOrganizationError: '',
    }),
  },
  initialState
)
