import {
  DEFAULT_DEVICE_INFO,
  DEVICE_STATISTIC_LABELS,
} from '../services/uvgiEmulator-constants'

import { withStyles } from '@material-ui/core/styles'
import ExpandableTable from '../../_shared/components/ExpandableTable'
import CertificatesInfoBlock from './CertificatesInfoBlock'
import CommDevicesInfoRow from './CommonDeviceInfoRow'
import styles from './style'

const DeviceInfoTable = (props) => {
  const { changeDateValue, commDevicesInfo, certificatesInfo, isSubmitted } =
    props

  return (
    <>
      <ExpandableTable
        CustomTableRow={CommDevicesInfoRow}
        propName={'commonDevicesInfo'}
        isSubmitted={isSubmitted}
        tableTitle={DEVICE_STATISTIC_LABELS.commDevicesInfo}
        columnNames={DEVICE_STATISTIC_LABELS.commDevicesInfoColumnsNames}
        defaultValue={{ ...DEFAULT_DEVICE_INFO }}
        tableData={commDevicesInfo}
        changeValue={changeDateValue}
      />
      <CertificatesInfoBlock
        blockData={certificatesInfo}
        isSubmitted={isSubmitted}
        changeValue={changeDateValue}
      />
    </>
  )
}

export default withStyles(styles)(DeviceInfoTable)
