import { call, fork, put, select, takeEvery } from 'redux-saga/effects'
import {
  CHANGE_FILTER_STATE,
  CREATE_USER_ERROR,
  CREATE_USER_INIT,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  GET_CUSTOMER_URL,
  GET_CUSTOMER_URL_FAILED,
  GET_CUSTOMER_URL_SUCCESS,
  GET_UNIQUE_CODE,
  GET_UNIQUE_CODE_FAILED,
  GET_UNIQUE_CODE_SUCCESS,
  LOAD_USERS,
  LOAD_USERS_FAILED,
  LOAD_USERS_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
} from './users-actions'

import { Action } from 'redux-act'
import { RootState } from 'store/store'
import { Payload } from 'types/storeTypes'
import { UserProfile } from 'types/userTypes'
import { HttpClient } from '../../../services/HttpClient'
import { UsersFilterState } from '../components/types'
import { prepareQuery } from './users-service'

type ErrorResponse = { message: string }

export const getFilterState = (state: RootState) =>
  state.usersReducer.userFilters

function* loadUsers() {
  try {
    const filterState: UsersFilterState = yield select(getFilterState)

    const query = prepareQuery(filterState)

    const data: ErrorResponse | UserProfile[] = yield call(() => {
      return HttpClient.get(
        `/api/secured/user-profiles/scroll?${query}order.${filterState.sort}=${
          filterState.sortDirection
        }&pageNum=${filterState.pageNum || 0}&pageSize=${
          filterState.pageSize || 10
        }`
      )
    })

    const countData: { count: number } = yield call(() => {
      return HttpClient.get(
        `/api/secured/user-profiles/count?organizationName=${
          filterState.organizationName
            ? `*${filterState.organizationName}*`
            : ''
        }&email=${filterState.email ? `*${filterState.email}*` : ''}`
      )
    })

    if ((data as ErrorResponse).message) {
      yield put(LOAD_USERS_FAILED((data as ErrorResponse).message))
    } else {
      yield put(LOAD_USERS_SUCCESS(data as UserProfile[], countData.count))
    }
  } catch (e: any) {
    yield put(LOAD_USERS_FAILED(e.message))
  }
}

function* getUniqueCode(action: Action<{ id: string }>) {
  try {
    const { id } = action.payload

    const data: string | ErrorResponse = yield call(() => {
      return HttpClient.post(`/api/secured/user-profiles/oneTimeToken/${id}`)
    })

    if ((data as ErrorResponse).message) {
      yield put(GET_UNIQUE_CODE_FAILED((data as ErrorResponse).message))
    } else {
      yield put(GET_UNIQUE_CODE_SUCCESS(data as string))
    }
  } catch (e: any) {
    yield put(GET_UNIQUE_CODE_FAILED(e.message))
  }
}

type CustomerURLResponse = {
  'customer-portal.url': string
}

function* getCustomerUrl() {
  try {
    const data: CustomerURLResponse | ErrorResponse = yield call(() => {
      return HttpClient.get('/api/secured/properties')
    })

    if ((data as ErrorResponse).message) {
      yield put(GET_CUSTOMER_URL_FAILED((data as ErrorResponse).message))
    } else {
      yield put(
        GET_CUSTOMER_URL_SUCCESS(
          (data as CustomerURLResponse)['customer-portal.url']
        )
      )
    }
  } catch (e: any) {
    yield put(GET_CUSTOMER_URL_FAILED(e.message))
  }
}

function* resetPassword(action: Action<{ id: string; onSuccess: () => void }>) {
  try {
    const { id, onSuccess } = action.payload

    const data: null | ErrorResponse = yield call(() => {
      return HttpClient.post(`/api/secured/user-profiles/reset-password/${id}`)
    })

    if ((data as ErrorResponse).message) {
      yield put(RESET_PASSWORD_FAILED((data as ErrorResponse).message))
    } else {
      yield put(RESET_PASSWORD_SUCCESS())
      onSuccess()
    }
  } catch (e: any) {
    yield put(RESET_PASSWORD_FAILED(e.message))
  }
}

function* deleteUser(action: Action<{ id: string; onSuccess: () => void }>) {
  try {
    const { id, onSuccess } = action.payload

    const data: null | ErrorResponse = yield call(() => {
      return HttpClient.put(`/api/secured/profiles/${id}/deactivate`)
    })

    if ((data as ErrorResponse).message) {
      yield put(DELETE_USER_FAILED((data as ErrorResponse).message))
    } else {
      yield put(DELETE_USER_SUCCESS())
      onSuccess()
    }
  } catch (e: any) {
    yield put(DELETE_USER_FAILED(e.message))
  }
}

function* createUser(action: Action<Payload<typeof CREATE_USER_INIT>>) {
  try {
    const payload = action.payload

    const data: null | ErrorResponse = yield call(() => {
      return HttpClient.post(`/api/secured/user-profiles/users`, {
        body: payload,
      })
    })

    if ((data as ErrorResponse).message) {
      yield put(CREATE_USER_ERROR((data as ErrorResponse).message))
    } else {
      yield put(CREATE_USER_SUCCESS())
    }
  } catch (e: any) {
    yield put(CREATE_USER_ERROR(e.message))
  }
}

function* loadUsersListSaga() {
  yield takeEvery(CHANGE_FILTER_STATE.getType(), loadUsers)
  yield takeEvery(LOAD_USERS.getType(), loadUsers)
  yield takeEvery(DELETE_USER_SUCCESS.getType(), loadUsers)
}

function* getUniqueCodeSaga() {
  yield takeEvery(GET_UNIQUE_CODE.getType(), getUniqueCode)
}

function* getCustomerUrlSaga() {
  yield takeEvery(GET_CUSTOMER_URL.getType(), getCustomerUrl)
}

function* resetPasswordSaga() {
  yield takeEvery(RESET_PASSWORD.getType(), resetPassword)
}

function* deleteUserSaga() {
  yield takeEvery(DELETE_USER.getType(), deleteUser)
}

function* createUserSaga() {
  yield takeEvery(CREATE_USER_INIT.getType(), createUser)
}

export default function* usersSaga() {
  yield fork(loadUsersListSaga)
  yield fork(getUniqueCodeSaga)
  yield fork(getCustomerUrlSaga)
  yield fork(resetPasswordSaga)
  yield fork(deleteUserSaga)
  yield fork(createUserSaga)
}
