import { TableCell } from '@material-ui/core'
import { Device } from 'containers/Devices/services/devices-types'
import { DEVICE_TYPE } from 'services/constants'

type DeviceSubTypeCellProps = Pick<Device, 'deviceSubType'> & {}

export const DeviceSubTypeCell = ({
  deviceSubType,
}: DeviceSubTypeCellProps) => (
  <TableCell>
    {DEVICE_TYPE[deviceSubType as keyof typeof DEVICE_TYPE].label}
  </TableCell>
)
