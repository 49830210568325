import { makeStyles, Theme } from '@material-ui/core'

import { COLORS } from '../../../../../services/constants'

export const useStyles = makeStyles(
  (theme: Theme) => ({
    actionContainer: {
      paddingTop: '10px',
      paddingBottom: '10px',
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    pageContainer: {
      padding: '20px 5%',
    },
    deviceDetailsGrid: {
      alignItems: 'baseline',
      fontFamily: 'Cerebri Sans Pro Regular',
      minHeight: 'unset',
      paddingBottom: '10px',
      paddingTop: '10px',
    },
    deviceDetailsGridChild: {
      fontFamily: 'Cerebri Sans Pro Regular',
      minHeight: 'unset',
    },
    detailKey: {
      display: 'block',
      fontFamily: 'Cerebri Sans Pro Regular',
      fontWeight: 'lighter',
      fontSize: '18px',
      lineHeight: 1,
    },
    detailValue: {
      display: 'block',
      fontFamily: 'Cerebri Sans Pro Regular',
      fontWeight: 'lighter',
      fontSize: '14px',
      lineHeight: 1,
      overflowWrap: 'anywhere',
      paddingTop: '10px',
    },
    deviceTypeWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    deviceSubType: {
      marginTop: '-10px',
      minWidth: '130px',
    },
    logSelect: {
      minWidth: '130px',
    },
    logSelectLabel: {
      marginLeft: '-10px',
      color: COLORS.BLACK,
    },
    save: {
      color: COLORS.BRIGHT_GREEN,
      padding: '0 5px',
    },
    reset: {
      color: COLORS.RED,
      padding: '0 5px',
    },
    edit: {
      padding: '0 12px',
    },
    type: {
      marginLeft: '10px',
      marginTop: '20px',
    },
    firmwares: {
      marginTop: '20px',
    },
    typeContainer: {
      margin: '0 4%',
      '& div.MuiAccordionDetails-root': {
        display: 'block',
      },
    },
    allowList: {
      marginTop: '20px',
      '&.MuiAccordion-root::before': {
        display: 'none',
      },
    },
    allowListHeader: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    deleteIcon: {
      height: '10px',
      width: '10px',
    },
    tableContainer: {
      marginTop: '20px',
      '& .MuiPaper-root': {
        maxHeight: '400px',
      },
    },
    tableTitle: {
      margin: '20px 10px',
    },
    notification: {
      margin: '20px auto',
    },
    input: {
      width: '20%',
      marginRight: '20px',
    },
    saveButton: {
      minWidth: '150px',
    },
    button: {
      padding: '5px 15px',
      fontSize: '16px',
      minHeight: 'unset',
      minWidth: 'unset',
      borderRadius: '999px',
      height: 'unset',
      display: 'flex',
      alignItems: 'center',
    },
    deviceSearch: {
      '& .MuiInputBase-root': {
        display: 'flex',
        boxSizing: 'border-box',
        border: '1px solid #ccc',
        borderRadius: '999px',
        color: '#ccc',
        padding: '5px',
        paddingBottom: '5px !important',
        paddingLeft: '15px',
      },
      '& .MuiInput-underline::before': {
        display: 'none !important',
      },
      '& .MuiInput-underline::after': {
        display: 'none !important',
      },
      '& > .Mui-focused': {
        borderColor: '#1796FF !important',
        border: '1px solid #1796FF',
        color: '#1796FF',
      },
    },
    bulkUploadContent: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px',
      flexDirection: 'column',
      marginBottom: '16px',
    },
    bulkUploadHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    bulkUploadInput: {
      display: 'flex',
      boxSizing: 'border-box',
      border: '1px solid #ccc',
      width: '100%',
      height: '10em',
    },
    error: {
      marginTop: '20px',
    },
    snackbar: {
      '& .MuiSnackbarContent-root': {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
    },
  }),
  { index: 1 }
)
