import { createAction } from 'redux-act'
import { UserProfile } from 'types/userTypes'
import { UsersFilterState } from '../components/types'

export const CHANGE_FILTER_STATE = createAction(
  '{USERS} Change Filter State',
  (userFilters: UsersFilterState) => ({
    userFilters,
  })
)

export const CREATE_USER_INIT = createAction<{
  organizationId: number
  firstName: string
  lastName: string
  email: string
  phoneNumber: string | null
  phoneExtension: string | null
  password: string
  role: string
  permissions: string[]
}>('{USERS} CREATE_USER_INIT')
export const CREATE_USER_CLEAN = createAction('{USERS} CREATE_USER_CLEAN')
export const CREATE_USER_SUCCESS = createAction('{USERS} CREATE_USER_SUCCESS')
export const CREATE_USER_ERROR = createAction<string>(
  '{USERS} CREATE_USER_ERROR'
)

export const RESET_ERROR = createAction('{USERS} Reset Error')

export const LOAD_USERS = createAction('{USERS} Load Users')

export const LOAD_USERS_SUCCESS = createAction(
  '{USERS} Load Users Success',
  (users: UserProfile[], count: number) => ({ count, users })
)

export const LOAD_USERS_FAILED = createAction(
  '{USERS} Load Users Failed',
  (message: string) => ({ message })
)

export const GET_UNIQUE_CODE = createAction(
  '{USERS} Get Unique Code',
  (id: number, email: string) => ({ email, id })
)

export const GET_UNIQUE_CODE_SUCCESS = createAction(
  '{USERS} Get Unique Code Success',
  (code: string) => ({ code })
)

export const GET_UNIQUE_CODE_FAILED = createAction(
  '{USERS} Get Unique Code Failed',
  (message: string) => ({ message })
)

export const GET_CUSTOMER_URL = createAction('{USERS} Get Customer URL')

export const GET_CUSTOMER_URL_SUCCESS = createAction(
  '{USERS} Get Customer URL Success',
  (url: string) => ({ url })
)

export const GET_CUSTOMER_URL_FAILED = createAction(
  '{USERS} Get Customer URL Failed',
  (message: string) => ({ message })
)

export const RESET_PASSWORD = createAction(
  '{USERS} Reset Password',
  (id: number, onSuccess: () => void) => ({ id, onSuccess })
)

export const RESET_PASSWORD_SUCCESS = createAction(
  '{USERS} Reset Password Success'
)

export const RESET_PASSWORD_FAILED = createAction(
  '{USERS} Reset Password Failed',
  (message: string) => ({ message })
)

export const DELETE_USER = createAction(
  '{USERS} Delete User',
  (id: number, onSuccess: () => void) => ({ id, onSuccess })
)

export const DELETE_USER_SUCCESS = createAction('{USERS} Delete User Success')

export const DELETE_USER_FAILED = createAction(
  '{USERS} Delete User Failed',
  (message: string) => ({ message })
)

export const RESET_CODE_AND_URL = createAction('{USERS} Reset Code And URL')
