import map from 'lodash/map'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import CardWithDescription from '../../../../../../components/_shared/CardWithDescription/CardWithDescription'
import { checkConnection } from '../../../../../../services/root/root-service'
import { GET_FEATURES } from '../../services/organizationDetails-actions'
import { CONTAINER_TITLES } from '../../services/organizationDetails-constants'
import ContainerWithTitle from '../ContainerWithTitle/ContainerWithTitle'
import FeatureSwitch from './FeatureSwitch'
import LocationSchemaToggle from './LocationSchemaToggle'

type FeatureTogglingProps = {
  isReadOnly?: boolean
  id: string
}

const FeatureToggling = ({ isReadOnly, id }: FeatureTogglingProps) => {
  const dispatch = useDispatch()

  const features = useAppSelector(
    (state) => state.organizationDetailsReducer.features
  )
  const isFeaturesReadOnly = useAppSelector(
    (state) => state.organizationReducer.isFeaturesReadOnly
  )

  const getFeatures = (id: string) =>
    dispatch(checkConnection(() => GET_FEATURES(id)))

  useEffect(() => {
    getFeatures(id)
  }, [id])

  const featuresList = map(
    // NOTE: EXT devices may be added back in future
    features.filter(({ id }) => id !== 'EXT_DEVICE'),
    (feature) => (
      <CardWithDescription
        {...feature}
        key={`${feature.id}-${id}`}
        actionComponent={
          <FeatureSwitch
            id={id}
            checked={feature.enabled}
            featureName={feature.id}
            isReadOnly={isFeaturesReadOnly || isReadOnly}
          />
        }
      />
    )
  )

  featuresList.push(
    <LocationSchemaToggle
      key="location-schema-toggle"
      id={id}
      isReadOnly={isReadOnly}
    />
  )

  return (
    <ContainerWithTitle title={CONTAINER_TITLES.features}>
      {featuresList}
    </ContainerWithTitle>
  )
}

export default FeatureToggling
