import authorizationActions from './authorization-actions'
import { DEACTIVATED_ERROR } from './authorization-constants'

export const authorizationReducer = (
  state = {
    loginError: '',
    loginInProgress: false,
    resetPasswordsError: '',
    setupNewPasswordError: '',
    setupNewPasswordSending: false,
    setupNewPasswordSuccess: false,
    resetPasswordSending: false,
    deactivatedError: '',
  },
  action
) => {
  switch (action.type) {
    case authorizationActions.actionTypes.LOGIN_TO_APP:
      return {
        ...state,
        loginInProgress: true,
      }
    case authorizationActions.actionTypes.LOGIN_TO_APP_FAILED:
      return {
        ...state,
        loginError: action.payload.message,
        loginInProgress: false,
      }
    case authorizationActions.actionTypes.LOGIN_TO_APP_SUCCEEDED:
      return {
        ...state,
        loginError: '',
        deactivatedError: '',
        loginInProgress: false,
      }
    case authorizationActions.actionTypes.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordSending: true,
      }
    case authorizationActions.actionTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordsError: action.payload.message,
        resetPasswordSending: false,
      }
    case authorizationActions.actionTypes.RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        resetPasswordsError: '',
        deactivatedError: '',
        resetPasswordSending: false,
      }
    case authorizationActions.actionTypes.UPDATE_PASSWORD:
      return {
        ...state,
        resetPasswordsError: '',
        deactivatedError: '',
        setupNewPasswordError: '',
        setupNewPasswordSending: true,
        setupNewPasswordSuccess: false,
      }
    case authorizationActions.actionTypes.UPDATE_PASSWORD_FAILED:
    case authorizationActions.actionTypes.SETUP_NEW_PASSWORD_FAILED:
      return {
        ...state,
        setupNewPasswordError: action.payload.message,
        setupNewPasswordSending: false,
        setupNewPasswordSuccess: false,
      }
    case authorizationActions.actionTypes.UPDATE_PASSWORD_SUCCEEDED:
    case authorizationActions.actionTypes.SETUP_NEW_PASSWORD_SUCCEEDED:
      return {
        ...state,
        loginError: '',
        resetPasswordsError: '',
        setupNewPasswordError: '',
        deactivatedError: '',
        setupNewPasswordSending: false,
        setupNewPasswordSuccess: true,
      }
    case authorizationActions.actionTypes.RESET_LOGIN_ERRORS:
      return {
        ...state,
        resetPasswordsError: '',
        loginError: '',
        deactivatedError: '',
      }
    case authorizationActions.actionTypes.USER_DEACTIVATED:
      return {
        ...state,
        deactivatedError: DEACTIVATED_ERROR,
      }
    default:
      return state
  }
}
