import { useEffect, useState } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CloseButton from '../../../../../../../components/_shared/closeButton/CloseButton'
import { Location } from '../../../services/organizationDetails-types'
import ControlPart from '../addLocation/ControlPart'
import { useStyles } from './style'

type EditModalProps = {
  isOpen: boolean
  actionLabel: string
  error: string
  modalTitle: string
  onClose: () => void
  onSubmit: (value: string, propertyFields: Partial<Location>) => void
  streetAddress: string
  city: string
  zip: string
  state: string
  name: string
  locationType: string
  width: number
  height: number
  length: number
}

const EditModal = ({
  isOpen,
  actionLabel,
  error,
  modalTitle,
  onClose,
  onSubmit,
  streetAddress,
  city,
  zip,
  state,
  name,
  locationType,
  width,
  height,
  length,
}: EditModalProps) => {
  const styles = useStyles()
  const [locationName, setLocationName] = useState(name)
  const [isEmptyTextField, setIsEmptyTextField] = useState(false)
  let timer: any
  let prevent = true
  const [propertyFields, setPropertyFields] = useState<Partial<Location>>({
    streetAddress,
    city,
    zip,
    state,
    width,
    height,
    length,
  })

  useEffect(() => {
    if (isOpen) {
      setPropertyFields({
        streetAddress,
        city,
        zip,
        state,
        width,
        height,
        length,
      })

      setLocationName(name)
    }
  }, [isOpen, name, streetAddress, city, zip, width, height, length])

  const onChangePropertyFields: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { value, name } = event.target

    setPropertyFields((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onChangeLocationName: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setLocationName(event.target.value)
  }

  const onCloseModal = () => {
    setIsEmptyTextField(false)

    onClose()
  }

  const onSubmitButton: React.MouseEventHandler = (event) => {
    event.preventDefault()
    const value = locationName

    if (value.trim() === '') {
      setIsEmptyTextField(true)
    } else {
      setIsEmptyTextField(false)

      if (error) {
        prevent = true
      }

      timer = setTimeout(function () {
        if (prevent) {
          onSubmit(value, propertyFields)
          prevent = false
        }
      }, 200)
    }
  }

  function handleDoubleClick() {
    if (timer) {
      clearTimeout(timer)
    }
    prevent = true
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle className={styles.modalTitleContainer} disableTypography>
          <Typography variant={'h4'} className={styles.modalTitle}>
            {modalTitle}
          </Typography>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <CloseButton onCloseModal={onCloseModal} />
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <ControlPart
            handleDoubleClick={handleDoubleClick}
            onSubmitButton={onSubmitButton}
            error={error}
            isBulk={false}
            onChangeLocationName={onChangeLocationName}
            locationName={locationName}
            isEmptyTextField={isEmptyTextField}
            locationType={locationType}
            propertyFields={propertyFields}
            onChangePropertyFields={onChangePropertyFields}
            submitButton={actionLabel}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default EditModal
