import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import { PAGES } from '../../../services/constants'
import rootActions from '../../../services/root/root-actions'
import featureTogglingActions from './featureToggling-actions'

export const featureTogglingReducer = (
  state = {
    features: [],
    isLoading: true,
    error: '',
    getFeaturesError: '',
    isReadOnly: false,
  },
  action
) => {
  switch (action.type) {
    case rootActions.actionTypes.SETUP_PERMISSIONS: {
      const { permissions } = action.payload

      return {
        ...state,
        isReadOnly: !permissions[PAGES.globalFeatureToggling].write,
      }
    }
    case featureTogglingActions.actionTypes.GET_FEATURES:
      return {
        ...state,
        isLoading: true,
        error: '',
        getFeaturesError: '',
      }
    case featureTogglingActions.actionTypes.GET_FEATURES_SUCCEEDED:
      return {
        ...state,
        features: action.payload.features.items,
        isLoading: false,
        error: '',
        getFeaturesError: '',
      }
    case featureTogglingActions.actionTypes.GET_FEATURES_FAILED: {
      return {
        ...state,
        getFeaturesError: action.payload.message,
        isLoading: false,
      }
    }
    case featureTogglingActions.actionTypes.UPDATE_FEATURE_STATE_SUCCEEDED: {
      const { id, switchState } = action.payload
      const changedIndex = findIndex(state.features, ['id', id])
      const features = cloneDeep(state.features)
      features[changedIndex].enabled = switchState

      return {
        ...state,
        features,
        isLoading: false,
        error: '',
        getFeaturesError: '',
      }
    }
    case featureTogglingActions.actionTypes.UPDATE_FEATURE_STATE_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    }
    default:
      return state
  }
}
