const devicesListActions = {}

devicesListActions.actionTypes = {
  LOAD_DEVICES: '{Devices List} Load Devices',
  LOAD_DEVICES_SUCCEEDED: '{Devices List} Load Devices Succeeded',
  LOAD_DEVICES_FAILED: '{Devices List} Load Devices Failed',

  DELETE_DEVICE: '{Devices List} Delete Device',
  DELETE_DEVICE_SUCCEEDED: '{Devices List} Delete Device Succeeded',
  DELETE_DEVICE_FAILED: '{Devices List} Delete Device Failed',

  FULL_CLEANUP_DEVICE_BY_ID: '{Devices List} Full Cleanup Device By Id',
  FULL_CLEANUP_DEVICE_BY_ID_SUCCEEDED:
    '{Devices List} Full Cleanup Device By Id Succeeded',
  FULL_CLEANUP_DEVICE_BY_ID_FAILED:
    '{Devices List} Full Cleanup Device By Id Failed',

  GET_CLEANUP_ABILITY: '{Devices List} Get Cleanup Ability',
  GET_CLEANUP_ABILITY_SUCCEEDED: '{Devices List} Get Cleanup Ability Succeeded',
  GET_CLEANUP_ABILITY_FAILED: '{Devices List} Get Cleanup Ability Failed',

  BULK_UPLOAD_DEVICES: '{Devices List} Bulk Upload Devices',
  BULK_UPLOAD_DEVICES_SUCCEEDED: '{Devices List} Bulk Upload Devices Succeeded',
  BULK_UPLOAD_DEVICES_FAILED: '{Devices List} Bulk Upload Devices Failed',

  RESET_BULK_UPLOAD_ERRORS: '{Devices List} Reset Bulk Upload Errors',
  RESET_ERROR: '{Devices List} Reset Errors',

  DOWNLOAD_CSV_EXAMPLE: '{Devices List} Download CSV Example',
  DOWNLOAD_CSV_EXAMPLE_SUCCEEDED:
    '{Devices List} Download CSV Example Succeeded',
  DOWNLOAD_CSV_EXAMPLE_FAILED: '{Devices List} Download CSV Example Failed',

  SET_PAGE: '{Devices List} Set Page',
  SET_FILTER_STATE: '{Devices List} Set Default Filter State',

  SET_ROWS_PER_PAGE: '{Devices List} Set Rows Per Page For Devices',
  GO_TO_NEXT_PAGE: '{Devices List} Go To Next Page',
  GO_TO_PREVIOUS_PAGE: '{Devices List} Go To Previous Page',
  APPLY_DEVICE_FILTER: '{Devices List} Apply Device Filter',

  LOAD_DEVICES_TOTALS: '{Devices List} Load Devices Totals',
  LOAD_DEVICES_TOTALS_SUCCEEDED: '{Devices List} Load Devices Totals Succeeded',
  LOAD_DEVICES_TOTALS_FAILED: '{Devices List} Load Devices Totals Failed',

  SET_TOTALS_BY_TYPE_RECEIVED: '{Devices List} Set Totals By Type Received',
  TOGGLE_SORT: '{Devices List} Set Totals By Type Received',

  UPDATE_CERTIFICATE: '{Devices List} Update Certificate',
  UPDATE_CERTIFICATE_SUCCEEDED: '{Devices List} Update Certificate Succeeded',
  UPDATE_CERTIFICATE_FAILED: '{Devices List} Update Certificate Failed',

  GET_EXT_DEVICES_TYPES_INFO: '{Devices List} Get Ext Devices Types Info',
  GET_EXT_DEVICES_TYPES_INFO_SUCCEEDED:
    '{Devices List} Get Ext Devices Types Info Succeeded',
  GET_EXT_DEVICES_TYPES_INFO_FAILED:
    '{Devices List} Get Ext Devices Types Info Failed',
}

devicesListActions.loadDevices = (query) => {
  return {
    type: devicesListActions.actionTypes.LOAD_DEVICES,
    payload: {
      query,
    },
  }
}

devicesListActions.loadDevicesSuccess = (devices, deviceType) => {
  return {
    type: devicesListActions.actionTypes.LOAD_DEVICES_SUCCEEDED,
    payload: {
      devices,
      deviceType,
    },
  }
}

devicesListActions.loadDevicesFailed = (message) => {
  return {
    type: devicesListActions.actionTypes.LOAD_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

devicesListActions.deleteDevice = (id, type, onSuccess) => {
  return {
    type: devicesListActions.actionTypes.DELETE_DEVICE,
    payload: {
      id,
      type,
      onSuccess,
    },
  }
}

devicesListActions.deleteDeviceSuccess = () => {
  return {
    type: devicesListActions.actionTypes.DELETE_DEVICE_SUCCEEDED,
  }
}

devicesListActions.deleteDeviceFailed = (message) => {
  return {
    type: devicesListActions.actionTypes.DELETE_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

devicesListActions.fullCleanupDeviceById = (
  id,
  onSuccess,
  stopDisablingButtons
) => {
  return {
    type: devicesListActions.actionTypes.FULL_CLEANUP_DEVICE_BY_ID,
    payload: {
      id,
      onSuccess,
      stopDisablingButtons,
    },
  }
}

devicesListActions.fullCleanupDeviceByIdSuccess = () => {
  return {
    type: devicesListActions.actionTypes.FULL_CLEANUP_DEVICE_BY_ID_SUCCEEDED,
  }
}

devicesListActions.fullCleanupDeviceByIdFailed = (message) => {
  return {
    type: devicesListActions.actionTypes.FULL_CLEANUP_DEVICE_BY_ID_FAILED,
    payload: {
      message,
    },
  }
}

devicesListActions.getCleanupAbility = () => {
  return {
    type: devicesListActions.actionTypes.GET_CLEANUP_ABILITY,
  }
}

devicesListActions.getCleanupAbilitySuccess = (abilities) => {
  return {
    type: devicesListActions.actionTypes.GET_CLEANUP_ABILITY_SUCCEEDED,
    payload: {
      abilities,
    },
  }
}

devicesListActions.getCleanupAbilityFailed = (message) => {
  return {
    type: devicesListActions.actionTypes.GET_CLEANUP_ABILITY_FAILED,
    payload: {
      message,
    },
  }
}

devicesListActions.bulkUploadDevices = (body, onSuccess) => {
  return {
    type: devicesListActions.actionTypes.BULK_UPLOAD_DEVICES,
    payload: {
      body,
      onSuccess,
    },
  }
}

devicesListActions.bulkUploadDevicesSuccess = (response) => ({
  type: devicesListActions.actionTypes.BULK_UPLOAD_DEVICES_SUCCEEDED,
  payload: {
    response,
  },
})

devicesListActions.bulkUploadDevicesFailed = (message) => {
  return {
    type: devicesListActions.actionTypes.BULK_UPLOAD_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

devicesListActions.resetBulkUploadErrors = () => {
  return {
    type: devicesListActions.actionTypes.RESET_BULK_UPLOAD_ERRORS,
  }
}

devicesListActions.resetError = () => {
  return {
    type: devicesListActions.actionTypes.RESET_ERROR,
  }
}

devicesListActions.downloadCsvExample = (body, onSuccess) => {
  return {
    type: devicesListActions.actionTypes.DOWNLOAD_CSV_EXAMPLE,
    payload: {
      body,
      onSuccess,
    },
  }
}

devicesListActions.downloadCsvExampleSuccess = (response) => ({
  type: devicesListActions.actionTypes.DOWNLOAD_CSV_EXAMPLE_SUCCEEDED,
  payload: {
    response,
  },
})

devicesListActions.downloadCsvExampleFailed = (message) => {
  return {
    type: devicesListActions.actionTypes.DOWNLOAD_CSV_EXAMPLE_FAILED,
    payload: {
      message,
    },
  }
}

devicesListActions.setPage = (page) => {
  return {
    type: devicesListActions.actionTypes.SET_PAGE,
    payload: {
      page,
    },
  }
}

devicesListActions.setFilterState = (filterState) => {
  return {
    type: devicesListActions.actionTypes.SET_FILTER_STATE,
    payload: {
      filterState,
    },
  }
}

devicesListActions.toggleSort = (sort) => {
  return {
    type: devicesListActions.actionTypes.TOGGLE_SORT,
    payload: {
      sort,
    },
  }
}

devicesListActions.setRowsPerPage = (rowsPerPage) => {
  return {
    type: devicesListActions.actionTypes.SET_ROWS_PER_PAGE,
    payload: {
      rowsPerPage,
    },
  }
}

devicesListActions.goToNextPage = function (newPage) {
  return {
    type: devicesListActions.actionTypes.GO_TO_NEXT_PAGE,
    payload: {
      newPage,
    },
  }
}

devicesListActions.goToPreviousPage = function (newPage) {
  return {
    type: devicesListActions.actionTypes.GO_TO_PREVIOUS_PAGE,
    payload: {
      newPage,
    },
  }
}

devicesListActions.applyDeviceFilter = function () {
  return {
    type: devicesListActions.actionTypes.APPLY_DEVICE_FILTER,
  }
}

devicesListActions.loadDevicesTotals = (query, deviceType) => {
  return {
    type: devicesListActions.actionTypes.LOAD_DEVICES_TOTALS,
    payload: {
      query,
      deviceType,
    },
  }
}

devicesListActions.loadDevicesTotalsSuccess = (
  devicesTypesCount,
  isExtDevicesTab
) => {
  return {
    type: devicesListActions.actionTypes.LOAD_DEVICES_TOTALS_SUCCEEDED,
    payload: {
      devicesTypesCount,
      isExtDevicesTab,
    },
  }
}

devicesListActions.loadDevicesTotalsFailed = (message) => {
  return {
    type: devicesListActions.actionTypes.LOAD_DEVICES_TOTALS_FAILED,
    payload: {
      message,
    },
  }
}

devicesListActions.setTotalsByTypeReceived = (status) => {
  return {
    type: devicesListActions.actionTypes.SET_TOTALS_BY_TYPE_RECEIVED,
    payload: {
      status,
    },
  }
}

devicesListActions.updateCertificate = (id, onSuccess) => {
  return {
    type: devicesListActions.actionTypes.UPDATE_CERTIFICATE,
    payload: {
      id,
      onSuccess,
    },
  }
}

devicesListActions.updateCertificateSuccess = () => {
  return {
    type: devicesListActions.actionTypes.UPDATE_CERTIFICATE_SUCCEEDED,
  }
}

devicesListActions.updateCertificateFailed = (message) => {
  return {
    type: devicesListActions.actionTypes.UPDATE_CERTIFICATE_FAILED,
    payload: {
      message,
    },
  }
}

devicesListActions.getExtDevicesTypesInfo = () => {
  return {
    type: devicesListActions.actionTypes.GET_EXT_DEVICES_TYPES_INFO,
  }
}

devicesListActions.getExtDevicesTypesInfoSuccess = (extTypesInfo) => {
  return {
    type: devicesListActions.actionTypes.GET_EXT_DEVICES_TYPES_INFO_SUCCEEDED,
    payload: {
      extTypesInfo,
    },
  }
}

devicesListActions.getExtDevicesTypesInfoFailed = (message) => {
  return {
    type: devicesListActions.actionTypes.GET_EXT_DEVICES_TYPES_INFO_FAILED,
    payload: {
      message,
    },
  }
}

export default devicesListActions
