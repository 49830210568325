import { makeStyles } from '@material-ui/core'
import { COLORS } from '../../../services/constants'

export default makeStyles({
  actionCard: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#F8F8F8',
    borderLeft: '5px solid rgba(0, 0, 0, 0.3)',

    height: 'fit-content',
    width: '100%',
    marginBottom: '4px',
  },
  actionWrapper: {
    paddingRight: '16px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  descriptionWrapper: {
    width: '50%',
    padding: '16px 0 16px 16px',
    margin: 'auto 0',
    fontFamily: 'Cerebri Sans Pro Regular',
  },
  description: {
    width: '100%',
    fontSize: '12px',
    color: COLORS.GRAY,
    marginBottom: '8px',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    fontSize: '14px',
    color: COLORS.BLACK,
  },
  fieldWithButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  inputField: {
    flex: 1,
    width: '100%',
  },
  buttonDone: {
    color: COLORS.GREEN,
  },
  buttonDisabled: { color: COLORS.GRAY_DISABLED },
})
