import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'

import Loader from 'components/_shared/loader/Loader'
import { DEVICE_DETAILS } from 'containers/Devices/components/Details/services/deviceDetails-constants'
import { Device } from 'containers/Devices/services/devices-types'
import map from 'lodash/map'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { yyyyMMDDHHmmFormat } from 'services/dateFormat-service'
import { checkConnection } from 'services/root/root-service'
import { useAppSelector } from 'utils/reduxHelpers'
import devicesListActions from '../../services/devicesList-actions'
import { DeviceEditLinkCell } from './cells/DeviceEditLinkCell'
import { SerialNumberCell } from './cells/SerialNumberCell'

export const BeamTable = () => {
  const dispatch = useDispatch()
  const devices = useAppSelector(
    (state) => state.devicesReducer.devicesListReducer.devicesList
  )
  const isLoading = useAppSelector(
    (state) => state.devicesReducer.devicesListReducer.isLoading
  )
  const page = useAppSelector(
    (state) => state.devicesReducer.devicesListReducer.page
  )
  const rowsPerPage = useAppSelector(
    (state) => state.devicesReducer.devicesListReducer.rowsPerPage
  )
  const totalCount = useAppSelector(
    (state) => state.devicesReducer.devicesListReducer.totalCount
  )

  const filterState = useAppSelector(
    (state) => state.devicesReducer.devicesListReducer.filterState
  )
  const isReadOnly = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.isReadOnly
  )

  const [sort, setSort] = useState<
    | 'serialNumber'
    | 'organizationName'
    | 'imei'
    | 'version'
    | 'nickname'
    | 'deviceSubType'
    | 'lastPoll'
  >('serialNumber')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')

  useEffect(() => {
    setSort(filterState.sort || 'serialNumber')
    setSortDirection(filterState.sortDirection || 'asc')
  }, [filterState.sort, filterState.sortDirection])

  const toggleDeviceSort = (
    filterSort: typeof sort,
    filterSortDirection: typeof sortDirection
  ) => {
    dispatch(
      devicesListActions.toggleSort({
        sort: filterSort,
        sortDirection: filterSortDirection,
      })
    )
  }
  const goToNextPage = (page: number) =>
    dispatch(checkConnection(() => devicesListActions.goToNextPage(page)))
  const goToPreviousPage = (page: number) =>
    dispatch(devicesListActions.goToPreviousPage(page))
  const changeRowsPerPage = (value: number) =>
    dispatch(devicesListActions.setRowsPerPage(value))
  const changePage = (newPage: number) => {
    const isDevicesWasLoaded = devices.length <= newPage * rowsPerPage

    if (newPage > page && isDevicesWasLoaded) {
      goToNextPage(newPage)
    } else {
      goToPreviousPage(newPage)
    }
  }

  const toggleSort = (newSort: typeof sort) => {
    toggleDeviceSort(
      newSort,
      newSort === sort
        ? sortDirection === 'asc'
          ? 'desc'
          : 'asc'
        : sortDirection
    )
  }

  return (
    <Loader isLoading={isLoading}>
      <Table id="devices-table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell
              sortDirection={sort === 'serialNumber' ? sortDirection : false}
            >
              <TableSortLabel
                active={sort === 'serialNumber'}
                direction={sortDirection}
                onClick={() => toggleSort('serialNumber')}
              >
                Serial Number
              </TableSortLabel>
            </TableCell>
            <TableCell
              sortDirection={
                sort === 'organizationName' ? sortDirection : false
              }
            >
              <TableSortLabel
                active={sort === 'organizationName'}
                direction={sortDirection}
                onClick={() => toggleSort('organizationName')}
              >
                Organization
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={sort === 'imei' ? sortDirection : false}>
              <TableSortLabel
                active={sort === 'imei'}
                direction={sortDirection}
                onClick={() => toggleSort('imei')}
              >
                IMEI
              </TableSortLabel>
            </TableCell>
            <TableCell
              sortDirection={sort === 'lastPoll' ? sortDirection : false}
            >
              <TableSortLabel
                active={sort === 'lastPoll'}
                direction={sortDirection}
                onClick={() => toggleSort('lastPoll')}
              >
                Last Seen
              </TableSortLabel>
            </TableCell>
            <TableCell
              sortDirection={sort === 'version' ? sortDirection : false}
            >
              <TableSortLabel
                active={sort === 'version'}
                direction={sortDirection}
                onClick={() => toggleSort('version')}
              >
                Firmware
              </TableSortLabel>
            </TableCell>
            <TableCell
              sortDirection={sort === 'nickname' ? sortDirection : false}
            >
              <TableSortLabel
                active={sort === 'nickname'}
                direction={sortDirection}
                onClick={() => toggleSort('nickname')}
              >
                Nickname
              </TableSortLabel>
            </TableCell>
            {!isReadOnly && <TableCell align="center">Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {useMemo(
            () =>
              map(devices, (device: Device) => (
                <TableRow key={`beam-device-${device.id}`}>
                  <TableCell>{device.id}</TableCell>
                  <SerialNumberCell {...device} />
                  <TableCell className="organization-name">
                    {device.organizationName}
                  </TableCell>
                  <TableCell className="imei">{device.imei}</TableCell>
                  <TableCell>
                    {device.lastPoll
                      ? moment(device.lastPoll).format(yyyyMMDDHHmmFormat)
                      : DEVICE_DETAILS.noInformation}
                  </TableCell>
                  <TableCell>{device.version}</TableCell>
                  <TableCell>{device.nickname}</TableCell>
                  {!isReadOnly && <DeviceEditLinkCell {...device} />}
                </TableRow>
              )),
            [devices]
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 30, 50]}
              colSpan={isReadOnly ? 7 : 8}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => {
                changePage(newPage)
              }}
              onRowsPerPageChange={(event) => {
                changeRowsPerPage(parseInt(event.target.value, 10))
                changePage(0)
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Loader>
  )
}
