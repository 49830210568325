const extEmulatorActions = {}

extEmulatorActions.actionTypes = {
  SEND_DEVICE_MESSAGE: '{EXT EVENTS EMULATOR} Send Device Message',
  SEND_DEVICE_MESSAGE_SUCCEEDED:
    '{EXT EVENTS EMULATOR} Send Device Message Succeeded',
  SEND_DEVICE_MESSAGE_FAILED:
    '{EXT EVENTS EMULATOR} Send Device Message Failed',

  LOAD_ALL_LOCATIONS: '{EXT EVENTS EMULATOR} Load All Locations',
  LOAD_ALL_LOCATIONS_SUCCEEDED:
    '{EXT EVENTS EMULATOR} Load All Locations Succeeded',

  LOAD_DEVICE_LOCATIONS_SUCCEEDED:
    '{EXT EVENTS EMULATOR} Load Device Locations Succeeded',
  LOAD_DEVICE_LOCATIONS_FAILED:
    '{EXT EVENTS EMULATOR} Load Device Locations Failed',

  LOAD_EXT_DEVICES: '{EXT EVENTS EMULATOR} Load EXT Devices',
  LOAD_EXT_DEVICES_SUCCEEDED:
    '{EXT EVENTS EMULATOR} Load EXT Devices Succeeded',
  LOAD_EXT_DEVICES_FAILED: '{EXT EVENTS EMULATOR} Load EXT Devices Failed',
}

extEmulatorActions.sendDeviceMessage = (params, body) => {
  return {
    type: extEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE,
    payload: {
      params,
      body,
    },
  }
}

extEmulatorActions.sendDeviceMessageSuccess = (message) => {
  return {
    type: extEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_SUCCEEDED,
    payload: {
      message,
    },
  }
}

extEmulatorActions.sendDeviceMessageFailed = (message) => {
  return {
    type: extEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_FAILED,
    payload: {
      message,
    },
  }
}

extEmulatorActions.loadAllLocations = () => {
  return {
    type: extEmulatorActions.actionTypes.LOAD_ALL_LOCATIONS,
  }
}

extEmulatorActions.loadAllLocationsSuccess = (locations) => {
  return {
    type: extEmulatorActions.actionTypes.LOAD_ALL_LOCATIONS_SUCCEEDED,
    payload: {
      locations,
    },
  }
}

extEmulatorActions.loadLocationsSuccess = (locations) => {
  return {
    type: extEmulatorActions.actionTypes.LOAD_DEVICE_LOCATIONS_SUCCEEDED,
    payload: {
      locations,
    },
  }
}

extEmulatorActions.loadLocationsFailed = (message) => {
  return {
    type: extEmulatorActions.actionTypes.LOAD_DEVICE_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

extEmulatorActions.loadEXTDevices = () => {
  return {
    type: extEmulatorActions.actionTypes.LOAD_EXT_DEVICES,
  }
}

extEmulatorActions.loadEXTDevicesSuccess = (devices) => {
  return {
    type: extEmulatorActions.actionTypes.LOAD_EXT_DEVICES_SUCCEEDED,
    payload: {
      devices,
    },
  }
}

extEmulatorActions.loadEXTDevicesFailed = (message) => {
  return {
    type: extEmulatorActions.actionTypes.LOAD_EXT_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

export default extEmulatorActions
