import { useEffect } from 'react'

import { withStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import CustomSelect from '../../../../../../components/_shared/customSelect/CustomSelect'
import actions from '../../services/devicesList-actions'
import { EXT_TYPES_INFO_SELECT_CONFIG } from '../../services/devicesList-constants'
import { getExtDevicesTypesAndSubTypesOptions } from '../../services/devicesList-service'
import styles from './styles'

const ExtTypesSelect = (props) => {
  const {
    filterState,
    setFilterState,
    extTypesInfo,
    getExtDevicesTypesInfo,
    classes,
  } = props

  useEffect(() => {
    getExtDevicesTypesInfo()
    // eslint-disable-next-line
  }, [])

  const handleExtTypeAndSubTypeChange = (e) => {
    const { name, value } = e.target

    if (name === EXT_TYPES_INFO_SELECT_CONFIG.type.name) {
      setFilterState({
        ...filterState,
        [name]: value,
        [EXT_TYPES_INFO_SELECT_CONFIG.subType.name]: '',
      })
    } else {
      setFilterState({
        ...filterState,
        [name]: value,
      })
    }
  }

  const [devicesTypesOptions, devicesSubTypesOptions] =
    getExtDevicesTypesAndSubTypesOptions(
      extTypesInfo,
      filterState.extDeviceType
    )

  return (
    <>
      <div className={classes.selectField}>
        <CustomSelect
          options={devicesTypesOptions}
          value={filterState.extDeviceType || ''}
          onChange={handleExtTypeAndSubTypeChange}
          {...EXT_TYPES_INFO_SELECT_CONFIG.type}
        />
      </div>
      <div className={classes.selectField}>
        <CustomSelect
          options={devicesSubTypesOptions}
          value={filterState.extDeviceSubType || ''}
          onChange={handleExtTypeAndSubTypeChange}
          isReadOnly={!filterState.extDeviceType}
          {...EXT_TYPES_INFO_SELECT_CONFIG.subType}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  extTypesInfo: state.devicesReducer.devicesListReducer.extTypesInfo,
})

const mapDispatchToProps = (dispatch) => ({
  getExtDevicesTypesInfo: () => dispatch(actions.getExtDevicesTypesInfo()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ExtTypesSelect))
