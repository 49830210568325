import { COLORS } from '../../../services/constants'

export const opts = (inModal) => ({
  lines: 15, // The number of lines to draw
  length: 8, // The length of each line
  width: 4, // The line thickness
  radius: 35, // The radius of the inner circle
  scale: 1, // Scales overall size of the spinner
  corners: 1, // Corner roundness (0..1)
  speed: 0.6, // Rounds per second
  rotate: 0, // The rotation offset
  animation: 'spinner-line-shrink', // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: COLORS.BLUE, // CSS color or array of colors
  fadeColor: 'transparent', // CSS color or array of colors
  top: !inModal ? '50%' : '56%', // Top position relative to parent
  left: '50%', // Left position relative to parent
  shadow: '0 0 1px transparent', // Box-shadow for the lines
  zIndex: 1500, // The z-index (defaults to 2e9)
  className: 'spinner', // The CSS class to assign to the spinner
  position: 'absolute', // Element positioning
})
