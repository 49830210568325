import { createTheme } from '@material-ui/core/styles'
import { COLORS } from './services/constants'

const mobileTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.BLUE,
    },
    info: {
      main: COLORS.GRAY,
    },
    error: {
      main: COLORS.RED_ORANGE,
    },
    secondary: {
      main: COLORS.RED,
    },
  },
  text: {
    secondary: COLORS.GRAY,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '26.5px',
        textTransform: 'none',
        minWidth: '40%',
        height: '45px',
        fontFamily: 'Cerebri Sans Pro Semi Bold',
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiButtonBase: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '26.5px',
      },
      notchedOutline: {
        paddingLeft: '15px',
      },
      input: {
        paddingLeft: '25px',
        '&::placeholder': {
          color: COLORS.LIGHT_SILVER,
          opacity: 1,
          fontFamily: 'Cerebri Sans Pro Regular',
        },
      },
    },
    MuiSelect: {
      outlined: {
        '&:focus': {
          borderRadius: '26.5px',
        },
      },
    },
    MuiTypography: {
      body2: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
      body1: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
        fontSize: '10px',
        lineHeight: '12px',
      },
      subtitle2: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
      },
      subtitle1: {
        fontFamily: 'Cerebri Sans Pro Regular',
        fontSize: '14px',
        lineHeight: '17px',
      },
      h6: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
      h5: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
        fontSize: '14px',
        lineHeight: '17px',
      },
      h4: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
        fontSize: '16px',
      },
      h3: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
        fontSize: '20px',
      },
      h2: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
        fontSize: '32px',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        padding: '20px 8px',
      },
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center',
      },
    },
    MuiDialogContent: {
      root: {
        textAlign: 'center',
        fontSize: '10px',
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        width: '77%',
      },
      paperWidthSm: {
        maxWidth: '500px',
      },
    },
    MuiFormControl: {
      root: {
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    MuiGrid: {
      root: {
        minHeight: '100vh',
      },
    },
    MuiFormLabel: {
      root: {
        color: COLORS.LIGHT_SILVER,
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 0,
        },
      },
    },
    MuiAppBar: {
      root: {
        height: '90px',
        boxShadow: 'none',
        display: 'grid',
      },
    },
    MuiToolbar: {
      regular: {
        padding: 0,
      },
    },
    MuiFab: {
      root: {
        textTransform: 'none',
        fontFamily: 'Cerebri Sans Pro Regular',
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
      input: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'Cerebri Sans Pro Regular',
        paddingLeft: '14px',
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
      head: {
        fontFamily: 'Cerebri Sans Pro Semi Bold',
      },
    },
    MuiLink: {
      root: {
        fontFamily: 'Cerebri Sans Pro Regular',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
  },
})

export default mobileTheme
