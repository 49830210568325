import { FEATURE_KEYS } from '../../_shared/services/emulator-constants'
import { getRoomsListFromTree } from '../../_shared/services/emulator-service'
import uvgiEmulatorActions from './uvgiEmulator-actions'

export const UVGIEmulatorReducer = (
  state = {
    error: '',
    deviceMessage: {
      [FEATURE_KEYS.beam]: '',
      [FEATURE_KEYS.vive]: '',
    },
    errorFromDeviceMessage: '',
    uvgiDevices: [],
    deviceLocations: [],
    allLocations: [],
  },
  action
) => {
  switch (action.type) {
    case uvgiEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_SUCCEEDED:
      const { activeTab, message } = action.payload

      return {
        ...state,
        deviceMessage: {
          ...state.deviceMessage,
          [activeTab]: message,
        },
        errorFromDeviceMessage: '',
      }
    case uvgiEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_FAILED:
      return {
        ...state,
        errorFromDeviceMessage: action.payload.message,
      }
    case uvgiEmulatorActions.actionTypes.LOAD_UVGI_DEVICES_SUCCEEDED:
      return {
        ...state,
        uvgiDevices: action.payload.devices,
        errorFromDeviceMessage: '',
      }
    case uvgiEmulatorActions.actionTypes.LOAD_DEVICE_LOCATIONS_SUCCEEDED:
      return {
        ...state,
        deviceLocations: getRoomsListFromTree(action.payload.locations),
        errorFromDeviceMessage: '',
      }
    case uvgiEmulatorActions.actionTypes.LOAD_ALL_LOCATIONS_SUCCEEDED:
      return {
        ...state,
        allLocations: getRoomsListFromTree(action.payload.locations),
        errorFromDeviceMessage: '',
      }
    case uvgiEmulatorActions.actionTypes.LOAD_UVGI_DEVICES_FAILED:
      return {
        ...state,
        errorFromDeviceMessage: action.payload.message,
      }
    default:
      return state
  }
}
