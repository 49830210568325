import { makeStyles } from '@material-ui/core'

const fieldMinWidh = 350

export const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '7%',
    paddingRight: '7%',
    margin: '2rem',
  },
  actionButtons: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: fieldMinWidh,
  },
  button: {
    margin: theme.spacing(1),
  },
  fieldsContainer: {
    minHeight: 'inherit',
    justifyContent: 'center',
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    '& .MuiGrid-root': {
      minHeight: 'inherit',
    },
  },
  phoneAndExtension: {
    width: fieldMinWidh,
    margin: theme.spacing(1),
    display: 'flex',
    '& .MuiFormControl-root:nth-child(1)': {
      width: '80%',
      '& .MuiInputBase-root': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    '& .MuiFormControl-root:nth-child(2)': {
      width: '20%',
      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeftWidth: 0,
        '& fieldset': {
          borderLeftWidth: 0,
        },
        '&.Mui-focused, &:hover': {
          '& fieldset': {
            borderLeftWidth: 1,
          },
        },
      },
    },
  },
}))
