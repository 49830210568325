import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import {
  getDevicesTotalsQuery,
  prepareFilterQuery,
} from './devicesList-service'

import isString from 'lodash/isString'
import { DEVICE_TYPE } from '../../../../../services/constants'
import { HttpClient } from '../../../../../services/HttpClient'
import deviceDetailsActions from '../../Details/services/deviceDetails-actions'
import devicesListActions from './devicesList-actions'

const getPage = (state) => state.devicesReducer.devicesListReducer.page
const getRowsPerPage = (state) =>
  state.devicesReducer.devicesListReducer.rowsPerPage
const getFilterState = (state) =>
  state.devicesReducer.devicesListReducer.filterState
const getTotalsByTypeReceived = (state) =>
  state.devicesReducer.devicesListReducer.totalsByTypeReceived
const getFilterQuery = (state) => state.devicesReducer.devicesListReducer.query
const getDeviceType = (state) =>
  state.devicesReducer.sharedDevicesReducer.deviceType

function* loadDevices(action) {
  try {
    const { query } = action.payload || {}

    const oldQuery = yield select(getFilterQuery)
    const page = yield select(getPage)
    const rowsPerPage = yield select(getRowsPerPage)
    const totalsByTypeReceived = yield select(getTotalsByTypeReceived)
    const filterState = yield select(getFilterState)
    const deviceType = yield select(getDeviceType)

    const newQuery = prepareFilterQuery({ ...filterState, deviceType })

    const url =
      deviceType === DEVICE_TYPE.NETWORK_DEVICE.type
        ? '/api/secured/devices/network-device/page-view'
        : deviceType === DEVICE_TYPE.EXT_DEVICE.type
        ? '/api/secured/devices/ext-device/page-view'
        : deviceType === DEVICE_TYPE.SENSOR.type
        ? '/api/secured/devices/sensor-device/page-view'
        : deviceType === DEVICE_TYPE.FAR_UVC.type ||
          deviceType === DEVICE_TYPE.UR_UVGI.type
        ? '/api/secured/devices/ur-uvgi/page-view'
        : '/api/secured/devices/'

    const data = yield call(() => {
      return HttpClient.get(
        url +
          `${
            query || oldQuery || (deviceType && newQuery) || '?query='
          }&pageNum=${page}&pageSize=${rowsPerPage}&order.${
            filterState.sort || 'serialNumber'
          }=${filterState.sortDirection || 'asc'}`
      )
    })

    if (data.message) {
      yield put(devicesListActions.loadDevicesFailed(data.message))
    } else {
      yield put(devicesListActions.loadDevicesSuccess(data, deviceType))

      if (!totalsByTypeReceived) {
        const query = getDevicesTotalsQuery(filterState)

        yield put(devicesListActions.loadDevicesTotals(query))
      }
    }
  } catch (e) {
    yield put(devicesListActions.loadDevicesFailed(e.message))
  }
}

function* deleteDevice(action) {
  try {
    const { id, type, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.delete(`/api/secured/devices/${type}/${id}`)
    })

    if (data.message) {
      yield put(devicesListActions.deleteDeviceFailed(data.message))
    } else {
      onSuccess && onSuccess()
      yield put(devicesListActions.deleteDeviceSuccess())
      yield put(deviceDetailsActions.getFullDeviceData())
    }
  } catch (e) {
    yield put(devicesListActions.deleteDeviceFailed(e.message))
  }
}

function* getCleanupAbility() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/devices/uvi-arc/abilities')
    })

    if (data.message) {
      yield put(devicesListActions.getCleanupAbilityFailed(data.message))
    } else {
      yield put(devicesListActions.getCleanupAbilitySuccess(data))
    }
  } catch (e) {
    yield put(devicesListActions.getCleanupAbilityFailed(e.message))
  }
}

function* fullCleanupDeviceById(action) {
  try {
    const { id, onSuccess, stopDisablingButtons } = action.payload

    const data = yield call(() => {
      return HttpClient.delete(`/api/secured/devices/cleanup/full/${id}`)
    })

    if (data.message) {
      yield put(devicesListActions.fullCleanupDeviceByIdFailed(data.message))
      stopDisablingButtons()
    } else {
      onSuccess()
      yield put(devicesListActions.fullCleanupDeviceByIdSuccess())
    }
  } catch (e) {
    const { stopDisablingButtons } = action.payload

    yield put(devicesListActions.fullCleanupDeviceByIdFailed(e.message))
    stopDisablingButtons()
  }
}

function* updateCertificate(action) {
  try {
    const { id, onSuccess } = action.payload
    const oldQuery = yield select(getFilterQuery)

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/devices/uvi-arc/update-certificate/${id}`
      )
    })

    if (data.message) {
      yield put(devicesListActions.updateCertificateFailed(data.message))
    } else {
      yield put(devicesListActions.updateCertificateSuccess())
      onSuccess()
      yield put(devicesListActions.loadDevices(oldQuery))
    }
  } catch (e) {
    yield put(devicesListActions.updateCertificateFailed(e.message))
  }
}

function* bulkUploadDevices(action) {
  try {
    const { body, onSuccess } = action.payload

    const data = yield call(() => {
      // make sure not to set the Content-Type header. The browser will set it for you, including the boundary parameter.
      return HttpClient.post('/api/secured/devices/uvi-arc/bulk', {
        body,
        headers: new Headers({}),
        isFormData: true,
      })
    })

    if (data.message || isString(data)) {
      yield put(devicesListActions.bulkUploadDevicesFailed(data.message))
    } else {
      yield put(devicesListActions.bulkUploadDevicesSuccess(data))

      onSuccess()
    }
  } catch (e) {
    yield put(devicesListActions.bulkUploadDevicesFailed(e.message))
  }
}

function* downloadCsvExample() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/template', {
        isDownload: true,
      })
    })

    if (data.message || isString(data)) {
      yield put(devicesListActions.downloadCsvExampleFailed(data.message))
    } else {
      yield put(devicesListActions.bulkUploadDevicesSuccess())
    }
  } catch (e) {
    yield put(devicesListActions.downloadCsvExampleFailed(e.message))
  }
}

function* loadDevicesTotals(action) {
  try {
    const { query } = action.payload

    const deviceType = yield select(getDeviceType)

    const isExtDevicesTab = deviceType === DEVICE_TYPE.EXT_DEVICE.type

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/devices/count-devices${query}`)
    })

    if (data.message) {
      yield put(devicesListActions.loadDevicesTotalsFailed(data.message))
    } else {
      yield put(
        devicesListActions.loadDevicesTotalsSuccess(data, isExtDevicesTab)
      )
    }
  } catch (e) {
    yield put(devicesListActions.loadDevicesTotalsFailed(e.message))
  }
}

function* getExtDevicesTypesInfo() {
  try {
    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/ext-device/external-device-types-and-subtype`
      )
    })

    if (data.message) {
      yield put(devicesListActions.getExtDevicesTypesInfoFailed(data.message))
    } else {
      yield put(devicesListActions.getExtDevicesTypesInfoSuccess(data))
    }
  } catch (e) {
    yield put(devicesListActions.getExtDevicesTypesInfoFailed(e.message))
  }
}

function* toggleSort(action) {
  yield put(devicesListActions.loadDevices())
}

function* loadDevicesSaga() {
  yield all([
    yield takeLatest(devicesListActions.actionTypes.LOAD_DEVICES, loadDevices),
    yield takeEvery(
      devicesListActions.actionTypes.DELETE_DEVICE_SUCCEEDED,
      loadDevices
    ),
    yield takeEvery(
      devicesListActions.actionTypes.FULL_CLEANUP_DEVICE_BY_ID_SUCCEEDED,
      loadDevices
    ),
    yield takeEvery(
      devicesListActions.actionTypes.BULK_UPLOAD_DEVICES_SUCCEEDED,
      loadDevices
    ),
    yield takeEvery(
      devicesListActions.actionTypes.GO_TO_NEXT_PAGE,
      loadDevices
    ),
    yield takeEvery(
      devicesListActions.actionTypes.GO_TO_PREVIOUS_PAGE,
      loadDevices
    ),
    yield takeEvery(
      devicesListActions.actionTypes.SET_ROWS_PER_PAGE,
      loadDevices
    ),
  ])
}

function* toggleSortSaga() {
  yield takeLatest(devicesListActions.actionTypes.TOGGLE_SORT, toggleSort)
}

function* deleteDeviceSaga() {
  yield takeLatest(devicesListActions.actionTypes.DELETE_DEVICE, deleteDevice)
}

function* getCleanupAbilitySaga() {
  yield takeLatest(
    devicesListActions.actionTypes.GET_CLEANUP_ABILITY,
    getCleanupAbility
  )
}

function* fullCleanupDeviceByIdSaga() {
  yield takeLatest(
    devicesListActions.actionTypes.FULL_CLEANUP_DEVICE_BY_ID,
    fullCleanupDeviceById
  )
}

function* updateCertificateSaga() {
  yield takeLatest(
    devicesListActions.actionTypes.UPDATE_CERTIFICATE,
    updateCertificate
  )
}

function* uploadBulkLoadDevicesSaga() {
  yield takeLatest(
    devicesListActions.actionTypes.BULK_UPLOAD_DEVICES,
    bulkUploadDevices
  )
}

function* downloadCsvExampleSaga() {
  yield takeLatest(
    devicesListActions.actionTypes.DOWNLOAD_CSV_EXAMPLE,
    downloadCsvExample
  )
}

function* loadDevicesTotalsSaga() {
  yield takeEvery(
    devicesListActions.actionTypes.LOAD_DEVICES_TOTALS,
    loadDevicesTotals
  )
}

function* getExtDevicesTypesInfoSaga() {
  yield takeLatest(
    devicesListActions.actionTypes.GET_EXT_DEVICES_TYPES_INFO,
    getExtDevicesTypesInfo
  )
}

export default function* devicesListSagas() {
  yield fork(loadDevicesSaga)
  yield fork(deleteDeviceSaga)
  yield fork(getCleanupAbilitySaga)
  yield fork(fullCleanupDeviceByIdSaga)
  yield fork(updateCertificateSaga)
  yield fork(uploadBulkLoadDevicesSaga)
  yield fork(downloadCsvExampleSaga)
  yield fork(loadDevicesTotalsSaga)
  yield fork(getExtDevicesTypesInfoSaga)
  yield fork(toggleSortSaga)
}
