import {
  isArc,
  isNetwork,
  isSensor,
} from 'containers/Devices/services/devices-service'

import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import { checkConnection } from '../../../../../services/root/root-service'
import deviceDetailsActions from '../../Details/services/deviceDetails-actions'
import { SNACKBAR_MESSAGES } from '../../Details/services/deviceDetails-constants'
import { useStyles } from './style'

type FactoryResetActionProps = {
  id: string
  onOpenSnackbar: () => void
  setSnackbarMessage: (message: string) => void
}

const FactoryResetAction = ({
  id,
  onOpenSnackbar,
  setSnackbarMessage,
}: FactoryResetActionProps) => {
  const style = useStyles()
  const dispatch = useDispatch()

  const isActionAvailable = useAppSelector(
    (state) =>
      state.devicesReducer.sharedDevicesReducer.isUVGIPowerManagementAvailable
  )
  const deviceType = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.deviceType
  )

  const removed = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.removed
  )

  const factoryReset = (id: string, onSuccess: () => void) =>
    dispatch(
      checkConnection(() => deviceDetailsActions.factoryReset(id, onSuccess))
    )

  const reset = () => {
    setSnackbarMessage(SNACKBAR_MESSAGES.reset)

    factoryReset(id, onOpenSnackbar)
  }

  const network = isNetwork(deviceType)
  const arc = isArc(deviceType)
  const sensor = isSensor(deviceType)

  if (!isActionAvailable || network || arc || sensor || removed) {
    return null
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={reset}
      className={style.deviceActionButton}
      id="factory-reset-btn"
    >
      Factory Reset
    </Button>
  )
}

export default FactoryResetAction
