import deviceDetailsActions, {
  GET_SENSOR_DEVICE_TYPES_FAILED,
  GET_SENSOR_DEVICE_TYPES_SUCCEEDED,
} from './deviceDetails-actions'
import {
  extractRSSIs,
  getUpdatedDeviceDetails,
  prepareHistoryDeviceDetails,
  prepareValueForDeviceDetails,
} from './deviceDetails-service'

import { createReducer } from 'redux-act'
import { prepareTypes } from '../../../../../services/root/root-service'
import { prepareExtraInfoForDeviceDetails } from '../../../services/devices-service'

const initialState = {
  deviceId: '',
  error: null as string | null,
  isUpdatingLabelForCleaning: false,
  isLoading: false,
  cleaningEnabled: false,
  deviceDetails: {},
  extDeviceTypes: [] as { value: any; label: any }[],
  extDeviceSubTypes: [] as { value: any; label: any }[],
  updateOnlyCleaningStatus: false,
  removed: false,
  wifiMac: '',
  macAddress: '',
  hardwareId: '',
  outputPower: '',
  networkDeviceTypes: [] as { value: any; label: any }[],
  sensorDeviceTypes: [] as { value: any; label: any }[],
  communicationInfo: [] as {
    name: string
    value: string
    measurementUnit: string
  }[],
  actualDeviceDetails: { anotherTypeCount: 0 } as {
    anotherTypeCount: number
  },
  historyDeviceDetails: { anotherTypeCount: 0 } as {
    anotherTypeCount: number
  },
  created: null as Date | null,
  modified: null as Date | null,
  certificateCreated: false,
  initialized: false,
  organizationId: '',
  loraId: '',
  bluetoothId: '',
  mcuUid: '',
  deviceEui: '',
  initialFirmwareVersion: '',
  baseMac: '',
  softApMac: '',
  ethernetMac: '',
  barcode: '',
  deviceType: '',
  deviceSubType: '',
  mode: '',
  lastPoll: null,
  uvgiType: null,
  days: [] as boolean[],
  desiredDays: [] as boolean[],
  desiredOnTime: null as number | null,
  desiredPower: null as number | null,
  desiredSafetyDelay: null as number | null,
  desiredOperationWindowSize: null as number | null,
  desiredOperationWindowRuntimeMax: null as number | null,
  desiredDailyMaxRuntime: null as number | null,
  desiredDailyStartTime: '',
  desiredDailyEndTime: '',
  onTime: null as number | null,
  power: null as number | null,
  safetyDelay: null as number | null,
  operationWindowSize: null as number | null,
  operationWindowRuntimeMax: null as number | null,
  dailyMaxRuntime: null as number | null,
  dailyStartTime: '',
  dailyEndTime: '',
  lastSuccessDeviceCommandTime: '',
  keepLedAlways: false,
  desiredKeepLedAlways: false,
  serialNumber: '',
  cumulativeUsageInSecs: null as number | null,
}

export const deviceDetailsReducer = createReducer(
  {
    [deviceDetailsActions.actionTypes.ADD_NEW_DEVICE]: (state, payload) => {
      return {
        ...state,
        error: '',
        isLoading: true,
      }
    },
    [deviceDetailsActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        isLoading: false,
      }
    },
    [deviceDetailsActions.actionTypes.ADD_NEW_DEVICE_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
    }),
    [deviceDetailsActions.actionTypes.UPDATE_SETTINGS_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
    }),
    [deviceDetailsActions.actionTypes.DISABLE_DEVICE_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
    }),
    [deviceDetailsActions.actionTypes.FACTORY_RESET_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
    }),
    [deviceDetailsActions.actionTypes.RESTART_DEVICE_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      error: message,
      isLoading: false,
    }),
    [deviceDetailsActions.actionTypes.DISABLE_DEVICE_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        updateOnlyCleaningStatus: true,
      }
    },
    [deviceDetailsActions.actionTypes.GET_EXTENDED_DEVICE]: (
      state,
      payload
    ) => {
      return {
        ...state,
        error: '',
        isLoading: true,
      }
    },
    [deviceDetailsActions.actionTypes.GET_EXTENDED_DEVICE_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        isLoading: false,
        device: payload.device,
        removed: payload.device?.removed,
        error: '',
      }
    },
    [deviceDetailsActions.actionTypes.GET_EXTENDED_DEVICE_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      isLoading: false,
      error: message,
      updateOnlyCleaningStatus: false,
    }),
    [deviceDetailsActions.actionTypes.GET_EXT_DEVICE_TYPES_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      isLoading: false,
      error: message,
      updateOnlyCleaningStatus: false,
    }),
    [deviceDetailsActions.actionTypes.GET_NETWORK_DEVICE_TYPES_FAILED]: (
      state,
      payload
    ) => ({
      ...state,
      isLoading: false,
      error: payload.message,
      updateOnlyCleaningStatus: false,
    }),
    [GET_SENSOR_DEVICE_TYPES_FAILED.getType()]: (state, payload) => ({
      ...state,
      isLoading: false,
      error: payload.message,
      updateOnlyCleaningStatus: false,
    }),
    [deviceDetailsActions.actionTypes.GET_FULL_DEVICE_DATA_FAILED]: (
      state,
      { message }
    ) => ({
      ...state,
      isLoading: false,
      error: message,
      updateOnlyCleaningStatus: false,
    }),
    [deviceDetailsActions.actionTypes.GET_ACTUAL_DEVICE_DETAILS]: (
      state,
      payload
    ) => {
      return {
        ...state,
        error: '',
        // updateCertificateError: '',
        isLoading: true,
      }
    },
    [deviceDetailsActions.actionTypes.GET_ACTUAL_DEVICE_DETAILS_SUCCEEDED]: (
      state,
      payload
    ) => {
      const dateArray = prepareHistoryDeviceDetails(payload.data)

      const [, removed, devicePower] = prepareExtraInfoForDeviceDetails(
        payload.data
      )

      return {
        ...state,
        removed,
        devicePower,
        imei: payload.imei,
        actualDeviceDetails: dateArray,
      }
    },
    [deviceDetailsActions.actionTypes.GET_HISTORY_DEVICE_DETAILS_SUCCEEDED]: (
      state,
      payload
    ) => {
      const dateArray = prepareHistoryDeviceDetails(payload.data)

      return {
        ...state,
        isLoading: payload.needToLoadFullDevice,
        historyDeviceDetails: dateArray,
      }
    },
    [deviceDetailsActions.actionTypes.GET_FULL_DEVICE_DATA]: (
      state,
      payload
    ) => {
      return {
        ...state,
        isLoading: false,
        cleaningEnabled: false,
        isUpdatingLabelForCleaning: false,
        deviceDetails: {},
        isUpdating: false,
        wifiMac: '',
        macAddress: '',
        hardwareId: '',
        created: null as Date | null,
        modified: null as Date | null,
        certificateCreated: false,
        initialized: false,
        organizationId: '',
        loraId: '',
        bluetoothId: '',
        mcuUid: '',
        deviceEui: '',
        initialFirmwareVersion: '',
        baseMac: '',
        softApMac: '',
        ethernetMac: '',
        barcode: '',
        deviceType: '',
        deviceSubType: '',
        mode: '',
        lastPoll: null,
        uvgiType: null,
        days: [],
        desiredDays: [],
        desiredOnTime: null,
        desiredPower: null,
        desiredSafetyDelay: null,
        desiredOperationWindowSize: null,
        desiredOperationWindowRuntimeMax: null,
        desiredDailyMaxRuntime: null,
        desiredDailyStartTime: '',
        desiredDailyEndTime: '',
        onTime: null,
        power: null,
        safetyDelay: null,
        operationWindowSize: null,
        operationWindowRuntimeMax: null,
        dailyMaxRuntime: null,
        dailyStartTime: '',
        dailyEndTime: '',
        lastSuccessDeviceCommandTime: '',
        keepLedAlways: false,
        desiredKeepLedAlways: false,
        serialNumber: '',
      }
    },
    [deviceDetailsActions.actionTypes.GET_FULL_DEVICE_DATA_SUCCEEDED]: (
      state,
      payload
    ) => {
      const { data } = payload
      const { updateOnlyCleaningStatus } = state

      const isUpdatingLabelForCleaning =
        data.cleaningEnabled !== data.desiredCleaningEnabled

      const updatedDeviceDetails = getUpdatedDeviceDetails(
        updateOnlyCleaningStatus,
        data
      )

      return {
        ...state,
        isLoading: false,
        cleaningEnabled: isUpdatingLabelForCleaning
          ? !!data.desiredCleaningEnabled
          : !!data.cleaningEnabled,
        isUpdatingLabelForCleaning,
        ...updatedDeviceDetails,
        wifiMac: data.wifiMac,
        macAddress: data.macAddress,
        hardwareId: data.hardwareId,
        created: data.created,
        modified: data.modified,
        certificateCreated: data.certificateCreated,
        initialized: data.initialized,
        organizationId: data.organizationId,
        loraId: data.loraId,
        bluetoothId: data.bluetoothId,
        mcuUid: data.mcuUid,
        deviceEui: data.deviceEui,
        initialFirmwareVersion: data.initialFirmwareVersion,
        baseMac: data.baseMac,
        softApMac: data.softApMac,
        ethernetMac: data.ethernetMac,
        barcode: data.barcode,
        deviceType: data.deviceType,
        deviceSubType: data.deviceSubType,
        removed: data.removed,
        organizationName: data.organizationName,
        cumulativeUsageInSecs: data.cumulativeUsageInSecs ?? null,
        mode: data.desiredMode,
        lastPoll: data.lastPoll,
        uvgiType: data.uvgiType,
        serialNumber: data.serialNumber,
        desiredOnTime: prepareValueForDeviceDetails(
          'onTime',
          data.desiredOnTime
        ),
        desiredPower: data.desiredPower,
        desiredSafetyDelay: data.desiredSafetyDelay,
        desiredOperationWindowSize: data.desiredOperationWindowSize,
        desiredOperationWindowRuntimeMax: data.desiredOperationWindowRuntimeMax,
        desiredDailyMaxRuntime: data.desiredDailyMaxRuntime,
        onTime: prepareValueForDeviceDetails('onTime', data.onTime),
        power: data.power,
        safetyDelay: data.safetyDelay,
        dailyMaxRuntime: data.dailyMaxRuntime,
        operationWindowSize: data.operationWindowSize,
        operationWindowRuntimeMax: data.operationWindowRuntimeMax,
        days: prepareValueForDeviceDetails('days', data.days),
        desiredDays: prepareValueForDeviceDetails('days', data.desiredDays),
        dailyStartTime: prepareValueForDeviceDetails(
          'dailyStartTime',
          data.dailyStartTime
        ),
        desiredDailyStartTime: prepareValueForDeviceDetails(
          'dailyStartTime',
          data.desiredDailyStartTime
        ),
        dailyEndTime: prepareValueForDeviceDetails(
          'dailyEndTime',
          data.dailyEndTime
        ),
        desiredDailyEndTime: prepareValueForDeviceDetails(
          'dailyEndTime',
          data.desiredDailyEndTime
        ),
        lastSuccessDeviceCommandTime: data.lastSuccessDeviceCommandTime,
        keepLedAlways: !!data.keepLedAlways,
        desiredKeepLedAlways: !!data.desiredKeepLedAlways,
      }
    },
    [deviceDetailsActions.actionTypes.UPDATE_SETTINGS_SUCCEEDED]: (state) => ({
      ...state,
      error: '',
    }),
    [deviceDetailsActions.actionTypes.UPDATE_EXTENDED_DEVICE_SUCCEEDED]: (
      state
    ) => ({
      ...state,
      error: '',
    }),
    [deviceDetailsActions.actionTypes.UPDATE_EXTENDED_DEVICE_FAILED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        error: payload.message,
      }
    },
    [deviceDetailsActions.actionTypes.SET_DEVICE_ID]: (state, payload) => {
      return {
        ...state,
        deviceId: payload.id,
      }
    },
    [deviceDetailsActions.actionTypes.RESET_ERROR]: (state, payload) => {
      return {
        ...state,
        error: '',
      }
    },
    [deviceDetailsActions.actionTypes.GET_EXT_DEVICE_TYPES_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        extDeviceTypes: prepareTypes(payload.types),
      }
    },
    [deviceDetailsActions.actionTypes.GET_EXT_DEVICE_SUB_TYPES_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        extDeviceSubTypes: prepareTypes(payload.subTypes),
      }
    },
    [deviceDetailsActions.actionTypes.GET_NETWORK_DEVICE_TYPES_SUCCEEDED]: (
      state,
      payload
    ) => ({
      ...state,
      networkDeviceTypes: prepareTypes(payload.types),
    }),
    [GET_SENSOR_DEVICE_TYPES_SUCCEEDED.getType()]: (state, payload) => ({
      ...state,
      sensorDeviceTypes: prepareTypes(payload.types),
    }),
    [deviceDetailsActions.actionTypes.GET_DEVICE_RSSI_SUCCEEDED]: (
      state,
      payload
    ) => {
      const { data } = payload

      return {
        ...state,
        communicationInfo: extractRSSIs(data),
      }
    },
    [deviceDetailsActions.actionTypes.GET_DEVICE_RSSI_FAILED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        error: payload.message,
        deviceByIMEI: null,
        isLoading: false,
      }
    },
  },
  initialState
)
