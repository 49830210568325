const DEFAULT_DEVICE_INFO = {
  commDeviceName: '',
  measurementDate: new Date(),
  isCurrentTime: true,
  measurementUnit: 'DBM',
  measurementValue: 0,
  mac: '',
}

export const initialState = {
  isSubmitted: false,
  transactionId: '',
  deviceName: '',
  locationId: '',
  location: { name: '', id: 0 },
  messageTypeText: '',
  useCurrentTime: true,
  deviceDateTime: new Date(),
  comment: 'web-emulator',

  imei: '',
  imeiCopy: '',
  softwareRevision: '',
  hardwareVersion: '',
  firmwareVersion: '',
  currentRunTime: 0,
  networkMethod: '',
  commonDevicesInfo: [{ ...DEFAULT_DEVICE_INFO }],
  certificatesInfo: {
    certificateId: '',
    publicKeyHash: '',
    privateKeyHash: '',
    certificateHash: '',
  },
}

const LABELS = {
  STS021: 'STS021 - Hub device statistics',
  STS031: 'STS031 - Hub-Relay device statistics',
}

export const EVENTS_VALUES = {
  STS021: 'sts021',
  STS031: 'sts031',
}

export const NETWORK_METHODS = {
  LTE: 'LTE',
  WIFI: 'WIFI',
  ETH: 'Ethernet',
}

export const messageConstants = [
  {
    label: LABELS.STS021,
    value: EVENTS_VALUES.STS021,
    code: EVENTS_VALUES.STS021,
  },
  {
    label: LABELS.STS031,
    value: EVENTS_VALUES.STS031,
    code: EVENTS_VALUES.STS031,
  },
]

export const paramsFields = [
  'deviceName',
  'locationId',
  'messageTypeText',
  'deviceDateTime',
  'softwareRevision',
  'hardwareVersion',
  'firmwareVersion',
  'currentRunTime',
]

export const commonRequiredFields = [...paramsFields]

export const ERROR = 'Not valid object in metaInfo field'

export const NET_LABELS = {
  softwareRevision: 'Software Revision',
  hardwareVersion: 'Hardware Version',
  firmwareVersion: 'Firmware Version',
  currentRuntime: 'Current Runtime',
  networkMethod: 'Network Method',
}
