import { Box, Typography } from '@material-ui/core'

import { useStyles } from './style'

type ContainerWithTitleProps = {
  children?: React.ReactNode
  title: string
  fullWidth?: boolean
}

const ContainerWithTitle = ({
  children,
  fullWidth,
  title,
}: ContainerWithTitleProps) => {
  const styles = useStyles()

  return (
    <Box className={styles.defaultContainer} width={fullWidth ? '100%' : '50%'}>
      <Box textAlign="left" margin="32px 0">
        <Typography variant="h3">{title}</Typography>
      </Box>
      <div className={fullWidth ? '' : styles.childrenContainer}>
        {children}
      </div>
    </Box>
  )
}

export default ContainerWithTitle
