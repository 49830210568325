export const TABLE_COLUMNS = {
  serialNumber: 'Serial Number',
  deviceType: 'Device Type',
  deviceSubType: 'Device Sub Type',
}

export const MODAL_TEXT = {
  title: 'Remove Device from Allow List',
  description:
    'Are you sure you want to remove this device from the allow list?',
  confirm: 'Confirm',
  cancel: 'Cancel',
}
