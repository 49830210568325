import {
  defaultFilterState,
  initialNumbersOfTypes,
} from './devicesList-constants'

import deviceDetailsActions from '../../Details/services/deviceDetails-actions'
import devicesListActions from './devicesList-actions'
import { transformTotalsArrayToObject } from './devicesList-service'

type DeviceListState = {
  isLoading: boolean
  devicesList: any[]
  query: string
  totalCount: number
  totalsByType: { [x: string]: number }
  loadDevicesError: string
  updateCertificateError: string
  extTypesInfo: any
  page: number
  rowsPerPage: number
  filterState: {
    serialNumber: string
    organizationName: string
    imei: string
  }
  abilities: {
    cleanup: boolean
    delete: boolean
    updateCertificate: boolean
    deleteAccount: boolean
    hideDetails: boolean
  }
  bulkLoadError: string
  totalsByTypeReceived: boolean
  error: any
  bulkLoadFailed: number
  bulkLoadTotal: number
  deviceByIMEI: {
    serialNumber: string
  } | null
}

export const devicesListReducer = (
  state = {
    isLoading: true,
    devicesList: [],
    query: '',
    totalCount: 0,
    totalsByType: initialNumbersOfTypes,
    loadDevicesError: '',
    updateCertificateError: '',
    extTypesInfo: {},

    page: 0,
    rowsPerPage: 30,
    filterState: defaultFilterState,

    abilities: {
      cleanup: false,
      delete: false,
      updateCertificate: false,
      deleteAccount: false,
      hideDetails: true,
    },
    bulkLoadError: '',
    totalsByTypeReceived: false,
    error: null,
    bulkLoadFailed: 0,
    bulkLoadTotal: 0,
    deviceByIMEI: null,
  } as DeviceListState,
  action: any
) => {
  switch (action.type) {
    case devicesListActions.actionTypes.LOAD_DEVICES: {
      return {
        ...state,
        devicesList: [],
        query: action.payload.query,
        isLoading: true,
        loadDevicesError: '',
        totalCount: 0,
      }
    }
    case devicesListActions.actionTypes.LOAD_DEVICES_SUCCEEDED: {
      const {
        devices: { items, totalCount },
        deviceType,
      } = action.payload

      return {
        ...state,
        devicesList: items,
        totalCount,
        isLoading: false,
        loadDevicesError: '',
        totalsByType: {
          ...state.totalsByType,
          [deviceType]: totalCount,
        },
      }
    }
    case devicesListActions.actionTypes.LOAD_DEVICES_FAILED: {
      return {
        ...state,
        loadDevicesError: action.payload.message,
        isLoading: false,
      }
    }
    case devicesListActions.actionTypes.GET_CLEANUP_ABILITY:
    case devicesListActions.actionTypes.UPDATE_CERTIFICATE: {
      return {
        ...state,
        error: '',
        updateCertificateError: '',
        isLoading: true,
      }
    }
    case devicesListActions.actionTypes.DELETE_DEVICE: {
      return {
        ...state,
        error: '',
        isLoading: true,
      }
    }
    case devicesListActions.actionTypes.DELETE_DEVICE_SUCCEEDED: {
      return {
        ...state,
        isLoading: false,
        page: 0,
        devicesList: [],
        totalCount: 0,
        totalsByTypeReceived: false,
      }
    }
    case devicesListActions.actionTypes.DELETE_DEVICE_FAILED:
    case devicesListActions.actionTypes.FULL_CLEANUP_DEVICE_BY_ID_FAILED:
    case devicesListActions.actionTypes.GET_CLEANUP_ABILITY_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        deviceByIMEI: null,
        isLoading: false,
      }
    }
    case devicesListActions.actionTypes.GET_CLEANUP_ABILITY_SUCCEEDED: {
      return {
        ...state,
        error: '',
        abilities: action.payload.abilities,
        isLoading: false,
      }
    }
    case devicesListActions.actionTypes.BULK_UPLOAD_DEVICES: {
      return {
        ...state,
        bulkLoadError: '',
      }
    }
    case devicesListActions.actionTypes.BULK_UPLOAD_DEVICES_SUCCEEDED: {
      const fails = action.payload.response?.fails
      const total = action.payload.response?.total

      return {
        ...state,
        bulkLoadFailed: fails,
        bulkLoadTotal: total,
        bulkLoadError: '',
        totalsByTypeReceived: false,
      }
    }
    case devicesListActions.actionTypes.BULK_UPLOAD_DEVICES_FAILED:
    case devicesListActions.actionTypes.DOWNLOAD_CSV_EXAMPLE_FAILED: {
      return {
        ...state,
        bulkLoadError: action.payload.message,
      }
    }
    case devicesListActions.actionTypes.RESET_BULK_UPLOAD_ERRORS: {
      return {
        ...state,
        bulkLoadError: '',
      }
    }
    case devicesListActions.actionTypes.SET_PAGE: {
      const { page } = action.payload

      return {
        ...state,
        page,
      }
    }
    case devicesListActions.actionTypes.TOGGLE_SORT:
      const { sort } = action.payload

      return {
        ...state,
        filterState: {
          ...(state.filterState || {}),
          ...sort,
        },
      }
    case devicesListActions.actionTypes.SET_FILTER_STATE: {
      const { filterState } = action.payload

      return {
        ...state,
        filterState: {
          ...filterState,
          serialNumber: filterState.serialNumber.trim(),
        },
      }
    }
    case devicesListActions.actionTypes.SET_ROWS_PER_PAGE: {
      const { rowsPerPage } = action.payload

      return {
        ...state,
        rowsPerPage,
        page: 0,
        isLoading: true,
        devicesList: [],
        totalCount: 0,
      }
    }
    case devicesListActions.actionTypes.GO_TO_PREVIOUS_PAGE:
    case devicesListActions.actionTypes.GO_TO_NEXT_PAGE: {
      const { newPage } = action.payload

      return {
        ...state,
        page: newPage,
        totalsByTypeReceived: true,
      }
    }
    case devicesListActions.actionTypes.APPLY_DEVICE_FILTER: {
      return {
        ...state,
        page: 0,
        isLoading: true,
        devicesList: [],
        totalCount: 0,
      }
    }
    case devicesListActions.actionTypes.LOAD_DEVICES_TOTALS_SUCCEEDED: {
      const { devicesTypesCount, isExtDevicesTab } = action.payload

      const totalsByType = transformTotalsArrayToObject(
        devicesTypesCount,
        isExtDevicesTab
      )

      return {
        ...state,
        totalsByType: {
          ...state.totalsByType,
          ...totalsByType,
        },
      }
    }
    case devicesListActions.actionTypes.LOAD_DEVICES_TOTALS_FAILED: {
      const { error } = action.payload

      return {
        ...state,
        error,
      }
    }
    case devicesListActions.actionTypes.SET_TOTALS_BY_TYPE_RECEIVED: {
      const { status } = action.payload

      return {
        ...state,
        totalsByTypeReceived: status,
      }
    }
    case devicesListActions.actionTypes.UPDATE_CERTIFICATE_SUCCEEDED: {
      return {
        ...state,
        updateCertificateError: '',
      }
    }
    case devicesListActions.actionTypes.UPDATE_CERTIFICATE_FAILED: {
      return {
        ...state,
        isLoading: false,
        updateCertificateError: action.payload.message,
      }
    }
    case deviceDetailsActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED: {
      return {
        ...state,
        page: 0,
        devicesList: [],
        totalCount: 0,
        totalsByTypeReceived: false,
      }
    }
    case devicesListActions.actionTypes.RESET_ERROR: {
      return {
        ...state,
        error: '',
      }
    }
    case devicesListActions.actionTypes.GET_EXT_DEVICES_TYPES_INFO_SUCCEEDED: {
      const { extTypesInfo } = action.payload

      return {
        ...state,
        extTypesInfo,
      }
    }
    case devicesListActions.actionTypes.GET_EXT_DEVICES_TYPES_INFO_FAILED: {
      const { message } = action.payload

      return {
        ...state,
        error: message,
      }
    }
    default:
      return state
  }
}
