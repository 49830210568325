import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  tableContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    '& table': {
      width: '100%',
    },
  },
  tableTitle: {
    margin: '20px 10px 20px 0',
  },
  extendedTableContainer: {
    '& table': {
      width: '100%',
    },
  },
})

export const jsonTableStyles = {
  rootElement: {
    width: '50%',
  },
}
