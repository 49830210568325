import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  treeWrapper: {
    marginTop: '20px',
    '& > button': {
      marginBottom: '10px',
    },
  },
}))
