import { createAction } from 'redux-act'

const sharedDevicesActions = {}

sharedDevicesActions.actionTypes = {
  RESET_ERROR_AND_DEVICE: '{Devices} Reset Error and Device by IMEI',
  RESET_ANOTHER_TYPE_COUNT: '{Devices} Reset Another Type Count',

  GET_PORTAL_ACTIVITY: '{Devices} Get Portal Activity',
  GET_PORTAL_ACTIVITY_SUCCEEDED: '{Devices} Get Portal Activity Succeeded',
  GET_PORTAL_ACTIVITY_FAILED: '{Devices} Get Portal Activity Failed',

  GET_DEVICE_EVENT_BY_ID: '{Devices} Get Device Event By Id',
  GET_DEVICE_EVENT_BY_ID_SUCCEEDED:
    '{Devices} Get Device Event By Id Succeeded',
  GET_DEVICE_EVENT_BY_ID_FAILED: '{Devices} Get Device Event By Id Failed',

  SET_DEVICE_TYPE: '{Devices} Set Device Type',

  UPDATE_DEVICE_SUB_TYPE: '{Devices} Update Device Type',
  UPDATE_DEVICE_SUB_TYPE_SUCCEEDED: '{Devices} Update Device Type Succeeded',
  UPDATE_DEVICE_SUB_TYPE_FAILED: '{Devices} Update Device Type Failed',

  LOAD_DATA_FROM_REDUX_STORE: '{Devices} Load Data From Redux Store',

  CHECK_IMEI: '{Devices} Check IMEI',
  CHECK_IMEI_SUCCEEDED: '{Devices} Check IMEI Succeeded',
  CHECK_IMEI_FAILED: '{Devices} Check IMEI Failed',
}

export const GET_DEVICE_SETTINGS_HISTORY = createAction(
  '{Devices} Get Device Settings History',
  (id) => ({ id })
)

export const GET_DEVICE_SETTINGS_HISTORY_FAILED = createAction(
  '{Devices} Get Device Settings History Failed',
  (message) => ({ message })
)

export const GET_DEVICE_SETTINGS_HISTORY_SUCCEEDED = createAction(
  '{Devices} Get Device Settings History',
  (settings) => ({ settings })
)

sharedDevicesActions.resetErrorAndDevice = () => {
  return {
    type: sharedDevicesActions.actionTypes.RESET_ERROR_AND_DEVICE,
  }
}

sharedDevicesActions.setDeviceType = (deviceType) => {
  return {
    type: sharedDevicesActions.actionTypes.SET_DEVICE_TYPE,
    payload: {
      deviceType,
    },
  }
}

sharedDevicesActions.resetAnotherTypeCount = () => {
  return {
    type: sharedDevicesActions.actionTypes.RESET_ANOTHER_TYPE_COUNT,
  }
}

sharedDevicesActions.getPortalActivity = (id) => {
  return {
    type: sharedDevicesActions.actionTypes.GET_PORTAL_ACTIVITY,
    payload: {
      id,
    },
  }
}

sharedDevicesActions.getPortalActivitySuccess = (data, id) => {
  return {
    type: sharedDevicesActions.actionTypes.GET_PORTAL_ACTIVITY_SUCCEEDED,
    payload: {
      data,
      id,
    },
  }
}

sharedDevicesActions.getPortalActivityFailed = (message) => {
  return {
    type: sharedDevicesActions.actionTypes.GET_PORTAL_ACTIVITY_FAILED,
    payload: {
      message,
    },
  }
}

sharedDevicesActions.getDeviceEventById = (id) => {
  return {
    type: sharedDevicesActions.actionTypes.GET_DEVICE_EVENT_BY_ID,
    payload: {
      id,
    },
  }
}

sharedDevicesActions.getDeviceEventByIdSuccess = (data) => {
  return {
    type: sharedDevicesActions.actionTypes.GET_DEVICE_EVENT_BY_ID_SUCCEEDED,
    payload: {
      data,
    },
  }
}

sharedDevicesActions.getDeviceEventByIdFailed = (message) => {
  return {
    type: sharedDevicesActions.actionTypes.GET_DEVICE_EVENT_BY_ID_FAILED,
    payload: {
      message,
    },
  }
}

sharedDevicesActions.updateDeviceType = (type, id) => {
  return {
    type: sharedDevicesActions.actionTypes.UPDATE_DEVICE_SUB_TYPE,
    payload: {
      type,
      id,
    },
  }
}

sharedDevicesActions.updateDeviceSuccess = (type) => {
  return {
    type: sharedDevicesActions.actionTypes.UPDATE_DEVICE_SUB_TYPE_SUCCEEDED,
    payload: {
      type,
    },
  }
}

sharedDevicesActions.updateDeviceTypeFailed = (message) => {
  return {
    type: sharedDevicesActions.actionTypes.UPDATE_DEVICE_SUB_TYPE_FAILED,
    payload: {
      message,
    },
  }
}

sharedDevicesActions.loadDataFromReduxStore = (status) => {
  return {
    type: sharedDevicesActions.actionTypes.LOAD_DATA_FROM_REDUX_STORE,
    payload: {
      status,
    },
  }
}

sharedDevicesActions.checkImei = (imei, type) => {
  return {
    type: sharedDevicesActions.actionTypes.CHECK_IMEI,
    payload: {
      imei,
      type,
    },
  }
}

sharedDevicesActions.checkImeiSuccess = (device) => {
  return {
    type: sharedDevicesActions.actionTypes.CHECK_IMEI_SUCCEEDED,
    payload: {
      device,
    },
  }
}

sharedDevicesActions.checkImeiFailed = (message) => {
  return {
    type: sharedDevicesActions.actionTypes.CHECK_IMEI_FAILED,
    payload: {
      message,
    },
  }
}

export default sharedDevicesActions
