import { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Loader from '../../components/_shared/loader/Loader'
import { checkConnection } from '../../services/root/root-service'
import CommandsReceiver from './components/CommandsReceiver/CommandsReceiver'
import DevicesSelect from './components/DevicesSelect/DevicesSelect'
import OrganizationAutocomplete from './components/orgAutocomplete/OrganizationAutocomplete'
import actions from './services/emulator-actions'
import { EMULATOR_PAGE_LABELS } from './services/emulator-constants'
import { getDeviceIdByImei } from './services/emulator-service'
import styles from './style'

const EmulatorPage = (props) => {
  const {
    getDevicePortalURL,
    devicePortalURL,
    loadUVGIDevices,
    devices,
    existingCommands,
    loading,
    loadOrganizations,
    classes,
  } = props

  const [device, setDevice] = useState('')
  const [organization, setOrganization] = useState(null)

  useEffect(() => {
    loadOrganizations()
    getDevicePortalURL()
    // eslint-disable-next-line
  }, [])

  const deviceId = getDeviceIdByImei(device, devices)

  const handleOrgChange = (event, newValue) => {
    setOrganization(newValue)
    setDevice('')
    loadUVGIDevices(newValue)
  }

  return (
    <Loader isLoading={loading}>
      <div className={classes.emulatorWrapper}>
        <div className={classes.emulatorTitleWrapper}>
          <Typography variant="h2">{EMULATOR_PAGE_LABELS.pageTitle}</Typography>
        </div>

        <OrganizationAutocomplete
          organization={organization}
          onChange={handleOrgChange}
        />
        <DevicesSelect
          devices={devices}
          device={device}
          setDevice={setDevice}
          disabled={!organization}
        />

        {device && (
          <div className={classes.commandsWrapper}>
            <CommandsReceiver
              existingCommands={existingCommands}
              deviceImei={device}
              devicePortalURL={devicePortalURL}
              deviceId={deviceId}
            />
          </div>
        )}
      </div>
    </Loader>
  )
}

const mapStateToProps = (state) => ({
  devicePortalURL: state.emulatorReducer.devicePortalURL,
  devices: state.emulatorReducer.devices,
  existingCommands: state.emulatorReducer.existingCommands,
  loading: state.emulatorReducer.loading,
})

const mapDispatchToProps = (dispatch) => ({
  getDevicePortalURL: () =>
    dispatch(checkConnection(actions.getDevicePortalURL)),
  loadUVGIDevices: (org) =>
    dispatch(checkConnection(() => actions.loadUVGIDevices(org))),
  loadOrganizations: () => dispatch(checkConnection(actions.loadOrganizations)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EmulatorPage))
