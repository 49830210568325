import map from 'lodash/map'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import { GET_WIFI_CREDENTIALS } from '../../services/organizationDetails-actions'
import { CONTAINER_TITLES } from '../../services/organizationDetails-constants'
import ContainerWithTitle from '../ContainerWithTitle/ContainerWithTitle'
import AddCredentialButton from './AddCredential/AddCredentialButton'
import { useStyles } from './style'
import WiFiCredentialCard from './WiFiCredentialCard/WiFiCredentialCard'

type WifiCredentialsProps = {
  organizationId: string
  isReadOnly: boolean
}

const WiFiCredentials = ({
  organizationId,
  isReadOnly,
}: WifiCredentialsProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const wifiCredentialsList = useAppSelector(
    (state) => state.organizationDetailsReducer.wifiCredentialsList
  )

  const getWiFiCredentials = (organizationId: string) =>
    dispatch(GET_WIFI_CREDENTIALS(organizationId))

  useEffect(() => {
    getWiFiCredentials(organizationId)
  }, [organizationId])

  return (
    <ContainerWithTitle title={CONTAINER_TITLES.wifi}>
      {!isReadOnly && (
        <div className={styles.buttonContainer}>
          <AddCredentialButton organizationId={organizationId} />
        </div>
      )}
      <div className={styles.credentialsList}>
        {map(wifiCredentialsList, (credential) => (
          <WiFiCredentialCard
            organizationId={organizationId}
            key={credential.id}
            credential={credential}
            isReadOnly={isReadOnly}
          />
        ))}
      </div>
    </ContainerWithTitle>
  )
}

export default WiFiCredentials
