import { COLORS } from '../../../services/constants'

const style = () => ({
  closeIcon: {
    position: 'absolute',
    left: '16px',
    top: '16px',
    padding: 0,
    color: COLORS.BLACK,
  },
})

export default style
