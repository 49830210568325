import * as netActions from './netEmulator-actions'

import { Device } from 'containers/Devices/services/devices-types'
import { getRoomsListFromTree } from 'containers/EventsEmulator/_shared/services/emulator-service'
import { createReducer } from 'redux-act'
import { Location } from 'types/locationTypes'
import { Payload } from 'types/storeTypes'

const initialState = {
  deviceMessage: null as null | Object,
  error: '',
  devices: [] as Device[],
  messageLocations: '',
  locations: [] as Location[],
}

export const netEmulatorReducer = createReducer(
  {
    [netActions.NET_LOAD_MESSAGE_DEVICES_SUCCESS.getType()]: (
      state,
      devices: Payload<typeof netActions.NET_LOAD_MESSAGE_DEVICES_SUCCESS>
    ) => ({
      ...state,
      devices,
    }),
    [netActions.NET_LOAD_ALL_LOCATIONS_SUCCESS.getType()]: (
      state,
      locations: Payload<typeof netActions.NET_LOAD_ALL_LOCATIONS_SUCCESS>
    ) => ({
      ...state,
      locations: getRoomsListFromTree(locations),
    }),
    [netActions.NET_SEND_DEVICE_MESSAGE_ERROR.getType()]: (
      state,
      error: Payload<typeof netActions.NET_SEND_DEVICE_MESSAGE_ERROR>
    ) => ({
      ...state,
      error,
    }),
    [netActions.NET_SEND_DEVICE_MESSAGE_SUCCESS.getType()]: (
      state,
      payload: Payload<typeof netActions.NET_SEND_DEVICE_MESSAGE_SUCCESS>
    ) => ({ ...state, deviceMessage: payload }),
  },
  initialState
)
