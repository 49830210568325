import { InputLabel, MenuItem, Select } from '@material-ui/core'
import {
  API_DEVICE_TYPE_DISPLAY,
  API_DEVICE_TYPE_MAPPER,
} from 'services/constants'

import FormControl from '@material-ui/core/FormControl'
import map from 'lodash/map'
import { ADD_DEVICE_LABELS } from '../../Details/services/deviceDetails-constants'

const DeviceTypeSelect = (props) => {
  const { deviceType, setDeviceType, devicesTypes } = props

  const handleDeviceTypeChange = (event) => {
    setDeviceType(event.target.value)
  }

  return (
    <FormControl variant="outlined" fullWidth required>
      <InputLabel>{ADD_DEVICE_LABELS.deviceType}</InputLabel>
      <Select
        name="deviceType"
        label={ADD_DEVICE_LABELS.deviceType}
        value={deviceType}
        onChange={handleDeviceTypeChange}
        required
      >
        {map(devicesTypes, (item) => (
          <MenuItem key={item.label} value={API_DEVICE_TYPE_MAPPER[item.label]}>
            {API_DEVICE_TYPE_DISPLAY[item.label]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default DeviceTypeSelect
