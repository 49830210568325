import { Grid, Snackbar } from '@material-ui/core'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import {
  autoHideDuration,
  DEVICE_SUBTYPE,
  DEVICE_TYPE,
  DEVICE_TYPE_LABELS,
} from '../../../../../services/constants'
import {
  DEVICE_DETAILS,
  DEVICE_DETAILS_ALERTS,
  SNACKBAR_MESSAGES,
} from '../services/deviceDetails-constants'

import Typography from '@material-ui/core/Typography'
import { Alert } from '@mui/material'
import classNames from 'classnames'
import { BatteryLevel } from 'containers/Devices/components/BatteryLevel/BatteryLevel'
import { GET_ORGANIZATION } from 'containers/Organizations/components/Details/services/organizationDetails-actions'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { yyyyMMDDHHmmFormat } from 'services/dateFormat-service'
import { useAppSelector } from 'utils/reduxHelpers'
import rootActions from '../../../../../services/root/root-actions'
import { checkConnection } from '../../../../../services/root/root-service'
import { isArc } from '../../../services/devices-service'
import { FAR_UVC_TYPES } from '../../List/services/devicesList-constants'
import deviceDetailsActions from '../services/deviceDetails-actions'
import ChangeDeviceType from './ChangeDeviceType'
import DeviceActions from './DeviceActions'
import DeviceEventsTable from './deviceEventsTable/DeviceEventsTable'
import DevicePortalActivity from './devicePortalActivity/DevicePortalActivity'
import DeviceSettings from './DeviceSettings/DeviceSettings'
import DeviceSettingsHistoryTable from './DeviceSettingsHistoryTable/DeviceSettingsHistoryTable'
import { EnableDeviceLogging } from './EnableDeviceLogging'
import { useStyles } from './style'

type DeviceDetailsDataProps = {
  serialNumber: string
  imei: string
  deviceId: string
  isEdit?: boolean
  children?: ReactNode
}

const DeviceDetailsValue = ({
  keyName,
  children,
  id,
}: {
  keyName: string
  children: ReactNode
  id?: string
}) => {
  const style = useStyles()

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={4}
      xl={2}
      className={style.deviceDetailsGridChild}
      id={id}
    >
      <Typography variant="inherit" className={style.detailKey}>
        {keyName}
      </Typography>
      <Typography variant="inherit" className={style.detailValue}>
        {children}
      </Typography>
    </Grid>
  )
}

const DeviceDetailsData = ({
  serialNumber,
  imei,
  deviceId,
  isEdit = false,
  children,
}: DeviceDetailsDataProps) => {
  const dispatch = useDispatch()
  const getFullDeviceData = () =>
    dispatch(checkConnection(() => deviceDetailsActions.getFullDeviceData()))
  const loadSubTypesForDevice = (deviceType: any) =>
    dispatch(
      checkConnection(() => rootActions.loadSubTypesForDevice(deviceType))
    )

  const deviceType = useAppSelector(
    (state) =>
      state.devicesReducer.deviceDetailsReducer
        .deviceType as keyof typeof DEVICE_TYPE_LABELS
  )
  const wifiMac = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.wifiMac
  )
  const macAddress = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.macAddress
  )
  const hardwareId = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.hardwareId
  )
  const created = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.created
  )
  const certificateCreated = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.certificateCreated
  )
  const initialized = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.initialized
  )
  const removed = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.removed
  )
  const outputPower = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.outputPower
  )
  const communicationInfo = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.communicationInfo
  )
  const isARCChangeRevisionAvailable = useAppSelector(
    (state) =>
      state.devicesReducer.sharedDevicesReducer.isARCChangeRevisionAvailable
  )
  const deviceEvents = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.deviceEvents
  )
  const organizationId = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.organizationId
  )
  const organization = useAppSelector(
    (state) => state.organizationDetailsReducer.details
  )

  const loraId = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.loraId
  )
  const bluetoothId = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.bluetoothId
  )
  const mcuUid = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.mcuUid
  )
  const deviceEui = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.deviceEui
  )
  const initialFirmwareVersion = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.initialFirmwareVersion
  )
  const baseMac = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.baseMac
  )
  const softApMac = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.softApMac
  )
  const ethernetMac = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.ethernetMac
  )
  const barcode = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.barcode
  )
  const cumulativeUsageInSecs = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.cumulativeUsageInSecs
  )
  const lastPoll = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.lastPoll
  )
  const deviceSubType = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.deviceSubType
  )
  const uvgiType = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.uvgiType
  )

  const style = useStyles()

  const isNotBeam = deviceSubType !== DEVICE_SUBTYPE.UR_UVGI.value

  useEffect(() => {
    if (organizationId) {
      dispatch(checkConnection(() => GET_ORGANIZATION(organizationId)))
    }
  }, [organizationId])

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(
    SNACKBAR_MESSAGES.updated
  )
  const isUVGI =
    deviceType === DEVICE_TYPE.UR_UVGI.type ||
    deviceType === DEVICE_TYPE.FAR_UVC.type

  const batteryVoltage = useMemo(() => {
    let voltage = -1
    deviceEvents.reduce((prevTS, event) => {
      if (
        prevTS <= event.serverTimestamp &&
        event.metaInfo?.vbat !== undefined
      ) {
        voltage = event.metaInfo.vbat
      }
      return event.serverTimestamp
    }, 0)
    return voltage
  }, [deviceEvents])

  const isArcDevice = isArc(deviceType)
  const isExternal = deviceType === DEVICE_TYPE.EXT_DEVICE.type
  const isNetwork =
    deviceType === DEVICE_TYPE.NETWORK_DEVICE.type ||
    deviceType === DEVICE_TYPE.HUB.type ||
    deviceType === DEVICE_TYPE.HUB_RELAY.type
  const isSensor =
    deviceType === DEVICE_TYPE.SENSOR.type ||
    deviceType === DEVICE_TYPE.PIRUV.type
  const isVive =
    deviceType === DEVICE_TYPE.FAR_UVC.type ||
    deviceSubType === DEVICE_TYPE.FAR_UVC.type ||
    deviceSubType === DEVICE_TYPE.EON.type
  const outputPowerValue =
    isUndefined(outputPower) || isNull(outputPower)
      ? DEVICE_DETAILS.noInformation
      : `${outputPower}%`

  useEffect(() => {
    if (isARCChangeRevisionAvailable && isArcDevice) {
      loadSubTypesForDevice(DEVICE_TYPE.UVI_ARC.type)
    }
  }, [isARCChangeRevisionAvailable, isArcDevice])

  useEffect(() => {
    if (deviceId) {
      dispatch(deviceDetailsActions.setDeviceId(deviceId))
    }
  }, [deviceId])

  useEffect(() => {
    if (deviceId && !isEdit) {
      getFullDeviceData()
    }
  }, [deviceId, isEdit])

  const onOpenSnackbar = () => {
    setOpenSnackbar(true)
  }

  const onCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        className={classNames(
          style.deviceDetailsGrid,
          'device-details-container'
        )}
      >
        <input type="hidden" id="device-removed" value={String(removed)} />
        <DeviceDetailsValue
          keyName={DEVICE_DETAILS.serialNumber}
          id="serial-number"
        >
          {serialNumber || DEVICE_DETAILS.noInformation}
        </DeviceDetailsValue>
        <DeviceDetailsValue
          keyName={DEVICE_DETAILS.deviceType}
          id="device-type"
        >
          {isARCChangeRevisionAvailable && isArcDevice ? (
            <ChangeDeviceType initialDeviceType={deviceSubType} id={deviceId} />
          ) : deviceType === DEVICE_DETAILS.noInformation ? (
            DEVICE_DETAILS.noInformation
          ) : isVive ? (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            FAR_UVC_TYPES[uvgiType! as keyof typeof FAR_UVC_TYPES]?.label || ''
          ) : (
            DEVICE_TYPE_LABELS[deviceType]
          )}
        </DeviceDetailsValue>
        {isNotBeam && !isArc && (
          <DeviceDetailsValue keyName={DEVICE_DETAILS.deviceSubType}>
            {deviceType === DEVICE_DETAILS.noInformation
              ? DEVICE_DETAILS.noInformation
              : DEVICE_TYPE_LABELS[deviceType]}
          </DeviceDetailsValue>
        )}
        <DeviceDetailsValue keyName={DEVICE_DETAILS.deviceId} id="device-id">
          {deviceId || DEVICE_DETAILS.noInformation}
        </DeviceDetailsValue>
        <DeviceDetailsValue
          keyName={DEVICE_DETAILS.organizationName}
          id="organization-name"
        >
          {organization?.name || DEVICE_DETAILS.noInformation}
        </DeviceDetailsValue>
        <DeviceDetailsValue
          keyName={DEVICE_DETAILS.organizationId}
          id="organization-id"
        >
          {organization?.id || DEVICE_DETAILS.noInformation}
        </DeviceDetailsValue>
        {!isExternal && !isNetwork && !isSensor && (
          <DeviceDetailsValue keyName={DEVICE_DETAILS.imei} id="imei">
            {imei || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {(isUVGI || isArcDevice) && (
          <DeviceDetailsValue keyName={DEVICE_DETAILS.lastPoll} id="last-poll">
            {lastPoll
              ? moment(lastPoll).format(yyyyMMDDHHmmFormat)
              : DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {isUVGI && (
          <DeviceDetailsValue keyName={DEVICE_DETAILS.wifiMac} id="wifi-mac">
            {wifiMac || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {isUVGI && (
          <DeviceDetailsValue keyName={DEVICE_DETAILS.loraId} id="lora-id">
            {loraId || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {isUVGI && (
          <DeviceDetailsValue
            keyName={DEVICE_DETAILS.bluetoothId}
            id="bluetooth-id"
          >
            {bluetoothId || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {isUVGI && (
          <DeviceDetailsValue keyName={DEVICE_DETAILS.mcuUid} id="mcu-uid">
            {mcuUid || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {isUVGI && (
          <DeviceDetailsValue
            keyName={DEVICE_DETAILS.deviceEui}
            id="device-eui"
          >
            {deviceEui || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {isUVGI && (
          <DeviceDetailsValue
            keyName={DEVICE_DETAILS.initialFirmwareVersion}
            id="initial-firmware-version"
          >
            {initialFirmwareVersion || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {isUVGI && (
          <DeviceDetailsValue keyName={DEVICE_DETAILS.baseMac} id="base-mac">
            {baseMac || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {isUVGI && (
          <DeviceDetailsValue
            keyName={DEVICE_DETAILS.softApMac}
            id="soft-ap-mac"
          >
            {softApMac || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {isUVGI && (
          <DeviceDetailsValue
            keyName={DEVICE_DETAILS.ethernetMac}
            id="ethernet-mac"
          >
            {ethernetMac || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {isUVGI && (
          <DeviceDetailsValue keyName={DEVICE_DETAILS.barcode} id="barcode">
            {barcode || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {batteryVoltage !== -1 && (
          <DeviceDetailsValue
            keyName={DEVICE_DETAILS.batteryLevel}
            id="battery-level"
          >
            <BatteryLevel voltage={batteryVoltage} />
          </DeviceDetailsValue>
        )}
        <DeviceDetailsValue keyName={DEVICE_DETAILS.created} id="created-date">
          {created
            ? moment(created).format(yyyyMMDDHHmmFormat)
            : DEVICE_DETAILS.noInformation}
        </DeviceDetailsValue>
        <DeviceDetailsValue
          keyName={DEVICE_DETAILS.certificateCreated}
          id="certificate-created"
        >
          {certificateCreated ? 'Yes' : 'No'}
        </DeviceDetailsValue>
        <DeviceDetailsValue
          keyName={DEVICE_DETAILS.initialized}
          id="initialized"
        >
          {initialized ? 'Yes' : 'No'}
        </DeviceDetailsValue>

        {(isNetwork || isSensor) && (
          <DeviceDetailsValue
            keyName={DEVICE_DETAILS.macAddress}
            id="mac-address"
          >
            {macAddress || hardwareId || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {isUVGI && (
          <DeviceDetailsValue
            keyName={DEVICE_DETAILS.outputPower}
            id="output-power"
          >
            {outputPowerValue || DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {(isUVGI || isArcDevice) && (
          <DeviceDetailsValue
            keyName={DEVICE_DETAILS.cumulativeUsage}
            id="bulbLifetimeUse"
          >
            {cumulativeUsageInSecs !== null && cumulativeUsageInSecs > 0
              ? `${(cumulativeUsageInSecs / 60 / 60).toFixed(2)} hours`
              : DEVICE_DETAILS.noInformation}
          </DeviceDetailsValue>
        )}
        {map(communicationInfo, (item) => (
          <DeviceDetailsValue
            key={item.name}
            keyName={item.name}
            id={item.name.toLowerCase()}
          >{`${item.value} ${item.measurementUnit}`}</DeviceDetailsValue>
        ))}
      </Grid>

      <DeviceActions
        deviceId={deviceId}
        serialNumber={serialNumber}
        onOpenSnackbar={onOpenSnackbar}
        setSnackbarMessage={setSnackbarMessage}
      />
      {isUVGI && <EnableDeviceLogging deviceId={deviceId} />}
      {children}

      {!isEdit && isUVGI && (
        <DeviceSettings
          deviceId={deviceId}
          onOpenSnackbar={onOpenSnackbar}
          setSnackbarMessage={setSnackbarMessage}
        />
      )}
      <Alert severity="info" sx={{ my: '2rem' }}>
        {DEVICE_DETAILS_ALERTS.INFO_ALL_TIMES_ARE_UTC}
      </Alert>
      {!isExternal && (
        <>
          <DevicePortalActivity />
          <DeviceEventsTable deviceEvents={deviceEvents} />
        </>
      )}
      {!isExternal && <DeviceSettingsHistoryTable deviceId={deviceId} />}

      <Snackbar
        open={openSnackbar}
        className={style.snackbar}
        autoHideDuration={autoHideDuration}
        onClose={onCloseSnackbar}
        message={snackbarMessage}
      />
    </>
  )
}

export default DeviceDetailsData
