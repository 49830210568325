import { Box, Typography, withStyles } from '@material-ui/core'

import classNames from 'classnames'
import map from 'lodash/map'
import uniqueId from 'lodash/uniqueId'
import { connect } from 'react-redux'
import { FIRMWARES_LABELS } from '../../services/firmwares-constants'
import ChangeDefaultFirmwareButton from './ChangeDefaultFirmwareButton'
import style from './style'

const DefaultFirmwares = (props) => {
  const { allDefaultFirmwares, firmwaresList, classes } = props

  const getDefaultFirmwaresContent = () => {
    return (
      <div>
        {map(allDefaultFirmwares, (deviceType) => (
          <div key={uniqueId()}>
            <Typography variant="h3" className={classes.typeWrapper}>
              {deviceType.deviceType.displayValue}
            </Typography>
            {getSubTypeContent(deviceType)}
          </div>
        ))}
      </div>
    )
  }

  const getSubTypeContent = (deviceType) => {
    return map(deviceType.subtypeInfos, (deviceSubType) => (
      <div key={uniqueId()}>
        <Typography variant="h4" className={classes.subTypeWrapper}>
          {deviceSubType.deviceSubType.displayValue}
          {deviceSubType.deviceSubType.additional
            ? ` (${deviceSubType.deviceSubType.additional})`
            : ''}
        </Typography>
        {getFirmwareTypeContent(deviceType, deviceSubType)}
      </div>
    ))
  }

  const getFirmwareTypeContent = (deviceType, deviceSubType) => {
    return map(deviceSubType.firmwares, (firmware) => {
      const actualFirmware = firmwaresList.find(
        (actualFirmware) =>
          firmware.id === actualFirmware.id && actualFirmware.isDefault
      )

      return (
        <div
          id={`${deviceSubType.deviceSubType.displayValue}-${firmware.firmwareType}`.toLowerCase()}
          className={classes.lineWrapper}
          key={uniqueId()}
        >
          <Box display="flex">
            <Typography
              variant="h5"
              color={!actualFirmware?.version ? 'error' : 'textPrimary'}
              className={classes.firmwareTypeWrapper}
            >
              {firmware.firmwareType}:
            </Typography>
            <Typography
              variant="subtitle1"
              color={!actualFirmware?.version ? 'error' : 'textPrimary'}
              className={classNames(
                classes.firmwareTypeWrapper,
                'firmware-value'
              )}
            >
              {actualFirmware?.version || FIRMWARES_LABELS.versionIsNotSet}
            </Typography>
          </Box>
          <ChangeDefaultFirmwareButton
            deviceType={deviceType.deviceType.value}
            deviceSubType={deviceSubType.deviceSubType.value}
            firmware={firmware}
          />
        </div>
      )
    })
  }

  return (
    <div className={classes.defaultFirmwaresPageContainer}>
      {getDefaultFirmwaresContent()}
    </div>
  )
}

const mapStateToProps = (state) => ({
  allDefaultFirmwares: state.firmwaresReducer.allDefaultFirmwares,
  firmwaresList: state.firmwaresReducer.firmwaresList,
})

export default connect(mapStateToProps)(withStyles(style)(DefaultFirmwares))
