import {
  SET_ERROR,
  UPDATE_LOCATION,
} from '../../../services/organizationDetails-actions'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { RESET_ERROR } from 'containers/Users/services/users-actions'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import { checkConnection } from '../../../../../../../services/root/root-service'
import { EDIT_LOCATION_LABELS } from '../../../services/organizationDetails-constants'
import { validateLocationName } from '../../../services/organizationDetails-service'
import { Location } from '../../../services/organizationDetails-types'
import EditModal from './EditModal'
import { useStyles } from './style'

type EditLocationButtonProps = {
  id: string
  parentId: string
  name: string
  locationType: string
  streetAddress: string
  city: string
  zip: string
  state: string
  width: number
  height: number
  length: number
}

const EditLocationButton = ({
  id,
  name,
  locationType,
  parentId,
  city,
  state,
  zip,
  streetAddress,
  width,
  height,
  length,
}: EditLocationButtonProps) => {
  const [open, setOpen] = useState(false)

  const styles = useStyles()
  const dispatch = useDispatch()

  const error = useAppSelector(
    (state) => state.organizationDetailsReducer.error
  )

  const resetError = () => dispatch(RESET_ERROR())
  const setError = (error: string) => dispatch(SET_ERROR(error))
  const updateLocation = (body: Partial<Location>, onSuccess: () => void) =>
    dispatch(checkConnection(() => UPDATE_LOCATION(body, onSuccess)))

  const modalTitle = EDIT_LOCATION_LABELS.edit + name
  const isProperty = locationType === 'property'

  const onSubmitButton = (value: string, propertyFields: Partial<Location>) => {
    const validationError = validateLocationName(value)

    if (validationError) {
      setError(validationError)
    } else {
      const updatedLocation = isProperty
        ? { id, parentId, locationType, name: value.trim(), ...propertyFields }
        : {
            id,
            parentId,
            locationType,
            name: value.trim(),
            length: propertyFields.length,
            width: propertyFields.width,
            height: propertyFields.height,
          }

      updateLocation(updatedLocation, handleClose)
    }
  }

  const handleOpen = () => {
    resetError()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <IconButton onClick={handleOpen} className={styles.rowIconButton}>
        <EditIcon className={styles.editIcon} />
      </IconButton>
      <EditModal
        isOpen={open}
        onClose={handleClose}
        onSubmit={onSubmitButton}
        modalTitle={modalTitle}
        actionLabel={EDIT_LOCATION_LABELS.button}
        error={error}
        city={city}
        state={state}
        streetAddress={streetAddress}
        zip={zip}
        locationType={locationType}
        name={name}
        width={width}
        height={height}
        length={length}
      />
    </div>
  )
}

export default EditLocationButton
