const styles = (theme) => ({
  dashboardPage: {
    height: 'calc(100vh - 90px)',
  },
  container: {
    paddingTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '70%',
  },
  '@media (min-width: 1224px)': {
    container: {
      width: '30%',
    },
  },
})

export default styles
