const authorizationActions = {}

authorizationActions.actionTypes = {
  LOGIN_TO_APP: '{AUTHORIZATION} User Login',
  LOGIN_TO_APP_SUCCEEDED: '{AUTHORIZATION} User Login Succeeded',
  LOGIN_TO_APP_FAILED: '{AUTHORIZATION} User Login Failed',

  LOGOUT: '{AUTHORIZATION} User Logout',
  LOGOUT_SUCCEEDED: '{AUTHORIZATION} User Logout Succeeded',
  LOGOUT_FAILED: '{AUTHORIZATION} User Logout Failed',

  RESET_PASSWORD: '{AUTHORIZATION} Reset Password',
  RESET_PASSWORD_SUCCEEDED: '{AUTHORIZATION} Reset Password Succeeded',
  RESET_PASSWORD_FAILED: '{AUTHORIZATION} Reset Password Failed',

  CHECK_TEMPORARY_PASSWORD: '{AUTHORIZATION} Check Temporary Password',
  CHECK_TEMPORARY_PASSWORD_SUCCEEDED:
    '{AUTHORIZATION} Check Temporary Password Succeeded',
  CHECK_TEMPORARY_PASSWORD_FAILED:
    '{AUTHORIZATION} Check Temporary Password Failed',

  SETUP_NEW_PASSWORD: '{AUTHORIZATION} Setup New Password',
  SETUP_NEW_PASSWORD_SUCCEEDED: '{AUTHORIZATION} Setup New Password Succeeded',
  SETUP_NEW_PASSWORD_FAILED: '{AUTHORIZATION} Setup New Password Failed',

  UPDATE_PASSWORD: '{AUTHORIZATION} Update Password',
  UPDATE_PASSWORD_SUCCEEDED: '{AUTHORIZATION} Update Password Succeeded',
  UPDATE_PASSWORD_FAILED: '{AUTHORIZATION} Update Password Failed',

  USER_DEACTIVATED: '{AUTHORIZATION} User Deactivated',

  RESET_LOGIN_ERRORS: '{AUTHORIZATION} Reset Login Errors',
}

authorizationActions.login = function (body, onSuccess) {
  return {
    type: authorizationActions.actionTypes.LOGIN_TO_APP,
    payload: {
      body,
      onSuccess,
    },
  }
}

authorizationActions.loginSucceeded = function (data) {
  return {
    type: authorizationActions.actionTypes.LOGIN_TO_APP_SUCCEEDED,
    payload: {
      data,
    },
  }
}

authorizationActions.loginFailed = function (message) {
  return {
    type: authorizationActions.actionTypes.LOGIN_TO_APP_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.logout = function () {
  return {
    type: authorizationActions.actionTypes.LOGOUT,
  }
}

authorizationActions.logoutSucceeded = function () {
  return {
    type: authorizationActions.actionTypes.LOGOUT_SUCCEEDED,
  }
}

authorizationActions.logoutFailed = function (message) {
  return {
    type: authorizationActions.actionTypes.LOGOUT_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.resetPassword = function (login, onSuccess) {
  return {
    type: authorizationActions.actionTypes.RESET_PASSWORD,
    payload: {
      login,
      onSuccess,
    },
  }
}

authorizationActions.resetPasswordSucceeded = function () {
  return {
    type: authorizationActions.actionTypes.RESET_PASSWORD_SUCCEEDED,
  }
}

authorizationActions.resetPasswordFailed = function (message) {
  return {
    type: authorizationActions.actionTypes.RESET_PASSWORD_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.checkTemporaryPassword = function (body, onSuccess) {
  return {
    type: authorizationActions.actionTypes.CHECK_TEMPORARY_PASSWORD,
    payload: {
      body,
      onSuccess,
    },
  }
}

authorizationActions.checkTemporaryPasswordSucceeded = function () {
  return {
    type: authorizationActions.actionTypes.CHECK_TEMPORARY_PASSWORD_SUCCEEDED,
  }
}

authorizationActions.checkTemporaryPasswordFailed = function (message) {
  return {
    type: authorizationActions.actionTypes.CHECK_TEMPORARY_PASSWORD_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.setupNewPassword = function (
  session,
  login,
  password,
  newPassword,
  givenName,
  familyName,
  onSuccess
) {
  return {
    type: authorizationActions.actionTypes.SETUP_NEW_PASSWORD,
    payload: {
      session,
      login,
      password,
      newPassword,
      givenName,
      familyName,
      onSuccess,
    },
  }
}

authorizationActions.setupNewPasswordSucceeded = function () {
  return {
    type: authorizationActions.actionTypes.SETUP_NEW_PASSWORD_SUCCEEDED,
  }
}

authorizationActions.resetLoginErrors = function () {
  return {
    type: authorizationActions.actionTypes.RESET_LOGIN_ERRORS,
  }
}

authorizationActions.setupNewPasswordFailed = function (message) {
  return {
    type: authorizationActions.actionTypes.SETUP_NEW_PASSWORD_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.updatePassword = function ({
  login,
  token,
  newPassword,
  onSuccess,
}) {
  return {
    type: authorizationActions.actionTypes.UPDATE_PASSWORD,
    payload: {
      login,
      token,
      newPassword,
      onSuccess,
    },
  }
}

authorizationActions.updatePasswordSucceeded = function () {
  return {
    type: authorizationActions.actionTypes.UPDATE_PASSWORD_SUCCEEDED,
  }
}

authorizationActions.updatePasswordFailed = function (message) {
  return {
    type: authorizationActions.actionTypes.UPDATE_PASSWORD_FAILED,
    payload: {
      message,
    },
  }
}

authorizationActions.userDeactivated = function () {
  return {
    type: authorizationActions.actionTypes.USER_DEACTIVATED,
  }
}

export default authorizationActions
