import * as moment from 'moment'

import {
  isAllValuesFilledAndPositive,
  isRequiredFieldsFilled,
  isTransactionIdValid,
  prepareMetaInfoRequestBody,
  pushParams,
} from '../../_shared/services/emulator-service'
import {
  EVENTS_CODES,
  EVENTS_VALUES,
  metaInfoValues,
  MOTION_PIR_DEFAULT_VALUES,
} from './uvgiEmulator-constants'

import every from 'lodash/every'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { yyyyMMDDTHHmmssFormat } from '../../../../services/dateFormat-service'
import { REQUIRED_META_INFO_ROWS } from '../../_shared/services/emulator-constants'

const commonRequiredFields = [
  'deviceName',
  'locationId',
  'messageTypeText',
  'deviceDateTime',
]

const mainOperationsFields = [
  ...commonRequiredFields,
  'outputPower',
  'mode',
  'messageFrequency',
  'timeout',
]

const mainIntermediateOperationsFields = [
  ...commonRequiredFields,
  'outputPower',
  'mode',
  'messageFrequency',
  'timeout',
]

const firmwareRequiredFields = [
  ...commonRequiredFields,
  'firmwareUpdateStatus',
  'firmwareUpdateTime',
  'firmwareUpdateType',
  'firmwareUpdateVersion',
]

const statusFields = [...commonRequiredFields, 'mode']

const deviceStatisticsFields = [
  ...commonRequiredFields,
  'bulbChangeDate',
  'deviceRevision',
  'softwareRevision',
  'bulbCyclesSinceChange',
  'powerCyclesSinceChange',
  'abuseEvents',
  'totalLEDTime',
  'totalRunTime',
  'ledTemp',
  'weightedTime',
]

const deviceStatisticsRequiredFields = [
  ...commonRequiredFields,
  'bulbChangeDate',
  'deviceRevision',
  'softwareRevision',
]

const deviceStatisticsNumeric = [
  'bulbCyclesSinceChange',
  'powerCyclesSinceChange',
  'abuseEvents',
  'totalLEDTime',
  'totalRunTime',
  'ledTemp',
  'weightedTime',
]

const certificatesInfoFields = [
  'certificateId',
  'publicKeyHash',
  'privateKeyHash',
  'certificateHash',
]

const commonDeviceInfoFields = [
  'mac',
  'commDeviceName',
  'measurementDate',
  'measurementUnit',
  'measurementValue',
]

const requiredParametersFields = [...commonRequiredFields, 'imei']

const requestParametersFields = [
  ...requiredParametersFields,
  'softwareRevision',
]

const prepareMotionPIRRequestBody = (description, status) => {
  return map(MOTION_PIR_DEFAULT_VALUES.sensorId, (sensorId, ind) => ({
    eventDescription: description[ind],
    alertStatus: status[ind],
    sensorId,
  }))
}

const prepareFaultInfoRequestBody = (values) => {
  const filterUnSetValues = filter(values, 'name')

  const faultInfo = map(filterUnSetValues, (item) => {
    return {
      name: item.name,
      date: item.isCurrentTime ? moment() : item.faultEventDate,
    }
  })

  return isEmpty(filter(faultInfo)) ? null : faultInfo
}

const isAllFieldsFilled = (fields, values) => {
  let isTransactionIDValid = true

  if (fields.indexOf('transactionId') !== -1) {
    isTransactionIDValid = isTransactionIdValid(values.transactionId)
  }

  return isTransactionIDValid && isRequiredFieldsFilled(fields, values)
}

const validateDeviceInfoFields = (fields, values, defaultValue, handler) => {
  const isCertificatesInfoValid = isAllFieldsFilled(
    certificatesInfoFields,
    values.certificatesInfo
  )
  const isNumbersValuesValid = isAllValuesFilledAndPositive(
    deviceStatisticsNumeric,
    values
  )

  const isCommonDeviceInfoValid =
    values.commonDevicesInfo.length &&
    every(values.commonDevicesInfo, (item) => {
      return isAllFieldsFilled(commonDeviceInfoFields, item)
    })

  return (
    isNumbersValuesValid &&
    isCommonDeviceInfoValid &&
    isCertificatesInfoValid &&
    handler(fields, values, defaultValue)
  )
}

// it is a handy method to check for required metaInfo fields of current message
const validateRequiredMetaInfoFields = (metaInfo, message) => {
  let isValid = true

  forEach(REQUIRED_META_INFO_ROWS, (row) => {
    if (row.messageType === message) {
      const correspondingValue = find(metaInfo, { key: row.key }).value

      isValid = isValid && !!correspondingValue
    }
  })

  return isValid
}

const validateRequiredFields = (
  values,
  message,
  handler,
  defaultValue = false
) => {
  switch (message) {
    case EVENTS_VALUES.on:
    case EVENTS_VALUES.off:
      defaultValue = handler(commonRequiredFields, values, defaultValue)
      break
    case EVENTS_VALUES.start:
    case EVENTS_VALUES.finish:
    case EVENTS_VALUES.proceed:
    case EVENTS_VALUES.pause:
    case EVENTS_VALUES.resume:
    case EVENTS_VALUES.cycleCountdown:
    case EVENTS_VALUES.cycleCancel:
      defaultValue = handler(mainOperationsFields, values, defaultValue)
      break
    case EVENTS_CODES.evt415:
    case EVENTS_CODES.evt416:
    case EVENTS_CODES.evt417:
      defaultValue =
        handler(firmwareRequiredFields, values, defaultValue) &&
        validateRequiredMetaInfoFields(values.metaInfo, message)
      break
    case EVENTS_CODES.evt511:
      defaultValue = handler(statusFields, values, defaultValue)
      break
    case EVENTS_CODES.sts011:
      defaultValue = validateDeviceInfoFields(
        deviceStatisticsRequiredFields,
        values,
        defaultValue,
        handler
      )
      break
    case EVENTS_CODES.evt011:
      defaultValue = validateDeviceInfoFields(
        requiredParametersFields,
        values,
        defaultValue,
        handler
      )
      break
    default:
      defaultValue = handler(commonRequiredFields, values, defaultValue)
      break
  }

  return defaultValue
}

const dataProcessing = (values, message, defaultValue, handler) => {
  switch (message) {
    case EVENTS_VALUES.on:
    case EVENTS_VALUES.off:
      defaultValue = handler(commonRequiredFields, values, defaultValue)
      break
    case EVENTS_VALUES.start:
    case EVENTS_VALUES.finish:
    case EVENTS_VALUES.proceed:
      defaultValue = handler(mainOperationsFields, values, defaultValue)
      break
    case EVENTS_VALUES.pause:
    case EVENTS_VALUES.resume:
    case EVENTS_VALUES.cycleCountdown:
    case EVENTS_VALUES.cycleCancel:
      defaultValue = handler(
        mainIntermediateOperationsFields,
        values,
        defaultValue
      )
      break
    case EVENTS_CODES.evt415:
    case EVENTS_CODES.evt416:
    case EVENTS_CODES.evt417:
      defaultValue = handler(firmwareRequiredFields, values, defaultValue)
      break
    case EVENTS_CODES.evt511:
      defaultValue = handler(statusFields, values, defaultValue)
      break
    case EVENTS_CODES.sts011:
      defaultValue = handler(deviceStatisticsFields, values, defaultValue)
      break
    case EVENTS_CODES.evt011:
      defaultValue = handler(requestParametersFields, values, defaultValue)
      break
    default:
      defaultValue = handler(commonRequiredFields, values, defaultValue)
      break
  }

  return defaultValue
}

const prepareRequestParams = (values, message) => {
  const params = []

  if (values.transactionId) {
    params.push(`transactionId=${values.transactionId}`)
  }

  return dataProcessing(values, message, params, pushParams)
}

const prepareRequestBody = (values, message) => {
  const body = {}

  if (message === EVENTS_CODES.evt511) {
    body.faultsInfo = prepareFaultInfoRequestBody(values.faultEventData)
  }

  if (message === EVENTS_CODES.evt711) {
    const { motionPIRDescription, motionPIRAlertStatus } = values

    body.pirsInfo = prepareMotionPIRRequestBody(
      motionPIRDescription,
      motionPIRAlertStatus
    )
  }

  if (indexOf(metaInfoValues, message) !== -1) {
    const { metaInfo } = values

    body.metaInfo = prepareMetaInfoRequestBody(metaInfo, message)
  }

  if (message === EVENTS_CODES.sts011 || message === EVENTS_CODES.evt011) {
    const { commonDevicesInfo, certificatesInfo } = values

    body.commDevicesInfo = commonDevicesInfo
    body.certificatesInfo = certificatesInfo
  }

  return isEmpty(body) ? null : body
}

const getLocationForDevice = (devices, serialNumber, locations) => {
  const device = find(devices, ['serialNumber', serialNumber])
  const locationId = device.allowedLocationIds[0]

  return find(locations, ['id', locationId])
}

const setTxStartTime = (message, txStartedAt) => {
  switch (message) {
    case EVENTS_VALUES.start:
      return moment().utc().format(yyyyMMDDTHHmmssFormat)

    case EVENTS_VALUES.finish:
    case EVENTS_VALUES.cycleCancel:
      return ''

    default:
      return txStartedAt
  }
}

const setNewMetaInfo = (metaInfo, txStartedAt) => {
  const newMetaInfo = filter(metaInfo, (item) => item.key !== 'start_time')

  if (!txStartedAt) {
    return newMetaInfo
  }

  return [
    {
      key: 'start_time',
      type: 'string',
      value: txStartedAt,
    },
    ...newMetaInfo,
  ]
}

const setMetaInfoForUpdateFail = (metaInfo, isUpdateFail) => {
  const newMetaInfo = filter(metaInfo, (item) => item.key !== 'updateFailCause')

  return isUpdateFail
    ? [
        {
          key: 'updateFailCause',
          type: 'string',
          value: '',
        },
        ...newMetaInfo,
      ]
    : [...newMetaInfo]
}

export {
  prepareMotionPIRRequestBody,
  prepareRequestParams,
  prepareRequestBody,
  validateRequiredFields,
  isAllFieldsFilled,
  getLocationForDevice,
  setTxStartTime,
  setNewMetaInfo,
  setMetaInfoForUpdateFail,
}
