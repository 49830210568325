import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  modalTitleContainer: {},
  modalTitle: {},
  dialogContent: {
    paddingBottom: '20px',
  },
  submitButton: {},
  listItemText: {
    fontSize: '1rem',
  },
})
