import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import ConfirmModal from '../../../components/_shared/confirmModal/ConfirmModal'
import { checkConnection } from '../../../services/root/root-service'
import { useStyles } from '../../Devices/components/List/components/style'
import actions from '../services/firmwares-actions'
import { FIRMWARE_CONFIRM_MODAL } from '../services/firmwares-constants'

const DeleteFirmwareButtonComponent = (props) => {
  const style = useStyles()
  const { id, type, version, error, deleteFirmware, resetError } = props
  const confirmText = FIRMWARE_CONFIRM_MODAL.confirmText(type, version)
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)

    resetError()
  }

  const onClick = () => {
    deleteFirmware(id, handleClose)
  }

  return (
    <div>
      <IconButton
        className={classNames('delete-firmware-button', style.deleteIcon)}
        color="secondary"
        onClick={handleOpen}
        aria-label="show more"
      >
        <CloseIcon />
      </IconButton>
      <ConfirmModal
        isOpen={open}
        error={error}
        onClose={handleClose}
        onSubmit={onClick}
        confirmText={confirmText}
        modalTitle={FIRMWARE_CONFIRM_MODAL.modalTitle}
        actionLabel={FIRMWARE_CONFIRM_MODAL.actionLabel}
        closeLabel={FIRMWARE_CONFIRM_MODAL.closeLabel}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  deleteFirmware: (id, onSuccess) =>
    dispatch(checkConnection(() => actions.deleteFirmware(id, onSuccess))),
  resetError: () => dispatch(actions.resetError()),
})

export default connect(null, mapDispatchToProps)(DeleteFirmwareButtonComponent)
