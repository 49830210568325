import { TextField } from '@material-ui/core'
import map from 'lodash/map'
import { EDIT_DEVICE } from '../components/Details/services/deviceDetails-constants'
import { getEditableUvgiFields } from '../services/devices-constants'

const EditableUVGIFields = (props) => {
  const { state, errors, changeTextField, isEditing = true } = props
  const uvgiFields = getEditableUvgiFields(isEditing)

  return map(uvgiFields, (item) => {
    return (
      <TextField
        id="add-device-mcu-uid"
        key={item.name}
        name={item.name}
        label={EDIT_DEVICE[item.name]}
        value={state[item.name] || ''}
        error={errors[item.name]}
        onChange={changeTextField}
        variant="outlined"
        fullWidth
        disabled={state.removed}
        required={item.required}
      />
    )
  })
}

export default EditableUVGIFields
