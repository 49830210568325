const styles = (theme) => ({
  featuresPageContainer: {
    width: '90%',
    paddingTop: '30px',
    margin: 'auto',
  },
  switchLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
    },
  },
  '@media (min-width: 1224px)': {},
})

export default styles
