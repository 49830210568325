import { useEffect, useState } from 'react'
import { UNKNOWN_ID, UNKNOWN_LOCATION } from '../../../services/constants'
import {
  getImeiByDevice,
  isRequiredFieldsFilled,
  pushParams,
} from '../_shared/services/emulator-service'
import {
  commonRequiredFields,
  ERROR,
  initialState,
  messageConstants,
  paramsFields,
} from './services/extEmulator-constants'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { JsonTable } from 'react-json-to-html'
import { connect } from 'react-redux'
import NetworkError from '../../../components/_shared/errors/NetworkError'
import { checkConnection } from '../../../services/root/root-service'
import styles from '../style'
import CommentField from '../_shared/components/CommentField'
import DefaultPart from '../_shared/components/DefaultPart'
import { FORM_LABELS } from '../_shared/services/emulator-constants'
import { getLocationForDevice } from './../UVGI/services/uvgiEmulator-service'
import MessageMeta from './components/MessageMeta'
import actions from './services/extEmulator-actions'
import { prepareBody } from './services/extEmulator-service'

const ExtEmulatorContainer = (props) => {
  const {
    loadDevices,
    sendDeviceMessage,
    deviceMessage,
    error,
    devices,
    locations,
    allLocations,
    networkError,
    sendDeviceMessageFailed,
    classes,
  } = props
  const [state, setState] = useState(initialState)

  useEffect(() => {
    loadDevices()
    // eslint-disable-next-line
  }, [])

  const changeValue = (event, name) => {
    setState({
      ...state,
      [name]: event.target.value,
    })
  }

  const changeDevice = (event) => {
    const { value } = event.target
    const imeiValue = getImeiByDevice(devices, value)
    const location = getLocationForDevice(devices, value, allLocations)

    setState({
      ...state,
      deviceName: value,
      imei: imeiValue,
      imeiCopy: imeiValue,
      location,
      locationId: location.id,
    })
  }

  const changeDateValue = (value, name) => {
    setState({
      ...state,
      [name]: value,
    })
  }

  const changeUseCurrentTimeCheckbox = (event) => {
    const { checked } = event.target

    setState({
      ...state,
      useCurrentTime: checked,
    })
  }

  const changeMessageType = (event) => {
    const { value } = event.target

    setState({
      ...state,
      messageTypeText: value,
      isSubmitted: false,
    })
  }

  const onSubmitButton = () => {
    const copyOfState = { ...state }

    const isValid = isRequiredFieldsFilled(commonRequiredFields, copyOfState)

    if (isValid) {
      const params = pushParams(paramsFields, copyOfState)

      try {
        const body = prepareBody(copyOfState.metaInfo)

        sendDeviceMessage(params.join('&'), { metaInfo: body })
      } catch (e) {
        sendDeviceMessageFailed(ERROR)
      }
    }

    setState({
      ...state,
      isSubmitted: true,
    })
  }

  const {
    deviceName,
    isSubmitted,
    locationId,
    location,
    messageTypeText,
    imei,
    imeiCopy,
    transactionId,
    deviceDateTime,
    useCurrentTime,
    metaInfo,
    comment,
  } = state

  const locationsForExtDevices = [
    location,
    { id: UNKNOWN_ID, name: UNKNOWN_LOCATION, children: [] },
  ]

  return networkError ? (
    <NetworkError />
  ) : (
    <>
      <div className={classes.formContainer}>
        {imei !== imeiCopy && (
          <Typography className={classes.selectAllLabel} variant="subtitle2">
            {FORM_LABELS.SERIAL_NUMBER}
          </Typography>
        )}
        <DefaultPart
          changeUseCurrentTimeCheckbox={changeUseCurrentTimeCheckbox}
          useCurrentTime={useCurrentTime}
          devices={devices}
          deviceDateTime={deviceDateTime}
          isSubmitted={isSubmitted}
          deviceName={deviceName}
          locations={locations}
          allLocations={locationsForExtDevices}
          locationId={locationId}
          messageTypeText={messageTypeText}
          transactionId={transactionId}
          changeDateValue={changeDateValue}
          changeValue={changeValue}
          changeMessageType={changeMessageType}
          messageConstants={messageConstants}
          isHideTransactionId={true}
          changeDevice={changeDevice}
        />
        <MessageMeta
          changeValue={changeValue}
          messageMeta={metaInfo}
          isSubmitted={isSubmitted}
        />
        <CommentField changeValue={changeValue} comment={comment} />

        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={onSubmitButton}
        >
          {FORM_LABELS.SUBMIT}
        </Button>
      </div>
      <div className={classes.messageContainer}>
        {!error && deviceMessage && <JsonTable json={deviceMessage} />}
        {error && (
          <Typography variant="subtitle2" color="error">
            {error}
          </Typography>
        )}
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendDeviceMessage: (data, body) =>
      dispatch(checkConnection(() => actions.sendDeviceMessage(data, body))),
    sendDeviceMessageFailed: (message) =>
      dispatch(actions.sendDeviceMessageFailed(message)),
    loadDevices: () => dispatch(checkConnection(actions.loadEXTDevices)),
  }
}

const mapStateToProps = (state) => {
  const {
    eventsEmulatorReducer: { ExtEmulatorReducer },
    rootReducer,
  } = state

  return {
    deviceMessage: ExtEmulatorReducer.deviceMessage,
    error: ExtEmulatorReducer.error,
    devices: ExtEmulatorReducer.devices,
    locations: ExtEmulatorReducer.deviceLocations,
    allLocations: ExtEmulatorReducer.allLocations,
    networkError: rootReducer.networkError,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ExtEmulatorContainer))
