import TextField from '@material-ui/core/TextField'
import { EXTENDED_MODE } from '../../_shared/services/emulator-constants'
import { validateNumericFields } from '../../_shared/services/emulator-service'

const SpecialFields = (props) => {
  const { changeValue, specialMode1, specialMode2 } = props

  const handleChange = (event) => {
    changeValue(event, event.target.name)
  }

  return (
    <>
      <TextField
        id="specialMode1"
        name="specialMode1"
        value={specialMode1}
        onChange={handleChange}
        error={!validateNumericFields(specialMode1)}
        label={EXTENDED_MODE.specialMode1}
        variant="outlined"
      />
      <TextField
        id="specialMode2"
        name="specialMode2"
        value={specialMode2}
        onChange={handleChange}
        error={!validateNumericFields(specialMode2)}
        label={EXTENDED_MODE.specialMode2}
        variant="outlined"
      />
    </>
  )
}

export default SpecialFields
