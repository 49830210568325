const styles = (theme) => ({
  wrapper: {
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 150px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
  },
  iconWrapper: {
    width: '30px',
    height: '30px',
  },
})

export default styles
