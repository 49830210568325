const style = () => ({
  actionButtonContainer: {
    maxWidth: '300px',
    marginRight: '20px',
  },
  dialogTitle: {
    marginTop: '10px',
  },
  statusFilerIcon: {
    position: 'absolute',
    left: '16px',
    top: '16px',
    padding: 0,
  },
  error: {
    marginTop: '10px',
  },
  dialogContent: {
    margin: '0 20px 20px',
  },
  actionButton: {
    marginTop: '20px',
  },
})

export default style
