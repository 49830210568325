const dashboardActions = {}

dashboardActions.actionTypes = {
  LOAD_MAIN_PAGE_VALUES: '{Dashboard} Load Main Page Values',
  LOAD_MAIN_PAGE_VALUES_SUCCEEDED:
    '{Dashboard} Load Main Page Values Succeeded',
  LOAD_MAIN_PAGE_VALUES_FAILED: '{Dashboard} Load Main Page Values Failed',

  GET_PORTAL_LINKS: '{Dashboard} Get Portal Links',
  GET_PORTAL_LINKS_SUCCEEDED: '{Dashboard} Get Portal Links Succeeded',
  GET_PORTAL_LINKS_FAILED: '{Dashboard} Get Portal Links Failed',

  CHECK_CUSTOMER_PORTAL_SUCCEEDED:
    '{Dashboard} Check Customer Portal Succeeded',
  CHECK_CUSTOMER_PORTAL_FAILED: '{Dashboard} Check Customer Portal Failed',

  CHECK_DEVICE_PORTAL_SUCCEEDED: '{Dashboard} Check Device Portal Succeeded',
  CHECK_DEVICE_PORTAL_FAILED: '{Dashboard} Check Device Portal Failed',

  GET_NUMBER_OF_INSTANCES: '{Dashboard} Get Number Of Instances',
  GET_NUMBER_OF_INSTANCES_SUCCEEDED:
    '{Dashboard} Get Number Of Instances Succeeded',
  GET_NUMBER_OF_INSTANCES_FAILED: '{Dashboard} Get Number Of Instances Failed',
}

dashboardActions.getPortalLinks = () => {
  return {
    type: dashboardActions.actionTypes.GET_PORTAL_LINKS,
  }
}

dashboardActions.getPortalLinksSuccess = (portalLinks) => {
  return {
    type: dashboardActions.actionTypes.GET_PORTAL_LINKS_SUCCEEDED,
    payload: {
      portalLinks,
    },
  }
}

dashboardActions.getPortalLinksFailed = (message) => {
  return {
    type: dashboardActions.actionTypes.GET_PORTAL_LINKS_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.getNumberOfInstances = () => {
  return {
    type: dashboardActions.actionTypes.GET_NUMBER_OF_INSTANCES,
  }
}

dashboardActions.getNumberOfInstancesSuccess = (
  numberOfInstances,
  portalLinks
) => {
  return {
    type: dashboardActions.actionTypes.GET_NUMBER_OF_INSTANCES_SUCCEEDED,
    payload: {
      numberOfInstances,
      portalLinks,
    },
  }
}

dashboardActions.getNumberOfInstancesFailed = (message) => {
  return {
    type: dashboardActions.actionTypes.GET_NUMBER_OF_INSTANCES_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.checkCustomerPortalSuccess = (portalLinks) => {
  return {
    type: dashboardActions.actionTypes.CHECK_CUSTOMER_PORTAL_SUCCEEDED,
    payload: {
      portalLinks,
    },
  }
}

dashboardActions.checkCustomerPortalFailed = (message) => {
  return {
    type: dashboardActions.actionTypes.CHECK_CUSTOMER_PORTAL_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.checkDevicePortalSuccess = (portalLinks) => {
  return {
    type: dashboardActions.actionTypes.CHECK_DEVICE_PORTAL_SUCCEEDED,
    payload: {
      portalLinks,
    },
  }
}

dashboardActions.checkDevicePortalFailed = (message) => {
  return {
    type: dashboardActions.actionTypes.CHECK_DEVICE_PORTAL_FAILED,
    payload: {
      message,
    },
  }
}

dashboardActions.loadMainPageValues = () => {
  return {
    type: dashboardActions.actionTypes.LOAD_MAIN_PAGE_VALUES,
  }
}

dashboardActions.loadMainPageValuesSuccess = (values) => {
  return {
    type: dashboardActions.actionTypes.LOAD_MAIN_PAGE_VALUES_SUCCEEDED,
    payload: {
      values,
    },
  }
}

dashboardActions.loadMainPageValuesFailed = (message) => {
  return {
    type: dashboardActions.actionTypes.LOAD_MAIN_PAGE_VALUES_FAILED,
    payload: {
      message,
    },
  }
}

export default dashboardActions
