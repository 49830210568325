import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import organizationActions from 'containers/Organizations/services/organization-actions'
import {
  CREATE_USER_CLEAN,
  CREATE_USER_INIT,
} from 'containers/Users/services/users-actions'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { useAppSelector } from 'utils/reduxHelpers'
import * as Yup from 'yup'
import {
  allowed_user_roles,
  formatPhoneNumber,
  permissionsList,
} from './services/useradd-constants'
import { useStyles } from './userAddPageStyles'

const PREVIOUS_PAGE_URL = '/users'
const PHONE_MAX_ALLOWED_DIGITS = 14
const PHONE_EXT_MAX_ALLOWED_DIGITS = 10

export const UserAddPage = ({ history }: RouteComponentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const submitError = useAppSelector(
    (state) => state.usersReducer.userCreation.error
  )
  const creating = useAppSelector(
    (state) => state.usersReducer.userCreation.creating
  )
  const organizations = useAppSelector((state) =>
    state.organizationReducer.organizations.sort((o1, o2) =>
      o1.name.toLowerCase() < o2.name.toLowerCase() ? -1 : 1
    )
  )
  const submitSuccess = useAppSelector(
    (state) => state.usersReducer.userCreation.success
  )

  const [filteredOrganizations, updateFilteredOrganizations] = useState<
    { label: string; value: string }[]
  >([])

  useEffect(() => {
    dispatch(organizationActions.loadOrganizations())
  }, [])

  const fetchOrganizationOptions = useCallback(
    (searchString: string) => {
      const regex = new RegExp(searchString, 'i')
      const filtered = organizations
        .filter((org) => regex.test(org.name || ''))
        .map((org) => ({ label: String(org.name), value: String(org.id) }))
      updateFilteredOrganizations(filtered)
    },
    [organizations]
  )

  useEffect(() => {
    fetchOrganizationOptions('')
  }, [fetchOrganizationOptions])

  const formik = useFormik({
    initialValues: {
      organization: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      extension: '',
      password: '',
      repeatPassword: '',
      role: '',
      permissions: [] as string[],
    },
    validationSchema: Yup.object({
      organization: Yup.string().required('Required'),
      role: Yup.string().required('Required'),
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phone: Yup.string(),
      extension: Yup.string().max(
        PHONE_EXT_MAX_ALLOWED_DIGITS,
        'Extension should be less than ' +
          PHONE_EXT_MAX_ALLOWED_DIGITS +
          ' digits'
      ),
      password: Yup.string()
        .required('Required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]+/, 'At least one lowercase character')
        .matches(/[A-Z]+/, 'At least one uppercase character')
        .matches(/[@$!%*#?&]+/, 'At least one special character')
        .matches(/\d+/, 'At least one number'),
      repeatPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
    }),
    onSubmit: ({
      firstName,
      lastName,
      organization,
      email,
      phone,
      extension,
      password,
      permissions,
      role,
    }) => {
      dispatch(
        CREATE_USER_INIT({
          organizationId: parseInt(organization),
          firstName,
          lastName,
          email,
          phoneNumber: phone,
          phoneExtension: extension,
          password,
          role,
          permissions,
        })
      )
    },
  })

  useEffect(() => {
    if (submitSuccess) {
      formik.resetForm()
      setTimeout(() => {
        dispatch(CREATE_USER_CLEAN())
        history.push(PREVIOUS_PAGE_URL)
      }, 3000)
    }
  }, [submitSuccess])

  const onCancelClick = useCallback(() => {
    dispatch(CREATE_USER_CLEAN())
    history.push(PREVIOUS_PAGE_URL)
  }, [dispatch])

  return (
    <form onSubmit={formik.handleSubmit} className={classes.pageContainer}>
      <Grid container spacing={2} className={classes.fieldsContainer}>
        <Grid item xs={12}>
          <h3>Create User</h3>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <Autocomplete
              fullWidth
              options={filteredOrganizations}
              getOptionLabel={(option) => option.label}
              onInputChange={(event, inputValue) => {
                fetchOrganizationOptions(inputValue)
              }}
              onChange={(event, option) => {
                formik.setFieldValue('organization', option ? option.value : '')
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organization"
                  name="organization"
                  className={classes.formControl}
                  value={formik.values.organization}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  error={
                    formik.touched.organization &&
                    Boolean(formik.errors.organization)
                  }
                  helperText={
                    formik.touched.organization && formik.errors.organization
                  }
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            variant="outlined"
            className={classes.formControl}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            variant="outlined"
            className={classes.formControl}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            className={classes.formControl}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.phoneAndExtension}>
            <TextField
              id="phone"
              name="phone"
              label="Phone Number"
              variant="outlined"
              value={formatPhoneNumber(formik.values.phone)}
              onChange={(event) => {
                const phoneNumber = event.target.value
                  .replace(/\D/g, '')
                  .slice(0, PHONE_MAX_ALLOWED_DIGITS)
                formik.setFieldValue('phone', phoneNumber)
              }}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.phone && Boolean(formik.errors.phone)) ||
                (formik.touched.extension && Boolean(formik.errors.extension))
              }
              helperText={
                (formik.touched.phone && formik.errors.phone) ||
                (formik.touched.extension && formik.errors.extension)
              }
            />
            <TextField
              id="extension"
              name="extension"
              label="Ext."
              variant="outlined"
              value={formik.values.extension.replace(/\D/g, '')}
              onChange={(event) => {
                const extension = event.target.value.replace(/\D/g, '')
                formik.setFieldValue('extension', extension)
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.extension && Boolean(formik.errors.extension)
              }
            />
          </div>
        </Grid>{' '}
        <Grid item xs={12} md={6}>
          <TextField
            id="password"
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            className={classes.formControl}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>{' '}
        <Grid item xs={12} md={6}>
          <TextField
            id="repeatPassword"
            name="repeatPassword"
            label="Repeat Password"
            type="password"
            variant="outlined"
            className={classes.formControl}
            value={formik.values.repeatPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.repeatPassword &&
              Boolean(formik.errors.repeatPassword)
            }
            helperText={
              formik.touched.repeatPassword && formik.errors.repeatPassword
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.role && Boolean(formik.errors.role)}
              label="Role"
            >
              {allowed_user_roles.map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              <Typography variant="caption" color="error">
                {formik.touched.role && formik.errors.role}
              </Typography>
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <h4>Permissions</h4>
          <FormControl
            component="fieldset"
            error={
              formik.touched.permissions && Boolean(formik.errors.permissions)
            }
          >
            <FormGroup>
              {permissionsList.map((permission) => (
                <FormControlLabel
                  key={permission.value}
                  control={
                    <Checkbox
                      checked={formik.values.permissions.includes(
                        permission.value
                      )}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="permissions"
                      color="primary"
                      value={permission.value}
                    />
                  }
                  label={permission.label}
                />
              ))}
            </FormGroup>
            <FormHelperText>{formik.errors.permissions}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid container justifyContent="center">
          <Box width={600} m={1}>
            {submitError && <Alert severity="error">{submitError}</Alert>}
            {submitSuccess && (
              <Alert severity="success">User Created Successfully!</Alert>
            )}
          </Box>
        </Grid>
        <Grid container justifyContent="center">
          <Box width={300} m={1}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={creating}
            >
              Create
            </Button>
          </Box>
          <Box width={300} m={1}>
            <Button
              variant="contained"
              color="default"
              disabled={creating}
              type="button"
              onClick={onCancelClick}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}
