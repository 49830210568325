import { ARC_PATHS, DATA_FIELDS, PAGES, PERMISSIONS } from '../constants'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import indexOf from 'lodash/indexOf'
import map from 'lodash/map'
import replace from 'lodash/replace'
import mobileTheme from '../../mobileTheme'
import filterQuery from '../filterQuery-service'
import actions from './root-actions'

const logOut = () => {
  window.location.href = '/api/secured/user-profiles/logout'
}

const withMediaQuery = (Component) => (props) => {
  const mediaQuery = useMediaQuery(mobileTheme.breakpoints.down('md'))
  return <Component isMobile={mediaQuery} {...props} />
}

const checkConnection = (action) => {
  const isOnline = window.navigator.onLine

  return isOnline ? action() : actions.setNetworkError(isOnline)
}

const prepareTypes = (types) => {
  return map(types, (item) => {
    return {
      value: item.value,
      label: item.displayValue,
    }
  })
}

const prepareTypesWithNone = (
  types,
  valueName = DATA_FIELDS.value,
  labelName = DATA_FIELDS.displayValue
) => {
  const preparedTypes = [
    {
      label: 'None',
      value: 0,
    },
  ]

  forEach(types, (item) => {
    preparedTypes.push({
      value: item[valueName],
      label: item[labelName],
    })
  })

  return preparedTypes
}

const prepareDeviceSubTypeQuery = (type, sybType) => {
  const deviceTypeQuery = filterQuery.getQueryString(
    type,
    filterQuery.templates.default,
    'deviceType',
    'eq'
  )

  const sybTypeQuery = filterQuery.getQueryString(
    sybType,
    filterQuery.templates.default,
    'deviceSubType',
    'eq'
  )

  return filterQuery.createQueryStringFromArray(
    [deviceTypeQuery, sybTypeQuery],
    'and'
  )
}

const prepareAccessForPages = (accessGroups) => {
  const initialPermissions = cloneDeep(PERMISSIONS)

  forEach(accessGroups, (item) => {
    if (includes(item, 'OPS')) {
      const groupName = item.slice(5, item.length - 4)

      initialPermissions[groupName].write = true
    } else if (includes(item, 'READ')) {
      const groupName = item.slice(5, item.length - 5)

      initialPermissions[groupName].read = true
    }

    if (includes(item, PAGES.deviceEmulator)) {
      initialPermissions[PAGES.deviceEmulator] = {
        read: true,
        write: true,
      }
    }

    if (includes(item, PAGES.uvgiPowerManagement)) {
      initialPermissions[PAGES.uvgiPowerManagement] = {
        read: true,
        write: true,
      }
    }

    if (includes(item, PAGES.ARCChangeRevision)) {
      initialPermissions[PAGES.ARCChangeRevision] = {
        read: true,
        write: true,
      }
    }
  })

  return initialPermissions
}

const goToRoute = (history, route) => {
  history.push(route)
}

const encodeString = (string) => replace(string, '&', '%26')

const isArcPath = (deviceType) => indexOf(ARC_PATHS, deviceType) !== -1

const escapeQuotes = (name) => {
  const replacedName = replace(name, /'/g, "%5C'")

  return replace(replacedName, /"/g, '%5C"')
}

export {
  logOut,
  withMediaQuery,
  checkConnection,
  prepareTypes,
  prepareTypesWithNone,
  prepareAccessForPages,
  goToRoute,
  encodeString,
  isArcPath,
  prepareDeviceSubTypeQuery,
  escapeQuotes,
}
