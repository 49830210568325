import arcEmulatorActions from './arcEmulator-actions'
import { getPlacementsFromTree } from './arcEmulator-service'

export const arcEmulatorReducer = (
  state = {
    error: '',
    deviceMessage: '',
    errorFromDeviceMessage: '',
    messageDevices: [],
    messageLocations: [],
    allMessageLocations: [],
  },
  action
) => {
  switch (action.type) {
    case arcEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_SUCCEEDED:
      return {
        ...state,
        deviceMessage: action.payload.message,
        errorFromDeviceMessage: '',
      }
    case arcEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_FAILED:
      return {
        ...state,
        errorFromDeviceMessage: action.payload.message,
      }
    case arcEmulatorActions.actionTypes.LOAD_MESSAGE_DEVICES_SUCCEEDED:
      return {
        ...state,
        messageDevices: action.payload.devices,
        errorFromDeviceMessage: '',
      }
    case arcEmulatorActions.actionTypes.LOAD_MESSAGE_LOCATIONS_SUCCEEDED:
      return {
        ...state,
        messageLocations: getPlacementsFromTree(action.payload.locations),
        errorFromDeviceMessage: '',
      }
    case arcEmulatorActions.actionTypes.LOAD_ALL_MESSAGE_LOCATIONS_SUCCEEDED:
      return {
        ...state,
        allMessageLocations: getPlacementsFromTree(action.payload.locations),
        errorFromDeviceMessage: '',
      }
    case arcEmulatorActions.actionTypes.LOAD_MESSAGE_DEVICES_FAILED:
      return {
        ...state,
        errorFromDeviceMessage: action.payload.message,
      }
    default:
      return state
  }
}
