import { BULK_UPLOAD_LABELS, getBulkLoadMessage } from '../../../../constants'

import { Snackbar } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useState } from 'react'
import { connect } from 'react-redux'
import BulkUploadButtonAndModal from '../../../../components/_shared/bulkUpload/BulkUploadButtonAndModal'
import ErrorHasOccurred from '../../../../components/_shared/errors/ErrorHasOccurred'
import NetworkError from '../../../../components/_shared/errors/NetworkError'
import { checkConnection } from '../../../../services/root/root-service'
import actions from '../../services/devices-actions'
import styles from '../../style'
import AddDeviceButton from '../Details/components/addDeviceButton/AddDeviceButton'
import DevicesTable from './components/DevicesTable'
import ImeiCheckingButton from './components/ImeiCheckingButton'
import devicesListActions from './services/devicesList-actions'
import { DEVICES_PAGE_LABELS } from './services/devicesList-constants'

const DevicesPage = (props) => {
  const {
    history,
    bulkLoadFailed,
    bulkLoadTotal,
    bulkLoadError,
    loadDevicesError,
    networkError,
    deviceType,
    setDeviceType,
    isReadOnly,
    bulkUploadDevices,
    resetBulkUploadErrors,
    downloadCsvExample,
    classes,
  } = props

  const [openSnackbar, setOpenSnackbar] = useState(false)

  const bulkLoadMessage = getBulkLoadMessage(bulkLoadFailed, bulkLoadTotal)

  const onCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const onOpenSnackBar = () => {
    setOpenSnackbar(true)
  }

  const goToFailedDevices = () => {
    window.location.href = '/#/devices/bulk/fails'
  }

  return (
    <div className={classes.devicesPageContainer}>
      <Box textAlign="center" marginBottom="15px">
        <Typography variant="h2" id="page-title">
          {DEVICES_PAGE_LABELS.devices}
        </Typography>
      </Box>
      {networkError ? (
        <NetworkError />
      ) : (
        <>
          <div className={classes.actionsWrapper}>
            {!isReadOnly && (
              <>
                <AddDeviceButton />
                <ImeiCheckingButton />
              </>
            )}
            <BulkUploadButtonAndModal
              isReadOnly={isReadOnly}
              onOpenSnackBar={onOpenSnackBar}
              error={bulkLoadError}
              bulkUpload={bulkUploadDevices}
              resetBulkUploadErrors={resetBulkUploadErrors}
              downloadCsvExample={downloadCsvExample}
              title={BULK_UPLOAD_LABELS.bulkUploadDevices}
            />
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={goToFailedDevices}
              className={classes.actionButton}
            >
              {BULK_UPLOAD_LABELS.seeFailedDevices}
            </Button>
          </div>
          {loadDevicesError ? (
            <ErrorHasOccurred hideLogo />
          ) : (
            <DevicesTable
              history={history}
              isReadOnly={isReadOnly}
              deviceType={deviceType}
              setDeviceType={setDeviceType}
            />
          )}
        </>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={onCloseSnackbar}
        message={bulkLoadMessage}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  const { devicesListReducer, sharedDevicesReducer } = state.devicesReducer

  return {
    bulkLoadFailed: devicesListReducer.bulkLoadFailed,
    bulkLoadTotal: devicesListReducer.bulkLoadTotal,
    bulkLoadError: devicesListReducer.bulkLoadError,
    loadDevicesError: devicesListReducer.loadDevicesError,
    networkError: state.rootReducer.networkError,
    deviceType: sharedDevicesReducer.deviceType,
    isReadOnly: sharedDevicesReducer.isReadOnly,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDeviceType: (type) => dispatch(actions.setDeviceType(type)),
  bulkUploadDevices: (body, onSuccess) =>
    dispatch(
      checkConnection(() =>
        devicesListActions.bulkUploadDevices(body, onSuccess)
      )
    ),
  downloadCsvExample: () =>
    dispatch(checkConnection(() => devicesListActions.downloadCsvExample())),
  resetBulkUploadErrors: () =>
    dispatch(devicesListActions.resetBulkUploadErrors()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DevicesPage))
