import { fork } from 'redux-saga/effects'
import authorizationSaga from '../containers/Authorization/services/authorization-sagas'
import dashboardSaga from '../containers/Dashboard/services/dashboard-sagas'
import allowListSagas from '../containers/Devices/components/Details/components/AllowList/services/AllowList-sagas'
import deviceDetailsSagas from '../containers/Devices/components/Details/services/deviceDetails-sagas'
import devicesListSagas from '../containers/Devices/components/List/services/devicesList-sagas'
import sharedDeviceSagas from '../containers/Devices/services/devices-sagas'
import emulatorSagas from '../containers/Emulator/services/emulator-sagas'
import eventsARCEmulatorSaga from '../containers/EventsEmulator/ARC/services/arcEmulator-sagas'
import eventsExtEmulatorSaga from '../containers/EventsEmulator/EXT_DEVICES/services/extEmulator-sagas'
import eventsNETEmulatorSaga from '../containers/EventsEmulator/NET_DEVICES/services/netEmulator-sagas'
import eventsUVGIEmulatorSaga from '../containers/EventsEmulator/UVGI/services/uvgiEmulator-sagas'
import eventsEmulatorSagas from '../containers/EventsEmulator/_shared/services/emulator-sagas'
import failedDevicesSagas from '../containers/FailedDevices/services/failedDevices-sagas'
import featuresTogglingSagas from '../containers/FeatureToggling/services/featureToggling-sagas'
import firmwareSagas from '../containers/Firmwares/services/firmwares-sagas'
import firmwareUpdateSagas from '../containers/FirmwareUpdateSchedule/services/firmwareUpdate-sagas'
import organizationDetailsSagas from '../containers/Organizations/components/Details/services/organizationDetails-sagas'
import organizationSaga from '../containers/Organizations/services/organization-sagas'
import usersSaga from '../containers/Users/services/users-sagas'
import rootSaga from './../services/root/root-sagas'

export default function* sagas() {
  yield fork(rootSaga)
  yield fork(organizationSaga)
  yield fork(firmwareSagas)
  yield fork(sharedDeviceSagas)
  yield fork(devicesListSagas)
  yield fork(deviceDetailsSagas)
  yield fork(failedDevicesSagas)
  yield fork(firmwareUpdateSagas)
  yield fork(usersSaga)
  yield fork(featuresTogglingSagas)
  yield fork(authorizationSaga)
  yield fork(emulatorSagas)
  yield fork(eventsEmulatorSagas)
  yield fork(eventsARCEmulatorSaga)
  yield fork(eventsNETEmulatorSaga)
  yield fork(eventsUVGIEmulatorSaga)
  yield fork(eventsExtEmulatorSaga)
  yield fork(dashboardSaga)
  yield fork(organizationDetailsSagas)
  yield fork(allowListSagas)
}
