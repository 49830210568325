import {
  EVENTS_VALUES,
  NETWORK_METHODS,
  NET_LABELS,
} from '../services/netEmulator-constants'

import { MenuItem } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import React from 'react'
import { validateNumericFields } from '../../_shared/services/emulator-service'

type NetFieldsProps = {
  changeValue: (e: React.ChangeEvent<any>, name: string) => any
  softwareRevision: string
  hardwareVersion: string
  firmwareVersion: string
  currentRunTime: number
  networkMethod?: string
  messageType: string
}

const NetFields = ({
  changeValue,
  softwareRevision,
  hardwareVersion,
  firmwareVersion,
  currentRunTime,
  networkMethod,
  messageType,
}: NetFieldsProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeValue(event, event.target.name)
  }

  const handleNetworkChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    changeValue(event, 'networkMethod')
  }

  return (
    <>
      <TextField
        id="softwareRevision"
        name="softwareRevision"
        value={softwareRevision}
        onChange={handleChange}
        label={NET_LABELS.softwareRevision}
        variant="outlined"
        required
      />
      <TextField
        id="hardwareVersion"
        name="hardwareVersion"
        value={hardwareVersion}
        onChange={handleChange}
        label={NET_LABELS.hardwareVersion}
        variant="outlined"
        required
      />
      <TextField
        id="firmwareVersion"
        name="firmwareVersion"
        value={firmwareVersion}
        onChange={handleChange}
        label={NET_LABELS.firmwareVersion}
        variant="outlined"
        required
      />
      <TextField
        id="currentRunTime"
        name="currentRunTime"
        value={currentRunTime}
        onChange={handleChange}
        error={!validateNumericFields(currentRunTime)}
        label={NET_LABELS.currentRuntime}
        variant="outlined"
        required
      />
      {messageType === EVENTS_VALUES.STS021 && (
        <TextField
          id="networkMethod"
          select
          value={networkMethod}
          label={NET_LABELS.networkMethod}
          onChange={handleNetworkChange}
          variant="outlined"
        >
          <MenuItem value=""></MenuItem>
          {Object.keys(NETWORK_METHODS).map((key) => (
            <MenuItem key={key} value={key}>
              {NETWORK_METHODS[key as keyof typeof NETWORK_METHODS]}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  )
}

export default NetFields
