import { useCallback, useEffect, useState } from 'react'
import {
  utcConverter,
  yyyyMMDFormat,
} from '../../../../services/dateFormat-service'

import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'utils/reduxHelpers'
import actions from '../../services/organization-actions'
import { ORGANIZATION_TABLE_LABELS } from '../../services/organization-constants'
import { OrganizationDetails } from '../Details/services/organizationDetails-types'
import { useStyles } from '../style'

type OrganizationsTableComponentProps = {
  selectedOrganizationId: number
  organizations: OrganizationDetails[]
}
const OrganizationsTableComponent = ({
  organizations,
  selectedOrganizationId,
}: OrganizationsTableComponentProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const moveSelected = (type: string) => dispatch(actions.moveSelected(type))
  const changeSelectedById = (id: number) =>
    dispatch(actions.changeSelectedById(id))

  const fields = ORGANIZATION_TABLE_LABELS

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [filteredOrgs, setFilteredOrgs] = useState(
    organizations?.slice(page, rowsPerPage) || []
  )

  const isReadOnly = useAppSelector(
    (state) => state.organizationReducer.isReadOnly
  )

  const keyPressFunction = useCallback((event: KeyboardEvent) => {
    switch (event.keyCode) {
      case 38:
        moveSelected('up')
        break
      case 40:
        moveSelected('down')
        break
      case 13:
        break
      default:
        return
    }

    // eslint-disable-next-line
  }, [])

  const history = useHistory()

  const goToDetails = (id: number) => {
    history.push(`organizations/details/${id}`)
  }

  useEffect(() => {
    document.addEventListener('keydown', keyPressFunction, false)

    return () => {
      document.removeEventListener('keydown', keyPressFunction, false)
    }
    // eslint-disable-next-line
  }, [])

  const getTableLabels = () =>
    map(fields, (value, key) => (
      <TableCell align="left" key={key}>
        {value}
      </TableCell>
    ))

  const getTableData = () =>
    filteredOrgs?.length === 0 ? (
      <TableRow></TableRow>
    ) : (
      map(filteredOrgs, (row) => {
        return (
          <TableRow
            key={row.id}
            onClick={() => changeSelectedById(row.id)}
            selected={row.id === selectedOrganizationId}
          >
            {map(fields, (value: string, key: keyof OrganizationDetails) => {
              const isModified = key !== 'modified' && key !== 'created'

              switch (value) {
                case ORGANIZATION_TABLE_LABELS.name:
                  return (
                    <TableCell align="left" key={key}>
                      {isReadOnly ? (
                        row.name
                      ) : (
                        <Link
                          className={styles.featuresButton}
                          onClick={() => goToDetails(row.id)}
                          style={{ cursor: 'pointer' }}
                        >
                          {row.name}
                        </Link>
                      )}
                    </TableCell>
                  )
                default:
                  return (
                    <TableCell align="left" key={key}>
                      {isModified
                        ? !isUndefined(row[key]) &&
                          !isNull(row[key]) &&
                          String(row[key])
                        : utcConverter(row[key], yyyyMMDFormat)}
                    </TableCell>
                  )
              }
            })}
          </TableRow>
        )
      })
    )

  const handleChangePage = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      newPage: number
    ) => {
      setPage(newPage)
    },
    []
  )

  const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }, [])

  useEffect(() => {
    setFilteredOrgs(
      organizations?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ) || []
    )
  }, [page, rowsPerPage])

  useEffect(() => {
    setRowsPerPage(rowsPerPage)
    setPage(0)
    setFilteredOrgs(
      organizations?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ) || []
    )
  }, [organizations])

  return (
    <TableContainer component={Paper} style={{ height: '100%' }}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>{getTableLabels()}</TableRow>
          {/*<TableRow><TableCell align="left">{filteredOrgs?.length}</TableCell></TableRow>*/}
        </TableHead>
        <TableBody>{getTableData()}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              component="td"
              count={organizations?.length || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 20, 50]}
              colSpan={Object.keys(fields).length}
              align="right"
              className={styles.tablePagination}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default OrganizationsTableComponent
