import {
  COMMUNICATION_DEVICE_NAME_OPTIONS,
  DEVICE_STATISTIC_LABELS,
} from '../services/uvgiEmulator-constants'

import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'
import CustomSelect from '../../../../components/_shared/customSelect/CustomSelect'
import { yyyyMMdFormat } from '../../../../services/dateFormat-service'
import EmulatorDatepicker from '../../_shared/components/EmulatorDatepicker'
import { validateNumericFields } from '../../_shared/services/emulator-service'
import styles from './style'

const CommDevicesInfoRow = (props) => {
  const {
    changeValue,
    isSubmitted,
    rowData: {
      mac,
      commDeviceName,
      measurementDate,
      isCurrentTime,
      measurementUnit,
      measurementValue,
    },
    deleteArrayElement,
    index,
    classes,
  } = props

  const isMeasurementValueNotValid =
    isSubmitted && !validateNumericFields(Math.abs(+measurementValue))

  const handleChange = (event) => {
    const { name, value } = event.target

    changeValue(value, name, index)
  }

  const changeCheckboxValue = (event) => {
    const { name, checked } = event.target

    changeValue(checked, name, index)
  }

  const changeDateFormat = (value) => {
    changeValue(value, 'measurementDate', index)
  }

  const deleteRow = () => {
    deleteArrayElement(index)
  }

  return (
    <TableRow key={'devicesInfoRow_' + index}>
      <TableCell className={classes.simpleValues}>
        <TextField
          id="outlined-basic"
          value={mac}
          name="mac"
          onChange={handleChange}
          required
          error={isSubmitted && !mac}
          label={DEVICE_STATISTIC_LABELS.mac}
          variant="outlined"
        />
      </TableCell>
      <TableCell className={classes.simpleValues}>
        <CustomSelect
          options={COMMUNICATION_DEVICE_NAME_OPTIONS}
          name="commDeviceName"
          label={DEVICE_STATISTIC_LABELS.commDeviceName}
          onChange={handleChange}
          value={commDeviceName}
          valueName="value"
          template={(item) => item.label}
        />
      </TableCell>
      <TableCell className={classes.dataCell}>
        <EmulatorDatepicker
          time={measurementDate}
          name={'measurementDate'}
          disabled={isCurrentTime}
          showTimeInput={true}
          dateFormat={yyyyMMdFormat}
          changeDateValue={changeDateFormat}
        />
        <Checkbox
          checked={isCurrentTime}
          name="isCurrentTime"
          onChange={changeCheckboxValue}
          color="primary"
        />
      </TableCell>
      <TableCell className={classes.simpleValues}>
        <TextField
          id="outlined-basic"
          disabled
          value={measurementUnit}
          name="measurementUnit"
          onChange={handleChange}
          required
          error={isSubmitted && !measurementUnit}
          label={DEVICE_STATISTIC_LABELS.measurementUnit}
          variant="outlined"
        />
      </TableCell>
      <TableCell className={classes.simpleValues}>
        <TextField
          id="outlined-basic"
          value={measurementValue}
          name="measurementValue"
          onChange={handleChange}
          required
          error={isMeasurementValueNotValid}
          type="number"
          label={DEVICE_STATISTIC_LABELS.measurementValue}
          variant="outlined"
        />
      </TableCell>
      <TableCell>
        <IconButton aria-label="delete" onClick={deleteRow}>
          <DeleteIcon color="error" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(CommDevicesInfoRow)
