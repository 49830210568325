import { PAGES } from '../../../services/constants'
import rootActions from '../../../services/root/root-actions'
import { prepareTypes } from '../../../services/root/root-service'
import firmwaresActions from './firmwares-actions'

export const firmwaresReducer = (
  state = {
    firmwaresList: null,
    error: null,
    isLoading: true,
    allDefaultFirmwares: [],
    loadFirmwaresError: '',
    devicesTypes: [],
    subTypesForDevice: [],
    firmwareTypesForDevice: [],
    isReadOnly: false,
    versionsForFirmwareType: [],
  },
  action
) => {
  switch (action.type) {
    case rootActions.actionTypes.SETUP_PERMISSIONS: {
      const { permissions } = action.payload

      return {
        ...state,
        isReadOnly: !permissions[PAGES.firmware].write,
      }
    }
    case firmwaresActions.actionTypes.UPLOAD_FIRMWARE:
    case firmwaresActions.actionTypes.GET_DEFAULT_FIRMWARES:
    case firmwaresActions.actionTypes.LOAD_FIRMWARES: {
      return {
        ...state,
        isLoading: true,
        loadFirmwaresError: '',
      }
    }
    case firmwaresActions.actionTypes.LOAD_FIRMWARES_SUCCESS: {
      return {
        ...state,
        firmwaresList: action.payload.firmwares,
        isLoading: false,
        loadFirmwaresError: '',
      }
    }
    case firmwaresActions.actionTypes.UPLOAD_FIRMWARE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case firmwaresActions.actionTypes.LOAD_FIRMWARE_TYPES_FOR_DEVICE_FAILED:
    case firmwaresActions.actionTypes.DELETE_FIRMWARE_FAILED:
    case firmwaresActions.actionTypes.UPLOAD_FIRMWARE_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    }
    case firmwaresActions.actionTypes.LOAD_FIRMWARES_FAILED: {
      return {
        ...state,
        loadFirmwaresError: action.payload.message,
        isLoading: false,
      }
    }
    case firmwaresActions.actionTypes.RESET_ERROR: {
      return {
        ...state,
        error: '',
      }
    }
    case firmwaresActions.actionTypes.GET_DEFAULT_FIRMWARES_SUCCESS: {
      return {
        ...state,
        allDefaultFirmwares: action.payload.allDefaultFirmwares,
        isLoading: false,
      }
    }
    case firmwaresActions.actionTypes
      .LOAD_FIRMWARE_TYPES_FOR_DEVICE_SUCCEEDED: {
      return {
        ...state,
        firmwareTypesForDevice: prepareTypes(
          action.payload.firmwareTypesForDevice
        ),
      }
    }
    case firmwaresActions.actionTypes
      .LOAD_VERSIONS_FOR_FIRMWARE_TYPE_SUCCEEDED: {
      return {
        ...state,
        versionsForFirmwareType: action.payload.versions,
      }
    }
    case firmwaresActions.actionTypes.RESET_VERSIONS_FOR_FIRMWARE_TYPE: {
      return {
        ...state,
        versionsForFirmwareType: [],
      }
    }
    default:
      return state
  }
}
