import { useEffect } from 'react'
import {
  FEATURES_LABELS,
  PAGE_TITTLE,
} from './services/featureToggling-constants'

import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import ErrorHasOccurred from '../../components/_shared/errors/ErrorHasOccurred'
import NetworkError from '../../components/_shared/errors/NetworkError'
import FeaturesTable from '../../components/_shared/FeaturesTable'
import LoaderComponent from '../../components/_shared/loader/Loader'
import { checkConnection } from '../../services/root/root-service'
import actions from './services/featureToggling-actions'
import styles from './style'

const FeatureTogglingPage = (props) => {
  const {
    classes,
    getFeatures,
    getFeaturesError,
    networkError,
    isLoading,
    features,
    updateFeatureState,
    error,
    isReadOnly,
  } = props

  useEffect(() => {
    getFeatures()
    // eslint-disable-next-line
  }, [])

  return (
    <LoaderComponent isLoading={isLoading && !networkError}>
      <div className={classes.featuresPageContainer}>
        <Box textAlign="center" marginBottom="30px">
          <Typography variant="h2">{PAGE_TITTLE}</Typography>
        </Box>
        {networkError ? (
          <NetworkError />
        ) : getFeaturesError ? (
          <ErrorHasOccurred hideLogo />
        ) : (
          <FeaturesTable
            updateFeatureState={updateFeatureState}
            labelsConfig={FEATURES_LABELS}
            features={features}
            error={error}
            isReadOnly={isReadOnly}
          />
        )}
      </div>
    </LoaderComponent>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFeatures: () => dispatch(checkConnection(actions.getFeatures)),
    updateFeatureState: (id, state) =>
      dispatch(checkConnection(() => actions.updateFeatureState(id, state))),
  }
}

const mapStateToProps = (state) => ({
  getFeaturesError: state.featureTogglingReducer.getFeaturesError,
  networkError: state.rootReducer.networkError,
  isLoading: state.featureTogglingReducer.isLoading,
  features: state.featureTogglingReducer.features,
  error: state.featureTogglingReducer.error,
  isReadOnly: state.featureTogglingReducer.isReadOnly,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FeatureTogglingPage))
