import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  tableContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    '& table': {
      width: '100%',
    },
  },
  tableTitle: {
    margin: '20px 10px 20px 0',
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableHeaderTooltip: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tooltip: {
    fontSize: '12px',
  },
})

export const jsonTableStyles = {
  rootElement: {
    width: '50%',
  },
}
