import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import { PAGINATION } from '../../../services/constants'
import LoaderComponent from '../loader/Loader'
import Body from './components/Body'
import Header from './components/Header'
import Toolbar from './components/Toolbar'

const MaterialTable = (props) => {
  const {
    multi, // specify weather it is possible to select multiple items
    tableData,
    tableTitle,
    cellsConfig,
    tableLabels,
    selected,
    setSelected,
    page,
    setPage,
    isSelectable,
    isLoading,
    totalCount,
    rowsPerPage,
    setRowsPerPage,
  } = props

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const value = +event.target.value

    setRowsPerPage(value)
    setPage(0)
  }

  return (
    <LoaderComponent isLoading={isLoading}>
      <Toolbar multi={multi} tableTitle={tableTitle} selected={selected} />
      <TableContainer component={Paper}>
        <Table>
          <Header
            multi={multi}
            tableData={tableData}
            tableLabels={tableLabels}
            selected={selected}
            setSelected={setSelected}
          />
          <Body
            multi={multi}
            isSelectable={isSelectable}
            tableData={tableData}
            cellsConfig={cellsConfig}
            selected={selected}
            setSelected={setSelected}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={PAGINATION.perPageValues}
        component="div"
        count={totalCount || tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </LoaderComponent>
  )
}

export default MaterialTable
