import { useEffect } from 'react'
import {
  failedDevicesCellsConfig,
  FAILED_DEVICES_LABELS,
  FAILED_DEVICES_TABLE_LABELS,
} from './services/failedDevices-constants'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { connect } from 'react-redux'
import ErrorHasOccurred from '../../components/_shared/errors/ErrorHasOccurred'
import NetworkError from '../../components/_shared/errors/NetworkError'
import MaterialTable from '../../components/_shared/materialTable/MaterialTable'
import { checkConnection } from '../../services/root/root-service'
import actions from './services/failedDevices-actions'
import styles from './style'

const FailedDevicesPage = (props) => {
  const {
    getFailedDevices,
    classes,
    devices,
    error,
    networkError,
    isLoading,
    totalCount,
    page,
    goToNextPage,
    rowsPerPage,
    changeRowsPerPage,
    goToPreviousPage,
    resetFailedDevices,
  } = props

  useEffect(() => {
    getFailedDevices()

    return () => {
      resetFailedDevices()
    }
    // eslint-disable-next-line
  }, [])

  const changeCurrentPage = (newPage) => {
    const isDevicesWasLoaded = devices.length <= newPage * rowsPerPage

    if (newPage > page && isDevicesWasLoaded) {
      goToNextPage(newPage)
    } else {
      goToPreviousPage(newPage)
    }
  }

  return (
    <div className={classes.root}>
      <Button
        href="#/devices"
        className={classes.actionButton}
        startIcon={<ArrowBackIcon />}
        color="primary"
      >
        {FAILED_DEVICES_LABELS.backToDevices}
      </Button>
      {networkError ? (
        <NetworkError />
      ) : error ? (
        <ErrorHasOccurred hideLogo={true} />
      ) : (
        <Paper className={classes.paper}>
          <MaterialTable
            tableData={devices}
            isSelectable={false}
            tableTitle={FAILED_DEVICES_LABELS.devices}
            tableLabels={FAILED_DEVICES_TABLE_LABELS}
            cellsConfig={failedDevicesCellsConfig}
            totalCount={totalCount}
            page={page}
            setPage={changeCurrentPage}
            setRowsPerPage={changeRowsPerPage}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
          />
        </Paper>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getFailedDevices: (query) =>
    dispatch(checkConnection(() => actions.getFailedDevices(query))),
  goToNextPage: (page) =>
    dispatch(checkConnection(() => actions.goToNextPage(page))),
  goToPreviousPage: (page) => dispatch(actions.goToPreviousPage(page)),
  changeRowsPerPage: (value) => dispatch(actions.setRowsPerPage(value)),
  resetFailedDevices: () => dispatch(actions.resetFailedDevices()),
})

const mapStateToProps = (state) => ({
  devices: state.failedDevicesReducer.devices,
  error: state.failedDevicesReducer.error,
  networkError: state.failedDevicesReducer.networkError,
  isLoading: state.failedDevicesReducer.isLoading,
  totalCount: state.failedDevicesReducer.totalCount,
  page: state.failedDevicesReducer.page,
  rowsPerPage: state.failedDevicesReducer.rowsPerPage,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FailedDevicesPage))
