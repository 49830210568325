import { createRef, useState } from 'react'
import {
  BUTTONS_LABEL,
  HELPER_TEXT,
  LINKS,
  LOGIN_LABELS,
} from '../../services/authorization-constants'

import { Link } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import eqipment from '../../../../assets/images/eqipment.png'
import logo from '../../../../assets/images/logo.jpg'
import { IMG_ALT } from '../../../../services/constants'
import { withMediaQuery } from '../../../../services/root/root-service'
import actions from '../../services/authorization-actions'
import styles from './style'

const LogInPageComponent = (props) => {
  const {
    classes,
    error,
    logIn,
    resetPassword,
    resetPasswordsError,
    resetLoginErrors,
    deactivatedError,
    isMobile,
  } = props
  const loginRef = createRef()
  const passwordRef = createRef()
  const [isLoginEmpty, setLoginEmpty] = useState(false)
  const [isPasswordEmpty, setPasswordEmpty] = useState(false)
  const [resetPasswordRequest, setResetPassword] = useState(false)
  const resetPasswordSending = useAppSelector(
    (state) => state.authorizationReducer.resetPasswordSending
  )
  const loginInProgress = useAppSelector(
    (state) => state.authorizationReducer.loginInProgress
  )

  const goToDashboard = () => {
    window.location.href = '/#/dashboard'
  }

  const onSubmit = () => {
    const login = loginRef.current.value
    const password = passwordRef.current.value

    resetLoginErrors()

    if (login && password) {
      const body = {
        login,
        password,
      }

      logIn(body, goToDashboard)
    } else {
      setLoginEmpty(!login)
      setPasswordEmpty(!password)
    }
  }

  const forgetPassword = () => {
    resetLoginErrors()

    if (loginRef.current.value) {
      setLoginEmpty(false)
      resetPassword(loginRef.current.value, () => setResetPassword(true))
    } else {
      setLoginEmpty(true)
    }
  }

  return (
    <div className={isMobile ? classes.mobileLogin : classes.desktopLogin}>
      <div
        className={
          isMobile ? classes.loginWrapperMobile : classes.loginWrapperDesktop
        }
      >
        <img className={classes.logoImage} src={logo} alt={IMG_ALT.LOGO} />
        <Typography variant="subtitle2" color="textSecondary">
          {HELPER_TEXT.signIn}
        </Typography>
        <FormHelperText
          error
          className={
            resetPasswordsError && resetPasswordRequest
              ? ''
              : classes.helperTextInvisible
          }
        >
          {resetPasswordsError}
        </FormHelperText>
        <FormHelperText
          error
          className={deactivatedError ? '' : classes.helperTextInvisible}
        >
          {deactivatedError}
        </FormHelperText>
        <TextField
          id="outlined-basic"
          label={LOGIN_LABELS.email}
          required
          error={!!(error || isLoginEmpty)}
          inputRef={loginRef}
          className={classes.loginInput}
          variant="outlined"
        />
        <TextField
          id="outlined-basic"
          label={LOGIN_LABELS.password}
          type="password"
          required
          inputRef={passwordRef}
          error={!!(error || isPasswordEmpty)}
          className={classes.loginInput}
          variant="outlined"
        />
        <FormHelperText
          error
          className={error ? '' : classes.helperTextInvisible}
        >
          {HELPER_TEXT.errorMessage}
        </FormHelperText>
        <Button
          variant="contained"
          className={classes.loginButton}
          onClick={onSubmit}
          color="primary"
          disabled={loginInProgress}
          endIcon={
            loginInProgress ? (
              <CircularProgress size={20} color="primary.contrastText" />
            ) : null
          }
        >
          {BUTTONS_LABEL.login}
        </Button>
        {resetPasswordRequest === false ? (
          <Link
            component="button"
            variant="body2"
            color="textSecondary"
            className={classes.forgotPassword}
            onClick={forgetPassword}
            disabled={resetPasswordSending}
          >
            {LINKS.forgotPassword}
          </Link>
        ) : (
          <Box bgcolor="warning.main" color="warning.contrastText" p={2}>
            <Typography variant="subtitle1">
              {HELPER_TEXT.resetPasswordEmailSent}
            </Typography>
          </Box>
        )}
      </div>
      {!isMobile && (
        <div className={classes.pictureWrapper}>
          <img
            className={classes.lampPictureWrapper}
            src={eqipment}
            alt={IMG_ALT.ARC_DEVICE}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    error: state.authorizationReducer.loginError,
    resetPasswordsError: state.authorizationReducer.resetPasswordsError,
    deactivatedError: state.authorizationReducer.deactivatedError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logIn: (body, onSuccess) => dispatch(actions.login(body, onSuccess)),
    resetPassword: (login, onSuccess) =>
      dispatch(actions.resetPassword(login, onSuccess)),
    resetLoginErrors: () => dispatch(actions.resetLoginErrors()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMediaQuery(LogInPageComponent)))
