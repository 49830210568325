const CHANGE_ACCOUNT_OWNER_LABELS = {
  page: 'Change Account Owner',
  firstParagraph:
    'Transferring account ownership will change the primary contact for your organization.',
  secondParagraph:
    'This changes your role to “Admin”, and you will need to log back in to access the dashboard.',
  thirdParagraph:
    'This action can only be undone by the R-Zero support team or the new Account Owner.',
  customSelectLabel: 'New Account Owner',
  createNewUser: 'Create New User ->',
  saveChanges: 'Save Changes',
  currentAccountOwner: 'Current Account Owner: ',
}

const ORGANIZATION_TABLE_LABELS = {
  name: 'Name',
  id: 'ID',
  salesForceId: 'Salesforce ID',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'ZIP',
  type: 'Type',
  active: 'Active',
}

const DETAILS_BUTTON_LABEL = 'Details'

const ORGANIZATION_PAGE_LABELS = {
  organizations: 'Organizations',
  search: 'Search',
  changeOwner: 'Change Owner',
  deactivateOrganization: 'Deactivate Organization',
  activateOrganization: 'Activate Organization',
  addOrganization: 'Add',
}

const CONFIRM_MODAL_LABELS = {
  modalTitle: 'Change Account Owner',
  actionLabel: 'Confirm',
  closeLabel: 'Cancel',
}

const ACTIONS_FOOTER_LABELS = {
  accountOwner: 'Account Owner',
  newAccountOwner: 'New Account Owner',
}

const DEACTIVATE_ORGANIZATION = {
  title: 'Deactivate Organization',
  deactivateText: (name: string) =>
    `Are you sure that you want to deactivate "${name}" organization?`,
  cancel: 'Cancel',
  confirm: 'Deactivate',
}

const ACTIVATE_ORGANIZATION = {
  title: 'Activate Organization',
  deactivateText: (name: string) =>
    `Are you sure that you want to activate "${name}" organization?`,
  cancel: 'Cancel',
  confirm: 'Activate',
}

const FEATURES_MODAL_LABELS = {
  features: 'Features',
  title: 'Feature Toggling',
  noData: 'There is no enable data',
}

const FEATURES_LABELS = {
  toggle: 'OFF / ON',
  title: 'Feature Name',
  description: 'Description',
}

const IDP_MODAL_LABELS = {
  idpButton: 'IDP',
  title: 'Identity Provider',
  save: 'Save',
  delete: 'Delete',
}

const initialValueIdpData = {
  id: '',
  organizationId: '',
  name: '',
  cognitoIssuerUri: '',
  cognitoUserPoolId: '',
  cognitoAppClientId: '',
  cognitoDomain: '',
  callbackUrl: '',
  salesforceId: '',
}

const IDP_FIELDS = [
  {
    name: 'id',
    label: 'id',
    required: false,
    disabled: true,
  },
  {
    name: 'organizationId',
    label: 'Organization id',
    required: false,
    disabled: true,
  },
  {
    name: 'name',
    label: 'Name',
    required: true,
  },
  {
    name: 'cognitoIssuerUri',
    label: 'Cognito Issuer Uri',
    required: true,
  },
  {
    name: 'cognitoUserPoolId',
    label: 'Cognito User Pool Id',
    required: true,
  },
  {
    name: 'cognitoAppClientId',
    label: 'Cognito App Client Id',
    required: true,
  },
  {
    name: 'cognitoDomain',
    label: 'Cognito Domain',
    required: true,
  },
  {
    name: 'callbackUrl',
    label: 'Callback Url',
    required: true,
  },
]

const initialValueOrganizationData = {
  name: '',
  phoneExtension: '',
  secondaryPhone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  timezoneName: '',
  abbreviation: '',
  type: 'CUSTOMER',
  salesForceId: '',
}

const ADD_ORGANIZATION_LABELS = {
  addOrganization: 'Add Organization',
  salesforceId: 'Salesforce Account ID',
  name: 'Organization Name',
  phoneExtension: 'Ext',
  secondaryPhone: 'Secondary Phone',
  address: 'Address',
  city: 'City',
  state: 'State',
  timezoneName: 'Timezone',
  type: 'Type',
  zip: 'Zip',
  save: 'Save',
  cancel: 'Cancel',
}

export const ADD_ORGANIZATIONS_ERRORS = {
  ALREADY_EXISTS: 'This organization already exists',
  UNEXPECTED:
    'Can not save organization at this moment, please try again later',
}

const COUNTRY_STATES = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

export type AddOrganizationDataType = typeof initialValueOrganizationData

export {
  CHANGE_ACCOUNT_OWNER_LABELS,
  CONFIRM_MODAL_LABELS,
  ORGANIZATION_TABLE_LABELS,
  ORGANIZATION_PAGE_LABELS,
  DETAILS_BUTTON_LABEL,
  ACTIONS_FOOTER_LABELS,
  DEACTIVATE_ORGANIZATION,
  ACTIVATE_ORGANIZATION,
  FEATURES_MODAL_LABELS,
  FEATURES_LABELS,
  IDP_MODAL_LABELS,
  initialValueIdpData,
  IDP_FIELDS,
  initialValueOrganizationData,
  ADD_ORGANIZATION_LABELS,
  COUNTRY_STATES,
}
