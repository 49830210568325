import { MenuItem, Select } from '@material-ui/core'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import map from 'lodash/map'
import { useState } from 'react'
import { connect } from 'react-redux'
import { checkConnection } from '../../../../../services/root/root-service'
import actions from '../../../services/devices-actions'
import { ADD_DEVICE_LABELS } from '../services/deviceDetails-constants'
import { useStyles } from './style'

const ChangeDeviceType = (props) => {
  const { initialDeviceType, subTypesForDevice, updateDeviceType, id } = props
  const [deviceType, setDeviceType] = useState(initialDeviceType)
  const [isEditable, setIsEditable] = useState(false)

  const style = useStyles()

  const onChange = (event) => {
    setDeviceType(event.target.value)
  }

  const changeEditPossibility = () => {
    setIsEditable(!isEditable)
  }

  const resetDeviceType = () => {
    setDeviceType(initialDeviceType)
    changeEditPossibility()
  }

  const saveChanges = () => {
    changeEditPossibility()
    updateDeviceType(deviceType, id)
  }

  return (
    <>
      <Select
        name="deviceSubType"
        label={ADD_DEVICE_LABELS.deviceSubType}
        value={deviceType || ''}
        disabled={!isEditable}
        onChange={onChange}
        className={style.deviceSubType}
        required
      >
        {map(subTypesForDevice, (item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {isEditable ? (
        <>
          <IconButton
            className={style.save}
            color="primary"
            onClick={saveChanges}
            aria-label="show more"
          >
            <DoneIcon />
          </IconButton>
          <IconButton
            className={style.reset}
            color="primary"
            onClick={resetDeviceType}
            aria-label="show more"
          >
            <CloseIcon />
          </IconButton>
        </>
      ) : (
        <IconButton
          className={style.edit}
          color="primary"
          onClick={changeEditPossibility}
          aria-label="show more"
        >
          <EditIcon />
        </IconButton>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updateDeviceType: (type, id) =>
    dispatch(checkConnection(() => actions.updateDeviceType(type, id))),
})

const mapStateToProps = (state) => ({
  subTypesForDevice: state.rootReducer.subTypesForDevice,
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDeviceType)
