import { withStyles } from '@material-ui/core/styles'
import map from 'lodash/map'
import { connect } from 'react-redux'
import NoPermissionsError from '../../../components/_shared/errors/NoPermissions'
import { dashboardButtonsConfig } from '../services/dashboard-constants'
import styles from '../style'
import DashboardButtonComponent from './DashboardButton'

const DashboardButtonsContainer = (props) => {
  const { history, permissions, mainPageValues, isAnyPageAvailable, classes } =
    props

  return isAnyPageAvailable ? (
    <div className={classes.container}>
      {map(
        dashboardButtonsConfig,
        (item) =>
          permissions[item.page].read && (
            <DashboardButtonComponent
              key={item.title}
              item={item}
              history={history}
              mainPageValues={mainPageValues}
              id={item.id}
            />
          )
      )}
    </div>
  ) : (
    <NoPermissionsError />
  )
}

const mapStateToProps = (state) => ({
  permissions: state.rootReducer.permissions,
  isAnyPageAvailable: state.rootReducer.isAnyPageAvailable,
  mainPageValues: state.dashboardReducer.mainPageValues,
})

export default connect(mapStateToProps)(
  withStyles(styles)(DashboardButtonsContainer)
)
