import { useCallback, useEffect, useMemo, useState } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { connect } from 'react-redux'
import ErrorHasOccurred from '../../components/_shared/errors/ErrorHasOccurred'
import NetworkError from '../../components/_shared/errors/NetworkError'
import LoaderComponent from '../../components/_shared/loader/Loader'
import { checkConnection } from '../../services/root/root-service'
import styles from '../Organizations/style'
import OrganizationsTable from './components/table/OrganizationTable'
import actions from './services/organization-actions'
import { ORGANIZATION_PAGE_LABELS } from './services/organization-constants'

const OrganizationsPage = (props) => {
  const {
    organizations,
    loadOrganizationError,
    classes,
    networkError,
    isLoading,
    loadOrganizations,
    isReadOnly,
    history,
    selectedId,
  } = props
  const [orgTypeFilter, setOrgTypeFilter] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const filteredOrgs = useMemo(() => {
    return (organizations?.slice(0) || [])
      .filter((org) => {
        return (
          searchTerm == '' ||
          org.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (org.salesForceId || '')
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          org.id.toString().includes(searchTerm.toLowerCase())
        )
      })
      .filter((org) => {
        return orgTypeFilter == true || org.type !== 'INTERNAL'
      })
  }, [organizations, searchTerm, orgTypeFilter])

  useEffect(() => {
    loadOrganizations()
    // eslint-disable-next-line
  }, [])

  const addOrganization = useCallback(() => {
    history.push(`/organizations/add`)
  }, [])

  const handleOrganizationTypeToggler = useCallback(() => {
    setOrgTypeFilter(!orgTypeFilter)
  }, [orgTypeFilter])

  const handleSearchChange = useCallback((event) => {
    const searchTerm = event.target.value.trim()
    setSearchTerm(searchTerm.trim())
  }, [])

  return (
    <LoaderComponent isLoading={isLoading && !networkError}>
      <div className={classes.pageContainer}>
        <Box textAlign="center" margin="30px 0 15px">
          <Typography variant="h2">
            {ORGANIZATION_PAGE_LABELS.organizations}
          </Typography>
        </Box>
        {networkError ? (
          <NetworkError />
        ) : (
          <>
            <div className={classes.actionsWrapper}>
              {!isReadOnly && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                  onClick={addOrganization}
                >
                  {ORGANIZATION_PAGE_LABELS.addOrganization}
                </Button>
              )}
              <TextField
                name="search"
                className={classes.filterField}
                label="Search"
                onChange={handleSearchChange}
                variant="outlined"
              />
              <FormControlLabel
                control={
                  <Switch
                    value={orgTypeFilter}
                    onChange={handleOrganizationTypeToggler}
                  />
                }
                label="Show internals"
              />
            </div>
            {loadOrganizationError ? (
              <ErrorHasOccurred hideLogo={true} />
            ) : (
              <div className={classes.tableWrapper}>
                <OrganizationsTable
                  isReadOnly={isReadOnly}
                  organizations={filteredOrgs}
                  selectedOrganizationId={selectedId}
                />
              </div>
            )}
          </>
        )}
      </div>
    </LoaderComponent>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadOrganizations: () =>
      dispatch(checkConnection(actions.loadOrganizations)),
  }
}

const mapStateToProps = (state) => ({
  organizations: state.organizationReducer.organizations,
  selectedId: state.organizationReducer.selected,
  error: state.organizationReducer.error,
  loadOrganizationError: state.organizationReducer.loadOrganizationError,
  networkError: state.rootReducer.networkError,
  isLoading: state.organizationReducer.isLoading,
  isReadOnly: state.organizationReducer.isReadOnly,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrganizationsPage))
