import {
  checkConnection,
  goToRoute,
} from '../../../../services/root/root-service'
import {
  ACTION_BUTTONS,
  FIRMWARES_TABLE_LABELS,
} from '../../services/firmwares-constants'

import { withStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import actions from '../../services/firmwares-actions'
import FirmwaresTableComponent from './FirmwaresTable'
import style from './style'

const FirmwaresTableContainer = (props) => {
  const { history, loadFirmwares, isReadOnly, classes } = props
  const configForActionButtons = {
    manageDefault: () => goToRoute(history, '/defaultFirmwares'),
    create: () => goToRoute(history, '/firmwares/createFirmware'),
  }

  useEffect(() => {
    loadFirmwares()
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.tableContainer}>
      <Box textAlign="center" margin="15px">
        <Typography variant="h2">{FIRMWARES_TABLE_LABELS.firmwares}</Typography>
      </Box>
      {!isReadOnly && (
        <div className={classes.buttonsWrapper}>
          <Button
            id="manage-default-firmwares"
            variant="contained"
            color="primary"
            className={classes.actionButton}
            onClick={configForActionButtons.manageDefault}
          >
            {ACTION_BUTTONS.manageDefault}
          </Button>
          <Button
            id="create-firmware-button"
            variant="contained"
            color="primary"
            className={classes.actionButton}
            onClick={configForActionButtons.create}
          >
            {ACTION_BUTTONS.create}
          </Button>
        </div>
      )}
      <FirmwaresTableComponent isReadOnly={isReadOnly} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  isReadOnly: state.firmwaresReducer.isReadOnly,
})

const mapDispatchToProps = (dispatch) => ({
  loadFirmwares: () => dispatch(checkConnection(actions.loadFirmwares)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(FirmwaresTableContainer))
