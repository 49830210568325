const styles = (theme) => ({
  root: {
    marginTop: '30px',
    maxHeight: 'calc(100% - 410px)',
    overflowY: 'scroll',
    width: '90%',
    margin: 'auto',
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  comment: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '300px',
  },
  tableCell: {
    maxWidth: '300px',
  },
})

export default styles
