import { useEffect, useState } from 'react'
import {
  ADD_LOCATION_LABELS,
  propertyFieldsInitial,
} from '../../../services/organizationDetails-constants'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import capitalize from 'lodash/capitalize'
import filter from 'lodash/filter'
import split from 'lodash/split'
import trim from 'lodash/trim'
import CloseButton from '../../../../../../../components/_shared/closeButton/CloseButton'
import { Location } from '../../../services/organizationDetails-types'
import ControlPart from './ControlPart'
import { useStyles } from './style'

type AddLocationModalProps = {
  onSubmit: (
    value: string,
    isBulk: boolean,
    locationType: string,
    propertyFields: Partial<Location>
  ) => void
  onClose: () => void
  modalTitle: string
  isOpen: boolean
  locationType: string
  error: string
  isBulk: boolean
  resetError: () => void
}

const AddLocationModal = ({
  onSubmit,
  onClose,
  modalTitle,
  isOpen,
  locationType,
  error,
  isBulk,
  resetError,
}: AddLocationModalProps) => {
  const styles = useStyles()
  const [isEmptyTextField, setIsEmptyTextField] = useState(false)
  const [locationName, setLocationName] = useState('')
  const [propertyFields, setPropertyFields] = useState<Partial<Location>>(
    propertyFieldsInitial
  )
  let timer: any

  useEffect(() => {
    setLocationName('')
    setPropertyFields({ ...propertyFieldsInitial })

    if (!isOpen) {
      resetError()
    }
  }, [isOpen])

  let prevent = true

  const onChangePropertyFields: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { value, name } = event.target

    setPropertyFields((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onCloseModal = () => {
    setIsEmptyTextField(false)

    setPropertyFields(propertyFieldsInitial)

    onClose()
  }

  const onChangeLocationName: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const locationName = e.target.value
    const splitted = split(locationName, '\n')

    const prepared = filter(splitted, (item) => {
      return item && trim(item)
    }).join('\n')

    if (
      splitted[splitted.length - 1] === '\n' ||
      splitted[splitted.length - 1] === ''
    ) {
      setLocationName(prepared + '\n')
    } else {
      setLocationName(prepared)
    }
  }

  const onSubmitButton: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault()
    const value = locationName

    if (value.trim() === '') {
      setIsEmptyTextField(true)
    } else {
      setIsEmptyTextField(false)

      if (error) {
        prevent = true
      }

      timer = setTimeout(function () {
        let result = {
          name: value,
          locationType,
          width: propertyFields.width,
          length: propertyFields.length,
          height: propertyFields.height,
        }
        const isProperty = locationType === 'property'

        if (isProperty) {
          result = { ...result, ...propertyFields }
        }

        if (prevent) {
          onSubmit(value, isBulk, locationType, result)

          prevent = false
        }
      }, 200)
    }
  }

  const handleDoubleClick: React.MouseEventHandler = () => {
    if (timer) {
      clearTimeout(timer)
    }
    prevent = true
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle className={styles.modalTitleContainer} disableTypography>
          <Typography variant={'h4'} className={styles.modalTitle}>
            {modalTitle}
          </Typography>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <CloseButton onCloseModal={onCloseModal} />
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <ControlPart
            handleDoubleClick={handleDoubleClick}
            onSubmitButton={onSubmitButton}
            error={error}
            isBulk={isBulk}
            onChangeLocationName={onChangeLocationName}
            locationName={locationName}
            isEmptyTextField={isEmptyTextField}
            locationType={locationType}
            propertyFields={propertyFields}
            onChangePropertyFields={onChangePropertyFields}
            submitButton={`${ADD_LOCATION_LABELS.add} ${capitalize(
              locationType
            )}`}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddLocationModal
