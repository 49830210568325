import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import ConfirmModal from '../../../../../../../components/_shared/confirmModal/ConfirmModal'
import { DELETE_WIFI_CREDENTIAL } from '../../../services/organizationDetails-actions'

type DeleteCredentialProps = {
  id: string
  ssid: string
}

const DeleteCredential = ({ id, ssid }: DeleteCredentialProps) => {
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()

  const deleteWiFiCredential = (id: string) =>
    dispatch(DELETE_WIFI_CREDENTIAL(id))

  const handleOpen: React.MouseEventHandler = (event) => {
    event.stopPropagation()

    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = () => {
    deleteWiFiCredential(id)

    handleClose()
  }

  const deleteWiFiCredentialWarning = `Are you sure you want to delete WiFi credential with SSID: ${ssid}`

  return (
    <>
      <IconButton color="secondary" onClick={handleOpen} aria-label="show more">
        <CloseIcon />
      </IconButton>
      <ConfirmModal
        onSubmit={onSubmit}
        onClose={handleClose}
        isOpen={open}
        confirmText={deleteWiFiCredentialWarning}
        actionLabel="Delete"
        closeLabel="Close"
        modalTitle="Delete WiFi Credential"
      />
    </>
  )
}

export default DeleteCredential
