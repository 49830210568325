import * as yup from 'yup'

import { Button, Grid, MenuItem, TextField } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  SAVE_ORGANIZATION_DATA_INIT,
  TIMEZONES_BY_ZIP_INIT,
} from '../../services/organization-actions'
import {
  ADD_ORGANIZATION_LABELS,
  COUNTRY_STATES,
  initialValueOrganizationData,
} from '../../services/organization-constants'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Alert } from '@material-ui/lab'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { useAppSelector } from 'utils/reduxHelpers'
import NetworkError from '../../../../components/_shared/errors/NetworkError'
import { ORAGNIZATION_TYPES } from '../../../../services/constants'
import { checkConnection } from '../../../../services/root/root-service'
import { useStyles } from './styles/AddOrganizationPageStyles'

const ZIP_LENGTH = 5

const schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required(),
  phoneExtension: yup.number().typeError('Extension must be a number'),
  secondaryPhone: yup.string(),
  address: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup
    .string()
    .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Enter a valid Zip code')
    .required(),
  timezoneName: yup.string().required('please select a timezone'),
  abbreviation: yup.string().required(),
  salesForceId: yup.string().required(),
})

const formatPhoneEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const val = event.target.value
  const current = val.replace(/\(|\)| /gm, '')

  if (
    isNaN(Number.parseInt(event.target.value[val.length - 1])) ||
    current.length > 10
  ) {
    event.target.value = val.substring(0, val.length - 1)
  } else {
    let num = `(${current.substring(0, 3)}) ${current.substring(
      3,
      6
    )} ${current.substring(6, current.length)}`

    num = num.trim()

    event.target.value = num
  }
}

const AddOrganizationPage = ({ history }: RouteComponentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const savingRef = useRef(false)
  const [formSuccess, setFormSuccess] = useState(false)
  const networkError = useAppSelector((state) => state.rootReducer.networkError)
  const timezoneList = useAppSelector(
    (state) => state.organizationReducer.timezones
  )
  const isLoadingTimezones = useAppSelector(
    (state) => state.organizationReducer.isLoadingTimezones
  )

  const isSaving = useAppSelector(
    (state) => state.organizationReducer.addOrganizationSending
  )

  const generalError = useAppSelector(
    (state) => state.organizationReducer.addOrganizationError
  )

  const handleCancel = () => {
    history.push('/organizations')
  }

  useEffect(() => {
    if (savingRef.current === true && isSaving == false && generalError == '') {
      setTimeout(() => handleCancel(), 1000)
      setFormSuccess(true)
    }
    savingRef.current = isSaving
  }, [isSaving, generalError])

  const formik = useFormik({
    initialValues: initialValueOrganizationData,
    onSubmit: (values) => {
      dispatch(checkConnection(() => SAVE_ORGANIZATION_DATA_INIT(values)))
    },
    validationSchema: schema,
  })

  useEffect(() => {
    const timezoneSelected = timezoneList.find(
      (tz) => tz.timezoneName === formik.values.timezoneName
    )
    if (timezoneSelected) {
      formik.setFieldValue('abbreviation', timezoneSelected.abbreviation)
    }
  }, [formik.values.timezoneName])

  useEffect(() => {
    const zip = formik.values.zip

    if (zip && !formik.errors.zip && zip.toString().length === ZIP_LENGTH) {
      dispatch(TIMEZONES_BY_ZIP_INIT(zip))
    }
  }, [formik.values.zip, formik.errors.zip, dispatch])

  return networkError ? (
    <NetworkError />
  ) : (
    <div>
      <Box textAlign="center" marginBottom="15px">
        <Typography variant="h2">
          {ADD_ORGANIZATION_LABELS.addOrganization}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit} noValidate>
        {generalError && <Alert severity="error">{generalError}</Alert>}
        {formSuccess && <Alert severity="success">Organization Created!</Alert>}

        <Grid container spacing={2} className={classes.fieldsContainer}>
          <Grid item xs={6}>
            <TextField
              name="name"
              variant="outlined"
              label={ADD_ORGANIZATION_LABELS.name}
              value={formik.values.name}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
              required
              fullWidth
            />

            <TextField
              name="salesForceId"
              variant="outlined"
              label={ADD_ORGANIZATION_LABELS.salesforceId}
              value={formik.values.salesForceId}
              error={
                formik.touched.salesForceId && !!formik.errors.salesForceId
              }
              helperText={
                formik.touched.salesForceId && formik.errors.salesForceId
              }
              onChange={formik.handleChange}
              required
              fullWidth
            />

            <div className={classes.phoneAndExtension}>
              <TextField
                name="secondaryPhone"
                variant="outlined"
                label={ADD_ORGANIZATION_LABELS.secondaryPhone}
                value={formik.values.secondaryPhone}
                error={
                  formik.touched.secondaryPhone &&
                  !!formik.errors.secondaryPhone
                }
                helperText={
                  formik.touched.secondaryPhone && formik.errors.secondaryPhone
                }
                onChange={formik.handleChange}
                onKeyUp={formatPhoneEvent}
              />
              <TextField
                name="phoneExtension"
                variant="outlined"
                label={ADD_ORGANIZATION_LABELS.phoneExtension}
                value={formik.values.phoneExtension}
                error={
                  formik.touched.phoneExtension &&
                  !!formik.errors.phoneExtension
                }
                helperText={
                  formik.touched.phoneExtension && formik.errors.phoneExtension
                }
                onChange={formik.handleChange}
              />
            </div>
            <TextField
              name="type"
              variant="outlined"
              select
              label={ADD_ORGANIZATION_LABELS.type}
              value={formik.values.type}
              error={formik.touched.type && !!formik.errors.type}
              helperText={formik.touched.type && formik.errors.type}
              onChange={formik.handleChange}
              required
              fullWidth
            >
              {ORAGNIZATION_TYPES.map(({ id, description }) => (
                <MenuItem value={id} key={id}>
                  {description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="address"
              variant="outlined"
              label={ADD_ORGANIZATION_LABELS.address}
              value={formik.values.address}
              error={formik.touched.address && !!formik.errors.address}
              helperText={formik.touched.address && formik.errors.address}
              onChange={formik.handleChange}
              required
              fullWidth
            />

            <TextField
              name="city"
              variant="outlined"
              label={ADD_ORGANIZATION_LABELS.city}
              value={formik.values.city}
              error={formik.touched.city && !!formik.errors.city}
              helperText={formik.touched.city && formik.errors.city}
              onChange={formik.handleChange}
              required
              fullWidth
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="state"
                  variant="outlined"
                  select
                  label={ADD_ORGANIZATION_LABELS.state}
                  value={formik.values.state}
                  error={formik.touched.state && !!formik.errors.state}
                  helperText={formik.touched.state && formik.errors.state}
                  onChange={formik.handleChange}
                  required
                  fullWidth
                >
                  {COUNTRY_STATES.map((state) => (
                    <MenuItem value={state} key={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="zip"
                  variant="outlined"
                  label={ADD_ORGANIZATION_LABELS.zip}
                  value={formik.values.zip}
                  error={!!formik.errors.zip}
                  helperText={formik.errors.zip}
                  onChange={formik.handleChange}
                  required
                />
              </Grid>
            </Grid>
            <TextField
              name="timezoneName"
              variant="outlined"
              select
              label={ADD_ORGANIZATION_LABELS.timezoneName}
              value={formik.values.timezoneName}
              disabled={isLoadingTimezones}
              error={
                formik.touched.timezoneName && !!formik.errors.timezoneName
              }
              helperText={
                isLoadingTimezones
                  ? 'Loading...'
                  : formik.touched.timezoneName && formik.errors.timezoneName
              }
              onChange={formik.handleChange}
              required
              fullWidth
            >
              {timezoneList.map(({ timezoneName }) => (
                <MenuItem value={timezoneName} key={timezoneName}>
                  {timezoneName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <div className={classes.actionButtons}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSaving || formSuccess}
          >
            {isSaving ? 'Saving ...' : ADD_ORGANIZATION_LABELS.save}
          </Button>
          <Button variant="contained" color="primary" onClick={handleCancel}>
            {ADD_ORGANIZATION_LABELS.cancel}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default AddOrganizationPage
