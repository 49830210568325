import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: 'red',
  },
  modalTitle: {
    paddingTop: '30px',
    textTransform: 'uppercase',
  },
  dialogContent: {
    paddingBottom: '30px',
  },
  formWrapper: {
    marginTop: '10px',
  },
  error: {
    marginBottom: '10px',
  },
  rowIconButton: {},
  outlined: {
    [theme.breakpoints.up('lg')]: {
      padding: '15.5px 14px',
    },
  },
  submitButton: {
    marginTop: '20px',
  },
  '@media (min-width: 1224px)': {
    submitButton: {
      width: '100%',
    },
  },
  input: {
    marginRight: '20px',
  },
  modalTitleContainer: {
    paddingBottom: 0,
    fontSize: 'inherit',
  },
}))
