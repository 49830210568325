import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  PUT_WIFI_CREDENTIAL,
  RESET_VALIDATION_ERRORS,
} from '../../../services/organizationDetails-actions'
import {
  WIFI,
  wifiCredentialInitialState,
  WIFI_FIELDS,
} from '../../../services/organizationDetails-constants'
import {
  PutWifiCredential,
  WifiCredential,
} from '../../../services/organizationDetails-types'

import CloseIcon from '@material-ui/icons/Close'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import { useStyles } from './style'

type AddCredentialModalProps = {
  isOpen: boolean
  onClose: () => void
  organizationId: string
  mode: string
  currentCredential?: WifiCredential
}

const AddCredentialModal = ({
  isOpen,
  onClose,
  organizationId,
  mode,
  currentCredential,
}: AddCredentialModalProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [formState, setFormState] = useState<WifiCredential>(
    isNil(currentCredential)
      ? { ...wifiCredentialInitialState }
      : { ...currentCredential }
  )
  const [checked, setChecked] = useState(false)

  const validationErrors = useAppSelector(
    (state) => state.organizationDetailsReducer.validationErrors
  )

  const putWiFiCredential = (body: PutWifiCredential, onSuccess: () => void) =>
    dispatch(PUT_WIFI_CREDENTIAL(body, onSuccess))
  const resetValidationErrors = () => dispatch(RESET_VALIDATION_ERRORS())

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value })
  }

  const handleChecked: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setChecked(event.target.checked)
  }

  const onSave = () => {
    putWiFiCredential({ organizationId, ...formState }, handleClose)
  }

  const handleClose = () => {
    if (mode === WIFI.add) {
      setFormState({ ...wifiCredentialInitialState })
    }

    resetValidationErrors()
    onClose()
  }

  return (
    <Dialog open={isOpen} keepMounted onClose={handleClose} maxWidth="sm">
      <DialogTitle className={styles.modalTitleContainer} disableTypography>
        <Typography
          variant="h5"
          color="textPrimary"
          className={styles.modalTitle}
        >
          {`${mode} ${WIFI.credentials}`}
        </Typography>
        <IconButton
          className={styles.closeIcon}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {validationErrors.message && (
          <Typography variant="body2" color="error">
            {validationErrors.message}
          </Typography>
        )}
        {map(WIFI_FIELDS, (value, key) => {
          if (key !== 'id') {
            return (
              <TextField
                key={key}
                name={key}
                variant="outlined"
                label={value}
                type={key === 'password' && !checked ? 'password' : 'text'}
                value={String(formState[key as keyof WifiCredential])}
                error={!!validationErrors[key]}
                onChange={onChange}
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                }}
                helperText={validationErrors[key] || ''}
                required={key !== 'password'}
              />
            )
          }
        })}
        <FormControlLabel
          label={WIFI.showPassword}
          classes={{
            label: styles.showPasswordLabel,
          }}
          className={styles.showPassword}
          control={
            <Checkbox
              value={checked}
              onChange={handleChecked}
              color="primary"
            />
          }
        />
        <Button
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={onSave}
        >
          {WIFI.save}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default AddCredentialModal
