import { makeStyles } from '@material-ui/core'
import { COLORS } from '../../../../services/constants'

export const useStyles = makeStyles(
  {
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '7%',
      paddingRight: '7%',
      alignItems: 'center',
    },
    title: {
      paddingBottom: '26px',
      paddingTop: '26px',
    },

    infoBlock: {
      fontSize: '15px',
      lineHeight: '18px',
      paddingBottom: '26px',
    },
    accountInfoBlock: {
      fontSize: '15px',
      lineHeight: '18px',
      paddingBottom: '26px',
      color: COLORS.BLACK,
      fontFamily: 'Cerebri Sans Pro Semi Bold',
    },
    newUserLink: {
      fontSize: '12px',
      lineHeight: '23px',
    },
    changeOwnerButton: {
      position: 'fixed',
      bottom: '41px',
    },
  },
  { index: 1 }
)
