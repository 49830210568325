import { COLORS } from '../../../../../../services/constants'

const style = () => ({
  pageWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '20px 7%',
  },
  pageTitle: {
    margin: '20px 0',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      width: '35%',
      minWidth: '400px',
    },
  },
  editPageWrapper: {
    '& > *': {
      margin: '20px 0',
    },
  },
  submitButton: {
    minWidth: '200px',
  },
  backButton: {
    marginTop: '15px',
    marginRight: '15px',
    '&.MuiIconButton-root': {
      padding: '2px',
    },
  },
  backIcon: {
    width: '28px',
    height: '28px',
    color: COLORS.BLUE,
  },
})

export default style
