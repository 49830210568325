import { COLORS } from '../../services/constants'

const styles = (theme) => ({
  firmwaresPage: {
    height: 'calc(100% - 155px)',
  },
  backButton: {
    color: '#1796FF',
    padding: '7px',
    marginRight: '10px',
  },
  defaultFirmwaresMessage: {
    backgroundColor: COLORS.GREEN,
    color: COLORS.BLACK,
  },
  '@media (min-width: 1224px)': {
    firmwaresPage: {
      padding: '50px',
    },
  },
})

export default styles
