import { DEVICE_TYPE } from '../../../../../../../services/constants'

export const deviceType = ({ deviceType, deviceSubType }) => {
  if (deviceType === DEVICE_TYPE.EXT_DEVICE.type) {
    return 'External Device'
  } else if (deviceType === DEVICE_TYPE.UR_UVGI.type) {
    if (deviceSubType === DEVICE_TYPE.UR_UVGI.type) {
      return 'Beam'
    } else {
      return 'Far UV'
    }
  } else if (deviceType === DEVICE_TYPE.NETWORK_DEVICE.type) {
    if (deviceSubType === DEVICE_TYPE.HUB.type) {
      return 'Hub'
    } else {
      return 'Hub Relay'
    }
  } else if (deviceType === DEVICE_TYPE.UVI_ARC.type) {
    if (deviceSubType === DEVICE_TYPE.UVI_ARC_REV_B.type) {
      return 'ARC (B)'
    } else if (deviceSubType === DEVICE_TYPE.UVI_ARC_REV_D.type) {
      return 'ARC (D)'
    } else {
      return 'ARC'
    }
  }
}
