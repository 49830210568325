import { useEffect, useState } from 'react'
import {
  ADD_DEVICE_LABELS,
  deviceDetailsErrors,
  initialStateDeviceDetails,
} from '../../services/deviceDetails-constants'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import forIn from 'lodash/forIn'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import { connect } from 'react-redux'
import { DEVICE_TYPE } from '../../../../../../services/constants'
import rootActions from '../../../../../../services/root/root-actions'
import { checkConnection } from '../../../../../../services/root/root-service'
import EditableUVGIFields from '../../../../_shared/EditableUVGIFields'
import DeviceTypeSelect from '../../../List/components/DeviceTypeSelect'
import deviceDetailsActions from '../../services/deviceDetails-actions'
import EditableExtDeviceFields from '../EditableExtDeviceFields'
import EditableNetworkDeviceFields from '../EditableNetworkDeviceFields'
import EditableSensorDeviceFields from '../EditableSensorDeviceFields'
import styles from './style'
import SubTypesSelect from './SubTypesSelect'

const AddDeviceModal = withStyles(styles)((props) => {
  const {
    open,
    onClose,
    addNewDevice,
    error,
    resetErrorAndDevice,
    loadDevicesTypes,
    devicesTypes,
    loadSubTypesForDevice,
    subTypesForDevice,
    classes,
    isLoading,
    getNetworkDeviceTypes,
  } = props
  const [deviceType, setDeviceType] = useState(DEVICE_TYPE.UVI_ARC.type)
  const [subTypes, setSubTypes] = useState([])
  const [state, setState] = useState(initialStateDeviceDetails[deviceType])
  const [errors, setErrors] = useState(deviceDetailsErrors[deviceType])
  const [errorString, setErrorString] = useState('')

  const isArc = deviceType === DEVICE_TYPE.UVI_ARC.type
  const isExternal = deviceType === DEVICE_TYPE.EXT_DEVICE.type
  const isNetwork = deviceType === DEVICE_TYPE.NETWORK_DEVICE.type
  const isSensor = deviceType === DEVICE_TYPE.SENSOR.type
  const isVive = deviceType === DEVICE_TYPE.FAR_UVC.type
  const isBeam = deviceType === DEVICE_TYPE.UR_UVGI.type

  useEffect(() => {
    if (open) {
      if (isEmpty(devicesTypes)) {
        loadDevicesTypes()
      }
      getNetworkDeviceTypes()
      loadSubTypesForDevice(DEVICE_TYPE.UVI_ARC.type)
    }
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (isArc) {
      setSubTypes(subTypesForDevice)
    } else if (isNetwork) {
      setSubTypes([
        {
          label: DEVICE_TYPE.NETWORK_DEVICE.type,
          value: DEVICE_TYPE.NETWORK_DEVICE.type,
        },
      ])
    } else if (isVive) {
      setSubTypes([
        { label: DEVICE_TYPE.VIVE.label, value: DEVICE_TYPE.VIVE.path },
        { label: DEVICE_TYPE.EON.label, value: DEVICE_TYPE.EON.path },
      ])
    } else {
      setSubTypes([
        {
          label: DEVICE_TYPE.EXT_DEVICE.type,
          value: DEVICE_TYPE.EXT_DEVICE.type,
        },
      ])
    }

    setState(initialStateDeviceDetails[deviceType])

    setErrors(deviceDetailsErrors[deviceType])

    resetErrorAndDevice()
    // eslint-disable-next-line
  }, [deviceType])

  useEffect(() => {
    setSubTypes(subTypesForDevice)
  }, [subTypesForDevice])

  const onChange = (event) => {
    let { value, name } = event.target

    if (name === 'mcuUid') {
      value = value.toUpperCase()
    }

    setState({ ...state, [name]: value })
  }

  const checkValidation = () => {
    resetErrorAndDevice()
    const checking = {}

    forIn(state, (value, key) => {
      if (!value) {
        checking[key] = true
      }

      if (key === 'mcuUid' && value) {
        if (value.length !== 24) {
          checking[key] = true

          setErrorString('Mcu Uid length have to be 24 symbols.')
        } else {
          let result

          if (value.slice(0, 20) !== 'TEST9999999999999999') {
            result = /^[A-F|0-9]{1,}$/.test(value)
          } else {
            const tail = value.slice(20, 25)

            result = /^[A-F|0-9]{1,}$/.test(tail)
          }

          if (!result) {
            checking[key] = true

            setErrorString(
              'Mcu Uid have to contain only capital letters A-F and numbers.'
            )
          }
        }

        if (!checking[key]) {
          setErrorString('')
        }
      }

      if (
        (key === 'macAddress' || (isSensor && key === 'hardwareId')) &&
        value &&
        value.replaceAll(':', '').length !== 12
      ) {
        checking[key] = true

        setErrorString(
          'MAC Address length should be 12, excluding characters like :'
        )
      }
    })

    return checking
  }

  const onSubmit = (event) => {
    event.preventDefault()

    const checkedErrors = checkValidation()
    const deviceSubType = !isArc ? deviceType : state.deviceSubType

    setErrors(checkedErrors)

    if (isEmpty(checkedErrors)) {
      addNewDevice(
        deviceType,
        {
          body: omitBy(
            {
              ...state,
              macAddress: state.macAddress?.replaceAll(':', ''),
              hardwareId: state.hardwareId?.replaceAll(':', ''),
              deviceSubType,
            },
            isNil
          ),
        },
        handleClose
      )
    }
  }

  const handleClose = () => {
    setDeviceType(DEVICE_TYPE.UVI_ARC.type)

    setState(initialStateDeviceDetails[DEVICE_TYPE.UVI_ARC.type])
    setErrors(deviceDetailsErrors[DEVICE_TYPE.UVI_ARC.type])

    resetErrorAndDevice()
    setErrorString('')

    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <IconButton
          className={classes.statusFilerIcon}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4">{ADD_DEVICE_LABELS.addDevice}</Typography>
        <Typography variant="subtitle1" color="error" className={classes.error}>
          {errorString || error}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DeviceTypeSelect
          deviceType={deviceType}
          setDeviceType={setDeviceType}
          devicesTypes={devicesTypes}
        />
        <form onSubmit={onSubmit} noValidate>
          {(isArc || isExternal) && (
            <SubTypesSelect
              deviceSubType={state.deviceSubType}
              subTypesForDevice={subTypes}
              onChange={onChange}
              error={errors.deviceSubType}
            />
          )}
          {isVive && (
            <SubTypesSelect
              name="uvgiType"
              deviceSubType={state.uvgiType}
              subTypesForDevice={subTypes}
              onChange={onChange}
              error={errors.deviceSubType}
            />
          )}
          {isExternal && (
            <EditableExtDeviceFields
              state={state}
              setState={setState}
              errors={errors}
              onChange={onChange}
              isEditing={false}
            />
          )}
          {isSensor && (
            <EditableSensorDeviceFields
              state={state}
              setState={setState}
              errors={errors}
              onChange={onChange}
              isEditing={false}
            />
          )}
          {isNetwork && (
            <EditableNetworkDeviceFields
              state={state}
              setState={setState}
              errors={errors}
              onChange={onChange}
              isEditing={false}
            />
          )}
          <TextField
            name="serialNumber"
            id="add-device-serial-number"
            variant="outlined"
            label={ADD_DEVICE_LABELS.serialNumber}
            value={state.serialNumber}
            onChange={onChange}
            error={errors.serialNumber}
            fullWidth
            required
          />
          {isArc && (
            <TextField
              name="barcode"
              id="add-device-imei"
              variant="outlined"
              label={ADD_DEVICE_LABELS.imeiBarcode}
              value={state.barcode}
              onChange={onChange}
              error={errors.barcode}
              fullWidth
              required
            />
          )}

          {(isVive || isBeam) && (
            <EditableUVGIFields
              state={state}
              errors={errors}
              changeTextField={onChange}
              isEditing={false}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            type="submit"
            id="add-device-submit"
            className={classes.actionButton}
            disabled={isLoading}
          >
            {ADD_DEVICE_LABELS.addDevice}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
})

const mapDispatchToProps = (dispatch) => ({
  addNewDevice: (type, params, onSuccess) =>
    dispatch(
      checkConnection(() =>
        deviceDetailsActions.addNewDevice(type, params, onSuccess)
      )
    ),
  resetErrorAndDevice: () => dispatch(deviceDetailsActions.resetError()),
  loadDevicesTypes: () =>
    dispatch(checkConnection(rootActions.loadDevicesTypes)),
  loadSubTypesForDevice: (deviceType) =>
    dispatch(
      checkConnection(() => rootActions.loadSubTypesForDevice(deviceType))
    ),
  getNetworkDeviceTypes: () =>
    dispatch(
      checkConnection(() => deviceDetailsActions.getNetworkDeviceTypes())
    ),
})

const maStateToProps = (state) => {
  const { deviceDetailsReducer } = state.devicesReducer

  return {
    error: deviceDetailsReducer.error,
    devicesTypes: state.rootReducer.devicesTypes,
    subTypesForDevice: state.rootReducer.subTypesForDevice,
    isLoading: deviceDetailsReducer.isLoading,
  }
}

export default connect(maStateToProps, mapDispatchToProps)(AddDeviceModal)
