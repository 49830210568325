const FILTER_LABELS = {
  serialNumber: 'Serial Number',
  firmwareType: 'Firmware Type',
  actualVersion: 'Actual Version',
  desiredVersion: 'Desired Version',
  applyFilter: 'Apply Filter',
  resetFilter: 'Reset Filter',
}

const FIRMWARE_UPDATES_LABELS = {
  firmwareType: 'Firmware Type',
  deviceType: 'Device Type',
  version: 'Version',
  scheduleUpdate: 'Schedule Update',
  cancelUpdate: 'Cancel Update',
  confirm: 'Confirm',
  close: 'Close',
}
const TITLES = {
  devicesFilter: 'Devices Filter',
  scheduleUpdate: 'Schedule Update Firmware Version',
  devices: 'Devices',
}

const FIRMWARE_UPDATE_HEADER = " Devices' firmware"

const getSelectedString = (selected) => `${selected} selected`

const FIRMWARE_TYPES_MAP = {
  BLUETOOTH: 'UVI_ARC',
  MODEM: 'UVI_ARC',
  UR_UVGI_COMBINED: 'UR_UVGI',
}

const initialState = {
  firmwareType: '',
  selected: [],
  firmwareVersion: '',
}

const DATA_FIELDS = {
  firmwareType: 'firmwareType',
}

export {
  FILTER_LABELS,
  FIRMWARE_UPDATES_LABELS,
  TITLES,
  FIRMWARE_UPDATE_HEADER,
  getSelectedString,
  FIRMWARE_TYPES_MAP,
  initialState,
  DATA_FIELDS,
}
