import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    marginTop: '30px',
    maxHeight: 'calc(100% - 250px)',
    overflowY: 'scroll',
    position: 'absolute',
    width: '90%',
  },
  error: {
    margin: '10px auto',
    textAlign: 'center',
  },
  actions: {
    minWidth: '140px',
  },
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px 0',
  },
  filterItem: {
    minWidth: '20%',
    display: 'flex',
  },
  actionButton: {
    width: '200px',
    marginTop: '20px',
    marginRight: '20px',
  },
  filterField: {
    width: '250px',
    marginRight: '20px',
  },
}))
