import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import EmulatorStatistics from '../../_shared/components/EmulatorStatistics'
import { validateNumericFields } from '../../_shared/services/emulator-service'
import { DEVICE_STATISTIC_LABELS } from '../services/uvgiEmulator-constants'
import DeviceInfoTable from './DeviceInfoTable'
import styles from './style'

const DeviceStatistic = (props) => {
  const {
    changeValue,
    changeDateValue,
    bulbChangeDate,
    deviceRevision,
    isSubmitted,
    softwareRevision,
    bulbCyclesSinceChange,
    powerCyclesSinceChange,
    abuseEvents,
    totalLEDTime,
    totalRunTime,
    ledTemp,
    weightedTime,
    commDevicesInfo,
    certificatesInfo,
  } = props
  const isTotalLEDTimeNotValid =
    isSubmitted && !validateNumericFields(totalLEDTime)
  const isTotalRunTimeNotValid =
    isSubmitted && !validateNumericFields(totalRunTime)
  const isLedTempNotValid = isSubmitted && !validateNumericFields(ledTemp)
  const isWeightedTimeNotValid =
    isSubmitted && !validateNumericFields(weightedTime)

  const onChangeValue = (event) => {
    changeValue(event, event.target.name)
  }

  return (
    <>
      <EmulatorStatistics
        date={bulbChangeDate}
        deviceRevision={deviceRevision}
        softwareRevision={softwareRevision}
        bulbCyclesSinceChange={bulbCyclesSinceChange}
        powerCyclesSinceChange={powerCyclesSinceChange}
        abuseEvents={abuseEvents}
        changeValue={onChangeValue}
        changeDateValue={changeDateValue}
        isSubmitted={isSubmitted}
      />
      <TextField
        id="outlined-basic"
        value={totalLEDTime}
        name="totalLEDTime"
        onChange={onChangeValue}
        error={isTotalLEDTimeNotValid}
        required
        type="number"
        label={DEVICE_STATISTIC_LABELS.totalLEDTime}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        value={totalRunTime}
        name="totalRunTime"
        onChange={onChangeValue}
        error={isTotalRunTimeNotValid}
        required
        type="number"
        label={DEVICE_STATISTIC_LABELS.totalRunTime}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        value={ledTemp}
        name="ledTemp"
        onChange={onChangeValue}
        error={isLedTempNotValid}
        required
        type="number"
        label={DEVICE_STATISTIC_LABELS.ledTemp}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        value={weightedTime}
        name="weightedTime"
        onChange={onChangeValue}
        error={isWeightedTimeNotValid}
        required
        type="number"
        label={DEVICE_STATISTIC_LABELS.weightedTime}
        variant="outlined"
      />
      <DeviceInfoTable
        changeDateValue={changeDateValue}
        isSubmitted={isSubmitted}
        certificatesInfo={certificatesInfo}
        commDevicesInfo={commDevicesInfo}
      />
    </>
  )
}

export default withStyles(styles)(DeviceStatistic)
