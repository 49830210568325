import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { PAGES } from 'services/constants'
import { useAppSelector } from 'utils/reduxHelpers'
import ErrorHasOccurred from '../../components/_shared/errors/ErrorHasOccurred'
import NetworkError from '../../components/_shared/errors/NetworkError'
import LoaderComponent from '../../components/_shared/loader/Loader'
import UsersTable from './components/UsersTable'
import { LOAD_USERS } from './services/users-actions'
import {
  USERS_ACTIONS_LABELS,
  USERS_PAGE_LABELS,
} from './services/users-constants'
import { useStyles } from './style'

const UsersPage = ({ history }: RouteComponentProps) => {
  const styles = useStyles()

  const loadUsersError = useAppSelector(
    (state) => state.usersReducer.loadUsersError
  )
  const networkError = useAppSelector((state) => state.rootReducer.networkError)
  const isLoading = useAppSelector((state) => state.usersReducer.isLoading)
  const permissions = useAppSelector((state) => state.rootReducer.permissions)
  const canAddUsers = permissions[PAGES.users as keyof typeof permissions].write

  const dispatch = useDispatch()

  const loadUsers = () => dispatch(LOAD_USERS())

  const addUsersClick = useCallback(() => {
    history.push('/users/add')
  }, [])

  useEffect(() => {
    loadUsers()
    // eslint-disable-next-line
  }, [])

  return (
    <LoaderComponent isLoading={isLoading && !networkError}>
      <div className={styles.usersPageContainer}>
        <Box textAlign="center" marginBottom="15px">
          <Typography variant="h2">{USERS_PAGE_LABELS.users}</Typography>
        </Box>
        <Box>
          {canAddUsers && (
            <Button
              variant="contained"
              className={styles.userActionBtn}
              onClick={addUsersClick}
              color="primary"
            >
              {USERS_ACTIONS_LABELS.addUser}
            </Button>
          )}
        </Box>
        {networkError ? (
          <NetworkError />
        ) : loadUsersError ? (
          <ErrorHasOccurred hideLogo={true} />
        ) : (
          <UsersTable />
        )}
      </div>
    </LoaderComponent>
  )
}

export default UsersPage
