import { TableCell } from '@material-ui/core'
import { Device } from 'containers/Devices/services/devices-types'
import { REVISION_NAMES } from '../../../services/devicesList-constants'

type DeviceRevisionCellProps = Pick<Device, 'deviceSubType'> & {}

export const DeviceRevisionCell = ({
  deviceSubType,
}: DeviceRevisionCellProps) => (
  <TableCell>
    {REVISION_NAMES[deviceSubType as keyof typeof REVISION_NAMES]}
  </TableCell>
)
