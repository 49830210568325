import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextareaAutosize,
  TextField,
} from '@material-ui/core'
import { useEffect, useState } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Autocomplete } from '@mui/material'
import { connect } from 'react-redux'
import { checkConnection } from '../../../../../../services/root/root-service'
import { useStyles } from '../style'
import AllowListTable from './AllowListTable'
import allowListActions from './services/AllowList-actions'
import { deviceType } from './services/AllowList-service'

const AllowList = (props) => {
  const {
    addDeviceError,
    allowList,
    allowListBulkAddClearError,
    bulkAddDeviceError,
    bulkAddModalShown,
    classes,
    hidden,
    hubId,
    searchAvailableDeviceList,
    searchResults,
    allowListAddDevice,
    allowListBulkAddDevices,
    toggleBulkAddModal,
    isLoading,
  } = props

  const style = useStyles()

  const [query, setQuery] = useState()
  const [selectedDevice, setSelectedDevice] = useState()
  const [bulkUploadDeviceSerialNumbers, setBulkUploadDeviceSerialNumbers] =
    useState()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const onBulkUploadCancel = () => {
    toggleBulkAddModal()
    setBulkUploadDeviceSerialNumbers()
    allowListBulkAddClearError()
  }

  const addBulkDevices = () => {
    allowListBulkAddDevices(
      hubId,
      bulkUploadDeviceSerialNumbers.split('\n').flatMap((x) => x.split(',')),
      () => setSnackbarMessage('Devices added to allow list')
    )
  }

  useEffect(() => {
    if (snackbarMessage) {
      setSnackbarOpen(true)
    }
  }, [snackbarMessage])

  useEffect(() => {
    if (!snackbarOpen) {
      setSnackbarMessage('')
    }
  }, [snackbarOpen])

  useEffect(() => {
    if (query?.length) {
      searchAvailableDeviceList(hubId, query)
    }
  }, [query])

  if (hidden) {
    return null
  }

  return (
    <>
      <div className={style.allowList}>
        <Box fullWidth>
          <div className={style.allowListHeader}>
            <Typography variant="h5">Allow List</Typography>
            <Autocomplete
              id="allow-list-search"
              sx={{ width: 300 }}
              getOptionLabel={(option) => {
                return typeof option === 'string' ? option : option.serialNumber
              }}
              filterOptions={(x) => x}
              options={searchResults.filter((x) =>
                x.serialNumber.toUpperCase().includes(query?.toUpperCase())
              )}
              filterSelectedOptions
              includeInputInList
              value={query}
              onChange={(event, newValue) => {
                setSelectedDevice(newValue)
                if (newValue) {
                  setQuery(newValue.serialNumber)
                } else {
                  setQuery(undefined)
                }
              }}
              onInputChange={(event, newInputValue) => {
                setQuery(newInputValue)
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Box container alignItems="center">
                      <Box item xs color="#aaa" fontSize="10px">
                        {deviceType(option)}
                      </Box>
                      <Box item>{option.serialNumber}</Box>
                    </Box>
                  </li>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={style.deviceSearch}
                />
              )}
            />
            <Button
              variant="contained"
              className={style.button}
              color="primary"
              onClick={() => {
                allowListAddDevice(hubId, selectedDevice.id, () =>
                  setSnackbarMessage('Device has been added to the allow list.')
                )
              }}
              disabled={!selectedDevice}
            >
              Add Device
            </Button>
            <Button
              variant="contained"
              className={style.button}
              color="primary"
              onClick={() => toggleBulkAddModal()}
            >
              <AddIcon />
              <div>Bulk Upload Devices</div>
            </Button>
            <Box fullWidth xs color="error" hidden={!addDeviceError}>
              {addDeviceError}
            </Box>
          </div>
        </Box>

        <Accordion className={style.allowList}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            classes={{ content: style.accordionSummary }}
          >
            <Typography variant="h5">{`${
              allowList?.length || 0
            } Devices`}</Typography>
          </AccordionSummary>
          <AccordionDetails hidden={!allowList?.length}>
            <AllowListTable hubId={hubId} />
          </AccordionDetails>
        </Accordion>
      </div>
      <Dialog open={bulkAddModalShown} onClose={onBulkUploadCancel}>
        <DialogTitle className={style.dialogTitle} disableTypography>
          <Box className={style.bulkUploadHeader}>
            <IconButton
              className={style.closeIcon}
              aria-label="close"
              onClick={onBulkUploadCancel}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h4">Bulk Add Devices To Allow List</Typography>
          </Box>
        </DialogTitle>
        <DialogContent className={style.bulkUploadContent}>
          <Box fullWidth color="error" hidden={!bulkAddDeviceError}>
            <Typography variant="h5" color="error">
              {bulkAddDeviceError}
            </Typography>
          </Box>
          <Box fullWidth>
            <TextareaAutosize
              value={bulkUploadDeviceSerialNumbers}
              onChange={(event, value) => {
                setBulkUploadDeviceSerialNumbers(event.target.value)
              }}
              className={style.bulkUploadInput}
              minRows={10}
              maxRows={30}
            />
          </Box>
          <Box
            fullWidth
            alignItems="center"
            justifyContent="space-around"
            gap="10px"
            display="flex"
          >
            <Button onClick={onBulkUploadCancel} variant="outlined">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={addBulkDevices}
              disabled={!bulkUploadDeviceSerialNumbers?.length || isLoading}
            >
              {!isLoading ? 'Add Devices' : 'Saving ...'}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        className={style.snackbar}
        autoHideDuration={1000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const { devicesReducer } = state

  const { allowListReducer } = devicesReducer

  return {
    addDeviceError: allowListReducer.addDeviceError,
    bulkAddDeviceError: allowListReducer.bulkAddDeviceError,
    allowList: allowListReducer.allowList || [],
    bulkAddModalShown: !!allowListReducer.bulkAddModalShown,
    searchResults: allowListReducer.searchResults || [],
    isLoading: allowListReducer.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  searchAvailableDeviceList: (deviceId, query) =>
    dispatch(
      checkConnection(() =>
        allowListActions.searchAvailableDeviceList(deviceId, query)
      )
    ),
  allowListAddDevice: (hubId, deviceId, callback) =>
    dispatch(
      checkConnection(() =>
        allowListActions.allowListAddDevice(hubId, deviceId, callback)
      )
    ),
  allowListBulkAddDevices: (hubId, serialNumbers, callback) =>
    dispatch(
      checkConnection(() =>
        allowListActions.allowListBulkAddDevices(hubId, serialNumbers, callback)
      )
    ),
  toggleBulkAddModal: () =>
    dispatch(checkConnection(() => allowListActions.toggleBulkAddModal())),
  allowListBulkAddClearError: () =>
    dispatch(
      checkConnection(() => allowListActions.allowListBulkAddClearError())
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AllowList)
