const styles = (theme) => ({
  root: {
    marginTop: '30px',
    maxHeight: 'calc(100% - 170px)',
    overflowY: 'auto',
    position: 'absolute',
    width: '90%',
    marginLeft: '5%',
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  actionButton: {
    minWidth: '250px',
    marginLeft: '10px',
  },
})

export default styles
