import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  receiverWrapper: {
    width: '90%',
    padding: '48px 0',
  },
  commandDataWrapper: {
    display: 'flex',
    marginBottom: '8px',
    padding: '16px',
    border: `1px solid ${COLORS.GRAY_SILVER}`,
    borderRadius: '10px',
  },
  rowItem: {
    display: 'flex',
    flex: '1 0 30%',
    minHeight: '52px',
    backgroundColor: COLORS.GRAY_SEASHELL,
    margin: '4px 0',
  },
  keyItem: {
    backgroundColor: COLORS.GRAY_SILVER,
    fontWeight: 'bold',
    borderRadius: '5px',
    padding: '4px 8px',
    margin: 'auto 0',
  },
  valueItem: {
    minWidth: '55%',
    padding: '4px 32px 4px 8px',
    margin: 'auto 0',
  },
  valueItemEditable: {
    minWidth: '55%',
    padding: '0 32px 0 8px',
    margin: 'auto 0',
  },
  refreshButton: {
    width: '100%',
  },
  refreshButtonContainer: {
    marginBottom: '24px',
    width: '15%',
  },
  postButton: {
    marginTop: '15px',
    width: '100%',
  },
  commandData: {
    margin: 'auto 0',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: COLORS.GRAY_SEASHELL,
    padding: '4px',
  },
  statusWrapper: {
    marginLeft: '8px',
  },
  title: {
    textAlign: 'center',
    padding: '44px 26px 30px',
  },
  rowHeader: {
    width: '13%',
    margin: 'auto 32px auto 0',
  },
  helperText: {
    textAlign: 'center',
    color: COLORS.GRAY_JUMBO,
    paddingTop: '44px',
  },
})

export default styles
