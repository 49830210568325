import { Checkbox, FormControlLabel, Switch } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { COLORS, DEVICE_TYPE } from '../../../../../../services/constants'
import {
  hhmmaFormat,
  MMDDYYFormat,
} from '../../../../../../services/dateFormat-service'
import {
  DEVICE_SETTINGS,
  initialSelectedDays,
  MODE_VALUES,
  SNACKBAR_MESSAGES,
} from '../../services/deviceDetails-constants'
import { isAtLeastVersion } from '../../utils'
import { DEFAULT_SETTINGS_ERRORS, SettingsErrors } from './constants'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import some from 'lodash/some'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import { checkConnection } from '../../../../../../services/root/root-service'
import deviceDetailsActions from '../../services/deviceDetails-actions'
import { checkValidation } from '../../services/deviceDetails-service'
import Mode from './Mode'
import SettingsFields from './SettingsFields'
import { useStyles } from './style'

const MINIMUM_DEVICE_VERSION = '2.0.0'

type DeviceSettingsProps = {
  deviceId: string
  onOpenSnackbar: () => any
  setSnackbarMessage: (message: string) => any
}

const DeviceSettings = ({
  onOpenSnackbar,
  setSnackbarMessage,
  deviceId,
}: DeviceSettingsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isUpdatingLabelForCleaning = useAppSelector(
    (state) =>
      state.devicesReducer.deviceDetailsReducer.isUpdatingLabelForCleaning
  )
  const requestError = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.error
  )
  const lastSuccessDeviceCommandTime = useAppSelector(
    (state) =>
      state.devicesReducer.deviceDetailsReducer.lastSuccessDeviceCommandTime
  )
  const cleaningEnabled = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.cleaningEnabled
  )
  const removed = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.removed
  )
  const deviceType = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.deviceType
  )
  const version = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.version
  )
  const mode = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.mode
  )
  const desiredKeepLedAlways = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredKeepLedAlways
  )
  const desiredDays = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredDays
  )
  const desiredPower = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredPower
  )
  const desiredSafetyDelay = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredSafetyDelay
  )
  const desiredOnTime = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredOnTime
  )
  const desiredDailyStartTime = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredDailyStartTime
  )
  const desiredDailyEndTime = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredDailyEndTime
  )
  const desiredOperationWindowRuntimeMax = useAppSelector(
    (state) =>
      state.devicesReducer.deviceDetailsReducer.desiredOperationWindowRuntimeMax
  )
  const desiredOperationWindowSize = useAppSelector(
    (state) =>
      state.devicesReducer.deviceDetailsReducer.desiredOperationWindowSize
  )

  const updateSettings = (id: string, value: any, onSuccess: () => any) =>
    dispatch(
      checkConnection(() =>
        deviceDetailsActions.updateSettings(id, value, onSuccess)
      )
    )
  const disableDevice = (id: string, mode: boolean) =>
    dispatch(
      checkConnection(() => deviceDetailsActions.disableDevice(id, mode))
    )

  const [device, setDevice] = useState({
    desiredDays,
    mode,
    desiredKeepLedAlways,
    desiredPower,
    desiredSafetyDelay,
    desiredOnTime,
    desiredDailyStartTime,
    desiredDailyEndTime,
    desiredOperationWindowRuntimeMax,
    desiredOperationWindowSize,
  })

  useEffect(() => {
    setDevice({
      desiredDays,
      mode,
      desiredKeepLedAlways,
      desiredPower,
      desiredSafetyDelay,
      desiredOnTime,
      desiredDailyStartTime,
      desiredDailyEndTime,
      desiredOperationWindowRuntimeMax,
      desiredOperationWindowSize,
    })
  }, [
    desiredDays,
    mode,
    desiredKeepLedAlways,
    desiredPower,
    desiredSafetyDelay,
    desiredOnTime,
    desiredDailyStartTime,
    desiredDailyEndTime,
    desiredOperationWindowRuntimeMax,
    desiredOperationWindowSize,
  ])

  const [errors, setErrors] = useState<SettingsErrors>(DEFAULT_SETTINGS_ERRORS)
  const [errorsMessage, setErrorsMessage] = useState('')

  const isScheduled = mode === MODE_VALUES.schedule
  const isNotVive = DEVICE_TYPE.FAR_UVC.type !== deviceType

  const supportsOperationWindows = isAtLeastVersion(
    MINIMUM_DEVICE_VERSION,
    version
  )

  const date = moment(lastSuccessDeviceCommandTime).format(MMDDYYFormat)
  const time = moment(lastSuccessDeviceCommandTime).format(hhmmaFormat)
  const error = errorsMessage || requestError

  const onChangeInput = (event: any) => {
    const copyState = { ...device }

    if (
      event.target.name === 'mode' &&
      event.target.value === 'SCHEDULED' &&
      !device.desiredDays?.length
    ) {
      copyState.desiredDays = initialSelectedDays
    }

    if (event.target.name === 'mode' && !!errorsMessage) {
      setErrorsMessage('')
    }

    setDevice({ ...copyState, [event.target.name]: event.target.value })
  }

  const onSwitchChange = (event: any) => {
    setDevice({ ...device, desiredKeepLedAlways: !device.desiredKeepLedAlways })
  }

  const onChangeWeekDays = (value: any) => {
    setDevice({ ...device, desiredDays: value })
  }

  const onSubmit = () => {
    const formErrors = checkValidation(
      device,
      setErrorsMessage,
      isNotVive,
      supportsOperationWindows
    )

    setErrors(formErrors)

    if (!some(formErrors)) {
      setSnackbarMessage(SNACKBAR_MESSAGES.updated)

      updateSettings(deviceId, device, onOpenSnackbar)
    }
  }

  const changeCleaningMode = () => {
    disableDevice(deviceId, !cleaningEnabled)
  }

  return (
    <div className={classes.settingsContainer}>
      <Typography variant="h4" className={classes.title}>
        {DEVICE_SETTINGS.title}
      </Typography>

      {lastSuccessDeviceCommandTime && (
        <Box display="flex" color={COLORS.GRAY}>
          <Typography variant="subtitle1" className={classes.lastUpdated}>
            {DEVICE_SETTINGS.lastUpdated}:
          </Typography>

          <Typography variant="subtitle1">
            {date} at {time} UTC
          </Typography>
        </Box>
      )}

      <Typography variant="h5" className={classes.subtitle}>
        {DEVICE_SETTINGS.exposureLimit}
      </Typography>

      <SettingsFields
        errors={errors}
        device={device}
        onChange={onChangeInput}
      />

      <Mode
        days={device.desiredDays}
        desiredDailyEndTime={device.desiredDailyEndTime}
        desiredDailyStartTime={device.desiredDailyStartTime}
        mode={device.mode}
        errors={errors}
        onChangeWeekDays={onChangeWeekDays}
        onChange={onChangeInput}
      />

      {isScheduled && (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="desiredKeepLedAlways"
              onChange={onSwitchChange}
              checked={device.desiredKeepLedAlways}
            />
          }
          label={DEVICE_SETTINGS.keepLedAlways}
          classes={{ label: classes.keepLedAlways }}
        />
      )}

      {error && (
        <Typography variant="subtitle1" color="error">
          {error}
        </Typography>
      )}

      {!removed && (
        <div className={classes.saveAndLabel}>
          <Button
            variant="contained"
            className={classes.saveButton}
            color="primary"
            onClick={onSubmit}
          >
            {DEVICE_SETTINGS.saveChanges}
          </Button>
        </div>
      )}

      <div className={classes.saveAndLabel}>
        <p className={classes.cleaningLabel}>{DEVICE_SETTINGS.disableDevice}</p>

        {isUpdatingLabelForCleaning && (
          <span className={classes.updatingLabel}>
            {DEVICE_SETTINGS.updating}
          </span>
        )}

        <Switch
          color="primary"
          checked={!cleaningEnabled}
          onChange={changeCleaningMode}
          disabled={removed}
          id="disable-device"
        />
      </div>

      <p className={classes.helperText}>
        {DEVICE_SETTINGS.disableDeviceHelperText}
      </p>
    </div>
  )
}

export default DeviceSettings
