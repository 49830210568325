const USER_ROLES = {
  accountOwnerRole: 'ACCOUNT_OWNER_ROLE',
  adminUserRole: 'ADMIN_USER_ROLE',
  operatorUserRole: 'OPERATOR_USER_ROLE',
  adminBillingUserRole: 'ADMIN_USER_BILLING_ROLE',
}

const API_DEVICE_TYPE_DISPLAY = {
  ARC: 'Arc',
  BEAM: 'Beam',
  FAR_UV: 'Far UV',
  NETWORK_DEVICE: 'Network',
  EXT_DEVICE: 'External',
  SENSOR: 'Sensor',
}

const API_DEVICE_TYPE_MAPPER: {
  [s in keyof typeof API_DEVICE_TYPE_DISPLAY]: keyof typeof DEVICE_TYPE
} = {
  ARC: 'UVI_ARC',
  BEAM: 'UR_UVGI',
  FAR_UV: 'FAR_UVC',
  NETWORK_DEVICE: 'NETWORK_DEVICE',
  EXT_DEVICE: 'EXT_DEVICE',
  SENSOR: 'SENSOR',
}

const DEVICE_TYPE = {
  UVI_ARC: {
    label: 'Arc',
    type: 'UVI_ARC',
    path: 'uvi-arc',
    apiPath: 'uvi-arc',
  },
  UVI_ARC_REV_B: {
    label: 'Arc Revision B',
    type: 'UVI_ARC_REV_B',
    path: 'uvi-arc-rev-b',
    apiPath: 'uvi-arc',
  },
  UVI_ARC_REV_D: {
    label: 'Arc Revision D',
    type: 'UVI_ARC_REV_D',
    path: 'uvi-arc-rev-d',
    apiPath: 'uvi-arc',
  },
  UR_UVGI: {
    label: 'Beam',
    type: 'UR_UVGI',
    path: 'ur-uvgi',
    apiPath: 'ur-uvgi',
  },
  FAR_UVC: {
    label: 'Far UV',
    type: 'FAR_UVC',
    path: 'ur-uvgi',
    apiPath: 'ur-uvgi',
  },
  EXT_DEVICE: {
    label: 'EXT_DEVICE',
    type: 'EXT_DEVICE',
    path: 'ext-device',
    apiPath: 'ext-device',
  },
  NETWORK_DEVICE: {
    label: 'Network Device',
    type: 'NETWORK_DEVICE',
    path: 'network-device',
    apiPath: 'network-device',
  },
  HUB_RELAY: {
    label: 'Hub Relay',
    type: 'HUB_RELAY',
    path: 'network-device',
    apiPath: 'network-device',
  },
  HUB: {
    label: 'Hub',
    type: 'HUB',
    path: 'network-device',
    apiPath: 'network-device',
  },
  SENSOR: {
    label: 'Sensor',
    type: 'SENSOR',
    path: 'sensor-device',
    apiPath: 'sensor-device',
  },
  PIRUV: {
    label: 'PIRUV',
    type: 'PIRUV',
    path: 'sensor-device',
    apiPath: 'sensor-device',
  },
  EON: {
    label: 'EON',
    type: 'EON',
    path: 'eon',
    apiPath: 'eon',
  },
  VIVE: {
    label: 'Vive',
    type: 'VIVE',
    path: 'vive',
    apiPath: 'vive',
  },
}

// might be redundant
const DEVICE_TYPE_MAPPER = {
  UVI_ARC: 'Arc',
  UR_UVGI: 'Beam',
  FAR_UVC: 'Far UV',
  EXT_DEVICE: 'External',
  NET_DEVICE: 'Network',
  HUB: 'Hub',
  HUB_RELAY: 'Hub Relay',
  SENSOR: 'Sensor',
}

const ARC_TYPES = ['UVI_ARC', 'UVI_ARC_REV_B', 'UVI_ARC_REV_D']
const NETWORK_TYPES = ['HUB', 'HUB_RELAY']
const ARC_PATHS = ['uvi-arc', 'uvi-arc-rev-b', 'uvi-arc-rev-d']

const DEVICE_TYPE_LABELS = {
  UVI_ARC: 'Arc Revision A',
  UVI_ARC_REV_B: 'Arc Revision B',
  UVI_ARC_REV_D: 'Arc Revision D',
  UR_UVGI: 'Beam',
  FAR_UVC: 'Far UV',
  EXT_DEVICE: 'External Device',
  HUB: 'Hub',
  HUB_RELAY: 'Hub Relay',
  NETWORK_DEVICE: 'Network Device',
  SENSOR: 'Sensor',
  PIRUV: 'PIRUV',
}

const DEVICE_SUBTYPE = {
  UR_UVGI: {
    label: 'Beam',
    value: 'UR_UVGI',
  },
  FAR_UVC: {
    label: 'Far UV',
    value: 'FAR_UVC',
  },
}

const FIRMWARE_TYPES = {
  BLUETOOTH: {
    label: 'BLUETOOTH',
    value: 'BLUETOOTH',
  },
  MODEM: {
    label: 'MODEM',
    value: 'MODEM',
  },
  MODEM_STACK: {
    label: 'MODEM_STACK',
    value: 'MODEM_STACK',
  },
  UR_UVGI_COMBINED: {
    label: 'UR_UVGI_COMBINED',
    value: 'UR_UVGI_COMBINED',
  },
}

const TIME_FORMAT = 'yyyy-MM-DDTHH:mm:ss'

const SNACKBAR_AUTO_HIDE_DURATION = 5000

const firmwareTypes = [
  {
    label: 'Modem',
    value: 'MODEM',
  },
  {
    label: 'Bluetooth',
    value: 'BLUETOOTH',
  },
]

const UVGI_FIRMWARE_TYPES = [
  {
    label: 'UR_UVGI_COMBINED',
    value: 'UR_UVGI_COMBINED',
  },
]

const PAGINATION = {
  perPage: 'Per Page',
  perPageValues: [5, 10, 20, 30],
  results: 'Results:',
  of: 'of',
  ofMore: 'of more than',
  apply: 'Apply',
}

const autoHideDuration = 5000

const PAGES = {
  device: 'DEVICE',
  users: 'ACCOUNT_AND_USERS',
  organizations: 'ORGANIZATION',
  firmware: 'FIRMWARE',
  deviceFirmware: 'DEVICE_FIRMWARE',
  deviceEmulator: 'DEVICE_EMULATOR',
  healthMonitoring: 'HEALTH_MONITORING',
  systemConfiguration: 'SYSTEM_CONFIGURATION',
  globalFeatureToggling: 'GLOBAL_FEATURE_TOGGLING',
  featureToggling: 'FEATURE_TOGGLING',
  uvgiPowerManagement: 'UVGI_POWER_MANAGEMENT',
  ARCChangeRevision: 'ROLE_DEVICE_CHANGE_REVISION',
  eventsEmulator: 'EVENTS_EMULATOR',
}

const PAGE_ACCESS = [
  'ROLE_DEVICE_READ',
  'ROLE_ACCOUNT_AND_USERS_READ',
  'ROLE_FIRMWARE_READ',
  'ROLE_DEVICE_FIRMWARE_READ',
  'ROLE_SYSTEM_CONFIGURATION_READ',
  'ROLE_HEALTH_MONITORING_READ',
  'ROLE_ORGANIZATION_READ',
  'ROLE_GLOBAL_FEATURE_TOGGLING_READ',
  'ROLE_FEATURE_TOGGLING_READ',
  'ROLE_DEVICE_EMULATOR',
]

const PERMISSIONS = {
  DEVICE: {
    read: false,
    write: false,
  },
  ACCOUNT_AND_USERS: {
    read: false,
    write: false,
  },
  ORGANIZATION: {
    read: false,
    write: false,
  },
  FIRMWARE: {
    read: false,
    write: false,
  },
  DEVICE_FIRMWARE: {
    read: false,
    write: false,
  },
  DEVICE_EMULATOR: {
    read: false,
    write: false,
  },
  EVENTS_EMULATOR: {
    read: false,
    write: false,
  },
  HEALTH_MONITORING: {
    read: false,
    write: false,
  },
  SYSTEM_CONFIGURATION: {
    read: false,
    write: false,
  },
  FEATURE_TOGGLING: {
    read: false,
    write: false,
  },
  GLOBAL_FEATURE_TOGGLING: {
    read: false,
    write: false,
  },
  UVGI_POWER_MANAGEMENT: {
    read: false,
    write: false,
  },
  ROLE_DEVICE_CHANGE_REVISION: {
    read: false,
    write: false,
  },
}

const DATA_FIELDS = {
  value: 'value',
  displayValue: 'displayValue',
}

const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  BLUE: '#1796FF',
  GRAY: '#6D6E70',
  COLD_GRAY: '#F4F7F9',
  WARM_GRAY: '#F8F8F8',
  BLUE_GRAY_SECONDARY: '#EFEFEF',
  LIGHT_GRAY: '#E0E0E0',
  LIGHT_SILVER: '#D0D2D3',
  GRAY_BOULDER: '#757575',
  GRAY_ZIRCON: '#DFE0E0',
  GRAY_SILVER: '#BBBBBB',
  GRAY_SEASHELL: '#F1F1F1',
  GRAY_JUMBO: '#7F7C82',
  RED: '#DC3838',
  RED_ORANGE: '#F14E22',
  GREEN: '#AEEBAE',
  BRIGHT_GREEN: '#4caf50',
  GRAY_DISABLED: '#E9E9EA',
  CONCRETE_GRAY: '#F3F3F3',
}

const ON_OFF_LABELS = {
  on: 'ON',
  off: 'OFF',
}

const ENTER_CODE_KEYS = {
  key: 'Enter',
  code: 13,
}

const IMG_ALT = {
  LOGO: 'R-ZERO logo',
  ARC_DEVICE: 'Arc device image',
}

const TREE_LEVELS = {
  PROPERTY: 'property',
  BUILDING: 'building',
  ZONE: 'zone',
  ROOM: 'room',
  LOCATIONS: 'locations',
  PLACEMENT: 'placement',
}

const UNKNOWN_LOCATION = 'Unknown Location'

const UNKNOWN_ID = 'unknown'

export {
  API_DEVICE_TYPE_DISPLAY,
  USER_ROLES,
  TIME_FORMAT,
  DEVICE_TYPE,
  DEVICE_TYPE_MAPPER,
  SNACKBAR_AUTO_HIDE_DURATION,
  firmwareTypes,
  UVGI_FIRMWARE_TYPES,
  FIRMWARE_TYPES,
  DEVICE_SUBTYPE,
  PAGINATION,
  autoHideDuration,
  PAGES,
  PERMISSIONS,
  PAGE_ACCESS,
  DATA_FIELDS,
  COLORS,
  ON_OFF_LABELS,
  DEVICE_TYPE_LABELS,
  ENTER_CODE_KEYS,
  IMG_ALT,
  ARC_TYPES,
  ARC_PATHS,
  TREE_LEVELS,
  UNKNOWN_LOCATION,
  UNKNOWN_ID,
  NETWORK_TYPES,
  API_DEVICE_TYPE_MAPPER,
}

export const ORAGNIZATION_TYPES = [
  {
    id: 'INTERNAL',
    description: 'Internal',
  },
  {
    id: 'CUSTOMER',
    description: 'Customer',
  },
]
