const PAGE_TITTLE = 'General Feature Toggling'

const FEATURES_LABELS = {
  toggle: 'OFF / ON',
  title: 'Feature Name',
  id: 'ID',
  level: 'Level',
  description: 'Description',
}

export { PAGE_TITTLE, FEATURES_LABELS }
