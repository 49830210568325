import { Divider, IconButton, TextField, Typography } from '@material-ui/core'
import {
  Add as AddIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  Edit as EditIcon,
} from '@material-ui/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import { ENTER_CODE_KEYS } from 'services/constants'
import useStyles from './style'

type CardWithDescriptionEditableProps = {
  title: string
  description: string
  onSubmit: (value: string) => void
  error?: string
  isRequired?: boolean
  isReadOnly?: boolean
}

type ButtonsBarProps = {
  onCancel: () => void
  onAccept: () => void
  hasError: boolean
}

const ButtonsBar = ({ hasError, onCancel, onAccept }: ButtonsBarProps) => {
  const classes = useStyles()

  return (
    <>
      <IconButton
        disabled={hasError}
        aria-label="accept button"
        onClick={onAccept}
      >
        <DoneIcon
          className={classNames(classes.buttonDone, {
            [classes.buttonDisabled]: hasError,
          })}
        />
      </IconButton>
      <Divider orientation="vertical" />
      <IconButton
        color="secondary"
        aria-label="cancel button"
        onClick={onCancel}
      >
        <CloseIcon />
      </IconButton>
    </>
  )
}

const CardWithDescriptionEditable = ({
  title,
  description,
  onSubmit,
  error = '',
  isRequired = false,
  isReadOnly = false,
}: CardWithDescriptionEditableProps) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [value, setValue] = useState(title)

  useEffect(() => {
    if (isEditMode) {
      setValue(title)
    }
  }, [isEditMode, setValue])

  const handleCancel = useCallback(() => {
    setIsEditMode(false)
  }, [])

  const hasError = useMemo(
    () => (isRequired && !value.trim()) || !!error,
    [error, isRequired, value]
  )

  const onSubmitButton = useCallback(() => {
    if (!hasError) {
      onSubmit(value.trim())
      setIsEditMode(false)
    }
  }, [value, hasError, onSubmit])

  const onKeyUp = useCallback(
    (e: any) => {
      if (e.key === ENTER_CODE_KEYS.key || e.keyCode === ENTER_CODE_KEYS.code) {
        e.preventDefault()
        onSubmitButton()
      }
    },
    [onSubmitButton]
  )

  const onChangeText = useCallback(
    (event: any) => {
      setValue(event.target.value)
    },
    [setValue]
  )

  const classes = useStyles()
  // onClick handler on actionWrapper <div> prevents firing event on parent <div>
  return (
    <div className={classes.actionCard}>
      <div className={classes.descriptionWrapper}>
        <p className={classes.description}>{description}</p>
        {!isEditMode || isReadOnly ? (
          <p className={classes.title}>{title}</p>
        ) : (
          <div>
            <TextField
              className={classes.inputField}
              variant="standard"
              value={value}
              autoFocus
              onChange={onChangeText}
              error={hasError}
              minRows={10}
              required={isRequired}
              onKeyUp={onKeyUp}
            />
            {error && (
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            )}
          </div>
        )}
      </div>

      {!isReadOnly && (
        <div
          className={classes.actionWrapper}
          onClick={(event) => event.stopPropagation()}
        >
          {!isEditMode ? (
            <IconButton
              size="small"
              onClick={() => setIsEditMode(!isEditMode)}
              aria-label="edit"
            >
              {title ? <EditIcon /> : <AddIcon color="primary" />}
            </IconButton>
          ) : (
            <div className={classes.fieldWithButtons}>
              <ButtonsBar
                hasError={hasError}
                onCancel={handleCancel}
                onAccept={onSubmitButton}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CardWithDescriptionEditable
