import { withStyles } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import styles from './toolbar-styles'

const ToolbarComponent = (props) => {
  const {
    multi, // specify wether it is possible to select multiple items
    tableTitle,
    selected,
    classes,
  } = props

  const getSelectedString = (selected) => `${selected} selected`

  return selected ? (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: selected.length > 0,
      })}
    >
      {selected.length > 0 && multi ? (
        <Typography color="inherit" variant="subtitle1" component="div">
          {getSelectedString(selected.length)}
        </Typography>
      ) : (
        <Typography variant="h6" id="tableTitle" component="div">
          {tableTitle}
        </Typography>
      )}
    </Toolbar>
  ) : null
}

export default withStyles(styles)(ToolbarComponent)
