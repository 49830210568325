import { tableCells, tableLabels } from '../../services/firmwareUpdate-service'

import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import MaterialTable from '../../../../components/_shared/materialTable/MaterialTable'
import { checkConnection } from '../../../../services/root/root-service'
import actions from '../../services/firmwareUpdate-actions'
import { TITLES } from '../../services/firmwareUpdate-constants'
import styles from './table-style'

const UpdateFirmwareTable = (props) => {
  const {
    classes,
    devices,
    setSelected,
    selected,
    totalCount,
    page,
    rowsPerPage,
    isLoading,
    goToNextPage,
    goToPreviousPage,
    changeRowsPerPage,
  } = props

  const changeCurrentPage = (newPage) => {
    const isDevicesWasLoaded = devices.length <= newPage * rowsPerPage

    if (newPage > page && isDevicesWasLoaded) {
      goToNextPage(newPage)
    } else {
      goToPreviousPage(newPage)
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} id="update-firmware-table-container">
        <MaterialTable
          tableData={devices}
          isSelectable={true}
          tableTitle={TITLES.devices}
          tableLabels={tableLabels}
          cellsConfig={tableCells}
          multi={true}
          selected={selected}
          setSelected={setSelected}
          totalCount={totalCount}
          page={page}
          setPage={changeCurrentPage}
          setRowsPerPage={changeRowsPerPage}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
        />
      </Paper>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  goToNextPage: (page) =>
    dispatch(checkConnection(() => actions.goToNextPage(page))),
  goToPreviousPage: (page) => dispatch(actions.goToPreviousPage(page)),
  changeRowsPerPage: (value) => dispatch(actions.setRowsPerPage(value)),
})

const mapStateToProps = (state) => ({
  isLoading: state.firmwareUpdateReducer.isLoading,
  totalCount: state.firmwareUpdateReducer.totalCount,
  page: state.firmwareUpdateReducer.page,
  rowsPerPage: state.firmwareUpdateReducer.rowsPerPage,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UpdateFirmwareTable))
