import { TableCell } from '@material-ui/core'
import { ContentCopy } from '@mui/icons-material'
import classNames from 'classnames'
import { Device } from 'containers/Devices/services/devices-types'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'utils/reduxHelpers'
import { useStyles } from '../styles'

type SerialNumberCellProps = Pick<Device, 'id' | 'serialNumber' | 'imei'> & {}

export const SerialNumberCell = ({
  id,
  imei,
  serialNumber,
}: SerialNumberCellProps) => {
  const style = useStyles()

  const isReadOnly = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.isReadOnly
  )

  return (
    <TableCell className="serial-number">
      <div className={style.serialNumber}>
        {isReadOnly ? (
          <div>{serialNumber}</div>
        ) : (
          <Link
            to={`/devices/details/${imei}/${serialNumber}/${id}`}
            className={classNames(style.tableLink, 'details-link')}
          >
            {serialNumber}
          </Link>
        )}
        <ContentCopy
          className={style.tableLink}
          onClick={async () => navigator.clipboard.writeText(serialNumber)}
        />
      </div>
    </TableCell>
  )
}
