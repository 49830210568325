import { ListSubheader } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles/index'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import map from 'lodash/map'
import uniqueId from 'lodash/uniqueId'
import style from './style'

const CustomSelectComponent = ({
  options,
  error,
  label,
  onChange,
  value,
  valueName,
  template,
  isRequired,
  isReadOnly,
  subheaderField,
  name,
  selectedOnlyBlack = false,
  classes,
}) => (
  <FormControl fullWidth variant="outlined" required={isRequired} error={error}>
    <InputLabel htmlFor="outlined-simple">{label}</InputLabel>
    <Select
      disabled={isReadOnly}
      error={error}
      name={name}
      value={value}
      autoWidth
      onChange={onChange}
      label={label}
      IconComponent={ExpandMoreIcon}
      className={selectedOnlyBlack ? classes.selectedTextColor : ''}
      inputProps={{
        id: 'outlined-simple',
      }}
    >
      {map(options, (item) =>
        subheaderField && item[subheaderField] ? (
          <ListSubheader key={uniqueId('subhead_')} disableSticky>
            {template(item)}
          </ListSubheader>
        ) : (
          <MenuItem key={uniqueId('menuItem_')} value={item[valueName]}>
            {template(item)}
          </MenuItem>
        )
      )}
    </Select>
  </FormControl>
)

export default withStyles(style)(CustomSelectComponent)
