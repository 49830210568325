import {
  CHANGE_FILTER_STATE,
  CREATE_USER_CLEAN,
  CREATE_USER_ERROR,
  CREATE_USER_INIT,
  CREATE_USER_SUCCESS,
  GET_CUSTOMER_URL_FAILED,
  GET_CUSTOMER_URL_SUCCESS,
  GET_UNIQUE_CODE,
  GET_UNIQUE_CODE_FAILED,
  GET_UNIQUE_CODE_SUCCESS,
  LOAD_USERS,
  LOAD_USERS_FAILED,
  LOAD_USERS_SUCCESS,
  RESET_CODE_AND_URL,
  RESET_ERROR,
  RESET_PASSWORD_FAILED,
} from './users-actions'

import { createReducer } from 'redux-act'
import { Payload } from 'types/storeTypes'
import { UserProfile } from 'types/userTypes'
import { PAGES } from '../../../services/constants'
import rootActions from '../../../services/root/root-actions'
import { UsersFilterState } from '../components/types'
import { filterInitialState } from './users-constants'

type UsersState = {
  userCreation: {
    creating: boolean
    error: string
    success: boolean
  }
  users: UserProfile[]
  filteredUsers: UserProfile[]
  userInfo: string
  isLoading: boolean
  error: string
  code: string | null
  url: string | null
  currentEmail: string | null
  loadUsersError: string
  isReadOnly: boolean
  userFilters: UsersFilterState
  count: number
}

export const usersReducer = createReducer(
  {
    [CHANGE_FILTER_STATE.getType()]: (state, payload) => {
      return {
        ...state,
        userFilters: payload?.userFilters || filterInitialState,
      }
    },
    [rootActions.actionTypes.SETUP_PERMISSIONS]: (state, payload) => {
      const { permissions } = payload

      return {
        ...state,
        isReadOnly: !permissions[PAGES.users].write,
      }
    },
    [LOAD_USERS.getType()]: (state, payload) => {
      return {
        ...state,
        loadUsersError: '',
      }
    },
    [GET_UNIQUE_CODE.getType()]: (state, payload) => {
      return {
        ...state,
        currentEmail: payload.email,
        error: '',
      }
    },
    [LOAD_USERS_SUCCESS.getType()]: (state, payload) => {
      const { users = [], count = 0 } = payload

      return {
        ...state,
        users,
        count,
        isLoading: false,
        loadUsersError: '',
        userInfo: '',
      }
    },
    [GET_UNIQUE_CODE_SUCCESS.getType()]: (state, payload) => {
      return {
        ...state,
        code: payload.code,
        error: '',
        userInfo: '',
      }
    },
    [GET_CUSTOMER_URL_SUCCESS.getType()]: (state, payload) => {
      return {
        ...state,
        url: payload.url,
        error: '',
        userInfo: '',
      }
    },
    [LOAD_USERS_FAILED.getType()]: (state, payload) => {
      return {
        ...state,
        loadUsersError: payload.message,
        isLoading: false,
      }
    },
    [GET_UNIQUE_CODE_FAILED.getType()]: (state, payload) => {
      return {
        ...state,
        error: payload.message,
      }
    },
    [RESET_PASSWORD_FAILED.getType()]: (state, payload) => {
      return {
        ...state,
        error: payload.message,
      }
    },
    [GET_CUSTOMER_URL_FAILED.getType()]: (state, payload) => {
      return {
        ...state,
        error: payload.message,
      }
    },
    [RESET_ERROR.getType()]: (state, payload) => {
      return {
        ...state,
        addPropertyError: '',
        error: '',
      }
    },
    [RESET_CODE_AND_URL.getType()]: (state, payload) => {
      return {
        ...state,
        code: null,
        url: null,
      }
    },
    [CREATE_USER_INIT.getType()]: (state) => {
      return {
        ...state,
        userCreation: {
          creating: true,
          error: '',
          success: false,
        },
      }
    },
    [CREATE_USER_SUCCESS.getType()]: (state) => {
      return {
        ...state,
        userCreation: {
          creating: false,
          error: '',
          success: true,
        },
      }
    },
    [CREATE_USER_ERROR.getType()]: (
      state,
      payload: Payload<typeof CREATE_USER_ERROR>
    ) => {
      return {
        ...state,
        userCreation: {
          creating: false,
          error: payload,
          success: false,
        },
      }
    },
    [CREATE_USER_CLEAN.getType()]: (state) => {
      return {
        ...state,
        userCreation: {
          creating: false,
          error: '',
          success: false,
        },
      }
    },
  },
  {
    users: [],
    filteredUsers: [],
    userInfo: '',
    isLoading: true,
    error: '',
    code: null,
    url: null,
    currentEmail: null,
    loadUsersError: '',
    isReadOnly: false,
    userFilters: filterInitialState,
    count: 0,
    userCreation: {
      creating: false,
      error: '',
      success: false,
    },
  } as UsersState
)
