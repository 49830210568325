import {
  ADD_LOCATION_LABELS,
  ADD_PROPERTY_LABELS,
  MAX_NAME_LENGTH,
} from '../../../services/organizationDetails-constants'

import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import capitalize from 'lodash/capitalize'
import { ENTER_CODE_KEYS } from '../../../../../../../services/constants'
import { Location } from '../../../services/organizationDetails-types'
import { useStyles } from './style'

type ControlPartProps = {
  locationType: string
  error: string
  handleDoubleClick: React.MouseEventHandler
  submitButton: string
  isBulk: boolean
  onChangeLocationName: React.ChangeEventHandler<HTMLInputElement>
  propertyFields: Partial<Location>
  onChangePropertyFields: React.ChangeEventHandler<HTMLInputElement>
  onSubmitButton: React.MouseEventHandler<HTMLButtonElement>
  isEmptyTextField: boolean
  locationName: string
}

const ControlPart = ({
  locationType,
  error,
  handleDoubleClick,
  submitButton,
  onChangeLocationName,
  isBulk,
  propertyFields,
  onChangePropertyFields,
  onSubmitButton,
  isEmptyTextField,
  locationName,
}: ControlPartProps) => {
  const styles = useStyles()
  const isProperty = locationType === 'property'

  const onKeyUp: React.KeyboardEventHandler = (e) => {
    if (e.key === ENTER_CODE_KEYS.key || e.keyCode === ENTER_CODE_KEYS.code) {
      onSubmitButton(e as any)
    }
  }

  return (
    <>
      <TextField
        variant="outlined"
        value={locationName}
        error={isEmptyTextField || !!error}
        label={capitalize(locationType) + ' ' + ADD_LOCATION_LABELS.name}
        inputProps={{
          className: styles.outlined,
          maxLength: isBulk ? '' : MAX_NAME_LENGTH,
        }}
        fullWidth
        onChange={onChangeLocationName}
        multiline={isBulk}
        rows={10}
        required
        onKeyUp={isBulk ? undefined : onKeyUp}
      />
      {isProperty && (
        <div>
          <TextField
            variant="outlined"
            name="streetAddress"
            value={propertyFields.streetAddress}
            label={ADD_PROPERTY_LABELS.streetAddress}
            onChange={onChangePropertyFields}
            fullWidth
          />
          <TextField
            variant="outlined"
            name="city"
            value={propertyFields.city}
            label={ADD_PROPERTY_LABELS.city}
            onChange={onChangePropertyFields}
            fullWidth
          />
          <div className={styles.stateAndZip}>
            <TextField
              variant="outlined"
              name="state"
              value={propertyFields.state}
              label={ADD_PROPERTY_LABELS.state}
              onChange={onChangePropertyFields}
            />
            <TextField
              variant="outlined"
              name="zip"
              value={propertyFields.zip}
              label={ADD_PROPERTY_LABELS.zip}
              onChange={onChangePropertyFields}
            />
          </div>
        </div>
      )}
      <div>
        <div className={styles.stateAndZip}>
          <TextField
            variant="outlined"
            name="width"
            value={propertyFields.width}
            label={ADD_PROPERTY_LABELS.width}
            onChange={onChangePropertyFields}
          />
          <TextField
            variant="outlined"
            name="length"
            value={propertyFields.length}
            label={ADD_PROPERTY_LABELS.length}
            onChange={onChangePropertyFields}
          />
          <TextField
            variant="outlined"
            name="height"
            value={propertyFields.height}
            label={ADD_PROPERTY_LABELS.height}
            onChange={onChangePropertyFields}
          />
        </div>
      </div>
      <Button
        onDoubleClick={handleDoubleClick}
        variant="contained"
        color="primary"
        className={styles.submitButton}
        onClick={onSubmitButton}
      >
        {submitButton}
      </Button>
    </>
  )
}

export default ControlPart
