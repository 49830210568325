import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import omitBy from 'lodash/omitBy'
import { DEVICE_TYPE } from '../../../services/constants'
import filterQuery from '../../../services/filterQuery-service'
import { DEVICE_DETAILS } from '../components/Details/services/deviceDetails-constants'

const prepareExtraInfoForDeviceDetails = (array) => {
  return isEmpty(array)
    ? [DEVICE_DETAILS.noInformation, DEVICE_DETAILS.noInformation]
    : [
        array[0].deviceType,
        array[0].removed,
        array[0].devicePower,
        array[0].deviceSubType,
        array[0].version,
      ]
}

const getIdsQuery = (devicesList) => {
  const ids = map(devicesList, (device) => device.id)

  return filterQuery.getArrayString(
    ids,
    filterQuery.templates.array,
    'id',
    'in'
  )
}

const prepareRequestQuery = (id) => {
  return filterQuery.getQueryString(
    id,
    filterQuery.templates.default,
    'deviceId',
    'eq'
  )
}

const prepareDeviceEvents = (events) => {
  return map(events, (item) => {
    const filteredEvent = omitBy(
      { ...item.event, id: item.id },
      (value) => !value
    )

    return {
      deviceDateTime: item.deviceDateTime,
      ...filteredEvent,
    }
  })
}

const isArc = (deviceType) => includes(deviceType, DEVICE_TYPE.UVI_ARC.type)

const isNetwork = (deviceType) =>
  includes(deviceType, DEVICE_TYPE.HUB.type) ||
  includes(deviceType, DEVICE_TYPE.NETWORK_DEVICE.type)

const isSensor = (deviceType) =>
  includes(deviceType, DEVICE_TYPE.SENSOR.type) ||
  includes(deviceType, DEVICE_TYPE.PIRUV.type)

export {
  prepareExtraInfoForDeviceDetails,
  getIdsQuery,
  prepareRequestQuery,
  prepareDeviceEvents,
  isArc,
  isNetwork,
  isSensor,
}
