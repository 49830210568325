import { useEffect, useState } from 'react'
import {
  DEVICE_TYPE,
  SNACKBAR_AUTO_HIDE_DURATION,
} from '../../../../../services/constants'
import { isArc, isNetwork, isSensor } from '../../../services/devices-service'
import {
  CERTIFICATE_UPDATED_SUCCESS,
  DEVICES_PAGE_LABELS,
} from '../services/devicesList-constants'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import map from 'lodash/map'
import snakeCase from 'lodash/snakeCase'
import uniqueId from 'lodash/uniqueId'
import { connect } from 'react-redux'
import { checkConnection } from '../../../../../services/root/root-service'
import actions from '../../../services/devices-actions'
import { DEVICE_TABS } from '../../../services/devices-constants'
import devicesListActions from '../services/devicesList-actions'
import DevicesFilterComponent from './deviceFilter/DeviceFilter'
import { useStyles } from './style'
import { ArcTable } from './tables/ArcTable'
import { BeamTable } from './tables/BeamTable'
import { NetworkTable } from './tables/NetworkTable'
import { SensorTable } from './tables/SensorTable'
import { ViveTable } from './tables/ViveTable'

const DevicesTableComponent = (props) => {
  const {
    loadDevices,
    getCleanupAbility,
    updateCertificate,
    updateCertificateError,
    abilities = {},
    deviceType,
    setDeviceType,
    totalsByType,
    setDefaultSort,
  } = props

  const style = useStyles()
  const updateCertificateAbility = abilities.updateCertificate
  const [openSnackBar, setOpenSnackbar] = useState(false)
  const clarifiedType = isArc(deviceType)
    ? DEVICE_TYPE.UVI_ARC.type
    : isNetwork(deviceType)
    ? DEVICE_TYPE.NETWORK_DEVICE.type
    : isSensor(deviceType)
    ? DEVICE_TYPE.SENSOR.type
    : deviceType

  useEffect(() => {
    getCleanupAbility()
    // eslint-disable-next-line
  }, [])

  const handleDeviceTypeChange = (event, value) => {
    setDefaultSort()
    setDeviceType(value)
  }

  const onUpdateCertificateSuccess = () => {
    setOpenSnackbar(true)
  }

  const recreateCertificate = () => {
    updateCertificate(selected[0], onUpdateCertificateSuccess)
  }

  const displayArcTable = isArc(deviceType)
  const displaySensorTable = isSensor(deviceType)
  const displayBeamTable = deviceType === DEVICE_TYPE.UR_UVGI.type
  const displayViveTable = deviceType === DEVICE_TYPE.FAR_UVC.type

  return (
    <>
      <DevicesFilterComponent
        loadDevices={loadDevices}
        deviceType={deviceType}
      />
      {updateCertificateAbility && (
        <Button
          onClick={recreateCertificate}
          className={style.updateCertificateButton}
          disabled={!selected.length || deviceType !== DEVICE_TYPE.UVI_ARC.type}
          variant="contained"
          color="primary"
        >
          {DEVICES_PAGE_LABELS.recreateCertificate}
        </Button>
      )}
      {updateCertificateError && (
        <Box margin="20px 0 0" textAlign="center">
          <Typography color="error" variant="subtitle1">
            {updateCertificateError}
          </Typography>
        </Box>
      )}
      <Paper>
        <Tabs
          onChange={handleDeviceTypeChange}
          value={snakeCase(clarifiedType).toUpperCase()}
          classes={{ flexContainer: style.tabsContainer }}
          className={style.tabsContainer}
          indicatorColor="primary"
        >
          {map(DEVICE_TABS, (item) => (
            <Tab
              label={
                totalsByType[item.type]
                  ? `${item.label} - ${totalsByType[item.type]}`
                  : item.label
              }
              key={uniqueId()}
              value={item.type}
              className={style.tab}
              id={item.path}
            />
          ))}
        </Tabs>
      </Paper>
      <div className={style.root}>
        <Paper className={style.paper}>
          {displayArcTable ? (
            <ArcTable />
          ) : displayBeamTable ? (
            <BeamTable />
          ) : displayViveTable ? (
            <ViveTable />
          ) : displaySensorTable ? (
            <SensorTable />
          ) : (
            <NetworkTable />
          )}
        </Paper>
      </div>
      <Snackbar
        open={openSnackBar}
        onClose={() => setOpenSnackbar(false)}
        message={CERTIFICATE_UPDATED_SUCCESS}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  loadDevices: (query) =>
    dispatch(checkConnection(() => devicesListActions.loadDevices(query))),
  getCleanupAbility: () =>
    dispatch(checkConnection(devicesListActions.getCleanupAbility)),
  updateCertificate: (id, onSuccess) =>
    dispatch(
      checkConnection(() => devicesListActions.updateCertificate(id, onSuccess))
    ),
  goToNextPage: (page) =>
    dispatch(checkConnection(() => devicesListActions.goToNextPage(page))),
  goToPreviousPage: (page) =>
    dispatch(devicesListActions.goToPreviousPage(page)),
  changeRowsPerPage: (value) =>
    dispatch(devicesListActions.setRowsPerPage(value)),
  loadDataFromReduxStore: (status) =>
    dispatch(actions.loadDataFromReduxStore(status)),
  setDefaultSort: () =>
    dispatch(
      devicesListActions.toggleSort({
        sort: 'serialNumber',
      })
    ),
  setDeviceType: (type) => dispatch(actions.setDeviceType(type)),
})

const mapStateToProps = (state) => {
  const { devicesListReducer } = state.devicesReducer

  return {
    devices: devicesListReducer.devicesList,
    updateCertificateError: devicesListReducer.updateCertificateError,
    abilities: devicesListReducer.abilities,
    isLoading: devicesListReducer.isLoading,
    page: devicesListReducer.page,
    rowsPerPage: devicesListReducer.rowsPerPage,
    totalCount: devicesListReducer.totalCount,
    totalsByType: devicesListReducer.totalsByType,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesTableComponent)
