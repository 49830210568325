import { DEVICE_TYPE, FIRMWARE_TYPES } from '../../../../../services/constants'

import fill from 'lodash/fill'
import moment from 'moment-timezone'

const commonErrorFields = {
  deviceSubType: false,
  serialNumber: false,
}

const uvgiErrorFields = {
  ...commonErrorFields,
  barcode: false,
  mcuUid: false,
  initialFirmwareVersion: false,
}

const deviceDetailsErrors = {
  [DEVICE_TYPE.UVI_ARC.type]: {
    ...commonErrorFields,
    barcode: false,
  },
  [DEVICE_TYPE.UR_UVGI.type]: {
    ...uvgiErrorFields,
  },
  [DEVICE_TYPE.FAR_UVC.type]: {
    ...uvgiErrorFields,
  },
  [DEVICE_TYPE.EXT_DEVICE.type]: {
    ...commonErrorFields,
  },
  [DEVICE_TYPE.NETWORK_DEVICE.type]: {
    ...commonErrorFields,
    macAddress: false,
  },
  [DEVICE_TYPE.SENSOR.type]: {
    ...commonErrorFields,
    macAddress: false,
  },
}

const initialStateDeviceDetails = {
  [DEVICE_TYPE.UVI_ARC.type]: {
    deviceSubType: null,
    serialNumber: '',
    barcode: '',
  },
  [DEVICE_TYPE.UR_UVGI.type]: {
    serialNumber: '',
    mcuUid: '',
    uvgiType: 'beam',
  },
  [DEVICE_TYPE.FAR_UVC.type]: {
    serialNumber: '',
    mcuUid: '',
  },
  [DEVICE_TYPE.EXT_DEVICE.type]: {
    deviceSubType: DEVICE_TYPE.EXT_DEVICE.type,
    serialNumber: '',
    extDeviceType: '',
    extDeviceSubType: '',
  },
  [DEVICE_TYPE.NETWORK_DEVICE.type]: {
    deviceSubType: DEVICE_TYPE.NETWORK_DEVICE.type,
    serialNumber: '',
    networkDeviceType: '',
    macAddress: '',
  },
  [DEVICE_TYPE.SENSOR.type]: {
    sensorDeviceType: DEVICE_TYPE.PIRUV.type,
    serialNumber: '',
    hardwareId: '',
  },
}

const ADD_DEVICE_LABELS = {
  addDevice: 'Add Device',
  deviceType: 'Device Type',
  deviceSubType: 'Device Sub Type',
  serialNumber: 'Serial Number',
  imeiBarcode: 'IMEI Barcode',
  externalDeviceType: 'External Device Type',
  externalDeviceSubType: 'External Device SubType',
  networkDeviceType: 'Network Device Type',
  sensorDeviceType: 'Sensor Device Type',
  macAddress: 'Mac Address',
  hardwareId: 'Hardware ID',
}

const DEVICE_DETAILS = {
  created: 'Created',
  updated: 'Updated',
  certificateCreated: 'Certificate Created',
  buttonTitle: 'Details',
  pageTitle: 'Device Details',
  serialNumber: 'Serial Number',
  imei: 'IMEI',
  wifiMac: 'WiFi MAC Address',
  firmwares: 'Firmwares',
  deviceType: 'Device Type',
  deviceSubType: 'Device Sub Type',
  removed: 'Removed',
  RSSI: 'RSSI',
  deviceId: 'Device ID',
  noInformation: 'No Information',
  transactionTimeout: 'Transaction Timeout',
  desiredTransactionTimeout: 'Desired Transaction Timeout',
  currentTransactionTimeout: 'Current Transaction Timeout',
  save: 'Save',
  outputPower: 'Output Power',
  devicePortalActivity: 'Device Portal Activity',
  macAddress: 'Mac Address',
  allowList: 'Allow List',
  batteryLevel: 'Battery Level',
  version: 'Version',
  initialized: 'Initialized',
  organizationName: 'Organization Name',
  organizationId: 'Organization ID',
  loraId: 'Lora ID',
  bluetoothId: 'Bluetooth ID',
  mcuUid: 'MCU UID',
  deviceEui: 'Device EUI',
  initialFirmwareVersion: 'Initial Firmware Version',
  baseMac: 'Base MAC Address',
  softApMac: 'Soft AP MAC Address',
  ethernetMac: 'Ethernet MAC Address',
  barcode: 'Barcode',
  cumulativeUsage: 'Bulb lifetime use',
  lastPoll: 'Last Seen',
}

const DEVICE_SETTINGS = {
  title: 'Device Settings',
  lastUpdated: 'Last updated',
  exposureLimit: 'Exposure Limit',
  powerLevel: 'Power Level %',
  power: 'Power',
  safetyDelaySeconds: 'Safety Delay (seconds)',
  safetyDelay: 'Safety Delay',
  onTimeMinutes: 'On-Time (minutes)',
  onTime: 'On-Time',
  mode: 'Mode',
  AUTO: 'Automatic',
  SCHEDULED: 'Schedule',
  dailyStartTime: 'Daily Start Time',
  dailyEndTime: 'Daily End Time',
  saveChanges: 'Save Changes',
  updating: 'Updating...',
  disableDevice: 'Disable Device',
  disableDeviceHelperText: 'Device must be disabled if being serviced',
  factoryReset: 'Factory Reset',
  keepLedAlways: 'Keep bulbs always on during the scheduled time frame',
  restartDevice: 'Restart Device',
  operationWindowSize: 'Operation Window Size (seconds)',
  operationWindowRuntimeMax: 'Operation Window Runtime Max (seconds)',
  dailyMaxRuntime: 'Daily Runtime Max (seconds)',
}

const ET_OFFSET = -moment.tz('US/Eastern').utcOffset()
const PT_OFFSET = -moment.tz('US/Pacific').utcOffset()

const ET_HOURS = ET_OFFSET / 60
const PT_HOURS = PT_OFFSET / 60

const ET_MINUTES = ET_OFFSET % 60
const PT_MINUTES = PT_OFFSET % 60

const TZ_DIFFERENCES = {
  ET: {
    hours: ET_HOURS > 0 ? `+${ET_HOURS}` : ET_HOURS,
    minutes: ET_MINUTES ? `:${ET_MINUTES.toString().padStart(2, '0')}` : '',
  },
  PT: {
    hours: PT_HOURS > 0 ? `+${PT_HOURS}` : PT_HOURS,
    minutes: PT_MINUTES ? `:${PT_MINUTES.toString().padStart(2, '0')}` : '',
  },
}

export const DEVICE_DETAILS_ALERTS = {
  INFO_ALL_TIMES_ARE_UTC: `All times are shown in UTC (ET${TZ_DIFFERENCES.ET.hours}${TZ_DIFFERENCES.ET.minutes} or PT${TZ_DIFFERENCES.PT.hours}${TZ_DIFFERENCES.PT.minutes})`,
}

const updatableFields = {
  power: 'desiredPower',
  safetyDelay: 'desiredSafetyDelay',
  onTime: 'desiredOnTime',
  dailyStartTime: 'desiredDailyStartTime',
  dailyEndTime: 'desiredDailyEndTime',
  days: 'desiredDays',
  keepLedAlways: 'desiredKeepLedAlways',
  operationWindowRuntimeMax: 'desiredOperationWindowRuntimeMax',
  operationWindowSize: 'desiredOperationWindowSize',
}

const keepLedAlwaysField = 'keepLedAlways'

const MODE_VALUES = {
  default: 'DEFAULT',
  automatic: 'AUTO',
  schedule: 'SCHEDULED',
}

const EDIT_DEVICE = {
  buttonTitle: 'Edit',
  pageTitle: 'Edit Device',
  pageViewModeTitle: 'View Device',
  wifiMac: 'Wifi Mac',
  loraId: 'Lora Id',
  bluetoothId: 'Bluetooth Id',
  mcuUid: 'Mcu Uid',
  deviceEui: 'Device Eui',
  initialFirmwareVersion: 'Initial Firmware Version',
  baseMac: 'Base Mac',
  softApMac: 'Soft Ap Mac',
  ethernetMac: 'Ethernet Mac',
  save: 'Save',
}

const timeFields = {
  dailyStartTime: 'dailyStartTime',
  dailyEndTime: 'dailyEndTime',
}

const FIRMWARE_TYPE_TABLE = {
  serialNumber: 'Serial Number',
  version: 'Actual Version',
  assignedDate: 'Actual Version Assign Date',
  desiredVersion: 'Desired Version',
  desiredDate: 'Desired Version Scheduled Date',
  commentary: 'Commentary',
}

const DEVICE_EVENT_TITLE = 'Device Events'
const DEVICE_SETTINGS_HISTORY_TITLE = 'Settings History'

const HISTORY_TABLE = {
  created: 'Created',
  firmwareId: 'Firmware Id',
  firmwareVersion: 'Firmware Version',
  organizationName: 'Organisation Name',
  requestType: 'Request Type',
  deviceTime: 'Device Time',
  source: 'Source',
  desiredDate: 'Desired Version Scheduled Date',
}

const viveRequiredFields = ['desiredOnTime']

const requiredFields = [
  ...viveRequiredFields,
  'desiredPower',
  'desiredSafetyDelay',
]

const ACTUAL_DEVICE_FIRMWARE = 'Actual Device Firmware'

const FIRMWARE_TYPES_LABELS = {
  MODEM: 'Modem',
  BLUETOOTH: 'Bluetooth',
  UR_UVGI_COMBINED: 'UR_UVGI_COMBINED',
  MODEM_STACK: 'MODEM_STACK',
}

const anotherTypeCountData = (count: number, deviceType: string) =>
  `Notification: There are ${count} rows with firmwareType not ${
    deviceType === DEVICE_TYPE.UVI_ARC.type
      ? 'BLUETOOTH/MODEM'
      : 'UR_UVGI_COMBINED'
  }.`

const FIRMWARE_TYPES_ARRAY = {
  [DEVICE_TYPE.UVI_ARC.type]: [
    FIRMWARE_TYPES.BLUETOOTH.value,
    FIRMWARE_TYPES.MODEM.value,
  ],
  [DEVICE_TYPE.UVI_ARC_REV_B.type]: [
    FIRMWARE_TYPES.BLUETOOTH.value,
    FIRMWARE_TYPES.MODEM.value,
    FIRMWARE_TYPES.MODEM_STACK.value,
  ],
  [DEVICE_TYPE.UVI_ARC_REV_D.type]: [
    FIRMWARE_TYPES.BLUETOOTH.value,
    FIRMWARE_TYPES.MODEM.value,
    FIRMWARE_TYPES.MODEM_STACK.value,
  ],
  [DEVICE_TYPE.UR_UVGI.type]: [FIRMWARE_TYPES.UR_UVGI_COMBINED.value],
  [DEVICE_TYPE.FAR_UVC.type]: [FIRMWARE_TYPES.UR_UVGI_COMBINED.value],
}

const MIN_RUNTIME_VALUE = 60

const MAX_RUNTIME_VALUE = 86400

const MAX_POWER_VALUE = 100

const MIN_POWER_VALUE = 1

const MAX_SAFETY_DELAY_VALUE = 600

const MIN_SAFETY_DELAY_VALUE = 1

const MAX_ON_TIME_VALUE = 1000

const MIN_ON_TIME_VALUE = 1

const exceedsError = 'Schedule exceeds maximum runtime'

const daysError = 'Please, select day(s) of week.'

const invalidDate = 'Invalid Date'

const EXTENDED_FORM_ERRORS_FIELDS = ['mcuUid']

const WEEK_DAYS_LABELS = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
const initialSelectedDays = fill(new Array(WEEK_DAYS_LABELS.length), true)

const SNACKBAR_MESSAGES = {
  updated: 'Updated Successfully',
  restarted: 'Restarted Successfully',
  reset: 'Reset Successfully',
}

const onSuccessUpdateDevice = 'Device Updated Successfully'

const DEVICE_PORTAL_LABELS = {
  dateTime: 'Date time:',
  uri: 'Uri:',
}

const DEVICE_CREATION_PATH = {
  create: 'create',
  createWithIot: 'create-with-iot',
}

const EVENTS_SYNC = {
  OUT_OF_SYNC: 'Device event is out of sync with the server',
  IN_SYNC: 'Device event is in sync with the server',
}

export {
  deviceDetailsErrors,
  initialStateDeviceDetails,
  ADD_DEVICE_LABELS,
  DEVICE_DETAILS,
  DEVICE_SETTINGS,
  updatableFields,
  keepLedAlwaysField,
  MODE_VALUES,
  EDIT_DEVICE,
  timeFields,
  FIRMWARE_TYPE_TABLE,
  DEVICE_EVENT_TITLE,
  DEVICE_SETTINGS_HISTORY_TITLE,
  HISTORY_TABLE,
  requiredFields,
  ACTUAL_DEVICE_FIRMWARE,
  FIRMWARE_TYPES_LABELS,
  daysError,
  exceedsError,
  MIN_ON_TIME_VALUE,
  MAX_ON_TIME_VALUE,
  MIN_SAFETY_DELAY_VALUE,
  MAX_SAFETY_DELAY_VALUE,
  MIN_POWER_VALUE,
  MAX_POWER_VALUE,
  MAX_RUNTIME_VALUE,
  MIN_RUNTIME_VALUE,
  FIRMWARE_TYPES_ARRAY,
  anotherTypeCountData,
  invalidDate,
  EXTENDED_FORM_ERRORS_FIELDS,
  WEEK_DAYS_LABELS,
  initialSelectedDays,
  onSuccessUpdateDevice,
  SNACKBAR_MESSAGES,
  DEVICE_PORTAL_LABELS,
  DEVICE_CREATION_PATH,
  viveRequiredFields,
  EVENTS_SYNC,
}
