import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { HttpClient } from '../../../services/HttpClient'
import dashboardActions from './dashboard-actions'
import { localhost } from './dashboard-constants'

function* getPortalLinks() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/public/portal-info/portals-link')
    })

    if (data.message) {
      yield put(dashboardActions.getPortalLinksFailed(data.message))
    } else {
      yield put(dashboardActions.getPortalLinksSuccess(data))
    }
  } catch (e) {
    yield put(dashboardActions.getPortalLinksFailed(e.message))
  }
}

function* getNumberOfInstances(action) {
  try {
    const { portalLinks } = action.payload

    if (portalLinks.Customer === localhost) {
      return
    }

    const data = yield call(() => {
      return HttpClient.get(
        portalLinks.Customer + '/api/public/portal-info/dashboard-instances'
      )
    })

    if (data.message) {
      yield put(dashboardActions.getNumberOfInstancesFailed(data.message))
    } else {
      yield put(dashboardActions.getNumberOfInstancesSuccess(data, portalLinks))
    }
  } catch (e) {
    yield put(dashboardActions.getNumberOfInstancesFailed(e.message))
  }
}

function* checkCustomerPortal(action) {
  try {
    const { portalLinks } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        portalLinks.Customer + '/api/public/portal-info/customer-portal'
      )
    })

    if (data.message) {
      yield put(dashboardActions.checkCustomerPortalFailed(data.message))
    } else {
      yield put(dashboardActions.checkCustomerPortalSuccess(portalLinks))
    }
  } catch (e) {
    yield put(dashboardActions.checkCustomerPortalFailed(e.message))
  }
}

function* checkDevicePortal(action) {
  try {
    const { portalLinks } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        portalLinks.Device + '/api/public/portal-info/device-portal'
      )
    })

    if (data.message) {
      yield put(dashboardActions.checkDevicePortalFailed(data.message))
    } else {
      yield put(dashboardActions.checkDevicePortalSuccess(data))
    }
  } catch (e) {
    yield put(dashboardActions.checkDevicePortalFailed(e.message))
  }
}

function* loadMainPageValues(action) {
  try {
    const data = yield call(() => {
      return HttpClient.get(`/api/public/portal-info`)
    })

    if (data.message) {
      yield put(dashboardActions.loadMainPageValuesFailed(data.message))
    } else {
      yield put(dashboardActions.loadMainPageValuesSuccess(data))
    }
  } catch (e) {
    yield put(dashboardActions.loadMainPageValuesFailed(e.message))
  }
}

function* loadMainPageValuesSaga() {
  yield takeLatest(
    dashboardActions.actionTypes.LOAD_MAIN_PAGE_VALUES,
    loadMainPageValues
  )
}

function* getPortalLinksSaga() {
  yield takeLatest(
    dashboardActions.actionTypes.LOAD_MAIN_PAGE_VALUES_SUCCEEDED,
    getPortalLinks
  )
}

function* getNumberOfInstancesSaga() {
  yield takeLatest(
    dashboardActions.actionTypes.GET_PORTAL_LINKS_SUCCEEDED,
    getNumberOfInstances
  )
}

function* checkDevicePortalSaga() {
  yield all([
    yield takeEvery(
      dashboardActions.actionTypes.CHECK_CUSTOMER_PORTAL_SUCCEEDED,
      checkDevicePortal
    ),
    yield takeEvery(
      dashboardActions.actionTypes.CHECK_CUSTOMER_PORTAL_FAILED,
      checkDevicePortal
    ),
  ])
}

function* checkCustomerPortalSaga() {
  yield takeLatest(
    dashboardActions.actionTypes.GET_NUMBER_OF_INSTANCES_SUCCEEDED,
    checkCustomerPortal
  )
}

export default function* dashboardSaga() {
  yield fork(loadMainPageValuesSaga)
  yield fork(getPortalLinksSaga)
  yield fork(checkCustomerPortalSaga)
  yield fork(checkDevicePortalSaga)
  yield fork(getNumberOfInstancesSaga)
}
