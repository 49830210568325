import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { HttpClient } from '../../../services/HttpClient'
import authorizationActions from './authorization-actions'
import { LOGIN_STATUSES } from './authorization-constants'

function* login(action) {
  try {
    const {
      body: { login, password },
      onSuccess,
    } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/public/login', {
        body: {
          login,
          password,
        },
      })
    })

    if (data.message) {
      yield put(authorizationActions.loginFailed(data.message))
    } else {
      if (data.status === LOGIN_STATUSES.ok) {
        localStorage.setItem('LOGIN', login)
        localStorage.setItem('LOGIN_FOR_RESET', null)

        HttpClient.login(data)
        yield put(authorizationActions.loginSucceeded(data))

        if (onSuccess) {
          onSuccess()
        }
      }

      if (data.status === LOGIN_STATUSES.new) {
        localStorage.setItem('LOGIN_FOR_RESET', login)
        window.location.href = '/#/updatePassword'
      }

      if (data.status === LOGIN_STATUSES.deactivated) {
        yield put(authorizationActions.userDeactivated())
      }
    }
  } catch (e) {
    yield put(authorizationActions.loginFailed(e.message))
  }
}

function* loginFromResetForm(action) {
  try {
    const {
      body: { login, password, newPassword, givenName, familyName },
      onSuccess,
    } = action.payload
    const isUpdatePassword =
      window.location.href.indexOf('/updatePassword') !== -1

    const data = yield call(() => {
      return HttpClient.post('/api/public/login', {
        body: {
          login,
          password,
        },
      })
    })

    if (data.message) {
      yield put(authorizationActions.loginFailed(data.message))
    } else {
      if (data.status === LOGIN_STATUSES.ok) {
        localStorage.setItem('LOGIN', login)
        localStorage.setItem('LOGIN_FOR_RESET', null)

        HttpClient.login(data)
        yield put(authorizationActions.loginSucceeded(data))

        if (onSuccess) {
          onSuccess()
        }
      }

      if (data.status === LOGIN_STATUSES.new) {
        if (isUpdatePassword) {
          yield put(
            authorizationActions.setupNewPassword(
              data.session,
              login,
              password,
              newPassword,
              givenName,
              familyName,
              onSuccess
            )
          )
        } else {
          yield put(
            authorizationActions.updatePassword(
              data.session,
              login,
              password,
              newPassword,
              onSuccess
            )
          )
        }
      }
    }
  } catch (e) {
    yield put(authorizationActions.loginFailed(e.message))
  }
}

function* logout(action) {
  try {
    const login = localStorage.getItem('LOGIN')

    const data = yield call(() => {
      return HttpClient.post('/api/public/logout', {
        body: {
          login,
        },
      })
    })

    if (data.message) {
      yield put(authorizationActions.logoutFailed(data.message))
    } else {
      localStorage.setItem('LOGIN', null)
      window.location.href = '/#/login'

      HttpClient.logout()
      yield put(authorizationActions.logoutSucceeded(data))
    }
  } catch (e) {
    yield put(authorizationActions.logoutFailed(e.message))
  }
}

function* resetPassword(action) {
  try {
    const { login, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/public/user-profiles/reset-password', {
        body: {
          email: login,
        },
      })
    })

    if (data.message) {
      yield put(authorizationActions.resetPasswordFailed(data.message))
    } else {
      yield put(authorizationActions.resetPasswordSucceeded(data))

      if (onSuccess) {
        onSuccess(login)
      }
    }
  } catch (e) {
    yield put(authorizationActions.resetPasswordFailed(e.message))
  }
}

function* setupNewPassword(action) {
  try {
    const {
      login,
      password,
      newPassword,
      session,
      onSuccess,
      givenName,
      familyName,
    } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/public/set-new-password', {
        body: {
          login,
          password,
          newPassword,
          session,
          givenName,
          familyName,
        },
      })
    })

    if (data.message) {
      yield put(authorizationActions.setupNewPasswordFailed(data.message))
    } else {
      localStorage.setItem('LOGIN', login)
      localStorage.setItem('LOGIN_FOR_RESET', null)

      HttpClient.login(data)

      if (onSuccess) {
        onSuccess()
      }

      yield put(authorizationActions.setupNewPasswordSucceeded(data))
    }
  } catch (e) {
    yield put(authorizationActions.setupNewPasswordFailed(e.message))
  }
}

function* updatePassword(action) {
  try {
    const { login, token, newPassword, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.post('/api/public/update-password', {
        body: {
          login,
          password: token,
          newPassword,
          session: token,
        },
      })
    })

    if (data.message) {
      yield put(authorizationActions.updatePasswordFailed(data.message))
    } else {
      if (onSuccess) {
        onSuccess()
      }

      yield put(authorizationActions.updatePasswordSucceeded(data))
    }
  } catch (e) {
    yield put(authorizationActions.updatePasswordFailed(e.message))
  }
}

function* loginSaga() {
  yield takeEvery(authorizationActions.actionTypes.LOGIN_TO_APP, login)
}

function* logoutSaga() {
  yield takeEvery(authorizationActions.actionTypes.LOGOUT, logout)
}

function* resetPasswordSaga() {
  yield takeEvery(
    authorizationActions.actionTypes.RESET_PASSWORD,
    resetPassword
  )
}

function* setupNewPasswordSaga() {
  yield takeEvery(
    authorizationActions.actionTypes.SETUP_NEW_PASSWORD,
    setupNewPassword
  )
}

function* updatePasswordSaga() {
  yield takeEvery(
    authorizationActions.actionTypes.UPDATE_PASSWORD,
    updatePassword
  )
}

function* loginFromResetPasswordSaga() {
  yield takeEvery(
    authorizationActions.actionTypes.CHECK_TEMPORARY_PASSWORD,
    loginFromResetForm
  )
}

export default function* authorizationSaga() {
  yield fork(loginSaga)
  yield fork(logoutSaga)
  yield fork(resetPasswordSaga)
  yield fork(setupNewPasswordSaga)
  yield fork(updatePasswordSaga)
  yield fork(loginFromResetPasswordSaga)
}
