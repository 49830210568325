import { getRoomsListFromTree } from '../../_shared/services/emulator-service'
import extEmulatorActions from './extEmulator-actions'
import { EXTEmulatorInitialState } from './extEmulator-constants'

export const ExtEmulatorReducer = (state = EXTEmulatorInitialState, action) => {
  switch (action.type) {
    case extEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_SUCCEEDED:
      return {
        ...state,
        deviceMessage: action.payload.message,
        error: '',
      }
    case extEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_FAILED:
      return {
        ...state,
        error: action.payload.message,
      }
    case extEmulatorActions.actionTypes.LOAD_EXT_DEVICES_SUCCEEDED:
      return {
        ...state,
        devices: action.payload.devices,
        error: '',
      }
    case extEmulatorActions.actionTypes.LOAD_DEVICE_LOCATIONS_SUCCEEDED:
      return {
        ...state,
        deviceLocations: getRoomsListFromTree(action.payload.locations),
        error: '',
      }
    case extEmulatorActions.actionTypes.LOAD_ALL_LOCATIONS_SUCCEEDED:
      return {
        ...state,
        allLocations: getRoomsListFromTree(action.payload.locations),
        error: '',
      }
    case extEmulatorActions.actionTypes.LOAD_EXT_DEVICES_FAILED:
      return {
        ...state,
        error: action.payload.message,
      }
    default:
      return state
  }
}
