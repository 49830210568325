import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import join from 'lodash/join'
import { HttpClient } from '../../../services/HttpClient'
import firmwareUpdateActions from './firmwareUpdate-actions'
import { prepareFilterQuery } from './firmwareUpdate-service'

export const getFilterQuery = (state) => state.firmwareUpdateReducer.query
const getPage = (state) => state.firmwareUpdateReducer.page
const getRowsPerPage = (state) => state.firmwareUpdateReducer.rowsPerPage
const getFirmwareTypeMap = (state) =>
  state.firmwareUpdateReducer.firmwareTypeMap

function* loadDevices(action) {
  try {
    const { query } = action.payload
    const oldQuery = yield select(getFilterQuery)
    const defaultQuery = prepareFilterQuery()

    const page = yield select(getPage)
    const rowsPerPage = yield select(getRowsPerPage)

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/firmwares/devices${
          query || oldQuery || defaultQuery
        }&pageNum=${page}&pageSize=${rowsPerPage}`
      )
    })

    if (data.message) {
      yield put(firmwareUpdateActions.loadDevicesFailed(data.message))
    } else {
      yield put(firmwareUpdateActions.loadDevicesSuccess(data))
    }
  } catch (e) {
    yield put(firmwareUpdateActions.loadDevicesFailed(e.message))
  }
}

function* loadFirmwareTypes() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/firmwares/firmware-types')
    })

    if (data.message) {
      yield put(firmwareUpdateActions.loadFirmwareTypesFailed(data.message))
    } else {
      yield put(firmwareUpdateActions.loadFirmwareTypesSuccess(data))
    }
  } catch (e) {
    yield put(firmwareUpdateActions.loadFirmwareTypesFailed(e.message))
  }
}

function* loadDevicesTypes() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/devices/types')
    })

    if (data.message) {
      yield put(firmwareUpdateActions.loadDevicesTypesFailed(data.message))
    } else {
      yield put(firmwareUpdateActions.loadDevicesTypesSuccess(data))
    }
  } catch (e) {
    yield put(firmwareUpdateActions.loadDevicesTypesFailed(e.message))
  }
}

function* loadOrganizations() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/organizations/scroll?order.name=asc')
    })

    if (data.message) {
      yield put(firmwareUpdateActions.loadOrganizationsFailed(data.message))
    } else {
      yield put(firmwareUpdateActions.loadOrganizationsSuccess(data))
    }
  } catch (e) {
    yield put(firmwareUpdateActions.loadOrganizationsFailed(e.message))
  }
}

function* loadVersions(action) {
  try {
    const { firmwareType } = action.payload
    const deviceTypesMap = yield select(getFirmwareTypeMap)

    const deviceType = deviceTypesMap[firmwareType]

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/firmwares/type/${firmwareType}/${deviceType}`
      )
    })

    if (data.message) {
      yield put(firmwareUpdateActions.loadFirmwareVersionsFailed(data.message))
    } else {
      yield put(firmwareUpdateActions.loadFirmwareVersionsSuccess(data))
    }
  } catch (e) {
    yield put(firmwareUpdateActions.loadFirmwareVersionsFailed(e.message))
  }
}

function* scheduleUpdate(action) {
  try {
    const { versionId, devicesIds, onSuccess } = action.payload
    const ids = join(devicesIds, ',')

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/firmwares/${versionId}/devices/${ids}`
      )
    })

    if (data.message) {
      yield put(
        firmwareUpdateActions.scheduleUpdateFirmwareVersionsFailed(data.message)
      )
    } else {
      onSuccess()
      yield put(
        firmwareUpdateActions.scheduleUpdateFirmwareVersionsSuccess(data)
      )
    }
  } catch (e) {
    yield put(
      firmwareUpdateActions.scheduleUpdateFirmwareVersionsFailed(e.message)
    )
  }
}

function* cancelUpdateFirmware(action) {
  try {
    const { deviceFirmwareIds, onSuccess } = action.payload
    let url = ''

    if (deviceFirmwareIds.length === 1) {
      url = `/api/secured/firmwares/cancel/${deviceFirmwareIds}`
    } else {
      url = `/api/secured/firmwares/cancel/all/${join(deviceFirmwareIds, ',')}`
    }

    const data = yield call(() => {
      return HttpClient.post(url)
    })

    if (data.message) {
      yield put(firmwareUpdateActions.cancelUpdateFirmwareFailed(data.message))
    } else {
      onSuccess()
      yield put(firmwareUpdateActions.cancelUpdateFirmwareSuccess(data))
    }
  } catch (e) {
    yield put(firmwareUpdateActions.cancelUpdateFirmwareFailed(e.message))
  }
}

function* loadDevicesSaga() {
  yield all([
    yield takeLatest(
      firmwareUpdateActions.actionTypes.LOAD_DEVICES,
      loadDevices
    ),
    yield takeEvery(
      firmwareUpdateActions.actionTypes.LOAD_DEVICES_TYPES_SUCCEEDED,
      loadDevices
    ),
    yield takeEvery(
      firmwareUpdateActions.actionTypes.GO_TO_NEXT_PAGE,
      loadDevices
    ),
    yield takeEvery(
      firmwareUpdateActions.actionTypes.SET_ROWS_PER_PAGE,
      loadDevices
    ),
    yield takeEvery(
      firmwareUpdateActions.actionTypes.CANCEL_UPDATE_FIRMWARE_SUCCEEDED,
      loadDevices
    ),
    yield takeEvery(
      firmwareUpdateActions.actionTypes
        .SCHEDULE_UPDATE_FIRMWARE_VERSIONS_SUCCEEDED,
      loadDevices
    ),
  ])
}

function* loadVersionsSaga() {
  yield takeLatest(
    firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_VERSIONS,
    loadVersions
  )
}

function* scheduleUpdateSaga() {
  yield takeLatest(
    firmwareUpdateActions.actionTypes.SCHEDULE_UPDATE_FIRMWARE_VERSIONS,
    scheduleUpdate
  )
}

function* loadFirmwareTypesSaga() {
  yield takeLatest(
    firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_TYPES,
    loadFirmwareTypes
  )
}

function* cancelUpdateFirmwareSaga() {
  yield takeLatest(
    firmwareUpdateActions.actionTypes.CANCEL_UPDATE_FIRMWARE,
    cancelUpdateFirmware
  )
}

function* loadDevicesTypesSaga() {
  yield takeLatest(
    firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_TYPES_SUCCEEDED,
    loadDevicesTypes
  )
}

function* loadOrganizationsSaga() {
  yield takeLatest(
    firmwareUpdateActions.actionTypes.LOAD_ORGANIZATIONS,
    loadOrganizations
  )
}

export default function* firmwareUpdateSagas() {
  yield fork(loadDevicesSaga)
  yield fork(loadVersionsSaga)
  yield fork(scheduleUpdateSaga)
  yield fork(loadFirmwareTypesSaga)
  yield fork(loadDevicesTypesSaga)
  yield fork(cancelUpdateFirmwareSaga)
  yield fork(loadOrganizationsSaga)
}
