import { ReactComponent as NoPermissions } from '../../../assets/icons/no_permissions.svg'
import ErrorPage from './ErrorPage'
import { NO_PERMISSIONS_ERROR } from './errors-constants'

const NoPermissionsError = () => {
  return (
    <ErrorPage
      pageTitle={NO_PERMISSIONS_ERROR.generalName}
      errorDescriptionPart1={NO_PERMISSIONS_ERROR.description1}
      hideLogo={true}
      Icon={NoPermissions}
    />
  )
}

export default NoPermissionsError
