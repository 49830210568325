const uvgiEmulatorActions = {}

uvgiEmulatorActions.actionTypes = {
  SEND_DEVICE_MESSAGE: '{UVGI EVENTS EMULATOR} Send Device Message',
  SEND_DEVICE_MESSAGE_SUCCEEDED:
    '{UVGI EVENTS EMULATOR} Send Device Message Succeeded',
  SEND_DEVICE_MESSAGE_FAILED:
    '{UVGI EVENTS EMULATOR} Send Device Message Failed',

  LOAD_ALL_LOCATIONS: '{UVGI EVENTS EMULATOR} Load All Locations',
  LOAD_ALL_LOCATIONS_SUCCEEDED:
    '{UVGI EVENTS EMULATOR} Load All Locations Succeeded',

  LOAD_DEVICE_LOCATIONS_SUCCEEDED:
    '{UVGI EVENTS EMULATOR} Load Device Locations Succeeded',
  LOAD_DEVICE_LOCATIONS_FAILED:
    '{UVGI EVENTS EMULATOR} Load Device Locations Failed',

  LOAD_UVGI_DEVICES: '{UVGI EVENTS EMULATOR} Load UVGI Devices',
  LOAD_UVGI_DEVICES_SUCCEEDED:
    '{UVGI EVENTS EMULATOR} Load UVGI Devices Succeeded',
  LOAD_UVGI_DEVICES_FAILED: '{UVGI EVENTS EMULATOR} Load UVGI Devices Failed',
}

//device message actions (for BE team)

uvgiEmulatorActions.sendDeviceMessage = function (params, body) {
  return {
    type: uvgiEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE,
    payload: {
      params,
      body,
    },
  }
}

uvgiEmulatorActions.sendDeviceMessageSuccess = function (message, activeTab) {
  return {
    type: uvgiEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_SUCCEEDED,
    payload: {
      message,
      activeTab,
    },
  }
}

uvgiEmulatorActions.sendDeviceMessageFailed = function (message) {
  return {
    type: uvgiEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_FAILED,
    payload: {
      message,
    },
  }
}

uvgiEmulatorActions.loadAllLocations = function () {
  return {
    type: uvgiEmulatorActions.actionTypes.LOAD_ALL_LOCATIONS,
  }
}

uvgiEmulatorActions.loadAllLocationsSuccess = function (locations) {
  return {
    type: uvgiEmulatorActions.actionTypes.LOAD_ALL_LOCATIONS_SUCCEEDED,
    payload: {
      locations,
    },
  }
}

uvgiEmulatorActions.loadLocationsSuccess = function (locations) {
  return {
    type: uvgiEmulatorActions.actionTypes.LOAD_DEVICE_LOCATIONS_SUCCEEDED,
    payload: {
      locations,
    },
  }
}

uvgiEmulatorActions.loadLocationsFailed = function (message) {
  return {
    type: uvgiEmulatorActions.actionTypes.LOAD_DEVICE_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

uvgiEmulatorActions.loadUVGIDevices = function () {
  return {
    type: uvgiEmulatorActions.actionTypes.LOAD_UVGI_DEVICES,
  }
}

uvgiEmulatorActions.loadUVGIDevicesSuccess = function (devices) {
  return {
    type: uvgiEmulatorActions.actionTypes.LOAD_UVGI_DEVICES_SUCCEEDED,
    payload: {
      devices,
    },
  }
}

uvgiEmulatorActions.loadUVGIDevicesFailed = function (message) {
  return {
    type: uvgiEmulatorActions.actionTypes.LOAD_UVGI_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

export default uvgiEmulatorActions
