import {
  ADD_LOCATION,
  ADD_LOCATIONS_BULK,
  RESET_ERROR,
  SET_ERROR,
} from '../../../services/organizationDetails-actions'
import {
  checkNameCase,
  prepareBulkLocations,
  validateLocationName,
} from '../../../services/organizationDetails-service'
import {
  Location,
  UploadLocation,
} from '../../../services/organizationDetails-types'

import Button from '@material-ui/core/Button'
import capitalize from 'lodash/capitalize'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { checkConnection } from 'services/root/root-service'
import { useAppSelector } from 'utils/reduxHelpers'
import { ADD_LOCATION_LABELS } from '../../../services/organizationDetails-constants'
import AddLocationModal from './AddLocationModal'
import { useStyles } from './style'

type AddLocationButtonProps = {
  parentId?: string
  locationType?: string
}

const AddLocationButton = ({
  parentId,
  locationType = 'property',
}: AddLocationButtonProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const error = useAppSelector(
    (state) => state.organizationDetailsReducer.error
  )

  const addLocation = (body: Partial<Location>, onSuccess: () => void) =>
    dispatch(checkConnection(() => ADD_LOCATION(body, onSuccess)))
  const addLocationsBulk = (body: UploadLocation[], onSuccess: () => void) =>
    dispatch(checkConnection(() => ADD_LOCATIONS_BULK(body, onSuccess)))
  const setError = (error: string) => dispatch(SET_ERROR(error))
  const resetError = () => dispatch(RESET_ERROR())

  const isBulk = locationType === 'room' || locationType === 'placement'

  const changeOpen = () => {
    setOpen(!open)
  }

  const onSubmitButton = (
    value: string,
    isBulk: boolean,
    locationType: string,
    propertyFields: Partial<Location>
  ) => {
    const validationError = validateLocationName(value, isBulk)

    if (validationError) {
      setError(validationError)
    } else {
      const uiValidationChecking = checkNameCase(value)

      if (isBulk && uiValidationChecking.length) {
        return
      } else {
        const body = { name: value, locationType, parentId }

        if (isBulk) {
          const newLocations = prepareBulkLocations(body)

          addLocationsBulk(newLocations, changeOpen)
        } else {
          const locationData =
            locationType === 'property'
              ? { ...body, ...propertyFields }
              : {
                  ...body,
                  width: propertyFields.width,
                  length: propertyFields.length,
                  height: propertyFields.height,
                }

          addLocation(locationData, changeOpen)
        }
      }
    }
  }

  return (
    <>
      <Button
        onClick={changeOpen}
        variant="outlined"
        color="primary"
        className={styles.addLocationButton}
      >
        {ADD_LOCATION_LABELS.plusAdd} {capitalize(locationType)}
      </Button>
      <AddLocationModal
        isOpen={open}
        isBulk={isBulk}
        onClose={changeOpen}
        onSubmit={onSubmitButton}
        locationType={locationType}
        modalTitle={ADD_LOCATION_LABELS.title}
        error={error}
        resetError={resetError}
      />
    </>
  )
}

export default AddLocationButton
