import { Box, Typography } from '@material-ui/core'

import DeleteDeviceAction from '../../List/components/DeleteDeviceAction'
import FactoryResetAction from '../../List/components/FactoryResetAction'
import FullCleanupAction from '../../List/components/FullCleanupAction'
import RestartDeviceAction from '../../List/components/RestartDeviceAction'
import { useStyles } from './style'

type DeviceActionsProps = {
  deviceId: string
  serialNumber: string
  onOpenSnackbar: () => void
  setSnackbarMessage: (message: string) => void
}

const DeviceActions = ({
  deviceId,
  serialNumber,
  onOpenSnackbar,
  setSnackbarMessage,
}: DeviceActionsProps) => {
  const style = useStyles()

  return (
    <>
      <Typography variant="h4">Actions</Typography>
      <Box className={style.actionContainer}>
        <FullCleanupAction id={deviceId} serialNumber={serialNumber} />
        <DeleteDeviceAction id={deviceId} serialNumber={serialNumber} />
        <RestartDeviceAction
          id={deviceId}
          onOpenSnackbar={onOpenSnackbar}
          setSnackbarMessage={setSnackbarMessage}
        />
        <FactoryResetAction
          id={deviceId}
          onOpenSnackbar={onOpenSnackbar}
          setSnackbarMessage={setSnackbarMessage}
        />
      </Box>
    </>
  )
}

export default DeviceActions
