import map from 'lodash/map'
import { DEFAULT_DEVICE_INFO } from '../../UVGI/services/uvgiEmulator-constants'

const TYPES = {
  powerOn: 'power on',
  powerOff: 'power off',
  countdownStart: 'countdown start',
  countdownInterrupted: 'countdown interrupted',
  countdownResume: 'countdown resume',
  countdownCancel: 'countdown cancel',
  countdownFinish: 'countdown finish',
  cycleStart: 'cycle start',
  cycleInterrupted: 'cycle interrupted',
  cycleResume: 'cycle resume',
  cycleCancel: 'cycle cancel',
  cycleFinish: 'cycle finish',
  init: 'Initialization of new device message',
  firmwareStart: 'firmware update start',
  firmwareSuccess: 'firmware update success',
  firmwareFail: 'firmware update fail',
  firmwareStatistics: 'firmware statistics',
  statistics: 'statistics',
  requestParameters: 'request parameters',
}

const DEFAULT_META_INFO = {
  key: '',
  type: 'string',
  value: '',
}

const initialState = {
  deviceName: '',
  locationId: '',
  messageTypeText: '',
  transactionId: '',
  useCurrentTime: true,
  deviceDateTime: new Date(),

  duration: '',

  firmwareUpdateStatus: '',
  firmwareUpdateTime: new Date(),
  firmwareUpdateType: '',
  firmwareUpdateVersion: '',

  imei: '',
  imeiCopy: '',
  comment: 'web-emulator',

  metaInfo: [DEFAULT_META_INFO],

  bulbChangeDate: new Date(),
  deviceRevision: '',
  softwareRevision: null,
  bulbCyclesSinceChange: '',
  powerCyclesSinceChange: '',
  abuseEvents: '',

  isFirmwareUpdate: false,
  isStatisticMessage: false,
  isDurationFieldHidden: true,
  isSubmitted: false,
  deviceLocationOnly: false,
  isAdditionalParams: false,

  commonDevicesInfo: [{ ...DEFAULT_DEVICE_INFO }],

  isExtended: false,
  specialMode1: '0',
  specialMode2: '0',
}

const messageConstants = [
  {
    label: TYPES.powerOn,
    value: TYPES.powerOn,
  },
  {
    label: TYPES.powerOff,
    value: TYPES.powerOff,
  },
  {
    label: TYPES.countdownStart,
    value: TYPES.countdownStart,
  },
  {
    label: TYPES.countdownInterrupted,
    value: TYPES.countdownInterrupted,
  },
  {
    label: TYPES.countdownResume,
    value: TYPES.countdownResume,
  },
  {
    label: TYPES.countdownCancel,
    value: TYPES.countdownCancel,
  },
  {
    label: TYPES.countdownFinish,
    value: TYPES.countdownFinish,
  },
  {
    label: TYPES.cycleStart,
    value: TYPES.cycleStart,
  },
  {
    label: TYPES.cycleInterrupted,
    value: TYPES.cycleInterrupted,
  },
  {
    label: TYPES.cycleResume,
    value: TYPES.cycleResume,
  },
  {
    label: TYPES.cycleCancel,
    value: TYPES.cycleCancel,
  },
  {
    label: TYPES.cycleFinish,
    value: TYPES.cycleFinish,
  },
  {
    label: TYPES.init,
    value: 'init',
  },
  {
    label: TYPES.firmwareStart,
    value: TYPES.firmwareStart,
  },
  {
    label: TYPES.firmwareSuccess,
    value: TYPES.firmwareSuccess,
  },
  {
    label: TYPES.firmwareFail,
    value: TYPES.firmwareFail,
  },
  {
    label: TYPES.firmwareStatistics,
    value: 'statistics',
  },
  {
    label: TYPES.requestParameters,
    value: TYPES.requestParameters,
  },
]

const firmwareStatuses = [
  TYPES.firmwareStart,
  TYPES.firmwareSuccess,
  TYPES.firmwareFail,
]

const metaInfoValues = map(messageConstants, (message) => message.value)

const firmwareUpdateStatuses = {
  [TYPES.firmwareStart]: 'START',
  [TYPES.firmwareSuccess]: 'SUCCESS',
  [TYPES.firmwareFail]: 'FAIL',
}

const firmwareUpdateTypes = [
  {
    label: 'Bluetooth',
    value: 'BLUETOOTH',
  },
  {
    label: 'Modem',
    value: 'MODEM',
  },
]

const firmwareUpdateTypesRev = [
  ...firmwareUpdateTypes,
  {
    label: 'Modem_Stack',
    value: 'MODEM_STACK',
  },
]

const FIRMWARE_UPDATES_LABELS = {
  firmwareUpdateStatus: 'Firmware Update Status',
  firmwareUpdateTime: 'Firmware Update Time',
  firmwareUpdateVersion: 'Firmware Update Version ',
  firmwareUpdateType: 'Firmware Update Type',
}

const STATISTICS_MESSAGE_LABELS = {
  bulbChangeDate: 'Bulb Change Date',
  deviceRevision: 'Device Revision',
  softwareRevision: 'Software Revision',
  bulbCyclesSinceChange: 'Bulb Cycles Since Change',
  powerCyclesSinceChange: 'Power Cycles Since Change',
  abuseEvents: 'Abuse Events',
}

const REQUEST_PARAMETERS_LABELS = {
  imei: 'IMEI',
  softwareRevision: 'Software Revision',
}

const EVENT_TYPE_VALUES = {
  A9: 'A9',
}

const EXTENDED_MODE = {
  specialMode1: 'Special mode 1',
  specialMode2: 'Special mode 2',
  checkboxLabel: 'Extended mode',
}

export {
  initialState,
  messageConstants,
  FIRMWARE_UPDATES_LABELS,
  firmwareUpdateStatuses,
  STATISTICS_MESSAGE_LABELS,
  firmwareStatuses,
  firmwareUpdateTypes,
  TYPES,
  REQUEST_PARAMETERS_LABELS,
  EVENT_TYPE_VALUES,
  EXTENDED_MODE,
  metaInfoValues,
  DEFAULT_META_INFO,
  firmwareUpdateTypesRev,
}
