const rootActions = {}

rootActions.actionTypes = {
  GET_USER_GROUPS: '{ROOT} Get User Groups',
  GET_USER_GROUPS_SUCCEEDED: '{ROOT} Get User Groups Succeeded',
  GET_USER_GROUPS_FAILED: '{ROOT} Get User Groups Failed',

  SETUP_PERMISSIONS: '{ROOT} Setup Permissions',

  SET_NETWORK_ERROR: '{ROOT} Set Network Error',

  LOAD_DEVICES_TYPES: '{ROOT} Load Devices Types',
  LOAD_DEVICES_TYPES_SUCCEEDED: '{ROOT} Load Devices Types Succeeded',
  LOAD_DEVICES_TYPES_FAILED: '{ROOT} Load Devices Types Failed',

  LOAD_SUB_TYPES_FOR_DEVICE: '{ROOT} Load Sub Types For Device',
  LOAD_SUB_TYPES_FOR_DEVICE_SUCCEEDED:
    '{ROOT} Load Sub Types For Device Succeeded',
  LOAD_SUB_TYPES_FOR_DEVICE_FAILED: '{ROOT} Load Sub Types For Device Failed',
}

rootActions.getUserGroups = () => {
  return {
    type: rootActions.actionTypes.GET_USER_GROUPS,
  }
}

rootActions.getUserGroupsSuccess = (groups) => {
  return {
    type: rootActions.actionTypes.GET_USER_GROUPS_SUCCEEDED,
    payload: {
      groups,
    },
  }
}

rootActions.getUserGroupsFailed = (message) => {
  return {
    type: rootActions.actionTypes.GET_USER_GROUPS_FAILED,
    payload: {
      message,
    },
  }
}

rootActions.setupPermissions = (permissions) => {
  return {
    type: rootActions.actionTypes.SETUP_PERMISSIONS,
    payload: {
      permissions,
    },
  }
}

rootActions.setNetworkError = (isOnline) => {
  return {
    type: rootActions.actionTypes.SET_NETWORK_ERROR,
    payload: {
      isOnline,
    },
  }
}

rootActions.loadDevicesTypes = () => {
  return {
    type: rootActions.actionTypes.LOAD_DEVICES_TYPES,
  }
}

rootActions.loadDevicesTypesSuccess = (types) => {
  return {
    type: rootActions.actionTypes.LOAD_DEVICES_TYPES_SUCCEEDED,
    payload: {
      types,
    },
  }
}

rootActions.loadDevicesTypesFailed = (message) => {
  return {
    type: rootActions.actionTypes.LOAD_DEVICES_TYPES_FAILED,
    payload: {
      message,
    },
  }
}

rootActions.loadSubTypesForDevice = (deviceType) => {
  return {
    type: rootActions.actionTypes.LOAD_SUB_TYPES_FOR_DEVICE,
    payload: {
      deviceType,
    },
  }
}

rootActions.loadSubTypesForDeviceSuccess = (subTypes) => {
  return {
    type: rootActions.actionTypes.LOAD_SUB_TYPES_FOR_DEVICE_SUCCEEDED,
    payload: {
      subTypes,
    },
  }
}

rootActions.loadSubTypesForDeviceFailed = (message) => {
  return {
    type: rootActions.actionTypes.LOAD_SUB_TYPES_FOR_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

export default rootActions
