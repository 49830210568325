const styles = (theme) => ({
  title: {
    textAlign: 'center',
    padding: '44px 26px 30px',
  },
  emulatorTitleWrapper: {
    textAlign: 'center',
    marginBottom: '30px',
    marginTop: '44px',
  },
  emulatorWrapper: {
    paddingLeft: '48px',
  },
  commandsWrapper: {
    width: '100%',
    justifyContent: 'space-between',
  },
  generatorWrapper: {
    width: '90%',
  },
  submitButton: {
    marginTop: '15px',
    width: '100%',
  },
})

export default styles
