import {
  daysError,
  DEVICE_DETAILS,
  DEVICE_SETTINGS,
  EXTENDED_FORM_ERRORS_FIELDS,
  invalidDate,
  MAX_ON_TIME_VALUE,
  MAX_POWER_VALUE,
  MAX_SAFETY_DELAY_VALUE,
  MIN_ON_TIME_VALUE,
  MIN_POWER_VALUE,
  MIN_SAFETY_DELAY_VALUE,
  MODE_VALUES,
  requiredFields,
  timeFields,
  viveRequiredFields,
} from './deviceDetails-constants'

import { omitBy } from 'lodash'
import fill from 'lodash/fill'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import some from 'lodash/some'
import moment from 'moment'
import { FIRMWARE_TYPES } from '../../../../../services/constants'

const OPERATION_WINDOW_SIZE_MAX = 60 * 60 * 72

const prepareHistoryDeviceDetails = (array) => {
  const types = [
    FIRMWARE_TYPES.MODEM.value,
    FIRMWARE_TYPES.MODEM_STACK.value,
    FIRMWARE_TYPES.BLUETOOTH.value,
    FIRMWARE_TYPES.UR_UVGI_COMBINED.value,
  ]
  const details = { anotherTypeCount: 0 }

  forEach(array, (item) => {
    if (details[item.type]) {
      details[item.type].push(item)
    } else if (item.type) {
      details[item.type] = [item]
    }

    if (item.type && types.indexOf(item.type) === -1) {
      details.anotherTypeCount++
    }
  })

  return details
}

const checkExtendedFormErrors = (values, isUVGI) => {
  return isUVGI
    ? reduce(
        EXTENDED_FORM_ERRORS_FIELDS,
        (result, value) => {
          return {
            ...result,
            [value]: !values[value],
          }
        },
        {}
      )
    : { extDeviceSubType: !values.extDeviceSubType }
}

const rangeError = (name, maxValue, minValue = 0) =>
  `The ${name} value should be from ${minValue} to ${maxValue}`

const lessThanError = (name, lessThanName) =>
  `The ${name} value should be less than ${lessThanName}`

const greaterThanZeroError = (name) =>
  `The ${name} value should be greater than zero`

const prepareBodyForUpdateSettings = (body) => {
  const copy = {
    onTime: body.desiredOnTime * 60,
    operationWindowSize: body.desiredOperationWindowSize,
    operationWindowRuntimeMax: body.desiredOperationWindowRuntimeMax,
    power: body.desiredPower,
    safetyDelay: body.desiredSafetyDelay,
    dailyStartTime: body.desiredDailyStartTime,
    dailyEndTime: body.desiredDailyEndTime,
    logLevel: body.logLevel,
    logCategory: body.logCategory,
  }

  if (body.mode === MODE_VALUES.automatic) {
    delete copy.dailyStartTime
    delete copy.dailyEndTime
  }

  if (body.mode === MODE_VALUES.schedule) {
    const convertedDays = map(body.desiredDays, (day) => +day)
    copy.keepLedAlways = body.desiredKeepLedAlways ? 1 : 0

    copy.days = join(convertedDays, ',')
  }

  copy.operationWindowSize = isNil(copy.operationWindowSize)
    ? isNil(copy.operationWindowRuntimeMax)
      ? null
      : copy.operationWindowRuntimeMax
    : Number(copy.operationWindowSize)
  copy.operationWindowRuntimeMax = isNil(copy.operationWindowRuntimeMax)
    ? null
    : Number(copy.operationWindowRuntimeMax)

  return omitBy(copy, isNil)
}

const prepareValueForDeviceDetails = (name, value) => {
  const isTimeField =
    name === timeFields.dailyStartTime || name === timeFields.dailyEndTime

  // take only hours and mins if there is seconds too. 'hh:mm' - 5 symbols
  if (isTimeField && value?.length > 5) {
    return value.substr(0, 5)
  }

  if (value && name === 'onTime') {
    return value / 60
  }

  if (name === 'days' && value) {
    const numbersArray = value.split(',')

    const days = fill(new Array(7), false)

    forEach(numbersArray, (item, index) => {
      days[index] = !!+item
    })

    return days
  }

  return value
}

// function which takes current ot desired value from backend response in depending on data
const prepareDeviceDetails = (device) => {
  const deviceDetails = {
    ...device,
    days: prepareValueForDeviceDetails('days', device.days),
    desiredDays: prepareValueForDeviceDetails('days', device.desiredDays),
    dailyStartTime: prepareValueForDeviceDetails(
      'dailyStartTime',
      device.dailyStartTime
    ),
    desiredDailyStartTime: prepareValueForDeviceDetails(
      'dailyStartTime',
      device.desiredDailyStartTime
    ),
    dailyEndTime: prepareValueForDeviceDetails(
      'dailyEndTime',
      device.dailyEndTime
    ),
    desiredDailyEndTime: prepareValueForDeviceDetails(
      'dailyEndTime',
      device.desiredDailyEndTime
    ),
    onTime: prepareValueForDeviceDetails('onTime', device.onTime),
    desiredOnTime: prepareValueForDeviceDetails('onTime', device.desiredOnTime),
    keepLedAlways: !!device.desiredKeepLedAlways,
  }

  if (deviceDetails.dailyStartTime && deviceDetails.dailyEndTime) {
    deviceDetails.mode = MODE_VALUES.schedule

    if (!deviceDetails.desiredDays) {
      deviceDetails.desiredDays = fill(new Array(7), false)
    }
    if (!deviceDetails.days) {
      deviceDetails.days = fill(new Array(7), false)
    }
  } else {
    deviceDetails.mode = MODE_VALUES.automatic
  }

  return deviceDetails
}

const checkValidation = (
  state,
  setErrorsMessage,
  isNotVive,
  supportsOperationWindows
) => {
  setErrorsMessage('')
  const formErrors = {}
  const fields = isNotVive ? requiredFields : viveRequiredFields

  forEach(fields, (value) => {
    formErrors[value] = !state[value]
  })

  const {
    desiredPower,
    desiredSafetyDelay,
    desiredOnTime,
    desiredDailyStartTime,
    desiredDailyEndTime,
    desiredOperationWindowRuntimeMax,
    desiredOperationWindowSize,
  } = state
  const dailyStartTimeLength = desiredDailyStartTime?.length
  const dailyEndTimeLength = desiredDailyEndTime?.length

  if (state.mode === MODE_VALUES.schedule) {
    formErrors.desiredDailyStartTime =
      !desiredDailyStartTime || dailyStartTimeLength !== 5
    formErrors.desiredDailyEndTime =
      !desiredDailyEndTime || dailyEndTimeLength !== 5
    formErrors.desiredDays = !state.desiredDays || !some(state.desiredDays)

    if (formErrors.desiredDays) {
      setErrorsMessage(daysError)
    }

    const formHasDaysAndTime =
      desiredDailyStartTime && desiredDailyEndTime && !formErrors.desiredDays

    if (
      formHasDaysAndTime &&
      dailyStartTimeLength === 5 &&
      dailyEndTimeLength === 5
    ) {
      const start = moment(desiredDailyStartTime, 'hh:mm')
      let end = moment(desiredDailyEndTime, 'hh:mm')

      if (!start.isValid() || !end.isValid()) {
        formErrors.desiredDailyStartTime = !start.isValid()
        formErrors.desiredDailyEndTime = !end.isValid()

        setErrorsMessage(invalidDate)
      }
    }
  }

  if (
    isNotVive &&
    (desiredPower < MIN_POWER_VALUE || desiredPower > MAX_POWER_VALUE)
  ) {
    formErrors.desiredPower = true

    setErrorsMessage(
      rangeError(DEVICE_SETTINGS.power, MAX_POWER_VALUE, MIN_POWER_VALUE)
    )
  }

  if (
    desiredOnTime &&
    (desiredOnTime < MIN_SAFETY_DELAY_VALUE ||
      desiredOnTime > MAX_ON_TIME_VALUE)
  ) {
    formErrors.desiredOnTime = true

    setErrorsMessage(
      rangeError(DEVICE_SETTINGS.onTime, MAX_ON_TIME_VALUE, MIN_ON_TIME_VALUE)
    )
  }

  if (
    isNotVive &&
    desiredSafetyDelay &&
    (desiredSafetyDelay < 1 || desiredSafetyDelay > MAX_SAFETY_DELAY_VALUE)
  ) {
    formErrors.desiredSafetyDelay = true

    setErrorsMessage(
      rangeError(
        DEVICE_SETTINGS.safetyDelay,
        MAX_SAFETY_DELAY_VALUE,
        MIN_SAFETY_DELAY_VALUE
      )
    )
  }

  if (
    !isNil(desiredOperationWindowRuntimeMax) &&
    !isNil(desiredOperationWindowSize) &&
    supportsOperationWindows
  ) {
    if (
      parseInt(desiredOperationWindowRuntimeMax) >
      parseInt(desiredOperationWindowSize)
    ) {
      formErrors.desiredOperationWindowRuntimeMax = true

      setErrorsMessage(
        lessThanError(
          DEVICE_SETTINGS.operationWindowRuntimeMax,
          DEVICE_SETTINGS.operationWindowSize
        )
      )
    }

    if (parseInt(desiredOperationWindowRuntimeMax) === 0) {
      formErrors.desiredOperationWindowRuntimeMax = true

      setErrorsMessage(
        greaterThanZeroError(DEVICE_SETTINGS.operationWindowRuntimeMax)
      )
    }
  }

  if (!supportsOperationWindows && !isNil(desiredOperationWindowRuntimeMax)) {
    if (
      parseInt(desiredOperationWindowRuntimeMax) > OPERATION_WINDOW_SIZE_MAX
    ) {
      formErrors.desiredOperationWindowRuntimeMax = true

      setErrorsMessage(
        lessThanError(
          DEVICE_SETTINGS.dailyMaxRuntime,
          OPERATION_WINDOW_SIZE_MAX
        )
      )
    }
  }

  if (!isNil(desiredOperationWindowSize) && supportsOperationWindows) {
    if (parseInt(desiredOperationWindowSize) > OPERATION_WINDOW_SIZE_MAX) {
      formErrors.desiredOperationWindowSize = true

      setErrorsMessage(
        lessThanError(
          DEVICE_SETTINGS.operationWindowSize,
          OPERATION_WINDOW_SIZE_MAX
        )
      )
    }
  }

  return formErrors
}

const getUpdatedDeviceDetails = (updateOnlyCleaningStatus, data) => {
  if (updateOnlyCleaningStatus) {
    return {
      updateOnlyCleaningStatus: false,
    }
  } else {
    const {
      desiredTransactionTimeout,
      currentTransactionTimeout,
      outputPower,
    } = data
    const deviceDetails = prepareDeviceDetails(data)

    return {
      deviceDetails,
      outputPower,
      desiredTransactionTimeout: moment
        .duration(desiredTransactionTimeout)
        .asSeconds(),
      currentTransactionTimeout: moment
        .duration(currentTransactionTimeout)
        .asSeconds(),
    }
  }
}

const extractRSSIs = (data) => {
  if (isEmpty(data)) {
    return [
      {
        name: 'RSSI',
        value: DEVICE_DETAILS.noInformation,
        measurementUnit: '',
      },
    ]
  }

  return map(data, (item) => ({
    name: item.communicationName,
    value: item.value,
    measurementUnit: item.measurementUnit,
  }))
}

export {
  prepareHistoryDeviceDetails,
  checkExtendedFormErrors,
  rangeError,
  prepareBodyForUpdateSettings,
  prepareValueForDeviceDetails,
  prepareDeviceDetails,
  checkValidation,
  getUpdatedDeviceDetails,
  extractRSSIs,
}
