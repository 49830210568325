import { withStyles } from '@material-ui/core/styles'
import styles from '../../style'

const EmulatorCustomTimeInput = (props) => {
  const { date, name, changeDateValue, classes } = props

  const handleChangeTime = (date, time = '') => {
    const [hh, mm, ss] = time.split(':')

    const targetDate = date
    targetDate.setHours(Number(hh), Number(mm), Number(ss))

    changeDateValue(targetDate, name)
  }

  return (
    <input
      type="time"
      step="1"
      value={date.toTimeString().split(' ')[0]}
      onChange={(e) => handleChangeTime(date, e.target.value)}
      className={classes.customTimeInput}
    />
  )
}

export default withStyles(styles)(EmulatorCustomTimeInput)
