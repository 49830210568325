const allowList = {}

allowList.actionTypes = {
  GET_ALLOW_LIST: '{ALLOW LIST} Get Allow List',
  GET_ALLOW_LIST_SUCCEEDED: '{ALLOW LIST} Get Allow List Succeeded',
  GET_ALLOW_LIST_FAILED: '{ALLOW LIST} Get Allow List Failed',

  ALLOW_LIST_REMOVE_DEVICE: '{ALLOW LIST} Remove Device From Allow List',
  ALLOW_LIST_REMOVE_DEVICE_SUCCEEDED:
    '{ALLOW LIST} Remove Device From Allow List Succeeded',
  ALLOW_LIST_REMOVE_DEVICE_FAILED:
    '{ALLOW LIST} Remove Device From Allow List Failed',

  ALLOW_LIST_ADD_DEVICE: '{ALLOW LIST} Add Device To Allow List',
  ALLOW_LIST_ADD_DEVICE_SUCCEEDED:
    '{ALLOW LIST} Add Device To Allow List Succeeded',
  ALLOW_LIST_ADD_DEVICE_FAILED: '{ALLOW LIST} Add Device To Allow List Failed',

  ALLOW_LIST_BULK_ADD_DEVICES: '{ALLOW LIST} Add Bulk Devices To Allow List',
  ALLOW_LIST_BULK_ADD_DEVICES_SUCCEEDED:
    '{ALLOW LIST} Add Bulk Devices To Allow List Succeeded',
  ALLOW_LIST_BULK_ADD_DEVICES_FAILED:
    '{ALLOW LIST} Add Bulk Devices To Allow List Failed',

  ALLOW_LIST_CLEAR_BULK_ADD_DEVICES_CLEAR_ERROR:
    '{ALLOW LIST} Add Bulk Devices To Allow List Clear Error',
  ALLOW_LIST_CLEAR_BULK_ADD_DEVICES_CLEAR_ERROR_SUCCESS:
    '{ALLOW LIST} Add Bulk Devices To Allow List Clear Error Succeeded',

  TOGGLE_BULK_ADD_MODAL: '{ALLOW LIST} Toggle Bulk Add Modal',
  TOGGLE_BULK_ADD_MODAL_SUCCESS: '{ALLOW LIST} Toggle Bulk Add Modal Success',

  SEARCH_AVAILABLE_DEVICE: '{ALLOW LIST} Search Available Allow List Devices',
  SEARCH_AVAILABLE_DEVICE_SUCCEEDED:
    '{ALLOW LIST} Search Available Allow List Devices Succeeded',
  SEARCH_AVAILABLE_DEVICE_FAILED:
    '{ALLOW LIST} Search Available Allow List Devices Failed',
}

allowList.getAllowList = (deviceId) => {
  return {
    type: allowList.actionTypes.GET_ALLOW_LIST,
    payload: {
      deviceId,
    },
  }
}

allowList.getAllowListSuccess = (data) => {
  return {
    type: allowList.actionTypes.GET_ALLOW_LIST_SUCCEEDED,
    payload: {
      data,
    },
  }
}

allowList.getAllowListFailed = (message) => {
  return {
    type: allowList.actionTypes.GET_ALLOW_LIST_FAILED,
    payload: {
      message,
    },
  }
}

allowList.allowListRemoveDevice = (hubId, deviceId) => {
  return {
    type: allowList.actionTypes.ALLOW_LIST_REMOVE_DEVICE,
    payload: {
      hubId,
      deviceId,
    },
  }
}

allowList.allowListRemoveDeviceSuccess = (data) => {
  return {
    type: allowList.actionTypes.ALLOW_LIST_REMOVE_DEVICE_SUCCEEDED,
    payload: {
      data,
    },
  }
}

allowList.allowListRemoveDeviceFailed = (message) => {
  return {
    type: allowList.actionTypes.ALLOW_LIST_REMOVE_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

allowList.allowListAddDevice = (hubId, deviceId, callback) => {
  return {
    type: allowList.actionTypes.ALLOW_LIST_ADD_DEVICE,
    payload: {
      callback,
      hubId,
      deviceId,
    },
  }
}

allowList.allowListAddDeviceSuccess = (data) => {
  return {
    type: allowList.actionTypes.ALLOW_LIST_ADD_DEVICE_SUCCEEDED,
    payload: {
      data,
    },
  }
}

allowList.allowListAddDeviceFailed = (message) => {
  return {
    type: allowList.actionTypes.ALLOW_LIST_ADD_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

allowList.allowListBulkAddDevices = (hubId, serialNumbers, callback) => {
  return {
    type: allowList.actionTypes.ALLOW_LIST_BULK_ADD_DEVICES,
    payload: {
      callback,
      hubId,
      serialNumbers,
    },
  }
}

allowList.allowListBulkAddDevicesSuccess = (data) => {
  return {
    type: allowList.actionTypes.ALLOW_LIST_BULK_ADD_DEVICES_SUCCEEDED,
    payload: {
      data,
    },
  }
}

allowList.allowListBulkAddDevicesFailed = (message) => {
  return {
    type: allowList.actionTypes.ALLOW_LIST_BULK_ADD_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

allowList.toggleBulkAddModal = () => {
  return {
    type: allowList.actionTypes.TOGGLE_BULK_ADD_MODAL,
    payload: {},
  }
}

allowList.toggleBulkAddModalSuccess = () => {
  return {
    type: allowList.actionTypes.TOGGLE_BULK_ADD_MODAL_SUCCESS,
    payload: {},
  }
}

allowList.allowListBulkAddClearError = () => {
  return {
    type: allowList.actionTypes.ALLOW_LIST_CLEAR_BULK_ADD_DEVICES_CLEAR_ERROR,
    payload: {},
  }
}

allowList.allowListBulkAddClearErrorSuccess = () => {
  return {
    type: allowList.actionTypes
      .ALLOW_LIST_CLEAR_BULK_ADD_DEVICES_CLEAR_ERROR_SUCCESS,
    payload: {},
  }
}

allowList.searchAvailableDeviceList = (deviceId, query) => {
  return {
    type: allowList.actionTypes.SEARCH_AVAILABLE_DEVICE,
    payload: {
      deviceId,
      query,
    },
  }
}

allowList.searchAvailableDeviceListSuccess = (data) => {
  return {
    type: allowList.actionTypes.SEARCH_AVAILABLE_DEVICE_SUCCEEDED,
    payload: {
      data,
    },
  }
}

allowList.searchAvailableDeviceListFailed = (message) => {
  return {
    type: allowList.actionTypes.SEARCH_AVAILABLE_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

export default allowList
