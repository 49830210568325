export const permissionsList: { value: string; label: string }[] = [
  { value: 'UVGI_POWER_MANAGEMENT', label: 'UVGI Power Management' },
  { value: 'READ_ONLY', label: 'Read Only' },
  { value: 'LIMITED', label: 'Limited' },
  { value: 'POWER_USER', label: 'Power User' },
  { value: 'SUPER_ADMIN', label: 'Super Admin' },
]

export const allowed_user_roles = [
  { label: 'Account Owner', value: 'ACCOUNT_OWNER_ROLE' },
  { label: 'Admin User', value: 'ADMIN_USER_ROLE' },
  { label: 'Admin User Billing', value: 'ADMIN_USER_BILLING_ROLE' },
  { label: 'Operator User', value: 'OPERATOR_USER_ROLE' },
  { label: 'Viewer User', value: 'VIEWER_USER_ROLE' },
]

export const formatPhoneNumber = (phone: string) => {
  const cleaned = ('' + phone).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d+)$/)

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}
