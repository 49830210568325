import { createRef, useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { DEVICE_TYPE } from '../../../../../services/constants'
import rootActions from '../../../../../services/root/root-actions'
import { checkConnection } from '../../../../../services/root/root-service'
import sharedDevicesActions from '../../../services/devices-actions'
import { ADD_DEVICE_LABELS } from '../../Details/services/deviceDetails-constants'
import { IMEI_LABELS } from '../services/devicesList-constants'
import { getInfoSerialNumber } from '../services/devicesList-service'
import DeviceTypeSelect from './DeviceTypeSelect'
import { useStyles } from './style'

const ImeiCheckingButton = (props) => {
  const {
    error,
    checkImei,
    deviceByImei,
    resetErrorAndDevice,
    devicesTypes,
    loadDevicesTypes,
  } = props

  const style = useStyles()

  const inputRef = createRef()

  const [open, setOpen] = useState(false)
  const [deviceType, setDeviceType] = useState(DEVICE_TYPE.UVI_ARC.type)
  const [isEmptyTextField, setIsEmptyTextField] = useState(false)

  const updatedDeviceTypes = filter(
    devicesTypes,
    (type) => type.value !== DEVICE_TYPE.EXT_DEVICE.type
  )

  useEffect(() => {
    if (isEmpty(devicesTypes)) {
      loadDevicesTypes()
    }
    // eslint-disable-next-line
  }, [])

  const handleClickOpen = () => {
    resetErrorAndDevice()
    setOpen(true)
  }

  const handleClose = () => {
    setIsEmptyTextField(false)

    setOpen(false)
  }

  const onApply = (event) => {
    event.preventDefault()

    const value = inputRef.current.value.trim()

    if (value === '') {
      setIsEmptyTextField(true)
    } else {
      setIsEmptyTextField(false)

      checkImei(value, deviceType)
    }
  }

  return (
    <>
      <div className={style.actionButtonContainer}>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          {IMEI_LABELS.action}
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={style.dialogTitle} disableTypography>
          <Typography variant="h4">{IMEI_LABELS.title}</Typography>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          {deviceByImei && getInfoSerialNumber(deviceByImei) && (
            <Typography variant="subtitle1">
              {deviceByImei && getInfoSerialNumber(deviceByImei)}
            </Typography>
          )}
          <IconButton
            className={style.closeIcon}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={style.dialogContent}>
          <DeviceTypeSelect
            deviceType={deviceType}
            setDeviceType={setDeviceType}
            devicesTypes={updatedDeviceTypes}
          />
          <form onSubmit={onApply} noValidate>
            <TextField
              name="imeiBarcode"
              variant="outlined"
              label={ADD_DEVICE_LABELS.imeiBarcode}
              inputRef={inputRef}
              error={isEmptyTextField}
              placeholder={IMEI_LABELS.placeholder}
              fullWidth
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={style.submitButton}
            >
              {IMEI_LABELS.action}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  checkImei: (imei, type) =>
    dispatch(checkConnection(() => sharedDevicesActions.checkImei(imei, type))),
  loadDevicesTypes: () =>
    dispatch(checkConnection(rootActions.loadDevicesTypes)),
  resetErrorAndDevice: () =>
    dispatch(sharedDevicesActions.resetErrorAndDevice()),
})

const mapStateToProps = (state) => {
  const { sharedDevicesReducer } = state.devicesReducer

  return {
    deviceByImei: sharedDevicesReducer.deviceByIMEI,
    error: sharedDevicesReducer.error,
    devicesTypes: state.rootReducer.devicesTypes,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImeiCheckingButton)
