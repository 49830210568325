import { combineReducers } from 'redux'
import { authorizationReducer } from '../containers/Authorization/services/authorization-reducer'
import { dashboardReducer } from '../containers/Dashboard/services/dashboard-reducer'
import { devicesReducer } from '../containers/Devices/services/reducers'
import { emulatorReducer } from '../containers/Emulator/services/emulator-reducers'
import { eventsEmulatorReducer } from '../containers/EventsEmulator/_shared/services/reducers'
import { failedDevicesReducer } from '../containers/FailedDevices/services/failedDevices-reducer'
import { featureTogglingReducer } from '../containers/FeatureToggling/services/featureToggling-reducer'
import { firmwaresReducer } from '../containers/Firmwares/services/firmwares-reducers'
import { firmwareUpdateReducer } from '../containers/FirmwareUpdateSchedule/services/firmwareUpdate-reducer'
import { customTreeReducer } from '../containers/Organizations/components/Details/components/Locations/customTree/services/customTree-reducer'
import { organizationDetailsReducer } from '../containers/Organizations/components/Details/services/organizationDetails-reducer'
import { organizationReducer } from '../containers/Organizations/services/organization-reducer'
import { usersReducer } from '../containers/Users/services/users-reducer'
import { rootReducer } from '../services/root/root-reducer'

export default combineReducers({
  rootReducer,
  organizationReducer,
  firmwaresReducer,
  devicesReducer,
  failedDevicesReducer,
  firmwareUpdateReducer,
  usersReducer,
  featureTogglingReducer,
  authorizationReducer,
  emulatorReducer,
  eventsEmulatorReducer,
  dashboardReducer,
  organizationDetailsReducer,
  customTreeReducer,
})
