import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { useAppSelector } from 'utils/reduxHelpers'
import CloseButton from '../../../../../../../components/_shared/closeButton/CloseButton'
import Loader from '../../../../../../../components/_shared/loader/Loader'
import DeleteContent from './DeleteContent'
import { useStyles } from './style'

type DeleteModalWithInputProps = {
  id: string
  parentId: string
  name: string
  handleClose: () => void
  title: string
  isOpen: boolean
}

const DeleteModalWithInput = ({
  id,
  parentId,
  name,
  handleClose,
  title,
  isOpen,
}: DeleteModalWithInputProps) => {
  const styles = useStyles()

  const isLoadingSubLocations = useAppSelector(
    (state) => state.organizationDetailsReducer.isLoadingSubLocations
  )

  return (
    <Dialog open={isOpen} keepMounted onClose={handleClose} maxWidth="xs">
      <DialogTitle className={styles.modalTitleContainer} disableTypography>
        <Typography
          variant="h3"
          color="secondary"
          className={styles.modalTitle}
        >
          {title}
        </Typography>
        <CloseButton onCloseModal={handleClose} />
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Loader isLoading={isLoadingSubLocations} loaderKey={id} inModal>
          <DeleteContent
            id={id}
            parentId={parentId}
            isOpen={isOpen}
            name={name}
            handleClose={handleClose}
          />
        </Loader>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteModalWithInput
