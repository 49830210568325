import {
  defaultFilterState,
  DEVICES_FILTER_LABELS,
} from '../../services/devicesList-constants'

import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import snakeCase from 'lodash/snakeCase'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { DEVICE_TYPE } from '../../../../../../services/constants'
import { encodeString } from '../../../../../../services/root/root-service'
import actions from '../../../../services/devices-actions'
import devicesListActions from '../../services/devicesList-actions'
import { prepareFilterQuery } from '../../services/devicesList-service'
import { useStyles } from '../style'
import ExtTypesSelect from './ExtTypesSelect'

const DevicesFilterComponent = (props) => {
  const {
    filterState,
    setFilterState,
    loadDevices,
    deviceType,
    applyFilter,
    setTotalsByTypeReceived,
    loadDataFromReduxStore,
    loadDataFromReduxStoreFlag,
  } = props

  const style = useStyles()

  useEffect(() => {
    filterDevices(filterState)
    // eslint-disable-next-line
  }, [deviceType])

  const filterDevices = (filterFields) => {
    const organizationName = encodeString(filterFields.organizationName.trim())
    const query = prepareFilterQuery({
      ...filterFields,
      deviceType: snakeCase(deviceType).toUpperCase(),
      organizationName,
    })

    if (!loadDataFromReduxStoreFlag) {
      applyFilter()
      loadDevices(query)
    } else {
      loadDataFromReduxStore(false)
    }
  }

  const handleFilterStateChange = (event) => {
    const value = event.target.value
    setFilterState({
      ...filterState,
      [event.target.name]:
        event.target.name === 'organizationName' ? value : value.trim(),
    })
  }

  const onApply = (event) => {
    event.preventDefault()

    setTotalsByTypeReceived(false)
    filterDevices(filterState)
  }

  const resetFilters = () => {
    setFilterState(defaultFilterState)
    setTotalsByTypeReceived(false)

    const query = prepareFilterQuery({
      ...defaultFilterState,
      deviceType,
    })

    applyFilter()
    loadDevices(query)
  }

  return (
    <>
      <form className={style.filterWrapper} onSubmit={onApply}>
        <TextField
          name="serialNumber"
          className={style.filterField}
          label={DEVICES_FILTER_LABELS.serialNumber}
          value={filterState.serialNumber}
          onChange={handleFilterStateChange}
          variant="outlined"
        />
        <TextField
          name="organizationName"
          className={style.filterField}
          label={DEVICES_FILTER_LABELS.organization}
          value={filterState.organizationName}
          onChange={handleFilterStateChange}
          variant="outlined"
        />
        {deviceType === DEVICE_TYPE.NETWORK_DEVICE.type ? (
          <TextField
            name="macAddress"
            className={style.filterField}
            label={DEVICES_FILTER_LABELS.macAddress}
            value={filterState.macAddress}
            onChange={handleFilterStateChange}
            variant="outlined"
          />
        ) : (
          <TextField
            name="imei"
            className={style.filterField}
            label={DEVICES_FILTER_LABELS.imei}
            value={filterState.imei}
            onChange={handleFilterStateChange}
            variant="outlined"
          />
        )}
        {deviceType === DEVICE_TYPE.EXT_DEVICE.type && (
          <ExtTypesSelect
            filterState={filterState}
            setFilterState={setFilterState}
          />
        )}
        <div className={style.filterButtonsWrapper}>
          <Button
            type="submit"
            id="apply-filter"
            variant="contained"
            className={style.submitButton}
            color="primary"
          >
            {DEVICES_FILTER_LABELS.apply}
          </Button>
          <Button
            id="reset-filter"
            variant="contained"
            className={style.submitButton}
            color="primary"
            onClick={resetFilters}
          >
            {DEVICES_FILTER_LABELS.resetFilter}
          </Button>
        </div>
      </form>
    </>
  )
}

const mapStateToProps = (state) => {
  const { devicesListReducer, sharedDevicesReducer } = state.devicesReducer

  return {
    filterState: devicesListReducer.filterState,
    loadDataFromReduxStoreFlag: sharedDevicesReducer.loadDataFromReduxStoreFlag,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setFilterState: (filter) =>
    dispatch(devicesListActions.setFilterState(filter)),
  applyFilter: () => dispatch(devicesListActions.applyDeviceFilter()),
  setTotalsByTypeReceived: (status) =>
    dispatch(devicesListActions.setTotalsByTypeReceived(status)),
  loadDataFromReduxStore: (status) =>
    dispatch(actions.loadDataFromReduxStore(status)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesFilterComponent)
