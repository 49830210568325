import { Device } from 'containers/Devices/services/devices-types'
import { createAction } from 'redux-act'
import { Location } from 'types/locationTypes'

export const NET_LOAD_MESSAGE_DEVICES_INIT = createAction(
  'NET_LOAD_MESSAGE_DEVICES_INIT'
)
export const NET_LOAD_MESSAGE_DEVICES_SUCCESS = createAction<Device[]>(
  'NET_LOAD_MESSAGE_DEVICES_SUCCESS'
)
export const NET_LOAD_MESSAGE_DEVICES_ERROR = createAction<any>(
  'NET_LOAD_MESSAGE_DEVICES_ERROR'
)

export const NET_SEND_DEVICE_MESSAGE_INIT = createAction<{
  params: string
  body: any
}>('NET_SEND_DEVICE_MESSAGE_INIT')
export const NET_SEND_DEVICE_MESSAGE_SUCCESS = createAction<any>(
  'NET_SEND_DEVICE_MESSAGE_SUCCESS'
)
export const NET_SEND_DEVICE_MESSAGE_ERROR = createAction<string>(
  'NET_SEND_DEVICE_MESSAGE_ERROR'
)

export const NET_LOAD_ALL_LOCATIONS_INIT = createAction(
  'NET_LOAD_ALL_LOCATIONS_INIT'
)
export const NET_LOAD_ALL_LOCATIONS_SUCCESS = createAction<Location[]>(
  'NET_LOAD_ALL_LOCATIONS_SUCCESS'
)
export const NET_LOAD_ALL_LOCATIONS_ERROR = createAction<any>(
  'NET_LOAD_ALL_LOCATIONS_ERROR'
)
