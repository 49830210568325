import TextField from '@material-ui/core/TextField'
import React from 'react'

type InputForTimeProps = {
  name: string
  error?: boolean
  value: string
  setChanged: (value: { target: { name: string; value: string } }) => void
  label: string
}

const InputForTime = ({
  name,
  error,
  value,
  setChanged,
  label,
}: InputForTimeProps) => {
  // analogue of onChange which we already have for phone numbers on PhoneWithExtension.js
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target
    const lengthOfTheString = value.length
    // remove : from time to check value
    const current = value.replace(/:/gm, '')

    if (
      isNaN(Number.parseInt(event.target.value[lengthOfTheString - 1])) ||
      current.length > 4
    ) {
      const updatedValue = value.substring(0, lengthOfTheString - 1)

      setChanged({ target: { name, value: updatedValue } })
    } else {
      if (current.length <= 2) {
        setChanged({ target: { name, value: current } })
      } else {
        const updatedValue = `${current.substring(0, 2)}:${current.substring(
          2,
          5
        )}`

        setChanged({ target: { name, value: updatedValue } })
      }
    }
  }

  return (
    <TextField
      variant="outlined"
      name={name}
      value={value}
      error={error}
      label={label}
      inputProps={{ min: '0', maxLength: '5' }}
      onChange={onChange}
    />
  )
}

export default InputForTime
