import {
  BulkUploadLocationsResponse,
  Level,
  Location,
  OrganizationDetails,
  PutWifiCredential,
  UploadLocation,
  WifiCredential,
  WifiValidationErrors,
} from './organizationDetails-types'

import { Feature } from 'containers/Organizations/services/organization-types'
import { createAction } from 'redux-act'

export const UPDATE_STATUS_START = createAction(
  '{Organization Details} Update Status Start',
  (id: string, status: boolean) => ({
    id,
    status,
  })
)
export const UPDATE_STATUS_SUCCEEDED = createAction(
  '{Organization Details} Update Status Succeeded'
)
export const UPDATE_STATUS_FAILED = createAction(
  '{Organization Details} Update Status Failed'
)
export const UPDATE_SF_ID_START = createAction(
  '{Organization Details} Update Salesforce ID Start',
  (id: string, salesForceId: string) => ({
    id,
    salesForceId,
  })
)
export const UPDATE_SF_ID_SUCCEEDED = createAction(
  '{Organization Details} Update Salesforce ID Succeeded'
)
export const UPDATE_SF_ID_FAILED = createAction(
  '{Organization Details} Update Salesforce ID Failed'
)
export const UPDATE_TYPE_START = createAction(
  '{Organization Details} Update Type Start',
  (id: string, type: string) => ({
    id,
    type,
  })
)
export const UPDATE_TYPE_SUCCEEDED = createAction(
  '{Organization Details} Update Type Succeeded'
)
export const UPDATE_TYPE_FAILED = createAction(
  '{Organization Details} Update Type Failed'
)

export const DOWNLOAD_WIFI_CREDENTIALS = createAction(
  '{Organization Details} Download WIFI Credentials',
  (id: string, filename: string) => ({ id, filename })
)

export const GET_ORGANIZATION = createAction(
  '{Organization Details} Get Organization',
  (id: string) => ({ id })
)

export const GET_ORGANIZATION_SUCCEEDED = createAction(
  '{Organization Details} Get Organization Succeeded',
  (data: OrganizationDetails) => ({ data })
)

export const GET_ORGANIZATION_FAILED = createAction(
  '{Organization Details} Get Organization Failed',
  (message: string) => ({ message })
)

export const GET_FEATURES = createAction(
  '{Organization Details} Get Features',
  (id: string) => ({ id })
)

export const GET_FEATURES_SUCCEEDED = createAction(
  '{Organization Details} Get Features Succeeded',
  (data: Feature[]) => ({ data })
)

export const GET_FEATURES_FAILED = createAction(
  '{Organization Details} Get Features Failed',
  (message: string) => ({ message })
)

export const CHANGE_FEATURE_FOR_ORGANIZATION = createAction(
  '{Organization Details} Change Feature For Organization',
  (id: string, code: string, checked: boolean) => ({ checked, code, id })
)

export const CHANGE_FEATURE_FOR_ORGANIZATION_SUCCEEDED = createAction(
  '{Organization Details} Change Feature For Organization Succeeded',
  (id: string, switchState: boolean) => ({
    id,
    switchState,
  })
)

export const CHANGE_FEATURE_FOR_ORGANIZATION_FAILED = createAction(
  '{Organization Details} Change Feature For Organization Failed',
  (message: string) => ({ message })
)

export const GET_WIFI_CREDENTIALS = createAction(
  '{Organization Details} Get Wifi Credentials',
  (organizationId: string) => ({
    organizationId,
  })
)

export const GET_WIFI_CREDENTIALS_SUCCEEDED = createAction(
  '{Organization Details} Get Wifi Credentials Succeeded',
  (wifiCredentialsList: WifiCredential[]) => ({
    wifiCredentialsList,
  })
)

export const GET_WIFI_CREDENTIALS_FAILED = createAction(
  '{Organization Details} Get Wifi Credentials Failed',
  (message: string) => ({ message })
)

export const PUT_WIFI_CREDENTIAL = createAction(
  '{Organization Details} Put Wifi Credential',
  (body: PutWifiCredential, onSuccess: () => void) => ({ body, onSuccess })
)

export const PUT_WIFI_CREDENTIAL_SUCCEEDED = createAction(
  '{Organization Details} Put Wifi Credential Succeeded',
  (data: WifiCredential) => ({ data })
)

export const PUT_WIFI_CREDENTIAL_FAILED = createAction(
  '{Organization Details} Put Wifi Credential Failed',
  (errors: WifiValidationErrors) => ({ errors })
)

export const DELETE_WIFI_CREDENTIAL = createAction(
  '{Organization Details} Delete Wifi Credential',
  (id: string) => ({ id })
)

export const DELETE_WIFI_CREDENTIAL_SUCCEEDED = createAction(
  '{Organization Details} Delete Wifi Credential Succeeded',
  (credentialId: string) => ({ credentialId })
)

export const DELETE_WIFI_CREDENTIAL_FAILED = createAction(
  '{Organization Details} Delete Wifi Credential Failed',
  (message: string) => ({ message })
)

export const RESET_VALIDATION_ERRORS = createAction(
  '{Organization Details} Reset Validation Errors'
)

export const GET_LOCATIONS_LIST = createAction(
  '{Organization Details} Get Locations List',
  (parentId: string | null, organizationName: string | undefined) => ({
    organizationName,
    parentId,
  })
)

export const GET_LOCATIONS_LIST_SUCCEEDED = createAction(
  '{Organization Details} Get Locations List Succeeded',
  (list: Location[]) => ({
    list,
  })
)

export const GET_LOCATIONS_LIST_FAILED = createAction(
  '{Organization Details} Get Locations List Failed',
  (message: string) => ({ message })
)

export const GET_LOCATION_SCHEMA = createAction(
  '{Organization Details} Get Location Schema',
  (id: string) => ({ id })
)

export const GET_LOCATION_SCHEMA_SUCCEEDED = createAction(
  '{Organization Details} Get Location Schema Succeeded',
  (levels: Level[]) => ({ levels })
)

export const GET_LOCATION_SCHEMA_FAILED = createAction(
  '{Organization Details} Get Location Schema Failed',
  (message: string) => ({ message })
)

export const BULK_UPLOAD_LOCATIONS = createAction(
  '{Organization Details} Bulk Upload Locations',
  (body: any, onClose: () => void) => ({ body, onClose })
)

export const BULK_UPLOAD_LOCATIONS_SUCCEEDED = createAction(
  '{Organization Details} Bulk Upload Locations Succeeded',
  (response: BulkUploadLocationsResponse) => ({ response })
)

export const BULK_UPLOAD_LOCATIONS_FAILED = createAction(
  '{Organization Details} Bulk Upload Locations Failed',
  (message: string) => ({ message })
)

export const GET_BULK_TEMPLATE = createAction(
  '{Organization Details} Get Bulk Template'
)

export const GET_BULK_TEMPLATE_SUCCEEDED = createAction(
  '{Organization Details} Get Bulk Template Succeeded',
  (file: any) => ({ file })
)

export const GET_BULK_TEMPLATE_FAILED = createAction(
  '{Organization Details} Get Bulk Template Failed',
  (message: string) => ({ message })
)

export const ADD_LOCATION = createAction(
  '{Organization Details} Add Location',
  (body: Partial<Location>, onSuccess: () => void) => ({ body, onSuccess })
)

export const ADD_LOCATION_SUCCEEDED = createAction(
  '{Organization Details} Add Location Succeeded',
  (location: Location) => ({ location })
)

export const ADD_LOCATION_FAILED = createAction(
  '{Organization Details} Add Location Failed',
  (message: string) => ({ message })
)

export const ADD_LOCATIONS_BULK = createAction(
  '{Organization Details} Add Locations Bulk',
  (body: UploadLocation[], onSuccess: () => void) => ({ body, onSuccess })
)

export const ADD_LOCATIONS_BULK_SUCCEEDED = createAction(
  '{Organization Details} Add Locations Bulk Succeeded',
  (response: Location[]) => ({ response })
)

export const ADD_LOCATIONS_BULK_FAILED = createAction(
  '{Organization Details} Add Locations Bulk Failed',
  (message: string) => ({ message })
)

export const SET_ERROR = createAction(
  '{Organization Details} Set Error',
  (error: string) => ({ error })
)

export const RESET_ERROR = createAction('{Organization Details} Reset Error')

export const GET_SUBLOCATIONS_COUNT = createAction(
  '{Organization Details} Get Sub Locations Count',
  (id: string) => ({ id })
)

export const GET_SUBLOCATIONS_COUNT_SUCCESS = createAction(
  '{Organization Details} Get Sub Locations Count Scceeded',
  (sublocations: number) => ({ sublocations })
)

export const GET_SUBLOCATIONS_COUNT_FAILED = createAction(
  '{Organization Details} Get Sublocations Count Failed',
  (message: string) => ({ message })
)

export const DELETE_LOCATION_WITH_SUBLOCATIONS = createAction(
  '{Organization Details} Delete Location With Sub Locations',
  (id: string, parentId: string, count: number, handleClose: () => void) => ({
    id,
    parentId,
    count,
    handleClose,
  })
)

export const DELETE_LOCATION_WITH_SUBLOCATIONS_SUCCEEDED = createAction(
  '{Organization Details} Delete Location With Sub Locations Succeeded',
  (id: string, parentId: string) => ({
    id,
    parentId,
  })
)

export const DELETE_LOCATION_WITH_SUBLOCATIONS_FAILED = createAction(
  '{Organization Details} Delete Location With Sublocations Failed',
  (message: string) => ({ message })
)

export const UPDATE_LOCATION = createAction(
  '{Organization Details} Update Location',
  (location: Partial<Location>, onSuccess: () => void) => ({
    location,
    onSuccess,
  })
)

export const UPDATE_LOCATION_SUCCEEDED = createAction(
  '{Organization Details} Update Location Succeeded',
  (location: Location) => ({ location })
)

export const UPDATE_LOCATION_FAILED = createAction(
  '{Organization Details} Update Location Failed',
  (message: string) => ({ message })
)

export const UPDATE_LOCATION_TREE = createAction(
  '{Organization Details} Update Location Tree',
  (organizationId: string, locationSchemaId: number) => ({
    locationSchemaId,
    organizationId,
  })
)

export const UPDATE_LOCATION_TREE_SUCCESS = createAction(
  '{Organization Details} Update Location Tree Success',
  (locationSchemaId: number) => ({ locationSchemaId })
)

export const UPDATE_LOCATION_TREE_FAILED = createAction(
  '{Organization Details} Update Location Tree Failed',
  (message: string) => ({ message })
)
