import { GDPA_API_URL, HOST } from '../constants'

import { createAuthProvider } from './tokenAuth'

export const HttpClient = (function HttpClient() {
  const defaultHeaders = () => {
    return new Headers({
      'Content-Type': 'application/json',
      'X-Content-Type-Options': 'nosniff',
    })
  }

  const prepareBody = (body) => {
    if (!body) {
      return undefined
    }

    return typeof body === 'string' ? body : JSON.stringify(body)
  }

  const [tp, login, logout] = createAuthProvider({
    accessTokenKey: 'authToken',
    onUpdateToken: (token) => {
      return fetch(HOST + '/api/public/refresh-jwt', {
        method: 'POST',
        headers: defaultHeaders(),
        body: prepareBody({ refreshToken: token.refreshToken }),
      }).then((r) => r.json())
    },
  })

  let unauthorizedHandler
  let excludedUrls = []

  return {
    tp,
    login,
    logout,

    setUnauthorizedHandler(handler, urls = []) {
      unauthorizedHandler = handler
      excludedUrls = [...excludedUrls, ...urls]
    },

    async makeRequest(url, method, payloads = {}) {
      let {
        headers = defaultHeaders(),
        body,
        access_token,
        isFormData,
        isDownload,
        useGDPA = false,
        filename = 'example.csv',
      } = payloads
      const host = useGDPA ? GDPA_API_URL : HOST
      const token = await tp.getToken()

      if (access_token) {
        headers.append('X-Auth-Token', access_token)
      }

      if (token) {
        headers.append('Authorization', `Bearer ${token}`)
      }

      if (useGDPA) {
        headers.append('gdp-user-pool', `admin`)
      }

      return fetch(host + url, {
        method,
        headers,
        cache: 'no-cache',
        credentials: 'include',
        body: isFormData ? body : prepareBody(body),
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response)
          }

          const isUnauthorizedResponse =
            response.status === 401 || response.status === 403
          const isUnauthorizedHandlerSet =
            typeof unauthorizedHandler === 'function'
          const currentUrlIsNotExcluded = !excludedUrls.includes(url)

          if (
            isUnauthorizedResponse &&
            isUnauthorizedHandlerSet &&
            currentUrlIsNotExcluded
          ) {
            unauthorizedHandler()
          }

          return new Promise((_resolve, reject) => {
            response.text().then((text) => {
              try {
                text
                  ? reject(JSON.parse(text))
                  : reject(new Error(response.statusText))
              } catch (e) {
                reject(text)
              }
            })
          })
        })
        .then((response) => {
          if (isDownload) {
            return response.blob().then((text) => {
              const url = window.URL.createObjectURL(new Blob([text]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', filename)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)

              return text
            })
          } else {
            return response.text().then((text) => {
              try {
                return text ? JSON.parse(text) : {}
              } catch {
                return text
              }
            })
          }
        })
        .catch((error) => {
          console.log(error)

          return error
        })
    },
    get(url, payloads) {
      return this.makeRequest(url, 'GET', payloads)
    },
    post(url, payloads) {
      return this.makeRequest(url, 'POST', payloads)
    },
    put(url, payloads) {
      return this.makeRequest(url, 'PUT', payloads)
    },
    delete(url, payloads) {
      return this.makeRequest(url, 'DELETE', payloads)
    },
  }
})()
