import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { STATISTICS_MESSAGE_LABELS } from '../../ARC/services/arcEmulator-constants'
import styles from '../../style'
import { validateNumericFields } from '../services/emulator-service'
import EmulatorDatepicker from './EmulatorDatepicker'

const EmulatorStatisticsMessageComponent = (props) => {
  const {
    classes,
    isSubmitted,
    changeValue,
    changeDateValue,
    deviceRevision,
    softwareRevision,
    bulbCyclesSinceChange,
    powerCyclesSinceChange,
    abuseEvents,
    date,
  } = props
  const isBulbCyclesSinceChangeValid =
    isSubmitted && !validateNumericFields(bulbCyclesSinceChange)
  const isPowerCyclesSinceChangeValid =
    isSubmitted && !validateNumericFields(powerCyclesSinceChange)
  const isAbuseEventsValid = isSubmitted && !validateNumericFields(abuseEvents)

  const onChangeValue = (event) => {
    changeValue(event, event.target.name)
  }

  return (
    <div className={classes.firmWareUpdateContainer}>
      <Typography variant="subtitle2">
        {STATISTICS_MESSAGE_LABELS.bulbChangeDate}
      </Typography>
      <EmulatorDatepicker
        time={date}
        changeDateValue={changeDateValue}
        name={'bulbChangeDate'}
      />
      <TextField
        id="outlined-basic"
        value={deviceRevision}
        name="deviceRevision"
        onChange={onChangeValue}
        error={isSubmitted && !deviceRevision}
        required
        label={STATISTICS_MESSAGE_LABELS.deviceRevision}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        name="softwareRevision"
        value={softwareRevision}
        onChange={onChangeValue}
        error={isSubmitted && !softwareRevision}
        required
        label={STATISTICS_MESSAGE_LABELS.softwareRevision}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        name="bulbCyclesSinceChange"
        value={bulbCyclesSinceChange}
        onChange={onChangeValue}
        error={isBulbCyclesSinceChangeValid}
        required
        type="number"
        label={STATISTICS_MESSAGE_LABELS.bulbCyclesSinceChange}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        value={powerCyclesSinceChange}
        name="powerCyclesSinceChange"
        onChange={onChangeValue}
        error={isPowerCyclesSinceChangeValid}
        required
        type="number"
        label={STATISTICS_MESSAGE_LABELS.powerCyclesSinceChange}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        value={abuseEvents}
        name="abuseEvents"
        onChange={onChangeValue}
        error={isAbuseEventsValid}
        required
        type="number"
        label={STATISTICS_MESSAGE_LABELS.abuseEvents}
        variant="outlined"
      />
    </div>
  )
}

export default withStyles(styles)(EmulatorStatisticsMessageComponent)
