import { call, fork, put, select, takeEvery } from 'redux-saga/effects'

import { HttpClient } from '../../../../services/HttpClient'
import { FEATURE_KEYS } from '../../_shared/services/emulator-constants'
import { getDeviceId } from '../../_shared/services/emulator-service'
import arcEmulatorActions from './arcEmulator-actions'

export const getDevices = (state) =>
  state.eventsEmulatorReducer.arcEmulatorReducer.messageDevices
const getOrganization = (state) =>
  state.eventsEmulatorReducer.sharedEmulatorReducer.selectedOrganization

function* sendDeviceMessage(action) {
  try {
    const { params, body } = action.payload
    const { id } = yield select(getOrganization)

    const data = yield call(() => {
      return HttpClient.post(
        `/api/secured/arc/admin/test/message/send?organizationId=${id}&${params}`,
        {
          body,
        }
      )
    })

    if (data.error) {
      yield put(arcEmulatorActions.sendDeviceMessageFailed(data.error))
    } else {
      yield put(arcEmulatorActions.sendDeviceMessageSuccess(data.message))
    }
  } catch (e) {
    yield put(arcEmulatorActions.sendDeviceMessageFailed(e.message))
  }
}

function* loadMessageLocations(action) {
  const devices = yield select(getDevices)
  const { serialNumber } = action.payload
  const deviceId = getDeviceId(devices, serialNumber)
  const { id } = yield select(getOrganization)

  try {
    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/locations/${deviceId}/allowedLocations/?orgId=${id}`
      )
    })

    if (data.message) {
      yield put(arcEmulatorActions.loadMessageLocationsFailed(data.message))
    } else {
      yield put(arcEmulatorActions.loadMessageLocationsSuccess(data))
    }
  } catch (e) {
    yield put(arcEmulatorActions.loadMessageLocationsFailed(e.message))
  }
}

function* loadAllMessageLocations() {
  try {
    const { id } = yield select(getOrganization)

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/locations/?orgId=${id}`)
    })

    if (data.message) {
      yield put(arcEmulatorActions.loadMessageLocationsFailed(data.message))
    } else {
      yield put(arcEmulatorActions.loadAllMessageLocationsSuccess(data))
    }
  } catch (e) {
    yield put(arcEmulatorActions.loadMessageLocationsFailed(e.message))
  }
}

function* loadMessageDevices(action) {
  try {
    const { name } = yield select(getOrganization)

    const body = {
      name,
      featureKeys: [FEATURE_KEYS.arc],
    }

    const data = yield call(() => {
      return HttpClient.post(`/api/secured/devices/organization`, { body })
    })

    if (data.message) {
      yield put(arcEmulatorActions.loadMessageDevicesFailed(data.message))
    } else {
      yield put(arcEmulatorActions.loadMessageDevicesSuccess(data))
    }
  } catch (e) {
    yield put(arcEmulatorActions.loadMessageDevicesFailed(e.message))
  }
}

function* sendDeviceMessageSaga() {
  yield takeEvery(
    arcEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE,
    sendDeviceMessage
  )
}

function* loadMessageLocationsSaga() {
  yield takeEvery(
    arcEmulatorActions.actionTypes.LOAD_MESSAGE_LOCATIONS,
    loadMessageLocations
  )
}

function* loadAllMessageLocationsSaga() {
  yield takeEvery(
    arcEmulatorActions.actionTypes.LOAD_MESSAGE_DEVICES_SUCCEEDED,
    loadAllMessageLocations
  )
}

function* loadMessageDevicesSaga() {
  yield takeEvery(
    arcEmulatorActions.actionTypes.LOAD_MESSAGE_DEVICES,
    loadMessageDevices
  )
}

export default function* eventsARCEmulatorSaga() {
  yield fork(sendDeviceMessageSaga)
  yield fork(loadMessageLocationsSaga)
  yield fork(loadMessageDevicesSaga)
  yield fork(loadAllMessageLocationsSaga)
}
