import { CHANGE_FEATURE_FOR_ORGANIZATION } from '../../services/organizationDetails-actions'

import { Switch } from '@material-ui/core'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { checkConnection } from '../../../../../../services/root/root-service'

type FeatureSwitchProps = {
  id: string
  checked: boolean
  isReadOnly?: boolean
  featureName: string
}

const FeatureSwitch = ({
  id,
  checked,
  isReadOnly,
  featureName,
}: FeatureSwitchProps) => {
  const dispatch = useDispatch()

  const changeFeatureForOrganization = (
    id: string,
    code: string,
    checked: boolean
  ) =>
    dispatch(
      checkConnection(() => CHANGE_FEATURE_FOR_ORGANIZATION(id, code, checked))
    )

  const [isChecked, setIsChecked] = useState(checked)

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, checked } = event.target

    setIsChecked(checked)
    changeFeatureForOrganization(id, name, checked)
  }

  return (
    <Switch
      checked={isChecked}
      disabled={isReadOnly}
      color="primary"
      onChange={handleChange}
      name={featureName}
    />
  )
}

export default FeatureSwitch
