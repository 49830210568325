const styles = (theme) => ({
  displayNone: {
    display: 'none',
  },
  upload: {
    display: 'flex',
    marginLeft: '10px',
    alignItems: 'center',
    '& button': {
      textTransform: 'none',
    },
  },
  fileName: {
    marginLeft: '5px',
  },
  actionButton: {
    minWidth: '250px',
    marginLeft: '10px',
  },
  closeIcon: {
    position: 'absolute',
    left: '16px',
    top: '16px',
    padding: 0,
  },
  dialogTitle: {
    fontFamily: 'Cerebri Sans Pro Medium',
    fontSize: '16px',
    lineHeight: '17px',
    marginTop: '17px',
  },
  dialogContent: {
    paddingBottom: '35px',
  },
  bulkUploadBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  error: {
    marginTop: '10px',
  },
  example: {
    cursor: 'pointer',
    fontSize: '14px',
  },
})

export default styles
