import { call, fork, put, takeLatest } from 'redux-saga/effects'

import { HttpClient } from '../../../services/HttpClient'
import featureTogglingActions from './featureToggling-actions'

export const getFilterQuery = (state) => state.firmwareUpdateReducer.query

function* getFeatures() {
  try {
    const data = yield call(() => {
      return HttpClient.get(`/api/secured/feature/`)
    })

    if (data.message) {
      yield put(featureTogglingActions.getFeaturesFailed(data.message))
    } else {
      yield put(featureTogglingActions.getFeaturesSuccess(data))
    }
  } catch (e) {
    yield put(featureTogglingActions.getFeaturesFailed(e.message))
  }
}

function* updateFeatureState(action) {
  try {
    const { id, switchState } = action.payload

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/feature/${id}/enable/${switchState}`)
    })

    if (data.message) {
      yield put(featureTogglingActions.updateFeatureStateFailed(data.message))
    } else {
      yield put(
        featureTogglingActions.updateFeatureStateSuccess(id, switchState)
      )
    }
  } catch (e) {
    yield put(featureTogglingActions.updateFeatureStateFailed(e.message))
  }
}

function* getFeaturesSaga() {
  yield takeLatest(featureTogglingActions.actionTypes.GET_FEATURES, getFeatures)
}

function* updateFeatureStateSaga() {
  yield takeLatest(
    featureTogglingActions.actionTypes.UPDATE_FEATURE_STATE,
    updateFeatureState
  )
}

export default function* featuresTogglingSagas() {
  yield fork(getFeaturesSaga)
  yield fork(updateFeatureStateSaga)
}
