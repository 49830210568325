import { RouteComponentProps } from 'react-router-dom'
import AccessWrapper from '../../components/_shared/AccessWrapper'
import { PAGES } from '../../services/constants'
import UsersPage from './UsersPage'

const Users = (props: RouteComponentProps) => {
  return (
    <AccessWrapper page={PAGES.users}>
      <UsersPage {...props} />
    </AccessWrapper>
  )
}

export default Users
