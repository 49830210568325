const CONTAINER_TITLES = {
  features: 'Features',
  wifi: 'WiFi Credentials',
  information: 'Information',
  locations: 'Locations',
}

const WIFI = {
  buttonLabel: '+ Add WiFi Credential Manually',
  showPassword: 'Show Password',
  save: 'Save',
  add: 'Add',
  edit: 'Edit',
  credentials: 'WiFi Credentials',
}

const WIFI_FIELDS = {
  configurationLabel: 'Configuration Label',
  ssid: 'SSID',
  password: 'Password',
}

const wifiCredentialInitialState = {
  configurationLabel: '',
  ssid: '',
  password: '',
  editable: true,
}

const LOCATION_FILTER_VALUES = {
  locationName: 'Search by Location Name',
}

const ADD_LOCATION_LABELS = {
  title: 'Add Locations',
  plusAdd: '+ Add',
  add: 'Add',
  name: 'Name',
  confirm: 'confirm',
}

const ADD_PROPERTY_LABELS = {
  streetAddress: 'Street Address',
  city: 'City',
  state: 'State',
  zip: 'ZIP',
  width: 'Width (in)',
  height: 'Height (in)',
  length: 'Length (in)',
}

const propertyFieldsInitial = {
  streetAddress: '',
  city: '',
  state: '',
  zip: '',
}

const MAX_NAME_LENGTH = 30

const PROPERTY_NAME = {
  // eslint-disable-next-line
  notAllowedSymbols: /[\@\$\^\_\~\`\\\|\{\}\[\]]/,
  helperText: 'The following symbols are not allowed: @ $ ^ _ ~ ` \\ | { } [ ]',
  lengthExceeds: ' location name exceeds 30 characters.',
}

const DELETE_LOCATION_LABELS = {
  warningTitle: 'Warning',
  deleteWithSubLocations1: (
    locationName,
    subLocationsCount
  ) => `Are you sure you want to delete "${locationName}"?
     This will permanently delete all usage data assosiated with this location and its (${subLocationsCount}) sub-locations.`,
  deleteWithoutSubLocations1: (
    locationName
  ) => `Are you sure you want to delete "${locationName}"?
     This will permanently delete all usage data assosiated with this location.`,
  commonMessage: (
    locationName
  ) => `"${locationName}" will also be removed as a location option
     on your R-Zero Arc.`,
  deleteWarningConfirm: (subLocationsCount) =>
    `To delete, enter (${subLocationsCount}) below and click "Delete Location".`,

  assignedLocationsAndUsers: (locationName) =>
    `The following users and devices are currently assigned to ${locationName}. Please, re-assign them before ` +
    `deleting this location.`,
  deleteLocation: 'Delete Location',
  back: 'Back',
}

const EDIT_LOCATION_LABELS = {
  edit: 'Edit ',
  button: 'Update Location',
}

export {
  CONTAINER_TITLES,
  WIFI,
  WIFI_FIELDS,
  wifiCredentialInitialState,
  LOCATION_FILTER_VALUES,
  ADD_LOCATION_LABELS,
  ADD_PROPERTY_LABELS,
  propertyFieldsInitial,
  MAX_NAME_LENGTH,
  PROPERTY_NAME,
  DELETE_LOCATION_LABELS,
  EDIT_LOCATION_LABELS,
}
