const styles = (theme) => ({
  selectCell: {
    minWidth: '130px',
    margin: 'auto 0',
  },
  descriptionCell: {
    width: '150px',
  },
  headerCell: {
    fontFamily: 'Cerebri Sans Pro Semi Bold',
  },
  tableWrapper: {
    marginBottom: '15px',
  },
  faultNames: {
    width: '220px',
  },
})

export default styles
