import {
  getStorageData,
  removeStorageData,
  setStorageComplicatedData,
} from './defaultStore-sevice'

import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'

const createAuthProvider = ({
  accessTokenExpireKey,
  accessTokenKey,
  localStorageKey = 'RZONE_AUTH_TOKEN',
  onUpdateToken,
  onHydratation,
}) => {
  const tp = createTokenProvider({
    accessTokenExpireKey,
    accessTokenKey,
    localStorageKey,
    onUpdateToken,
    onHydratation,
  })

  const login = (newTokens) => {
    tp.setToken(newTokens)
  }

  const logout = () => {
    tp.setToken(null)
  }

  return [tp, login, logout]
}

const createTokenProvider = ({
  localStorageKey,
  accessTokenKey,
  accessTokenExpireKey,
  onUpdateToken,
  onHydratation,
}) => {
  let listeners = []

  const getTokenInternal = () => {
    const data = getStorageData(localStorageKey)

    return (data && JSON.parse(data)) || null
  }

  const subscribe = (listener) => {
    listeners.push(listener)
  }

  const unsubscribe = (listener) => {
    listeners = listeners.filter((l) => l !== listener)
  }

  const jwtExp = (token) => {
    if (!(typeof token === 'string')) {
      return null
    }

    const split = token.split('.')

    if (split.length < 2) {
      return null
    }

    try {
      const jwt = JSON.parse(atob(token.split('.')[1]))

      if (jwt && jwt.exp && Number.isFinite(jwt.exp)) {
        return jwt.exp * 1000
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }

  const getExpire = (token) => {
    if (!token) {
      return null
    }

    if (accessTokenExpireKey) {
      return token[accessTokenExpireKey]
    }

    if (accessTokenKey) {
      const exp = jwtExp(token[accessTokenKey])

      if (exp) {
        return exp
      }
    }

    return jwtExp(token)
  }

  const isExpired = (exp) => {
    if (!exp) {
      return false
    }

    return Date.now() > exp
  }

  const checkExpiry = async () => {
    const token = getTokenInternal()

    if (token && isExpired(getExpire(token))) {
      const newToken = onUpdateToken ? await onUpdateToken(token) : null

      if (newToken) {
        setToken(newToken)
      } else {
        removeStorageData(localStorageKey)
      }
    }
  }

  const getToken = async () => {
    await checkExpiry()

    if (accessTokenKey) {
      const token = getTokenInternal()

      return token && token[accessTokenKey]
    }

    return getTokenInternal()
  }

  const isLoggedIn = () => {
    const token = getTokenInternal()

    if (onHydratation) onHydratation(token)

    return !!token && !isEmpty(token)
  }

  const setToken = (token) => {
    const oldTokenString = getStorageData(localStorageKey)
    const tokenObj = oldTokenString && JSON.parse(oldTokenString)

    if (token) {
      if (tokenObj && !isEmpty(tokenObj)) {
        token.refreshToken = tokenObj.refreshToken
      }

      setStorageComplicatedData(localStorageKey, token)
    } else {
      removeStorageData(localStorageKey)
    }

    notify()
  }

  const notify = () => {
    const isLogged = isLoggedIn()

    forEach(listeners, (l) => l(isLogged))
  }

  return {
    getToken,
    isLoggedIn,
    setToken,
    subscribe,
    unsubscribe,
  }
}

export { createAuthProvider, createTokenProvider }
