import { DEVICE_TYPE } from '../../../../../services/constants'

const DEVICE_DELETE_MODAL = {
  modalTitle: 'Delete Device',
  actionLabel: 'Delete',
  closeLabel: 'Cancel',
}

const FULL_CLEANUP_DEVICE = {
  modalTitle: 'Full Cleanup Device',
  tooltip: 'Full Cleanup',
  actionLabel: 'Cleanup',
  closeLabel: 'Cancel',
}

const BACKEND_CLEANUP_DEVICE = {
  modalTitle: 'Backend Cleanup Device',
  tooltip: 'Backend Cleanup',
  actionLabel: 'Cleanup',
  closeLabel: 'Cancel',
}

const DEVICES_PAGE_LABELS = {
  devices: 'Devices',
  [DEVICE_TYPE.UR_UVGI.type]: 'Beam Devices',
  [DEVICE_TYPE.UVI_ARC.type]: 'Arc Devices',
  [DEVICE_TYPE.FAR_UVC.type]: 'FarUV Devices',
  [DEVICE_TYPE.EXT_DEVICE.type]: 'External Devices',
  [DEVICE_TYPE.NETWORK_DEVICE.type]: 'Network Devices',
  [DEVICE_TYPE.SENSOR.type]: 'Sensor Devices',
  recreateCertificate: 'Update Certificate',
}

const IMEI_LABELS = {
  title: 'Check Serial Number by IMEI',
  placeholder: 'IMEI',
  action: 'Check IMEI',
}

const DEVICES_FILTER_LABELS = {
  serialNumber: 'Serial Number',
  organization: 'Organization',
  imei: 'IMEI',
  macAddress: 'Mac Address',
  apply: 'Apply Filter',
  resetFilter: 'Reset filter',
}

const defaultFilterState = {
  serialNumber: '',
  organizationName: '',
  imei: '',
  macAddress: '',
}

const CERTIFICATE_UPDATED_SUCCESS = 'Certificate Updated Successfully'

const SEARCH_FIELDS = {
  serialNumber: 'serialNumber',
  organization: 'organization',
  imei: 'imei',
  macAddress: 'macAddress',
  organizationName: 'organizationName',
}

const REVISION_NAMES = {
  UVI_ARC: 'A',
  UVI_ARC_REV_B: 'B',
  UVI_ARC_REV_D: 'D',
}

const initialNumbersOfTypes = {
  [DEVICE_TYPE.UVI_ARC.type]: 0,
  [DEVICE_TYPE.NETWORK_DEVICE.type]: 0,
  [DEVICE_TYPE.SENSOR.type]: 0,
}

const EXT_TYPES_INFO_SELECT_CONFIG = {
  type: {
    label: 'Type',
    name: 'extDeviceType',
    valueName: 'value',
    template: (option) => option.label,
  },
  subType: {
    label: 'SubType',
    name: 'extDeviceSubType',
    valueName: 'value',
    template: (option) => option.label,
  },
}

export const FAR_UVC_TYPES = {
  vive: {
    label: 'Vive',
  },
  eon: {
    label: 'EON',
  },
}

export {
  DEVICE_DELETE_MODAL,
  FULL_CLEANUP_DEVICE,
  BACKEND_CLEANUP_DEVICE,
  DEVICES_PAGE_LABELS,
  IMEI_LABELS,
  DEVICES_FILTER_LABELS,
  defaultFilterState,
  CERTIFICATE_UPDATED_SUCCESS,
  SEARCH_FIELDS,
  REVISION_NAMES,
  initialNumbersOfTypes,
  EXT_TYPES_INFO_SELECT_CONFIG,
}
