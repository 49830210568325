import AccessWrapper from '../../components/_shared/AccessWrapper'
import { PAGES } from '../../services/constants'
import OrganizationsPage from './OrganizationsPage'

const Organizations = (props) => {
  return (
    <AccessWrapper page={PAGES.organizations}>
      <OrganizationsPage {...props} />
    </AccessWrapper>
  )
}

export default Organizations
