import { extractUuids, prepareCommands } from './emulator-service'

import emulatorActions from './emulator-actions'

export const emulatorReducer = (
  state = {
    devicePortalURL: '',

    devices: [],
    commandTypes: [],
    commandNames: [],

    loadDevicesError: '',
    loadCommandNamesError: '',
    loadCommandTypesError: '',

    sentCommands: [],
    sentCommandsUuids: [],
    existingCommands: [],
    existingCommandsUuids: [],

    loadOrganizationError: [],
    organizations: [],

    loading: false,
  },
  action
) => {
  switch (action.type) {
    case emulatorActions.actionTypes.LOAD_ORGANIZATIONS: {
      return {
        ...state,
        loadOrganizationError: '',
        isLoading: true,
      }
    }
    case emulatorActions.actionTypes.LOAD_ORGANIZATIONS_SUCCEEDED: {
      const { organizations } = action.payload

      return {
        ...state,
        organizations,
        isLoading: false,
        loadOrganizationError: '',
      }
    }
    case emulatorActions.actionTypes.LOAD_UVGI_DEVICES_SUCCEEDED: {
      const { devices } = action.payload

      return {
        ...state,
        devices,
        loadDevicesError: '',
        loading: false,
      }
    }
    case emulatorActions.actionTypes.GET_DEVICE_PORTAL_URL_SUCCEEDED: {
      const { url } = action.payload

      return {
        ...state,
        devicePortalURL: url,
      }
    }
    case emulatorActions.actionTypes.LOAD_COMMAND_NAMES_SUCCEEDED: {
      const { commandNames } = action.payload

      return {
        ...state,
        commandNames: prepareCommands(commandNames),
        loadCommandNamesError: '',
      }
    }
    case emulatorActions.actionTypes.LOAD_COMMAND_TYPES_SUCCEEDED: {
      const { commandTypes } = action.payload

      return {
        ...state,
        commandTypes: prepareCommands(commandTypes),
        loadCommandTypesError: '',
      }
    }
    case emulatorActions.actionTypes.SEND_COMMANDS_SUCCEEDED: {
      const { data } = action.payload

      return {
        ...state,
        sentCommandsUuids: extractUuids(data),
      }
    }
    case emulatorActions.actionTypes.GET_COMMANDS_SUCCEEDED: {
      const { data } = action.payload

      return {
        ...state,
        existingCommands: data,
        existingCommandsUuids: extractUuids(data),
      }
    }
    case emulatorActions.actionTypes.LOAD_ORGANIZATIONS_FAILED: {
      return {
        ...state,
        loadOrganizationError: action.payload.message,
        isLoading: false,
      }
    }
    default:
      return state
  }
}
