import { COLORS } from '../../services/constants'

const styles = () => ({
  button: {
    marginBottom: '20px',
    cursor: 'pointer',
    height: 'auto',
    textAlign: 'left',
    padding: '15px',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  disabledRow: {
    backgroundColor: COLORS.GRAY_DISABLED,
    '& > td': {
      color: COLORS.GRAY,
    },
  },
})

export default styles
