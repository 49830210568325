import { Device } from 'containers/Devices/services/devices-types'
import find from 'lodash/find'
import { initialState } from './netEmulator-constants'

export const getDeviceBySerialNumber = (
  devices: Device[],
  serialNumber: string
) => {
  return find(devices, ['serialNumber', serialNumber])
}

export const getImeiByDevice = (devices: Device[], serialNumber: string) => {
  const device = find(devices, ['serialNumber', serialNumber])

  return device?.imei
}

export const getLocationForDevice = (
  devices: Device[],
  serialNumber: string,
  locations: { id: number; name: string }[]
) => {
  const device = find(devices, ['serialNumber', serialNumber])
  const locationId = device?.allowedLocationIds[0]

  return find(locations, ['id', locationId])
}

export const validateBody = ({
  certificatesInfo,
  commonDevicesInfo,
}: typeof initialState) => {
  let isValid = true
  Object.keys(certificatesInfo).map((key) => {
    if (!certificatesInfo[key as keyof typeof certificatesInfo]) {
      isValid = false
    }
  })

  commonDevicesInfo.map((commonDeviceInfo) => {
    if (!commonDeviceInfo['mac'] || !commonDeviceInfo['commDeviceName']) {
      isValid = false
    }
  })
  return isValid
}

export const prepareBody = ({
  certificatesInfo,
  commonDevicesInfo,
}: typeof initialState) => {
  const body = {
    commDevicesInfo: commonDevicesInfo,
    certificatesInfo: certificatesInfo,
  }

  return JSON.stringify(body)
}
