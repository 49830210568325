const LABELS = {
  msrmnt: 'Measurement Event',
  messageMeta: 'Message Meta',
}

const EVENTS_VALUES = {
  msrmnt: 'MSRMNT',
}

const messageConstants = [
  {
    label: LABELS.msrmnt,
    value: EVENTS_VALUES.msrmnt,
    code: EVENTS_VALUES.msrmnt,
  },
]

const EXTEmulatorInitialState = {
  error: '',
  deviceMessage: '',
  devices: [],
  deviceLocations: [],
  allLocations: [],
}

const initialState = {
  deviceName: '',
  locationId: '',
  location: { name: '' },
  messageTypeText: '',
  useCurrentTime: true,
  deviceDateTime: new Date(),
  metaInfo: '',
  comment: 'web-emulator',

  imei: '',
  imeiCopy: '',
}

const paramsFields = [
  'deviceName',
  'locationId',
  'messageTypeText',
  'deviceDateTime',
]

const commonRequiredFields = [...paramsFields, 'metaInfo']

const ERROR = 'Not valid object in metaInfo field'

export {
  messageConstants,
  EXTEmulatorInitialState,
  LABELS,
  initialState,
  commonRequiredFields,
  EVENTS_VALUES,
  paramsFields,
  ERROR,
}
