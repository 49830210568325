import {
  DEVICE_MODE_OPTIONS,
  FAULT_DEFAULT_DATA,
  FAULT_EVENTS,
  LABELS,
} from '../services/uvgiEmulator-constants'

import { withStyles } from '@material-ui/core/styles'
import CustomSelect from '../../../../components/_shared/customSelect/CustomSelect'
import styles from '../../style'
import ExpandableTable from '../../_shared/components/ExpandableTable'
import FaultEventRow from './FaultEventRow'

const StatusEventComponent = (props) => {
  const { isSubmitted, changeValue, faultEventData, changeDateValue, mode } =
    props

  const _messageModeTemplate = (item) => item.label

  const onChangeValue = (event) => {
    changeValue(event, event.target.name)
  }

  return (
    <>
      <CustomSelect
        options={DEVICE_MODE_OPTIONS}
        isRequired={true}
        name="mode"
        label={LABELS.mode}
        onChange={onChangeValue}
        error={!mode && isSubmitted}
        value={mode}
        valueName="value"
        template={_messageModeTemplate}
      />
      <ExpandableTable
        CustomTableRow={FaultEventRow}
        propName={'faultEventData'}
        limit={10}
        isSubmitted={isSubmitted}
        tableTitle={FAULT_EVENTS.tableTitle}
        columnNames={FAULT_EVENTS.tableColumnsLabels}
        defaultValue={{ ...FAULT_DEFAULT_DATA }}
        tableData={faultEventData}
        changeValue={changeDateValue}
      />
    </>
  )
}

export default withStyles(styles)(StatusEventComponent)
