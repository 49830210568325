import {
  ADD_LOCATIONS_BULK_FAILED,
  ADD_LOCATION_FAILED,
  BULK_UPLOAD_LOCATIONS_FAILED,
  BULK_UPLOAD_LOCATIONS_SUCCEEDED,
  CHANGE_FEATURE_FOR_ORGANIZATION,
  CHANGE_FEATURE_FOR_ORGANIZATION_FAILED,
  CHANGE_FEATURE_FOR_ORGANIZATION_SUCCEEDED,
  DELETE_LOCATION_WITH_SUBLOCATIONS_FAILED,
  DELETE_WIFI_CREDENTIAL_FAILED,
  DELETE_WIFI_CREDENTIAL_SUCCEEDED,
  GET_BULK_TEMPLATE_FAILED,
  GET_FEATURES,
  GET_FEATURES_FAILED,
  GET_FEATURES_SUCCEEDED,
  GET_LOCATIONS_LIST_FAILED,
  GET_LOCATION_SCHEMA_FAILED,
  GET_LOCATION_SCHEMA_SUCCEEDED,
  GET_ORGANIZATION,
  GET_ORGANIZATION_FAILED,
  GET_ORGANIZATION_SUCCEEDED,
  GET_SUBLOCATIONS_COUNT,
  GET_SUBLOCATIONS_COUNT_FAILED,
  GET_SUBLOCATIONS_COUNT_SUCCESS,
  GET_WIFI_CREDENTIALS_FAILED,
  GET_WIFI_CREDENTIALS_SUCCEEDED,
  PUT_WIFI_CREDENTIAL_FAILED,
  PUT_WIFI_CREDENTIAL_SUCCEEDED,
  RESET_ERROR,
  RESET_VALIDATION_ERRORS,
  SET_ERROR,
  UPDATE_LOCATION_FAILED,
  UPDATE_LOCATION_TREE,
  UPDATE_LOCATION_TREE_FAILED,
  UPDATE_LOCATION_TREE_SUCCESS,
  UPDATE_SF_ID_FAILED,
  UPDATE_SF_ID_SUCCEEDED,
  UPDATE_STATUS_FAILED,
  UPDATE_STATUS_SUCCEEDED,
  UPDATE_TYPE_FAILED,
  UPDATE_TYPE_SUCCEEDED,
} from './organizationDetails-actions'
import {
  deleteCredential,
  updateWiFiCredentialsList,
} from './organizationDetails-service'
import {
  Feature,
  OrganizationDetails,
  WifiCredential,
  WifiValidationErrors,
} from './organizationDetails-types'

import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import { createReducer } from 'redux-act'

export const organizationDetailsReducer = createReducer<{
  wifiCredentialsList: WifiCredential[]
  isLoading: boolean
  isLoadingSubLocations: boolean
  organizationId: string
  loadOrganizationError: string
  isUpdatingFeature: boolean
  error: string
  features: Feature[]
  validationErrors: WifiValidationErrors
  locationSchemaId: number | null
  details: OrganizationDetails
  treeLevels: { [key: string]: string }
  treeMargins: { [key: string]: string }
  possibleChildren: { [key: string]: string[] }
  subLocationsCount: number
  bulkLoadFailed: number
  bulkLoadTotal: number
  bulkLoadError: string
}>(
  {
    [GET_ORGANIZATION.getType()]: (state, payload) => ({
      ...state,
      organizationId: payload.id,
    }),
    [GET_FEATURES.getType()]: (state, payload) => ({
      ...state,
      loadOrganizationError: '',
    }),
    [CHANGE_FEATURE_FOR_ORGANIZATION.getType()]: (state, payload) => ({
      ...state,
      isUpdatingFeature: true,
    }),
    [CHANGE_FEATURE_FOR_ORGANIZATION_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [GET_FEATURES_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [GET_ORGANIZATION_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [GET_LOCATIONS_LIST_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [GET_LOCATION_SCHEMA_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [ADD_LOCATION_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [ADD_LOCATIONS_BULK_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [DELETE_LOCATION_WITH_SUBLOCATIONS_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [UPDATE_LOCATION_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [GET_BULK_TEMPLATE_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
      isLoading: false,
      isUpdatingFeature: false,
    }),
    [CHANGE_FEATURE_FOR_ORGANIZATION_SUCCEEDED.getType()]: (state, payload) => {
      const { id, switchState } = payload

      const changedIndex = findIndex(state.features, ['id', id])
      const features = cloneDeep(state.features)
      features[changedIndex].enabled = switchState

      return {
        ...state,
        features,
        error: payload.message,
        isLoading: false,
        isUpdatingFeature: false,
      }
    },
    [GET_FEATURES_SUCCEEDED.getType()]: (state, payload) => ({
      ...state,
      features: payload.data,
      isLoading: false,
    }),
    [GET_WIFI_CREDENTIALS_SUCCEEDED.getType()]: (state, payload) => ({
      ...state,
      wifiCredentialsList: payload.wifiCredentialsList,
    }),
    [GET_WIFI_CREDENTIALS_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
    }),
    [DELETE_WIFI_CREDENTIAL_FAILED.getType()]: (state, payload) => ({
      ...state,
      error: payload.message,
    }),
    [PUT_WIFI_CREDENTIAL_FAILED.getType()]: (state, payload) => ({
      ...state,
      validationErrors: payload.errors,
    }),
    [PUT_WIFI_CREDENTIAL_SUCCEEDED.getType()]: (state, payload) => {
      const { data } = payload

      const updatedWiFiCredentialsList = updateWiFiCredentialsList(
        state.wifiCredentialsList,
        data
      )

      return {
        ...state,
        wifiCredentialsList: updatedWiFiCredentialsList,
        error: '',
      }
    },
    [DELETE_WIFI_CREDENTIAL_SUCCEEDED.getType()]: (state, payload) => {
      const { credentialId } = payload

      const updatedList = deleteCredential(
        state.wifiCredentialsList,
        credentialId
      )

      return {
        ...state,
        wifiCredentialsList: updatedList,
        error: '',
      }
    },
    [RESET_VALIDATION_ERRORS.getType()]: (state, payload) => ({
      ...state,
      validationErrors: {},
    }),
    [GET_ORGANIZATION_SUCCEEDED.getType()]: (state, payload) => {
      const details = payload.data[0]
      return {
        ...state,
        locationSchemaId: details.locationSchemaId,
        details,
      }
    },
    [GET_LOCATION_SCHEMA_SUCCEEDED.getType()]: (state, payload) => {
      const treeLevels = <{ [key: string]: string }>{}
      const treeMargins = <{ [key: string]: string }>{}
      const possibleChildren = <{ [key: string]: string[] }>{}

      forEach(
        payload.levels,
        (
          item: {
            locationType: string
            possibleParents: string[]
          },
          index: number
        ) => {
          const { locationType, possibleParents } = item

          treeLevels[locationType] = item.locationType
          treeMargins[locationType] = `${index * 10}px`

          if (possibleParents?.length) {
            forEach(possibleParents, (parent) => {
              if (possibleChildren[parent]?.length) {
                possibleChildren[parent].push(locationType)
              } else {
                possibleChildren[parent] = [locationType]
              }
            })
          }
        }
      )

      return {
        ...state,
        treeLevels,
        treeMargins,
        possibleChildren,
      }
    },
    [BULK_UPLOAD_LOCATIONS_SUCCEEDED.getType()]: (state, payload) => {
      const { fails, total } = payload.response

      return {
        ...state,
        bulkLoadFailed: fails,
        bulkLoadTotal: total,
        bulkLoadError: '',
      }
    },
    [BULK_UPLOAD_LOCATIONS_FAILED.getType()]: (state, payload) => ({
      ...state,
      bulkLoadError: payload.message,
    }),
    [SET_ERROR.getType()]: (state, payload) => ({
      ...state,
      error: payload.error,
    }),
    [RESET_ERROR.getType()]: (state, payload) => ({
      ...state,
      error: '',
    }),
    [GET_SUBLOCATIONS_COUNT.getType()]: (state, payload) => ({
      ...state,
      isLoadingSubLocations: true,
      subLocationsCount: 0,
    }),
    [GET_SUBLOCATIONS_COUNT_FAILED.getType()]: (state, payload) => ({
      ...state,
      isLoadingSubLocations: false,
      subLocationsCount: 0,
    }),
    [GET_SUBLOCATIONS_COUNT_SUCCESS.getType()]: (state, payload) => ({
      ...state,
      isLoadingSubLocations: false,
      subLocationsCount: payload.sublocations,
    }),
    [UPDATE_LOCATION_TREE.getType()]: (state, payload) => ({
      ...state,
      locationSchemaId: payload.locationSchemaId,
      isLoading: true,
    }),
    [UPDATE_LOCATION_TREE_SUCCESS.getType()]: (state, payload) => ({
      ...state,
      isLoading: false,
      locationSchemaId: payload.locationSchemaId,
    }),
    [UPDATE_LOCATION_TREE_FAILED.getType()]: (state, payload) => ({
      ...state,
      isLoading: false,
      errors: payload.message,
    }),
    [UPDATE_STATUS_SUCCEEDED.getType()]: (state, payload) => {
      const details = payload as OrganizationDetails
      return {
        ...state,
        details,
      }
    },
    [UPDATE_SF_ID_SUCCEEDED.getType()]: (state, payload) => {
      const { salesForceId } = payload
      return {
        ...state,
        details: { ...state.details, salesForceId },
      }
    },
    [UPDATE_TYPE_SUCCEEDED.getType()]: (state, payload) => {
      const { type } = payload
      return {
        ...state,
        details: { ...state.details, type },
      }
    },
    [UPDATE_STATUS_FAILED.getType()]: (state, payload) => {
      return {
        ...state,
        details: { ...state.details },
      }
    },
    [UPDATE_TYPE_FAILED.getType()]: (state, payload) => {
      return {
        ...state,
        details: { ...state.details },
      }
    },
    [UPDATE_SF_ID_FAILED.getType()]: (state, payload) => {
      return {
        ...state,
        details: { ...state.details },
      }
    },
  },
  {
    organizationId: '',
    wifiCredentialsList: <WifiCredential[]>[],
    isLoading: false,
    isLoadingSubLocations: false,
    error: '',
    validationErrors: {
      message: '',
    },
    loadOrganizationError: '',
    isUpdatingFeature: false,
    locationSchemaId: null,
    treeLevels: {},
    treeMargins: {},
    possibleChildren: {},
    features: <Feature[]>[],
    details: <OrganizationDetails>{},
    subLocationsCount: 0,
    bulkLoadFailed: 0,
    bulkLoadTotal: 0,
    bulkLoadError: '',
  }
)
