import { createAction } from 'redux-act'
import { AddOrganizationDataType } from './organization-constants'

const actionTypes = {
  LOAD_ORGANIZATIONS: '{ORGANIZATIONS} Load Organizations',
  LOAD_ORGANIZATIONS_SUCCEEDED: '{ORGANIZATIONS} Load Organizations Succeeded',
  LOAD_ORGANIZATIONS_FAILED: '{ORGANIZATIONS} Load Organizations Failed',

  MOVE_SELECTED: '{ORGANIZATIONS} Move Selected',
  CHANGE_SELECTED_BY_ID: '{ORGANIZATIONS} Change Selected By Id',

  GET_ACCOUNT_OWNER: '{ORGANIZATIONS} Get Account Owner',
  GET_ACCOUNT_OWNER_SUCCEEDED: '{ORGANIZATIONS} Get Account Owner Succeeded',
  GET_ACCOUNT_OWNER_FAILED: '{ORGANIZATIONS} Get Account Owner Failed',

  GET_POTENTIAL_ACCOUNT_OWNERS: '{ORGANIZATIONS} Get Potential Account Owners',
  GET_POTENTIAL_ACCOUNT_OWNERS_SUCCEEDED:
    '{ORGANIZATIONS} Get Potential Account Owners Succeeded',
  GET_POTENTIAL_ACCOUNT_OWNERS_FAILED:
    '{ORGANIZATIONS} Get Potential Account Owners',

  CHANGE_ACCOUNT_OWNER: '{Account} Change Account Owner',
  CHANGE_ACCOUNT_OWNER_SUCCEEDED: '{Account} Change Account Owner Succeeded',
  CHANGE_ACCOUNT_OWNER_FAILED: '{Account} Change Account Owner Failed',

  DEACTIVATE_ACCOUNT: '{Account} Deactivate Account',
  DEACTIVATE_ACCOUNT_SUCCEEDED: '{Account} Deactivate Account Succeeded',
  DEACTIVATE_ACCOUNT_FAILED: '{Account} Deactivate Account Failed',

  ACTIVATE_ACCOUNT: '{Account} Activate Account',
  ACTIVATE_ACCOUNT_SUCCEEDED: '{Account} Activate Account Succeeded',
  ACTIVATE_ACCOUNT_FAILED: '{Account} Activate Account Failed',

  GET_FEATURES: '{ORGANIZATIONS} Get Features',
  GET_FEATURES_SUCCEEDED: '{ORGANIZATIONS} Get Features Succeeded',
  GET_FEATURES_FAILED: '{ORGANIZATIONS} Get Features Failed',

  OFF_FEATURE_FOR_ORGANIZATION: '{ORGANIZATIONS} Off Feature For Organization',
  ON_FEATURE_FOR_ORGANIZATION: '{ORGANIZATIONS} On Feature For Organization',
  CHANGE_FEATURE_FOR_ORGANIZATION_SUCCEEDED:
    '{ORGANIZATIONS} Off Feature For Organization Succeeded',
  CHANGE_FEATURE_FOR_ORGANIZATION_FAILED:
    '{ORGANIZATIONS} Off Feature For Organization Failed',

  GET_IDP_DATA: '{ORGANIZATIONS} Get Idp Data',
  GET_IDP_DATA_SUCCEEDED: '{ORGANIZATIONS} Get Idp Data Succeeded',
  GET_IDP_DATA_FAILED: '{ORGANIZATIONS} Get Idp Data Failed',

  SAVE_IDP_DATA: '{ORGANIZATIONS} Save Idp Data',
  SAVE_IDP_DATA_SUCCEEDED: '{ORGANIZATIONS} Save Idp Data Succeeded',
  SAVE_IDP_DATA_FAILED: '{ORGANIZATIONS} Save Idp Data Failed',

  DELETE_IDP_DATA: '{ORGANIZATIONS} Delete Idp Data',
  DELETE_IDP_DATA_SUCCEEDED: '{ORGANIZATIONS} Delete Idp Data Succeeded',
  DELETE_IDP_DATA_FAILED: '{ORGANIZATIONS} Delete Idp Data Failed',

  RESET_ERROR: '{ORGANIZATIONS} Reset Error',
}

const organizationActions = {
  actionTypes,

  loadOrganizations: function () {
    return {
      type: actionTypes.LOAD_ORGANIZATIONS,
    }
  },

  loadOrganizationsSuccess: function (organizations: any) {
    return {
      type: actionTypes.LOAD_ORGANIZATIONS_SUCCEEDED,
      payload: {
        organizations,
      },
    }
  },

  loadOrganizationsFailed: function (message: any) {
    return {
      type: actionTypes.LOAD_ORGANIZATIONS_FAILED,
      payload: {
        message,
      },
    }
  },

  moveSelected: function (type: any) {
    return {
      type: actionTypes.MOVE_SELECTED,
      payload: {
        type,
      },
    }
  },

  changeSelectedById: function (id: any) {
    return {
      type: actionTypes.CHANGE_SELECTED_BY_ID,
      payload: {
        id,
      },
    }
  },

  getAccountOwner: function (id: any) {
    return {
      type: actionTypes.GET_ACCOUNT_OWNER,
      payload: {
        id,
      },
    }
  },

  getAccountOwnerSuccess: function (account: any) {
    return {
      type: actionTypes.GET_ACCOUNT_OWNER_SUCCEEDED,
      payload: {
        account,
      },
    }
  },

  getAccountOwnerFailed: function (message: any) {
    return {
      type: actionTypes.GET_ACCOUNT_OWNER_FAILED,
      payload: {
        message,
      },
    }
  },

  getPotentialAccountOwners: function (id: any) {
    return {
      type: actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS,
      payload: {
        id,
      },
    }
  },

  getPotentialAccountOwnersSuccess: function (admins: any) {
    return {
      type: actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS_SUCCEEDED,
      payload: {
        admins,
      },
    }
  },

  getPotentialAccountOwnersFailed: function (message: any) {
    return {
      type: actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS_FAILED,
      payload: {
        message,
      },
    }
  },

  changeAccountOwner: function (newId: any, onSuccess: any) {
    return {
      type: actionTypes.CHANGE_ACCOUNT_OWNER,
      payload: {
        newId,
        onSuccess,
      },
    }
  },

  changeAccountOwnerSuccess: function () {
    return {
      type: actionTypes.CHANGE_ACCOUNT_OWNER_SUCCEEDED,
    }
  },

  changeAccountOwnerFailed: function (message: any) {
    return {
      type: actionTypes.CHANGE_ACCOUNT_OWNER_FAILED,
      payload: {
        message,
      },
    }
  },

  deactivateAccount: (id: any, onSuccess: any) => {
    return {
      type: actionTypes.DEACTIVATE_ACCOUNT,
      payload: {
        id,
        onSuccess,
      },
    }
  },

  deactivateAccountSuccess: () => {
    return {
      type: actionTypes.DEACTIVATE_ACCOUNT_SUCCEEDED,
    }
  },

  deactivateAccountFailed: (message: any) => {
    return {
      type: actionTypes.DEACTIVATE_ACCOUNT_FAILED,
      payload: {
        message,
      },
    }
  },

  activateAccount: (id: any, onSuccess: any) => {
    return {
      type: actionTypes.ACTIVATE_ACCOUNT,
      payload: {
        id,
        onSuccess,
      },
    }
  },

  activateAccountSuccess: () => {
    return {
      type: actionTypes.ACTIVATE_ACCOUNT_SUCCEEDED,
    }
  },

  activateAccountFailed: (message: any) => {
    return {
      type: actionTypes.ACTIVATE_ACCOUNT_FAILED,
      payload: {
        message,
      },
    }
  },

  getFeatures: (id: any) => {
    return {
      type: actionTypes.GET_FEATURES,
      payload: {
        id,
      },
    }
  },

  getFeaturesSuccess: (data: any) => {
    return {
      type: actionTypes.GET_FEATURES_SUCCEEDED,
      payload: {
        data,
      },
    }
  },

  getFeaturesFailed: (message: any) => {
    return {
      type: actionTypes.GET_FEATURES_FAILED,
      payload: {
        message,
      },
    }
  },

  offFeatureForOrganization: (id: any, code: any) => {
    return {
      type: actionTypes.OFF_FEATURE_FOR_ORGANIZATION,
      payload: {
        id,
        code,
      },
    }
  },

  onFeatureForOrganization: (id: any, code: any) => {
    return {
      type: actionTypes.ON_FEATURE_FOR_ORGANIZATION,
      payload: {
        id,
        code,
      },
    }
  },

  changeFeatureForOrganizationSuccess: (id: any, switchState: any) => {
    return {
      type: actionTypes.CHANGE_FEATURE_FOR_ORGANIZATION_SUCCEEDED,
      payload: {
        id,
        switchState,
      },
    }
  },

  changeFeatureForOrganizationFailed: (message: any) => {
    return {
      type: actionTypes.CHANGE_FEATURE_FOR_ORGANIZATION_FAILED,
      payload: {
        message,
      },
    }
  },

  getIdpData: (id: any) => {
    return {
      type: actionTypes.GET_IDP_DATA,
      payload: {
        id,
      },
    }
  },

  getIdpDataSuccess: (id: any, data: any) => {
    return {
      type: actionTypes.GET_IDP_DATA_SUCCEEDED,
      payload: {
        id,
        data,
      },
    }
  },

  getIdpDataFailed: (message: any) => {
    return {
      type: actionTypes.GET_IDP_DATA_FAILED,
      payload: {
        message,
      },
    }
  },

  saveIdpData: (id: any, body: any, onSuccess: any) => {
    return {
      type: actionTypes.SAVE_IDP_DATA,
      payload: {
        id,
        body,
        onSuccess,
      },
    }
  },

  saveIdpDataSuccess: (data: any) => {
    return {
      type: actionTypes.SAVE_IDP_DATA_SUCCEEDED,
      payload: {
        data,
      },
    }
  },

  saveIdpDataFailed: (message: any) => {
    return {
      type: actionTypes.SAVE_IDP_DATA_FAILED,
      payload: {
        message,
      },
    }
  },

  deleteIdpData: (id: any) => {
    return {
      type: actionTypes.DELETE_IDP_DATA,
      payload: {
        id,
      },
    }
  },

  deleteIdpDataSuccess: (data: any) => {
    return {
      type: actionTypes.DELETE_IDP_DATA_SUCCEEDED,
      payload: {
        data,
      },
    }
  },

  deleteIdpDataFailed: (message: any) => {
    return {
      type: actionTypes.DELETE_IDP_DATA_FAILED,
      payload: {
        message,
      },
    }
  },
  resetError: () => {
    return {
      type: actionTypes.RESET_ERROR,
    }
  },
}

export const SAVE_ORGANIZATION_DATA_INIT =
  createAction<AddOrganizationDataType>(
    '{ORGANIZATIONS} Save Organization Data Init'
  )

export const SAVE_ORGANIZATION_DATA_SUCCESS = createAction(
  '{ORGANIZATIONS} Save Organization Data Succeeded'
)

export const SAVE_ORGANIZATION_DATA_ERROR = createAction<string>(
  '{ORGANIZATIONS} Save Organization Data Failed'
)

export const TIMEZONES_BY_ZIP_INIT = createAction<string>(
  '{ORGANIZATIONS} List Timezones by ZIP Init'
)

export const TIMEZONES_BY_ZIP_SUCCESS = createAction<
  { timezoneName: string; abbreviation: string }[]
>('{ORGANIZATIONS} List Timezones by ZIP Success')

export const TIMEZONES_BY_ZIP_ERROR = createAction(
  '{ORGANIZATIONS} List Timezones by ZIP Error'
)

export default organizationActions
