import { Button, ListItemText } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import debounce from 'lodash/debounce'
import CloseButton from '../closeButton/CloseButton'
import CustomSelectComponent from '../CustomSelect'
import { useStyles } from './EditDropdownModalStyles'

type EditDropdownModalProp = {
  onSubmit: (newValue: string) => void
  onClose?: () => void
  modalTitle: string
  defaultValue: string
  label: string
  buttonText?: string
  isOpen?: boolean
  error?: string
  options: { id: string; description: string }[]
}

export const EditDropdownModal = ({
  onSubmit,
  onClose,
  modalTitle,
  defaultValue,
  label,
  options,
  buttonText = 'Submit',
  isOpen = false,
  error = '',
}: EditDropdownModalProp) => {
  const classes = useStyles()
  const [value, setValue] = useState(defaultValue)
  const [isEmptyTextField, setIsEmptyTextField] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setValue(defaultValue)
    }
  }, [isOpen, setValue])

  const onCloseModal = () => {
    setIsEmptyTextField(false)
    onClose?.()
  }

  const onSubmitButton = useCallback(
    debounce((event: any) => {
      event.preventDefault()

      if (value.trim() === '') {
        setIsEmptyTextField(true)
      } else {
        setIsEmptyTextField(false)

        if (!error) {
          onSubmit(value)
        }
      }
    }, 1000),
    [value]
  )

  const onChangeDropdown = useCallback(
    (event: any) => {
      setValue(event.target.value)
    },
    [setValue]
  )

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle className={classes.modalTitleContainer} disableTypography>
          <Typography variant={'h4'} className={classes.modalTitle}>
            {modalTitle}
          </Typography>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <CloseButton onCloseModal={onCloseModal} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <CustomSelectComponent
            value={value}
            error={isEmptyTextField || !!error}
            label={label}
            name={label}
            valueName="id"
            options={options}
            isRequired
            onChange={onChangeDropdown}
            template={(item: { description: string }) => (
              <ListItemText>
                <span className={classes.listItemText}>{item.description}</span>
              </ListItemText>
            )}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={onSubmitButton}
          >
            {buttonText}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}
