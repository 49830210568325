import Cookie from 'js-cookie'
import { COOKIE_DOMAIN } from '../constants'

const getStorageData = (key) => {
  const storageString = Cookie.get(key)
  return storageString
}

const removeStorageData = (key) => {
  Cookie.remove(key, { domain: COOKIE_DOMAIN })
}

const setStorageComplicatedData = (key, complexData) => {
  const { status, ...data } = complexData
  Cookie.set(key, JSON.stringify(data), { domain: COOKIE_DOMAIN })
}

export { getStorageData, removeStorageData, setStorageComplicatedData }
