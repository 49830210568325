import {
  initialState,
  PASSWORD_REQUIREMENTS,
  RESET_PASSWORD,
} from '../../services/authorization-constants'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import every from 'lodash/every'
import valuesIn from 'lodash/valuesIn'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import logo from '../../../../assets/images/logo.jpg'
import { IMG_ALT } from '../../../../services/constants'
import actions from '../../services/authorization-actions'
import styles from './style'

const REDIRECT_TIMEOUT = 5000

const getKeyFromUrl = (key, fallback = '') => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(key) || fallback
}

const ResetPasswordComponent = ({ classes, triggerSubmit, loginError }) => {
  const login = getKeyFromUrl('email', localStorage.getItem('LOGIN_FOR_RESET'))
  const token = getKeyFromUrl('token')
  const [newPasswordState, setNewPasswordState] = useState(initialState)
  const isUpdatePassword =
    window.location.href.indexOf('/updatePassword') !== -1

  const setupNewPasswordSending = useAppSelector(
    (state) => state.authorizationReducer.setupNewPasswordSending
  )
  const setupNewPasswordSuccess = useAppSelector(
    (state) => state.authorizationReducer.setupNewPasswordSuccess
  )
  const setupNewPasswordError = useAppSelector(
    (state) => state.authorizationReducer.setupNewPasswordError
  )

  const [isSubmitted, setSubmittedFlag] = useState(false)

  const isDontMatch =
    (isSubmitted || newPasswordState.newPasswordConfirm) &&
    newPasswordState.newPassword !== newPasswordState.newPasswordConfirm

  const goToDashboard = () => {
    setTimeout(() => {
      window.location.href = '/#/login'
    }, REDIRECT_TIMEOUT)
  }

  const validatePassword = (str) => {
    const rules = {
      lowerCase: /[a-z]/.test(str),
      upperCase: /[A-Z]/.test(str),
      specialCharacter: /[@$!%*#?&_]/.test(str),
      number: /\d/.test(str),
      eightCharacters: str.length >= 8,
      space: /^\S*$/.test(str),
    }

    setNewPasswordState({
      ...newPasswordState,
      rules,
    })

    const rulesValues = valuesIn(rules)

    return every(rulesValues, (item) => item)
  }

  const onSubmit = () => {
    const { newPassword, newPasswordConfirm, givenName, familyName } =
      newPasswordState

    const body = {
      login,
      token,
      newPassword,
      givenName,
      familyName,
      onSuccess: goToDashboard,
    }

    const isNamesValid = isUpdatePassword ? givenName && familyName : true
    const isValid = validatePassword(newPassword)

    setSubmittedFlag(true)

    if (isNamesValid && isValid && newPassword === newPasswordConfirm) {
      triggerSubmit(body)
    }
  }

  const onChangeValue = (event) => {
    setNewPasswordState({
      ...newPasswordState,
      [event.target.name]: event.target.value,
    })
  }

  const {
    rules: {
      lowerCase,
      upperCase,
      specialCharacter,
      number,
      eightCharacters,
      space,
    },
    newPassword,
    newPasswordConfirm,
    givenName,
    familyName,
  } = newPasswordState

  const isTemporaryPasswordInvalid = !!loginError || (!token && isSubmitted)
  const isNewPasswordInvalid = !!isDontMatch || (!newPassword && isSubmitted)
  const isNewPasswordConfirmInvalid =
    !!isDontMatch || (!newPasswordConfirm && isSubmitted)

  return (
    <div className={classes.resetPasswordForm}>
      <img
        className={classes.resetPasswordLogo}
        alt={IMG_ALT.LOGO}
        src={logo}
      />
      <div className={classes.resetPasswordLabel}>
        {RESET_PASSWORD.resetPassword}
      </div>
      <div className={classes.description}>
        <span className={classes.stepLabel}>
          {RESET_PASSWORD.step_one_label}&nbsp;
        </span>
        {RESET_PASSWORD.step_one_description}
      </div>
      <TextField
        id="outlined-basic"
        label={RESET_PASSWORD.email}
        required
        disabled
        value={login}
        className={classes.resetPasswordInput}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        type="hidden"
        required
        name="temporaryPassword"
        autoComplete="nope"
        inputProps={{
          autoComplete: 'new-password',
        }}
        onChange={onChangeValue}
        value={token}
        error={isTemporaryPasswordInvalid}
      />
      <FormHelperText
        error
        className={
          isTemporaryPasswordInvalid ? '' : classes.helperTextInvisible
        }
      >
        {RESET_PASSWORD.temporaryPasswordIncorrect}
      </FormHelperText>
      <div className={classes.description}>
        <span className={classes.stepLabel}>
          {RESET_PASSWORD.step_two_label}&nbsp;
        </span>
        {RESET_PASSWORD.step_two_description}
      </div>
      <TextField
        id="outlined-basic"
        label={RESET_PASSWORD.newPassword}
        name="newPassword"
        required
        onChange={onChangeValue}
        type="password"
        error={isNewPasswordInvalid}
        value={newPassword}
        className={classes.resetPasswordInput}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        label={RESET_PASSWORD.confirmPassword}
        type="password"
        name="newPasswordConfirm"
        onChange={onChangeValue}
        required
        value={newPasswordConfirm}
        error={isNewPasswordConfirmInvalid}
        className={classes.resetPasswordInput}
        variant="outlined"
      />
      <FormHelperText
        error
        className={isDontMatch ? '' : classes.helperTextInvisible}
      >
        {RESET_PASSWORD.passwordsDontMatch}
      </FormHelperText>
      <div className={classes.rules}>
        <div className={classes.rulesTitle}>
          {PASSWORD_REQUIREMENTS.contain}
        </div>
        <ul className={classes.rule}>
          <li id="lower-case" className={lowerCase ? '' : classes.ruleError}>
            {PASSWORD_REQUIREMENTS.lowerCase}
          </li>
          <li id="upper-case" className={upperCase ? '' : classes.ruleError}>
            {PASSWORD_REQUIREMENTS.upperCase}
          </li>
          <li
            id="special-character"
            className={specialCharacter ? '' : classes.ruleError}
          >
            {PASSWORD_REQUIREMENTS.specialCharacter}
          </li>
          <li id="number" className={number ? '' : classes.ruleError}>
            {PASSWORD_REQUIREMENTS.number}
          </li>
          <li
            id="eight-characters"
            className={eightCharacters ? '' : classes.ruleError}
          >
            {PASSWORD_REQUIREMENTS.characters}
          </li>
          <li id="eight-characters" className={space ? '' : classes.ruleError}>
            {PASSWORD_REQUIREMENTS.space}
          </li>
        </ul>
      </div>
      {isUpdatePassword && (
        <>
          <TextField
            id="outlined-basic"
            label={RESET_PASSWORD.givenName}
            name="givenName"
            error={isSubmitted && !givenName}
            required
            onChange={onChangeValue}
            value={givenName}
            className={classes.resetPasswordInput}
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            label={RESET_PASSWORD.familyName}
            name="familyName"
            error={isSubmitted && !familyName}
            onChange={onChangeValue}
            required
            value={familyName}
            className={classes.resetPasswordInput}
            variant="outlined"
          />
        </>
      )}
      {setupNewPasswordError && (
        <Box bgcolor="warning.main" color="warning.contrastText" p={2}>
          <Typography variant="subtitle1">
            {RESET_PASSWORD.formErrorMessage}
          </Typography>
        </Box>
      )}
      {setupNewPasswordSuccess && (
        <Box bgcolor="success.main" color="success.contrastText" p={2}>
          <Typography variant="subtitle1">
            {RESET_PASSWORD.formSuccessMessage}
          </Typography>
        </Box>
      )}
      <Button
        variant="contained"
        className={classes.resetPasswordButton}
        onClick={onSubmit}
        color="primary"
        disabled={setupNewPasswordSending || setupNewPasswordSuccess}
      >
        {RESET_PASSWORD.resetPassword}
      </Button>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loginError: state.authorizationReducer.loginError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    triggerSubmit: (body) => dispatch(actions.updatePassword(body)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResetPasswordComponent))
