import { createAction } from 'redux-act'

const deviceDetailsActions = {}

deviceDetailsActions.actionTypes = {
  ADD_NEW_DEVICE: '{Device Details} Add New Device',
  ADD_NEW_DEVICE_SUCCEEDED: '{Device Details} Add New Device Succeeded',
  ADD_NEW_DEVICE_FAILED: '{Device Details} Add New Device Failed',

  GET_DEVICE_RSSI: '{Devices Details} Get Device RSSI',
  GET_DEVICE_RSSI_SUCCEEDED: '{Devices Details} Get Device RSSI Succeeded',
  GET_DEVICE_RSSI_FAILED: '{Devices Details} Get Device RSSI Failed',

  GET_EXTENDED_DEVICE: '{Device Details} Get Extended Device',
  GET_EXTENDED_DEVICE_SUCCEEDED:
    '{Device Details} Get Extended Device Succeeded',
  GET_EXTENDED_DEVICE_FAILED: '{Device Details} Get Extended Device Failed',

  GET_ACTUAL_DEVICE_DETAILS: '{Device Details} Get Actual Device Details',
  GET_ACTUAL_DEVICE_DETAILS_SUCCEEDED:
    '{Device Details} Get Actual Device Details Succeded',
  GET_ACTUAL_DEVICE_DETAILS_FAILED:
    '{Device Details} Get Actual Device Details Failed',

  GET_HISTORY_DEVICE_DETAILS: '{Device Details} Get History Device Details',
  GET_HISTORY_DEVICE_DETAILS_SUCCEEDED:
    '{Device Details} Get History Device Details Succeeded',
  GET_HISTORY_DEVICE_DETAILS_FAILED:
    '{Device Details} Get History Device Details Failed',

  UPDATE_SETTINGS: '{Device Details} Update Settings',
  UPDATE_SETTINGS_SUCCEEDED: '{Device Details} Update Settings Succeeded',
  UPDATE_SETTINGS_FAILED: '{Device Details} Update Settings Failed',

  DISABLE_DEVICE: '{Device Details} Disable Device',
  DISABLE_DEVICE_SUCCEEDED: '{Device Details} Disable Device Succeeded',
  DISABLE_DEVICE_FAILED: '{Device Details} Disable Device Failed',

  FACTORY_RESET: '{Device Details} Firmware Reset',
  FACTORY_RESET_SUCCEEDED: '{Device Details} Firmware Reset Succeeded',
  FACTORY_RESET_FAILED: '{Device Details} Firmware Reset Failed',

  GET_FULL_DEVICE_DATA: '{Device Details} Get Transaction Timeout Data',
  GET_FULL_DEVICE_DATA_SUCCEEDED:
    '{Device Details} Get Transaction Timeout Data Succeeded',
  GET_FULL_DEVICE_DATA_FAILED:
    '{Device Details} Get Transaction Timeout Data Failed',

  UPDATE_EXTENDED_DEVICE: '{Device Details} Update Extended Device',
  UPDATE_EXTENDED_DEVICE_SUCCEEDED:
    '{Device Details} Update Extended Device Succeeded',
  UPDATE_EXTENDED_DEVICE_FAILED:
    '{Device Details} Update Extended Device Failed',

  SET_DEVICE_ID: '{Device Details} Set Device Id',

  RESET_ERROR: '{Device Details} Reset Error',

  GET_EXT_DEVICE_TYPES: '{Device Details} Get External Device Types',
  GET_EXT_DEVICE_TYPES_SUCCEEDED:
    '{Device Details} Get External Device Types Succeeded',
  GET_EXT_DEVICE_TYPES_FAILED:
    '{Device Details} Get External Device Types Failed',

  GET_NETWORK_DEVICE_TYPES: '{Device Details} Get Network Device Types',
  GET_NETWORK_DEVICE_TYPES_SUCCEEDED:
    '{Device Details} Get Network Device Types Succeeded',
  GET_NETWORK_DEVICE_TYPES_FAILED:
    '{Device Details} Get Network Device Types Failed',

  GET_EXT_DEVICE_SUB_TYPES: '{Device Details} Get External Device SubTypes',
  GET_EXT_DEVICE_SUB_TYPES_SUCCEEDED:
    '{Device Details} Get External Device SubTypes Succeeded',
  GET_EXT_DEVICE_SUB_TYPES_FAILED:
    '{Device Details} Get External Device SubTypes Failed',

  RESTART_DEVICE: '{Device Details} Restart Device',
  RESTART_DEVICE_SUCCEEDED: '{Device Details} Restart Device Succeeded',
  RESTART_DEVICE_FAILED: '{Device Details} Restart Device Failed',
}

deviceDetailsActions.addNewDevice = (type, params, onSuccess) => {
  return {
    type: deviceDetailsActions.actionTypes.ADD_NEW_DEVICE,
    payload: {
      type,
      params,
      onSuccess,
    },
  }
}

deviceDetailsActions.addNewDeviceSuccess = () => {
  return {
    type: deviceDetailsActions.actionTypes.ADD_NEW_DEVICE_SUCCEEDED,
  }
}

deviceDetailsActions.addNewDeviceFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.ADD_NEW_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.getDeviceRSSI = (deviceId) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_DEVICE_RSSI,
    payload: {
      deviceId,
    },
  }
}

deviceDetailsActions.getDeviceRSSISucceeded = (data) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_DEVICE_RSSI_SUCCEEDED,
    payload: {
      data,
    },
  }
}

deviceDetailsActions.getDeviceRSSIFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_DEVICE_RSSI_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.getExtendedDevice = (id, deviceTypePath) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_EXTENDED_DEVICE,
    payload: {
      id,
      deviceTypePath,
    },
  }
}

deviceDetailsActions.getExtendedDeviceSuccess = (device) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_EXTENDED_DEVICE_SUCCEEDED,
    payload: {
      device,
    },
  }
}

deviceDetailsActions.getExtendedDeviceFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_EXTENDED_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.getActualDeviceDetails = (id, imei) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_ACTUAL_DEVICE_DETAILS,
    payload: {
      id,
      imei,
    },
  }
}

deviceDetailsActions.getActualDeviceDetailsSuccess = (data, imei, id) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_ACTUAL_DEVICE_DETAILS_SUCCEEDED,
    payload: {
      data,
      imei,
      id,
    },
  }
}

deviceDetailsActions.getActualDeviceDetailsFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_ACTUAL_DEVICE_DETAILS_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.getHistoryDeviceDetails = (imei) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_HISTORY_DEVICE_DETAILS,
    payload: {
      imei,
    },
  }
}

deviceDetailsActions.getHistoryDeviceDetailsSuccess = (
  data,
  needToLoadFullDevice
) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_HISTORY_DEVICE_DETAILS_SUCCEEDED,
    payload: {
      data,
      needToLoadFullDevice,
    },
  }
}

deviceDetailsActions.getHistoryDeviceDetailsFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_HISTORY_DEVICE_DETAILS_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.updateSettings = (id, body, onSuccess) => {
  return {
    type: deviceDetailsActions.actionTypes.UPDATE_SETTINGS,
    payload: {
      id,
      body,
      onSuccess,
    },
  }
}

deviceDetailsActions.updateSettingsSuccess = () => {
  return {
    type: deviceDetailsActions.actionTypes.UPDATE_SETTINGS_SUCCEEDED,
  }
}

deviceDetailsActions.updateSettingsFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.UPDATE_SETTINGS_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.disableDevice = (id, mode) => {
  return {
    type: deviceDetailsActions.actionTypes.DISABLE_DEVICE,
    payload: {
      id,
      mode,
    },
  }
}

deviceDetailsActions.disableDeviceSuccess = () => {
  return {
    type: deviceDetailsActions.actionTypes.DISABLE_DEVICE_SUCCEEDED,
  }
}

deviceDetailsActions.disableDeviceFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.DISABLE_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.factoryReset = (id, onSuccess) => {
  return {
    type: deviceDetailsActions.actionTypes.FACTORY_RESET,
    payload: {
      id,
      onSuccess,
    },
  }
}

deviceDetailsActions.factoryResetSuccess = () => {
  return {
    type: deviceDetailsActions.actionTypes.FACTORY_RESET_SUCCEEDED,
  }
}

deviceDetailsActions.factoryResetFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.FACTORY_RESET_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.getFullDeviceData = (imei) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_FULL_DEVICE_DATA,
    payload: {
      imei,
    },
  }
}

deviceDetailsActions.getFullDeviceDataSuccess = (data, id) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_FULL_DEVICE_DATA_SUCCEEDED,
    payload: {
      data,
      id,
    },
  }
}

deviceDetailsActions.getFullDeviceDataFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_FULL_DEVICE_DATA_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.updateExtendedDevice = (
  id,
  deviceTypePath,
  body,
  onSuccess
) => {
  return {
    type: deviceDetailsActions.actionTypes.UPDATE_EXTENDED_DEVICE,
    payload: {
      id,
      deviceTypePath,
      body,
      onSuccess,
    },
  }
}

deviceDetailsActions.updateExtendedDeviceSuccess = () => {
  return {
    type: deviceDetailsActions.actionTypes.UPDATE_EXTENDED_DEVICE_SUCCEEDED,
  }
}

deviceDetailsActions.updateExtendedDeviceFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.UPDATE_EXTENDED_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.setDeviceId = (id) => {
  return {
    type: deviceDetailsActions.actionTypes.SET_DEVICE_ID,
    payload: {
      id,
    },
  }
}

deviceDetailsActions.resetError = () => {
  return {
    type: deviceDetailsActions.actionTypes.RESET_ERROR,
  }
}

deviceDetailsActions.getExtDeviceTypes = () => {
  return {
    type: deviceDetailsActions.actionTypes.GET_EXT_DEVICE_TYPES,
  }
}

deviceDetailsActions.getExtDeviceTypesSuccess = (types) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_EXT_DEVICE_TYPES_SUCCEEDED,
    payload: {
      types,
    },
  }
}

deviceDetailsActions.getExtDeviceTypesFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_EXT_DEVICE_TYPES_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.getNetworkDeviceTypes = () => {
  return {
    type: deviceDetailsActions.actionTypes.GET_NETWORK_DEVICE_TYPES,
  }
}

deviceDetailsActions.getNetworkDeviceTypesSuccess = (types) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_NETWORK_DEVICE_TYPES_SUCCEEDED,
    payload: {
      types,
    },
  }
}

deviceDetailsActions.getNetworkDeviceTypesFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_NETWORK_DEVICE_TYPES_FAILED,
    payload: {
      message,
    },
  }
}

export const GET_SENSOR_DEVICE_TYPES = createAction(
  '{Device Details} Get Sensor Device Types'
)

export const GET_SENSOR_DEVICE_TYPES_SUCCEEDED = createAction(
  '{Device Details} Get Sensor Device Types Succeded',
  (types) => ({ types })
)

export const GET_SENSOR_DEVICE_TYPES_FAILED = createAction(
  '{Device Details} Get Sensor Device Types Failed',
  (message) => ({ message })
)

deviceDetailsActions.getExtDeviceSubTypes = (type) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_EXT_DEVICE_SUB_TYPES,
    payload: {
      type,
    },
  }
}

deviceDetailsActions.getExtDeviceSubTypesSuccess = (subTypes) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_EXT_DEVICE_SUB_TYPES_SUCCEEDED,
    payload: {
      subTypes,
    },
  }
}

deviceDetailsActions.getExtDeviceSubTypesFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.GET_EXT_DEVICE_SUB_TYPES_FAILED,
    payload: {
      message,
    },
  }
}

deviceDetailsActions.restartDevice = (id, onSuccess) => {
  return {
    type: deviceDetailsActions.actionTypes.RESTART_DEVICE,
    payload: {
      id,
      onSuccess,
    },
  }
}

deviceDetailsActions.restartDeviceSuccess = () => {
  return {
    type: deviceDetailsActions.actionTypes.RESTART_DEVICE_SUCCEEDED,
  }
}

deviceDetailsActions.restartDeviceFailed = (message) => {
  return {
    type: deviceDetailsActions.actionTypes.RESTART_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

export default deviceDetailsActions
