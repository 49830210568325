import some from 'lodash/some'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { checkConnection } from '../../../../../services/root/root-service'
import deviceDetailsActions from '../services/deviceDetails-actions'
import { ADD_DEVICE_LABELS } from '../services/deviceDetails-constants'
import SubTypesSelect from './addDeviceButton/SubTypesSelect'

const EditableExtDeviceFields = (props) => {
  const {
    state,
    setState,
    errors,
    onChange,
    extDeviceTypes,
    extDeviceSubTypes,
    getExtDeviceTypes,
    getExtDeviceSubTypes,
  } = props

  useEffect(() => {
    if (!extDeviceTypes.length) {
      getExtDeviceTypes()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // this need to check bc the user could change extDeviceType
    const selectedSubTypeExistsInArr = some(extDeviceSubTypes, {
      value: state.extDeviceSubType,
    })
    const extDeviceSubType =
      (selectedSubTypeExistsInArr && state.extDeviceSubType) ||
      extDeviceSubTypes[0]?.value

    setState({ ...state, extDeviceSubType })

    // eslint-disable-next-line
  }, [extDeviceSubTypes])

  const onChangeType = (event) => {
    onChange(event)

    getExtDeviceSubTypes(event.target.value)
  }

  return (
    <>
      <SubTypesSelect
        deviceSubType={state.extDeviceType}
        subTypesForDevice={extDeviceTypes}
        onChange={onChangeType}
        error={errors.extDeviceType}
        name="extDeviceType"
        label={ADD_DEVICE_LABELS.externalDeviceType}
      />

      <SubTypesSelect
        deviceSubType={state.extDeviceSubType}
        subTypesForDevice={extDeviceSubTypes}
        onChange={onChange}
        error={errors.extDeviceSubType}
        name="extDeviceSubType"
        label={ADD_DEVICE_LABELS.externalDeviceSubType}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const { deviceDetailsReducer } = state.devicesReducer

  return {
    extDeviceTypes: deviceDetailsReducer.extDeviceTypes,
    extDeviceSubTypes: deviceDetailsReducer.extDeviceSubTypes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getExtDeviceTypes: () =>
      dispatch(checkConnection(() => deviceDetailsActions.getExtDeviceTypes())),
    getExtDeviceSubTypes: (type) =>
      dispatch(
        checkConnection(() => deviceDetailsActions.getExtDeviceSubTypes(type))
      ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditableExtDeviceFields)
