const styles = (theme) => ({
  devicesPageContainer: {
    width: '90%',
    paddingTop: '30px',
    margin: 'auto',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  actionButton: {
    minWidth: '250px',
    marginLeft: '10px',
  },
})

export default styles
