import AccessWrapper from '../../../../components/_shared/AccessWrapper'
import { PAGES } from '../../../../services/constants'
import CreateFirmwarePage from './CreateFirmwarePage'

const CreateFirmware = (props) => {
  return (
    <AccessWrapper page={PAGES.firmware}>
      <CreateFirmwarePage {...props} isWriteOnly={true} />
    </AccessWrapper>
  )
}

export default CreateFirmware
