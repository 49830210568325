import { makeStyles, Theme } from '@material-ui/core'

import { COLORS } from '../../../../../../services/constants'

export const useStyles = makeStyles((theme: Theme) => ({
  defaultContainer: {
    boxSizing: 'border-box',
    padding: '0 32px 32px',
    border: `1px solid ${COLORS.COLD_GRAY}`,
    backgroundColor: COLORS.WHITE,
  },
  childrenContainer: {
    height: '400px',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}))
