import AppBar from '@material-ui/core/AppBar'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import logo from '../../../assets/images/logo.jpg'
import { IMG_ALT } from '../../../services/constants'
import LogoutButton from './menu/LogoutButton'
import styles from './style'

const HeaderComponent = withStyles(styles)((props) => {
  const { classes } = props

  const onClickLogo = () => {
    window.location.href = '/#/dashboard'
  }

  const isIframe = window.location !== window.parent.location

  return (
    <>
      <AppBar
        position="relative"
        color="transparent"
        className={classes.appBar}
      >
        <Toolbar className={classes.headerContainer}>
          <img
            alt={IMG_ALT.LOGO}
            src={logo}
            className={classes.logoImage}
            onClick={onClickLogo}
          />
          {}
          {!isIframe && <LogoutButton />}
        </Toolbar>
      </AppBar>
    </>
  )
})

export default HeaderComponent
