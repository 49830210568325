import {
  ARC_TYPES,
  DEVICE_TYPE,
  NETWORK_TYPES,
} from '../../../../../services/constants'
import {
  encodeString,
  escapeQuotes,
} from '../../../../../services/root/root-service'
import { isArc, isNetwork, isSensor } from '../../../services/devices-service'
import { initialNumbersOfTypes, SEARCH_FIELDS } from './devicesList-constants'

import extend from 'lodash/extend'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import forIn from 'lodash/forIn'
import indexOf from 'lodash/indexOf'
import join from 'lodash/join'
import keys from 'lodash/keys'
import map from 'lodash/map'
import toUpper from 'lodash/toUpper'
import filterQuery from '../../../../../services/filterQuery-service'

const getFullCleanupConfirmText = (serialNumber) =>
  `Are you sure you want to make full cleanup device with SN: ${serialNumber}`

const getInfoSerialNumber = (device) =>
  `Serial Number of Device: ${device.serialNumber}`

const getDeleteConfirmText = (serialNumber) =>
  `Are you sure you want to delete device with SN: ${serialNumber}`

const prepareDeviceSubTypeQuery = (type, subType) => {
  const deviceSubTypes = isArc(subType)
    ? ARC_TYPES
    : isNetwork(subType)
    ? NETWORK_TYPES
    : [subType]

  return filterQuery.getArrayStringInQuotes(
    deviceSubTypes,
    filterQuery.templates.array,
    isSensor(type) ? 'deviceType' : 'deviceSubType',
    'in'
  )
}

const prepareExtDeviceSubTypeQuery = (type, subType) => {
  const deviceType = [toUpper(type)]
  const deviceSubTypes = [subType]

  const typeQuery = filterQuery.getArrayStringInQuotes(
    deviceType,
    filterQuery.templates.array,
    'extDeviceType',
    'in'
  )

  return subType
    ? [
        typeQuery,
        filterQuery.getArrayStringInQuotes(
          deviceSubTypes,
          filterQuery.templates.array,
          'extDeviceSubType',
          'in'
        ),
      ]
    : [typeQuery]
}

const prepareFilterQuery = (params) => {
  const { deviceType, firmwareType } = params
  const filtersArray = []
  const likeQueryLabels = [
    SEARCH_FIELDS.serialNumber,
    SEARCH_FIELDS.organizationName,
    deviceType === DEVICE_TYPE.NETWORK_DEVICE.type
      ? SEARCH_FIELDS.macAddress
      : SEARCH_FIELDS.imei,
  ]

  if (firmwareType) {
    filtersArray.push(
      filterQuery.getQueryString(
        firmwareType,
        filterQuery.templates.default,
        'type',
        'eq'
      )
    )
  }

  if (params.deviceType) {
    let type
    const isArcDevice = isArc(deviceType)
    const isNetworkDevice = isNetwork(deviceType)

    if (isArcDevice) {
      type = DEVICE_TYPE.UVI_ARC.type
    } else if (isNetworkDevice) {
      type = DEVICE_TYPE.NETWORK_DEVICE.type
    } else if (deviceType === DEVICE_TYPE.FAR_UVC.type) {
      type = DEVICE_TYPE.UR_UVGI.type
    } else {
      type = deviceType
    }

    filtersArray.push(prepareDeviceSubTypeQuery(type, deviceType))
  }

  if (params.extDeviceType && deviceType === DEVICE_TYPE.EXT_DEVICE.type) {
    const [typeQuery, subTypeQuery] = prepareExtDeviceSubTypeQuery(
      params.extDeviceType,
      params.extDeviceSubType
    )

    filtersArray.push(typeQuery)

    if (subTypeQuery) {
      filtersArray.push(subTypeQuery)
    }
  }

  forIn(params, (value, key) => {
    if (likeQueryLabels.indexOf(key) !== -1 && value) {
      const isOrg = key === SEARCH_FIELDS.organizationName

      filtersArray.push(
        filterQuery.getQueryString(
          `*${isOrg ? escapeQuotes(value) : value}*`,
          filterQuery.templates.default,
          key,
          'contains'
        )
      )
    }
  })

  const queryString = filterQuery.createQueryStringFromArray(
    filtersArray,
    'and'
  )

  return queryString && `?query=${queryString}`
}

const joinDevices = (devices, prevDevices) => {
  return map(prevDevices, (item) =>
    extend(item, find(devices, { id: item.id }))
  )
}

const getDevicesTotalsQuery = (filterState) => {
  const organization = encodeString(filterState.organizationName)
  const filtersArray = []
  const likeQueryLabels = [
    SEARCH_FIELDS.serialNumber,
    SEARCH_FIELDS.organization,
    SEARCH_FIELDS.imei,
  ]
  const filterQuery = { ...filterState, organization }

  forIn(filterQuery, (value, key) => {
    if (indexOf(likeQueryLabels, key) !== -1 && value) {
      filtersArray.push(`${key}=*${value}*`)
    }
  })

  const queryString = join(filtersArray, '&')

  return queryString && `?${queryString}`
}

const transformTotalsArrayToObject = (devicesTypesCount, isExtDevicesTab) => {
  const numberOfTypes = { ...initialNumbersOfTypes }

  forEach(devicesTypesCount, (value) => {
    if (!value) {
      return
    }

    if (isArc(value.deviceType)) {
      numberOfTypes.UVI_ARC += value.count
    } else if (isNetwork(value.deviceType)) {
      numberOfTypes.NETWORK_DEVICE += value.count || 0
    } else if (isSensor(value.deviceType)) {
      numberOfTypes.SENSOR += value.count || 0
    } else if (
      !isExtDevicesTab ||
      value.deviceType !== DEVICE_TYPE.EXT_DEVICE.type
    ) {
      numberOfTypes[value.deviceType] = value.count
    }
  })

  return numberOfTypes
}

const getOptionsForSelect = (array) =>
  map(array, (item) => ({
    value: item,
    label: item,
  }))

const getExtDevicesTypesAndSubTypesOptions = (extTypesInfo, selectedType) => {
  const deviceTypes = keys(extTypesInfo)
  const deviceSubTypes = selectedType ? extTypesInfo[selectedType] : []

  return [getOptionsForSelect(deviceTypes), getOptionsForSelect(deviceSubTypes)]
}

export {
  getFullCleanupConfirmText,
  getInfoSerialNumber,
  getDeleteConfirmText,
  prepareDeviceSubTypeQuery,
  prepareFilterQuery,
  joinDevices,
  getDevicesTotalsQuery,
  transformTotalsArrayToObject,
  getExtDevicesTypesAndSubTypesOptions,
}
