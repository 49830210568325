import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  addLocationButton: {
    height: '26px',
    minWidth: 'auto',
  },
  dialogContent: {
    paddingBottom: '20px',
  },
  stateAndZip: {
    display: 'flex',
    gap: '10px',
  },
  modalTitleContainer: {
    paddingBottom: 0,
    fontSize: 'inherit',
  },
  modalTitle: {
    textAlign: 'center',
    marginTop: '14px',
    marginBottom: '6px ',
    paddingLeft: '16px',
  },
  outlined: {
    [theme.breakpoints.up('lg')]: {
      padding: '15.5px 14px',
    },
  },
  submitButton: {
    marginTop: '20px',
  },
  '@media (min-width: 1224px)': {
    submitButton: {
      width: '100%',
    },
  },
}))
