import { Box, Radio, RadioGroup, Typography } from '@material-ui/core'

import { useDispatch } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import CardWithDescription from '../../../../../../components/_shared/CardWithDescription/CardWithDescription'
import { checkConnection } from '../../../../../../services/root/root-service'
import { UPDATE_LOCATION_TREE } from '../../services/organizationDetails-actions'
import { useStyles } from '../../style'

const LEGACY_SCHEMA_ID = 1
const EXPANDED_SCHEMA_ID = 2

type LocationSchemaToggleProps = {
  id: string
  isReadOnly?: boolean
}

const LocationSchemaToggle = ({
  id,
  isReadOnly,
}: LocationSchemaToggleProps) => {
  const dispatch = useDispatch()
  const styles = useStyles()

  const locationSchemaId = useAppSelector(
    (state) => state.organizationDetailsReducer.locationSchemaId
  )

  const updateLocationTreeId = (
    organizationId: string,
    locationSchemaId: number
  ) =>
    dispatch(
      checkConnection(() =>
        UPDATE_LOCATION_TREE(organizationId, locationSchemaId)
      )
    )

  return (
    <CardWithDescription
      title="Location Tree Handling"
      description="Legacy or Expanded Location Trees"
      actionComponent={
        <Box className={styles.toggleBox}>
          <RadioGroup
            aria-disabled={isReadOnly}
            value={locationSchemaId || LEGACY_SCHEMA_ID}
            onChange={(e) => {
              if (!isReadOnly) {
                updateLocationTreeId(id, Number(e.target.value))
              }
            }}
            className={styles.toggleContainer}
          >
            <div className={styles.radioBox}>
              <div className={styles.labelContainer}>
                <Radio
                  disabled={isReadOnly}
                  value={LEGACY_SCHEMA_ID}
                  color="primary"
                  className={styles.radio}
                />
                <Typography variant="subtitle1">Legacy</Typography>
              </div>
              <div className={styles.labelContainer}>
                <Radio
                  disabled={isReadOnly}
                  value={EXPANDED_SCHEMA_ID}
                  color="primary"
                  className={styles.radio}
                />
                <Typography variant="subtitle1">Expanded</Typography>
              </div>
            </div>
          </RadioGroup>
        </Box>
      }
    />
  )
}

export default LocationSchemaToggle
