import { PAGES } from '../../../services/constants'

const dashboardButtonsConfig = [
  {
    title: 'Manage devices',
    id: 'devices',
    values: [
      {
        name: 'Total',
        propertyName: 'deviceCount',
      },
      {
        name: 'Active',
        propertyName: 'activeDeviceCount',
      },
    ],
    href: '/devices',
    page: PAGES.device,
  },
  {
    title: 'Manage customer accounts and organizations',
    id: 'users',
    values: [
      {
        name: 'Total accounts',
        propertyName: 'accountCount',
      },
      {
        name: 'Active accounts',
        propertyName: 'activeAccountCount',
      },
      {
        name: 'Total users',
        propertyName: 'userCount',
      },
      {
        name: 'Active users',
        propertyName: 'activeUserCount',
      },
    ],
    href: '/users',
    page: PAGES.users,
  },
  {
    title: 'Firmware library',
    id: 'firmware-versions',
    values: [
      {
        name: "Device's types",
        propertyName: 'deviceTypeCount',
      },
    ],
    href: '/firmwares',
    page: PAGES.firmware,
  },
  {
    title: 'Manage fleet firmware changes',
    id: 'devices-firmware',
    values: [
      {
        name: 'Scheduled firmware updates',
        propertyName: 'scheduleDeviceCount',
      },
    ],
    href: '/firmware-update/schedule',
    page: PAGES.deviceFirmware,
  },
  {
    title: 'System configuration page',
    id: 'system-configuration',
    values: [],
    href: '',
    page: PAGES.systemConfiguration,
  },
  {
    title: 'System health monitoring',
    id: 'system-health-monitoring',
    values: [
      {
        name: 'Current status',
        propertyName: 'status',
      },
      {
        name: 'Number of instances',
        propertyName: 'numberOfInstances',
      },
    ],
    href: '',
    page: PAGES.healthMonitoring,
  },
  {
    title: 'Organizations',
    id: 'organizations',
    values: [],
    href: '/organizations',
    page: PAGES.organizations,
  },
  {
    title: 'Feature Toggling',
    id: 'feature-toggling',
    values: [],
    href: '/feature/toggling',
    page: PAGES.globalFeatureToggling,
  },
  {
    title: 'Device Emulator',
    id: 'device-emulator',
    values: [],
    href: '/device/sendCommand',
    page: PAGES.deviceEmulator,
  },
  {
    title: 'Events Emulator',
    id: 'events-emulator',
    values: [],
    href: '/device/sendMessage',

    // to provide proper permissions
    page: PAGES.deviceEmulator,
  },
]

const noInformation = 'No Information'

const localhost = 'http://localhost:8080'

export { dashboardButtonsConfig, noInformation, localhost }
