import { IconButton, TableCell, TableRow } from '@material-ui/core'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useCallback } from 'react'

type ExpandableTableRowProps = {
  children: any
  expandComponent: any
  onClick?: () => any
  isExpanded: boolean
}

export const ExpandableTableRow = ({
  children,
  expandComponent,
  isExpanded,
  onClick,
}: ExpandableTableRowProps) => {
  const handleClick = useCallback(() => {
    onClick?.()
  }, [isExpanded, onClick])

  return (
    <>
      <TableRow>
        {children}

        <TableCell padding="checkbox">
          <IconButton onClick={handleClick}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {isExpanded && <TableRow>{expandComponent}</TableRow>}
    </>
  )
}
