import { Box, Button, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import {
  DETAILS_BUTTON_LABEL,
  ORGANIZATION_PAGE_LABELS,
} from '../../services/organization-constants'
import {
  UPDATE_SF_ID_START,
  UPDATE_STATUS_START,
  UPDATE_TYPE_START,
} from './services/organizationDetails-actions'

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import IdpButton from '../buttonsWithModal/IdpButton'
import ContainerWithTitle from './components/ContainerWithTitle/ContainerWithTitle'
import FeatureToggling from './components/FeatureToggling/FeatureToggling'
import Locations from './components/Locations/Locations'
import { OrganizationDetailsCard } from './components/OrganizationDetailsCard/OrganizationDetailsCard'
import WiFiCredentials from './components/WiFiCredentials/WiFiCredentials'
import { CONTAINER_TITLES } from './services/organizationDetails-constants'
import { useStyles } from './style'

const OrganizationDetails = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams<{
    id: string
  }>()

  const details = useAppSelector(
    (state) => state.organizationDetailsReducer.details
  )

  const isReadOnly = useAppSelector(
    (state) => state.organizationReducer.isReadOnly
  )

  const changeAccountOwner = () => {
    history.push(`/organizations/changeOwner/${details.id}`)
  }

  const onChangeStatus = useCallback(
    (status: boolean) => {
      dispatch(UPDATE_STATUS_START(id, status))
    },
    [dispatch, id]
  )

  const onChangeSalesforceId = useCallback(
    (salesForceId: string) => {
      dispatch(UPDATE_SF_ID_START(id, salesForceId))
    },
    [dispatch, id]
  )

  const onChangeType = useCallback(
    (type: string) => {
      dispatch(UPDATE_TYPE_START(id, type))
    },
    [dispatch, id]
  )

  return (
    <div>
      <Box textAlign="center" margin="32px 0">
        <Typography variant="h2">
          {details.name || DETAILS_BUTTON_LABEL}
        </Typography>
      </Box>

      <div className={styles.detailsWrapper}>
        <OrganizationDetailsCard
          isReadOnly={isReadOnly}
          organization={details}
          onChangeStatus={onChangeStatus}
          onChangeSalesforceId={onChangeSalesforceId}
          onChangeType={onChangeType}
        />
        <FeatureToggling id={id} isReadOnly={isReadOnly} />
        <WiFiCredentials organizationId={id} isReadOnly={isReadOnly} />
      </div>

      <div className={styles.detailsWrapper}>
        <ContainerWithTitle title={CONTAINER_TITLES.locations}>
          <Locations isReadOnly={isReadOnly} id={id} />
          {!isReadOnly && (
            <div className={styles.buttonsContainer}>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionButton}
                onClick={changeAccountOwner}
              >
                {ORGANIZATION_PAGE_LABELS.changeOwner}
              </Button>
              <IdpButton organization={details} />
            </div>
          )}
        </ContainerWithTitle>
      </div>
    </div>
  )
}

export default OrganizationDetails
