import {
  Snackbar,
  TableCell,
  TableHead,
  TableSortLabel,
} from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import {
  CHANGE_FILTER_STATE,
  GET_CUSTOMER_URL,
  GET_UNIQUE_CODE,
  RESET_CODE_AND_URL,
} from '../services/users-actions'
import { UserFilterSort, UsersFilterState } from './types'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import map from 'lodash/map'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import { checkConnection } from '../../../services/root/root-service'
import ActionsDropdown from './ActionsDropdown'
import { useStyles } from './style'
import UsersFilter from './UsersFilter'

const UsersTableComponent = () => {
  const dispatch = useDispatch()
  const styles = useStyles()

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [textForSnackbar, setTextForSnackbar] = useState<string>('')

  const users = useAppSelector((state) => state.usersReducer.users)
  const filterState = useAppSelector((state) => state.usersReducer.userFilters)
  const count = useAppSelector((state) => state.usersReducer.count)
  const code = useAppSelector((state) => state.usersReducer.code)
  const url = useAppSelector((state) => state.usersReducer.url)
  const currentEmail = useAppSelector(
    (state) => state.usersReducer.currentEmail
  )
  const isReadOnly = useAppSelector((state) => state.usersReducer.isReadOnly)

  const getUniqueCode = (id: number, email: string) =>
    dispatch(checkConnection(() => GET_UNIQUE_CODE(id, email)))
  const getCustomerUrl = () => dispatch(GET_CUSTOMER_URL())
  const resetCodeAndUrl = () => dispatch(RESET_CODE_AND_URL())
  const applyFilter = (filterState: UsersFilterState) =>
    dispatch(CHANGE_FILTER_STATE(filterState))

  const handleOpenSnackBar = (text: string) => {
    setTextForSnackbar(text)

    setOpenSnackbar(true)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const goToCustomer = (id: number, email: string) => {
    getUniqueCode(id, email)
    getCustomerUrl()
  }

  const toggleSort = (newSort: UserFilterSort) => {
    const { sort, sortDirection } = filterState
    applyFilter({
      ...filterState,
      sort: newSort,
      sortDirection:
        newSort === sort
          ? sortDirection === 'asc'
            ? 'desc'
            : 'asc'
          : sortDirection,
    })
  }

  useEffect(() => {
    return () => {
      resetCodeAndUrl()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (code && url) {
      const container = document.getElementById('container')
      if (container) {
        container.innerHTML = ''

        const link = document.createElement('a')
        link.href = `${url}/#/factory/${code}/${currentEmail}`
        link.target = '_blank'
        container.appendChild(link)
        link.click()
      }
    }
  }, [code, url])

  return (
    <>
      <div className={styles.tableContainer}>
        <UsersFilter />
        <TableContainer component={Paper}>
          <Table id="users-table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell
                  sortDirection={
                    filterState.sort === 'firstName'
                      ? filterState.sortDirection
                      : false
                  }
                >
                  <TableSortLabel
                    active={filterState.sort === 'firstName'}
                    direction={filterState.sortDirection}
                    onClick={() => toggleSort('firstName')}
                  >
                    First Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sortDirection={
                    filterState.sort === 'lastName'
                      ? filterState.sortDirection
                      : false
                  }
                >
                  <TableSortLabel
                    active={filterState.sort === 'lastName'}
                    direction={filterState.sortDirection}
                    onClick={() => toggleSort('lastName')}
                  >
                    Last Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sortDirection={
                    filterState.sort === 'roleName'
                      ? filterState.sortDirection
                      : false
                  }
                >
                  <TableSortLabel
                    active={filterState.sort === 'roleName'}
                    direction={filterState.sortDirection}
                    onClick={() => toggleSort('roleName')}
                  >
                    Role
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sortDirection={
                    filterState.sort === 'organizationName'
                      ? filterState.sortDirection
                      : false
                  }
                >
                  <TableSortLabel
                    active={filterState.sort === 'organizationName'}
                    direction={filterState.sortDirection}
                    onClick={() => toggleSort('organizationName')}
                  >
                    Organization
                  </TableSortLabel>
                </TableCell>
                <TableCell>Organization Active</TableCell>
                <TableCell
                  sortDirection={
                    filterState.sort === 'email'
                      ? filterState.sortDirection
                      : false
                  }
                >
                  <TableSortLabel
                    active={filterState.sort === 'email'}
                    direction={filterState.sortDirection}
                    onClick={() => toggleSort('email')}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>Active</TableCell>
                {!isReadOnly && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {useMemo(() => {
                return map(users || [], (user) => {
                  return (
                    <TableRow key={user.id}>
                      <TableCell>{user.id}</TableCell>
                      <TableCell>{user.firstName}</TableCell>
                      <TableCell>{user.lastName}</TableCell>
                      <TableCell>{user.roleName}</TableCell>
                      <TableCell>{user.organizationName}</TableCell>
                      <TableCell>
                        {user.organizationActive ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.active ? 'Yes' : 'No'}</TableCell>
                      {!isReadOnly && (
                        <TableCell align="center" className={styles.actions}>
                          <ActionsDropdown
                            user={user}
                            goToCustomer={goToCustomer}
                            handleOpenSnackBar={handleOpenSnackBar}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })
              }, [users])}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 30, 50]}
                  colSpan={isReadOnly ? 8 : 9}
                  count={count}
                  rowsPerPage={filterState.pageSize}
                  page={filterState.pageNum}
                  onPageChange={(event, newPage) => {
                    applyFilter({
                      ...filterState,
                      pageNum: newPage,
                    })
                  }}
                  onRowsPerPageChange={(event) => {
                    applyFilter({
                      ...filterState,
                      pageNum: 0,
                      pageSize: parseInt(event.target.value, 10),
                    })
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <div id="container"></div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={textForSnackbar}
      />
    </>
  )
}

export default UsersTableComponent
