import { Box, Tooltip } from '@material-ui/core'
import {
  DesiredSettings,
  ReportedSettings,
  SettingsMappings,
} from './constants'

import { WarningAmber } from '@mui/icons-material'
import isNil from 'lodash/isNil'
import { ReactNode } from 'react'
import { useAppSelector } from 'utils/reduxHelpers'
import { useStyles } from './style'

type WarningTooltipProps = {
  reportedKey: ReportedSettings
  desiredKey: DesiredSettings
  children: ReactNode
}

export const WarningTooltip = ({
  children,
  reportedKey,
  desiredKey,
}: WarningTooltipProps) => {
  const desiredOnTime = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredOnTime
  )
  const desiredPower = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredPower
  )
  const desiredSafetyDelay = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredSafetyDelay
  )
  const desiredOperationWindowSize = useAppSelector(
    (state) =>
      state.devicesReducer.deviceDetailsReducer.desiredOperationWindowSize
  )
  const desiredOperationWindowRuntimeMax = useAppSelector(
    (state) =>
      state.devicesReducer.deviceDetailsReducer.desiredOperationWindowRuntimeMax
  )
  const onTime = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.onTime
  )
  const power = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.power
  )
  const safetyDelay = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.safetyDelay
  )
  const operationWindowSize = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.operationWindowSize
  )
  const operationWindowRuntimeMax = useAppSelector(
    (state) =>
      state.devicesReducer.deviceDetailsReducer.operationWindowRuntimeMax
  )
  const dailyMaxRuntime = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.dailyMaxRuntime
  )
  const desiredDailyMaxRuntime = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredDailyMaxRuntime
  )

  const styles = useStyles()
  const device = {
    desiredOnTime,
    desiredPower,
    desiredSafetyDelay,
    desiredOperationWindowSize,
    desiredOperationWindowRuntimeMax,
    onTime,
    power,
    safetyDelay,
    operationWindowSize,
    operationWindowRuntimeMax,
    desiredDailyMaxRuntime,
    dailyMaxRuntime,
  }
  const reported = device[reportedKey]
  const desired = device[desiredKey]

  const shouldHideWarning =
    (!!reported && !!desired && reported === desired) ||
    (isNil(reported) && isNil(desired))

  if (shouldHideWarning) {
    return (
      <Box display="inline-flex" className={styles.warningIndicator}>
        {children}
      </Box>
    )
  }

  return (
    <Box display="inline-flex" alignItems="center" justifyContent="flex-start">
      {children}
      <Tooltip
        title={`Reported ${SettingsMappings[reportedKey]} (${
          reported || 'Unknown'
        }) value is out of sync with the Desired ${
          SettingsMappings[reportedKey]
        } value (${desired || 'Unknown'}).`}
        placement="top"
      >
        <WarningAmber className={styles.warningIndicator} />
      </Tooltip>
    </Box>
  )
}
