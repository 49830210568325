import { call, fork, put, select, takeEvery } from 'redux-saga/effects'

import { HttpClient } from '../../../../services/HttpClient'
import uvgiEmulatorActions from './uvgiEmulator-actions'

const getActiveTab = (state) =>
  state.eventsEmulatorReducer.sharedEmulatorReducer.tab
const getOrganization = (state) =>
  state.eventsEmulatorReducer.sharedEmulatorReducer.selectedOrganization

function* sendDeviceMessage(action) {
  try {
    const { params, body } = action.payload
    const { id } = yield select(getOrganization)
    const activeTab = yield select(getActiveTab)

    const data = yield call(() => {
      return HttpClient.post(
        `/api/secured/uvgi/admin/test/message/send?organizationId=${id}&${params}`,
        { body }
      )
    })

    if (data.error) {
      yield put(uvgiEmulatorActions.sendDeviceMessageFailed(data.error))
    } else {
      yield put(
        uvgiEmulatorActions.sendDeviceMessageSuccess(data.message, activeTab)
      )
    }
  } catch (e) {
    yield put(uvgiEmulatorActions.sendDeviceMessageFailed(e.message))
  }
}

function* loadAllLocations() {
  try {
    const { id } = yield select(getOrganization)

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/locations/?orgId=${id}`)
    })

    if (data.message) {
      yield put(uvgiEmulatorActions.loadLocationsFailed(data.message))
    } else {
      yield put(uvgiEmulatorActions.loadAllLocationsSuccess(data))
    }
  } catch (e) {
    yield put(uvgiEmulatorActions.loadLocationsFailed(e.message))
  }
}

function* loadUVGIDevices(action) {
  try {
    const { name } = yield select(getOrganization)
    const activeTab = yield select(getActiveTab)

    const body = {
      name,
      featureKeys: [activeTab],
    }

    const data = yield call(() => {
      return HttpClient.post(`/api/secured/devices/organization`, { body })
    })

    if (data.message) {
      yield put(uvgiEmulatorActions.loadUVGIDevices(data.message))
    } else {
      yield put(uvgiEmulatorActions.loadUVGIDevicesSuccess(data))
    }
  } catch (e) {
    yield put(uvgiEmulatorActions.loadUVGIDevicesFailed(e.message))
  }
}

function* sendDeviceMessageSaga() {
  yield takeEvery(
    uvgiEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE,
    sendDeviceMessage
  )
}

function* loadAllMessageLocationsSaga() {
  yield takeEvery(
    uvgiEmulatorActions.actionTypes.LOAD_UVGI_DEVICES_SUCCEEDED,
    loadAllLocations
  )
}

function* loadMessageDevicesSaga() {
  yield takeEvery(
    uvgiEmulatorActions.actionTypes.LOAD_UVGI_DEVICES,
    loadUVGIDevices
  )
}

export default function* eventsUVGIEmulatorSaga() {
  yield fork(sendDeviceMessageSaga)
  yield fork(loadMessageDevicesSaga)
  yield fork(loadAllMessageLocationsSaga)
}
