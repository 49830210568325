import { TableCell } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { connect } from 'react-redux'
import AllowListTableRow from './AllowListTableRow'
import { TABLE_COLUMNS } from './services/AllowList-constants'

const AllowListTable = (props) => {
  const { allowList, hubId } = props

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" key="placeholder-index" />
            <TableCell align="left">{TABLE_COLUMNS.serialNumber}</TableCell>
            <TableCell align="left">{TABLE_COLUMNS.deviceType}</TableCell>
            <TableCell align="left">{TABLE_COLUMNS.deviceSubType}</TableCell>
            <TableCell align="left" key="placeholder-action" />
          </TableRow>
        </TableHead>
        <TableBody>
          {allowList.map((device, index) => (
            <AllowListTableRow
              hubId={hubId}
              key={`allow-list-${device.id}`}
              index={index + 1}
              device={device}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = (state) => {
  const { devicesReducer } = state

  return {
    allowList: devicesReducer?.allowListReducer.allowList || [],
  }
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AllowListTable)
