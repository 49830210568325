import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { REQUEST_PARAMETERS_LABELS } from '../../ARC/services/arcEmulator-constants'
import styles from '../../style'

const RequestParametersComponent = (props) => {
  const { classes, isSubmitted, changeValue, imei, softwareRevision } = props

  const onChangeValue = (event) => {
    changeValue(event, event.target.name)
  }

  return (
    <div className={classes.firmWareUpdateContainer}>
      <TextField
        id="outlined-basic"
        name="imei"
        value={imei}
        onChange={onChangeValue}
        required
        error={!imei && isSubmitted}
        label={REQUEST_PARAMETERS_LABELS.imei}
        variant="outlined"
      />
      <TextField
        id="outlined-basic"
        name="softwareRevision"
        value={softwareRevision}
        onChange={onChangeValue}
        inputProps={{ maxLength: 255 }}
        label={REQUEST_PARAMETERS_LABELS.softwareRevision}
        variant="outlined"
      />
    </div>
  )
}

export default withStyles(styles)(RequestParametersComponent)
