import AccessWrapper from '../../components/_shared/AccessWrapper'
import { PAGES } from '../../services/constants'
import FeatureTogglingPage from './FeatureTogglingPage'

const FeatureToggling = (props) => {
  return (
    <AccessWrapper page={PAGES.globalFeatureToggling}>
      <FeatureTogglingPage {...props} />
    </AccessWrapper>
  )
}

export default FeatureToggling
