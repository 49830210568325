const firmwaresActions = {}

firmwaresActions.actionTypes = {
  LOAD_FIRMWARES: '{FIRMWARES} Load Firmwares',
  LOAD_FIRMWARES_SUCCESS: '{FIRMWARES} Load Firmwares Success',
  LOAD_FIRMWARES_FAILED: '{FIRMWARES} Load Firmwares Failed',

  UPLOAD_FIRMWARE: '{FIRMWARES} Upload Firmware',
  UPLOAD_FIRMWARE_SUCCESS: '{FIRMWARES} Upload Firmware Success',
  UPLOAD_FIRMWARE_FAILED: '{FIRMWARES} Upload Firmware Failed',

  DELETE_FIRMWARE: '{FIRMWARES} Delete Firmware',
  DELETE_FIRMWARE_SUCCESS: '{FIRMWARES} Delete Firmware Success',
  DELETE_FIRMWARE_FAILED: '{FIRMWARES} Delete Firmware Failed',

  RESET_ERROR: '{FIRMWARES} Reset Error',

  UPDATE_DEFAULT_FIRMWARE: '{FIRMWARES} Update Default Firmware',
  UPDATE_DEFAULT_FIRMWARE_FAILED: '{FIRMWARES} Update Default Firmware Failed',

  GET_DEFAULT_FIRMWARES: '{FIRMWARES} Get Default Firmwares',
  GET_DEFAULT_FIRMWARES_SUCCESS: '{FIRMWARES} Get Default Firmwares Success',
  GET_DEFAULT_FIRMWARES_FAILED: '{FIRMWARES} Get Default Firmwares Failed',

  LOAD_FIRMWARE_TYPES: '{FIRMWARES} Load Firmware Types',
  LOAD_FIRMWARE_TYPES_SUCCEEDED: '{FIRMWARES} Load Firmware Types Succeeded',
  LOAD_FIRMWARE_TYPES_FAILED: '{FIRMWARES} Load Firmware Types Failed',

  LOAD_FIRMWARE_TYPES_FOR_DEVICE: '{FIRMWARES} Load Firmwares For Device',
  LOAD_FIRMWARE_TYPES_FOR_DEVICE_SUCCEEDED:
    '{FIRMWARES} Load Firmwares For Device Succeeded',
  LOAD_FIRMWARE_TYPES_FOR_DEVICE_FAILED:
    '{FIRMWARES} Load Firmwares For Device Failed',

  LOAD_VERSIONS_FOR_FIRMWARE_TYPE: '{FIRMWARES} Load Versions For Firmware',
  LOAD_VERSIONS_FOR_FIRMWARE_TYPE_SUCCEEDED:
    '{FIRMWARES} Load Versions For Firmware Succeeded',
  LOAD_VERSIONS_FOR_FIRMWARE_TYPE_FAILED:
    '{FIRMWARES} Load Versions For Firmware Failed',

  RESET_VERSIONS_FOR_FIRMWARE_TYPE:
    '{FIRMWARES} Reset Versions For Firmware Type',
}

firmwaresActions.loadFirmwares = () => {
  return {
    type: firmwaresActions.actionTypes.LOAD_FIRMWARES,
  }
}

firmwaresActions.loadFirmwaresSuccess = (firmwares) => {
  return {
    type: firmwaresActions.actionTypes.LOAD_FIRMWARES_SUCCESS,
    payload: {
      firmwares,
    },
  }
}

firmwaresActions.loadFirmwaresFailed = (message) => ({
  type: firmwaresActions.actionTypes.LOAD_FIRMWARES_FAILED,
  payload: {
    message,
  },
})

firmwaresActions.uploadFirmware = (body, back) => {
  return {
    type: firmwaresActions.actionTypes.UPLOAD_FIRMWARE,
    payload: {
      body,
      back,
    },
  }
}

firmwaresActions.uploadFirmwareSuccess = () => ({
  type: firmwaresActions.actionTypes.UPLOAD_FIRMWARE_SUCCESS,
})

firmwaresActions.uploadFirmwareFailed = (message) => {
  return {
    type: firmwaresActions.actionTypes.UPLOAD_FIRMWARE_FAILED,
    payload: {
      message,
    },
  }
}

firmwaresActions.deleteFirmware = (id, onSuccess) => {
  return {
    type: firmwaresActions.actionTypes.DELETE_FIRMWARE,
    payload: {
      id,
      onSuccess,
    },
  }
}

firmwaresActions.deleteFirmwareSuccess = () => ({
  type: firmwaresActions.actionTypes.DELETE_FIRMWARE_SUCCESS,
})

firmwaresActions.deleteFirmwareFailed = (message) => {
  return {
    type: firmwaresActions.actionTypes.DELETE_FIRMWARE_FAILED,
    payload: {
      message,
    },
  }
}

firmwaresActions.resetError = () => {
  return {
    type: firmwaresActions.actionTypes.RESET_ERROR,
  }
}

firmwaresActions.updateDefaultFirmware = (id, unset) => {
  return {
    type: firmwaresActions.actionTypes.UPDATE_DEFAULT_FIRMWARE,
    payload: {
      id,
      unset,
    },
  }
}

firmwaresActions.updateDefaultFirmwareFailed = (message) => ({
  type: firmwaresActions.actionTypes.UPDATE_DEFAULT_FIRMWARE_FAILED,
  payload: {
    message,
  },
})

firmwaresActions.getDefaultFirmwares = (deviceType) => ({
  type: firmwaresActions.actionTypes.GET_DEFAULT_FIRMWARES,
  payload: {
    deviceType,
  },
})

firmwaresActions.getDefaultFirmwaresSuccess = (allDefaultFirmwares) => ({
  type: firmwaresActions.actionTypes.GET_DEFAULT_FIRMWARES_SUCCESS,
  payload: {
    allDefaultFirmwares,
  },
})

firmwaresActions.getDefaultFirmwaresFailed = (message) => ({
  type: firmwaresActions.actionTypes.GET_DEFAULT_FIRMWARES_FAILED,
  payload: {
    message,
  },
})

firmwaresActions.loadFirmwareTypesForDevice = (deviceType, deviceSubType) => {
  return {
    type: firmwaresActions.actionTypes.LOAD_FIRMWARE_TYPES_FOR_DEVICE,
    payload: {
      deviceType,
      deviceSubType,
    },
  }
}

firmwaresActions.loadFirmwareTypesForDeviceSuccess = (
  firmwareTypesForDevice
) => {
  return {
    type: firmwaresActions.actionTypes.LOAD_FIRMWARE_TYPES_FOR_DEVICE_SUCCEEDED,
    payload: {
      firmwareTypesForDevice,
    },
  }
}

firmwaresActions.loadFirmwareTypesForDeviceFailed = (message) => {
  return {
    type: firmwaresActions.actionTypes.LOAD_FIRMWARE_TYPES_FOR_DEVICE_FAILED,
    payload: {
      message,
    },
  }
}

firmwaresActions.loadVersionsForFirmwareType = (
  deviceType,
  deviceSubType,
  firmwareType
) => {
  return {
    type: firmwaresActions.actionTypes.LOAD_VERSIONS_FOR_FIRMWARE_TYPE,
    payload: {
      deviceType,
      deviceSubType,
      firmwareType,
    },
  }
}

firmwaresActions.loadVersionsForFirmwareTypeSuccess = (versions) => {
  return {
    type: firmwaresActions.actionTypes
      .LOAD_VERSIONS_FOR_FIRMWARE_TYPE_SUCCEEDED,
    payload: {
      versions,
    },
  }
}

firmwaresActions.loadVersionsForFirmwareTypeFailed = (message) => {
  return {
    type: firmwaresActions.actionTypes.LOAD_VERSIONS_FOR_FIRMWARE_TYPE_FAILED,
    payload: {
      message,
    },
  }
}

firmwaresActions.resetVersionsForFirmwareType = () => {
  return {
    type: firmwaresActions.actionTypes.RESET_VERSIONS_FOR_FIRMWARE_TYPE,
  }
}

export default firmwaresActions
