import { TableCell } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import classNames from 'classnames'
import map from 'lodash/map'
import toString from 'lodash/toString'
import { connect } from 'react-redux'
import ErrorHasOccurred from '../../../../components/_shared/errors/ErrorHasOccurred'
import { DEVICE_TYPE_LABELS } from '../../../../services/constants'
import { utcConverter } from '../../../../services/dateFormat-service'
import { FIRMWARES_TABLE_LABELS } from '../../services/firmwares-constants'
import DeleteFirmwareButton from '../DeleteFirmwareButton'
import styles from './style'

const FirmwaresTableComponent = (props) => {
  const {
    firmwares,
    error,
    classes,
    loadFirmwaresError,
    isReadOnly,
    firmwaresList,
  } = props

  const getTableData = () => {
    return map(firmwares, (firmware) => {
      const actualFirmware = (firmwaresList || []).find(
        (actualFirmware) => Number(firmware.id) === Number(actualFirmware.id)
      )

      return (
        <TableRow key={firmware.id}>
          <TableCell className="firmware-type" align="center">
            {firmware.type}
          </TableCell>
          <TableCell className="firmware-version" align="center">
            {firmware.version}
          </TableCell>
          <TableCell
            className={classNames(classes.modifiedCell, 'modified-date')}
            align="left"
          >
            {utcConverter(firmware.modified)}
          </TableCell>
          <TableCell className="device-type" align="center">
            {DEVICE_TYPE_LABELS[firmware.deviceSubType]}
          </TableCell>
          <TableCell className="default-flag" align="center">
            {toString(actualFirmware?.isDefault || false)}
          </TableCell>
          {!isReadOnly && (
            <TableCell className={classes.actionCell} align="center">
              <DeleteFirmwareButton
                type={firmware.type}
                className="delete-firmware-button"
                version={firmware.version}
                error={error}
                id={firmware.id}
              />
            </TableCell>
          )}
        </TableRow>
      )
    })
  }

  return loadFirmwaresError ? (
    <ErrorHasOccurred hideLogo={true} />
  ) : (
    <div className={classes.table}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" id="firmware-versions-table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {FIRMWARES_TABLE_LABELS.firmwareType}
              </TableCell>
              <TableCell align="center">
                {FIRMWARES_TABLE_LABELS.version}
              </TableCell>
              <TableCell align="left" className={classes.modifiedCell}>
                {FIRMWARES_TABLE_LABELS.modified}
              </TableCell>
              <TableCell align="center">
                {FIRMWARES_TABLE_LABELS.deviceType}
              </TableCell>
              <TableCell align="center">
                {FIRMWARES_TABLE_LABELS.default}
              </TableCell>
              {!isReadOnly && <TableCell align="center" />}
            </TableRow>
          </TableHead>
          <TableBody>{getTableData()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const mapStateToProps = (state) => ({
  firmwares: state.firmwaresReducer.firmwaresList,
  activeFirmwares: state.firmwaresReducer.activeFirmwares,
  error: state.firmwaresReducer.error,
  loadFirmwaresError: state.firmwaresReducer.loadFirmwaresError,
  firmwaresList: state.firmwaresReducer.firmwaresList,
})

export default connect(mapStateToProps)(
  withStyles(styles)(FirmwaresTableComponent)
)
