import { TableCell } from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import { Device } from 'containers/Devices/services/devices-types'
import { Link } from 'react-router-dom'
import { DEVICE_TYPE } from 'services/constants'
import { useStyles } from '../styles'

type DeviceEditLinkCellProps = Pick<Device, 'id' | 'deviceType'> & {}

export const DeviceEditLinkCell = ({
  id,
  deviceType,
}: DeviceEditLinkCellProps) => {
  const style = useStyles()
  return (
    <TableCell align="center">
      <Link
        to={`/devices/edit/${
          DEVICE_TYPE[deviceType as keyof typeof DEVICE_TYPE].path
        }/${id}`}
        className={style.tableLink}
      >
        <Settings />
      </Link>
    </TableCell>
  )
}
