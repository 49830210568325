import {
  GET_SUBLOCATIONS_COUNT,
  RESET_ERROR,
} from '../../../services/organizationDetails-actions'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { checkConnection } from '../../../../../../../services/root/root-service'
import { DELETE_LOCATION_LABELS } from '../../../services/organizationDetails-constants'
import DeleteModalWithInputComponent from './DeleteModalWithInput'
import { useStyles } from './style'

type DeleteLocationButtonProps = {
  id: string
  parentId: string
  name: string
}

const DeleteLocationButton = ({
  id,
  parentId,
  name,
}: DeleteLocationButtonProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)

  const resetError = () => dispatch(RESET_ERROR())
  const getSubLocationsCount = (id: string) =>
    dispatch(checkConnection(() => GET_SUBLOCATIONS_COUNT(id)))

  const handleOpen = () => {
    getSubLocationsCount(id)

    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)

    resetError()
  }

  return (
    <div>
      <IconButton className={styles.rowIconButton} onClick={handleOpen}>
        <CloseIcon className={styles.icon} />
      </IconButton>
      <DeleteModalWithInputComponent
        isOpen={open}
        title={DELETE_LOCATION_LABELS.warningTitle}
        handleClose={handleClose}
        id={id}
        parentId={parentId}
        name={name}
      />
    </div>
  )
}

export default DeleteLocationButton
