import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import map from 'lodash/map'
import { connect } from 'react-redux'
import { checkConnection } from '../../../../services/root/root-service'
import actions from '../../services/firmwares-actions'
import { FIRMWARES_LABELS } from '../../services/firmwares-constants'
import styles from './style'

const ChangeDefaultVersionModal = withStyles(styles)((props) => {
  const {
    open,
    onClose,
    error,
    versionsForFirmwareType,
    loadVersionsForFirmwareType,
    resetVersionsForFirmwareType,
    updateDefaultFirmware,
    firmware,
    firmware: { firmwareType },
    deviceType,
    deviceSubType,
    classes,
    firmwaresList,
  } = props
  const actualFirmware = firmwaresList.find(
    (actualFirmware) =>
      actualFirmware.id === firmware.id && actualFirmware.isDefault
  )
  const [version, setVersion] = useState(actualFirmware?.id)

  useEffect(() => {
    if (open) {
      loadVersionsForFirmwareType(deviceType, deviceSubType, firmwareType)
    } else {
      resetVersionsForFirmwareType()
    }
    // eslint-disable-next-line
  }, [open])

  const handleClose = () => {
    setVersion(firmware.id)

    onClose()
  }

  const handleChange = (event) => {
    let firmwareId = null
    if (event.target.value) {
      const actualFirmware = firmwaresList.find(
        (firmware) => (firmware.id = Number(event.target.value))
      )

      firmwareId = actualFirmware?.id
    }
    setVersion(firmwareId ? Number(firmwareId) : firmwareId)
  }

  const onSubmit = () => {
    updateDefaultFirmware(!version ? firmware.id : version, !version)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <IconButton
          className={classes.statusFilerIcon}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4">{FIRMWARES_LABELS.versions}</Typography>
        <Typography variant="subtitle1" color="error" className={classes.error}>
          {error}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormControl component="fieldset" className={classes.versionsList}>
          <RadioGroup
            aria-label="versions"
            value={version}
            onChange={handleChange}
            id="change-default-firmware-options"
          >
            {map(versionsForFirmwareType || [], (item) => {
              const isCurrent = version === item.id

              return (
                <FormControlLabel
                  checked={isCurrent}
                  key={item.id}
                  value={item.id}
                  control={<Radio disableRipple />}
                  label={`${item.version} ${
                    isCurrent ? FIRMWARES_LABELS.current : ''
                  }`}
                />
              )
            })}
            <FormControlLabel
              checked={!version}
              value={null}
              control={<Radio disableRipple />}
              label="None"
            />
          </RadioGroup>
        </FormControl>
        <Button
          id="change-default-firmware-submit"
          variant="contained"
          color="primary"
          disabled={actualFirmware?.id === version}
          onClick={onSubmit}
          className={classes.actionButton}
        >
          {FIRMWARES_LABELS.defaultActive}
        </Button>
      </DialogContent>
    </Dialog>
  )
})

const maStateToProps = (state) => ({
  error: state.firmwaresReducer.error,
  versionsForFirmwareType: state.firmwaresReducer.versionsForFirmwareType,
  devicesTypes: state.firmwaresReducer.devicesTypes,
  subTypesForDevice: state.firmwaresReducer.subTypesForDevice,
  firmwareTypesForDevice: state.firmwaresReducer.firmwareTypesForDevice,
  firmwaresList: state.firmwaresReducer.firmwaresList,
})

const mapDispatchToProps = (dispatch) => ({
  updateDefaultFirmware: (id, unset, onClose) =>
    dispatch(
      checkConnection(() => actions.updateDefaultFirmware(id, unset, onClose))
    ),
  loadVersionsForFirmwareType: (deviceType, deviceSubType, firmwareTyp) =>
    dispatch(
      checkConnection(() =>
        actions.loadVersionsForFirmwareType(
          deviceType,
          deviceSubType,
          firmwareTyp
        )
      )
    ),
  resetVersionsForFirmwareType: () =>
    dispatch(actions.resetVersionsForFirmwareType()),
})

export default connect(
  maStateToProps,
  mapDispatchToProps
)(ChangeDefaultVersionModal)
