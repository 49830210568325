import { Location } from 'containers/Organizations/components/Details/services/organizationDetails-types'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import sortedIndexBy from 'lodash/sortedIndexBy'

const prepareProperties = (params: { nodes: Location[] }) => {
  const { nodes } = params
  const properties = [...nodes]

  map(properties, (item) => {
    item.expanded = false
  })

  return properties
}

const changeExpanded = (
  parentId: string,
  newList: Location[],
  locations: Location[]
) => {
  const tree = [...locations]
  let found = false

  const traverse = function (current: Location[]) {
    forEach(current, (item, key) => {
      if (!found) {
        if (item.id === parentId) {
          item.expanded = !item.expanded

          if (!item.children?.length) {
            item.children = [...newList]
            item.hasChild = true
          }

          found = true
        }

        if (item.children?.length) {
          traverse(item.children)
        }
      }
    })
  }

  traverse(tree)

  return tree
}

const shrinkNode = (id: string, locations: Location[]) => {
  const tree = [...locations]
  let found = false

  const traverse = function (current: Location[]) {
    forEach(current, (item, key) => {
      if (!found) {
        if (item.id === id) {
          item.expanded = !item.expanded
          found = true
        }

        if (item.children?.length) {
          traverse(item.children)
        }
      }
    })
  }

  traverse(tree)

  return tree
}

const updateTree = (nodes: Location[], params: Location, action: string) => {
  let newTree = cloneDeep(nodes)

  switch (action) {
    case 'add':
      newTree = addLocation(nodes, params)
      break
    case 'delete':
      newTree = deleteLocation(nodes, params)
      break
    case 'edit':
      newTree = editLocation(nodes, params)
      break
    default:
      return newTree
  }

  return newTree
}

const updateTreeBulk = (nodes: Location[], params: Location[]) => {
  return bulkAddLocations(nodes, params)
}

const addLocation = (nodes: Location[], location: Location) => {
  const { parentId } = location
  const nodeList = [...nodes]

  let isLocAdded = false

  if (!parentId) {
    const value = {
      ...location,
      children: [],
      hasChild: false,
    }
    const index = sortedIndexBy(nodeList, value, 'name')

    nodeList.splice(index, 0, value)
  } else {
    const traverse = function (current: Location[]) {
      forEach(current, (item) => {
        if (item.id === parentId) {
          isLocAdded = true

          item.hasChild = true

          if (item.children) {
            const index = sortedIndexBy(item.children, location, 'name')

            item.children.splice(index, 0, location)
          } else {
            item.children = [location]
          }
        } else if (item.hasChild && !isLocAdded) {
          traverse(item.children)
        }
      })
    }

    traverse(nodeList)
  }

  return nodeList
}

const bulkAddLocations = (nodes: Location[], locations: Location[]) => {
  let nodeList = [...nodes]

  let isLocAdded = false

  if (!locations[0].parentId) {
    const childs = map(locations, (item) => {
      return {
        ...item,
        children: [],
        hasChild: false,
      }
    })

    const updatedList = [...nodeList, ...childs]
    nodeList = sortBy(updatedList, ['name'])
  } else {
    const traverse = function (current: Location[]) {
      forEach(current, (item) => {
        if (item.id === locations[0].parentId) {
          const childs = map(locations, (loc) => {
            isLocAdded = true

            return {
              ...loc,
              children: [],
              hasChild: false,
            }
          })

          item.hasChild = true

          if (item.children) {
            const updatedList = [...item.children, ...childs]

            item.children = sortBy(updatedList, ['name'])
          } else {
            item.children = sortBy(childs, ['name'])
          }
        } else if (item.hasChild && !isLocAdded) {
          traverse(item.children)
        }
      })
    }

    traverse(nodeList)
  }

  return nodeList
}

const deleteLocation = (nodes: Location[], params: Location) => {
  const { id, parentId } = params
  const nodeList = [...nodes]
  let isLocDeleted = false

  const traverse = function (current: Location[]) {
    forEach(current, (item, key) => {
      if (!isLocDeleted) {
        if (parentId) {
          // delete child location
          if (+item.id === +parentId) {
            isLocDeleted = true

            item.children = filter(item.children, (child) => {
              return child.id !== id
            })

            if (!item.children.length) {
              item.hasChild = false
            }
          } else if (item.hasChild && !isLocDeleted) {
            traverse(item.children)
          }
        } else {
          // delete property
          if (+item.id === +id) {
            isLocDeleted = true
            nodeList.splice(key, 1)
          }
        }
      }
    })
  }

  traverse(nodeList)

  return nodeList
}

const editLocation = (nodes: Location[], location: Location) => {
  const { id } = location
  const nodeList = [...nodes]
  let isLocEdited = false

  const traverse = function (current: Location[]) {
    forEach(current, (item, index) => {
      if (!isLocEdited) {
        if (item.id === id) {
          isLocEdited = true

          current.splice(index, 1)

          const neededIndex = sortedIndexBy(current, location, 'name')
          current.splice(neededIndex, 0, location)
        } else if (item.hasChild) {
          traverse(item.children)
        }
      }
    })
  }

  traverse(nodeList)

  return nodeList
}

export {
  changeExpanded,
  shrinkNode,
  prepareProperties,
  updateTree,
  updateTreeBulk,
}
