import { withStyles } from '@material-ui/core/styles'
import RequestParameters from '../../_shared/components/RequestParameters'
import DeviceInfoTable from './DeviceInfoTable'
import styles from './style'

const UVGIRequestParameters = (props) => {
  const {
    changeValue,
    changeDateValue,
    imei,
    isSubmitted,
    softwareRevision,
    commDevicesInfo,
    certificatesInfo,
  } = props

  return (
    <>
      <RequestParameters
        softwareRevision={softwareRevision}
        changeValue={changeValue}
        imei={imei}
        isSubmitted={isSubmitted}
      />
      <DeviceInfoTable
        changeDateValue={changeDateValue}
        isSubmitted={isSubmitted}
        certificatesInfo={certificatesInfo}
        commDevicesInfo={commDevicesInfo}
      />
    </>
  )
}

export default withStyles(styles)(UVGIRequestParameters)
