import {
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  COMMANDS_RECEIVER,
  KEY_TYPES,
} from '../../../services/emulator-constants'

import { withStyles } from '@material-ui/core/styles'
import entries from 'lodash/entries'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import uniqueId from 'lodash/uniqueId'
import { useState } from 'react'
import { connect } from 'react-redux'
import actions from '../../../services/emulator-actions'
import { generateTableKeys } from '../../../services/emulator-service'
import styles from '../style'

const CommandDataTemplate = (props) => {
  const {
    count,
    commandData,
    applyCommands,
    imei,
    id,
    devicePortalURL,
    classes,
  } = props

  const [resultValue, setResultValue] = useState({ ...commandData.value })
  const [status, setStatus] = useState('')

  const handleResultChange = (event) => {
    setResultValue({
      ...resultValue,
      [event.target.name]: event.target.value,
    })
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
  }

  const handleCommandRefresh = () => {
    const body = [
      {
        uuid: commandData.uuid,
        results: map(resultValue, (value, key) => ({
          name: key,
          value,
        })),
        status,
      },
    ]

    applyCommands(body, imei, id, devicePortalURL)
  }

  const isSinglePropNameDifference =
    !isEmpty(commandData.value) &&
    isUndefined(commandData.value[commandData.name])

  const notOriginalPropName =
    entries(commandData.value).length > 1 || isSinglePropNameDifference

  const extendedTableKeys =
    notOriginalPropName && generateTableKeys(commandData)

  const generateValueCard = (item) => {
    const cellName = item.cellName || item
    const propName = item.name || commandData.name

    let valueCard

    switch (cellName) {
      case KEY_TYPES.result:
        valueCard = (
          <div className={classes.valueItemEditable}>
            <TextField
              type="text"
              name={propName}
              value={resultValue[propName]}
              onChange={handleResultChange}
              disabled={isEmpty(commandData.value)}
            />
          </div>
        )

        break

      case KEY_TYPES.status:
        valueCard = (
          <div className={classes.valueItemEditable}>
            <Select value={status} onChange={handleStatusChange}>
              {map(COMMANDS_RECEIVER.statuses, (status) => (
                <MenuItem value={status} key={uniqueId()}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </div>
        )

        break

      default:
        const value = !isEmpty(commandData[item])
          ? commandData[item][commandData.name]
          : '{}'

        valueCard = (
          <div className={classes.valueItem}>
            {`${
              item !== KEY_TYPES.value ? item.value || commandData[item] : value
            }`}
          </div>
        )

        break
    }

    return valueCard
  }

  const tableKeysForCommandRow = notOriginalPropName
    ? extendedTableKeys
    : COMMANDS_RECEIVER.tableKeys

  const commandRow = map(tableKeysForCommandRow, (item, ind) => (
    <div className={classes.rowItem} key={`${commandData.uuid}__${ind}`}>
      <div className={classes.keyItem}>{item.cellName || item}</div>
      {generateValueCard(item)}
    </div>
  ))

  return (
    <div className={classes.commandDataWrapper}>
      <div className={classes.rowHeader}>
        <Typography variant="subtitle2" align="center">
          {COMMANDS_RECEIVER.commandTitle(count)}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.postButton}
          onClick={handleCommandRefresh}
        >
          {COMMANDS_RECEIVER.postReport}
        </Button>
      </div>
      <div className={classes.commandData}>{commandRow}</div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  applyCommands: (data, deviceImei, deviceId, devicePortalURL) =>
    dispatch(
      actions.applyCommands(data, deviceImei, deviceId, devicePortalURL)
    ),
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(CommandDataTemplate))
