import { RouteComponentProps } from 'react-router-dom'
import AccessWrapper from '../../../../components/_shared/AccessWrapper'
import { PAGES } from '../../../../services/constants'
import AddOrganizationPage from './AddOrganizationPage'

const AddOrganization = (props: RouteComponentProps) => {
  return (
    <AccessWrapper page={PAGES.organizations}>
      <AddOrganizationPage {...props} />
    </AccessWrapper>
  )
}

export default AddOrganization
