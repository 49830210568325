import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  usersPageContainer: {
    width: '90%',
    paddingTop: '30px',
    margin: 'auto',
  },
  userActionBtn: {
    minWidth: '200px',
    marginRight: '20px',
  },
  '@media (min-width: 1224px)': {},
}))
