import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import organizationActions, {
  SAVE_ORGANIZATION_DATA_ERROR,
  SAVE_ORGANIZATION_DATA_INIT,
  SAVE_ORGANIZATION_DATA_SUCCESS,
  TIMEZONES_BY_ZIP_ERROR,
  TIMEZONES_BY_ZIP_INIT,
  TIMEZONES_BY_ZIP_SUCCESS,
} from './organization-actions'
import {
  prepareAccountOwnerQuery,
  prepareAdminsQuery,
} from './organization-service'

import { HttpClient } from '../../../services/HttpClient'
import { ADD_ORGANIZATIONS_ERRORS } from './organization-constants'

export const getAccount = (state) =>
  state.organizationReducer.selectedAccountOwner

function* loadOrganizations() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/organizations/scroll')
    })

    if (data.message) {
      yield put(organizationActions.loadOrganizationsFailed(data.message))
    } else {
      yield put(organizationActions.loadOrganizationsSuccess(data))
    }
  } catch (e) {
    yield put(organizationActions.loadOrganizationsFailed(e.message))
  }
}

function* getAccountOwner(action) {
  try {
    const requestQuery = prepareAccountOwnerQuery(action.payload.id)

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/user-profiles/scroll${requestQuery}`)
    })

    if (data.message) {
      yield put(organizationActions.getAccountOwnerFailed(data.message))
    } else {
      if (data && data.length) {
        yield put(organizationActions.getAccountOwnerSuccess(data))
      }
    }
  } catch (e) {
    yield put(organizationActions.getAccountOwnerFailed(e.message))
  }
}

function* getAdmins(action) {
  try {
    const requestQuery = prepareAdminsQuery(action.payload.id)

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/user-profiles/scroll${requestQuery}`)
    })

    if (data.message) {
      yield put(
        organizationActions.getPotentialAccountOwnersFailed(data.message)
      )
    } else {
      yield put(organizationActions.getPotentialAccountOwnersSuccess(data))
    }
  } catch (e) {
    yield put(organizationActions.getPotentialAccountOwnersFailed(e.message))
  }
}

function* changeAccountOwner(action) {
  try {
    const account = yield select(getAccount)
    const { newId, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.post(
        `/api/secured/user-profiles/owner/change/${account.id}/${newId}`
      )
    })

    if (data.message) {
      yield put(organizationActions.changeAccountOwnerFailed(data.message))
    } else {
      yield put(organizationActions.changeAccountOwnerSuccess(data))
      onSuccess()
    }
  } catch (e) {
    yield put(organizationActions.changeAccountOwnerFailed(e.message))
  }
}

function* changeAdminsList() {
  try {
    const account = yield select(getAccount)
    const orgId = (account && account.organizationId) || null

    yield put(organizationActions.getPotentialAccountOwners(orgId))
  } catch (e) {
    yield put(organizationActions.getPotentialAccountOwnersFailed(e.message))
  }
}

function* activateAccount(action) {
  try {
    const { id, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/accounts/${id}/activate`)
    })

    if (data.message) {
      yield put(organizationActions.activateAccountFailed(data.message))
    } else {
      onSuccess()

      yield put(organizationActions.activateAccountSuccess())
    }
  } catch (e) {
    yield put(organizationActions.activateAccountFailed(e.message))
  }
}

function* deactivateAccount(action) {
  try {
    const { id, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/accounts/${id}/deactivate`)
    })

    if (data.message) {
      yield put(organizationActions.deactivateAccountFailed(data.message))
    } else {
      onSuccess()

      yield put(organizationActions.deactivateAccountSuccess())
    }
  } catch (e) {
    yield put(organizationActions.deactivateAccountFailed(e.message))
  }
}

function* getFeatures(action) {
  try {
    const { id } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/feature/${id}`)
    })

    if (data.message) {
      yield put(organizationActions.getFeaturesFailed(data.message))
    } else {
      yield put(organizationActions.getFeaturesSuccess(data))
    }
  } catch (e) {
    yield put(organizationActions.getFeaturesFailed(e.message))
  }
}

function* onFeatureForOrganization(action) {
  try {
    const { id, code } = action.payload

    const data = yield call(() => {
      return HttpClient.post(`/api/secured/feature/${id}/${code}`)
    })

    if (data.message) {
      yield put(
        organizationActions.changeFeatureForOrganizationFailed(data.message)
      )
    } else {
      yield put(
        organizationActions.changeFeatureForOrganizationSuccess(code, true)
      )
    }
  } catch (e) {
    yield put(organizationActions.changeFeatureForOrganizationFailed(e.message))
  }
}

function* offFeatureForOrganization(action) {
  try {
    const { id, code } = action.payload

    const data = yield call(() => {
      return HttpClient.delete(`/api/secured/feature/${id}/${code}`)
    })

    if (data.message) {
      yield put(
        organizationActions.changeFeatureForOrganizationFailed(data.message)
      )
    } else {
      yield put(
        organizationActions.changeFeatureForOrganizationSuccess(code, false)
      )
    }
  } catch (e) {
    yield put(organizationActions.changeFeatureForOrganizationFailed(e.message))
  }
}

function* getIdpData(action) {
  try {
    const { id } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/idp/${id}`)
    })

    if (data.message) {
      yield put(organizationActions.getIdpDataFailed(data.message))
    } else {
      yield put(organizationActions.getIdpDataSuccess(id, data))
    }
  } catch (e) {
    yield put(organizationActions.getIdpDataFailed(e.message))
  }
}

function* saveIdpData(action) {
  try {
    const { id, body, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/idp/${id}`, { body })
    })

    if (data.message) {
      yield put(organizationActions.saveIdpDataFailed(data.message))
    } else {
      yield put(organizationActions.saveIdpDataSuccess(data))

      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(organizationActions.saveIdpDataFailed(e.message))
  }
}

function* deleteIdpData(action) {
  try {
    const { id } = action.payload

    const data = yield call(() => {
      return HttpClient.delete(`/api/secured/idp/${id}`)
    })

    if (data.message) {
      yield put(organizationActions.deleteIdpDataFailed(data.message))
    } else {
      yield put(organizationActions.deleteIdpDataSuccess(data))

      yield put(organizationActions.getIdpData(id))
    }
  } catch (e) {
    yield put(organizationActions.deleteIdpDataFailed(e.message))
  }
}

function* loadOrganizationsSaga() {
  yield all([
    yield takeEvery(
      organizationActions.actionTypes.LOAD_ORGANIZATIONS,
      loadOrganizations
    ),
    yield takeEvery(
      organizationActions.actionTypes.ACTIVATE_ACCOUNT_SUCCEEDED,
      loadOrganizations
    ),
    yield takeEvery(
      organizationActions.actionTypes.DEACTIVATE_ACCOUNT_SUCCEEDED,
      loadOrganizations
    ),
  ])
}

function* getAccountOwnerSaga() {
  yield takeEvery(
    organizationActions.actionTypes.GET_ACCOUNT_OWNER,
    getAccountOwner
  )
}

function* getAdminsSaga() {
  yield takeEvery(
    organizationActions.actionTypes.GET_POTENTIAL_ACCOUNT_OWNERS,
    getAdmins
  )
}

function* changeAccountOwnerSaga() {
  yield takeLatest(
    organizationActions.actionTypes.CHANGE_ACCOUNT_OWNER,
    changeAccountOwner
  )
}

function* changeAdminsListSaga() {
  yield takeLatest(
    organizationActions.actionTypes.GET_ACCOUNT_OWNER_SUCCEEDED,
    changeAdminsList
  )
}

function* deactivateAccountSaga() {
  yield takeLatest(
    organizationActions.actionTypes.DEACTIVATE_ACCOUNT,
    deactivateAccount
  )
}

function* activateAccountSaga() {
  yield takeLatest(
    organizationActions.actionTypes.ACTIVATE_ACCOUNT,
    activateAccount
  )
}

function* getFeaturesSaga() {
  yield takeLatest(organizationActions.actionTypes.GET_FEATURES, getFeatures)
}

function* onFeatureForOrganizationSaga() {
  yield takeLatest(
    organizationActions.actionTypes.ON_FEATURE_FOR_ORGANIZATION,
    onFeatureForOrganization
  )
}

function* offFeatureForOrganizationSaga() {
  yield takeLatest(
    organizationActions.actionTypes.OFF_FEATURE_FOR_ORGANIZATION,
    offFeatureForOrganization
  )
}

function* getIdpSaga() {
  yield takeLatest(organizationActions.actionTypes.GET_IDP_DATA, getIdpData)
}

function* saveIdpDataSaga() {
  yield takeLatest(organizationActions.actionTypes.SAVE_IDP_DATA, saveIdpData)
}

function* deleteIdpDataSaga() {
  yield takeLatest(
    organizationActions.actionTypes.DELETE_IDP_DATA,
    deleteIdpData
  )
}

function* validateOrganizationExists(secondaryPhone, name) {
  const data = yield call(() => {
    return HttpClient.get(
      `/api/public/organizations/exists/?phone=${
        secondaryPhone || ' '
      }&name=${name}`
    )
  })
  return data.exists
}

function* saveOrganizationDataSaga() {
  yield takeLatest(
    SAVE_ORGANIZATION_DATA_INIT.getType(),
    function* getTimezonesList(action) {
      try {
        const formData = action.payload

        const orgExists = yield validateOrganizationExists(
          formData.secondaryPhone,
          formData.name
        )

        if (!orgExists) {
          const data = yield call(() => {
            return HttpClient.post(`/api/secured/organizations`, {
              body: formData,
            })
          })

          if (data.message) {
            console.error(data.message)
            yield put(
              SAVE_ORGANIZATION_DATA_ERROR(ADD_ORGANIZATIONS_ERRORS.UNEXPECTED)
            )
          } else {
            yield put(SAVE_ORGANIZATION_DATA_SUCCESS())
          }
        } else {
          yield put(
            SAVE_ORGANIZATION_DATA_ERROR(
              ADD_ORGANIZATIONS_ERRORS.ALREADY_EXISTS
            )
          )
        }
      } catch (e) {
        console.error(data.message)
        yield put(
          SAVE_ORGANIZATION_DATA_ERROR(ADD_ORGANIZATIONS_ERRORS.UNEXPECTED)
        )
      }
    }
  )
}

function* getTimezonesListSaga() {
  yield takeLatest(
    TIMEZONES_BY_ZIP_INIT.getType(),
    function* getTimezonesList(action) {
      try {
        const zip = action.payload

        const data = yield call(() => {
          return HttpClient.get(`/api/secured/timezones/${zip}`)
        })

        if (data.message) {
          yield put(TIMEZONES_BY_ZIP_ERROR(data.message))
        } else {
          yield put(TIMEZONES_BY_ZIP_SUCCESS(data))
        }
      } catch (e) {
        yield put(TIMEZONES_BY_ZIP_ERROR(e.message))
      }
    }
  )
}

export default function* organizationSaga() {
  yield fork(loadOrganizationsSaga)
  yield fork(getAccountOwnerSaga)
  yield fork(getAdminsSaga)
  yield fork(changeAccountOwnerSaga)
  yield fork(changeAdminsListSaga)
  yield fork(deactivateAccountSaga)
  yield fork(activateAccountSaga)
  yield fork(getFeaturesSaga)
  yield fork(onFeatureForOrganizationSaga)
  yield fork(offFeatureForOrganizationSaga)
  yield fork(getIdpSaga)
  yield fork(saveIdpDataSaga)
  yield fork(deleteIdpDataSaga)
  yield fork(saveOrganizationDataSaga)
  yield fork(getTimezonesListSaga)
}
