const style = () => ({
  buttonsWrapper: {
    padding: '0 7%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginTop: '20px',
    display: 'block',
    marginLeft: '20px',
    minWidth: '120px',
  },
  table: {
    margin: '0 4%',
    marginTop: '30px',
    maxHeight: 'calc(100% - 240px)',
    overflowY: 'scroll',
    position: 'absolute',
    width: '92%',
  },
})

export default style
