import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import map from 'lodash/map'

const CustomSelectComponent = ({
  id = 'custom-select',
  options,
  error,
  label,
  onChange,
  value,
  valueName,
  template,
  isRequired,
  autoWidth = true,
  menuItemStyles = '',
  name,
  disabled = false,
}) => (
  <FormControl
    fullWidth
    id={id}
    variant="outlined"
    required={isRequired}
    error={error}
    disabled={disabled}
  >
    <InputLabel htmlFor="outlined-simple">{label}</InputLabel>
    <Select
      error={error}
      value={value}
      autoWidth={autoWidth}
      onChange={onChange}
      name={name}
      label={label}
      IconComponent={ExpandMoreIcon}
      inputProps={{
        id: 'outlined-simple',
      }}
    >
      {map(options, (item) => {
        return (
          <MenuItem
            key={item[valueName]}
            value={item[valueName]}
            className={classNames(menuItemStyles, 'user-role')}
          >
            {template(item)}
          </MenuItem>
        )
      })}
    </Select>
  </FormControl>
)

export default CustomSelectComponent
