import moment from 'moment'

const getCustomCalendarDate = (date) => {
  return moment(date).calendar({
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: function (now) {
      return 'MMM D'
    },
  })
}

const utcConverter = (date, format) => {
  return moment.utc(date).format(format)
}

const utcConverterToDate = (date) => {
  return moment.utc(date).local()
}

const hhmmaFormat = 'hh:mm a'
const HmmFormat = 'H:mm'
const mssFormat = 'm:ss'
const yyyyMMDFormat = 'yyyy-MM-D'
const yyyyMMDDTHHmmssFormat = 'yyyy-MM-DDTHH:mm:ss'
const yyyyMMDD = 'yyyy-MM-DD'
const MMDDYYFormat = 'MM/DD/YY'
const MMddyyyyhmmssaa = 'MM/dd/yyyy h:mm:ss aa'
const yyyyMMdFormat = 'yyyy/MM/d'
const yyyyMMDDHHmmFormat = 'yyyy/MM/DD - HH:mm'

export {
  getCustomCalendarDate,
  utcConverter,
  utcConverterToDate,
  hhmmaFormat,
  HmmFormat,
  mssFormat,
  yyyyMMDFormat,
  yyyyMMDDTHHmmssFormat,
  MMDDYYFormat,
  MMddyyyyhmmssaa,
  yyyyMMDD,
  yyyyMMdFormat,
  yyyyMMDDHHmmFormat,
}
