import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { DEVICE_TYPE } from '../../../services/constants'
import { HttpClient } from '../../../services/HttpClient'
import firmwareActions from './firmwares-actions'

function* loadFirmwares() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/firmwares')
    })

    yield put(firmwareActions.loadFirmwaresSuccess(data))

    if (window.location.href.indexOf('/defaultFirmwares') !== -1) {
      yield put(firmwareActions.getDefaultFirmwares())
    }
  } catch (e) {
    yield put(firmwareActions.loadFirmwaresFailed(e.message))
  }
}

function* deleteFirmware(action) {
  try {
    const { id, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.delete(`/api/secured/firmwares/${id}`)
    })

    if (data.message) {
      yield put(firmwareActions.deleteFirmwareFailed(data.message))
    } else {
      onSuccess()
      yield put(firmwareActions.deleteFirmwareSuccess())
    }
  } catch (e) {
    yield put(firmwareActions.deleteFirmwareFailed(e.message))
  }
}

function* uploadFirmware(action) {
  try {
    const { body, back } = action.payload

    const data = yield call(() => {
      // make sure not to set the Content-Type header. The browser will set it for you, including the boundary parameter.
      return HttpClient.post('/api/secured/firmwares', {
        body,
        headers: new Headers({}),
        isFormData: true,
      })
    })

    if (data.message || typeof data === 'string') {
      yield put(firmwareActions.uploadFirmwareFailed(data.message))
    } else {
      yield put(firmwareActions.uploadFirmwareSuccess(data))
      back()
    }
  } catch (e) {
    yield put(firmwareActions.uploadFirmwareFailed(e.message))
  }
}

function* updateDefaultFirmware(action) {
  try {
    const { id, unset } = action.payload

    const data = yield unset
      ? call(() => {
          return HttpClient.delete(`/api/secured/firmwares/${id}/removeDefault`)
        })
      : call(() => {
          return HttpClient.put(`/api/secured/firmwares/${id}/default`)
        })

    if (data.message) {
      yield put(firmwareActions.updateDefaultFirmwareFailed(data.message))
    } else {
      yield put(firmwareActions.getDefaultFirmwares())
      yield put(firmwareActions.loadFirmwares())
    }
  } catch (e) {
    yield put(firmwareActions.updateDefaultFirmwareFailed(e.message))
  }
}

function* getDefaultFirmwares() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/firmwares/allocation')
    })

    if (data.message) {
      yield put(firmwareActions.getDefaultFirmwaresFailed(data.message))
    } else {
      yield put(firmwareActions.getDefaultFirmwaresSuccess(data))
    }
  } catch (e) {
    yield put(firmwareActions.getDefaultFirmwaresFailed(e.message))
  }
}

function* loadFirmwareTypesForDevice(action) {
  try {
    let { deviceType, deviceSubType } = action.payload

    if (deviceType === DEVICE_TYPE.FAR_UVC.type) {
      deviceType = DEVICE_TYPE.UR_UVGI.type
    }

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/firmwares/firmware-types/${deviceType}/${deviceSubType}`
      )
    })

    if (data.message) {
      yield put(firmwareActions.loadFirmwareTypesForDeviceFailed(data.message))
    } else {
      yield put(firmwareActions.loadFirmwareTypesForDeviceSuccess(data))
    }
  } catch (e) {
    yield put(firmwareActions.loadSubTypesForDeviceFailed(e.message))
  }
}

function* loadVersionsForFirmwareType(action) {
  try {
    let { deviceType, deviceSubType, firmwareType } = action.payload

    // if(deviceType === DEVICE_TYPE.FAR_UVC.type) {
    //     deviceType = DEVICE_TYPE.UR_UVGI.type;
    // }

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/firmwares/type/${firmwareType}/${deviceType}/${deviceSubType}`
      )
    })

    if (data.message) {
      yield put(firmwareActions.loadVersionsForFirmwareTypeFailed(data.message))
    } else {
      yield put(firmwareActions.loadVersionsForFirmwareTypeSuccess(data))
    }
  } catch (e) {
    yield put(firmwareActions.loadVersionsForFirmwareTypeFailed(e.message))
  }
}

function* loadFirmwaresSaga() {
  yield all([
    yield takeEvery(firmwareActions.actionTypes.LOAD_FIRMWARES, loadFirmwares),
    yield takeEvery(
      firmwareActions.actionTypes.DELETE_FIRMWARE_SUCCESS,
      loadFirmwares
    ),
  ])
}

function* uploadFirmwareSaga() {
  yield takeLatest(firmwareActions.actionTypes.UPLOAD_FIRMWARE, uploadFirmware)
}

function* deleteFirmwareSaga() {
  yield takeLatest(firmwareActions.actionTypes.DELETE_FIRMWARE, deleteFirmware)
}

function* updateDefaultFirmwareSaga() {
  yield takeLatest(
    firmwareActions.actionTypes.UPDATE_DEFAULT_FIRMWARE,
    updateDefaultFirmware
  )
}

function* getDefaultFirmwaresSaga() {
  yield takeLatest(
    firmwareActions.actionTypes.GET_DEFAULT_FIRMWARES,
    getDefaultFirmwares
  )
}

function* loadFirmwareTypesForDeviceSaga() {
  yield takeLatest(
    firmwareActions.actionTypes.LOAD_FIRMWARE_TYPES_FOR_DEVICE,
    loadFirmwareTypesForDevice
  )
}

function* loadVersionsForFirmwareTypeSaga() {
  yield takeLatest(
    firmwareActions.actionTypes.LOAD_VERSIONS_FOR_FIRMWARE_TYPE,
    loadVersionsForFirmwareType
  )
}

export default function* firmwareSagas() {
  yield fork(loadFirmwaresSaga)
  yield fork(uploadFirmwareSaga)
  yield fork(deleteFirmwareSaga)
  yield fork(updateDefaultFirmwareSaga)
  yield fork(getDefaultFirmwaresSaga)
  yield fork(loadFirmwareTypesForDeviceSaga)
  yield fork(loadVersionsForFirmwareTypeSaga)
}
