const styles = (theme) => ({
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonsWrapper: {
    width: '700px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formWrapper: {
    margin: 'auto',
    width: '80%',
    maxWidth: '500px',
  },
  displayNone: {
    display: 'none',
  },
  upload: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    '& button': {
      textTransform: 'none',
    },
  },
  actionButtons: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  error: {
    marginTop: '10px',
  },
  fileName: {
    marginLeft: '5px',
  },
  modifiedCell: {
    maxWidth: '120px',
  },
  actionCell: {
    maxWidth: '40px',
  },
  deleteIcon: {
    padding: 0,
  },
  '@media (min-width: 1224px)': {
    createFirmware: {
      padding: '25px 10px',
    },
  },
})

export default styles
