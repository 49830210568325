import { DEVICE_TYPE } from '../../../services/constants'

const ACTION_BUTTONS = {
  create: 'Create',
  manageDefault: 'Manage Default Firmware',
  edit: 'Edit',
  manageDefaultFirmware: 'Manage Default Firmware',
}

const FIRMWARES_TABLE_LABELS = {
  firmwares: 'Firmwares',
  deviceType: 'Device Type',
  firmwareType: 'Firmware Type',
  version: 'Version',
  modified: 'Modified',
  default: 'Default Flag',
}

const CREATE_FIRMWARE_LABELS = {
  createFirmware: 'Create Firmware',
  deviceType: 'Device Type',
  deviceSubType: 'Device SubType',
  firmwareType: 'Firmware Type',
  version: 'Version',
  upload: 'Upload',
  download: 'Download',
  save: 'Save',
  cancel: 'Cancel',
}

const FIRMWARE_TYPES_VALUES = {
  modem: 'Modem',
  bluetooth: 'Bluetooth',
}

const VERSIONS_TYPES = {
  default: 'default',
}

const FIRMWARE_CONFIRM_MODAL = {
  modalTitle: 'Delete Firmware',
  actionLabel: 'Delete',
  closeLabel: 'Cancel',
  confirmText: (firmwareName, version) =>
    `Are you sure you want to delete: ${firmwareName} - ${version}`,
}

const FILE_ERROR = 'Please, select a file'

const FILE_TOO_BIG_MESSAGE = 'File is too big. Maximum file size is 300 KB.'

const initialFormValue = {
  file: null,
  deviceType: '',
  firmwareType: '',
  version: '',
}

const FIRMWARES_LABELS = {
  defaultFirmwaresTitle: "Default Firmwares' Versions",
  defaultActive: 'Update Default Version',
  changeVersion: 'Change Version',
  versions: 'Versions',
  versionIsNotSet: 'Version is not set',
  deviceTypes: 'Device Types',
  deviceSubType: 'Device Sub Type',
  firmwareTypes: 'Firmware Types',
  modem: 'MODEM',
  bluetooth: 'BLUETOOTH',
  current: ' - Current',
  next: 'Next',
  back: 'Back',
}

const DEVICE_TYPE_OPTIONS = [
  {
    label: DEVICE_TYPE.UVI_ARC.label,
    value: DEVICE_TYPE.UVI_ARC.type,
  },
  {
    label: DEVICE_TYPE.UR_UVGI.label,
    value: DEVICE_TYPE.UR_UVGI.type,
  },
]

const UVGI_FIRMWARE = 'UR_UVGI_COMBINED'

const NO_FIRMWARE_VERSION =
  'You do not have any version for this type of firmware. Please choose another type or create new firmware.'

const FULL_LIST_OF_FIRMWARES = 'Full List of Firmwares'

export {
  ACTION_BUTTONS,
  FIRMWARES_TABLE_LABELS,
  CREATE_FIRMWARE_LABELS,
  FIRMWARE_TYPES_VALUES,
  FILE_ERROR,
  FILE_TOO_BIG_MESSAGE,
  initialFormValue,
  FIRMWARE_CONFIRM_MODAL,
  FIRMWARES_LABELS,
  VERSIONS_TYPES,
  DEVICE_TYPE_OPTIONS,
  UVGI_FIRMWARE,
  NO_FIRMWARE_VERSION,
  FULL_LIST_OF_FIRMWARES,
}
