import batteryImage0URL from './assets/battery-0.svg'
import batteryImage100URL from './assets/battery-100.svg'
import batteryImage25URL from './assets/battery-25.svg'
import batteryImage50URL from './assets/battery-50.svg'
import batteryImage75URL from './assets/battery-75.svg'

const BATTERY_LEVELS = {
  100: batteryImage100URL,
  75: batteryImage75URL,
  50: batteryImage50URL,
  25: batteryImage25URL,
  0: batteryImage0URL,
}

type BatteryLevelProps = { voltage: number; width?: number }

export const BatteryLevel = ({ voltage, width = 30 }: BatteryLevelProps) => {
  let url = BATTERY_LEVELS[100]
  if (isNaN(voltage) || voltage === 0) {
    url = BATTERY_LEVELS[0]
  } else if (voltage >= 290) {
    url = BATTERY_LEVELS[100]
  } else if (voltage < 290 && voltage >= 270) {
    url = BATTERY_LEVELS[75]
  } else if (voltage < 270 && voltage >= 250) {
    url = BATTERY_LEVELS[50]
  } else {
    url = BATTERY_LEVELS[25]
  }
  return <img src={url} width={width} />
}
