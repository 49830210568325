import { useState } from 'react'
import { WIFI } from '../../../services/organizationDetails-constants'
import AddCredentialModal from './AddCredentialModal'
import { useStyles } from './style'

type AddCredentialButtonProps = {
  organizationId: string
}

const AddCredentialButton = ({ organizationId }: AddCredentialButtonProps) => {
  const styles = useStyles()

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <button className={styles.addCredentialButton} onClick={handleOpen}>
        <p className={styles.buttonLabel}>{WIFI.buttonLabel}</p>
      </button>
      <AddCredentialModal
        isOpen={open}
        onClose={handleClose}
        organizationId={organizationId}
        mode={WIFI.add}
      />
    </>
  )
}

export default AddCredentialButton
