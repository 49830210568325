const style = (theme) => ({
  featuresButton: {
    cursor: 'pointer',
  },
  closeIcon: {
    position: 'absolute',
    left: '16px',
    top: '16px',
    padding: 0,
  },
  modalTitle: {
    margin: '10px',
  },
  dialogContent: {
    margin: '0 0 20px',
    display: 'flex',
    flexDirection: 'column',
  },
  featureWrapper: {
    margin: '10px 0',
  },
  button: {
    margin: '10px auto',
    minWidth: '250px',
  },
  switchLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
      fontFamily: 'Cerebri Sans Pro Semi Bold',
    },
  },
})

export default style
