import { Checkbox, TableCell, TableHead, TableRow } from '@material-ui/core'

import filter from 'lodash/filter'
import isNull from 'lodash/isNull'
import map from 'lodash/map'

const Header = (props) => {
  const {
    multi, // specify weather it is possible to select multiple items
    tableData,
    tableLabels,
    selected,
    setSelected,
  } = props
  const labels = filter(tableLabels, (label) => !isNull(label))

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = map(tableData, (n) => n.id)

      setSelected(newSelected)
    } else {
      setSelected([])
    }
  }

  return (
    <TableHead>
      <TableRow>
        {multi && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={
                selected.length > 0 && selected.length < tableData.length
              }
              checked={
                tableData.length > 0 && selected.length === tableData.length
              }
              onChange={handleSelectAllClick}
              inputProps={{ 'aria-label': 'select all tableData' }}
            />
          </TableCell>
        )}
        {map(labels, (value, key) => (
          <TableCell align="left" key={key}>
            {value}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default Header
