import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import map from 'lodash/map'
import uniqueId from 'lodash/uniqueId'
import { WEEK_DAYS_LABELS } from '../../services/deviceDetails-constants'
import { useStyles } from './style'

type WeekDaysProps = {
  desiredDays: boolean[]
  onChangeDays: (weekDays: boolean[]) => void
}

const WeekDays = ({ desiredDays, onChangeDays }: WeekDaysProps) => {
  const selectDay = (index: number) => {
    const daysCopy = [...desiredDays]

    daysCopy[index] = !desiredDays[index]

    onChangeDays(daysCopy)
  }

  const styles = useStyles()

  return (
    <div className={styles.daysWrapper}>
      {map(WEEK_DAYS_LABELS, (item, index) => {
        return (
          <Button
            variant="contained"
            key={uniqueId()}
            onClick={() => selectDay(index)}
            className={
              desiredDays && desiredDays[index]
                ? classNames(styles.selectedDay, styles.weekDay)
                : styles.weekDay
            }
          >
            {item}
          </Button>
        )
      })}
    </div>
  )
}

export default WeekDays
