import {
  ACTUAL_DEVICE_FIRMWARE,
  FIRMWARE_TYPE_TABLE,
} from '../services/deviceDetails-constants'

import { TableCell } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import map from 'lodash/map'
import toString from 'lodash/toString'
import { useStyles } from './style'

const FirmwareTypeTable = (props) => {
  const { tableData } = props

  const style = useStyles()

  const getTableLabels = () =>
    map(FIRMWARE_TYPE_TABLE, (value, key) => (
      <TableCell align="center" key={key}>
        {value}
      </TableCell>
    ))

  const getTableData = () => {
    return tableData && tableData.length
      ? map(tableData, (row) => {
          return (
            <TableRow key={row.id}>
              {map(FIRMWARE_TYPE_TABLE, (value, key) => {
                return (
                  <TableCell align="center" key={key}>
                    {row[key] ? toString(row[key]) : ''}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })
      : null
  }

  return (
    <div className={style.tableContainer}>
      <Typography variant="h5" className={style.tableTitle}>
        {ACTUAL_DEVICE_FIRMWARE}
      </Typography>
      <TableContainer component={Paper}>
        <Table className={style.table}>
          <TableHead>
            <TableRow>{getTableLabels()}</TableRow>
          </TableHead>
          <TableBody>{getTableData()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default FirmwareTypeTable
