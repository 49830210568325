import { Switch } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import map from 'lodash/map'
import { ON_OFF_LABELS } from '../../services/constants'
import styles from './style'

const FeaturesTable = (props) => {
  const {
    features,
    updateFeatureState,
    error,
    isReadOnly,
    labelsConfig,
    classes,
  } = props

  const handleChange = (event) => {
    updateFeatureState(event.target.name, event.target.checked)
  }

  const getTableLabels = () =>
    map(labelsConfig, (value, key) => (
      <TableCell align="left" key={key}>
        {value}
      </TableCell>
    ))

  const getTableData = () => {
    return features && features.length
      ? map(
          // NOTE: EXT devices may be added back in future
          features.filter(({ id }) => id !== 'EXT_DEVICE'),
          (row) => {
            return (
              <TableRow
                key={row.id}
                className={
                  !row.enabled && isReadOnly ? classes.disabledRow : ''
                }
                selected={row.selected}
              >
                {map(labelsConfig, (value, key) => {
                  return value !== labelsConfig.toggle ? (
                    <TableCell align="left" key={key}>
                      {key !== 'toggle' && row[key]}
                    </TableCell>
                  ) : (
                    <TableCell align="left" key={key}>
                      {isReadOnly ? (
                        <div>
                          {row.enabled ? ON_OFF_LABELS.on : ON_OFF_LABELS.off}
                        </div>
                      ) : (
                        <Switch
                          checked={row.enabled}
                          disabled={isReadOnly}
                          color="primary"
                          onChange={handleChange}
                          name={row.id}
                        />
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          }
        )
      : null
  }

  return (
    <>
      {error && <div>{error}</div>}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>{getTableLabels()}</TableRow>
          </TableHead>
          <TableBody>{getTableData()}</TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default withStyles(styles)(FeaturesTable)
