import { call, fork, put, select, takeEvery } from 'redux-saga/effects'

import { HttpClient } from '../../../../services/HttpClient'
import { FEATURE_KEYS } from '../../_shared/services/emulator-constants'
import extEmulatorActions from './extEmulator-actions'

const getOrganization = (state) =>
  state.eventsEmulatorReducer.sharedEmulatorReducer.selectedOrganization

function* sendDeviceMessage(action) {
  try {
    const { params, body } = action.payload
    const { id } = yield select(getOrganization)

    const data = yield call(() => {
      return HttpClient.post(
        `/api/secured/ext-device/admin/test/message/send?organizationId=${id}&${params}`,
        { body }
      )
    })

    if (data.error) {
      yield put(extEmulatorActions.sendDeviceMessageFailed(data.error))
    } else {
      yield put(extEmulatorActions.sendDeviceMessageSuccess(data.message))
    }
  } catch (e) {
    yield put(extEmulatorActions.sendDeviceMessageFailed(e.message))
  }
}

function* loadAllLocations() {
  try {
    const { id } = yield select(getOrganization)

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/locations/?orgId=${id}`)
    })

    if (data.message) {
      yield put(extEmulatorActions.loadLocationsFailed(data.message))
    } else {
      yield put(extEmulatorActions.loadAllLocationsSuccess(data))
    }
  } catch (e) {
    yield put(extEmulatorActions.loadLocationsFailed(e.message))
  }
}

function* loadEXTDevices() {
  try {
    const { name } = yield select(getOrganization)

    const body = {
      name,
      featureKeys: [FEATURE_KEYS.ext],
    }

    const data = yield call(() => {
      return HttpClient.post(`/api/secured/devices/organization`, { body })
    })

    if (data.message) {
      yield put(extEmulatorActions.loadEXTDevicesFailed(data.message))
    } else {
      yield put(extEmulatorActions.loadEXTDevicesSuccess(data))
    }
  } catch (e) {
    yield put(extEmulatorActions.loadEXTDevicesFailed(e.message))
  }
}

function* sendDeviceMessageSaga() {
  yield takeEvery(
    extEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE,
    sendDeviceMessage
  )
}

function* loadAllMessageLocationsSaga() {
  yield takeEvery(
    extEmulatorActions.actionTypes.LOAD_EXT_DEVICES_SUCCEEDED,
    loadAllLocations
  )
}

function* loadDevicesSaga() {
  yield takeEvery(
    extEmulatorActions.actionTypes.LOAD_EXT_DEVICES,
    loadEXTDevices
  )
}

export default function* eventsExtEmulatorSaga() {
  yield fork(sendDeviceMessageSaga)
  yield fork(loadDevicesSaga)
  yield fork(loadAllMessageLocationsSaga)
}
