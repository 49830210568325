import { useCallback, useEffect, useState } from 'react'
import {
  CHANGE_ACCOUNT_OWNER_LABELS,
  CONFIRM_MODAL_LABELS,
} from '../../services/organization-constants'
import {
  getConfirmText,
  getUserName,
  _accountOwnerTemplate,
} from '../../services/organization-service'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import isEmpty from 'lodash/isEmpty'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { useAppSelector } from 'utils/reduxHelpers'
import ConfirmModal from '../../../../components/_shared/confirmModal/ConfirmModal'
import CustomSelect from '../../../../components/_shared/CustomSelect'
import { checkConnection } from '../../../../services/root/root-service'
import actions from '../../services/organization-actions'
import { useStyles } from './style'

const ChangeAccountOwnerPage = ({
  match,
  history,
}: RouteComponentProps<{
  id: string
}>) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const error = useAppSelector((state) => state.organizationReducer.error)
  const admins = useAppSelector((state) => state.organizationReducer.admins)
  const selectedAccountOwner = useAppSelector(
    (state) => state.organizationReducer.selectedAccountOwner
  )

  const sortedAdmins = admins.sort((user1, user2) =>
    `${user1.firstName} ${user1.lastName}`.localeCompare(
      `${user2.firstName} ${user2.lastName}`
    )
  )

  const getAccountOwner = useCallback(
    (id: string) =>
      dispatch(checkConnection(() => actions.getAccountOwner(id))),
    []
  )

  const changeAccountOwner = useCallback(
    (id: string, onSuccess: Function) =>
      dispatch(
        checkConnection(() => actions.changeAccountOwner(id, onSuccess))
      ),
    []
  )

  const [accountOwner, setAccountOwner] = useState('')
  const [openConfirm, setOpenConfirm] = useState(false)
  const [confirmText, setConfirmText] = useState('')

  useEffect(() => {
    if (isEmpty(selectedAccountOwner)) {
      const orgId = match.params.id
      getAccountOwner(orgId)
    }
  }, [selectedAccountOwner])

  const changeAccount = (event: any) => {
    const id = event.target.value
    setAccountOwner(id)

    const userName = getUserName(sortedAdmins, id)
    setConfirmText(getConfirmText(userName))
  }

  const closeConfirm = () => {
    setOpenConfirm(false)
    history.push('/organizations')
  }

  const requestConfirm = () => {
    setOpenConfirm(true)
  }

  const saveChanges = () => {
    changeAccountOwner(accountOwner, closeConfirm)
  }

  return (
    <div className={classes.pageContainer}>
      <Typography variant="subtitle2" className={classes.title}>
        {CHANGE_ACCOUNT_OWNER_LABELS.page}
      </Typography>

      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.infoBlock}
      >
        {CHANGE_ACCOUNT_OWNER_LABELS.firstParagraph}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.infoBlock}
      >
        {CHANGE_ACCOUNT_OWNER_LABELS.secondParagraph}
      </Typography>

      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.infoBlock}
      >
        {CHANGE_ACCOUNT_OWNER_LABELS.thirdParagraph}
      </Typography>

      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.accountInfoBlock}
      >
        {`${CHANGE_ACCOUNT_OWNER_LABELS.currentAccountOwner}
              ${selectedAccountOwner.firstName} ${selectedAccountOwner.lastName}`}
      </Typography>

      <CustomSelect
        options={sortedAdmins || []}
        label={CHANGE_ACCOUNT_OWNER_LABELS.customSelectLabel}
        onChange={changeAccount}
        value={accountOwner}
        valueName="id"
        template={_accountOwnerTemplate}
        error={!!error}
        name=""
        isRequired={false}
      />
      <Button
        color="primary"
        variant="contained"
        onClick={requestConfirm}
        className={classes.changeOwnerButton}
        disabled={!accountOwner}
        type="submit"
      >
        {CHANGE_ACCOUNT_OWNER_LABELS.saveChanges}
      </Button>
      <ConfirmModal
        isOpen={openConfirm}
        error={error}
        onClose={closeConfirm}
        onSubmit={saveChanges}
        confirmText={confirmText}
        modalTitle={CONFIRM_MODAL_LABELS.modalTitle}
        actionLabel={CONFIRM_MODAL_LABELS.actionLabel}
        closeLabel={CONFIRM_MODAL_LABELS.closeLabel}
      />
    </div>
  )
}

export default ChangeAccountOwnerPage
