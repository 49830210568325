import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  actionButton: {
    width: 'fit-content',
    minWidth: 'auto',
    marginRight: '10px',
  },
  detailsWrapper: {
    display: 'flex',
    paddingLeft: '7%',
    paddingRight: '7%',
    flexWrap: 'wrap',
  },
  toggleBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  labelContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  radioBox: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  radio: {
    padding: '0 5px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
})
