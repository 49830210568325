import { ChangeEventHandler, useEffect } from 'react'

import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { RootState } from 'store/store'
import { checkConnection } from '../../../../../services/root/root-service'
import { GET_SENSOR_DEVICE_TYPES } from '../services/deviceDetails-actions'
import { ADD_DEVICE_LABELS } from '../services/deviceDetails-constants'
import SubTypesSelect from './addDeviceButton/SubTypesSelect'

type EditableSensorDeviceFieldsProps = {
  state: {
    hardwareId: string
    sensorDeviceType: string
  }
  errors: {
    hardwareId: boolean
    sensorDeviceType: boolean
  }
  onChange?: ChangeEventHandler
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const EditableSensorDeviceFields = (props: EditableSensorDeviceFieldsProps) => {
  const { state, errors, onChange, sensorDeviceTypes, getSensorDeviceTypes } =
    props

  useEffect(() => {
    if (!sensorDeviceTypes.length) {
      getSensorDeviceTypes()
    }
  }, [sensorDeviceTypes])

  return (
    <>
      <SubTypesSelect
        deviceSubType={state.sensorDeviceType}
        subTypesForDevice={sensorDeviceTypes}
        onChange={onChange}
        error={errors.sensorDeviceType}
        name="sensorDeviceType"
        label={ADD_DEVICE_LABELS.sensorDeviceType}
      />
      {/* NOTE: Below should be updated to toggle the hardwareId label based on the sensor type */}
      <TextField
        name="hardwareId"
        variant="outlined"
        label={ADD_DEVICE_LABELS.macAddress}
        value={state.hardwareId}
        onChange={onChange}
        error={errors.hardwareId}
        fullWidth
        required
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  const { deviceDetailsReducer } = state.devicesReducer

  return {
    sensorDeviceTypes: deviceDetailsReducer.sensorDeviceTypes || [],
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getSensorDeviceTypes: () =>
      dispatch(checkConnection(() => GET_SENSOR_DEVICE_TYPES())),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditableSensorDeviceFields)
