import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxHeight: 'calc(100% - 370px)',
    overflowY: 'scroll',
    position: 'absolute',
    width: '90%',
    margin: 'auto',
  },
  tabsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '10px',
  },
  deviceActionButton: {
    color: 'white',
    height: 'unset',
    minWidth: 'unset',
    paddingBottom: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '5px',
  },
  restartButton: {
    color: 'white',
    backgroundColor: '#EB903B',
    height: 'unset',
    minWidth: 'unset',
    paddingBottom: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '5px',
  },
  tab: {
    fontFamily: 'Cerebri Sans Pro Regular',
    textTransform: 'none',
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  actionButtonContainer: {
    maxWidth: '300px',
    marginRight: '20px',
  },
  dialogTitle: {
    marginTop: '10px',
  },
  statusFilerIcon: {
    position: 'absolute',
    left: '16px',
    top: '16px',
    padding: 0,
  },
  dialogContent: {
    margin: '0 20px 20px',
  },
  actionButton: {
    marginTop: '20px',
  },
  error: {
    marginTop: '10px',
  },
  deleteIcon: {
    padding: 0,
  },
  filterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0',
  },
  filterField: {
    width: '25%',
    marginRight: '12px',
    maxWidth: '340px',
  },
  button: {
    minWidth: '190px',
  },
  filterButtonsWrapper: {
    display: 'flex',
  },
  submitButton: {
    marginLeft: '20px',
  },
  selector: {
    width: '100%',
  },
  menuItem: {
    justifyContent: 'center',
  },
  closeIcon: {
    position: 'absolute',
    left: '16px',
    top: '16px',
    padding: 0,
  },
  updateCertificateButton: {
    minWidth: '190px',
    marginBottom: '25px',
  },
  switchLabel: {
    marginLeft: '20px',
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
    },
  },
}))
