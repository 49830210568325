const MENU_ITEMS_LABELS = {
  recentActivity: 'Recent Activity',
  devices: 'Devices',
  updateFirmware: 'Update Firmware',
  viewHistory: 'View History',
  locations: 'Locations',
  account: 'Account',
  users: 'Users',
  userManual: 'User Manual',
  marketingMaterials: 'Marketing Materials',
  logout: 'Log Out',
  support: 'Contact Support',
}

const MENU_ITEMS = {
  dashboard: {
    name: 'Dashboard',
    to: '/dashboard',
    disabled: false,
  },
  locations: {
    name: 'Locations',
    to: '/locations',
    disabled: false,
  },
  devices: {
    name: 'Devices',
    to: '/devices',
    disabled: false,
  },
  resources: {
    name: 'Resources',
    to: '/resources',
    disabled: true,
  },
  updateFirmware: {
    name: 'Update Firmware',
    to: '/updateFirmware',
    disabled: true,
  },
  users: {
    name: 'Users',
    to: '/users',
    disabled: false,
  },
  account: {
    name: 'Account',
    to: '/account',
    disabled: false,
  },
}

const OPERATORS_MENU_ITEMS_DESKTOP = [
  MENU_ITEMS.dashboard,
  MENU_ITEMS.locations,
  MENU_ITEMS.devices,
  MENU_ITEMS.resources,
  MENU_ITEMS.updateFirmware,
]

const ACCOUNT_OWNER_MENU_ITEMS_DESKTOP = [
  MENU_ITEMS.dashboard,
  MENU_ITEMS.locations,
  MENU_ITEMS.devices,
  MENU_ITEMS.resources,
  MENU_ITEMS.users,
  MENU_ITEMS.account,
  MENU_ITEMS.updateFirmware,
]

const HEADER_CONSTANTS_DESKTOP = [
  MENU_ITEMS.dashboard,
  MENU_ITEMS.locations,
  MENU_ITEMS.devices,
  MENU_ITEMS.resources,
  MENU_ITEMS.users,
  MENU_ITEMS.updateFirmware,
]

export {
  MENU_ITEMS_LABELS,
  HEADER_CONSTANTS_DESKTOP,
  OPERATORS_MENU_ITEMS_DESKTOP,
  ACCOUNT_OWNER_MENU_ITEMS_DESKTOP,
}
