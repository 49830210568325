import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const AutocompleteComponent = (props) => {
  const {
    options,
    value,
    onChange,
    isCustomLabel = false,
    getCustomLabel,
    label,
  } = props

  const getLabelDefault = (option) => option.name || ''

  return (
    <Autocomplete
      onChange={onChange}
      value={value}
      options={options}
      getOptionLabel={isCustomLabel ? getCustomLabel : getLabelDefault}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          margin="normal"
        />
      )}
    />
  )
}

export default AutocompleteComponent
