import { makeStyles, Theme } from '@material-ui/core'

import { COLORS } from '../../../../../../services/constants'

export const useStyles = makeStyles((theme: Theme) => ({
  settingsContainer: {
    flexDirection: 'column',
    alignItems: 'start!important',
  },
  title: {
    margin: '20px 0 10px',
  },
  subtitle: {
    margin: '10px 0',
  },
  input: {
    width: '350px',
  },
  checkboxLabel: {
    '& span': {
      fontSize: '14px',
      fontFamily: 'Cerebri Sans Pro Regular',
    },
  },
  warningIndicator: {
    color: 'orange',
    marginLeft: '5px',
    marginRight: '5px',
  },
  radio: {
    maxWidth: 'fit-content',
  },
  saveAndLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '320px',
    alignItems: 'center',
  },
  saveButton: {
    minWidth: '150px',
    height: '40px',
    margin: '20px 0',
  },
  resetButton: {
    minWidth: '150px',
    marginTop: '20px',
  },
  updatingLabel: {
    fontFamily: 'Cerebri Sans Pro Italic',
    color: COLORS.GRAY,
  },
  value: {
    marginLeft: '10px',
  },
  timeSelect: {
    width: '180px',
    marginRight: '50px',
  },
  customInput: {
    minWidth: '180px',
    height: '55px',
  },
  rangeWrapper: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-between',
    gridAutoRows: 'min-content',
    maxWidth: '450px',
    '& > *': {
      width: '45%',
    },
  },
  rangeWrapperAlert: {
    width: '100%',
  },
  powerAndRuntimeWrapper: {
    '& > *': {
      display: 'flex',
      paddingBottom: '15px',
    },
  },
  daysWrapper: {
    display: 'flex',
    width: '300px',
    justifyContent: 'space-between',
    margin: '20px 0',
  },
  weekDay: {
    color: COLORS.WHITE,
    minWidth: '30px',
    height: '30px',
    padding: 0,
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: COLORS.BLACK,
    },
  },
  selectedDay: {
    backgroundColor: COLORS.GRAY,
  },
  lastUpdated: {
    marginBottom: '20px',
    fontFamily: 'Cerebri Sans Pro Semi Bold',
    marginRight: '5px',
  },
  cleaningLabel: {
    fontSize: '14px',
    fontFamily: 'Cerebri Sans Pro Semi Bold',
  },
  helperText: {
    display: 'block',
    color: COLORS.GRAY,
    fontSize: '13px',
    fontFamily: 'Cerebri Sans Pro Regular',
  },
  keepLedAlways: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '14px',
  },
}))
