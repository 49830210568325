import { Dialog, Input, Link, withStyles } from '@material-ui/core'
import { useState } from 'react'

import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import PublishIcon from '@material-ui/icons/Publish'
import { BULK_UPLOAD_LABELS } from '../../../constants'
import BulkUploadInput from './BulkUploadInput'
import styles from './style'

const BulkUploadButtonAndModal = (props) => {
  const {
    title,
    error,
    bulkUpload,
    onOpenSnackBar,
    resetBulkUploadErrors,
    downloadCsvExample,
    isReadOnly,
    classes,
  } = props
  const [open, setOpen] = useState(false)
  const [fileName, setFileName] = useState('')
  const [file, setFile] = useState(null)

  const onClose = () => {
    // setOpen(false);
    onOpenSnackBar()
    setFileName('')
    setFile(null)
  }

  const onCancel = () => {
    setOpen(false)
    setFileName('')
    setFile(null)
    resetBulkUploadErrors()
  }

  const onOpen = () => {
    setOpen(true)
  }

  const onUpload = () => {
    if (file) {
      const formData = new FormData()
      formData.append('file', file)

      bulkUpload(formData, onClose)
    }
  }

  const downloadExample = () => {
    downloadCsvExample()
  }

  return (
    <>
      {!isReadOnly && (
        <Button
          id="bulk-upload-button"
          variant="contained"
          color="primary"
          startIcon={<PublishIcon />}
          onClick={onOpen}
          className={classes.actionButton}
        >
          {BULK_UPLOAD_LABELS.bulkUpload}
        </Button>
      )}
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <IconButton
            className={classes.closeIcon}
            aria-label="close"
            onClick={onCancel}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4">{title}</Typography>
          <Typography
            variant="subtitle1"
            color="error"
            className={classes.error}
          >
            {error}
          </Typography>

          <Link
            onClick={downloadExample}
            className={classes.example}
            id="download-csv-template"
          >
            {BULK_UPLOAD_LABELS.downloadExample}
          </Link>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.bulkUploadBlock}>
            <Input
              id="standard-adornment-password"
              type={'text'}
              disabled
              value={fileName}
              endAdornment={
                <InputAdornment position="end">
                  <BulkUploadInput
                    setFileName={setFileName}
                    setFile={setFile}
                  />
                </InputAdornment>
              }
            />
          </div>
          <Button
            id="bulk-upload-submit"
            variant="contained"
            color="primary"
            disabled={!fileName}
            onClick={onUpload}
            className={classes.actionButton}
          >
            {BULK_UPLOAD_LABELS.upload}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(BulkUploadButtonAndModal)
