import { PAGES, PAGINATION } from '../../../services/constants'
import {
  prepareTypes,
  prepareTypesWithNone,
} from '../../../services/root/root-service'

import rootActions from '../../../services/root/root-actions'
import firmwareUpdateActions from './firmwareUpdate-actions'
import { DATA_FIELDS } from './firmwareUpdate-constants'
import { getFirmwareAndDeviceTypeMap } from './firmwareUpdate-service'

export const firmwareUpdateReducer = (
  state = {
    devices: [],
    versions: [],
    error: null,
    isLoading: true,
    page: 0,
    totalCount: 0,
    rowsPerPage: PAGINATION.perPageValues[0],
    query: '',
    loadDevicesError: '',
    firmwareTypes: [],
    devicesTypes: [],
    isReadOnly: false,
    organizationsList: [],
  },
  action
) => {
  switch (action.type) {
    case rootActions.actionTypes.SETUP_PERMISSIONS: {
      const { permissions } = action.payload

      return {
        ...state,
        isReadOnly: !permissions[PAGES.deviceFirmware].write,
      }
    }
    case firmwareUpdateActions.actionTypes.RESET_ERROR: {
      return {
        ...state,
        error: '',
      }
    }
    case firmwareUpdateActions.actionTypes.APPLY_DEVICE_FILTER:
    case firmwareUpdateActions.actionTypes.CANCEL_UPDATE_FIRMWARE_SUCCEEDED:
    case firmwareUpdateActions.actionTypes.SCHEDULE_UPDATE_FIRMWARE_VERSIONS: {
      return {
        ...state,
        page: 0,
        isLoading: true,
        devices: [],
        totalCount: 0,
        error: '',
      }
    }
    case firmwareUpdateActions.actionTypes.RESET_TABLE_STATE_WITHOUT_RELOADING:
      return {
        ...state,
        page: 0,
        devices: [],
        totalCount: 0,
      }
    case firmwareUpdateActions.actionTypes.LOAD_DEVICES: {
      return {
        ...state,
        query: action.payload.query,
        isLoading: true,
        loadDevicesError: '',
      }
    }
    case firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_TYPES_SUCCEEDED: {
      return {
        ...state,
        firmwareTypes: prepareTypesWithNone(
          action.payload.types,
          DATA_FIELDS.firmwareType,
          DATA_FIELDS.firmwareType
        ),
        firmwareTypeMap: getFirmwareAndDeviceTypeMap(action.payload.types),
      }
    }
    case firmwareUpdateActions.actionTypes.LOAD_DEVICES_TYPES_SUCCEEDED: {
      return {
        ...state,
        devicesTypes: prepareTypes(action.payload.types),
      }
    }
    case firmwareUpdateActions.actionTypes.LOAD_DEVICES_SUCCEEDED: {
      const { items, totalCount } = action.payload.devices
      const updatedDevices = [...state.devices, ...items]

      return {
        ...state,
        devices: updatedDevices,
        totalCount,
        isLoading: false,
        loadDevicesError: '',
      }
    }
    case firmwareUpdateActions.actionTypes.SET_PAGE: {
      const { page } = action.payload

      return {
        ...state,
        page,
      }
    }
    case firmwareUpdateActions.actionTypes.SET_ROWS_PER_PAGE: {
      const { rowsPerPage } = action.payload

      return {
        ...state,
        rowsPerPage,
        page: 0,
        isLoading: true,
        devices: [],
        totalCount: 0,
      }
    }
    case firmwareUpdateActions.actionTypes.GO_TO_PREVIOUS_PAGE:
    case firmwareUpdateActions.actionTypes.GO_TO_NEXT_PAGE: {
      const { page } = action.payload

      return {
        ...state,
        page,
      }
    }
    case firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_VERSIONS_SUCCEEDED: {
      return {
        ...state,
        versions: action.payload.versions,
        isLoading: false,
      }
    }
    case firmwareUpdateActions.actionTypes
      .SCHEDULE_UPDATE_FIRMWARE_VERSIONS_SUCCEEDED: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case firmwareUpdateActions.actionTypes.LOAD_DEVICES_FAILED: {
      return {
        ...state,
        loadDevicesError: action.payload.message,
        isLoading: false,
      }
    }
    case firmwareUpdateActions.actionTypes.CANCEL_UPDATE_FIRMWARE_FAILED:
    case firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_TYPES_FAILED:
    case firmwareUpdateActions.actionTypes.LOAD_DEVICES_TYPES_FAILED:
    case firmwareUpdateActions.actionTypes
      .SCHEDULE_UPDATE_FIRMWARE_VERSIONS_FAILED:
    case firmwareUpdateActions.actionTypes.LOAD_FIRMWARE_VERSIONS_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    }
    case firmwareUpdateActions.actionTypes.LOAD_ORGANIZATIONS_SUCCEEDED: {
      return {
        ...state,
        organizationsList: action.payload.organizations,
        isLoading: false,
      }
    }
    default:
      return state
  }
}
