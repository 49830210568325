import { makeStyles } from '@material-ui/core'
import { COLORS } from '../../services/constants'

const styles = (theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px',
  },
  deviceMessage: {
    border: `2px solid ${COLORS.BLACK}`,
    padding: '10px',
  },
  firmWareUpdateContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  customInput: {
    width: '100%',
    height: '55px',
  },
  deviceLocationOnly: {
    marginLeft: '5px',
  },
  customTimeInput: {
    border: `1px solid ${COLORS.BLACK}`,
    borderRadius: '3px',
    padding: '0 4px',
  },
  submitButton: {
    marginTop: '15px',
  },
  checkboxLabel: {
    fontSize: '14px',
  },
  tabsContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-around',
    margin: '0 auto',
  },
  tab: {
    fontFamily: 'Cerebri Sans Pro Regular',
    width: '50%',
  },
  emulatorWrapper: {
    margin: '12px 36px 0',
    display: 'flex',
    paddingBottom: '15px',
  },
  emulatorTitleWrapper: {
    textAlign: 'center',
    marginBottom: '30px',
    marginTop: '44px',
  },
  organizationSelectWrapper: {
    margin: '36px 36px 0',
    width: '30%',
  },
  '@media (min-width: 1224px)': {
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
    },
  },
})

export default styles
export const useStyles = makeStyles(styles)
