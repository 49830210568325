import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import {
  DEVICE_LOGGING_CATEGORIES,
  DEVICE_LOG_LEVELS,
  EnableDeviceLoggingProps,
  LoggingCategories,
  LogLevels,
} from './types'

import { Alert } from '@mui/material'
import isNil from 'lodash/isNil'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { checkConnection } from 'services/root/root-service'
import { useAppSelector } from 'utils/reduxHelpers'
import deviceDetailsActions from '../services/deviceDetails-actions'
import { isAtLeastVersion } from '../utils'
import { useStyles } from './style'

const MINIMUM_DEVICE_VERSION = '2.2.0'

export const EnableDeviceLogging = ({ deviceId }: EnableDeviceLoggingProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [logLevel, setLogLevel] = useState<LogLevels | ''>('')
  const [logCategory, setLogCategory] = useState<LoggingCategories | ''>('')

  const desiredPower = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredPower
  )
  const desiredSafetyDelay = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredSafetyDelay
  )
  const desiredOnTime = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.desiredOnTime
  )
  const removed = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.removed
  )
  const version = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.version
  )

  const supportsLoggingCommands = isAtLeastVersion(
    MINIMUM_DEVICE_VERSION,
    version
  )

  const addLogLevel = () =>
    dispatch(
      checkConnection(() =>
        deviceDetailsActions.updateSettings(
          deviceId,
          {
            desiredOnTime,
            desiredPower,
            desiredSafetyDelay,
            logLevel,
            logCategory,
          },
          () => {
            setLogLevel('')
            setLogCategory('')
          }
        )
      )
    )

  if (removed || !supportsLoggingCommands) {
    return null
  }

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="row"
      flexWrap="wrap"
      sx={
        {
          gap: '10px',
        } as any // MUI :(
      }
    >
      <Box width="100%" mt="10px">
        <b>Enable Logging</b>
      </Box>
      <Box width="100%" fontSize="12px">
        <Alert severity="info">
          You can enable logging below by selecting a category and a
          corresponding level.
          <br /> These settings are not persisted at present: when the device
          restarts, it will lose the logging settings.
        </Alert>
      </Box>
      <Box>
        <FormControl>
          <InputLabel className={styles.logSelectLabel}>
            Log Category
          </InputLabel>
          <Select
            placeholder="Category"
            className={styles.logSelect}
            value={logCategory}
            onChange={(event) => {
              setLogCategory(event.target.value as LoggingCategories)
            }}
          >
            {DEVICE_LOGGING_CATEGORIES.map(({ label, value }) => (
              <MenuItem key={`category-${label}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl>
          <InputLabel className={styles.logSelectLabel}>Log Level</InputLabel>
          <Select
            placeholder="Type"
            className={styles.logSelect}
            value={logLevel}
            onChange={(event) => {
              setLogLevel(event.target.value as LogLevels)
            }}
          >
            {DEVICE_LOG_LEVELS.map(({ label, value }) => (
              <MenuItem key={`type-${label}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Button
          size="medium"
          color="primary"
          onClick={addLogLevel}
          variant="contained"
          disabled={!logCategory || isNil(logLevel) || logLevel === ''}
        >
          Enable Logging
        </Button>
      </Box>
    </Box>
  )
}
