import {
  GET_LOCATIONS_LIST,
  GET_ORGANIZATION,
} from '../../services/organizationDetails-actions'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { checkConnection } from '../../../../../../services/root/root-service'
import AddLocationsButton from './addLocation/AddLocationButton'
import TreeList from './customTree/TreeList'
import LocationsFilter from './LocationsFilter'
import { useStyles } from './style'

type LocationsProps = {
  isReadOnly: boolean
  id: string
}

const Locations = ({ id, isReadOnly }: LocationsProps) => {
  const styles = useStyles()

  const dispatch = useDispatch()

  const getLocationsList = (parentId: string | null) =>
    dispatch(checkConnection(() => GET_LOCATIONS_LIST(parentId, undefined)))
  const getOrganization = (id: string) =>
    dispatch(checkConnection(() => GET_ORGANIZATION(id)))

  useEffect(() => {
    getOrganization(id)
    getLocationsList(null)
  }, [id])

  return (
    <>
      {!isReadOnly && <LocationsFilter />}
      <div className={styles.treeWrapper}>
        {!isReadOnly && <AddLocationsButton />}
        <TreeList isReadOnly={isReadOnly} />
      </div>
    </>
  )
}

export default Locations
