import { Tab, Tabs, Typography } from '@material-ui/core'
import {
  devicesTypesConfig,
  ORGANIZATION_PLACEHOLDER,
} from './_shared/services/emulator-constants'

import { withStyles } from '@material-ui/styles'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import Autocomplete from '../../components/_shared/autocomplete/AutocompleteComponent'
import Loader from '../../components/_shared/loader/Loader'
import { checkConnection } from '../../services/root/root-service'
import styles from './style'
import sharedActions from './_shared/services/emulator-actions'
import { getOrganizationIdLabel } from './_shared/services/emulator-service'

const EventsEmulatorContainer = (props) => {
  const {
    organizationsList,
    selectedOrganization,
    getOrganizations,
    setOrganization,
    isLoading,
    tab,
    setTab,
    classes,
  } = props

  useEffect(() => {
    getOrganizations()
    // eslint-disable-next-line
  }, [])

  const handleDeviceTypeChange = (event, newTab) => {
    setTab(newTab)
  }

  const handleOrganizationSelection = (event, selectedOrg) => {
    setOrganization(selectedOrg)
  }

  const activeTabConfig = find(devicesTypesConfig, { id: tab })

  return (
    <Loader isLoading={isLoading}>
      <div className={classes.emulatorTitleWrapper}>
        <Typography variant="h2">{'Events Emulator'}</Typography>
      </div>

      <Tabs
        onChange={handleDeviceTypeChange}
        value={tab}
        classes={{ flexContainer: classes.tabsContainer }}
        indicatorColor="primary"
      >
        {map(devicesTypesConfig, (item) => (
          <Tab
            label={item.index}
            key={item.id}
            value={item.id}
            className={classes.tab}
          />
        ))}
      </Tabs>

      <div className={classes.organizationSelectWrapper}>
        <Autocomplete
          options={organizationsList}
          value={selectedOrganization}
          onChange={handleOrganizationSelection}
          label={ORGANIZATION_PLACEHOLDER}
        />

        <Typography variant="body2">
          {getOrganizationIdLabel(selectedOrganization?.id)}
        </Typography>
      </div>

      {!isEmpty(selectedOrganization) && (
        <div className={classes.emulatorWrapper} key={activeTabConfig.id}>
          {activeTabConfig.component()}
        </div>
      )}
    </Loader>
  )
}

const mapStateToProps = (state) => {
  const {
    eventsEmulatorReducer: { sharedEmulatorReducer },
  } = state

  return {
    tab: sharedEmulatorReducer.tab,
    organizationsList: sharedEmulatorReducer.organizationsList,
    selectedOrganization: sharedEmulatorReducer.selectedOrganization,
    isLoading: sharedEmulatorReducer.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setTab: (tab) => dispatch(sharedActions.setTab(tab)),
  getOrganizations: () =>
    dispatch(checkConnection(sharedActions.getOrganizations)),
  setOrganization: (orgId) =>
    dispatch(checkConnection(() => sharedActions.setOrganization(orgId))),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EventsEmulatorContainer))
