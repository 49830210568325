import {
  devicesTemplateForSelect,
  extractDevicesForSelect,
} from '../../services/emulator-service'

import { withStyles } from '@material-ui/core/styles'
import CustomSelect from '../../../../components/_shared/CustomSelect'
import { EMULATOR_PAGE_LABELS } from '../../services/emulator-constants'
import styles from './style'

const DevicesSelect = (props) => {
  const { devices, device, setDevice, disabled, classes } = props

  const handleDeviceChange = (event) => {
    const { value } = event.target

    setDevice(value)
  }

  const devicesForSelect = extractDevicesForSelect(devices)

  return (
    <div className={classes.deviceSelectWrapper}>
      <CustomSelect
        options={devicesForSelect}
        template={devicesTemplateForSelect}
        isRequired
        valueName={'imei'}
        value={device}
        onChange={handleDeviceChange}
        label={EMULATOR_PAGE_LABELS.deviceSelect}
        disabled={disabled}
      />
    </div>
  )
}

export default withStyles(styles)(DevicesSelect)
