import 'react-datepicker/dist/react-datepicker.css'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import { yyyyMMdFormat } from '../../../../services/dateFormat-service'
import styles from '../../style'
import { FORM_LABELS } from '../services/emulator-constants'
import EmulatorCustomTimeInput from './EmulatorCustomTimeInput'

const EmulatorCustomInput = forwardRef(
  ({ value, onClick, isDisabled, classes }, ref) => (
    <Button
      className={classes.customInput}
      variant="outlined"
      disabled={isDisabled}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </Button>
  )
)

const EmulatorDatepickerComponent = (props) => {
  const {
    classes,
    changeDateValue,
    time,
    name,
    disabled,
    showTimeInput,
    dateFormat = yyyyMMdFormat,
  } = props

  const filterPassedTime = (time) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)

    return currentDate.getTime() < selectedDate.getTime()
  }

  return (
    <DatePicker
      selected={time}
      showTimeInput={showTimeInput}
      timeInputLabel={FORM_LABELS.TIME_LABEL}
      customInput={
        <EmulatorCustomInput isDisabled={disabled} classes={classes} />
      }
      dateFormat={dateFormat}
      filterTime={filterPassedTime}
      customTimeInput={
        <EmulatorCustomTimeInput
          name={name}
          changeDateValue={changeDateValue}
        />
      }
      onChange={(date) => changeDateValue(date, name)}
    />
  )
}

export default withStyles(styles)(EmulatorDatepickerComponent)
