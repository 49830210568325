import {
  utcConverter,
  yyyyMMDDTHHmmssFormat,
} from '../../../../services/dateFormat-service'

import { TableCell } from '@material-ui/core'
import map from 'lodash/map'
import { DEVICE_TYPE } from '../../../../services/constants'

const Cells = (props) => {
  const { cellsConfig, tableDataRow } = props

  return map(cellsConfig, (value, key) => {
    switch (value?.type) {
      case 'text':
        return (
          <TableCell align="left" key={key} className={key}>
            {(tableDataRow[key] || tableDataRow[key] === false) &&
              tableDataRow[key].toString()}
          </TableCell>
        )
      case 'data':
        return (
          <TableCell align="left" key={key} className={key}>
            {tableDataRow[key] &&
              utcConverter(tableDataRow[key], yyyyMMDDTHHmmssFormat)}
          </TableCell>
        )
      case 'action':
        return (
          <TableCell key={key}>{value.actionComponent(tableDataRow)}</TableCell>
        )
      case 'deviceType':
        return (
          <TableCell align="left" width="80px" key={key} className={key}>
            {DEVICE_TYPE[tableDataRow[key]]?.label || tableDataRow[key]}
          </TableCell>
        )
      default:
        return null
    }
  })
}

export default Cells
