import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    serialNumber: {
      alignItems: 'center',
      display: 'flex',
      gap: '10px',
      justifyContent: 'flex-start',
    },
    tableLink: {
      color: '#1796FF',
      cursor: 'pointer',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
  })
)
