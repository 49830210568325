import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import {
  DEVICE_SETTINGS,
  MODE_VALUES,
} from '../../services/deviceDetails-constants'

import Typography from '@material-ui/core/Typography'
import Alert from '@mui/material/Alert'
import { useMemo } from 'react'
import { useAppSelector } from 'utils/reduxHelpers'
import { SettingsErrors } from './constants'
import InputForTime from './InputForTime'
import { useStyles } from './style'
import WeekDays from './WeekDays'

const FIRMWARE_VERSION_ALERT = 'v2.3'

type ModeProps = {
  desiredDailyStartTime: string
  desiredDailyEndTime: string
  mode: string
  days: boolean[]
  onChangeWeekDays: (weekdays: boolean[]) => void
  onChange: (value: {
    target: {
      name: string
      value: string
    }
  }) => void
  errors: SettingsErrors
}

const Mode = ({
  errors,
  onChange,
  onChangeWeekDays,
  desiredDailyEndTime,
  desiredDailyStartTime,
  mode,
  days,
}: ModeProps) => {
  const styles = useStyles()

  const removed = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.removed
  )

  const showAlert = useMemo(() => {
    return (
      (desiredDailyEndTime || '').length == 5 &&
      (desiredDailyStartTime || '').length == 5 &&
      desiredDailyEndTime < desiredDailyStartTime
    )
  }, [desiredDailyEndTime, desiredDailyStartTime])

  return (
    <>
      <Typography variant="h5" className={styles.subtitle}>
        {DEVICE_SETTINGS.mode}
      </Typography>

      <RadioGroup
        name="mode"
        value={mode}
        onChange={onChange}
        className={styles.radio}
      >
        <FormControlLabel
          id="automatic-mode-checkbox"
          className={styles.checkboxLabel}
          value={MODE_VALUES.automatic}
          control={
            <Radio color="primary" checked={mode === MODE_VALUES.automatic} />
          }
          label={DEVICE_SETTINGS.AUTO}
          disabled={removed}
        />
        <FormControlLabel
          id="schedule-mode-checkbox"
          className={styles.checkboxLabel}
          value={MODE_VALUES.schedule}
          control={
            <Radio color="primary" checked={mode === MODE_VALUES.schedule} />
          }
          label={DEVICE_SETTINGS.SCHEDULED}
          disabled={removed}
        />
      </RadioGroup>

      {mode !== MODE_VALUES.automatic && (
        <>
          <WeekDays desiredDays={days} onChangeDays={onChangeWeekDays} />

          <div className={styles.rangeWrapper}>
            <InputForTime
              name="desiredDailyStartTime"
              error={errors.desiredDailyStartTime}
              label={DEVICE_SETTINGS.dailyStartTime}
              value={desiredDailyStartTime || ''}
              setChanged={onChange}
            />

            <InputForTime
              name="desiredDailyEndTime"
              error={errors.desiredDailyEndTime}
              label={DEVICE_SETTINGS.dailyEndTime}
              value={desiredDailyEndTime || ''}
              setChanged={onChange}
            />

            {showAlert && (
              <Alert severity="info" className={styles.rangeWrapperAlert}>
                End Date refers to next day. This is only available since
                firmware
                {' ' + FIRMWARE_VERSION_ALERT}, in previous versions this will
                be ignored.
              </Alert>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default Mode
