import { Button, Tooltip } from '@material-ui/core'

import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'utils/reduxHelpers'
import ConfirmModal from '../../../../../components/_shared/confirmModal/ConfirmModal'
import { checkConnection } from '../../../../../services/root/root-service'
import devicesListActions from '../services/devicesList-actions'
import { FULL_CLEANUP_DEVICE } from '../services/devicesList-constants'
import { getFullCleanupConfirmText } from '../services/devicesList-service'
import { useStyles } from './style'

type FullCleanupActionProps = {
  id: string
  serialNumber: string
}

const FullCleanupAction = ({ id, serialNumber }: FullCleanupActionProps) => {
  const dispatch = useDispatch()
  const style = useStyles()
  const history = useHistory()

  const isAvailableHost = !location.origin.includes('//factory.rzero.com')

  const error = useAppSelector(
    (state) => state.devicesReducer.devicesListReducer.error
  )
  const canCleanup = useAppSelector(
    (state) => state.devicesReducer.devicesListReducer.abilities.cleanup
  )
  const confirmText = getFullCleanupConfirmText(serialNumber)
  const [open, setOpen] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)

  const cleanupFullById = (
    id: string,
    onSuccess: () => void,
    stopDisablingButtons: () => void
  ) =>
    dispatch(
      checkConnection(() =>
        devicesListActions.fullCleanupDeviceById(
          id,
          onSuccess,
          stopDisablingButtons
        )
      )
    )
  const resetError = () => dispatch(devicesListActions.resetError())

  const handleOpen: React.MouseEventHandler = (event) => {
    event.stopPropagation()

    resetError()
    setOpen(true)
  }

  const onSuccess = () => {
    setOpen(false)
    setDisabled(false)
    history.push('/devices')
  }

  const handleClose: React.MouseEventHandler = (event) => {
    event.stopPropagation()

    onSuccess()
  }

  const onClick: React.MouseEventHandler = (event) => {
    event.stopPropagation()

    setDisabled(true)
    cleanupFullById(id, onSuccess, stopDisablingButtons)
  }

  const stopDisablingButtons = () => {
    setDisabled(false)
  }

  if (!canCleanup || !isAvailableHost) {
    return null
  }

  return (
    <div>
      <Tooltip title={FULL_CLEANUP_DEVICE.tooltip} placement="top">
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          className={style.deviceActionButton}
          id="full-cleanup-btn"
        >
          Full Cleanup
        </Button>
      </Tooltip>
      <ConfirmModal
        isOpen={open}
        onClose={handleClose}
        onSubmit={onClick}
        disabled={disabled}
        error={error}
        confirmText={confirmText}
        modalTitle={FULL_CLEANUP_DEVICE.modalTitle}
        actionLabel={FULL_CLEANUP_DEVICE.actionLabel}
        closeLabel={FULL_CLEANUP_DEVICE.closeLabel}
      />
    </div>
  )
}

export default FullCleanupAction
