const style = () => ({
  defaultFirmwaresPageContainer: {
    padding: '20px 25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  lineWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  typeWrapper: {
    margin: '20px 0',
  },
  subTypeWrapper: {
    margin: '10px',
  },
  firmwareTypeWrapper: {
    margin: '10px 0 10px 20px',
  },
  linkToTable: {
    cursor: 'pointer',
  },
  changeDefaultButton: {
    minWidth: '150px',
  },
  dialogTitle: {
    marginTop: '10px',
  },
  dialogContent: {
    margin: '0 20px 20px',
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    marginTop: '10px',
  },
  statusFilerIcon: {
    position: 'absolute',
    left: '16px',
    top: '16px',
    padding: 0,
  },
  versionsList: {
    textAlign: 'left',
  },
  actionButton: {
    width: 'auto',
    margin: '10px 0',
  },
  helperText: {
    padding: '25px 0',
  },
})

export default style
