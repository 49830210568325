import { Button, Typography } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import map from 'lodash/map'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import actions from '../../services/emulator-actions'
import { COMMANDS_RECEIVER } from '../../services/emulator-constants'
import { filterCommands } from '../../services/emulator-service'
import CommandDataTemplate from './CommandDataTemplate/CommandDataTemplate'
import styles from './style'

const CommandsReceiver = (props) => {
  const {
    getCommands,
    existingCommands,
    setCommandStatus,
    devicePortalURL,
    deviceImei,
    deviceId,
    classes,
  } = props

  useEffect(() => {
    getCommands(deviceId)
    // eslint-disable-next-line
  }, [deviceId])

  const sortedExistingCommands = filterCommands(existingCommands)

  const handleSubmit = () => {
    getCommands(deviceId)
  }

  return (
    <div className={classes.receiverWrapper}>
      <Typography variant="h3" className={classes.title}>
        {COMMANDS_RECEIVER.titleExisting}
      </Typography>
      <div className={classes.refreshButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.refreshButton}
          onClick={handleSubmit}
        >
          {COMMANDS_RECEIVER.buttonLabel}
        </Button>
      </div>
      {!!sortedExistingCommands.length ? (
        map(sortedExistingCommands, (commandData, ind) => (
          <CommandDataTemplate
            key={commandData.uuid}
            commandData={commandData}
            setCommandStatus={setCommandStatus}
            imei={deviceImei}
            id={deviceId}
            devicePortalURL={devicePortalURL}
            count={ind + 1}
          />
        ))
      ) : (
        <Typography variant="h4" className={classes.helperText}>
          {COMMANDS_RECEIVER.noCommandsLabel}
        </Typography>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getCommands: (id) => dispatch(actions.getCommands(id)),
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(CommandsReceiver))
