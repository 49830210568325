import concat from 'lodash/concat'

const DEVICE_TABS = [
  {
    label: 'Arc',
    type: 'UVI_ARC',
    path: 'uvi-arc',
  },
  {
    label: 'Beam',
    type: 'UR_UVGI',
    path: 'ur-uvgi',
  },
  {
    label: 'Far UV',
    type: 'FAR_UVC',
    path: 'far-uvc',
  },
  // NOTE: GDPAPPS-193 - Hide ext devices, this may need to be removed in future.
  // {
  //   label: 'EXT_DEVICE',
  //   type: 'EXT_DEVICE',
  //   path: 'ext-device',
  // },
  {
    label: 'Network',
    type: 'NETWORK_DEVICE',
    path: 'network-device',
  },
  // NOTE: GDPAPPS-263 - Hide ext devices, this may need to be removed in future.
  // {
  //   label: 'Sensors',
  //   type: 'SENSOR',
  //   path: 'sensor',
  // },
]

const EDITABLE_UVGI_FIELDS = [
  {
    name: 'deviceEui',
  },
  {
    name: 'initialFirmwareVersion',
  },
  {
    name: 'baseMac',
  },
  {
    name: 'softApMac',
  },
  {
    name: 'ethernetMac',
  },
]

export const DEVICE_COMMAND_MAPPING = {
  LED_POWER_LEVEL: 'LED Power Level',
  SAFETY_DELAY: 'Safety Delay',
  DAILY_MAX_RUNTIME: 'Daily Max Runtime (Seconds)',
  OPERATION_WINDOW_RUNTIME_MAX: 'Operation Window Runtime Max (Seconds)',
  FACTORY_RESET: 'Factory Reset',
  OPERATION_WINDOW_SIZE: 'Operation Window Size (Seconds)',
  ON_TIME: 'On Time',
  ENABLE_CLEANING: 'Enable Cleaning',
  MODE: 'Mode',
  MESSAGE_FREQUENCY: 'Message Frequency',
  DAILY_START_TIME: 'Daily Start Time',
  DAILY_END_TIME: 'Daily End Time',
  MAXIMUM_TRANSACTION_DURATION: 'Maximum Transaction Duration',
  LOCATION_ID: 'Location ID',
  SCHEDULED_MODE: 'Scheduled Mode',
  DEBUG_MODE: 'Debug Mode',
  CLEAR_CERTIFICATES: 'Clear Certificates',
  CHANGE_CREDENTIALS: 'Change Credentials',
  PULL_CERTIFICATE: 'Pull Certificate',
  CLEAR_CACHE: 'Clear Cache',
  CLEAR_COMMANDS: 'Clear Commands',
  GET_COMMANDS: 'Get Commands',
  GET_STATE: 'Get State',
  GET_CERT_HASHES: 'Get Certificate Hashes',
  DEVICE_RESTART: 'Device Restart',
  MANUAL_START: 'Manual Start',
  MANUAL_FINISH: 'Manual Finish',
  RESTART_CYCLE: 'Restart Cycle',
  CUSTOM_COMMAND1: 'Custom Command 1',
  CUSTOM_COMMAND2: 'Custom Command 2',
  CUSTOM_COMMAND3: 'Custom Command 3',
  SCHEDULED_DOWNTIME: 'Scheduled Downtime',
  LOG_CTRL: 'Remote Logging',
}

const getEditableUvgiFields = (isEditing) => {
  const initialFields = !isEditing
    ? [
        {
          name: 'mcuUid',
          required: true,
        },
      ]
    : []

  return concat(initialFields, EDITABLE_UVGI_FIELDS)
}

export { DEVICE_TABS, getEditableUvgiFields }
