import { createRef } from 'react'

import { withStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import styles from './style'

const BulkUploadInput = (props) => {
  const { setFileName, setFile, classes } = props
  let myInput = createRef()

  const handleSelectedFile = (event) => {
    setFile(event.target.files[0])
    setFileName(event.target.files[0].name)
  }

  return (
    <div id="bulk-upload-form" className={classes.upload}>
      <input
        id="myInput"
        type="file"
        accept=".csv, .tsv"
        ref={(ref) => (myInput = ref)}
        onChange={handleSelectedFile}
        className={classes.displayNone}
        required
      />
      <IconButton onClick={(e) => myInput.click()}>
        <AttachFileIcon />
      </IconButton>
    </div>
  )
}

export default withStyles(styles)(BulkUploadInput)
