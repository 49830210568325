import TextField from '@material-ui/core/TextField'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { checkConnection } from '../../../../../services/root/root-service'
import deviceDetailsActions from '../services/deviceDetails-actions'
import { ADD_DEVICE_LABELS } from '../services/deviceDetails-constants'
import SubTypesSelect from './addDeviceButton/SubTypesSelect'

const EditableNetworkDeviceFields = (props) => {
  const {
    state,
    setState,
    errors,
    onChange,
    networkDeviceTypes,
    getNetworkDeviceTypes,
  } = props

  useEffect(() => {
    if (!networkDeviceTypes.length) {
      getNetworkDeviceTypes()
    }
  }, [networkDeviceTypes])

  return (
    <>
      <SubTypesSelect
        deviceSubType={state.networkDeviceType}
        subTypesForDevice={networkDeviceTypes}
        onChange={onChange}
        error={errors.networkDeviceType}
        name="networkDeviceType"
        label={ADD_DEVICE_LABELS.networkDeviceType}
      />
      <TextField
        name="macAddress"
        variant="outlined"
        label={ADD_DEVICE_LABELS.macAddress}
        value={state.macAddress}
        onChange={onChange}
        error={errors.macAddress}
        fullWidth
        required
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const { deviceDetailsReducer } = state.devicesReducer

  return {
    networkDeviceTypes: deviceDetailsReducer.networkDeviceTypes || [],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNetworkDeviceTypes: () =>
      dispatch(
        checkConnection(() => deviceDetailsActions.getNetworkDeviceTypes())
      ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditableNetworkDeviceFields)
