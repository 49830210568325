import { useState } from 'react'

import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { ADD_DEVICE_LABELS } from '../../services/deviceDetails-constants'
import AddDeviceModal from './AddDeviceModal'
import styles from './style'

const AddDeviceButton = (props) => {
  const { classes } = props

  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.actionButtonContainer}>
      <Button
        id="add-device"
        color="primary"
        variant="contained"
        onClick={openModal}
      >
        {ADD_DEVICE_LABELS.addDevice}
      </Button>
      <AddDeviceModal open={open} onClose={onClose} />
    </div>
  )
}

export default withStyles(styles)(AddDeviceButton)
