import { FILTER_LABELS, TITLES } from '../../services/firmwareUpdate-constants'
import {
  filterInitialState,
  prepareFilterQuery,
} from '../../services/firmwareUpdate-service'

import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import Autocomplete from '../../../../components/_shared/autocomplete/AutocompleteComponent'
import CustomSelect from '../../../../components/_shared/CustomSelect'
import { checkConnection } from '../../../../services/root/root-service'
import { ORGANIZATION_PLACEHOLDER } from '../../../EventsEmulator/_shared/services/emulator-constants'
import actions from '../../services/firmwareUpdate-actions'
import styles from './../../style'

const DevicesFilterComponent = (props) => {
  const {
    filterState,
    setFilterState,
    loadDevices,
    firmwareType = '',
    updateFirmwareType,
    setSelected,
    firmwareTypes,
    applyFilter,
    resetState,
    loadOrganizations,
    organizationsList,
    classes,
  } = props

  useEffect(() => {
    loadOrganizations()
    // eslint-disable-next-line
  }, [])

  const _messageTypeTemplate = (item) => item.label

  const changeTextField = (event) => {
    setFilterState({
      ...filterState,
      [event.target.name]: event.target.value.trim(),
    })
  }

  const changeFilterType = (event) => {
    updateFirmwareType(event.target.value)
  }

  const changeOrganization = (event, selectedOrg) => {
    setFilterState({
      ...filterState,
      organization: selectedOrg,
    })
  }

  const onApply = (event) => {
    event.preventDefault()

    const query = prepareFilterQuery({
      ...filterState,
      firmwareType,
    })

    setSelected([])
    applyFilter()
    loadDevices(query)
  }

  const resetFilter = () => {
    setFilterState(filterInitialState)

    applyFilter()
    loadDevices()
    resetState()
  }

  const grtOrganizationLabel = (option) => {
    return option && option.name ? `${option.name} (id:${option.id})` : ''
  }

  return (
    <>
      <Typography className={classes.title} variant="h6" component="div">
        {TITLES.devicesFilter}
      </Typography>
      <form onSubmit={onApply} className={classes.filterWrapper}>
        <div className={classes.deviceFilterWrapper}>
          <TextField
            name="serialNumber"
            className={classes.field}
            label={FILTER_LABELS.serialNumber}
            value={filterState.serialNumber}
            onChange={changeTextField}
            variant="outlined"
          />
          <div className={classes.selector}>
            <CustomSelect
              options={firmwareTypes}
              label={FILTER_LABELS.firmwareType}
              onChange={changeFilterType}
              value={firmwareType}
              valueName="value"
              template={_messageTypeTemplate}
            />
          </div>
          <TextField
            name="version"
            className={classes.field}
            label={FILTER_LABELS.actualVersion}
            value={filterState.version}
            onChange={changeTextField}
            variant="outlined"
          />
          <TextField
            name="desiredVersion"
            className={classes.field}
            label={FILTER_LABELS.desiredVersion}
            value={filterState.desiredVersion}
            onChange={changeTextField}
            variant="outlined"
          />
          {organizationsList.length && (
            <div className={classes.autocomplete}>
              <Autocomplete
                options={organizationsList}
                isCustomLabel={true}
                getCustomLabel={grtOrganizationLabel}
                value={filterState.organization}
                onChange={changeOrganization}
                label={ORGANIZATION_PLACEHOLDER}
              />
            </div>
          )}
        </div>
        <Button
          type="submit"
          id="filters-submit"
          variant="contained"
          className={classes.actionButton}
          color="primary"
        >
          {FILTER_LABELS.applyFilter}
        </Button>
        <Button
          id="reset-filter"
          variant="contained"
          onClick={resetFilter}
          className={classes.actionButton}
          color="primary"
        >
          {FILTER_LABELS.resetFilter}
        </Button>
      </form>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadOrganizations: () =>
      dispatch(checkConnection(actions.loadOrganizations)),
  }
}

const mapStateToProps = (state) => ({
  firmwareTypes: state.firmwareUpdateReducer.firmwareTypes,
  organizationsList: state.firmwareUpdateReducer.organizationsList,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DevicesFilterComponent))
