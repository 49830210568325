const failedDevicesActions = {}

failedDevicesActions.actionTypes = {
  GET_FAILED_DEVICES: '{Failed Devices} Get Devices',
  GET_FAILED_DEVICES_SUCCEEDED: '{Failed Devices} Get Devices Succeeded',
  GET_FAILED_DEVICES_FAILED: '{ Failed Devices} Get Devices Failed',

  SET_PAGE: '{Failed Devices} Set Page',
  SET_ROWS_PER_PAGE: '{Failed Devices} Set Rows Per Page For Devices',

  GO_TO_NEXT_PAGE: '{Failed Devices} Go To Next Page',
  GO_TO_PREVIOUS_PAGE: '{Failed Devices} Go To Previous Page',

  RESET_FAILED_DEVICES: '{Failed Devices} Reset Devices',
}

failedDevicesActions.goToNextPage = function (page) {
  return {
    type: failedDevicesActions.actionTypes.GO_TO_NEXT_PAGE,
    payload: {
      page,
    },
  }
}

failedDevicesActions.goToPreviousPage = function (page) {
  return {
    type: failedDevicesActions.actionTypes.GO_TO_PREVIOUS_PAGE,
    payload: {
      page,
    },
  }
}

failedDevicesActions.setRowsPerPage = (rowsPerPage) => {
  return {
    type: failedDevicesActions.actionTypes.SET_ROWS_PER_PAGE,
    payload: {
      rowsPerPage,
    },
  }
}

failedDevicesActions.getFailedDevices = () => {
  return {
    type: failedDevicesActions.actionTypes.GET_FAILED_DEVICES,
  }
}

failedDevicesActions.getFailedDevicesSuccess = (failedDevices) => {
  return {
    type: failedDevicesActions.actionTypes.GET_FAILED_DEVICES_SUCCEEDED,
    payload: {
      failedDevices,
    },
  }
}

failedDevicesActions.getFailedDevicesFailed = (message) => {
  return {
    type: failedDevicesActions.actionTypes.GET_FAILED_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

failedDevicesActions.setPage = (page) => {
  return {
    type: failedDevicesActions.actionTypes.SET_PAGE,
    payload: {
      page,
    },
  }
}

failedDevicesActions.resetFailedDevices = () => {
  return {
    type: failedDevicesActions.actionTypes.RESET_FAILED_DEVICES,
  }
}

export default failedDevicesActions
