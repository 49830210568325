import { call, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  EXPAND_NODE,
  LOAD_NODES_SUCCEEEDED,
} from '../components/Locations/customTree/services/customTree-actions'
import {
  ADD_LOCATION,
  ADD_LOCATIONS_BULK,
  ADD_LOCATIONS_BULK_FAILED,
  ADD_LOCATIONS_BULK_SUCCEEDED,
  ADD_LOCATION_FAILED,
  ADD_LOCATION_SUCCEEDED,
  BULK_UPLOAD_LOCATIONS,
  BULK_UPLOAD_LOCATIONS_FAILED,
  BULK_UPLOAD_LOCATIONS_SUCCEEDED,
  CHANGE_FEATURE_FOR_ORGANIZATION,
  CHANGE_FEATURE_FOR_ORGANIZATION_FAILED,
  CHANGE_FEATURE_FOR_ORGANIZATION_SUCCEEDED,
  DELETE_LOCATION_WITH_SUBLOCATIONS,
  DELETE_LOCATION_WITH_SUBLOCATIONS_FAILED,
  DELETE_LOCATION_WITH_SUBLOCATIONS_SUCCEEDED,
  DELETE_WIFI_CREDENTIAL,
  DELETE_WIFI_CREDENTIAL_FAILED,
  DELETE_WIFI_CREDENTIAL_SUCCEEDED,
  DOWNLOAD_WIFI_CREDENTIALS,
  GET_BULK_TEMPLATE,
  GET_BULK_TEMPLATE_FAILED,
  GET_BULK_TEMPLATE_SUCCEEDED,
  GET_FEATURES,
  GET_FEATURES_FAILED,
  GET_FEATURES_SUCCEEDED,
  GET_LOCATIONS_LIST,
  GET_LOCATIONS_LIST_FAILED,
  GET_LOCATION_SCHEMA_FAILED,
  GET_LOCATION_SCHEMA_SUCCEEDED,
  GET_ORGANIZATION,
  GET_ORGANIZATION_FAILED,
  GET_ORGANIZATION_SUCCEEDED,
  GET_SUBLOCATIONS_COUNT,
  GET_SUBLOCATIONS_COUNT_FAILED,
  GET_SUBLOCATIONS_COUNT_SUCCESS,
  GET_WIFI_CREDENTIALS,
  GET_WIFI_CREDENTIALS_FAILED,
  GET_WIFI_CREDENTIALS_SUCCEEDED,
  PUT_WIFI_CREDENTIAL,
  PUT_WIFI_CREDENTIAL_FAILED,
  UPDATE_LOCATION,
  UPDATE_LOCATION_FAILED,
  UPDATE_LOCATION_SUCCEEDED,
  UPDATE_LOCATION_TREE,
  UPDATE_LOCATION_TREE_FAILED,
  UPDATE_SF_ID_FAILED,
  UPDATE_SF_ID_START,
  UPDATE_SF_ID_SUCCEEDED,
  UPDATE_STATUS_FAILED,
  UPDATE_STATUS_START,
  UPDATE_STATUS_SUCCEEDED,
  UPDATE_TYPE_FAILED,
  UPDATE_TYPE_START,
  UPDATE_TYPE_SUCCEEDED,
} from './organizationDetails-actions'

import isString from 'lodash/isString'
import some from 'lodash/some'
import { HttpClient } from '../../../../../services/HttpClient'
import { queryForGetLocationsList } from './organizationDetails-service'

export const getAccount = (state) =>
  state.organizationReducer.selectedAccountOwner
export const getOrganizationId = (state) =>
  state.organizationDetailsReducer.organizationId

function* getOrganization(action) {
  try {
    const { id } = action.payload

    if (id) {
      const data = yield call(() => {
        return HttpClient.get(
          `/api/secured/organizations/scroll?query=(id eq ${id})`
        )
      })

      if (data.message) {
        yield put(GET_ORGANIZATION_FAILED(data.message))
      } else {
        yield put(GET_ORGANIZATION_SUCCEEDED(data))
      }
    } else {
      yield put(GET_ORGANIZATION_SUCCEEDED({}))
    }
  } catch (e) {
    yield put(GET_ORGANIZATION_FAILED(e.message))
  }
}

function* getFeatures(action) {
  try {
    const { id } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/feature/${id}`)
    })

    if (data.message) {
      yield put(GET_FEATURES_FAILED(data.message))
    } else {
      yield put(GET_FEATURES_SUCCEEDED(data))
    }
  } catch (e) {
    yield put(GET_FEATURES_FAILED(e.message))
  }
}

function* changeFeatureForOrganization(action) {
  try {
    const { id, code, checked } = action.payload

    const data = yield call(() => {
      return checked
        ? HttpClient.post(`/api/secured/feature/${id}/${code}`)
        : HttpClient.delete(`/api/secured/feature/${id}/${code}`)
    })

    if (data.message) {
      yield put(CHANGE_FEATURE_FOR_ORGANIZATION_FAILED(data.message))
    } else {
      yield put(CHANGE_FEATURE_FOR_ORGANIZATION_SUCCEEDED(code, checked))
    }
  } catch (e) {
    yield put(CHANGE_FEATURE_FOR_ORGANIZATION_FAILED(e.message))
  }
}

function* getWiFiCredentials(action) {
  try {
    const { organizationId } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/wi-fi-credential/${organizationId}`)
    })

    if (data.message) {
      yield put(GET_WIFI_CREDENTIALS_FAILED(data.message))
    } else {
      yield put(GET_WIFI_CREDENTIALS_SUCCEEDED(data))
    }
  } catch (e) {
    yield put(GET_WIFI_CREDENTIALS_FAILED(e.message))
  }
}

function* downloadWifiCredentials({ payload: { id, filename } }) {
  yield call(() => {
    return HttpClient.post(`/api/generate-wifi-credentials/${id}`, {
      useGDPA: true,
      isDownload: true,
      filename,
    })
  })
}

function* putWiFiCredential(action) {
  try {
    const { body, onSuccess } = action.payload

    const { organizationId } = body

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/wi-fi-credential/${organizationId}`, {
        body,
      })
    })

    if (some(data)) {
      yield put(PUT_WIFI_CREDENTIAL_FAILED(data))
    } else {
      yield put(GET_WIFI_CREDENTIALS(organizationId))

      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(PUT_WIFI_CREDENTIAL_FAILED(e.message))
  }
}

function* deleteWiFiCredential(action) {
  try {
    const { id } = action.payload

    const data = yield call(() => {
      return HttpClient.delete(`/api/secured/wi-fi-credential/${id}`)
    })

    if (data.message) {
      yield put(DELETE_WIFI_CREDENTIAL_FAILED(data.message))
    } else {
      yield put(DELETE_WIFI_CREDENTIAL_SUCCEEDED(id))
    }
  } catch (e) {
    yield put(DELETE_WIFI_CREDENTIAL_FAILED(e.message))
  }
}

function* getLocationsList(action) {
  try {
    const { locationType, parentId, organizationName } = action.payload
    const organizationId = yield select(getOrganizationId)
    const query = queryForGetLocationsList(
      organizationId,
      locationType,
      parentId,
      organizationName
    )

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/locations/scroll?order.name=asc&query=${query}`
      )
    })

    if (data.message) {
      yield put(GET_LOCATIONS_LIST_FAILED(data.message))
    } else {
      if (!parentId) {
        yield put(LOAD_NODES_SUCCEEEDED(data, locationType, parentId))
      } else {
        yield put(EXPAND_NODE(data, parentId))
      }
    }
  } catch (e) {
    yield put(GET_LOCATIONS_LIST_FAILED(e.message))
  }
}

function* getLocationSchema(action) {
  try {
    const id = yield select(getOrganizationId)

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/location-schema-definition/current-schema?orgId=${id}`
      )
    })

    if (data.message) {
      yield put(GET_LOCATION_SCHEMA_FAILED(data.message))
    } else {
      yield put(GET_LOCATION_SCHEMA_SUCCEEDED(data))
    }
  } catch (e) {
    yield put(GET_LOCATION_SCHEMA_FAILED(e.message))
  }
}

function* bulkUploadLocations(action) {
  try {
    const { body, onClose } = action.payload
    const id = yield select(getOrganizationId)

    const data = yield call(() => {
      // make sure not to set the Content-Type header. The browser will set it for you, including the boundary parameter.
      return HttpClient.post(`/api/secured/locations/bulk-file?orgId=${id}`, {
        body,
        headers: new Headers({}),
        isFormData: true,
      })
    })

    if (data.message) {
      yield put(BULK_UPLOAD_LOCATIONS_FAILED(data.message))
    } else {
      yield put(BULK_UPLOAD_LOCATIONS_SUCCEEDED(data))

      onClose && onClose()
    }
  } catch (e) {
    yield put(BULK_UPLOAD_LOCATIONS_FAILED(e.message))
  }
}

function* getBulkTemplate() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/locations/bulk-template', {
        isDownload: true,
      })
    })

    if (data.message || isString(data)) {
      yield put(GET_BULK_TEMPLATE_FAILED(data.message))
    } else {
      yield put(GET_BULK_TEMPLATE_SUCCEEDED(data))
    }
  } catch (e) {
    yield put(GET_BULK_TEMPLATE_FAILED(e.message))
  }
}

function* updateSalesforceID(action) {
  const { salesForceId, id } = action.payload
  const data = yield call(() => {
    return HttpClient.put(`/api/secured/organizations/${id}`, {
      body: { salesForceId },
    })
  })

  if (data.message || isString(data)) {
    yield put(UPDATE_SF_ID_FAILED(data.message))
  } else {
    yield put(UPDATE_SF_ID_SUCCEEDED(data))
  }
}

function* updateStatus(action) {
  const { status, id } = action.payload
  const data = yield call(() => {
    return HttpClient.put(`/api/secured/organizations/${id}`, {
      body: { status },
    })
  })

  if (data.message || isString(data)) {
    yield put(UPDATE_STATUS_FAILED(data.message))
  } else {
    yield put(UPDATE_STATUS_SUCCEEDED(data))
  }
}

function* updateType(action) {
  const { type, id } = action.payload
  const data = yield call(() => {
    return HttpClient.put(`/api/secured/organizations/${id}`, {
      body: { type },
    })
  })

  if (data.message || isString(data)) {
    yield put(UPDATE_TYPE_FAILED(data.message))
  } else {
    yield put(UPDATE_TYPE_SUCCEEDED(data))
  }
}

function* updateLocationTree(action) {
  try {
    const { organizationId, locationSchemaId } = action.payload

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/organizations/${organizationId}/locationTree/${locationSchemaId}`
      )
    })

    if (data.message || isString(data)) {
      yield put(UPDATE_LOCATION_TREE_FAILED(data.message))
    } else {
      yield put(UPDATE_LOCATION_TREE_SUCCESS(locationSchemaId))
    }
  } catch (e) {
    yield put(UPDATE_LOCATION_TREE_FAILED(e.message))
  }
}

function* addLocation(action) {
  try {
    const { body, onSuccess } = action.payload
    const id = yield select(getOrganizationId)

    const data = yield call(() => {
      return HttpClient.post(`/api/secured/locations/?orgId=${id}`, { body })
    })

    if (data.message) {
      yield put(ADD_LOCATION_FAILED(data.message))
    } else {
      yield put(ADD_LOCATION_SUCCEEDED(data))

      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(ADD_LOCATION_FAILED(e.message))
  }
}

function* addLocationsBulk(action) {
  try {
    const { body, onSuccess } = action.payload
    const id = yield select(getOrganizationId)

    const data = yield call(() => {
      return HttpClient.post(`/api/secured/locations/bulk?orgId=${id}`, {
        body,
      })
    })

    if (data.message) {
      yield put(ADD_LOCATIONS_BULK_FAILED(data.message))
    } else {
      yield put(ADD_LOCATIONS_BULK_SUCCEEDED(data))

      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(ADD_LOCATIONS_BULK_FAILED(e.message))
  }
}

function* getSubLocationsCount(action) {
  try {
    const { id } = action.payload

    if (id) {
      const data = yield call(() => {
        return HttpClient.get(`/api/secured/locations/${id}/sub-locations`)
      })

      if (data.message) {
        yield put(GET_SUBLOCATIONS_COUNT_FAILED(data.message))
      } else {
        yield put(GET_SUBLOCATIONS_COUNT_SUCCESS(data.subLocationsCount))
      }
    }
  } catch (e) {
    yield put(GET_SUBLOCATIONS_COUNT_FAILED(e.message))
  }
}

function* deleteLocationWithSubLocations(action) {
  try {
    const { id, parentId, count, handleClose } = action.payload
    const orgId = yield select(getOrganizationId)

    const data = yield call(() => {
      return HttpClient.delete(
        `/api/secured/locations/${id}/sub-locations/${count}?orgId=${orgId}`
      )
    })

    if (data.message) {
      yield put(DELETE_LOCATION_WITH_SUBLOCATIONS_FAILED(data.message))
    } else {
      yield put(DELETE_LOCATION_WITH_SUBLOCATIONS_SUCCEEDED(id, parentId))

      handleClose()
    }
  } catch (e) {
    yield put(DELETE_LOCATION_WITH_SUBLOCATIONS_FAILED(e.message))
  }
}

function* updateLocation(action) {
  try {
    const { location, onSuccess } = action.payload
    const orgId = yield select(getOrganizationId)

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/locations/${location.id}?orgId=${orgId}`,
        { body: location }
      )
    })

    if (data.message) {
      yield put(UPDATE_LOCATION_FAILED(data.message))
    } else {
      yield put(UPDATE_LOCATION_SUCCEEDED(data))

      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(UPDATE_LOCATION_FAILED(e.message))
  }
}

function* getOrganizationSaga() {
  yield takeLatest(GET_ORGANIZATION.getType(), getOrganization)
}

function* getFeaturesSaga() {
  yield takeLatest(GET_FEATURES.getType(), getFeatures)
}

function* changeFeatureForOrganizationSaga() {
  yield takeLatest(
    CHANGE_FEATURE_FOR_ORGANIZATION.getType(),
    changeFeatureForOrganization
  )
}

function* getWiFiCredentialsSaga() {
  yield takeLatest(GET_WIFI_CREDENTIALS.getType(), getWiFiCredentials)
}

function* downloadWifiCredentialsSaga() {
  yield takeLatest(DOWNLOAD_WIFI_CREDENTIALS.getType(), downloadWifiCredentials)
}

function* putWiFiCredentialSaga() {
  yield takeLatest(PUT_WIFI_CREDENTIAL.getType(), putWiFiCredential)
}

function* deleteWiFiCredentialSaga() {
  yield takeLatest(DELETE_WIFI_CREDENTIAL.getType(), deleteWiFiCredential)
}

function* getLocationsListSaga() {
  yield takeLatest(GET_LOCATIONS_LIST.getType(), getLocationsList)
}

function* getLocationSchemaSaga() {
  yield takeLatest(GET_ORGANIZATION_SUCCEEDED.getType(), getLocationSchema)
}

function* bulkUploadLocationsSaga() {
  yield takeLatest(BULK_UPLOAD_LOCATIONS.getType(), bulkUploadLocations)
}

function* addLocationSaga() {
  yield takeLatest(ADD_LOCATION.getType(), addLocation)
}

function* addLocationsBulkSaga() {
  yield takeLatest(ADD_LOCATIONS_BULK.getType(), addLocationsBulk)
}

function* getSubLocationsCountSaga() {
  yield takeLatest(GET_SUBLOCATIONS_COUNT.getType(), getSubLocationsCount)
}

function* deleteLocationWithSubLocationsSaga() {
  yield takeLatest(
    DELETE_LOCATION_WITH_SUBLOCATIONS.getType(),
    deleteLocationWithSubLocations
  )
}

function* updateLocationSaga() {
  yield takeLatest(UPDATE_LOCATION.getType(), updateLocation)
}

function* getBulkTemplateSaga() {
  yield takeLatest(GET_BULK_TEMPLATE.getType(), getBulkTemplate)
}

function* updateStatusSaga() {
  yield takeLatest(UPDATE_STATUS_START.getType(), updateStatus)
}

function* updateSalesforceIDSaga() {
  yield takeLatest(UPDATE_SF_ID_START.getType(), updateSalesforceID)
}

function* updateTypeSaga() {
  yield takeLatest(UPDATE_TYPE_START.getType(), updateType)
}

function* updateLocationTreeSaga() {
  yield takeLatest(UPDATE_LOCATION_TREE.getType(), updateLocationTree)
}

export default function* organizationDetailsSaga() {
  yield fork(getOrganizationSaga)
  yield fork(getFeaturesSaga)
  yield fork(changeFeatureForOrganizationSaga)
  yield fork(getWiFiCredentialsSaga)
  yield fork(putWiFiCredentialSaga)
  yield fork(deleteWiFiCredentialSaga)
  yield fork(downloadWifiCredentialsSaga)
  yield fork(getLocationsListSaga)
  yield fork(getLocationSchemaSaga)
  yield fork(bulkUploadLocationsSaga)
  yield fork(addLocationSaga)
  yield fork(addLocationsBulkSaga)
  yield fork(getSubLocationsCountSaga)
  yield fork(deleteLocationWithSubLocationsSaga)
  yield fork(updateLocationSaga)
  yield fork(getBulkTemplateSaga)
  yield fork(updateStatusSaga)
  yield fork(updateSalesforceIDSaga)
  yield fork(updateTypeSaga)
  yield fork(updateLocationTreeSaga)
}
