import { useEffect, useState } from 'react'
import {
  DEFAULT_DEVICE_INFO,
  DEVICE_STATISTIC_LABELS,
} from '../UVGI/services/uvgiEmulator-constants'
import {
  FORM_LABELS,
  META_INFO_LABELS_EVENTS,
} from '../_shared/services/emulator-constants'
import {
  durationFieldCondition,
  prepareMetaInfoRequestBody,
  validateNumericFields,
} from '../_shared/services/emulator-service'
import {
  DEFAULT_META_INFO,
  firmwareStatuses,
  firmwareUpdateStatuses,
  firmwareUpdateTypes,
  firmwareUpdateTypesRev,
  initialState,
  messageConstants,
  metaInfoValues,
} from './services/arcEmulator-constants'
import {
  getCommDevicesInfo,
  getDeviceBySerialNumber,
  prepareRequestParams,
  validateValues,
} from './services/arcEmulator-service'

import { FormControlLabel } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import indexOf from 'lodash/indexOf'
import { JsonTable } from 'react-json-to-html'
import { connect } from 'react-redux'
import NetworkError from '../../../components/_shared/errors/NetworkError'
import { DEVICE_TYPE } from '../../../services/constants'
import { checkConnection } from '../../../services/root/root-service'
import styles from '../style'
import CommonDevicesInfoRow from '../UVGI/components/CommonDeviceInfoRow'
import CommentField from '../_shared/components/CommentField'
import DefaultPart from '../_shared/components/DefaultPart'
import EmulatorStatistics from '../_shared/components/EmulatorStatistics'
import ExpandableTable from '../_shared/components/ExpandableTable'
import FirmwareUpdateMessage from '../_shared/components/FirmwareUpdate'
import MetaInfoRow from '../_shared/components/MetaInfoRow'
import RequestParameters from '../_shared/components/RequestParameters'
import SpecialField from './components/ExtendedMode'
import actions from './services/arcEmulator-actions'

const ARCEmulatorContainer = (props) => {
  const {
    loadDevices,
    loadLocations,
    sendDeviceMessage,
    deviceMessage,
    error,
    devices,
    locations,
    allLocations,
    networkError,
    classes,
  } = props
  const [state, setState] = useState(initialState)
  const [firmwareUpdateOptions, setFirmwareUpdateOptions] =
    useState(firmwareUpdateTypes)

  useEffect(() => {
    loadDevices()
    // eslint-disable-next-line
  }, [])

  const changeValue = (event, name) => {
    setState({
      ...state,
      [name]: event.target.value,
    })
  }

  const changeDevice = (event) => {
    const { value } = event.target
    const deviceDetails = getDeviceBySerialNumber(devices, value)
    const { imei, deviceSubType } = deviceDetails
    const options =
      deviceSubType === DEVICE_TYPE.arc
        ? firmwareUpdateTypes
        : firmwareUpdateTypesRev
    setFirmwareUpdateOptions(options)

    setState({
      ...state,
      deviceName: value,
      imei,
      imeiCopy: imei,
    })

    if (state.deviceLocationOnly) {
      loadLocations(value)
    }
  }

  const changeDateValue = (value, name) => {
    setState({
      ...state,
      [name]: value,
    })
  }

  const changeCheckbox = (event) => {
    const { checked } = event.target
    const { deviceName } = state

    setState({
      ...state,
      deviceLocationOnly: checked,
    })

    if (checked && deviceName) {
      loadLocations(deviceName)
    }
  }

  const changeUseCurrentTimeCheckbox = (event) => {
    const { checked } = event.target

    setState({
      ...state,
      useCurrentTime: checked,
    })
  }

  const changeMessageType = (event) => {
    const { value } = event.target

    setState({
      ...state,
      messageTypeText: value,
      isDurationFieldHidden: durationFieldCondition.indexOf(value) === -1,
      isFirmwareUpdate: firmwareStatuses.indexOf(value) !== -1,
      firmwareUpdateStatus: firmwareUpdateStatuses[value] || '',
      isAdditionalParams: value === 'request parameters',
      isStatisticMessage: value === 'statistics',
      isSubmitted: false,
    })
  }

  const onSubmitButton = () => {
    const { messageTypeText, isStatisticMessage, commonDevicesInfo, metaInfo } =
      state
    const isValid = validateValues(state, messageTypeText)
    const params = prepareRequestParams(state, messageTypeText)

    const body = {
      metaInfo: prepareMetaInfoRequestBody(metaInfo),
    }

    if (isStatisticMessage) {
      body.commDevicesInfo = getCommDevicesInfo([...commonDevicesInfo])
    }

    if (isValid) {
      sendDeviceMessage(params.join('&'), body)
    }

    setState({
      ...state,
      isSubmitted: true,
    })
  }

  const {
    deviceName,
    isSubmitted,
    locationId,
    messageTypeText,
    firmwareUpdateStatus,
    firmwareUpdateVersion,
    firmwareUpdateTime,
    firmwareUpdateType,
    bulbChangeDate,
    deviceRevision,
    softwareRevision,
    bulbCyclesSinceChange,
    powerCyclesSinceChange,
    duration,
    abuseEvents,
    deviceLocationOnly,
    comment,
    imei,
    imeiCopy,
    transactionId,
    deviceDateTime,
    useCurrentTime,
    specialMode1,
    specialMode2,
    metaInfo,
    commonDevicesInfo,
  } = state

  return networkError ? (
    <NetworkError />
  ) : (
    <>
      <div className={classes.formContainer}>
        {imei !== imeiCopy && (
          <Typography className={classes.checkboxLabel} variant="subtitle2">
            {FORM_LABELS.SERIAL_NUMBER}
          </Typography>
        )}
        <FormControlLabel
          classes={{ label: classes.checkboxLabel }}
          className={classes.deviceLocationOnly}
          control={
            <Checkbox
              checked={deviceLocationOnly}
              onChange={changeCheckbox}
              color="primary"
            />
          }
          label={FORM_LABELS.DEVICE_LOCATION_ONLY}
        />
        <DefaultPart
          deviceLocationOnly={deviceLocationOnly}
          isARC
          changeUseCurrentTimeCheckbox={changeUseCurrentTimeCheckbox}
          useCurrentTime={useCurrentTime}
          devices={devices}
          deviceDateTime={deviceDateTime}
          isSubmitted={isSubmitted}
          deviceName={deviceName}
          locations={locations}
          allLocations={allLocations}
          locationId={locationId}
          messageTypeText={messageTypeText}
          transactionId={transactionId}
          changeDateValue={changeDateValue}
          changeValue={changeValue}
          changeMessageType={changeMessageType}
          messageConstants={messageConstants}
          changeDevice={changeDevice}
        />
        {!state.isDurationFieldHidden && (
          <TextField
            id="outlined-basic"
            required
            error={isSubmitted && !validateNumericFields(duration)}
            value={duration}
            onChange={(e) => {
              changeValue(e, 'duration')
            }}
            label={FORM_LABELS.DURATION}
            variant="outlined"
          />
        )}
        {state.isFirmwareUpdate && (
          <FirmwareUpdateMessage
            version={firmwareUpdateVersion}
            status={firmwareUpdateStatus}
            messageType={messageTypeText}
            time={firmwareUpdateTime}
            type={firmwareUpdateType}
            changeValue={changeValue}
            isSubmitted={isSubmitted}
            firmwareOptions={firmwareUpdateOptions}
            changeDateValue={changeDateValue}
          />
        )}
        {state.isStatisticMessage && (
          <>
            <EmulatorStatistics
              date={bulbChangeDate}
              deviceRevision={deviceRevision}
              softwareRevision={softwareRevision}
              bulbCyclesSinceChange={bulbCyclesSinceChange}
              powerCyclesSinceChange={powerCyclesSinceChange}
              abuseEvents={abuseEvents}
              changeValue={changeValue}
              changeDateValue={changeDateValue}
              isSubmitted={isSubmitted}
            />
            <ExpandableTable
              CustomTableRow={CommonDevicesInfoRow}
              propName={'commonDevicesInfo'}
              isSubmitted={isSubmitted}
              tableTitle={DEVICE_STATISTIC_LABELS.commDevicesInfo}
              columnNames={DEVICE_STATISTIC_LABELS.commDevicesInfoColumnsNames}
              defaultValue={{ ...DEFAULT_DEVICE_INFO }}
              tableData={commonDevicesInfo}
              changeValue={changeDateValue}
            />
          </>
        )}
        {state.isAdditionalParams && (
          <RequestParameters
            deviceRevision={deviceRevision}
            softwareRevision={softwareRevision}
            changeValue={changeValue}
            imei={imei}
          />
        )}
        {state.isExtended && (
          <SpecialField
            changeValue={changeValue}
            specialMode1={specialMode1}
            specialMode2={specialMode2}
          />
        )}
        {indexOf(metaInfoValues, messageTypeText) !== -1 && (
          <ExpandableTable
            CustomTableRow={MetaInfoRow}
            propName={'metaInfo'}
            isSubmitted={isSubmitted}
            tableTitle={META_INFO_LABELS_EVENTS.tableTitle}
            columnNames={META_INFO_LABELS_EVENTS.tableColumnsLabels}
            defaultValue={{ ...DEFAULT_META_INFO }}
            tableData={metaInfo}
            changeValue={changeDateValue}
          />
        )}
        {messageTypeText && (
          <CommentField changeValue={changeValue} comment={comment} />
        )}
        <Button
          variant="contained"
          className={classes.submitButton}
          color="primary"
          onClick={onSubmitButton}
        >
          {FORM_LABELS.SUBMIT}
        </Button>
      </div>
      <div className={classes.messageContainer}>
        {!error && deviceMessage && <JsonTable json={deviceMessage} />}
        {error && (
          <Typography variant="subtitle2" color="error">
            {error}
          </Typography>
        )}
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendDeviceMessage: (data, body) =>
      dispatch(checkConnection(() => actions.sendDeviceMessage(data, body))),
    loadLocations: (serialNumber) =>
      dispatch(
        checkConnection(() => actions.loadMessageLocations(serialNumber))
      ),
    loadDevices: () => dispatch(checkConnection(actions.loadMessageDevices)),
  }
}

const mapStateToProps = (state) => {
  const {
    eventsEmulatorReducer: { arcEmulatorReducer },
    rootReducer,
  } = state

  return {
    deviceMessage: arcEmulatorReducer.deviceMessage,
    error: arcEmulatorReducer.errorFromDeviceMessage,
    devices: arcEmulatorReducer.messageDevices,
    locations: arcEmulatorReducer.messageLocations,
    allLocations: arcEmulatorReducer.allMessageLocations,
    networkError: rootReducer.networkError,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ARCEmulatorContainer))
