import { combineReducers } from 'redux'
import { arcEmulatorReducer } from '../../ARC/services/arcEmulator-reducer'
import { ExtEmulatorReducer } from '../../EXT_DEVICES/services/extEmulator-reducer'
import { netEmulatorReducer as netEmulator } from '../../NET_DEVICES/services/netEmulator-reducer'
import { UVGIEmulatorReducer } from '../../UVGI/services/uvgiEmulator-reducer'
import { sharedEmulatorReducer } from './emulator-reducer'

export const eventsEmulatorReducer = combineReducers({
  sharedEmulatorReducer,
  arcEmulatorReducer,
  UVGIEmulatorReducer,
  ExtEmulatorReducer,
  netEmulator,
})
