import { useEffect, useState } from 'react'
import { UNKNOWN_ID, UNKNOWN_LOCATION } from '../../../services/constants'
import {
  FORM_LABELS,
  META_INFO_LABELS_EVENTS,
} from '../_shared/services/emulator-constants'
import {
  DEFAULT_META_INFO,
  EVENTS_CODES,
  firmwareCodes,
  firmwareUpdateStatuses,
  firmwareUpdateTypes,
  initialState,
  mainOperationsValues,
  messageConstants,
  metaInfoValues,
} from './services/uvgiEmulator-constants'
import {
  getLocationForDevice,
  isAllFieldsFilled,
  prepareRequestBody,
  prepareRequestParams,
  setMetaInfoForUpdateFail,
  setNewMetaInfo,
  setTxStartTime,
  validateRequiredFields,
} from './services/uvgiEmulator-service'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import indexOf from 'lodash/indexOf'
import { JsonTable } from 'react-json-to-html'
import { connect } from 'react-redux'
import NetworkError from '../../../components/_shared/errors/NetworkError'
import { checkConnection } from '../../../services/root/root-service'
import styles from '../style'
import CommentField from '../_shared/components/CommentField'
import DefaultPart from '../_shared/components/DefaultPart'
import ExpandableTable from '../_shared/components/ExpandableTable'
import FirmwareUpdate from '../_shared/components/FirmwareUpdate'
import MetaInfoRow from '../_shared/components/MetaInfoRow'
import { getImeiByDevice } from '../_shared/services/emulator-service'
import DeviceStatistic from './components/DeviceStatistics'
import MainEventOperations from './components/MainEventOperations'
import MotionPIRTable from './components/motionPIR/MotionPIRTable'
import StatusEvent from './components/StatusEvent'
import UVGIRequestParameters from './components/UVGIRequestParameters'
import actions from './services/uvgiEmulator-actions'

const UVGIEmulatorContainer = (props) => {
  const {
    loadDevices,
    sendDeviceMessage,
    deviceMessage,
    error,
    devices,
    locations,
    allLocations,
    networkError,
    tab,
    classes,
  } = props
  const [state, setState] = useState(initialState)

  useEffect(() => {
    loadDevices()
    // eslint-disable-next-line
  }, [tab])

  useEffect(() => {
    setState({
      ...state,
      metaInfo: setNewMetaInfo(state.metaInfo, state.txStartedAt),
    })
    // eslint-disable-next-line
  }, [state.txStartedAt])

  useEffect(() => {
    setState({
      ...state,
      metaInfo: setMetaInfoForUpdateFail(
        state.metaInfo,
        state.messageTypeText === EVENTS_CODES.evt417
      ),
    })
    // eslint-disable-next-line
  }, [state.messageTypeText])

  const changeValue = (event, name) => {
    setState({
      ...state,
      [name]: event.target.value,
    })
  }

  const changeDevice = (event) => {
    const imeiValue = getImeiByDevice(devices, event.target.value)
    const location = getLocationForDevice(
      devices,
      event.target.value,
      allLocations
    )

    setState({
      ...state,
      deviceName: event.target.value,
      imei: imeiValue,
      imeiCopy: imeiValue,
      location,
      locationId: location.id,
    })
  }

  const changeDateValue = (value, name) => {
    setState({
      ...state,
      [name]: value,
    })
  }

  const changeUseCurrentTimeCheckbox = (event) => {
    const checked = event.target.checked

    setState({
      ...state,
      useCurrentTime: checked,
    })
  }

  const changeMessageType = (event) => {
    const value = event.target.value

    setState({
      ...state,
      messageTypeText: value,
      firmwareUpdateStatus: firmwareUpdateStatuses[value] || '',
      isAdditionalParams: value === 'request parameters',
      isStatisticMessage: value === 'statistics',
      isSubmitted: false,
    })
  }

  const onSubmitButton = () => {
    const { messageTypeText, txStartedAt } = state
    const copyOfState = { ...state }

    const isValid = validateRequiredFields(
      copyOfState,
      messageTypeText,
      isAllFieldsFilled
    )
    const params = prepareRequestParams(copyOfState, messageTypeText)

    const body = prepareRequestBody(copyOfState, messageTypeText)

    if (isValid) {
      sendDeviceMessage(params.join('&'), body)
    }

    setState({
      ...state,
      txStartedAt: setTxStartTime(messageTypeText, txStartedAt),
      isSubmitted: true,
    })
  }

  const {
    deviceName,
    isSubmitted,
    locationId,
    location,
    messageTypeText,
    imei,
    imeiCopy,
    transactionId,
    deviceDateTime,
    useCurrentTime,
    motionPIRDescription,
    motionPIRAlertStatus,
    faultEventData,
    outputPower,
    mode,
    messageFrequency,
    timeout,
    firmwareUpdateStatus,
    firmwareUpdateVersion,
    firmwareUpdateTime,
    firmwareUpdateType,
    bulbChangeDate,
    deviceRevision,
    softwareRevision,
    bulbCyclesSinceChange,
    powerCyclesSinceChange,
    abuseEvents,
    totalLEDTime,
    totalRunTime,
    ledTemp,
    weightedTime,
    commonDevicesInfo,
    certificatesInfo,
    comment,
    metaInfo,
  } = state

  const locationsForUVGI = [
    location,
    { id: UNKNOWN_ID, name: UNKNOWN_LOCATION, children: [] },
  ]

  return networkError ? (
    <NetworkError />
  ) : (
    <>
      <div className={classes.formContainer}>
        {imei !== imeiCopy && (
          <Typography className={classes.selectAllLabel} variant="subtitle2">
            {FORM_LABELS.SERIAL_NUMBER}
          </Typography>
        )}
        <DefaultPart
          changeUseCurrentTimeCheckbox={changeUseCurrentTimeCheckbox}
          useCurrentTime={useCurrentTime}
          devices={devices}
          deviceDateTime={deviceDateTime}
          isSubmitted={isSubmitted}
          deviceName={deviceName}
          locations={locations}
          allLocations={locationsForUVGI}
          locationId={locationId}
          messageTypeText={messageTypeText}
          transactionId={transactionId}
          changeDateValue={changeDateValue}
          changeValue={changeValue}
          changeMessageType={changeMessageType}
          messageConstants={messageConstants}
          changeDevice={changeDevice}
          isUVGI
        />
        {indexOf(mainOperationsValues, messageTypeText) !== -1 && (
          <MainEventOperations
            outputPower={outputPower}
            isSubmitted={isSubmitted}
            mode={mode}
            timeout={timeout}
            messageFrequency={messageFrequency}
            changeValue={changeValue}
          />
        )}
        {messageTypeText === EVENTS_CODES.evt711 && (
          <MotionPIRTable
            description={motionPIRDescription}
            alertStatus={motionPIRAlertStatus}
            changeValue={changeDateValue}
          />
        )}
        {indexOf(firmwareCodes, messageTypeText) !== -1 && (
          <FirmwareUpdate
            version={firmwareUpdateVersion}
            status={firmwareUpdateStatus}
            messageType={messageTypeText}
            time={firmwareUpdateTime}
            type={firmwareUpdateType}
            changeValue={changeValue}
            isSubmitted={isSubmitted}
            firmwareOptions={firmwareUpdateTypes}
            changeDateValue={changeDateValue}
          />
        )}
        {messageTypeText === EVENTS_CODES.evt511 && (
          <StatusEvent
            changeValue={changeValue}
            mode={mode}
            changeDateValue={changeDateValue}
            faultEventData={faultEventData}
          />
        )}
        {messageTypeText === EVENTS_CODES.sts011 && (
          <DeviceStatistic
            bulbChangeDate={bulbChangeDate}
            deviceRevision={deviceRevision}
            softwareRevision={softwareRevision}
            bulbCyclesSinceChange={bulbCyclesSinceChange}
            powerCyclesSinceChange={powerCyclesSinceChange}
            abuseEvents={abuseEvents}
            changeValue={changeValue}
            changeDateValue={changeDateValue}
            isSubmitted={isSubmitted}
            totalLEDTime={totalLEDTime}
            totalRunTime={totalRunTime}
            ledTemp={ledTemp}
            weightedTime={weightedTime}
            commDevicesInfo={commonDevicesInfo}
            certificatesInfo={certificatesInfo}
          />
        )}
        {messageTypeText === EVENTS_CODES.evt011 && (
          <UVGIRequestParameters
            deviceRevision={deviceRevision}
            changeValue={changeValue}
            changeDateValue={changeDateValue}
            imei={imei}
            isSubmitted={isSubmitted}
            softwareRevision={softwareRevision}
            commDevicesInfo={commonDevicesInfo}
            certificatesInfo={certificatesInfo}
          />
        )}
        {indexOf(metaInfoValues, messageTypeText) !== -1 && (
          <ExpandableTable
            CustomTableRow={MetaInfoRow}
            propName={'metaInfo'}
            isSubmitted={isSubmitted}
            tableTitle={META_INFO_LABELS_EVENTS.tableTitle}
            columnNames={META_INFO_LABELS_EVENTS.tableColumnsLabels}
            defaultValue={{ ...DEFAULT_META_INFO }}
            tableData={metaInfo}
            changeValue={changeDateValue}
          />
        )}
        {messageTypeText && (
          <CommentField changeValue={changeValue} comment={comment} />
        )}
        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={onSubmitButton}
        >
          {FORM_LABELS.SUBMIT}
        </Button>
      </div>
      <div className={classes.messageContainer}>
        {!error && deviceMessage[tab] && (
          <JsonTable json={deviceMessage[tab]} />
        )}
        {error && (
          <Typography variant="subtitle2" color="error">
            {error}
          </Typography>
        )}
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendDeviceMessage: (data, body) =>
      dispatch(checkConnection(() => actions.sendDeviceMessage(data, body))),
    loadDevices: () => dispatch(checkConnection(actions.loadUVGIDevices)),
  }
}

const mapStateToProps = (state) => {
  const {
    eventsEmulatorReducer: { UVGIEmulatorReducer, sharedEmulatorReducer },
    rootReducer,
  } = state

  return {
    deviceMessage: UVGIEmulatorReducer.deviceMessage,
    error: UVGIEmulatorReducer.errorFromDeviceMessage,
    devices: UVGIEmulatorReducer.uvgiDevices,
    locations: UVGIEmulatorReducer.deviceLocations,
    allLocations: UVGIEmulatorReducer.allLocations,
    networkError: rootReducer.networkError,
    tab: sharedEmulatorReducer.tab,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UVGIEmulatorContainer))
