import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableCell,
} from '@material-ui/core'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import map from 'lodash/map'
import toString from 'lodash/toString'
import { HISTORY_TABLE } from '../services/deviceDetails-constants'
import { useStyles } from './style'

const HistoryTable = (props) => {
  const { tableData } = props

  const style = useStyles()

  const getTableLabels = () =>
    map(HISTORY_TABLE, (value, key) => (
      <TableCell align="center" key={key}>
        {value}
      </TableCell>
    ))

  const getTableData = () => {
    return tableData && tableData.length
      ? map(tableData, (row) => {
          return (
            <TableRow key={row.id}>
              {map(HISTORY_TABLE, (value, key) => {
                return (
                  <TableCell align="center" key={key}>
                    {row[key] ? toString(row[key]) : ''}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })
      : null
  }

  return (
    <Accordion className={style.container}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        classes={{ content: style.accordionSummary }}
      >
        <Typography variant="h5">{`History: ${
          tableData ? tableData.length : 0
        } items`}</Typography>
      </AccordionSummary>
      <AccordionDetails className={style.details}>
        <div className={style.tableContainer}>
          <TableContainer component={Paper}>
            <Table className={style.table}>
              <TableHead>
                <TableRow>{getTableLabels()}</TableRow>
              </TableHead>
              <TableBody>{getTableData()}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default HistoryTable
