import 'spin.js/spin.css'

import { useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import { Spinner } from 'spin.js'
import { opts } from './loader-service'
import styles from './style'

const LoaderComponent = ({
  isLoading,
  classes,
  children,
  inModal = false,
  loaderKey = 'root',
}) => {
  const id = `loader-${loaderKey}`

  useEffect(() => {
    const target = document.getElementById(id)
    const spinner = new Spinner(opts(inModal))

    if (isLoading) {
      spinner.spin(target)
    } else {
      spinner.stop()
    }
    // eslint-disable-next-line
  }, [isLoading])

  return isLoading ? (
    <div className={classes.wrapper}>
      <div id={id} />
    </div>
  ) : (
    <>{children}</>
  )
}

export default withStyles(styles)(LoaderComponent)
