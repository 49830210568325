import { PAGINATION } from '../../../services/constants'

const failedDevicesCellsConfig = {
  serialNumber: { type: 'text' },
  organization: { type: 'text' },
  deviceType: { type: 'deviceType' },
  created: { type: 'data' },
  imei: { type: 'text' },
  modified: { type: 'data' },
  comment: { type: 'text' },
}

const FAILED_DEVICES_TABLE_LABELS = {
  serialNumber: 'Serial Number',
  organization: 'Organization',
  deviceType: 'Device Type',
  created: 'Created',
  imei: 'IMEI',
  modified: 'Modified',
  comment: 'Comment',
}

const FAILED_DEVICES_LABELS = {
  backToDevices: 'Back to Devices List',
  devices: 'Failed Devices',
}

const initialStateFailedDevices = {
  devices: [],
  error: null,
  isLoading: true,
  page: 0,
  totalCount: 0,
  rowsPerPage: PAGINATION.perPageValues[0],
}

export {
  failedDevicesCellsConfig,
  FAILED_DEVICES_TABLE_LABELS,
  FAILED_DEVICES_LABELS,
  initialStateFailedDevices,
}
