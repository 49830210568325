export type EnableDeviceLoggingProps = {
  deviceId: string
}
export type LoggingCategories =
  | 'main'
  | 'i2c'
  | 'spi3'
  | 'hw'
  | 'uvgi'
  | 'dev man'
  | 'net man'
  | 'evt man'
  | 'monitor'
  | 'settings'
  | 'http'
  | 'lwesp'
  | 'opwindow'
  | 'ux'

export type LogLevels =
  | 'Errors'
  | 'Warnings'
  | 'Informative'
  | 'Debug'
  | 'Disabled'

export const DEVICE_LOGGING_CATEGORIES: {
  label: LoggingCategories
  value: LoggingCategories
}[] = [
  { label: 'main', value: 'main' },
  { label: 'i2c', value: 'i2c' },
  { label: 'spi3', value: 'spi3' },
  { label: 'hw', value: 'hw' },
  { label: 'uvgi', value: 'uvgi' },
  { label: 'dev man', value: 'dev man' },
  { label: 'net man', value: 'net man' },
  { label: 'evt man', value: 'evt man' },
  { label: 'monitor', value: 'monitor' },
  { label: 'settings', value: 'settings' },
  { label: 'http', value: 'http' },
  { label: 'lwesp', value: 'lwesp' },
  { label: 'opwindow', value: 'opwindow' },
  { label: 'ux', value: 'ux' },
]

export const DEVICE_LOG_LEVELS: { label: LogLevels; value: number }[] = [
  {
    label: 'Errors',
    value: 0,
  },
  {
    label: 'Warnings',
    value: 1,
  },
  {
    label: 'Informative',
    value: 2,
  },
  {
    label: 'Debug',
    value: 3,
  },
  {
    label: 'Disabled',
    value: 4,
  },
]

export const DEVICE_LOG_LEVELS_MAPPING: { [s: number]: LogLevels } = {
  0: 'Errors',
  1: 'Warnings',
  2: 'Informative',
  3: 'Debug',
  4: 'Disabled',
}
