import AccessWrapper from '../../components/_shared/AccessWrapper'
import { PAGES } from '../../services/constants'
import FailedDevicesPage from './FailedDevicesPage'

const FailedDevices = (props) => {
  return (
    <AccessWrapper page={PAGES.device}>
      <FailedDevicesPage {...props} />
    </AccessWrapper>
  )
}

export default FailedDevices
