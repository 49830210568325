import { call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { DEVICE_TYPE } from '../constants'
import { HttpClient } from '../HttpClient'
import rootActions from './root-actions'

export const getPermissions = (state) => state.rootReducer.permissions

function* getUserGroups() {
  try {
    const data = yield call(() => {
      return HttpClient.get(`/api/secured/user-profiles/user-authority-groups`)
    })

    if (data.message) {
      yield put(rootActions.getUserGroupsFailed(data.message))
    } else {
      yield put(rootActions.getUserGroupsSuccess(data))

      const permissions = yield select(getPermissions)
      yield put(rootActions.setupPermissions(permissions))
    }
  } catch (e) {
    yield put(rootActions.getUserGroupsFailed(e.message))
  }
}

function* loadDevicesTypes() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/firmwares/device-types')
    })

    if (data.message) {
      yield put(rootActions.loadDevicesTypesFailed(data.message))
    } else {
      yield put(
        rootActions.loadDevicesTypesSuccess(
          // NOTE: GDPAPPS-193 - Hide External Devices, this may need to be removed in future.
          // NOTE: GDPAPPS-263 - Hide sensor devices, this may need to be removed in future.
          data.filter(
            (type) =>
              type.value !== DEVICE_TYPE.EXT_DEVICE.label &&
              type.value !== DEVICE_TYPE.SENSOR.label
          )
        )
      )
    }
  } catch (e) {
    yield put(rootActions.loadDevicesTypesFailed(e.message))
  }
}

function* loadSubTypesForDevice(action) {
  try {
    let { deviceType } = action.payload

    if (deviceType === DEVICE_TYPE.FAR_UVC.type) {
      deviceType = DEVICE_TYPE.UR_UVGI.type
    }

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/firmwares/device-subtypes/${deviceType}`
      )
    })

    if (data.message) {
      yield put(rootActions.loadSubTypesForDeviceFailed(data.message))
    } else {
      yield put(rootActions.loadSubTypesForDeviceSuccess(data))
    }
  } catch (e) {
    yield put(rootActions.loadSubTypesForDeviceFailed(e.message))
  }
}

function* getUserGroupsSaga() {
  yield takeLatest(rootActions.actionTypes.GET_USER_GROUPS, getUserGroups)
}

function* loadDevicesTypesSaga() {
  yield takeLatest(rootActions.actionTypes.LOAD_DEVICES_TYPES, loadDevicesTypes)
}

function* loadDeviceSubTypesSaga() {
  yield takeLatest(
    rootActions.actionTypes.LOAD_SUB_TYPES_FOR_DEVICE,
    loadSubTypesForDevice
  )
}

export default function* rootSaga() {
  yield fork(getUserGroupsSaga)
  yield fork(loadDevicesTypesSaga)
  yield fork(loadDeviceSubTypesSaga)
}
