import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import styles from '../../style'
import { COMMENT_FIELD_LABELS } from '../services/emulator-constants'

const CommentFieldComponent = (props) => {
  const { changeValue, comment, classes } = props

  const onChangeValue = (event) => {
    changeValue(event, event.target.name)
  }

  return (
    <TextField
      className={classes.fullWidthTextField}
      disabled
      id="outlined-basic"
      value={comment}
      name={COMMENT_FIELD_LABELS.name}
      label={COMMENT_FIELD_LABELS.label}
      onChange={onChangeValue}
      variant="outlined"
    />
  )
}

export default withStyles(styles)(CommentFieldComponent)
