export type DesiredSettings =
  | 'desiredOnTime'
  | 'desiredPower'
  | 'desiredSafetyDelay'
  | 'desiredOperationWindowSize'
  | 'desiredOperationWindowRuntimeMax'
  | 'desiredDailyMaxRuntime'

export type ReportedSettings =
  | 'onTime'
  | 'power'
  | 'safetyDelay'
  | 'operationWindowSize'
  | 'operationWindowRuntimeMax'
  | 'dailyMaxRuntime'

export type SettingsProps = { [s in DesiredSettings]?: number | null }

export const SettingsMappings: { [s in ReportedSettings]: string } = {
  onTime: 'On Time',
  power: 'Power',
  safetyDelay: 'Safety Delay',
  operationWindowSize: 'Operation Window Size',
  operationWindowRuntimeMax: 'Operation Window Runtime Max',
  dailyMaxRuntime: 'Daily Runtime Max',
}

export type SettingsErrors = {
  desiredDailyStartTime: boolean
  desiredDailyEndTime: boolean
  desiredDays: boolean
  desiredPower: boolean
  desiredOnTime: boolean
  desiredSafetyDelay: boolean
  desiredOperationWindowRuntimeMax: boolean
  desiredOperationWindowSize: boolean
}

export const DEFAULT_SETTINGS_ERRORS: {
  [s in keyof SettingsErrors]: boolean
} = {
  desiredDailyStartTime: false,
  desiredDailyEndTime: false,
  desiredDays: false,
  desiredPower: false,
  desiredOnTime: false,
  desiredSafetyDelay: false,
  desiredOperationWindowRuntimeMax: false,
  desiredOperationWindowSize: false,
}
