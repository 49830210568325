import { call, fork, put, takeLatest } from 'redux-saga/effects'

import { HttpClient } from '../../../../../../../services/HttpClient'
import allowListActions from './AllowList-actions'

function* getAllowList(action) {
  try {
    const { deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/network-device/id/${deviceId}/allowList`
      )
    })

    if (data.message) {
      yield put(allowListActions.getAllowListFailed(data.message))
    } else {
      yield put(allowListActions.getAllowListSuccess(data?.items || []))
    }
  } catch (e) {
    yield put(allowListActions.getAllowListFailed(e.message))
  }
}

function* allowListRemoveDevice(action) {
  try {
    const { hubId, deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.delete(
        `/api/secured/devices/network-device/id/${hubId}/allowList/${deviceId}`
      )
    })

    if (data.message) {
      yield put(allowListActions.allowListRemoveDeviceFailed(data.message))
    } else {
      yield put(allowListActions.allowListRemoveDeviceSuccess(data))
      yield put(allowListActions.getAllowList(hubId))
    }
  } catch (e) {
    yield put(allowListActions.allowListRemoveDeviceFailed(e.message))
  }
}

function* allowListAddDevice(action) {
  try {
    const { callback, hubId, deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/devices/network-device/id/${hubId}/allowList/${deviceId}`
      )
    })

    if (data.message) {
      yield put(allowListActions.allowListAddDeviceFailed(data.message))
    } else {
      yield put(allowListActions.allowListAddDeviceSuccess(data))
      yield put(allowListActions.getAllowList(hubId))
      if (callback) {
        callback()
      }
    }
  } catch (e) {
    yield put(allowListActions.allowListAddDeviceFailed(e.message))
  }
}

function* allowListBulkAddDevices(action) {
  try {
    const { callback, hubId, serialNumbers } = action.payload

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/devices/network-device/id/${hubId}/allowList/bulk`,
        {
          body: { serialNumbers },
        }
      )
    })

    if (data.message) {
      yield put(allowListActions.allowListBulkAddDevicesFailed(data.message))
    } else {
      yield put(allowListActions.allowListBulkAddDevicesSuccess(data))
      yield put(allowListActions.getAllowList(hubId))

      if (callback) {
        callback()
      }
    }
  } catch (e) {
    yield put(allowListActions.allowListBulkAddDeviceFailed(e.message))
  }
}

function* toggleBulkAddModal(action) {
  yield put(allowListActions.toggleBulkAddModalSuccess())
}

function* allowListBulkAddDevicesClearError(action) {
  yield put(allowListActions.allowListBulkAddClearErrorSuccess())
}

function* searchAvailableDeviceList(action) {
  try {
    const { deviceId, query } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/network-device/id/${deviceId}/availableDevices/${query}`
      )
    })

    if (data.message) {
      yield put(allowListActions.searchAvailableDeviceListFailed(data.message))
    } else {
      yield put(
        allowListActions.searchAvailableDeviceListSuccess(data?.items || [])
      )
    }
  } catch (e) {
    yield put(allowListActions.searchAvailableDeviceListFailed(e.message))
  }
}

function* getAllowListSaga() {
  yield takeLatest(allowListActions.actionTypes.GET_ALLOW_LIST, getAllowList)
}

function* allowListRemoveDeviceSaga() {
  yield takeLatest(
    allowListActions.actionTypes.ALLOW_LIST_REMOVE_DEVICE,
    allowListRemoveDevice
  )
}

function* allowListAddDeviceSaga() {
  yield takeLatest(
    allowListActions.actionTypes.ALLOW_LIST_ADD_DEVICE,
    allowListAddDevice
  )
  yield takeLatest(
    allowListActions.actionTypes.ALLOW_LIST_BULK_ADD_DEVICES,
    allowListBulkAddDevices
  )
  yield takeLatest(
    allowListActions.actionTypes.TOGGLE_BULK_ADD_MODAL,
    toggleBulkAddModal
  )
  yield takeLatest(
    allowListActions.actionTypes.ALLOW_LIST_CLEAR_BULK_ADD_DEVICES_CLEAR_ERROR,
    allowListBulkAddDevicesClearError
  )
}

function* searchAvailableDeviceListSaga() {
  yield takeLatest(
    allowListActions.actionTypes.SEARCH_AVAILABLE_DEVICE,
    searchAvailableDeviceList
  )
}

export default function* allowListSagas() {
  yield fork(getAllowListSaga)
  yield fork(allowListRemoveDeviceSaga)
  yield fork(allowListAddDeviceSaga)
  yield fork(searchAvailableDeviceListSaga)
}
