import actions from './failedDevices-actions'
import { initialStateFailedDevices } from './failedDevices-constants'

export const failedDevicesReducer = (
  state = initialStateFailedDevices,
  action
) => {
  switch (action.type) {
    case actions.actionTypes.GET_FAILED_DEVICES: {
      return {
        ...state,
        error: '',
        isLoading: true,
      }
    }
    case actions.actionTypes.GET_FAILED_DEVICES_SUCCEEDED: {
      const { totalCount, items } = action.payload.failedDevices

      return {
        ...state,
        devices: [...state.devices, ...items],
        totalCount,
        error: '',
        isLoading: false,
      }
    }
    case actions.actionTypes.RESET_FAILED_DEVICES: {
      return {
        ...initialStateFailedDevices,
        isLoading: false,
      }
    }
    case actions.actionTypes.GET_FAILED_DEVICES_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      }
    }
    case actions.actionTypes.GO_TO_PREVIOUS_PAGE:
    case actions.actionTypes.GO_TO_NEXT_PAGE: {
      const { page } = action.payload

      return {
        ...state,
        page,
      }
    }
    case actions.actionTypes.SET_ROWS_PER_PAGE: {
      const { rowsPerPage } = action.payload

      return {
        ...state,
        rowsPerPage,
        page: 0,
        isLoading: true,
        devices: [],
        totalCount: 0,
      }
    }
    case actions.actionTypes.SET_PAGE: {
      const { page } = action.payload

      return {
        ...state,
        page,
      }
    }
    default:
      return state
  }
}
