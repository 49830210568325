import { createReducer } from 'redux-act'
import {
  DEVICE_TYPE,
  DEVICE_TYPE_LABELS,
  PAGES,
} from '../../../services/constants'
import rootActions from '../../../services/root/root-actions'
import deviceDetailsActions from '../components/Details/services/deviceDetails-actions'
import { prepareHistoryDeviceDetails } from '../components/Details/services/deviceDetails-service'
import devicesListActions from '../components/List/services/devicesList-actions'
import sharedDevicesActions, {
  GET_DEVICE_SETTINGS_HISTORY,
  GET_DEVICE_SETTINGS_HISTORY_FAILED,
  GET_DEVICE_SETTINGS_HISTORY_SUCCEEDED,
} from './devices-actions'
import {
  prepareDeviceEvents,
  prepareExtraInfoForDeviceDetails,
} from './devices-service'
import { DeviceEvent, DeviceSetting } from './devices-types'

const initialState = {
  error: null as string | null,
  isLoading: true,
  anotherTypeCount: 0,
  userAuthorityGroups: [],
  communicationInfo: [],
  isReadOnly: false,
  loadDataFromReduxStoreFlag: false,
  deviceType: DEVICE_TYPE.UVI_ARC.type as keyof typeof DEVICE_TYPE_LABELS,
  deviceByIMEI: null,
  deviceEvents: <DeviceEvent[]>[],
  deviceSettingsHistory: <DeviceSetting[]>[],
  deviceSettingsError: '',
  isUVGIPowerManagementAvailable: false,
  isARCChangeRevisionAvailable: false,
  version: null as string | null,
}
export const sharedDevicesReducer = createReducer(
  {
    [rootActions.actionTypes.SETUP_PERMISSIONS]: (state, payload) => {
      const { permissions } = payload

      return {
        ...state,
        isPermissionsLoaded: true,
        isReadOnly: !permissions[PAGES.device].write,
        isUVGIPowerManagementAvailable:
          permissions[PAGES.uvgiPowerManagement].write,
        isARCChangeRevisionAvailable:
          permissions[PAGES.ARCChangeRevision].write,
      }
    },
    [sharedDevicesActions.actionTypes.CHECK_IMEI_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        deviceByIMEI: payload.device,
        error: '',
      }
    },
    [sharedDevicesActions.actionTypes.UPDATE_DEVICE_SUB_TYPE_FAILED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      }
    },
    [sharedDevicesActions.actionTypes.CHECK_IMEI_FAILED]: (state, payload) => {
      return {
        ...state,
        isLoading: false,
        deviceByIMEI: null,
        error: payload.message,
      }
    },
    [sharedDevicesActions.actionTypes.RESET_ERROR_AND_DEVICE]: (
      state,
      payload
    ) => {
      return {
        ...state,
        error: '',
        deviceByIMEI: null,
      }
    },
    [sharedDevicesActions.actionTypes.UPDATE_DEVICE_SUB_TYPE_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        deviceType: payload.type,
        loadDataFromReduxStoreFlag: false,
      }
    },
    [sharedDevicesActions.actionTypes.SET_DEVICE_TYPE]: (state, payload) => {
      const { deviceType } = payload

      return {
        ...state,
        deviceType,
        page: 0,
        totalsByTypeReceived: true,
      }
    },
    [sharedDevicesActions.actionTypes.RESET_ANOTHER_TYPE_COUNT]: (
      state,
      payload
    ) => {
      return {
        ...state,
        anotherTypeCount: 0,
      }
    },
    [sharedDevicesActions.actionTypes.GET_PORTAL_ACTIVITY_SUCCEEDED]: (
      state,
      payload
    ) => {
      const { data } = payload

      return {
        ...state,
        portalActivity: data,
      }
    },
    [sharedDevicesActions.actionTypes.GET_DEVICE_EVENT_BY_ID_SUCCEEDED]: (
      state,
      payload
    ) => {
      const { data } = payload
      const deviceEvents = prepareDeviceEvents(data) as DeviceEvent[]

      return {
        ...state,
        anotherTypeCount: 0,
        deviceEvents,
      }
    },
    [sharedDevicesActions.actionTypes.GET_PORTAL_ACTIVITY_FAILED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        error: payload.message,
        portalActivity: [],
      }
    },
    [sharedDevicesActions.actionTypes.GET_DEVICE_EVENT_BY_ID_FAILED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        error: payload.message,
        deviceEvents: [],
      }
    },
    [sharedDevicesActions.actionTypes.LOAD_DATA_FROM_REDUX_STORE]: (
      state,
      payload
    ) => {
      const { status } = payload

      return {
        ...state,
        loadDataFromReduxStoreFlag: status,
      }
    },
    [deviceDetailsActions.actionTypes.ADD_NEW_DEVICE_FAILED]: (
      state,
      payload
    ) => ({
      ...state,
      deviceByIMEI: null,
    }),
    [deviceDetailsActions.actionTypes.UPDATE_SETTINGS_FAILED]: (
      state,
      payload
    ) => ({
      ...state,
      deviceByIMEI: null,
    }),
    [deviceDetailsActions.actionTypes.DISABLE_DEVICE_FAILED]: (
      state,
      payload
    ) => ({
      ...state,
      deviceByIMEI: null,
    }),
    [deviceDetailsActions.actionTypes.FACTORY_RESET_FAILED]: (
      state,
      payload
    ) => ({
      ...state,
      deviceByIMEI: null,
    }),
    [devicesListActions.actionTypes.DELETE_DEVICE_FAILED]: (
      state,
      payload
    ) => ({
      ...state,
      deviceByIMEI: null,
    }),
    [devicesListActions.actionTypes.FULL_CLEANUP_DEVICE_BY_ID_FAILED]: (
      state,
      payload
    ) => ({
      ...state,
      deviceByIMEI: null,
    }),
    [devicesListActions.actionTypes.GET_CLEANUP_ABILITY_FAILED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        deviceByIMEI: null,
      }
    },
    [deviceDetailsActions.actionTypes.GET_EXTENDED_DEVICE_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        deviceType: payload.device?.deviceSubType,
      }
    },
    [GET_DEVICE_SETTINGS_HISTORY.getType()]: (state, payload) => {
      return {
        ...state,
        deviceSettingsHistory: [],
        deviceSettingsError: '',
      }
    },
    [GET_DEVICE_SETTINGS_HISTORY_FAILED.getType()]: (state, payload) => {
      const { message } = payload

      return {
        ...state,
        deviceSettingsHistory: [],
        deviceSettingsError: message,
      }
    },
    [GET_DEVICE_SETTINGS_HISTORY_SUCCEEDED.getType()]: (state, payload) => {
      const { settings } = payload
      return {
        ...state,
        deviceSettingsHistory: settings,
        deviceSettingsError: '',
      }
    },
    [deviceDetailsActions.actionTypes.GET_ACTUAL_DEVICE_DETAILS_SUCCEEDED]: (
      state,
      payload
    ) => {
      const dateArray = prepareHistoryDeviceDetails(payload.data)

      const [, , , deviceSubType, version] = prepareExtraInfoForDeviceDetails(
        payload.data
      )

      return {
        ...state,
        deviceType: deviceSubType,
        version,
        anotherTypeCount: state.anotherTypeCount + dateArray.anotherTypeCount,
      }
    },
    [deviceDetailsActions.actionTypes.GET_HISTORY_DEVICE_DETAILS_SUCCEEDED]: (
      state,
      payload
    ) => {
      const dateArray = prepareHistoryDeviceDetails(payload.data)

      return {
        ...state,
        anotherTypeCount: state.anotherTypeCount + dateArray.anotherTypeCount,
      }
    },
  },
  initialState
)
