import useStyles from './style'

type CardWithDescriptionProps = {
  title: string
  description: string
  actionComponent?: JSX.Element | null
}

const CardWithDescription = ({
  title,
  description,
  actionComponent,
}: CardWithDescriptionProps) => {
  const classes = useStyles()
  // onClick handler on actionWrapper <div> prevents firing event on parent <div>
  return (
    <div className={classes.actionCard}>
      <div className={classes.descriptionWrapper}>
        <p className={classes.description}>{description}</p>
        <p className={classes.title}>{title}</p>
      </div>
      <div
        className={classes.actionWrapper}
        onClick={(event) => event.stopPropagation()}
      >
        {actionComponent}
      </div>
    </div>
  )
}

export default CardWithDescription
