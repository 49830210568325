import { createAction } from 'redux-act'

export const EXPAND_NODE = createAction(
  '{Custom Tree} Expand Node',
  (data: Location[], parentId: string) => ({
    data,
    parentId,
  })
)

export const SHRINK_NODE = createAction(
  '{Custom Tree} Shrink Node',
  (id: string) => ({ id })
)

export const LOAD_NODES_SUCCEEEDED = createAction(
  '{Custom Tree} Load Nodes Succeeded',
  (list: Location[], locationType: string, parentId: string) => ({
    list,
    locationType,
    parentId,
  })
)
