import {
  filterInitialState,
  FILTER_LABELS,
  ROLES,
} from '../services/users-constants'

import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import CustomSelect from '../../../components/_shared/CustomSelect'
import { CHANGE_FILTER_STATE } from '../services/users-actions'
import { useStyles } from './style'
import { UsersFilterState } from './types'

type LabelItem = {
  label: string
}

const UsersFilter = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const filterState = useAppSelector((state) => state.usersReducer.userFilters)

  const applyFilter = (filterState: UsersFilterState) =>
    dispatch(CHANGE_FILTER_STATE(filterState))

  const changeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    applyFilter({
      ...filterState,
      [event.target.name]: event.target.value,
      pageNum: 0,
    })
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        applyFilter(filterState)
      }}
    >
      <Typography variant="h6" component="div">
        {FILTER_LABELS.usersFilter}
      </Typography>

      <div className={styles.filterWrapper}>
        <div className={styles.filterItem}>
          <div className={styles.filterField}>
            <CustomSelect
              id="users-role-filter-select"
              name="users-role-filter-select"
              error={null}
              isRequired={false}
              options={ROLES}
              label={FILTER_LABELS.role}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                applyFilter({
                  ...filterState,
                  roleName: event.target.value,
                })
              }}
              value={filterState.roleName}
              valueName="value"
              template={(item: LabelItem) => item.label}
            />
          </div>
          <TextField
            name="organizationName"
            className={styles.filterField}
            label={FILTER_LABELS.organizationName}
            value={filterState.organizationName}
            onChange={changeTextField}
            variant="outlined"
          />
          <TextField
            name="email"
            className={styles.filterField}
            label={FILTER_LABELS.email}
            value={filterState.email}
            onChange={changeTextField}
            variant="outlined"
          />
        </div>
        <div className={styles.filterItem}>
          <Button
            type="submit"
            className={styles.actionButton}
            variant="contained"
            color="primary"
          >
            {FILTER_LABELS.applyFilter}
          </Button>
          <Button
            variant="contained"
            className={styles.actionButton}
            onClick={() => applyFilter(filterInitialState)}
            color="primary"
          >
            {FILTER_LABELS.resetFilter}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default UsersFilter
