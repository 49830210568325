import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    width: '100%',
    height: '75px',
  },
  credentialsList: {
    height: '225px',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}))
