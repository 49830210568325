import map from 'lodash/map'
import { useMemo } from 'react'
import { useAppSelector } from 'utils/reduxHelpers'
import { Location } from '../../../services/organizationDetails-types'
import { useStyles } from './style'
import TreeNode from './TreeNode'

type TreeListProps = {
  isReadOnly: boolean
}

const TreeList = ({ isReadOnly }: TreeListProps) => {
  const styles = useStyles()

  const nodes = useAppSelector((state) => state.customTreeReducer.nodes)

  const renderTreeNodes = (nodes: Location[], parent?: Location) => {
    const renderNodes = (nodes: Location[]) => {
      return map(nodes, (node) => {
        const parentExpanded = parent
          ? parent.key
            ? parent.expanded
            : true
          : true

        if (!parentExpanded) {
          return null
        }

        return (
          <div key={node.id}>
            <TreeNode isReadOnly={isReadOnly} {...node}>
              {node.hasChild && renderTreeNodes(node.children, node)}
            </TreeNode>
          </div>
        )
      })
    }

    return renderNodes(nodes)
  }

  const renderedNodes = useMemo(() => renderTreeNodes(nodes), [nodes])

  return (
    <>
      <div className={styles.nodesWrapper}>{renderedNodes}</div>
    </>
  )
}

export default TreeList
