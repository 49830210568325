import { Snackbar, withStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import {
  autoHideDuration,
  DEVICE_TYPE,
} from '../../../../../../services/constants'
import {
  EDIT_DEVICE,
  onSuccessUpdateDevice,
} from '../../services/deviceDetails-constants'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { ArrowBack } from '@material-ui/icons'
import snakeCase from 'lodash/snakeCase'
import some from 'lodash/some'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import LoaderComponent from '../../../../../../components/_shared/loader/Loader'
import { checkConnection } from '../../../../../../services/root/root-service'
import actions from '../../../../services/devices-actions'
import deviceDetailsActions from '../../services/deviceDetails-actions'
import { checkExtendedFormErrors } from '../../services/deviceDetails-service'
import EditExtDevice from './EditExtDevice'
import EditUVGIDevice from './EditUVGIDevice'
import style from './style'

const EditDevicePage = (props) => {
  const {
    getFullDeviceData,
    setDeviceId,
    setDeviceType,
    updateExtendedDevice,
    device = {},
    error,
    history,
    isLoading,
    networkError,
    classes,
  } = props
  const [state, setState] = useState({})
  const [errors, setErrors] = useState({})
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const { id, deviceType } = useParams()

  useEffect(() => {
    setDeviceId(id)
    setDeviceType(snakeCase(deviceType).toUpperCase())
  }, [id, deviceType])

  useEffect(() => {
    if (
      (!device.serialNumber && !state.serialNumber) ||
      Number(id) !== device.id
    ) {
      getFullDeviceData()
    } else if (device.serialNumber && !state.serialNumber) {
      setState(device)
      if (device.deviceSubType === DEVICE_TYPE.FAR_UVC.type) {
        setDeviceType(DEVICE_TYPE.FAR_UVC.type)
      }
    }
  }, [device.serialNumber, state.serialNumber])

  const changeTextField = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const onSubmit = () => {
    const extendedFormErrors = checkExtendedFormErrors(
      state,
      device.deviceType === DEVICE_TYPE.UR_UVGI.type ||
        device.deviceType === DEVICE_TYPE.FAR_UVC.type
    )

    if (some(extendedFormErrors)) {
      setErrors(extendedFormErrors)
    } else {
      setErrors({})

      updateExtendedDevice(id, deviceType, state, onChangeSnackbar)
    }
  }

  const onBack = () => {
    history.push('/devices')
  }

  const onChangeSnackbar = () => {
    setOpenSnackbar(!openSnackbar)
  }

  const getEditPageContent = () => {
    switch (deviceType) {
      case DEVICE_TYPE.UR_UVGI.path:
      case DEVICE_TYPE.FAR_UVC.path:
        return (
          <EditUVGIDevice
            state={state}
            errors={errors}
            onChange={changeTextField}
            onSubmit={onSubmit}
          />
        )
      case DEVICE_TYPE.EXT_DEVICE.path:
        return (
          <EditExtDevice
            state={state}
            setState={setState}
            errors={errors}
            onChange={changeTextField}
            onSubmit={onSubmit}
          />
        )
      default:
        return 'Error'
    }
  }

  return (
    <LoaderComponent isLoading={isLoading && !networkError}>
      <div className={classes.pageWrapper}>
        <IconButton onClick={onBack} className={classes.backButton}>
          <ArrowBack className={classes.backIcon} />
        </IconButton>
        <div className={classes.editPageWrapper}>
          <Typography variant="h3">{EDIT_DEVICE.pageViewModeTitle}</Typography>
          {error && (
            <Typography variant="subtitle1" color="error">
              {error}
            </Typography>
          )}

          {getEditPageContent()}
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={autoHideDuration}
        onClose={onChangeSnackbar}
        message={onSuccessUpdateDevice}
      />
    </LoaderComponent>
  )
}

const mapStateToProps = (state) => {
  const { deviceDetailsReducer } = state.devicesReducer

  const { deviceDetails } = deviceDetailsReducer

  return {
    device: {
      id: deviceDetails.id,
      loraId: deviceDetails.loraId,
      imei: deviceDetails.imei,
      serialNumber: deviceDetails.serialNumber,
      removed: deviceDetails.removed,
      deviceType: deviceDetails.deviceType,
      bluetoothId: deviceDetails.bluetoothId,
      mcuUid: deviceDetails.mcuUid,
      deviceEui: deviceDetails.deviceEui,
      initialFirmwareVersion: deviceDetails.initialFirmwareVersion,
      baseMac: deviceDetails.baseMac,
      ethernetMac: deviceDetails.ethernetMac,
      deviceSubType: deviceDetails.deviceSubType,
      softApMac: deviceDetails.softApMac,
    },
    isLoading: deviceDetailsReducer.isLoading,
    error: deviceDetailsReducer.error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDeviceId: (id) => dispatch(deviceDetailsActions.setDeviceId(id)),
  setDeviceType: (deviceType) => dispatch(actions.setDeviceType(deviceType)),
  getFullDeviceData: () =>
    dispatch(checkConnection(() => deviceDetailsActions.getFullDeviceData())),
  updateExtendedDevice: (id, body, onSuccess) =>
    dispatch(
      checkConnection(() =>
        deviceDetailsActions.updateExtendedDevice(id, body, onSuccess)
      )
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(EditDevicePage))
