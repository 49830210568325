import * as actions from './services/netEmulator-actions'

import { Button, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { UNKNOWN_ID, UNKNOWN_LOCATION } from 'services/constants'
import {
  isRequiredFieldsFilled,
  pushParams,
} from '../_shared/services/emulator-service'
import {
  commonRequiredFields,
  ERROR,
  initialState,
  messageConstants,
  paramsFields,
} from './services/netEmulator-constants'
import {
  getImeiByDevice,
  getLocationForDevice,
  prepareBody,
  validateBody,
} from './services/netEmulator-service'

import NetworkError from 'components/_shared/errors/NetworkError'
import { JsonTable } from 'react-json-to-html'
import { useDispatch } from 'react-redux'
import { checkConnection } from 'services/root/root-service'
import { useAppSelector } from 'utils/reduxHelpers'
import { useStyles } from '../style'
import DeviceInfoTable from '../UVGI/components/DeviceInfoTable'
import CommentField from '../_shared/components/CommentField'
import DefaultPart from '../_shared/components/DefaultPart'
import { FORM_LABELS } from '../_shared/services/emulator-constants'
import NetFields from './components/NetFields'

export const NetEmulatorContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const deviceMessage = useAppSelector(
    (state) => state.eventsEmulatorReducer.netEmulator.deviceMessage
  )

  const error = useAppSelector(
    (state) => state.eventsEmulatorReducer.netEmulator.error
  )
  const devices = useAppSelector(
    (state) => state.eventsEmulatorReducer.netEmulator.devices
  )
  const locations = useAppSelector(
    (state) => state.eventsEmulatorReducer.netEmulator.messageLocations
  )
  const allLocations = useAppSelector(
    (state) => state.eventsEmulatorReducer.netEmulator.locations
  )
  const networkError = useAppSelector(
    (state) => (state.rootReducer as any).networkError as boolean
  )

  useEffect(() => {
    dispatch(checkConnection(actions.NET_LOAD_MESSAGE_DEVICES_INIT))
  }, [])

  if (networkError) {
    return <NetworkError />
  }

  const [state, setState] = useReducer(
    (state: typeof initialState, newState: Partial<typeof initialState>) => ({
      ...state,
      ...newState,
    }),
    initialState
  )

  const {
    deviceName,
    isSubmitted,
    locationId,
    location,
    messageTypeText,
    imei,
    imeiCopy,
    transactionId,
    deviceDateTime,
    useCurrentTime,
    comment,
    softwareRevision,
    hardwareVersion,
    firmwareVersion,
    currentRunTime,
    networkMethod,
    certificatesInfo,
    commonDevicesInfo,
  } = state

  const changeDateValue = (value: any, name: string) => {
    setState({
      [name]: value,
    })
  }

  const changeValue = (event: any, name: string) => {
    setState({
      [name]: event.target.value,
    })
  }

  const changeMessageType = (event: any) => {
    const { value } = event.target

    setState({
      messageTypeText: value,
      isSubmitted: false,
    })
  }

  const changeDevice = (event: any) => {
    const { value } = event.target
    const imeiValue = getImeiByDevice(devices, value)
    const location = getLocationForDevice(devices, value, allLocations)

    setState({
      deviceName: value,
      imei: imeiValue || '',
      imeiCopy: imeiValue || '',
      location: location || initialState.location,
      locationId: location?.id.toString() || '',
    })
  }

  const onDeviceInfoChange = useCallback(
    (data: any, name: string) => {
      if (name === 'commonDevicesInfo') {
        setState({
          commonDevicesInfo: data,
        })
      } else {
        setState({
          certificatesInfo: data,
        })
      }
    },
    [state]
  )

  const changeUseCurrentTimeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target

      setState({
        useCurrentTime: checked,
      })
    },
    [state]
  )
  const locationsForNetDevices = useMemo(
    () => [location, { id: UNKNOWN_ID, name: UNKNOWN_LOCATION, children: [] }],
    [location]
  )

  const onSubmitButton = useCallback(() => {
    const copyOfState = { ...state }

    const isValid = isRequiredFieldsFilled(commonRequiredFields, copyOfState)

    if (isValid && validateBody(copyOfState)) {
      const params = pushParams(paramsFields, copyOfState)

      try {
        const body = prepareBody(copyOfState)

        dispatch(
          checkConnection(() =>
            actions.NET_SEND_DEVICE_MESSAGE_INIT({
              params: params.join('&'),
              body,
            })
          )
        )
      } catch (e) {
        dispatch(actions.NET_SEND_DEVICE_MESSAGE_ERROR(ERROR))
      }
    }

    setState({
      isSubmitted: true,
    })
  }, [dispatch, state])

  return (
    <>
      <div className={classes.formContainer}>
        {imei !== imeiCopy && (
          <Typography className={classes.checkboxLabel} variant="subtitle2">
            {FORM_LABELS.SERIAL_NUMBER}
          </Typography>
        )}
        <DefaultPart
          changeUseCurrentTimeCheckbox={changeUseCurrentTimeCheckbox}
          useCurrentTime={useCurrentTime}
          devices={devices}
          deviceDateTime={deviceDateTime}
          isSubmitted={isSubmitted}
          deviceName={deviceName}
          locations={locations}
          allLocations={locationsForNetDevices}
          locationId={locationId}
          messageTypeText={messageTypeText}
          transactionId={transactionId}
          changeDateValue={changeDateValue}
          changeValue={changeValue}
          changeMessageType={changeMessageType}
          messageConstants={messageConstants}
          isHideTransactionId={false}
          changeDevice={changeDevice}
        />

        <NetFields
          changeValue={changeValue}
          softwareRevision={softwareRevision}
          hardwareVersion={hardwareVersion}
          firmwareVersion={firmwareVersion}
          currentRunTime={currentRunTime}
          networkMethod={networkMethod}
          messageType={messageTypeText}
        />

        <DeviceInfoTable
          certificatesInfo={certificatesInfo}
          isSubmitted={isSubmitted}
          commDevicesInfo={commonDevicesInfo}
          changeDateValue={onDeviceInfoChange}
        />
        <CommentField changeValue={changeValue} comment={comment} />
        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={onSubmitButton}
        >
          {FORM_LABELS.SUBMIT}
        </Button>
      </div>

      <div className={classes.messageContainer}>
        {!error && deviceMessage && <JsonTable json={deviceMessage} />}
        {error && (
          <Typography variant="subtitle2" color="error">
            {error}
          </Typography>
        )}
      </div>
    </>
  )
}
