import * as actions from './netEmulator-actions'

import { call, fork, put, select, takeEvery } from 'redux-saga/effects'

import { Device } from 'containers/Devices/services/devices-types'
import { FEATURE_KEYS } from 'containers/EventsEmulator/_shared/services/emulator-constants'
import isError from 'lodash/isError'
import { HttpClient } from 'services/HttpClient'
import { RootState } from 'store/store'
import { Location } from 'types/locationTypes'
import { getErrorMessage } from 'utils/sagasHelpers'

const getOrganization = (state: RootState) =>
  state.eventsEmulatorReducer.sharedEmulatorReducer.selectedOrganization

function* loadMessageDevicesSaga() {
  yield takeEvery(actions.NET_LOAD_MESSAGE_DEVICES_INIT, function* (action) {
    try {
      const { name } = yield select(getOrganization)

      const body = {
        name,
        featureKeys: [FEATURE_KEYS.net],
      }

      const data: Device[] = yield call(() => {
        return HttpClient.post(`/api/secured/devices/organization`, {
          body,
        })
      })

      if (isError(data)) {
        yield put(actions.NET_LOAD_MESSAGE_DEVICES_ERROR(getErrorMessage(data)))
      } else {
        yield put(actions.NET_LOAD_MESSAGE_DEVICES_SUCCESS(data))
        yield put(actions.NET_LOAD_ALL_LOCATIONS_INIT())
      }
    } catch (e) {
      yield put(actions.NET_LOAD_MESSAGE_DEVICES_ERROR(getErrorMessage(e)))
    }
  })
}

function* sendDeviceMessageSaga() {
  yield takeEvery<typeof actions.NET_SEND_DEVICE_MESSAGE_INIT>(
    actions.NET_SEND_DEVICE_MESSAGE_INIT,
    function* (action) {
      try {
        const { params, body } = action.payload
        const { id } = yield select(getOrganization)

        const data: { s: string } = yield call(() => {
          return HttpClient.post(
            `/api/secured/network/admin/test/message/send?organizationId=${id}&${params}`,
            { body }
          )
        })

        if (isError(data)) {
          yield put(
            actions.NET_SEND_DEVICE_MESSAGE_ERROR(getErrorMessage(data))
          )
        } else {
          yield put(actions.NET_SEND_DEVICE_MESSAGE_SUCCESS(data))
        }
      } catch (e) {
        yield put(actions.NET_SEND_DEVICE_MESSAGE_ERROR(getErrorMessage(e)))
      }
    }
  )
}

function* loadAllLocationsSaga() {
  yield takeEvery<typeof actions.NET_LOAD_ALL_LOCATIONS_INIT>(
    actions.NET_LOAD_ALL_LOCATIONS_INIT,
    function* (action) {
      try {
        const { id } = yield select(getOrganization)

        const data: Location[] = yield call(() => {
          return HttpClient.get(`/api/secured/locations/?orgId=${id}`)
        })

        if (isError(data)) {
          yield put(actions.NET_LOAD_ALL_LOCATIONS_ERROR(getErrorMessage(data)))
        } else {
          yield put(actions.NET_LOAD_ALL_LOCATIONS_SUCCESS(data))
        }
      } catch (e) {
        yield put(actions.NET_LOAD_ALL_LOCATIONS_ERROR(getErrorMessage(e)))
      }
    }
  )
}

export default function* eventsNETEmulatorSaga() {
  yield fork(loadMessageDevicesSaga)
  yield fork(sendDeviceMessageSaga)
  yield fork(loadAllLocationsSaga)
}
