import {
  BOOL_OPTIONS,
  META_INFO_LABELS,
  REQUIRED_META_INFO_ROWS,
  TYPES_OPTIONS,
} from '../services/emulator-constants'

import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'
import find from 'lodash/find'
import CustomSelect from '../../../../components/_shared/customSelect/CustomSelect'
import { _messageTypeTemplate } from '../services/emulator-service'
import styles from './style'

const MetaInfoRow = (props) => {
  const {
    changeValue,
    index,
    classes,
    rowData: { value, key, type },
    deleteArrayElement,
    isSubmitted,
  } = props

  const requiredMetaInfoRow = find(REQUIRED_META_INFO_ROWS, { key })

  const handleChange = (event) => {
    const { name, value } = event.target

    changeValue(value, name, index)
  }

  const deleteRow = () => {
    deleteArrayElement(index)
  }

  return (
    <TableRow key={'devicesInfoRow_' + index}>
      <TableCell className={classes.simpleValues}>
        <TextField
          id="outlined-basic"
          value={key}
          name="key"
          onChange={handleChange}
          label={META_INFO_LABELS.key}
          variant="outlined"
          disabled={!!requiredMetaInfoRow}
        />
      </TableCell>
      <TableCell className={classes.simpleValues}>
        <CustomSelect
          options={TYPES_OPTIONS}
          name="type"
          label={META_INFO_LABELS.type}
          onChange={handleChange}
          value={type}
          valueName="value"
          template={_messageTypeTemplate}
          isReadOnly={!!requiredMetaInfoRow}
        />
      </TableCell>
      <TableCell className={classes.simpleValues}>
        {type === 'boolean' || requiredMetaInfoRow ? (
          <CustomSelect
            options={
              requiredMetaInfoRow ? requiredMetaInfoRow.options : BOOL_OPTIONS
            }
            name="value"
            label={META_INFO_LABELS.value}
            onChange={handleChange}
            value={value}
            valueName="value"
            template={_messageTypeTemplate}
            error={isSubmitted && !!requiredMetaInfoRow && !value}
          />
        ) : (
          <TextField
            id="outlined-basic"
            value={value}
            name="value"
            onChange={handleChange}
            type={type === 'string' ? 'text' : 'number'}
            variant="outlined"
          />
        )}
      </TableCell>
      <TableCell>
        <IconButton
          aria-label="delete"
          onClick={deleteRow}
          disabled={!!requiredMetaInfoRow}
        >
          <DeleteIcon color="error" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(MetaInfoRow)
