import { DEVICE_TYPE, PAGE_ACCESS, PERMISSIONS } from '../constants'
import { prepareAccessForPages, prepareTypes } from './root-service'

import featureTogglingActions from 'containers/FeatureToggling/services/featureToggling-actions'
import firmwaresActions from 'containers/Firmwares/services/firmwares-actions'
import firmwareUpdateActions from 'containers/FirmwareUpdateSchedule/services/firmwareUpdate-actions'
import organizationActions from 'containers/Organizations/services/organization-actions'
import { LOAD_USERS_SUCCESS } from 'containers/Users/services/users-actions'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import intersection from 'lodash/intersection'
import toUpper from 'lodash/toUpper'
import { createReducer } from 'redux-act'
import authorizationActions from '../../containers/Authorization/services/authorization-actions'
import rootActions from './root-actions'

const initialState = {
  isLoading: false,
  permissions: cloneDeep(PERMISSIONS),
  isRolesLoaded: false,
  isAnyPageAvailable: true,
  devicesTypes: [] as { label: string; value: string }[],
  networkError: false,
}

export const rootReducer = createReducer(
  {
    [authorizationActions.actionTypes.LOGOUT_SUCCEEDED]: (state, payload) => ({
      ...state,
      permissions: cloneDeep(PERMISSIONS),
      isRolesLoaded: false,
    }),
    [rootActions.actionTypes.SET_NETWORK_ERROR]: (state, payload) => {
      const { isOnline } = payload.isOnline

      return {
        ...state,
        networkError: !isOnline,
      }
    },
    [rootActions.actionTypes.GET_USER_GROUPS_SUCCEEDED]: (state, payload) => {
      const { groups } = payload
      const coincidence = intersection(PAGE_ACCESS, groups)

      return {
        ...state,
        isAnyPageAvailable: !!coincidence.length,
        isRolesLoaded: true,
        permissions: prepareAccessForPages(groups),
      }
    },
    [firmwareUpdateActions.actionTypes.LOAD_DEVICES_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        networkError: false,
      }
    },
    [organizationActions.actionTypes.LOAD_ORGANIZATIONS_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        networkError: false,
      }
    },
    [LOAD_USERS_SUCCESS.getType()]: (state, payload) => {
      return {
        ...state,
        networkError: false,
      }
    },
    [firmwaresActions.actionTypes.LOAD_FIRMWARES_SUCCESS]: (state, payload) => {
      return {
        ...state,
        networkError: false,
      }
    },
    [featureTogglingActions.actionTypes.GET_FEATURES_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        networkError: false,
      }
    },
    [rootActions.actionTypes.LOAD_DEVICES_TYPES_SUCCEEDED]: (
      state,
      payload
    ) => {
      const { types } = payload

      forEach(types, (type) => {
        if (type.displayValue === toUpper(DEVICE_TYPE.FAR_UVC.label)) {
          type.value = DEVICE_TYPE.FAR_UVC.type
        }
      })

      return {
        ...state,
        devicesTypes: prepareTypes(types),
      }
    },
    [rootActions.actionTypes.LOAD_SUB_TYPES_FOR_DEVICE_SUCCEEDED]: (
      state,
      payload
    ) => {
      return {
        ...state,
        subTypesForDevice: prepareTypes(payload.subTypes),
      }
    },
  },

  initialState
)
