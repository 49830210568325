import {
  DEVICE_DETAILS,
  DEVICE_SETTINGS,
  MAX_ON_TIME_VALUE,
  MAX_SAFETY_DELAY_VALUE,
  MIN_ON_TIME_VALUE,
  MIN_SAFETY_DELAY_VALUE,
} from '../../services/deviceDetails-constants'
import { SettingsErrors, SettingsProps } from './constants'

import { TextField } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { DEVICE_TYPE } from 'services/constants'
import { useAppSelector } from 'utils/reduxHelpers'
import { useStyles } from './style'
import { WarningTooltip } from './WarningTooltip'

type SettingsFieldsProps = {
  device: SettingsProps
  errors: SettingsErrors
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

const SettingsFields = ({ device, errors, onChange }: SettingsFieldsProps) => {
  const styles = useStyles()

  const version = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.version
  )
  const removed = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.removed
  )
  const isUVGIPowerManagementAvailable = useAppSelector(
    (state) =>
      state.devicesReducer.sharedDevicesReducer.isUVGIPowerManagementAvailable
  )
  const deviceType = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.deviceType
  )

  const isNotVive = DEVICE_TYPE.FAR_UVC.type !== deviceType

  const supportsOperationWindows =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (version || '').localeCompare('2.0.0', undefined, {
      numeric: true,
      sensitivity: 'base',
    }) >= 0

  return isUVGIPowerManagementAvailable ? (
    <>
      {isNotVive && (
        <WarningTooltip desiredKey="desiredPower" reportedKey="power">
          <TextField
            variant="outlined"
            name="desiredPower"
            error={errors.desiredPower}
            value={device.desiredPower || ''}
            onChange={onChange}
            label={DEVICE_SETTINGS.powerLevel}
            className={styles.input}
            inputProps={{ type: 'number', max: 100, min: 0 }}
            disabled={removed}
            required
          />
        </WarningTooltip>
      )}
      {isNotVive && (
        <WarningTooltip
          desiredKey="desiredSafetyDelay"
          reportedKey="safetyDelay"
        >
          <TextField
            variant="outlined"
            name="desiredSafetyDelay"
            error={errors.desiredSafetyDelay}
            onChange={onChange}
            value={device.desiredSafetyDelay || ''}
            label={DEVICE_SETTINGS.safetyDelaySeconds}
            className={styles.input}
            inputProps={{
              type: 'number',
              max: MAX_SAFETY_DELAY_VALUE,
              min: MIN_SAFETY_DELAY_VALUE,
            }}
            disabled={removed}
            required
          />
        </WarningTooltip>
      )}
      <WarningTooltip desiredKey="desiredOnTime" reportedKey="onTime">
        <TextField
          variant="outlined"
          name="desiredOnTime"
          error={errors.desiredOnTime}
          onChange={onChange}
          value={device.desiredOnTime || ''}
          label={DEVICE_SETTINGS.onTimeMinutes}
          className={styles.input}
          inputProps={{
            type: 'number',
            max: MAX_ON_TIME_VALUE,
            min: MIN_ON_TIME_VALUE,
          }}
          disabled={removed}
          required
        />
      </WarningTooltip>

      {supportsOperationWindows && (
        <WarningTooltip
          desiredKey="desiredOperationWindowSize"
          reportedKey="operationWindowSize"
        >
          <TextField
            variant="outlined"
            name="desiredOperationWindowSize"
            error={errors.desiredOperationWindowSize}
            onChange={onChange}
            value={device.desiredOperationWindowSize || ''}
            label={DEVICE_SETTINGS.operationWindowSize}
            className={styles.input}
            inputProps={{ type: 'number' }}
            disabled={removed}
            required
          />
        </WarningTooltip>
      )}
      <WarningTooltip
        desiredKey={
          supportsOperationWindows
            ? 'desiredOperationWindowRuntimeMax'
            : 'desiredDailyMaxRuntime'
        }
        reportedKey={
          supportsOperationWindows
            ? 'operationWindowRuntimeMax'
            : 'dailyMaxRuntime'
        }
      >
        <TextField
          variant="outlined"
          name="desiredOperationWindowRuntimeMax"
          error={errors.desiredOperationWindowRuntimeMax}
          onChange={onChange}
          value={device.desiredOperationWindowRuntimeMax || ''}
          label={
            supportsOperationWindows
              ? DEVICE_SETTINGS.operationWindowRuntimeMax
              : DEVICE_SETTINGS.dailyMaxRuntime
          }
          className={styles.input}
          inputProps={{ type: 'number' }}
          disabled={removed}
          required
        />
      </WarningTooltip>
    </>
  ) : (
    <div className={styles.powerAndRuntimeWrapper}>
      {isNotVive && (
        <div id="power-level-container">
          <Typography variant="subtitle1">
            {DEVICE_SETTINGS.powerLevel}:
          </Typography>
          <Typography variant="subtitle1" className={styles.value}>
            {device.desiredPower}
          </Typography>
        </div>
      )}
      {isNotVive && (
        <div id="safety-delay-container">
          <Typography variant="subtitle1">
            {DEVICE_SETTINGS.safetyDelaySeconds}:
          </Typography>
          <Typography variant="subtitle1" className={styles.value}>
            {device.desiredSafetyDelay}
          </Typography>
        </div>
      )}
      <div>
        <Typography variant="subtitle1">
          {DEVICE_SETTINGS.onTimeMinutes}:
        </Typography>
        <Typography variant="subtitle1" className={styles.value}>
          {device.desiredOnTime}
        </Typography>
      </div>
      {supportsOperationWindows && (
        <div>
          <Typography variant="subtitle1">
            {DEVICE_SETTINGS.operationWindowSize}:
          </Typography>
          <Typography variant="subtitle1" className={styles.value}>
            {device.desiredOperationWindowSize || DEVICE_DETAILS.noInformation}
          </Typography>
        </div>
      )}
      <div id="max-runtime-container">
        <Typography variant="subtitle1">
          {supportsOperationWindows
            ? DEVICE_SETTINGS.operationWindowRuntimeMax
            : DEVICE_SETTINGS.dailyMaxRuntime}
          :
        </Typography>
        <Typography variant="subtitle1" className={styles.value}>
          {device.desiredOperationWindowRuntimeMax ||
            DEVICE_DETAILS.noInformation}
        </Typography>
      </div>
    </div>
  )
}

export default SettingsFields
