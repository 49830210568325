import { combineReducers } from 'redux'
import { allowListReducer } from '../components/Details/components/AllowList/services/AllowList-reducer'
import { deviceDetailsReducer } from '../components/Details/services/deviceDetails-reducer'
import { devicesListReducer } from '../components/List/services/devicesList-reducers'
import { sharedDevicesReducer } from './devices-reducers'

export const devicesReducer = combineReducers({
  sharedDevicesReducer,
  deviceDetailsReducer,
  allowListReducer,
  devicesListReducer,
})
