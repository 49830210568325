import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { HttpClient } from '../../../services/HttpClient'
import actions from './failedDevices-actions'

const getPage = (state) => state.failedDevicesReducer.page
const getRowsPerPage = (state) => state.failedDevicesReducer.rowsPerPage

function* getFailedDevices() {
  try {
    const page = yield select(getPage)
    const rowsPerPage = yield select(getRowsPerPage)

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/uvi-arc/bulk/fails?order.created=desc&pageNum=${page}&pageSize=${rowsPerPage}`
      )
    })

    if (data.message) {
      yield put(actions.getFailedDevicesFailed(data.message))
    } else {
      yield put(actions.getFailedDevicesSuccess(data))
    }
  } catch (e) {
    yield put(actions.getFailedDevicesFailed(e.message))
  }
}

function* loadFailedDevicesSaga() {
  yield all([
    yield takeLatest(actions.actionTypes.GET_FAILED_DEVICES, getFailedDevices),
    yield takeLatest(actions.actionTypes.GO_TO_NEXT_PAGE, getFailedDevices),
    yield takeLatest(actions.actionTypes.SET_ROWS_PER_PAGE, getFailedDevices),
  ])
}

export default function* failedDevicesSagas() {
  yield fork(loadFailedDevicesSaga)
}
