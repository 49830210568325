import AccessWrapper from '../../components/_shared/AccessWrapper'
import { PAGES } from '../../services/constants'
import DevicesPage from './components/List/DevicesPage'

const Devices = (props) => {
  return (
    <AccessWrapper page={PAGES.device}>
      <DevicesPage {...props} />
    </AccessWrapper>
  )
}

export default Devices
