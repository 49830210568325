import forIn from 'lodash/forIn'
import map from 'lodash/map'
import toUpper from 'lodash/toUpper'
import { DEVICE_TYPE } from '../../../services/constants'

const prepareFirmwares = (list) => {
  const versionsByType = {}

  map(list, (item) => {
    versionsByType[item.type] = item
  })

  return versionsByType
}

const getSubType = (type) => {
  if (type?.props?.children) {
    const { value } = type.props

    if (value === toUpper(DEVICE_TYPE.UR_UVGI.type)) {
      return DEVICE_TYPE.UR_UVGI.type
    } else if (value === toUpper(DEVICE_TYPE.FAR_UVC.type)) {
      return DEVICE_TYPE.FAR_UVC.type
    }
  }

  return false
}

const getVersionString = (firmwares, firmwareType, type) =>
  `Type: ${firmwareType}, ${type} version - ${firmwares[firmwareType].version}`

const getNoVersionString = (firmwareType) =>
  `Type: ${firmwareType}, active version - not set`

const prepareMessageText = (firmwares, type) => {
  const messages = []

  forIn(firmwares, (value, key) => {
    if (firmwares[key]) {
      messages.push(getVersionString(firmwares, key, type))
    } else {
      messages.push(getNoVersionString(key))
    }
  })

  return messages.join(' | ')
}

const _messageTypeTemplate = (item) => item.label

export {
  prepareMessageText,
  prepareFirmwares,
  _messageTypeTemplate,
  getSubType,
}
