import AccessWrapper from '../../../../components/_shared/AccessWrapper'
import { PAGES } from '../../../../services/constants'
import ChangeAccountOwnerPage from './ChangeAccountOwnerPage'

const ChangeAccountOwner = (props) => {
  return (
    <AccessWrapper page={PAGES.organizations} isWriteOnly={true}>
      <ChangeAccountOwnerPage {...props} />
    </AccessWrapper>
  )
}

export default ChangeAccountOwner
