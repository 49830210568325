import find from 'lodash/find'
import forEach from 'lodash/forEach'
import forIn from 'lodash/forIn'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import filterQuery from '../../../services/filterQuery-service'

const filterInitialState = {
  serialNumber: '',
  version: '',
  desiredVersion: '',
  organization: {},
}

const prepareFilterQuery = (params) => {
  const filtersArray = []
  const likeQueryLabels = ['serialNumber', 'version', 'desiredVersion']

  filtersArray.push(
    filterQuery.getQueryString(
      false,
      filterQuery.templates.default,
      'removed',
      'eq'
    )
  )

  if (params) {
    if (params.organization && !isEmpty(params.organization)) {
      filtersArray.push(
        filterQuery.getQueryString(
          params.organization.id,
          filterQuery.templates.default,
          'organizationId',
          'eq'
        )
      )
    }

    if (params.firmwareType) {
      filtersArray.push(
        filterQuery.getQueryString(
          params.firmwareType,
          filterQuery.templates.default,
          'type',
          'eq'
        )
      )
    }

    forIn(params, (value, key) => {
      if (likeQueryLabels.indexOf(key) !== -1 && value) {
        filtersArray.push(
          filterQuery.getQueryString(
            `*${value}*`,
            filterQuery.templates.default,
            key,
            'contains'
          )
        )
      }
    })
  }

  const queryString = filterQuery.createQueryStringFromArray(
    filtersArray,
    'and'
  )

  return queryString && `?query=${queryString}`
}

const headCells = [
  {
    id: 'serialNumber',
    numeric: false,
    disablePadding: true,
    label: 'Serial Number',
  },
  { id: 'type', numeric: false, disablePadding: false, label: 'Firmware Type' },
  {
    id: 'version',
    numeric: false,
    disablePadding: false,
    label: 'Actual Version',
  },
  {
    id: 'assignedDate',
    numeric: false,
    disablePadding: false,
    label: 'Actual Version assign date',
  },
  {
    id: 'desiredVersion',
    numeric: false,
    disablePadding: false,
    label: 'Desired Version',
  },
  {
    id: 'desiredDate',
    numeric: false,
    disablePadding: false,
    label: 'Desired Version scheduled date',
  },
  {
    id: 'commentary',
    numeric: false,
    disablePadding: false,
    label: 'Commentary',
  },
]

const tableLabels = {
  serialNumber: 'Serial Number',
  type: 'Firmware Type',
  version: 'Actual Version',
  assignedDate: 'Actual Version assign date',
  desiredVersion: 'Desired Version',
  desiredDate: 'Desired Version scheduled date',
  commentary: 'Commentary',
  organizationName: 'Organization',
}

const tableCells = {
  serialNumber: { type: 'text' },
  type: { type: 'text' },
  version: { type: 'text' },
  assignedDate: { type: 'data' },
  desiredVersion: { type: 'text' },
  desiredDate: { type: 'data' },
  commentary: { type: 'text' },
  organizationName: { type: 'text' },
}

const getFirmwareAndDeviceTypeMap = (typesArray) => {
  const result = {}

  forEach(typesArray, (data) => {
    result[data.firmwareType] = data.deviceType
  })

  return result
}

const getSelectedDeviceFirmware = (devices, ids) => {
  return map(ids, (id) => find(devices, ['id', id]))
}

const getConformationText = (multiple) =>
  `Are you sure you want to clear desired firmware for device${
    multiple ? 's' : ''
  }?`

export {
  filterInitialState,
  prepareFilterQuery,
  headCells,
  tableLabels,
  tableCells,
  getFirmwareAndDeviceTypeMap,
  getSelectedDeviceFirmware,
  getConformationText,
}
