const styles = (theme) => ({
  dataCell: {
    minWidth: '180px',
  },
  simpleValues: {
    width: '220px',
  },
})

export default styles
