import { createRef, useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useDispatch } from 'react-redux'
import { checkConnection } from 'services/root/root-service'
import { useAppSelector } from 'utils/reduxHelpers'
import { DELETE_LOCATION_WITH_SUBLOCATIONS } from '../../../services/organizationDetails-actions'
import { DELETE_LOCATION_LABELS } from '../../../services/organizationDetails-constants'
import { useStyles } from './style'

type DeleteContentProps = {
  id: string
  parentId: string
  name: string
  handleClose: () => void
  isOpen: boolean
}

const DeleteContent = ({
  id,
  parentId,
  name,
  handleClose,
  isOpen,
}: DeleteContentProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const inputRef = createRef<HTMLInputElement>()
  const [error, setError] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const isLoadingSubLocations = useAppSelector(
    (state) => state.organizationDetailsReducer.isLoadingSubLocations
  )
  const subLocationsError = useAppSelector(
    (state) => state.organizationDetailsReducer.error
  )
  const subLocationsCount = useAppSelector(
    (state) => state.organizationDetailsReducer.subLocationsCount
  )

  const deleteLocationWithSubLocations = (count: number) =>
    dispatch(
      checkConnection(() =>
        DELETE_LOCATION_WITH_SUBLOCATIONS(id, parentId, count, handleClose)
      )
    )

  const withInput = subLocationsCount !== 0

  const dialogHelperText = !withInput
    ? DELETE_LOCATION_LABELS.deleteWithoutSubLocations1(name)
    : DELETE_LOCATION_LABELS.deleteWithSubLocations1(name, subLocationsCount)
  const dialogHelperText3 = DELETE_LOCATION_LABELS.commonMessage(name)
  const deleteWarningConfirm =
    withInput && DELETE_LOCATION_LABELS.deleteWarningConfirm(subLocationsCount)

  useEffect(() => {
    setInputValue('')
  }, [isOpen])

  useEffect(() => {
    if (isOpen && withInput && inputRef?.current) {
      inputRef.current.focus()
    }
  }, [isLoadingSubLocations, isOpen, withInput, inputRef])

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value

    if (/^\d+$/.test(value) || value === '') {
      setInputValue(value)
    }
  }

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault()

    if ((withInput && inputValue) || !withInput) {
      setError(false)
      deleteLocationWithSubLocations(withInput ? Number(inputValue) : 0)
    } else {
      setError(true)
    }
  }

  return (
    <>
      <Typography variant="body2">{dialogHelperText}</Typography>
      <br />
      <Typography variant="body2">{dialogHelperText3}</Typography>
      <br />
      <Typography variant="body2">{deleteWarningConfirm}</Typography>

      <form onSubmit={handleSubmit} className={styles.formWrapper}>
        {withInput && (
          <TextField
            className={styles.input}
            variant="outlined"
            value={inputValue}
            onChange={onChange}
            placeholder={`${subLocationsCount}`}
            inputProps={{ className: styles.outlined }}
            error={error}
            inputRef={inputRef}
          />
        )}
        <Typography variant="subtitle1" color="error" className={styles.error}>
          {subLocationsError}
        </Typography>

        <Button
          type="submit"
          color="secondary"
          className={styles.submitButton}
          variant="contained"
        >
          {DELETE_LOCATION_LABELS.deleteLocation}
        </Button>
      </form>
    </>
  )
}

export default DeleteContent
