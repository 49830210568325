import { withStyles } from '@material-ui/core/styles'
import DeviceDetailsData from '../DeviceDetailsData'
import EditableExtDeviceFields from '../EditableExtDeviceFields'
import style from './style'

const EditExtDevice = (props) => {
  const { state, setState, errors, onChange } = props

  return (
    <>
      <DeviceDetailsData
        serialNumber={state.serialNumber}
        deviceType={state.deviceType}
        deviceId={state.id}
        removed={state.removed}
        isEdit
      />

      <EditableExtDeviceFields
        state={state}
        setState={setState}
        errors={errors}
        onChange={onChange}
        isEditing
      />
    </>
  )
}

export default withStyles(style)(EditExtDevice)
