import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CustomSelect from '../../../../components/_shared/customSelect/CustomSelect'
import { FIRMWARE_UPDATES_LABELS } from '../../ARC/services/arcEmulator-constants'
import styles from '../../style'
import EmulatorDatepicker from './EmulatorDatepicker'

const FirmwareUpdateMessageComponent = (props) => {
  const {
    classes,
    isSubmitted,
    changeValue,
    changeDateValue,
    type,
    status,
    version,
    time,
    firmwareOptions,
  } = props

  const _messageTypeTemplate = (item) => item.label

  const onChangeValue = (event) => {
    changeValue(event, event.target.name)
  }

  return (
    <div className={classes.firmWareUpdateContainer}>
      <CustomSelect
        options={firmwareOptions}
        isRequired={true}
        name="firmwareUpdateType"
        label={FIRMWARE_UPDATES_LABELS.firmwareUpdateType}
        onChange={onChangeValue}
        error={!type && isSubmitted}
        value={type}
        valueName="value"
        template={_messageTypeTemplate}
      />

      <TextField
        id="outlined-basic"
        value={status}
        disabled
        label={FIRMWARE_UPDATES_LABELS.firmwareUpdateStatus}
        variant="outlined"
      />

      <TextField
        id="outlined-basic"
        value={version}
        name="firmwareUpdateVersion"
        onChange={onChangeValue}
        required
        error={!version && isSubmitted}
        inputProps={{ maxLength: 24 }}
        label={FIRMWARE_UPDATES_LABELS.firmwareUpdateVersion}
        variant="outlined"
      />
      <Typography variant="subtitle2">
        {FIRMWARE_UPDATES_LABELS.firmwareUpdateTime}
      </Typography>
      <EmulatorDatepicker
        time={time}
        name={'firmwareUpdateTime'}
        changeDateValue={changeDateValue}
      />
    </div>
  )
}

export default withStyles(styles)(FirmwareUpdateMessageComponent)
