import values from 'lodash/values'

const MOTION_PIR_DEFAULT_VALUES = {
  description: ['0', '0', '0'],
  alertStatus: ['Info', 'Info', 'Info'],
  sensorId: [1, 2, 3],
}

const FAULT_DEFAULT_DATA = {
  name: '',
  faultEventDate: new Date(),
  isCurrentTime: true,
}

const DEFAULT_DEVICE_INFO = {
  commDeviceName: '',
  measurementDate: new Date(),
  isCurrentTime: true,
  measurementUnit: 'DBM',
  measurementValue: 0,
}

const DEFAULT_META_INFO = {
  key: '',
  type: 'string',
  value: '',
}

/*
    POWER - when device is powered on.
    MOTION - when cycle starts by PIR sensors
    MANUAL - when cycle starts by Operator command
*/
const DEVICE_MODE_OPTIONS = [
  {
    label: 'Power',
    value: 'POWER',
  },
  {
    label: 'Motion',
    value: 'MOTION',
  },
  {
    label: 'Manual',
    value: 'MANUAL',
  },
]

const initialState = {
  deviceName: '',
  locationId: '',
  location: { name: '' },
  messageTypeText: '',
  transactionId: '',
  useCurrentTime: true,
  deviceDateTime: new Date(),
  outputPower: 100,
  mode: 'MOTION',
  messageFrequency: '',

  txStartedAt: '',

  metaInfo: [DEFAULT_META_INFO],

  timeout: '',

  motionPIRDescription: MOTION_PIR_DEFAULT_VALUES.description,
  motionPIRAlertStatus: MOTION_PIR_DEFAULT_VALUES.alertStatus,

  faultEventData: [{ ...FAULT_DEFAULT_DATA }],

  firmwareUpdateStatus: '',
  firmwareUpdateTime: new Date(),
  firmwareUpdateType: '',
  firmwareUpdateVersion: '',

  bulbChangeDate: new Date(),
  deviceRevision: '',
  softwareRevision: 'UVGI:?',
  bulbCyclesSinceChange: 0,
  powerCyclesSinceChange: 0,
  abuseEvents: 0,
  totalLEDTime: 0,
  totalRunTime: 0,
  ledTemp: 0,
  weightedTime: 0,

  commonDevicesInfo: [{ ...DEFAULT_DEVICE_INFO }],
  certificatesInfo: {
    mac: '',
    certificateId: '',
    publicKeyHash: '',
    privateKeyHash: '',
    certificateHash: '',
  },

  imei: '',
  imeiCopy: '',
  comment: 'web-emulator',
}

const LABELS = {
  powerOn: 'power on',
  powerOff: 'power off',
  start: 'cycle start',
  finish: 'cycle finish',
  proceed: 'cycle proceed',
  pause: 'cycle pause',
  resume: 'cycle resume',
  cycleCountdown: 'cycle countdown',
  cycleCancel: 'cycle cancel',
  firmwareStart: 'firmware update start',
  firmwareSuccess: 'firmware update success',
  firmwareFail: 'firmware update fail',
  statusEvent: 'status',
  motionPIR: 'motion PIR',
  mode: 'Mode',
  outputPower: 'Output Power',
  messageFrequency: 'Message Frequency',
  duration: 'Duration',
  timeout: 'Timeout',
  deviceStatistic: 'device statistic',
  requestParameters: 'request parameters',
  initDeviceMessage: 'init device message',
  name: 'Name',
}

const EVENTS_CODES = {
  evt011: 'EVT011',
  evt111_on: 'EVT111_on',
  evt111_off: 'EVT111_off',
  evt311_start: 'evt311_start',
  evt311_finish: 'evt311_finish',
  evt311_proceed: 'evt311_proceed',
  evt311_pause: 'evt311_pause',
  evt311_resume: 'evt311_resume',
  evt311_cycle_countdown: 'evt311_cycle_countdown',
  evt311_cycle_cancel: 'evt311_cycle_cancel',
  evt415: 'EVT415',
  evt416: 'EVT416',
  evt417: 'EVT417',
  evt511: 'EVT511',
  evt711: 'EVT711',
  sts011: 'STS011',
  ini011: 'INI011',
}

const EVENTS_VALUES = {
  on: 'power on',
  off: 'power off',
  start: 'cycle start',
  finish: 'cycle finish',
  proceed: 'cycle proceed',
  START: 'START',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  pause: 'cycle pause',
  resume: 'cycle resume',
  cycleCountdown: 'cycle countdown',
  cycleCancel: 'cycle cancel',
}

const powerCodes = [EVENTS_CODES.evt111_on, EVENTS_CODES.evt111_off]

const mainOperationsValues = [
  EVENTS_VALUES.start,
  EVENTS_VALUES.finish,
  EVENTS_VALUES.proceed,
  EVENTS_VALUES.pause,
  EVENTS_VALUES.resume,
  EVENTS_VALUES.cycleCountdown,
  EVENTS_VALUES.cycleCancel,
]

const metaInfoValues = [
  ...values(EVENTS_VALUES),
  EVENTS_CODES.evt415,
  EVENTS_CODES.evt416,
  EVENTS_CODES.evt417,
  EVENTS_CODES.evt511,
  EVENTS_CODES.evt711,
  EVENTS_CODES.ini011,
  EVENTS_CODES.sts011,
  EVENTS_CODES.evt011,
]

const firmwareCodes = [
  EVENTS_CODES.evt415,
  EVENTS_CODES.evt416,
  EVENTS_CODES.evt417,
]

const messageConstants = [
  {
    label: LABELS.powerOn,
    value: EVENTS_VALUES.on,
    code: EVENTS_CODES.evt111_on,
  },
  {
    label: LABELS.powerOff,
    value: EVENTS_VALUES.off,
    code: EVENTS_CODES.evt111_off,
  },
  {
    label: LABELS.start,
    value: EVENTS_VALUES.start,
    code: EVENTS_CODES.evt311_start,
  },
  {
    label: LABELS.finish,
    value: EVENTS_VALUES.finish,
    code: EVENTS_CODES.evt311_finish,
  },
  {
    label: LABELS.proceed,
    value: EVENTS_VALUES.proceed,
    code: EVENTS_CODES.evt311_proceed,
  },
  {
    label: LABELS.pause,
    value: EVENTS_VALUES.pause,
    code: EVENTS_CODES.evt311_pause,
  },
  {
    label: LABELS.resume,
    value: EVENTS_VALUES.resume,
    code: EVENTS_CODES.evt311_resume,
  },
  {
    label: LABELS.cycleCountdown,
    value: EVENTS_VALUES.cycleCountdown,
    code: EVENTS_CODES.evt311_cycle_countdown,
  },
  {
    label: LABELS.cycleCancel,
    value: EVENTS_VALUES.cycleCancel,
    code: EVENTS_CODES.evt311_cycle_cancel,
  },
  {
    label: LABELS.firmwareStart,
    value: EVENTS_CODES.evt415,
    code: EVENTS_CODES.evt415,
  },
  {
    label: LABELS.firmwareSuccess,
    value: EVENTS_CODES.evt416,
    code: EVENTS_CODES.evt416,
  },
  {
    label: LABELS.firmwareFail,
    value: EVENTS_CODES.evt417,
    code: EVENTS_CODES.evt417,
  },
  {
    label: LABELS.statusEvent,
    value: EVENTS_CODES.evt511,
    code: EVENTS_CODES.evt511,
  },
  {
    label: LABELS.motionPIR,
    value: EVENTS_CODES.evt711,
    code: EVENTS_CODES.evt711,
  },
  {
    label: LABELS.initDeviceMessage,
    value: EVENTS_CODES.ini011,
    code: EVENTS_CODES.ini011,
  },
  {
    label: LABELS.deviceStatistic,
    value: EVENTS_CODES.sts011,
    code: EVENTS_CODES.sts011,
  },
  {
    label: LABELS.requestParameters,
    value: EVENTS_CODES.evt011,
    code: EVENTS_CODES.evt011,
  },
]

const EXTENDED_MODE = {
  specialMode1: 'Special mode 1',
  specialMode2: 'Special mode 2',
  checkboxLabel: 'Extended mode',
}

const MOTION_PIR_TABLE = {
  sensor: {
    label: 'Sensor',
    options: [
      'Occupancy (1)',
      'Left Disinfection (2)',
      'Right Disinfection (3)',
    ],
  },
  eventDescription: {
    label: 'Event Description',
    options: ['0', '1'],
  },
  alertStatus: {
    label: 'Alert Status',
    defaultOption: 'Info',
    options: [
      {
        label: 'Info',
        value: 'Info',
      },
      {
        label: 'Not Critical Error',
        value: 'NotCriticalError',
      },
      {
        label: 'Critical Error',
        value: 'CriticalError',
      },
    ],
  },
}

const FAULT_EVENTS = {
  tableColumnsLabels: ['Name', 'Date', 'Use Current Time', ''],
  tableTitle: 'Fault Info',
}

const FAULT_EVENT_NAMES = [
  {
    label: 'Over-heated-LED',
    value: 'OVER_HEATED_LED',
  },
  {
    label: 'Non-functioning-fan',
    value: 'NON_FUNCTIONING_FAN',
  },
  {
    label: 'Bad-mount-contact',
    value: 'BAD_MOUNT_CONTACT',
  },
  {
    label: 'Baffle-open',
    value: 'BAFFLE_OPEN',
  },
  {
    label: 'Manual-reset',
    value: 'MANUAL_RESET',
  },
  {
    label: 'Hardware-issue',
    value: 'HARDWARE_ISSUE',
  },
  {
    label: 'Ballast-status',
    value: 'BALLAST_STATUS',
  },
  {
    label: 'Ux-pcb-disconnected',
    value: 'UX_PCB_DISCONNECTED',
  },
]

const firmwareUpdateTypes = [
  {
    label: 'Beam',
    value: 'UR_UVGI_COMBINED',
  },
]

const firmwareUpdateStatuses = {
  [EVENTS_CODES.evt415]: 'START',
  [EVENTS_CODES.evt416]: 'SUCCESS',
  [EVENTS_CODES.evt417]: 'FAIL',
}

const DEVICE_STATISTIC_LABELS = {
  totalLEDTime: 'Total LED Time',
  totalRunTime: 'Total Run Time',
  ledTemp: 'LED Temp',
  weightedTime: 'Weighted Time',
  commDevicesInfo: 'Communication Devices Info',
  mac: 'MAC',
  commDeviceName: 'Device Name',
  measurementDate: 'Date',
  measurementUnit: 'Unit',
  measurementValue: 'Value',
  certificatesInfo: 'Certificates Info',
  certificateId: 'Certificate Id',
  publicKeyHash: 'Public Key Hash',
  privateKeyHash: 'Private Key Hash',
  certificateHash: 'Certificate Hash',
  commDevicesInfoColumnsNames: [
    'MAC Adress',
    'Communication Device Name',
    'Measurement Date',
    'Measurement Unit',
    'Measurement Value',
    '',
  ],
}

const COMMUNICATION_DEVICE_NAME_OPTIONS = [
  {
    label: 'WIFI',
    value: 'WIFI',
  },
  {
    label: 'LORA',
    value: 'LORA',
  },
  {
    label: 'BLUETOOTH',
    value: 'BLUETOOTH',
  },
  {
    label: 'LTE',
    value: 'LTE',
  },
]

const DATE_FIELDS = ['bulbChangeDate', 'firmwareUpdateTime']

export {
  messageConstants,
  LABELS,
  EXTENDED_MODE,
  MOTION_PIR_TABLE,
  MOTION_PIR_DEFAULT_VALUES,
  FAULT_EVENT_NAMES,
  FAULT_EVENTS,
  FAULT_DEFAULT_DATA,
  EVENTS_CODES,
  powerCodes,
  mainOperationsValues,
  DEVICE_MODE_OPTIONS,
  firmwareCodes,
  firmwareUpdateStatuses,
  firmwareUpdateTypes,
  EVENTS_VALUES,
  initialState,
  DEVICE_STATISTIC_LABELS,
  DEFAULT_DEVICE_INFO,
  metaInfoValues,
  DEFAULT_META_INFO,
  COMMUNICATION_DEVICE_NAME_OPTIONS,
  DATE_FIELDS,
}
