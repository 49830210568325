import {
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import {
  IDP_FIELDS,
  IDP_MODAL_LABELS,
  initialValueIdpData,
} from '../../services/organization-constants'
import {
  cantBeDeleted,
  getIdpErrors,
} from '../../services/organization-service'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import some from 'lodash/some'
import Loader from '../../../../components/_shared/loader/Loader'
import style from './style'

const IdpModal = (props) => {
  const {
    isOpen,
    id,
    idpData = initialValueIdpData,
    saveIdpData,
    deleteIdpData,
    error,
    onClose,
    isLoading,
    classes,
  } = props
  const [state, setState] = useState(idpData)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (!isEqual(state, idpData)) {
      setState(idpData)
    }
    // eslint-disable-next-line
  }, [idpData])

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const getModalContent = () =>
    map(IDP_FIELDS, (field) => (
      <TextField
        key={field.name}
        name={field.name}
        variant="outlined"
        label={field.label}
        value={state[field.name]}
        error={errors[field.name]}
        onChange={onChange}
        disabled={field.disabled}
        required={field.required}
      />
    ))

  const onSave = () => {
    const newErrors = getIdpErrors(state)
    setErrors(newErrors)

    if (!some(newErrors)) {
      saveIdpData(id, state, handleClose())
    }
  }

  const onDelete = () => {
    deleteIdpData(id)
  }

  const handleClose = () => {
    setErrors({})

    onClose()
  }

  return (
    <Dialog open={isOpen} keepMounted onClose={handleClose} maxWidth="md">
      <DialogTitle className={classes.modalTitleContainer} disableTypography>
        <Typography
          variant="h5"
          color="textPrimary"
          className={classes.modalTitle}
        >
          {IDP_MODAL_LABELS.title}
        </Typography>
        <IconButton
          className={classes.closeIcon}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Loader isLoading={isLoading} loaderKey={id} inModal>
          {error && (
            <Typography
              variant="subtitle1"
              color="error"
              className={classes.error}
            >
              {error}
            </Typography>
          )}
          {getModalContent()}
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onSave}
          >
            {IDP_MODAL_LABELS.save}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onDelete}
            disabled={cantBeDeleted(idpData)}
          >
            {IDP_MODAL_LABELS.delete}
          </Button>
        </Loader>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(style)(IdpModal)
