const EMULATOR_PAGE_LABELS = {
  pageTitle: 'Device Emulator',
  submit: 'Submit',
  deviceSelect: 'Device',
  organization: 'Organization',
}

const COMMANDS_GENERATOR = {
  columnNames: ['UUID', 'Type', 'Property', 'Value', 'Ordering', ''],
  tableTitle: 'Commands',
  defaultCommand: {
    uuid: '',
    type: '',
    name: '',
    value: 0,
    ordering: 0,
  },
  tableLabels: {
    uuid: 'UUID',
    type: 'Command Type',
    name: 'Property name',
    value: 'Value',
    ordering: 'Ordering',
  },
  helperText: ' (Command already exists)',
}

const COMMANDS_RECEIVER = {
  titleNew: 'New Commands',
  titleExisting: 'Existing Commands from DB',
  buttonLabel: 'Refresh',
  statuses: ['success', 'failed'],
  tableKeys: [
    'name',
    'type',
    'uuid',
    'value',
    'result',
    'created',
    'ordering',
    'status',
    'modified',
  ],
  extendedTableKeys: (values) => [
    'name',
    'type',
    'uuid',
    'created',
    'ordering',
    'modified',
    ...values,
    'status',
  ],
  commandTitle: (count) => `Command #${count}`,
  postReport: 'Post Report',
  noCommandsLabel:
    "No commands for this device. Click 'Refresh' to update the list.",
}

const KEY_TYPES = {
  value: 'value',
  result: 'result',
  status: 'status',
}

// for now
const AVAILABLE_COMMAND_TYPES = [
  {
    label: 'Change State',
    value: 'CHANGE_STATE',
  },
]

const AVAILABLE_PROP_NAMES = [
  {
    label: 'Output Power',
    value: 'OUTPUT_POWER',
  },
]

const DEFAULT_COMMAND_TEMPLATE = {
  uuid: '',
  type: '',
  name: '',
  value: 0,
  ordering: 0,
}

export {
  EMULATOR_PAGE_LABELS,
  COMMANDS_GENERATOR,
  COMMANDS_RECEIVER,
  AVAILABLE_COMMAND_TYPES,
  AVAILABLE_PROP_NAMES,
  DEFAULT_COMMAND_TEMPLATE,
  KEY_TYPES,
}
