import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { connect } from 'react-redux'
import { EMULATOR_PAGE_LABELS } from '../../services/emulator-constants'
import styles from './style'

const OrganizationAutocomplete = (props) => {
  const { organizations, organization, onChange, classes } = props

  return (
    <div className={classes.deviceSelectWrapper}>
      <Autocomplete
        onChange={onChange}
        value={organization}
        options={organizations}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={EMULATOR_PAGE_LABELS.organization}
            variant="outlined"
            margin="normal"
          />
        )}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  organizations: state.emulatorReducer.organizations,
})

export default connect(mapStateToProps)(
  withStyles(styles)(OrganizationAutocomplete)
)
