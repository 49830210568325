import { FORM_LABELS, TYPES } from '../services/emulator-constants'
import {
  isTransactionIdValid,
  _deviceNameTemplate,
  _locationLabelTemplateDesktop,
  _locationLabelTemplateMobile,
  _messageTypeTemplate,
} from '../services/emulator-service'

import { FormControlLabel } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CustomSelect from '../../../../components/_shared/customSelect/CustomSelect'
import { MMddyyyyhmmssaa } from '../../../../services/dateFormat-service'
import { withMediaQuery } from '../../../../services/root/root-service'
import styles from '../../style'
import EmulatorDatepicker from './EmulatorDatepicker'

const DefaultPartComponent = (props) => {
  const {
    classes,
    isSubmitted,
    changeValue,
    isMobile,
    messageTypeText,
    deviceLocationOnly,
    devices,
    deviceName,
    locations,
    allLocations,
    locationId,
    changeUseCurrentTimeCheckbox,
    useCurrentTime,
    changeDevice,
    changeDateValue,
    changeMessageType,
    transactionId,
    deviceDateTime,
    messageConstants,
    isHideTransactionId,
    isUVGI = false,
  } = props
  const isNotRequestedParams = messageTypeText !== TYPES.requestParameters

  const changeLocationId = (event) => {
    changeValue(event, 'locationId')
  }

  const changeTransactionId = (event) => {
    changeValue(event, 'transactionId')
  }

  return (
    <>
      <CustomSelect
        options={devices}
        isRequired={true}
        error={isSubmitted && !deviceName}
        label={FORM_LABELS.DEVICE_NAME}
        onChange={changeDevice}
        value={deviceName}
        valueName="serialNumber"
        template={_deviceNameTemplate}
      />
      {isNotRequestedParams && (
        <CustomSelect
          options={deviceLocationOnly ? locations : allLocations}
          isRequired={true}
          error={isSubmitted && !locationId}
          label={FORM_LABELS.LOCATION}
          onChange={changeLocationId}
          value={locationId}
          valueName="id"
          subheaderField="subheader"
          template={
            isMobile
              ? _locationLabelTemplateMobile
              : _locationLabelTemplateDesktop
          }
        />
      )}
      <CustomSelect
        options={messageConstants}
        isRequired={true}
        error={isSubmitted && !messageTypeText}
        label={FORM_LABELS.MESSAGE_TYPE}
        onChange={changeMessageType}
        value={messageTypeText}
        valueName={'value'}
        template={_messageTypeTemplate}
      />
      {isNotRequestedParams && !isHideTransactionId && (
        <TextField
          id="outlined-basic"
          value={transactionId}
          onChange={changeTransactionId}
          minLength={17}
          error={
            !!(
              transactionId &&
              !isTransactionIdValid(transactionId, isUVGI) &&
              isSubmitted
            )
          }
          label={
            isUVGI
              ? FORM_LABELS.TRANSACTION_ID_UVGI
              : FORM_LABELS.TRANSACTION_ID
          }
          variant="outlined"
        />
      )}
      <FormControlLabel
        classes={{ label: classes.checkboxLabel }}
        className={classes.deviceLocationOnly}
        control={
          <Checkbox
            checked={useCurrentTime}
            onChange={changeUseCurrentTimeCheckbox}
            color="primary"
          />
        }
        label={FORM_LABELS.USE_CURRENT_TIME}
      />
      <EmulatorDatepicker
        time={deviceDateTime}
        name={'deviceDateTime'}
        disabled={useCurrentTime}
        showTimeInput
        dateFormat={MMddyyyyhmmssaa}
        changeDateValue={changeDateValue}
      />
    </>
  )
}

export default withMediaQuery(withStyles(styles)(DefaultPartComponent))
