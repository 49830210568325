import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { HttpClient } from '../../../services/HttpClient'
import { FEATURE_KEYS } from '../../EventsEmulator/_shared/services/emulator-constants'
import emulatorActions from './emulator-actions'

function* loadOrganizations() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/organizations/scroll?order.name=asc')
    })

    if (data.message) {
      yield put(emulatorActions.loadOrganizationsFailed(data.message))
    } else {
      yield put(emulatorActions.loadOrganizationsSuccess(data))
    }
  } catch (e) {
    yield put(emulatorActions.loadOrganizationsFailed(e.message))
  }
}

function* getDevicePortalURL(action) {
  try {
    const data = yield call(() => {
      return HttpClient.get(`/api/secured/commands/device-portal`)
    })

    if (data.message) {
      yield put(emulatorActions.getDevicePortalURLFailed(data.message))
    } else {
      yield put(emulatorActions.getDevicePortalURLSuccess(data))
    }
  } catch (e) {
    yield put(emulatorActions.getDevicePortalURLFailed(e.message))
  }
}

function* loadUVGIDevices(action) {
  try {
    const {
      org: { name },
    } = action.payload

    const body = {
      name,
      featureKeys: [FEATURE_KEYS.beam, FEATURE_KEYS.vive],
    }

    const data = yield call(() => {
      return HttpClient.post(`/api/secured/devices/organization`, { body })
    })

    if (data.message) {
      yield put(emulatorActions.loadUVGIDevicesFailed(data.message))
    } else {
      yield put(emulatorActions.loadUVGIDevicesSuccess(data))
    }
  } catch (e) {
    yield put(emulatorActions.loadUVGIDevicesFailed(e.message))
  }
}

function* loadCommandNames(action) {
  try {
    const data = yield call(() => {
      return HttpClient.get(`/api/secured/commands/names`)
    })

    if (data.message) {
      yield put(emulatorActions.loadCommandNamesFailed(data.message))
    } else {
      yield put(emulatorActions.loadCommandNamesSuccess(data))
    }
  } catch (e) {
    yield put(emulatorActions.loadCommandNamesFailed(e.message))
  }
}

function* loadCommandTypes(action) {
  try {
    const data = yield call(() => {
      return HttpClient.get(`/api/secured/commands/types`)
    })

    if (data.message) {
      yield put(emulatorActions.loadCommandTypesFailed(data.message))
    } else {
      yield put(emulatorActions.loadCommandTypesSuccess(data))
    }
  } catch (e) {
    yield put(emulatorActions.loadCommandTypesFailed(e.message))
  }
}

function* sendCommands(action) {
  try {
    const { body, deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.post(`/api/secured/commands/${deviceId}`, {
        body,
      })
    })

    if (data.message) {
      yield put(emulatorActions.sendCommandsFailed(data.message))
    } else {
      yield put(emulatorActions.sendCommandsSucceeded(body))

      yield put(emulatorActions.getCommands(deviceId))
    }
  } catch (e) {
    yield put(emulatorActions.sendCommandsFailed(e.message))
  }
}

function* getCommands(action) {
  try {
    const { deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/commands/${deviceId}`)
    })

    if (data.message) {
      yield put(emulatorActions.getCommandsFailed(data.message))
    } else {
      yield put(emulatorActions.getCommandsSucceeded(data))
    }
  } catch (e) {
    yield put(emulatorActions.getCommandsFailed(e.message))
  }
}

function* applyCommands(action) {
  try {
    const { body, deviceImei, deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.post(
        `/api/secured/emulator/commands/report/${deviceImei}`,
        {
          body,
        }
      )
    })

    if (data.message) {
      yield put(emulatorActions.applyCommandsFailed(data.message))
    } else {
      yield put(emulatorActions.applyCommandsSucceeded(body, deviceId))
    }
  } catch (e) {
    yield put(emulatorActions.applyCommandsFailed(e.message))
  }
}

function* loadOrganizationsSaga() {
  yield takeEvery(
    emulatorActions.actionTypes.LOAD_ORGANIZATIONS,
    loadOrganizations
  )
}

function* getDevicePortalURLSaga() {
  yield takeLatest(
    emulatorActions.actionTypes.GET_DEVICE_PORTAL_URL,
    getDevicePortalURL
  )
}

function* loadUVGIDevicesSaga() {
  yield takeLatest(
    emulatorActions.actionTypes.LOAD_UVGI_DEVICES,
    loadUVGIDevices
  )
}

function* loadCommandNamesSaga() {
  yield takeLatest(
    emulatorActions.actionTypes.LOAD_UVGI_DEVICES,
    loadCommandNames
  )
}

function* loadCommandTypesSaga() {
  yield takeLatest(
    emulatorActions.actionTypes.LOAD_UVGI_DEVICES,
    loadCommandTypes
  )
}

function* sendCommandsSaga() {
  yield takeLatest(emulatorActions.actionTypes.SEND_COMMANDS, sendCommands)
}

function* getCommandsSaga() {
  yield all([
    yield takeLatest(emulatorActions.actionTypes.GET_COMMANDS, getCommands),
    yield takeLatest(
      emulatorActions.actionTypes.APPLY_COMMANDS_SUCCEEDED,
      getCommands
    ),
  ])
}

function* applyCommandsSaga() {
  yield takeLatest(emulatorActions.actionTypes.APPLY_COMMANDS, applyCommands)
}

export default function* deviceSagas() {
  yield fork(getDevicePortalURLSaga)
  yield fork(loadUVGIDevicesSaga)
  yield fork(loadCommandNamesSaga)
  yield fork(loadCommandTypesSaga)
  yield fork(sendCommandsSaga)
  yield fork(applyCommandsSaga)
  yield fork(getCommandsSaga)
  yield fork(loadOrganizationsSaga)
}
