import { useDispatch } from 'react-redux'
import {
  BULK_UPLOAD_LABELS,
  getBulkLoadMessage,
} from '../../../../../../constants'
import {
  BULK_UPLOAD_LOCATIONS,
  GET_BULK_TEMPLATE,
} from '../../services/organizationDetails-actions'
import { useStyles } from './style'

import { Snackbar } from '@material-ui/core'
import { useState } from 'react'
import { useAppSelector } from 'utils/reduxHelpers'
import BulkUploadButtonAndModal from '../../../../../../components/_shared/bulkUpload/BulkUploadButtonAndModal'
import { checkConnection } from '../../../../../../services/root/root-service'

const LocationsFilter = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const bulkLoadFailed = useAppSelector(
    (state) => state.organizationDetailsReducer.bulkLoadFailed
  )
  const bulkLoadTotal = useAppSelector(
    (state) => state.organizationDetailsReducer.bulkLoadTotal
  )
  const bulkLoadError = useAppSelector(
    (state) => state.organizationDetailsReducer.bulkLoadError
  )

  const bulkUploadLocations = (body: any, onSuccess: () => void) =>
    dispatch(checkConnection(() => BULK_UPLOAD_LOCATIONS(body, onSuccess)))
  const getBulkTemplate = () =>
    dispatch(checkConnection(() => GET_BULK_TEMPLATE()))

  const bulkLoadMessage = getBulkLoadMessage(bulkLoadFailed, bulkLoadTotal)

  const onCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const onOpenSnackBar = () => {
    setOpenSnackbar(true)
  }

  return (
    <div className={styles.filterWrapper}>
      <BulkUploadButtonAndModal
        isReadOnly={false}
        onOpenSnackBar={onOpenSnackBar}
        error={bulkLoadError}
        bulkUpload={bulkUploadLocations}
        resetBulkUploadErrors={() => {}}
        downloadCsvExample={getBulkTemplate}
        title={BULK_UPLOAD_LABELS.bulkUploadLocations}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={onCloseSnackbar}
        message={bulkLoadMessage}
      />
    </div>
  )
}

export default LocationsFilter
