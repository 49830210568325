import { useEffect, useState } from 'react'
import {
  FIRMWARE_UPDATE_HEADER,
  initialState,
} from './services/firmwareUpdate-constants'
import {
  filterInitialState,
  prepareFilterQuery,
} from './services/firmwareUpdate-service'

import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import ErrorHasOccurred from '../../components/_shared/errors/ErrorHasOccurred'
import NetworkError from '../../components/_shared/errors/NetworkError'
import { checkConnection } from '../../services/root/root-service'
import CancelFirmwareButton from './components/cancelFirmwareUpdate/CancelFirmwareUpdateButton'
import DevicesFilter from './components/deviceFilter/DevicesFilter'
import UpdateFirmwareTable from './components/infoForUpdate/UpdateFirmwareTable'
import ScheduleFirmwareUpdate from './components/scheduleFirmwareUpdate/ScheduleFirmwareUpdate'
import actions from './services/firmwareUpdate-actions'
import styles from './style'

const FirmwareUpdateSchedulePage = (props) => {
  const {
    loadFirmwareTypes,
    resetTableState,
    applyFilter,
    loadDevices,
    devices,
    loadDevicesError,
    networkError,
    isReadOnly,
    error,
    classes,
  } = props
  const [state, setState] = useState(initialState)
  const [filterState, setFilterState] = useState(filterInitialState)

  useEffect(() => {
    loadFirmwareTypes()

    return () => {
      resetTableState()
    }
    // eslint-disable-next-line
  }, [])

  const changeSelected = (selected) => {
    setState((prevState) => ({ ...prevState, selected }))
  }

  const updateFirmwareType = (type) => {
    setState({ ...state, firmwareType: type })

    const query = prepareFilterQuery({
      ...filterState,
      firmwareType: type,
    })

    changeSelected([])
    applyFilter()
    loadDevices(query)
  }

  const updateFirmwareVersion = (firmwareVersion) => {
    setState((prevState) => ({ ...prevState, firmwareVersion }))
  }

  const resetState = () => {
    setState(initialState)
  }

  return (
    <>
      <Box textAlign="center" className={classes.header}>
        <Typography variant="h2">{FIRMWARE_UPDATE_HEADER}</Typography>
      </Box>
      {networkError ? (
        <NetworkError />
      ) : (
        <>
          <DevicesFilter
            updateFirmwareType={updateFirmwareType}
            setSelected={changeSelected}
            firmwareType={state.firmwareType}
            resetState={resetState}
            filterState={filterState}
            setFilterState={setFilterState}
            loadDevices={loadDevices}
            applyFilter={applyFilter}
          />
          {loadDevicesError ? (
            <ErrorHasOccurred hideLogo />
          ) : (
            <>
              {!isReadOnly && (
                <>
                  <ScheduleFirmwareUpdate
                    updateFirmwareType={updateFirmwareType}
                    devices={devices}
                    selected={state.selected}
                    setSelected={changeSelected}
                    firmwareType={state.firmwareType}
                    updateFirmwareVersion={updateFirmwareVersion}
                    firmwareVersion={state.firmwareVersion}
                  />
                  <CancelFirmwareButton
                    selected={state.selected}
                    error={error}
                    setSelected={changeSelected}
                    devices={devices}
                  />
                </>
              )}
              <UpdateFirmwareTable
                devices={devices}
                setSelected={changeSelected}
                selected={state.selected}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadFirmwareTypes: () =>
      dispatch(checkConnection(() => actions.loadFirmwareTypes())),
    resetTableState: () => dispatch(actions.resetTableState()),
    applyFilter: () => dispatch(actions.applyDeviceFilter()),
    loadDevices: (query) =>
      dispatch(checkConnection(() => actions.loadDevices(query))),
  }
}

const mapStateToProps = (state) => ({
  devices: state.firmwareUpdateReducer.devices,
  error: state.firmwareUpdateReducer.error,
  loadDevicesError: state.firmwareUpdateReducer.loadDevicesError,
  networkError: state.rootReducer.networkError,
  isReadOnly: state.firmwareUpdateReducer.isReadOnly,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FirmwareUpdateSchedulePage))
