import { InputLabel, MenuItem, Select } from '@material-ui/core'

import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import map from 'lodash/map'
import { ADD_DEVICE_LABELS } from '../../services/deviceDetails-constants'
import style from './style'

const SubTypesSelect = (props) => {
  const {
    deviceSubType,
    error,
    subTypesForDevice,
    onChange,
    name = 'deviceSubType',
    label = ADD_DEVICE_LABELS.deviceSubType,
    classes,
  } = props

  return (
    <FormControl variant="outlined" error={error} fullWidth required>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        label={label}
        value={deviceSubType || ''}
        onChange={onChange}
        className={classes.select}
        required
      >
        {map(subTypesForDevice, (item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default withStyles(style)(SubTypesSelect)
