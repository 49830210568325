import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import map from 'lodash/map'
import uniqueId from 'lodash/uniqueId'
import { MOTION_PIR_TABLE } from '../../services/uvgiEmulator-constants'
import styles from './style'

const MotionPIRTableComponent = withStyles(styles)((props) => {
  const { classes, description, alertStatus, changeValue } = props

  const handleDescriptionChange = (value, ind) => {
    description[ind] = value

    changeValue(description, 'motionPIRDescription')
  }

  const handleAlertStatusChange = (value, ind) => {
    alertStatus[ind] = value

    changeValue(alertStatus, 'motionPIRAlertStatus')
  }

  const getTableRow = (sensor, ind) => (
    <TableRow key={'row_' + ind}>
      <TableCell>{sensor}</TableCell>
      <TableCell>
        <Select
          value={description[ind]}
          classes={{
            root: classes.selectCell,
          }}
          onChange={(e) => handleDescriptionChange(e.target.value, ind)}
        >
          {map(MOTION_PIR_TABLE.eventDescription.options, (option) => (
            <MenuItem value={option} key={uniqueId()}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <Select
          value={alertStatus[ind]}
          classes={{
            root: classes.selectCell,
          }}
          onChange={(e) => handleAlertStatusChange(e.target.value, ind)}
        >
          {map(MOTION_PIR_TABLE.alertStatus.options, (option) => (
            <MenuItem value={option.value} key={uniqueId()}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
    </TableRow>
  )

  return (
    <Table size="small" className={classes.tableWrapper}>
      <TableHead>
        <TableRow>
          {map(Object.keys(MOTION_PIR_TABLE), (key) => (
            <TableCell classes={{ root: classes.headerCell }} key={uniqueId()}>
              {MOTION_PIR_TABLE[key].label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {map(MOTION_PIR_TABLE.sensor.options, (option, ind) =>
          getTableRow(option, ind)
        )}
      </TableBody>
    </Table>
  )
})

export default MotionPIRTableComponent
