import { useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import DashboardButtonsContainer from './buttons/DashboardButtonsContainer'
import actions from './services/dashboard-actions'
import styles from './style'

const DashboardPage = (props) => {
  const { history, loadMainPageValues, classes } = props

  useEffect(() => {
    loadMainPageValues()
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.dashboardPage}>
      <DashboardButtonsContainer history={history} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  loadMainPageValues: () => dispatch(actions.loadMainPageValues()),
})

const mapStateToProps = (state) => ({
  isLoading: state.dashboardReducer.isLoading,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DashboardPage))
