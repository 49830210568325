const featureTogglingActions = {}

featureTogglingActions.actionTypes = {
  GET_FEATURES: '{FEATURE TOGGLING} Get Features',
  GET_FEATURES_SUCCEEDED: '{FEATURE TOGGLING} Get Features Succeeded',
  GET_FEATURES_FAILED: '{FEATURE TOGGLING} Get Features Failed',

  UPDATE_FEATURE_STATE: '{FEATURE TOGGLING} Update Feature State',
  UPDATE_FEATURE_STATE_SUCCEEDED:
    '{FEATURE TOGGLING} Update Feature State Succeeded',
  UPDATE_FEATURE_STATE_FAILED: '{FEATURE TOGGLING} Update Feature State Failed',

  RESET_ERROR: '{FEATURE TOGGLING} Reset Error',
}

featureTogglingActions.resetError = () => {
  return {
    type: featureTogglingActions.actionTypes.RESET_ERROR,
  }
}

featureTogglingActions.getFeatures = () => {
  return {
    type: featureTogglingActions.actionTypes.GET_FEATURES,
  }
}

featureTogglingActions.getFeaturesSuccess = (features) => {
  return {
    type: featureTogglingActions.actionTypes.GET_FEATURES_SUCCEEDED,
    payload: {
      features,
    },
  }
}

featureTogglingActions.getFeaturesFailed = (message) => {
  return {
    type: featureTogglingActions.actionTypes.GET_FEATURES_FAILED,
    payload: {
      message,
    },
  }
}

featureTogglingActions.updateFeatureState = (id, switchState) => {
  return {
    type: featureTogglingActions.actionTypes.UPDATE_FEATURE_STATE,
    payload: {
      id,
      switchState,
    },
  }
}

featureTogglingActions.updateFeatureStateSuccess = (id, switchState) => {
  return {
    type: featureTogglingActions.actionTypes.UPDATE_FEATURE_STATE_SUCCEEDED,
    payload: {
      id,
      switchState,
    },
  }
}

featureTogglingActions.updateFeatureStateFailed = (message) => {
  return {
    type: featureTogglingActions.actionTypes.UPDATE_FEATURE_STATE_FAILED,
    payload: {
      message,
    },
  }
}

export default featureTogglingActions
