import forIn from 'lodash/forIn'
import { TIME_FORMAT } from './constants'

const filterQuery = {}

const _paramsTemplate = (key, filterType, value) =>
  `${key} ${filterType} '${value}'`
const _arrayParamsTemplate = (key, filterType, value) =>
  `${key} ${filterType} ${value}`

const _wrapInParentheses = (text) => {
  return '(' + text + ')'
}

const _wrapInQuotes = (text) => {
  return "'" + text + "'"
}

const _wrapInSquareBrackets = (text) => {
  return encodeURIComponent('[') + text + encodeURIComponent(']')
}

// type = 'start' || 'end' (start or end of date)
const _formatDate = (date, type) => {
  if (type === 'start') {
    return date.startOf('day').format(TIME_FORMAT)
  } else {
    return date.endOf('day').format(TIME_FORMAT)
  }
}

filterQuery.getDatePeriodString = (start, end, template, key, filterType) => {
  const startDate = _wrapInQuotes(_formatDate(start, 'start'))
  const endDate = _wrapInQuotes(_formatDate(end, 'end'))
  const periodString = startDate + ',' + endDate

  if (template) {
    return template(key, filterType, _wrapInSquareBrackets(periodString))
  }

  return _wrapInSquareBrackets(periodString)
}

filterQuery.getArrayString = (array, template, key, filterType) => {
  const arrayString = array.join(',')

  if (template) {
    return template(key, filterType, _wrapInSquareBrackets(arrayString))
  }

  return _wrapInSquareBrackets(arrayString)
}

filterQuery.getStringFromObj = (
  obj,
  template,
  filterType,
  condition,
  otherTemplate
) => {
  const filters = []

  forIn(obj, (value, key) => {
    if (value && value.length) {
      filters.push(
        template(key, filterType, _wrapInSquareBrackets(value.join(',')))
      )
    }
  })

  if (otherTemplate) {
    filters.push(otherTemplate)
  }

  return _wrapInParentheses(filters.join(' ' + condition + ' '))
}

filterQuery.getQueryString = (value, template, key, filterType) => {
  if (template) {
    return template(key, filterType, value)
  }

  return _wrapInParentheses(value)
}

filterQuery.getArrayStringInQuotes = (
  array,
  template,
  key,
  filterType,
  queryInBody = false
) => {
  const preparedArray = []

  forIn(array, function (item) {
    if (item) {
      preparedArray.push("'" + item + "'")
    }
  })

  return filterQuery.getArrayString(
    preparedArray,
    template,
    key,
    filterType,
    queryInBody
  )
}

filterQuery.createQueryStringFromArray = (filtersArray, filterCondition) => {
  filtersArray = filtersArray || []

  const queryString = filtersArray.join(' ' + filterCondition + ' ')

  return queryString && _wrapInParentheses(queryString)
}

filterQuery.createQueryString = (mainFilter, otherFilters, filterCondition) => {
  mainFilter = mainFilter || []
  otherFilters = otherFilters || []
  filterCondition = filterCondition || 'or'

  let queryString

  if (mainFilter && otherFilters) {
    queryString = _wrapInParentheses(
      mainFilter + ' ' + filterCondition + ' ' + otherFilters
    )
  } else {
    queryString = mainFilter || otherFilters
  }

  return queryString
}

filterQuery.templates = {
  default: _paramsTemplate,
  array: _arrayParamsTemplate,
}

export default filterQuery
