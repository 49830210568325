import eventsEmulatorActions from './emulator-actions'
import { FEATURE_KEYS } from './emulator-constants'

export const sharedEmulatorReducer = (
  state = {
    tab: FEATURE_KEYS.arc,
    isLoading: true,
    organizationsList: [],
    selectedOrganization: null,
    error: '',
  },
  action
) => {
  switch (action.type) {
    case eventsEmulatorActions.actionTypes.SET_TAB:
      const { tab } = action.payload

      return {
        ...state,
        tab,
      }
    case eventsEmulatorActions.actionTypes.GET_ORGANIZATIONS:
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    case eventsEmulatorActions.actionTypes.GET_ORGANIZATIONS_SUCCEEDED:
      const { organizationsList } = action.payload

      return {
        ...state,
        organizationsList,
        isLoading: false,
        error: '',
      }
    case eventsEmulatorActions.actionTypes.GET_ORGANIZATIONS_FAILED:
      const { message } = action.payload

      return {
        ...state,
        error: message,
        isLoading: false,
      }
    case eventsEmulatorActions.actionTypes.SET_ORGANIZATION:
      const { selectedOrganization } = action.payload

      return {
        ...state,
        selectedOrganization,
      }
    default:
      return state
  }
}
