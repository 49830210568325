import { useCallback, useState } from 'react'

import { Button } from '@material-ui/core'
import DownloadIcon from '@mui/icons-material/Download'
import { useDispatch } from 'react-redux'
import CardWithDescription from '../../../../../../../components/_shared/CardWithDescription/CardWithDescription'
import { DOWNLOAD_WIFI_CREDENTIALS } from '../../../services/organizationDetails-actions'
import { WIFI } from '../../../services/organizationDetails-constants'
import { WifiCredential } from '../../../services/organizationDetails-types'
import AddCredentialModal from '../AddCredential/AddCredentialModal'
import DeleteWiFiCredentialButton from '../DeleteCredential/DeleteCredential'
import { useStyles } from './style'

type WifiCredentialCardProps = {
  organizationId: string
  credential: WifiCredential
  isReadOnly: boolean
}

const WiFiCredentialCard = ({
  organizationId,
  credential,
  isReadOnly,
}: WifiCredentialCardProps) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)

  const onDownload = (credentialId: string, filename: string) =>
    dispatch(DOWNLOAD_WIFI_CREDENTIALS(credentialId, filename))

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDownload = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    onDownload(credential.id!, `${credential.configurationLabel}.gbl`)
  }, [credential, onDownload])

  return (
    <>
      <div
        onClick={isReadOnly ? undefined : handleOpen}
        style={{ cursor: isReadOnly ? 'unset' : 'pointer' }}
      >
        <CardWithDescription
          title={credential.ssid}
          description={credential.configurationLabel}
          actionComponent={
            <>
              <Button
                variant="text"
                color="primary"
                className={styles.wifiActionButton}
                onClick={handleDownload}
                startIcon={<DownloadIcon />}
              >
                .gbl
              </Button>
              {credential.editable && !isReadOnly && (
                <DeleteWiFiCredentialButton
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  id={credential.id!}
                  ssid={credential.ssid}
                />
              )}
            </>
          }
        />
      </div>
      {credential.editable && (
        <AddCredentialModal
          isOpen={open}
          currentCredential={credential}
          onClose={handleClose}
          organizationId={organizationId}
          mode={WIFI.edit}
        />
      )}
    </>
  )
}

export default WiFiCredentialCard
