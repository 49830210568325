import './App.css'

import { useCallback, useEffect, useState } from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import HeaderContainer from './components/_shared/header/HeaderComponent'
import LogIn from './containers/Authorization/components/login/LogIn'
import ResetPassword from './containers/Authorization/components/resetPassword/ResetPassword'
import authorizationActions from './containers/Authorization/services/authorization-actions'
import DashboardPage from './containers/Dashboard/DashboardPage'
import DeviceDetails from './containers/Devices/components/Details/components/DeviceDetails'
import EditDevice from './containers/Devices/components/Details/components/editDevice/EditDevice'
import Devices from './containers/Devices/Devices'
import Emulator from './containers/Emulator/Emulator'
import EventsEmulator from './containers/EventsEmulator/EventsEmulatorContainer'
import FailedDevices from './containers/FailedDevices/FailedDevices'
import FeatureToggling from './containers/FeatureToggling/FeatureToggling'
import CreateFirmware from './containers/Firmwares/components/createFirmware/CreateFirmware'
import FirmwaresTableContainer from './containers/Firmwares/components/firmwaresTable/FirmwaresTableContainer'
import Firmwares from './containers/Firmwares/Firmwares'
import FirmwareUpdateSchedule from './containers/FirmwareUpdateSchedule/FirmwareUpdateSchedule'
import AddOrganization from './containers/Organizations/components/addOrganization/AddOrganization'
import ChangeAccountOwner from './containers/Organizations/components/changeAccountOwner/ChangeAccountOwner'
import OrganizationDetails from './containers/Organizations/components/Details/OrganizationDetails'
import Organizations from './containers/Organizations/Organizations'
import UserAdd from './containers/UserAdd/UserAdd'
import Users from './containers/Users/Users'
import { HttpClient } from './services/HttpClient'
import actions from './services/root/root-actions'
import { checkConnection } from './services/root/root-service'
import styles from './style'

const App = (props) => {
  const [isLogged, setIsLogged] = useState(HttpClient.tp.isLoggedIn())
  const { classes, logout, isRolesLoaded, getUserGroups } = props

  const listener = useCallback(
    (newIsLogged) => {
      setIsLogged(newIsLogged)
    },
    [setIsLogged]
  )

  useEffect(() => {
    HttpClient.setUnauthorizedHandler(() => {
      logout()
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isRolesLoaded && isLogged) {
      getUserGroups()
    }
    // eslint-disable-next-line
  }, [isRolesLoaded, isLogged])

  useEffect(() => {
    HttpClient.tp.subscribe(listener)

    return () => {
      HttpClient.tp.unsubscribe(listener)
    }
  }, [listener])

  return (
    <HashRouter>
      {isLogged && <HeaderContainer />}
      {isLogged && isRolesLoaded && (
        <div className={classes.pageContainer}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route
              path="/dashboard"
              component={(props) => <DashboardPage {...props} />}
            />
            <Route
              exact
              path="/organizations"
              component={(props) => <Organizations {...props} />}
            />
            <Route
              path="/organizations/changeOwner/:id"
              component={(props) => <ChangeAccountOwner {...props} />}
            />
            <Route
              path="/organizations/add"
              component={(props) => <AddOrganization {...props} />}
            />
            <Route
              path="/organizations/details/:id"
              component={(props) => <OrganizationDetails {...props} />}
            />
            <Route
              path="/device/sendCommand"
              component={(props) => <Emulator {...props} />}
            />
            <Route
              path="/device/sendMessage"
              component={(props) => <EventsEmulator {...props} />}
            />
            <Route
              path="/defaultFirmwares"
              exact
              component={(props) => <Firmwares {...props} />}
            />
            <Route
              path="/firmwares"
              exact
              component={(props) => <FirmwaresTableContainer {...props} />}
            />
            <Route
              path="/firmwares/createFirmware"
              component={(props) => <CreateFirmware {...props} />}
            />
            <Route
              exact
              path="/devices"
              component={(props) => <Devices {...props} />}
            />
            <Route
              exact
              path="/devices/bulk/fails"
              component={(props) => <FailedDevices {...props} />}
            />
            <Route
              path="/devices/details/:imei/:serialNumber/:id"
              component={(props) => <DeviceDetails {...props} />}
            />
            <Route
              path="/devices/edit/:deviceType/:id"
              component={(props) => <EditDevice {...props} />}
            />
            <Route
              path="/firmware-update/schedule"
              component={(props) => <FirmwareUpdateSchedule {...props} />}
            />
            <Route
              path="/users/add"
              component={(props) => <UserAdd {...props} />}
            />
            <Route path="/users" component={(props) => <Users {...props} />} />
            <Route
              path="/feature/toggling"
              component={(props) => <FeatureToggling {...props} />}
            />
            <Redirect to="/dashboard" push />
          </Switch>
        </div>
      )}
      {!isLogged && (
        <div className={classes.authWrapper}>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route path="/login" component={(props) => <LogIn {...props} />} />
            <Route
              exact
              path="/updatePassword"
              component={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/resetPassword"
              component={(props) => <ResetPassword {...props} />}
            />
            <Redirect to="/login" push />
          </Switch>
        </div>
      )}
    </HashRouter>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authorizationActions.logout()),
    getUserGroups: () => dispatch(checkConnection(actions.getUserGroups)),
  }
}

const mapStateToProps = (state) => ({
  isRolesLoaded: state.rootReducer.isRolesLoaded,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(App))
