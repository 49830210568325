import {
  MAX_NAME_LENGTH,
  PROPERTY_NAME,
  WIFI_FIELDS,
} from './organizationDetails-constants'

import compact from 'lodash/compact'
import differenceBy from 'lodash/differenceBy'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import join from 'lodash/join'
import map from 'lodash/map'
import split from 'lodash/split'
import trim from 'lodash/trim'
import uniqBy from 'lodash/uniqBy'
import filterQuery from '../../../../../services/filterQuery-service'

const validateWiFiCredentials = (state) => {
  const errors = {}

  forEach(WIFI_FIELDS, (_, key) => {
    errors[key] = !trim(state[key])
  })

  return errors
}

const updateWiFiCredentialsList = (credentialsList, entry) => {
  const updatedCredentialsList = [...credentialsList]

  const credentialIndex = findIndex(updatedCredentialsList, ['id', entry.id])

  if (credentialIndex !== -1) {
    updatedCredentialsList[credentialIndex] = { ...entry }
  } else {
    updatedCredentialsList.push({ ...entry })
  }

  return updatedCredentialsList
}

const deleteCredential = (credentialsList, id) =>
  filter(credentialsList, (item) => item.id !== id)

const queryForGetLocationsList = (
  organizationId,
  locationType,
  parentId,
  organizationName
) => {
  const queryParams = []

  queryParams.push(
    filterQuery.getQueryString(
      organizationId,
      filterQuery.templates.array,
      'organizationId',
      'eq'
    )
  )

  queryParams.push(
    filterQuery.getQueryString(
      false,
      filterQuery.templates.array,
      'removed',
      'eq'
    )
  )

  if (parentId) {
    queryParams.push(
      filterQuery.getQueryString(
        parentId,
        filterQuery.templates.array,
        'parentId',
        'eq'
      )
    )
  } else {
    queryParams.push(
      filterQuery.getQueryString(
        'isNull "',
        filterQuery.templates.array,
        'parentId',
        ''
      )
    )
  }

  if (organizationName) {
    queryParams.push(
      filterQuery.getQueryString(
        organizationName,
        filterQuery.templates.default,
        'name',
        'eq'
      )
    )
  }

  return filterQuery.createQueryStringFromArray(queryParams, 'and')
}

const validateLocationName = (name, isBulk = false) => {
  if (name.match(PROPERTY_NAME.notAllowedSymbols)) {
    return PROPERTY_NAME.helperText
  }

  if (isBulk) {
    const splitted = split(name, '\n')
    const index = findIndex(splitted, (item) => item.length > MAX_NAME_LENGTH)

    return index === -1 ? '' : splitted[index] + PROPERTY_NAME.lengthExceeds
  } else if (name.length > MAX_NAME_LENGTH) {
    return PROPERTY_NAME.lengthExceeds
  }

  return ''
}

const getLocationsFromString = (text) => compact(split(text, '\n'))

const checkNameCase = (text) => {
  const splattedArrays = getLocationsFromString(text)
  const uniqArr = uniqBy(splattedArrays, (item) => item.toLowerCase())

  if (uniqArr.length === splattedArrays.length) {
    return []
  }

  return differenceBy(splattedArrays, uniqArr)
}

const getWarningText = (locations, isMultiple) =>
  isMultiple
    ? `Locations named "${locations}" were found. Do you wish to proceed?`
    : `A location with the name "${locations}" was found. Do you wish to proceed?`

const getLocationsNames = (locations) => {
  const names = map(locations, (item) => item.name)

  return getWarningText(join(names, ','), !!names.length)
}

const getWarningUIValidationText = (locations) => {
  const locationString = join(locations, ',')

  return `You are trying to create the following locations "${locationString}" with the same name but different capitalization.`
}

const prepareBulkLocations = (body) => {
  const { name, parentId, locationType } = body
  const splattedArrays = compact(split(name, '\n'))

  return map(splattedArrays, (item) => {
    return {
      parentId,
      locationType,
      name: trim(item),
    }
  })
}

export {
  deleteCredential,
  updateWiFiCredentialsList,
  validateWiFiCredentials,
  queryForGetLocationsList,
  validateLocationName,
  checkNameCase,
  getLocationsNames,
  getWarningUIValidationText,
  prepareBulkLocations,
}
