import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  helperTextInvisible: {
    visibility: 'hidden',
  },
  description: {
    color: COLORS.GRAY,
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '16px',
    marginTop: '10px',
  },
  resetPasswordForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '30px',
  },
  resetPasswordInput: {
    position: 'relative',
    marginBottom: 0,
    width: '500px',
  },
  resetPasswordLogo: {
    display: 'block',
    width: '250px',
    margin: '0 auto 30px',
  },
  stepLabel: {
    fontFamily: 'Cerebri Sans Pro Semi Bold',
    fontSize: '16px',
  },
  resetPasswordLabel: {
    fontFamily: 'Cerebri Sans Pro Semi Bold',
    fontSize: '14px',
    color: COLORS.GRAY,
    marginBottom: '10px',
  },
  rules: {
    color: COLORS.GRAY,
    fontSize: '14px',
    marginBottom: '25px',
  },
  rule: {
    paddingLeft: '20px',
  },
  rulesTitle: {
    fontFamily: 'Cerebri Sans Pro Semi Bold',
    marginBottom: '5px',
  },
  resetPasswordButton: {
    marginTop: '15px',
    width: '500px',
  },
  ruleError: {
    color: COLORS.RED,
  },
  '@media (max-width: 580px)': {
    resetPasswordInput: {
      width: '100%',
    },
    resetPasswordButton: {
      width: '100%',
    },
  },
})

export default styles
