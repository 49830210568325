import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  actionButtons: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  fieldsContainer: {
    minHeight: 'inherit',
    justifyContent: 'center',
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    '& .MuiGrid-root': {
      minHeight: 'inherit',
    },
  },
  phoneAndExtension: {
    display: 'flex',
    '& .MuiFormControl-root:nth-child(1)': {
      width: '70%',
      '& .MuiInputBase-root': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    '& .MuiFormControl-root:nth-child(2)': {
      width: '30%',
      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeftWidth: 0,
        '& fieldset': {
          borderLeftWidth: 0,
        },
        '&.Mui-focused, &:hover': {
          '& fieldset': {
            borderLeftWidth: 1,
          },
        },
      },
    },
  },
})
