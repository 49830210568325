const arcEmulatorActions = {}

arcEmulatorActions.actionTypes = {
  SEND_DEVICE_MESSAGE: '{ARC EVENTS EMULATOR} Send Device Message',
  SEND_DEVICE_MESSAGE_SUCCEEDED:
    '{ARC EVENTS EMULATOR} Send Device Message Succeeded',
  SEND_DEVICE_MESSAGE_FAILED:
    '{ARC EVENTS EMULATOR} Send Device Message Failed',

  LOAD_ALL_MESSAGE_LOCATIONS:
    '{ARC EVENTS EMULATOR} Load All Message Locations',
  LOAD_ALL_MESSAGE_LOCATIONS_SUCCEEDED:
    '{ARC EVENTS EMULATOR} Load All Message Locations Succeeded',
  LOAD_MESSAGE_LOCATIONS: '{ARC EVENTS EMULATOR} Load Message Locations',
  LOAD_MESSAGE_LOCATIONS_SUCCEEDED:
    '{ARC EVENTS EMULATOR} Load Message Locations Succeeded',
  LOAD_MESSAGE_LOCATIONS_FAILED:
    '{ARC EVENTS EMULATOR} Load Message Locations Failed',

  LOAD_MESSAGE_DEVICES: '{ARC EVENTS EMULATOR} Load Message Devices',
  LOAD_MESSAGE_DEVICES_SUCCEEDED:
    '{ARC EVENTS EMULATOR} Load Message Devices Succeeded',
  LOAD_MESSAGE_DEVICES_FAILED:
    '{ARC EVENTS EMULATOR} Load Message Devices Failed',
}

//device message actions (for BE team)

arcEmulatorActions.sendDeviceMessage = function (params, body) {
  return {
    type: arcEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE,
    payload: {
      params,
      body,
    },
  }
}

arcEmulatorActions.sendDeviceMessageSuccess = function (message) {
  return {
    type: arcEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_SUCCEEDED,
    payload: {
      message,
    },
  }
}

arcEmulatorActions.sendDeviceMessageFailed = function (message) {
  return {
    type: arcEmulatorActions.actionTypes.SEND_DEVICE_MESSAGE_FAILED,
    payload: {
      message,
    },
  }
}

arcEmulatorActions.loadMessageLocations = function (serialNumber) {
  return {
    type: arcEmulatorActions.actionTypes.LOAD_MESSAGE_LOCATIONS,
    payload: {
      serialNumber,
    },
  }
}

arcEmulatorActions.loadAllLocations = function () {
  return {
    type: arcEmulatorActions.actionTypes.LOAD_ALL_MESSAGE_LOCATIONS,
  }
}

arcEmulatorActions.loadAllMessageLocationsSuccess = function (locations) {
  return {
    type: arcEmulatorActions.actionTypes.LOAD_ALL_MESSAGE_LOCATIONS_SUCCEEDED,
    payload: {
      locations,
    },
  }
}

arcEmulatorActions.loadMessageLocationsSuccess = function (locations) {
  return {
    type: arcEmulatorActions.actionTypes.LOAD_MESSAGE_LOCATIONS_SUCCEEDED,
    payload: {
      locations,
    },
  }
}

arcEmulatorActions.loadMessageLocationsFailed = function (message) {
  return {
    type: arcEmulatorActions.actionTypes.LOAD_MESSAGE_LOCATIONS_FAILED,
    payload: {
      message,
    },
  }
}

arcEmulatorActions.loadMessageDevices = function () {
  return {
    type: arcEmulatorActions.actionTypes.LOAD_MESSAGE_DEVICES,
  }
}

arcEmulatorActions.loadMessageDevicesSuccess = function (devices) {
  return {
    type: arcEmulatorActions.actionTypes.LOAD_MESSAGE_DEVICES_SUCCEEDED,
    payload: {
      devices,
    },
  }
}

arcEmulatorActions.loadMessageDevicesFailed = function (message) {
  return {
    type: arcEmulatorActions.actionTypes.LOAD_MESSAGE_DEVICES_FAILED,
    payload: {
      message,
    },
  }
}

export default arcEmulatorActions
