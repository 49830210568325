import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import some from 'lodash/some'
import { USER_ROLES } from '../../../services/constants'
import filterQuery from './../../../services/filterQuery-service'
import { IDP_FIELDS } from './organization-constants'

const prepareOrganizations = (organizations) => {
  forEach(organizations, (organization, i) => {
    organization.selected = i === 0
  })

  return organizations
}

const moveSelectedOrganization = (organizations, type) => {
  const selectedIndex = findIndex(organizations, (item) => item.selected)

  if (organizations && organizations.length) {
    if (type === 'up') {
      if (selectedIndex > 0) {
        organizations[selectedIndex - 1].selected = true
        organizations[selectedIndex].selected = false
      }
    } else {
      if (selectedIndex < organizations.length - 1) {
        organizations[selectedIndex + 1].selected = true
        organizations[selectedIndex].selected = false
      }
    }
  }

  return organizations
}

const prepareAccountOwnerQuery = (orgId) => {
  const authoritiesArray = []
  authoritiesArray.push("'" + USER_ROLES.accountOwnerRole + "'")
  const roleQuery = filterQuery.getArrayString(
    authoritiesArray,
    filterQuery.templates.array,
    'roleId',
    'in'
  )
  const orgIdQuery = filterQuery.getQueryString(
    orgId,
    filterQuery.templates.array,
    'organizationId',
    'eq'
  )

  const fullQuery = filterQuery.createQueryString(roleQuery, orgIdQuery, 'and')

  return `?query=${fullQuery}`
}

const prepareAdminsQuery = (orgId) => {
  const authoritiesArray = []
  const orgIdQuery = filterQuery.getQueryString(
    orgId,
    filterQuery.templates.array,
    'organizationId',
    'eq'
  )

  authoritiesArray.push("'" + USER_ROLES.adminUserRole + "'")
  authoritiesArray.push("'" + USER_ROLES.adminBillingUserRole + "'")
  const roleQuery = filterQuery.getArrayString(
    authoritiesArray,
    filterQuery.templates.array,
    'roleId',
    'in'
  )

  const fullQuery = filterQuery.createQueryString(roleQuery, orgIdQuery, 'and')

  return `?query=${fullQuery}`
}

const getConfirmText = (name) => {
  return `Please confirm that you are transitioning account ownership to ${name}`
}

const _accountOwnerTemplate = (item) => `${item.firstName} ${item.lastName}`

const getUserName = (owners, id) => {
  let name = ''

  forEach(owners, (item) => {
    if (item.id === id) {
      name = _accountOwnerTemplate(item)
    }
  })

  return name
}

const getIdpErrors = (data) => {
  const errors = {}

  forEach(IDP_FIELDS, (field) => {
    if (field.required) {
      errors[field.name] = !data[field.name]
    }
  })

  return errors
}

const cantBeDeleted = (data) => {
  return some(data, (item) => item === '')
}

export {
  moveSelectedOrganization,
  prepareOrganizations,
  prepareAccountOwnerQuery,
  prepareAdminsQuery,
  getConfirmText,
  _accountOwnerTemplate,
  getUserName,
  getIdpErrors,
  cantBeDeleted,
}
