import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import actions from '../../../../containers/Authorization/services/authorization-actions'
import { COLORS } from '../../../../services/constants'
import SvgIconComponent from '../../SvgIconComponent'
import styles from './../style'
import { MENU_ITEMS_LABELS } from './services/menu-constants'

const LogoutButton = withStyles(styles)((props) => {
  const { classes, logout } = props

  return (
    <Button
      onClick={logout}
      classes={{ root: classes.logoutButton }}
      startIcon={
        <SvgIconComponent name="logout" size={18} color={COLORS.BLACK} />
      }
    >
      {MENU_ITEMS_LABELS.logout}
    </Button>
  )
})

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  }
}

export default connect(null, mapDispatchToProps)(LogoutButton)
