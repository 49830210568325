import { Fab, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { createRef, useEffect, useState } from 'react'
import {
  API_DEVICE_TYPE_MAPPER,
  DEVICE_TYPE,
} from '../../../../services/constants'
import {
  CREATE_FIRMWARE_LABELS,
  FILE_ERROR,
  initialFormValue,
} from '../../services/firmwares-constants'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { connect } from 'react-redux'
import NetworkError from '../../../../components/_shared/errors/NetworkError'
import rootActions from '../../../../services/root/root-actions'
import { checkConnection } from '../../../../services/root/root-service'
import firmwaresActions from '../../services/firmwares-actions'
import { getSubType } from '../../services/firmwares-service'
import styles from '../style'

const CreateFirmwarePage = (props) => {
  const {
    history,
    uploadFirmware,
    error,
    resetError,
    devicesTypes,
    subTypesForDevice,
    firmwareTypesForDevice,
    loadDevicesTypes,
    loadSubTypesForDevice,
    loadFirmwareTypesForDevice,
    networkError,
    classes,
  } = props
  const [state, setState] = useState(initialFormValue)
  const [deviceSubType, setDeviceSubType] = useState('')
  const [errors, setErrors] = useState({})
  let myInput = createRef()

  useEffect(() => {
    loadDevicesTypes()

    return () => {
      resetError()
    }
    // eslint-disable-next-line
  }, [])

  const onChangeDeviceType = (event, child) => {
    setState({ ...state, [event.target.name]: event.target.value })
    const subType = getSubType(child)

    if (subType) {
      setDeviceSubType(subType)
      loadFirmwareTypesForDevice(event.target.value, subType)
    } else {
      setDeviceSubType('')
    }

    loadSubTypesForDevice(event.target.value)
  }

  const onChangeDeviceSubType = (event) => {
    const value = event.target.value

    setDeviceSubType(value)
    loadFirmwareTypesForDevice(state.deviceType, value)
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleCancel = () => {
    history.push('/firmwares')
  }

  const handleSelectedFile = (event) => {
    setState({
      ...state,
      file: event.target.files[0],
      selectedFileName: event.target.files[0].name,
    })
  }

  const checkValidation = () => {
    const checking = {}

    for (let [key, value] of Object.entries(state)) {
      if (!value) {
        checking[key] = true
      }
    }

    return checking
  }

  const handleSave = (event) => {
    event.preventDefault()

    const checkedErrors = checkValidation()
    setErrors(checkedErrors)

    if (isEmpty(checkedErrors)) {
      const formData = new FormData()
      let { deviceType } = state

      if (deviceType === DEVICE_TYPE.FAR_UVC.type) {
        deviceType = DEVICE_TYPE.UR_UVGI.type
      }

      formData.append('file', state.file)
      formData.append('version', state.version)
      formData.append('firmwareType ', state.firmwareType)
      formData.append('deviceType ', deviceType)
      formData.append('deviceSubType ', deviceSubType)

      uploadFirmware(formData, handleCancel)
    }
  }

  return networkError ? (
    <NetworkError />
  ) : (
    <div className={classes.createFirmware}>
      <Box textAlign="center" marginBottom="15px">
        <Typography variant="h2">
          {CREATE_FIRMWARE_LABELS.createFirmware}
        </Typography>
      </Box>
      <form
        id="myForm"
        className={classes.formWrapper}
        onSubmit={handleSave}
        noValidate
      >
        <FormControl
          variant="outlined"
          error={errors.deviceType}
          fullWidth
          required
        >
          <InputLabel>{CREATE_FIRMWARE_LABELS.deviceType}</InputLabel>
          <Select
            name="deviceType"
            label={CREATE_FIRMWARE_LABELS.deviceType}
            value={state.deviceType}
            onChange={onChangeDeviceType}
            className={classes.select}
            required
          >
            {map(
              devicesTypes.filter(
                (item) => item.value !== DEVICE_TYPE.NETWORK_DEVICE.label
              ),
              (item) => (
                <MenuItem
                  key={item.value}
                  value={API_DEVICE_TYPE_MAPPER[item.label]}
                >
                  {item.label}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        {state.deviceType && !!subTypesForDevice?.length && (
          <FormControl
            variant="outlined"
            error={errors.deviceSubType}
            fullWidth
            required
          >
            <InputLabel>{CREATE_FIRMWARE_LABELS.deviceSubType}</InputLabel>
            <Select
              name="deviceSubType"
              label={CREATE_FIRMWARE_LABELS.deviceSubType}
              disabled={state.deviceType !== DEVICE_TYPE.UVI_ARC.type}
              value={deviceSubType}
              onChange={onChangeDeviceSubType}
              className={classes.select}
              required
            >
              {map(subTypesForDevice, (item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {deviceSubType && !!firmwareTypesForDevice?.length && (
          <FormControl
            variant="outlined"
            error={errors.firmwareType}
            fullWidth
            required
          >
            <InputLabel>{CREATE_FIRMWARE_LABELS.firmwareType}</InputLabel>
            <Select
              name="firmwareType"
              label={CREATE_FIRMWARE_LABELS.firmwareType}
              value={state.firmwareType}
              onChange={handleChange}
              className={classes.select}
              required
            >
              {map(firmwareTypesForDevice, (item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          name="version"
          variant="outlined"
          value={state.version}
          error={errors.version}
          onChange={handleChange}
          label={CREATE_FIRMWARE_LABELS.version}
          required
          fullWidth
        />

        <div className={classes.upload}>
          <input
            id="myInput"
            type="file"
            ref={(ref) => (myInput = ref)}
            onChange={handleSelectedFile}
            className={classes.displayNone}
            required
          />
          <Fab
            variant="extended"
            color="default"
            onClick={(e) => myInput.click()}
          >
            <AddIcon />
            <span>{CREATE_FIRMWARE_LABELS.upload}</span>
          </Fab>
          <Typography variant="subtitle1" className={classes.fileName}>
            {state.selectedFileName}
          </Typography>
        </div>
        {errors.file && (
          <Typography
            variant="subtitle1"
            color="error"
            className={classes.error}
          >
            {FILE_ERROR}
          </Typography>
        )}

        <Typography variant="subtitle1" color="error" className={classes.error}>
          {error}
        </Typography>

        <div className={classes.actionButtons}>
          <Button variant="contained" color="primary" type="submit">
            {CREATE_FIRMWARE_LABELS.save}
          </Button>
          <Button variant="contained" color="primary" onClick={handleCancel}>
            {CREATE_FIRMWARE_LABELS.cancel}
          </Button>
        </div>
      </form>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  uploadFirmware: (body, back) =>
    dispatch(
      checkConnection(() => firmwaresActions.uploadFirmware(body, back))
    ),
  loadDevicesTypes: () =>
    dispatch(checkConnection(rootActions.loadDevicesTypes)),
  loadSubTypesForDevice: (deviceType) =>
    dispatch(
      checkConnection(() => rootActions.loadSubTypesForDevice(deviceType))
    ),
  loadFirmwareTypesForDevice: (deviceType, deviceSubType) =>
    dispatch(
      checkConnection(() =>
        firmwaresActions.loadFirmwareTypesForDevice(deviceType, deviceSubType)
      )
    ),
  resetError: () => dispatch(firmwaresActions.resetError()),
})

const mapStateToProps = (state) => ({
  error: state.firmwaresReducer.error,
  firmwareTypesForDevice: state.firmwaresReducer.firmwareTypesForDevice,
  devicesTypes: state.rootReducer.devicesTypes,
  subTypesForDevice: state.rootReducer.subTypesForDevice,
  networkError: state.rootReducer.networkError,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CreateFirmwarePage))
