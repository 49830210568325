import every from 'lodash/every'
import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import { COMMANDS_RECEIVER } from './emulator-constants'

const extractDevicesForSelect = (devices) => {
  return map(devices, (device) => ({
    imei: device.imei,
    id: device.id,
  }))
}

const prepareCommands = (list) => {
  return map(list, (item) => ({
    value: item,
    label: item,
  }))
}

const devicesTemplateForSelect = (option) => option.imei

const validateCommandsOrder = (commandsList) => {
  const commandsOrdering = sortBy(
    map(commandsList, (command) => +command.ordering)
  )

  return every(commandsOrdering, (el, ind) => el === ind + 1)
}

const getDeviceIdByImei = (imei, devices) =>
  find(devices, (device) => device.imei === imei)?.id

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const prepareBodyToSend = (commandsList) => {
  const commands = map(commandsList, (command) => {
    if (command.exists) {
      delete command.exists
    }

    return {
      ...command,
      uuid: command.uuid || uuidv4(),
    }
  })

  return commands
}

const prepareBodyForApply = (commandsList) => {
  const commands = map(commandsList, (command) => ({
    uuid: command.uuid,
    results: [
      {
        name: command.name,
        value: command.value,
      },
    ],
    status: command.newStatus,
  }))

  return commands
}

const updateCommandStatus = (commandsList, newStatus, uuid) => {
  return map(commandsList, (command) => ({
    ...command,
    newStatus: uuid === command.uuid ? newStatus : command.newStatus,
  }))
}

const extractExistingCommands = (commandsList, uuidList) =>
  filter(commandsList, (command) => !includes(uuidList, command.uuid))

const extractUuids = (commandsList) => {
  return map(commandsList, (command) => command.uuid)
}

const filterCommands = (commandsList) =>
  filter(commandsList, ({ status, result }) => !status && !result)

const generateTableKeys = (commandData) => {
  const values = map(commandData.value, (value, key) => [
    {
      cellName: 'propName',
      value: key,
    },
    {
      cellName: 'value',
      value,
    },
    {
      cellName: 'result',
      name: key,
    },
  ])

  return COMMANDS_RECEIVER.extendedTableKeys(
    reduce(values, (a, b) => [...a, ...b])
  )
}

export {
  extractDevicesForSelect,
  devicesTemplateForSelect,
  validateCommandsOrder,
  getDeviceIdByImei,
  prepareBodyToSend,
  prepareBodyForApply,
  updateCommandStatus,
  extractUuids,
  extractExistingCommands,
  prepareCommands,
  filterCommands,
  generateTableKeys,
}
