import { COLORS } from '../../../services/constants'

const styles = (theme) => ({
  logoImage: {
    height: '30px',
    cursor: 'pointer',
  },
  logoutButton: {
    minWidth: '15%',
  },
  appBar: {
    height: '90px',
    boxShadow: 'none',
    display: 'grid',
    padding: '0 7%',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '@media (min-width: 1224px)': {
    logoImage: {
      height: '36px',
    },
    appBar: {
      height: '81px',
      display: 'grid',
      padding: '0 23px',
      boxShadow: `0 2px 4px 0 ${COLORS.GRAY_ZIRCON}`,
    },
  },
})

export default styles
