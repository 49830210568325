import { COLORS } from '../../services/constants'

const styles = (theme) => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '7%',
    paddingRight: '7%',
  },
  tableWrapper: {
    top: '250px',
    height: 'calc(100% - 270px)',
    maxHeight: 'calc(100% - 210px)',
    overflowY: 'auto',
    position: 'absolute',
    width: '86%',
  },
  filterField: {
    marginRight: 20,
    width: 300,
  },
  actionButton: {
    width: 'fit-content',
    minWidth: 'auto',
    marginRight: '10px',
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: COLORS.GRAY,
  },
  toggleContainer: {
    width: '100%',
  },
})

export default styles
