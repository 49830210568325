const styles = (theme) => ({
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 5%',
    minWidth: '90%',
  },
  deviceFilterWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 2,
    '& > div': {
      flexGrow: 1,
    },
  },
  field: {
    margin: '0 10px',
  },
  actionButton: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    margin: '0 10px',
  },
  selector: {
    flexBasis: '200px',
    margin: '0 10px',
  },
  title: {
    padding: '0 5%',
  },
  header: {
    paddingTop: '15px',
    marginBottom: '15px',
  },
  cancelButton: {
    marginLeft: 'calc(5% + 10px)',
    minWidth: 'fit-content',
  },
  autocomplete: {
    marginLeft: '10px',
    marginBottom: '10px',
    flexBasis: '200px',
  },
})

export default styles
