import {
  FIRMWARE_UPDATES_LABELS,
  TITLES,
} from '../../services/firmwareUpdate-constants'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import compact from 'lodash/compact'
import map from 'lodash/map'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import CustomSelect from '../../../../components/_shared/CustomSelect'
import { checkConnection } from '../../../../services/root/root-service'
import actions from '../../services/firmwareUpdate-actions'
import styles from './../../style'

const ScheduleFirmwareUpdateComponent = withStyles(styles)((props) => {
  const {
    classes,
    firmwareType = '',
    updateFirmwareType,
    setSelected,
    versions,
    selected,
    scheduleUpdateFirmwareVersions,
    devices,
    firmwareTypes,
    loadFirmwareVersions,
    updateFirmwareVersion,
    firmwareVersion = '',
  } = props
  const isSavedButtonDisabled =
    !firmwareVersion || !selected.length || !firmwareType

  const _messageTypeTemplate = (item) => item.label
  const _versionTemplate = (item) => item.version

  useEffect(() => {
    if (firmwareType) {
      loadFirmwareVersions(firmwareType)
    }
    // eslint-disable-next-line
  }, [firmwareType])

  const changeVersion = (event) => {
    updateFirmwareVersion(event.target.value)
  }

  const changeFilterType = (event) => {
    updateFirmwareType(event.target.value)

    if (event.target.value !== firmwareType) {
      updateFirmwareVersion('')
    }
  }

  const resetSelected = () => {
    setSelected([])
  }

  const scheduleUpdate = () => {
    const selectedDevicesIds = map(devices, (item) => {
      if (selected.indexOf(item.id) !== -1) {
        return item.deviceId
      }
    })

    scheduleUpdateFirmwareVersions(
      firmwareVersion,
      compact(selectedDevicesIds),
      resetSelected
    )
  }

  return (
    <>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {TITLES.scheduleUpdate}
      </Typography>
      <div className={classes.filterWrapper}>
        <div className={classes.selector}>
          <CustomSelect
            id="schedule-update-firmware-type"
            options={firmwareTypes}
            label={FIRMWARE_UPDATES_LABELS.firmwareType}
            onChange={changeFilterType}
            value={firmwareType}
            valueName="value"
            template={_messageTypeTemplate}
          />
        </div>
        <div className={classes.selector}>
          <CustomSelect
            id="schedule-update-firmware-version"
            options={versions}
            label={FIRMWARE_UPDATES_LABELS.version}
            onChange={changeVersion}
            value={firmwareVersion}
            valueName="id"
            template={_versionTemplate}
          />
        </div>
        <Button
          id="schedule-update-button"
          variant="contained"
          disabled={isSavedButtonDisabled}
          onClick={scheduleUpdate}
          className={classes.actionButton}
          color="primary"
        >
          {FIRMWARE_UPDATES_LABELS.scheduleUpdate}
        </Button>
      </div>
    </>
  )
})

const mapStateToProps = (state) => ({
  versions: state.firmwareUpdateReducer.versions,
  firmwareTypes: state.firmwareUpdateReducer.firmwareTypes,
})

const mapDispatchToProps = (dispatch) => {
  return {
    scheduleUpdateFirmwareVersions: (versionId, devicesIds, onSuccess) =>
      dispatch(
        checkConnection(() =>
          actions.scheduleUpdateFirmwareVersions(
            versionId,
            devicesIds,
            onSuccess
          )
        )
      ),
    loadFirmwareVersions: (type, deviceType) =>
      dispatch(
        checkConnection(() => actions.loadFirmwareVersions(type, deviceType))
      ),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleFirmwareUpdateComponent)
