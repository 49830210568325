import AccessWrapper from '../../components/_shared/AccessWrapper'
import { PAGES } from '../../services/constants'
import EmulatorPage from './EmulatorPage'

const Emulator = (props) => {
  return (
    <AccessWrapper page={PAGES.deviceEmulator}>
      <EmulatorPage {...props} />
    </AccessWrapper>
  )
}

export default Emulator
