import { makeStyles, Theme } from '@material-ui/core'

import { COLORS } from '../../../../../../../services/constants'

export const useStyles = makeStyles((theme: Theme) => ({
  addCredentialButton: {
    width: '70%',
    height: '48px',
    backgroundColor: COLORS.CONCRETE_GRAY,
    borderRadius: '22px',
    border: 'none',
    color: COLORS.BLUE,
    texAlign: 'center',
    lineHeight: '24px',
    cursor: 'pointer',
  },
  buttonLabel: {
    fontSize: '12px',
    fontFamily: 'Cerebri Sans Pro Semi Bold',
  },
  featuresButton: {
    cursor: 'pointer',
  },
  closeIcon: {
    position: 'absolute',
    left: '16px',
    top: '16px',
    padding: 0,
  },
  modalTitle: {
    margin: '10px',
  },
  dialogContent: {
    margin: '0 0 20px',
    display: 'flex',
    flexDirection: 'column',
  },
  featureWrapper: {
    margin: '10px 0',
  },
  button: {
    margin: '10px auto',
    minWidth: '250px',
  },
  switchLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
      fontFamily: 'Cerebri Sans Pro Semi Bold',
    },
  },
  showPassword: {
    marginLeft: '12px',
  },
  showPasswordLabel: {
    fontFamily: 'Cerebri Sans Pro Regular',
    fontSize: '14px',
    color: COLORS.GRAY,
  },
  modalTitleContainer: {},
}))
