import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { getIdsQuery, isArc } from '../../../services/devices-service'
import deviceDetailsActions, {
  GET_SENSOR_DEVICE_TYPES,
  GET_SENSOR_DEVICE_TYPES_FAILED,
  GET_SENSOR_DEVICE_TYPES_SUCCEEDED,
} from './deviceDetails-actions'

import { GET_DEVICE_SETTINGS_HISTORY } from 'containers/Devices/services/devices-actions'
import { DEVICE_TYPE } from '../../../../../services/constants'
import { HttpClient } from '../../../../../services/HttpClient'
import devicesListActions from '../../List/services/devicesList-actions'
import { DEVICE_CREATION_PATH } from './deviceDetails-constants'
import { prepareBodyForUpdateSettings } from './deviceDetails-service'

const getFilterQuery = (state) => state.devicesReducer.devicesListReducer.query
const getId = (state) => state.devicesReducer.deviceDetailsReducer.deviceId
const getDeviceType = (state) =>
  state.devicesReducer.sharedDevicesReducer.deviceType

function* addNewDevice(action) {
  try {
    const {
      type,
      params: { body },
      onSuccess,
    } = action.payload

    const query = yield select(getFilterQuery)
    const path = DEVICE_TYPE[type].path

    const createPath =
      type === DEVICE_TYPE.EXT_DEVICE.type ||
      type === DEVICE_TYPE.NETWORK_DEVICE.type ||
      type === DEVICE_TYPE.SENSOR.type
        ? DEVICE_CREATION_PATH.create
        : DEVICE_CREATION_PATH.createWithIot

    const data = yield call(() => {
      return HttpClient.post(`/api/secured/devices/${path}/${createPath}`, {
        body,
      })
    })

    if (!data.message) {
      yield put(deviceDetailsActions.addNewDeviceSuccess(data))
      onSuccess && onSuccess()
      yield put(devicesListActions.loadDevices(query))
    } else {
      yield put(deviceDetailsActions.addNewDeviceFailed(data.message))
    }
  } catch (e) {
    yield put(deviceDetailsActions.addNewDeviceFailed(e.message))
  }
}

function* getExtendedDevice(action) {
  try {
    const { id, deviceTypePath } = action.payload
    const query = getIdsQuery([{ id: +id }])

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/${deviceTypePath}?query=(${query})`
      )
    })

    if (data.message) {
      yield put(deviceDetailsActions.getExtendedDeviceFailed(data.message))
    } else {
      yield put(deviceDetailsActions.getExtendedDeviceSuccess(data[0]))

      if (deviceTypePath === DEVICE_TYPE.EXT_DEVICE.path) {
        yield put(
          deviceDetailsActions.getExtDeviceSubTypes(data[0].extDeviceType)
        )
      }
    }
  } catch (e) {
    yield put(deviceDetailsActions.getExtendedDeviceFailed(e.message))
  }
}

function* getActualDeviceDetails(action) {
  try {
    const { id, imei } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/device-firmware-update-history/actual/${id}`
      )
    })

    yield put(deviceDetailsActions.setDeviceId(id))

    if (data.message) {
      yield put(deviceDetailsActions.getActualDeviceDetailsFailed(data.message))
    } else {
      yield put(
        deviceDetailsActions.getActualDeviceDetailsSuccess(data, imei, id)
      )
    }
  } catch (e) {
    yield put(deviceDetailsActions.getActualDeviceDetailsFailed(e.message))
  }
}

function* getDeviceRSSI(action) {
  try {
    const { deviceId } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/devices/${deviceId}/rssi `)
    })

    if (data.message) {
      yield put(deviceDetailsActions.getDeviceRSSIFailed(data.message))
    } else {
      yield put(deviceDetailsActions.getDeviceRSSISucceeded(data))
    }
  } catch (e) {
    yield put(deviceDetailsActions.getDeviceRSSIFailed(e.message))
  }
}

function* getHistoryDeviceDetails() {
  try {
    const id = yield select(getId)
    const deviceType = yield select(getDeviceType)
    const needLoadFullDeviceData =
      deviceType === DEVICE_TYPE.UR_UVGI.type ||
      deviceType === DEVICE_TYPE.FAR_UVC.type

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/device-firmware-update-history/history/${id}`
      )
    })

    if (data.message) {
      yield put(
        deviceDetailsActions.getHistoryDeviceDetailsFailed(data.message)
      )
    } else {
      yield put(
        deviceDetailsActions.getHistoryDeviceDetailsSuccess(
          data,
          needLoadFullDeviceData
        )
      )
    }
  } catch (e) {
    yield put(deviceDetailsActions.getHistoryDeviceDetailsFailed(e.message))
  }
}

function* updateSettings(action) {
  try {
    const { id, body, onSuccess } = action.payload

    const preparedBody = prepareBodyForUpdateSettings(body)

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/devices/ur-uvgi/${id}/update-device-settings`,
        { body: preparedBody }
      )
    })

    if (data.message) {
      yield put(deviceDetailsActions.updateSettingsFailed(data.message))
    } else {
      yield put(deviceDetailsActions.updateSettingsSuccess())
      yield put(GET_DEVICE_SETTINGS_HISTORY(id))
      onSuccess()
    }
  } catch (e) {
    yield put(deviceDetailsActions.updateSettingsFailed(e.message))
  }
}

function* disableDevice(action) {
  try {
    const { id, mode } = action.payload

    const data = yield call(() => {
      return HttpClient.put(
        `/api/secured/devices/ur-uvgi/${id}/cleaning/${+mode}`
      )
    })

    if (data.message) {
      yield put(deviceDetailsActions.disableDeviceFailed(data.message))
    } else {
      yield put(deviceDetailsActions.disableDeviceSuccess())
    }
  } catch (e) {
    yield put(deviceDetailsActions.disableDeviceFailed(e.message))
  }
}

function* factoryReset(action) {
  try {
    const { id, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/devices/ur-uvgi/${id}/factory-reset`)
    })

    if (data.message) {
      yield put(deviceDetailsActions.factoryResetFailed(data.message))
    } else {
      yield put(deviceDetailsActions.factoryResetSuccess())
      yield put(deviceDetailsActions.getFullDeviceData())

      onSuccess()
    }
  } catch (e) {
    yield put(deviceDetailsActions.factoryResetFailed(e.message))
  }
}

function* getFullDeviceData() {
  try {
    const deviceType = yield select(getDeviceType)
    // ToDo move this variable to reducer

    const isNetwork =
      deviceType === DEVICE_TYPE.NETWORK_DEVICE.type ||
      deviceType === DEVICE_TYPE.HUB.type ||
      deviceType === DEVICE_TYPE.HUB_RELAY.type
    const isSensor =
      deviceType === DEVICE_TYPE.SENSOR.type ||
      deviceType === DEVICE_TYPE.PIRUV.type

    const arc = isArc(deviceType)

    const id = yield select(getId)

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices${
          isSensor
            ? '/sensor-device'
            : isNetwork
            ? '/network-device'
            : arc
            ? ''
            : '/ur-uvgi'
        }/id/${id}`
      )
    })

    if (data.message) {
      yield put(deviceDetailsActions.getFullDeviceDataFailed(data.message))
    } else {
      yield put(deviceDetailsActions.getFullDeviceDataSuccess(data))
    }
  } catch (e) {
    yield put(deviceDetailsActions.getFullDeviceDataFailed(e.message))
  }
}

function* updateExtendedDevice(action) {
  try {
    const { id, deviceTypePath, body, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/devices/${deviceTypePath}/${id}`, {
        body,
      })
    })

    if (data.message) {
      yield put(deviceDetailsActions.updateExtendedDeviceFailed(data.message))
    } else {
      yield put(deviceDetailsActions.updateExtendedDeviceSuccess())
      yield put(deviceDetailsActions.getFullDeviceData())
      onSuccess && onSuccess()
    }
  } catch (e) {
    yield put(deviceDetailsActions.updateExtendedDeviceFailed(e.message))
  }
}

function* getExtDeviceTypes() {
  try {
    const data = yield call(() => {
      return HttpClient.get(
        '/api/secured/devices/ext-device/external-device-types'
      )
    })

    if (data.message) {
      yield put(deviceDetailsActions.getExtDeviceTypesFailed(data.message))
    } else {
      yield put(deviceDetailsActions.getExtDeviceTypesSuccess(data))
    }
  } catch (e) {
    yield put(deviceDetailsActions.getExtDeviceTypesFailed(e.message))
  }
}

function* getNetworkDeviceTypes() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/devices/network-device/types')
    })

    if (data.message) {
      yield put(deviceDetailsActions.getNetworkDeviceTypesFailed(data.message))
    } else {
      yield put(deviceDetailsActions.getNetworkDeviceTypesSuccess(data))
    }
  } catch (e) {
    yield put(deviceDetailsActions.getNetworkDeviceTypesFailed(e.message))
  }
}

function* getSensorDeviceTypes() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/devices/sensor-device/types')
    })

    if (data.message) {
      yield put(GET_SENSOR_DEVICE_TYPES_FAILED(data.message))
    } else {
      yield put(GET_SENSOR_DEVICE_TYPES_SUCCEEDED(data))
    }
  } catch (e) {
    yield put(GET_SENSOR_DEVICE_TYPES_FAILED(e.message))
  }
}

function* getExtDeviceSubTypes(action) {
  try {
    const { type } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/ext-device/external-device-sub-types/${type}`
      )
    })

    if (data.message) {
      yield put(deviceDetailsActions.getExtDeviceSubTypesFailed(data.message))
    } else {
      yield put(deviceDetailsActions.getExtDeviceSubTypesSuccess(data))
    }
  } catch (e) {
    yield put(deviceDetailsActions.getExtDeviceSubTypesFailed(e.message))
  }
}

function* restartDevice(action) {
  try {
    const { id, onSuccess } = action.payload

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/devices/ur-uvgi/${id}/device-restart`)
    })

    if (data.message) {
      yield put(deviceDetailsActions.restartDeviceFailed(data.message))
    } else {
      yield put(deviceDetailsActions.restartDeviceSuccess())
      yield put(deviceDetailsActions.getFullDeviceData())

      onSuccess()
    }
  } catch (e) {
    yield put(deviceDetailsActions.restartDeviceFailed(e.message))
  }
}

function* addNewDeviceSaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.ADD_NEW_DEVICE,
    addNewDevice
  )
}

function* getExtendedDeviceSaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.GET_EXTENDED_DEVICE,
    getExtendedDevice
  )
}

function* getActualDeviceDetailsSaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.GET_ACTUAL_DEVICE_DETAILS,
    getActualDeviceDetails
  )
}

function* getDeviceRSSISaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.GET_DEVICE_RSSI,
    getDeviceRSSI
  )
}

function* getHistoryDeviceDetailsSaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.GET_ACTUAL_DEVICE_DETAILS_SUCCEEDED,
    getHistoryDeviceDetails
  )
}

function* updateSettingsSaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.UPDATE_SETTINGS,
    updateSettings
  )
}

function* disableDeviceSaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.DISABLE_DEVICE,
    disableDevice
  )
}

function* factoryResetSaga() {
  yield takeLatest(deviceDetailsActions.actionTypes.FACTORY_RESET, factoryReset)
}

function* updateExtendedDeviceSaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.UPDATE_EXTENDED_DEVICE,
    updateExtendedDevice
  )
}

function* getSensorDeviceTypesSaga() {
  yield takeLatest(GET_SENSOR_DEVICE_TYPES.getType(), getSensorDeviceTypes)
}

function* getFullDeviceDataSaga() {
  yield all([
    yield takeLatest(
      deviceDetailsActions.actionTypes.GET_FULL_DEVICE_DATA,
      getFullDeviceData
    ),
    yield takeLatest(
      deviceDetailsActions.actionTypes.GET_HISTORY_DEVICE_DETAILS_SUCCEEDED,
      getFullDeviceData
    ),
    yield takeLatest(
      deviceDetailsActions.actionTypes.UPDATE_SETTINGS_SUCCEEDED,
      getFullDeviceData
    ),
    yield takeLatest(
      deviceDetailsActions.actionTypes.DISABLE_DEVICE_SUCCEEDED,
      getFullDeviceData
    ),
    yield takeLatest(
      deviceDetailsActions.actionTypes.FACTORY_RESET_SUCCEEDED,
      getFullDeviceData
    ),
  ])
}

function* getExtDeviceTypesSaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.GET_EXT_DEVICE_TYPES,
    getExtDeviceTypes
  )
}

function* getExtDeviceSubTypesSaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.GET_EXT_DEVICE_SUB_TYPES,
    getExtDeviceSubTypes
  )
}

function* restartDeviceSaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.RESTART_DEVICE,
    restartDevice
  )
}

function* getNetworkDeviceTypesSaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.GET_NETWORK_DEVICE_TYPES,
    getNetworkDeviceTypes
  )
}

export default function* deviceDetailsSagas() {
  yield fork(addNewDeviceSaga)
  yield fork(getExtendedDeviceSaga)
  yield fork(getActualDeviceDetailsSaga)
  yield fork(getDeviceRSSISaga)
  yield fork(getHistoryDeviceDetailsSaga)
  yield fork(updateSettingsSaga)
  yield fork(disableDeviceSaga)
  yield fork(factoryResetSaga)
  yield fork(updateExtendedDeviceSaga)
  yield fork(getFullDeviceDataSaga)
  yield fork(getExtDeviceTypesSaga)
  yield fork(getExtDeviceSubTypesSaga)
  yield fork(restartDeviceSaga)
  yield fork(getNetworkDeviceTypesSaga)
  yield fork(getSensorDeviceTypesSaga)
}
