import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import sharedDevicesActions, {
  GET_DEVICE_SETTINGS_HISTORY,
  GET_DEVICE_SETTINGS_HISTORY_FAILED,
  GET_DEVICE_SETTINGS_HISTORY_SUCCEEDED,
} from './devices-actions'

import { DEVICE_TYPE } from '../../../services/constants'
import { HttpClient } from '../../../services/HttpClient'
import deviceDetailsActions from '../components/Details/services/deviceDetails-actions'
import { prepareRequestQuery } from './devices-service'

function* getPortalActivity(action) {
  try {
    const { id } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/${id}/device-portal-activity?query=order.dateTime=desc`
      )
    })

    if (data.message) {
      yield put(sharedDevicesActions.getPortalActivityFailed(data.message))
    } else {
      yield put(sharedDevicesActions.getPortalActivitySuccess(data, id))
    }
  } catch (e) {
    yield put(sharedDevicesActions.getPortalActivityFailed(e.message))
  }
}

function* getDeviceEventById(action) {
  try {
    const { id } = action.payload
    const query = prepareRequestQuery(id)

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/events/device-event?query=${query}&pageSize=10&order.deviceDateTime=desc`
      )
    })

    if (data.message) {
      yield put(sharedDevicesActions.getDeviceEventByIdFailed(data.message))
    } else {
      yield put(sharedDevicesActions.getDeviceEventByIdSuccess(data))
    }
  } catch (e) {
    yield put(sharedDevicesActions.getDeviceEventByIdFailed(e.message))
  }
}

function* updateDeviceSubtype(action) {
  try {
    const { type, id } = action.payload

    const data = yield call(() => {
      return HttpClient.put(`/api/secured/devices/uvi-arc/${id}/rev/${type}`)
    })

    if (data.message) {
      yield put(sharedDevicesActions.updateDeviceTypeFailed(data.message))
    } else {
      yield put(sharedDevicesActions.updateDeviceSuccess(type))
    }
  } catch (e) {
    yield put(sharedDevicesActions.updateDeviceTypeFailed(e.message))
  }
}

function* checkImei(action) {
  try {
    const { imei, type } = action.payload

    const data = yield call(() => {
      return HttpClient.get(
        `/api/secured/devices/check/${DEVICE_TYPE[
          type
        ].label.toLowerCase()}/${imei}`
      )
    })

    if (data.message) {
      yield put(sharedDevicesActions.checkImeiFailed(data.message))
    } else {
      yield put(sharedDevicesActions.checkImeiSuccess(data))
    }
  } catch (e) {
    yield put(sharedDevicesActions.checkImeiFailed(e.message))
  }
}

function* getSettingsHistory(action) {
  try {
    const { id } = action.payload

    const data = yield call(() => {
      return HttpClient.get(`/api/secured/devices/${id}/settings/history`)
    })

    if (data.message) {
      yield put(GET_DEVICE_SETTINGS_HISTORY_FAILED(data.message))
    } else {
      yield put(GET_DEVICE_SETTINGS_HISTORY_SUCCEEDED(data))
    }
  } catch (e) {
    yield put(GET_DEVICE_SETTINGS_HISTORY_FAILED(e.message))
  }
}

function* getPortalActivitySaga() {
  yield takeLatest(
    deviceDetailsActions.actionTypes.GET_ACTUAL_DEVICE_DETAILS_SUCCEEDED,
    getPortalActivity
  )
}

function* getDeviceEventByIdSaga() {
  yield takeLatest(
    sharedDevicesActions.actionTypes.GET_PORTAL_ACTIVITY_SUCCEEDED,
    getDeviceEventById
  )
}

function* updateDeviceTypeSaga() {
  yield takeEvery(
    sharedDevicesActions.actionTypes.UPDATE_DEVICE_SUB_TYPE,
    updateDeviceSubtype
  )
}

function* checkImeiSaga() {
  yield takeLatest(sharedDevicesActions.actionTypes.CHECK_IMEI, checkImei)
}

function* getSettingsHistorySaga() {
  yield takeLatest(GET_DEVICE_SETTINGS_HISTORY.getType(), getSettingsHistory)
}

export default function* sharedDeviceSagas() {
  yield fork(getPortalActivitySaga)
  yield fork(getDeviceEventByIdSaga)
  yield fork(updateDeviceTypeSaga)
  yield fork(checkImeiSaga)
  yield fork(getSettingsHistorySaga)
}
