import AccessWrapper from '../../../../../components/_shared/AccessWrapper'
import { PAGES } from '../../../../../services/constants'
import DeviceDetailsPage from './DeviceDetailsPage'

const DeviceDetails = (props) => {
  return (
    <AccessWrapper page={PAGES.device} isWriteOnly={true}>
      <DeviceDetailsPage {...props} />
    </AccessWrapper>
  )
}

export default DeviceDetails
