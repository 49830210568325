const HELPER_TEXT = {
  signIn: 'Sign in to your account',
  errorMessage: 'The email or password you entered is incorrect.',
  emailRequired: 'Email is required if you want to reset your password',
  resetPasswordEmailSent:
    'Please check your email and follow the instructions.',
}

const LINKS = {
  forgotPassword: 'Forgot password?',
  createAccount: 'Create an account →',
}

const BUTTONS_LABEL = {
  login: 'Login',
}

const LOGIN_LABELS = {
  email: 'Email',
  password: 'Password',
}

const RESET_PASSWORD = {
  email: 'Email',
  temporaryPassword: 'Temporary Password',
  temporaryPasswordIncorrect:
    'The temporary password you entered is incorrect.',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  step_one_label: 'Step 1:',
  step_one_description: 'Enter your temporary password, sent to you via email.',
  step_two_label: 'Step 2:',
  step_two_description: 'For security purposes, create your new password.',
  passwordsDontMatch: "Passwords don't match",
  resetPassword: 'Reset Password',
  givenName: 'Given Name',
  familyName: 'Family Name',
  formErrorMessage:
    'Password reset link you clicked on has expired. For security reasons, the link is only valid for a limited time. Please visit the login page and click on "Forgot password" again to request a new reset link.',
  formSuccessMessage:
    'Your password has been reset successfully. You will be redirected to the login page in a few seconds.',
}

const PASSWORD_REQUIREMENTS = {
  contain: 'New password must contain:',
  lowerCase: 'lower case letter',
  upperCase: 'upper case letter',
  specialCharacter: 'special character(!@#$%^&*_)',
  number: 'number',
  characters: 'at least 8 characters',
  space: 'space is not a character',
}

const LOGIN_STATUSES = {
  ok: 'OK',
  new: 'NEW_PASSWORD_REQUIRED',
  deactivated: 'DEACTIVATED',
}

const DEACTIVATED_ERROR = 'Your account has been deactivated'

const initialState = {
  temporaryPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
  givenName: '',
  familyName: '',
  rules: {
    lowerCase: true,
    upperCase: true,
    specialCharacter: true,
    number: true,
    eightCharacters: true,
    space: true,
  },
}

export {
  LINKS,
  HELPER_TEXT,
  BUTTONS_LABEL,
  RESET_PASSWORD,
  PASSWORD_REQUIREMENTS,
  LOGIN_STATUSES,
  LOGIN_LABELS,
  initialState,
  DEACTIVATED_ERROR,
}
