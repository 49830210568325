import { COLORS } from '../../../../services/constants'

const styles = (theme) => ({
  headerCell: {
    fontFamily: 'Cerebri Sans Pro Semi Bold',
  },
  tableWrapper: {
    marginBottom: '15px',
    border: `1px solid ${COLORS.LIGHT_GRAY}`,
  },
  simpleValues: {
    width: '220px',
  },
  error: {
    paddingTop: '20px',
  },
  fullWidthTextField: {
    width: '100%',
  },
})

export default styles
