import {
  isArc,
  isNetwork,
  isSensor,
} from 'containers/Devices/services/devices-service'

import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'utils/reduxHelpers'
import { checkConnection } from '../../../../../services/root/root-service'
import deviceDetailsActions from '../../Details/services/deviceDetails-actions'
import { SNACKBAR_MESSAGES } from '../../Details/services/deviceDetails-constants'
import { useStyles } from './style'

type RestartDeviceActionProps = {
  id: string
  onOpenSnackbar: () => void
  setSnackbarMessage: (message: string) => void
}

const RestartDeviceAction = ({
  id,
  onOpenSnackbar,
  setSnackbarMessage,
}: RestartDeviceActionProps) => {
  const style = useStyles()
  const dispatch = useDispatch()

  const deviceType = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.deviceType
  )

  const removed = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.removed
  )

  const restartDevice = (id: string, onSuccess: () => void) =>
    dispatch(
      checkConnection(() => deviceDetailsActions.restartDevice(id, onSuccess))
    )

  const restart = () => {
    setSnackbarMessage(SNACKBAR_MESSAGES.restarted)

    restartDevice(id, onOpenSnackbar)
  }
  const network = isNetwork(deviceType)
  const arc = isArc(deviceType)
  const sensor = isSensor(deviceType)

  if (network || arc || sensor || removed) {
    return null
  }

  return (
    <Button
      variant="contained"
      onClick={restart}
      className={style.restartButton}
      id="restart-device-btn"
    >
      Restart Device
    </Button>
  )
}

export default RestartDeviceAction
