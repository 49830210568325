import { makeStyles, Theme } from '@material-ui/core'

import { COLORS } from '../../../services/constants'

export const useStyles = makeStyles((theme: Theme) => ({
  organizationCard: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: COLORS.WARM_GRAY,
    height: '50px',
    alignItems: 'center',
    marginBottom: '5px',
  },
  featuresButton: {
    cursor: 'pointer',
  },
  secondaryText: {
    fontSize: '10px',
    lineHeight: '12px',
    display: 'inline-block',
  },
  mainText: {
    fontSize: '14px',
    lineHeight: '17px',
  },
  rowIconButton: {
    padding: 0,
    width: '30px',
  },
  navigationRowItem: {
    width: '30px',
    lineHeight: '45px',
    height: '100%',
    borderLeft: `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
  },
  rowIcon: {
    height: '14px',
    color: COLORS.BLACK,
  },
  info: {
    width: 'calc(100% - 30px)',
    display: 'flex',
    height: 'inherit',
  },
  infoColumn: {
    width: '60%',
    marginLeft: '12px',
    verticalAlign: 'middle',
  },
  timeColumn: {
    width: '30%',
    paddingLeft: '8px',
    verticalAlign: 'middle',
    borderLeft: `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
  },
  table: {
    height: 'calc(100% - 210px)',
    maxHeight: 'calc(100% - 210px)',
    overflowY: 'scroll',
  },
  tablePagination: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#FAFAFA',
  },
  '@media (min-width: 1224px)': {
    secondaryText: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    mainText: {
      fontSize: '17px',
      lineHeight: '20px',
    },
  },
}))
