import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const AccessWrapper = (props) => {
  const { page, permissions, children, isRolesLoaded, isWriteOnly } = props
  const isAvailable = isWriteOnly
    ? permissions[page].write
    : permissions[page].read

  return isRolesLoaded ? (
    isAvailable ? (
      children
    ) : (
      <Redirect to="/#/dashboard" />
    )
  ) : null
}

const mapStateToProps = (state) => ({
  isRolesLoaded: state.rootReducer.isRolesLoaded,
  permissions: state.rootReducer.permissions,
})

export default connect(mapStateToProps)(AccessWrapper)
