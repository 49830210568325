import { makeStyles, Theme } from '@material-ui/core'

import { COLORS } from '../../../../../../../services/constants'

export const useStyles = makeStyles((theme: Theme) => ({
  treeNodeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: COLORS.WARM_GRAY,
    marginBottom: '5px',
    height: '70px',
  },
  singleSelectionNode: {
    height: '51px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    paddingLeft: '20px',
    flexDirection: 'column',
    gap: '5px',
  },
  fillX: {
    width: '100%',
  },
  nodesWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    minHeight: '200px',
    display: 'block',
  },
  singleSelectionTreeLabel: {
    fontSize: '14px',
  },
  singleSelectionTreeLabelSelectable: {
    fontSize: '14px',
    marginLeft: '22px',
  },
  selectAllLabel: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  treeCheckbox: {
    '& svg': {
      width: '14px',
      height: '14px',
    },
  },
  singleSelectionTreeCheckbox: {
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  arrowColumn: {
    height: '100%',
    borderLeft: `2px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
    '& button': {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: COLORS.BLACK,
    },
  },
  buttonsWrapper: {
    display: 'flex',
    height: '100%',
    '& > div': {
      display: 'flex',
      minWidth: '45px',
      alignItems: 'center',
      borderLeft: `1px solid ${COLORS.BLUE_GRAY_SECONDARY}`,
      height: '100%',
    },
  },
  actionPartWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& > button': {
      marginRight: '10px',
    },
  },
  '@media (min-width: 1224px)': {
    treeCheckbox: {
      paddingLeft: '24px',
      paddingRight: '10px',
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
    selectAllLabel: {
      fontFamily: 'Cerebri Sans Pro Regular',
      fontSize: 'initial',
    },
    arrowColumn: {
      minWidth: '25px',
      height: '61px',
      borderLeft: 'none',
      '& button': {
        color: COLORS.BLACK,
      },
    },
    singleSelectionTreeCheckbox: {
      marginLeft: '10px',
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
    singleSelectionTreeLabel: {
      fontFamily: 'Cerebri Sans Pro Medium',
      fontSize: '16px',
    },
    singleSelectionTreeLabelSelectable: {
      fontFamily: 'Cerebri Sans Pro Medium',
      fontSize: '16px',
    },
  },
}))
