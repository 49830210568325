import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import styles from '../../../components/_shared/style'
import { noInformation } from '../services/dashboard-constants'

const DashboardButtonComponent = withStyles(styles)((props) => {
  const { item, history, mainPageValues, classes, id } = props

  const handleClick = () => {
    history.push(item.href)
  }

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={handleClick}
      id={id}
    >
      <Typography variant="subtitle2">{item.title}</Typography>
      {map(item.values, (field) => (
        <Typography variant="subtitle1" key={field.name}>
          {`${field.name}: ${
            isUndefined(mainPageValues[field.propertyName])
              ? noInformation
              : mainPageValues[field.propertyName]
          }`}
        </Typography>
      ))}
    </Button>
  )
})

export default DashboardButtonComponent
