import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  bulb: {
    height: '25px',
    width: '25px',
    backgroundColor: '#bbb',
    borderRadius: '50%',
    display: 'inline-block',
  },
  red: {
    backgroundColor: '#FF0000',
  },
  green: {
    backgroundColor: '#008000',
  },
  blue: {
    backgroundColor: '#0000FF',
  },
  yellow: {
    backgroundColor: '#FFFF00',
  },
  black: {
    backgroundColor: '#000000',
  },
})
