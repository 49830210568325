import { checkConnection, goToRoute } from '../../services/root/root-service'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ArrowBack } from '@material-ui/icons'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import NetworkError from '../../components/_shared/errors/NetworkError'
import LoaderComponent from '../../components/_shared/loader/Loader'
import DefaultFirmwares from './components/defaultFirmwares/DefaultFirmwares'
import actions from './services/firmwares-actions'
import { FIRMWARES_LABELS } from './services/firmwares-constants'
import styles from './style'

const FirmwaresPage = (props) => {
  const { networkError, isLoading, loadFirmwares, history, classes } = props

  useEffect(() => {
    loadFirmwares()
    // eslint-disable-next-line
  }, [])

  const goToFirmwares = () => {
    goToRoute(history, '/firmwares')
  }

  return (
    <LoaderComponent isLoading={isLoading && !networkError}>
      <div className={classes.firmwaresPage}>
        <Box display="flex" justifyContent="center" marginBottom="15px">
          <IconButton onClick={goToFirmwares} className={classes.backButton}>
            <ArrowBack className={classes.backIcon} />
          </IconButton>
          <Typography variant="h2">
            {FIRMWARES_LABELS.defaultFirmwaresTitle}
          </Typography>
        </Box>
        {networkError ? <NetworkError /> : <DefaultFirmwares />}
      </div>
    </LoaderComponent>
  )
}

const mapStateToProps = (state) => ({
  networkError: state.rootReducer.networkError,
  isLoading: state.firmwaresReducer.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
  loadFirmwares: () => dispatch(checkConnection(actions.loadFirmwares)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FirmwaresPage))
