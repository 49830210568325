import {
  utcConverter,
  yyyyMMDDTHHmmssFormat,
} from '../../../../../../services/dateFormat-service'
import {
  DEVICE_DETAILS,
  DEVICE_PORTAL_LABELS,
} from '../../services/deviceDetails-constants'

import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import map from 'lodash/map'
import uniqueId from 'lodash/uniqueId'
import { connect } from 'react-redux'
import style from './style'

const DevicePortalActivity = (props) => {
  const { activities, classes } = props

  const getListData = () => {
    return map(activities, (row) => (
      <div className={classes.itemContainer} key={uniqueId()}>
        <div className={classes.item}>
          <Typography variant="h5">{DEVICE_PORTAL_LABELS.dateTime}</Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {utcConverter(row.dateTime, yyyyMMDDTHHmmssFormat)}
          </Typography>
        </div>
        <div className={classes.item}>
          <Typography variant="h5">{DEVICE_PORTAL_LABELS.uri}</Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {row.uri}
          </Typography>
        </div>
      </div>
    ))
  }

  return (
    <div className={classes.portalActivitiesList}>
      <Typography variant="h4" className={classes.listTitle}>
        {DEVICE_DETAILS.devicePortalActivity}
      </Typography>
      {getListData()}
    </div>
  )
}

const mapStateToProps = (state) => ({
  activities: state.devicesReducer.sharedDevicesReducer.portalActivity,
})

export default connect(mapStateToProps)(withStyles(style)(DevicePortalActivity))
