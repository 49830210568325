import AccessWrapper from '../../components/_shared/AccessWrapper'
import { PAGES } from '../../services/constants'
import FirmwaresPage from './FirmwaresPage'

const Firmwares = (props) => {
  return (
    <AccessWrapper page={PAGES.firmware}>
      <FirmwaresPage {...props} />
    </AccessWrapper>
  )
}

export default Firmwares
