import { FAULT_EVENT_NAMES, LABELS } from '../services/uvgiEmulator-constants'

import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import uniqueId from 'lodash/uniqueId'
import CustomSelect from '../../../../components/_shared/customSelect/CustomSelect'
import { MMddyyyyhmmssaa } from '../../../../services/dateFormat-service'
import EmulatorDatepicker from '../../_shared/components/EmulatorDatepicker'
import { _messageTypeTemplate } from '../../_shared/services/emulator-service'
import styles from './motionPIR/style'

const FaultEventRow = (props) => {
  const {
    changeValue,
    rowData: { name, faultEventDate, isCurrentTime },
    index,
    isSubmitted,
    deleteArrayElement,
    classes,
  } = props

  const handleChange = (event) => {
    const { name, value } = event.target

    changeValue(value, name, index)
  }

  const changeCheckboxValue = (event) => {
    const { name, checked } = event.target

    changeValue(checked, name, index)
  }

  const changeDateFormat = (value) => {
    changeValue(value, 'faultEventDate', index)
  }

  const deleteRow = () => {
    deleteArrayElement(index)
  }

  return (
    <TableRow key={uniqueId()}>
      <TableCell className={classes.faultNames}>
        <CustomSelect
          options={FAULT_EVENT_NAMES}
          name="name"
          label={LABELS.name}
          onChange={handleChange}
          error={isSubmitted && !name}
          value={name}
          valueName="value"
          template={_messageTypeTemplate}
        />
      </TableCell>
      <TableCell>
        <EmulatorDatepicker
          time={faultEventDate}
          name={'deviceDateTime'}
          disabled={isCurrentTime}
          showTimeInput={true}
          dateFormat={MMddyyyyhmmssaa}
          changeDateValue={changeDateFormat}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={isCurrentTime}
          name="isCurrentTime"
          onChange={changeCheckboxValue}
          color="primary"
        />
      </TableCell>
      <TableCell>
        <IconButton aria-label="delete" onClick={deleteRow}>
          <DeleteIcon color="error" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(FaultEventRow)
