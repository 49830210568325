import AccessWrapper from '../../../../../../components/_shared/AccessWrapper'
import { PAGES } from '../../../../../../services/constants'
import EditDevicePage from './EditDevicePage'

const EditDevice = (props) => {
  return (
    <AccessWrapper page={PAGES.device} isWriteOnly={true}>
      <EditDevicePage {...props} />
    </AccessWrapper>
  )
}

export default EditDevice
