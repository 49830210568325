import {
  Checkbox,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
} from '@material-ui/core'

import find from 'lodash/find'
import indexOf from 'lodash/indexOf'
import map from 'lodash/map'
import slice from 'lodash/slice'
import uniqueId from 'lodash/uniqueId'
import Cells from './Cells'
import styles from './style'

const Body = (props) => {
  const {
    multi, // specify wether it is possible to select multiple items
    tableData,
    cellsConfig,
    selected,
    setSelected,
    page,
    rowsPerPage,
    isSelectable,
    classes,
  } = props

  const handleSelectRow = (event, id) => {
    const selectedIndex = indexOf(selected, id)
    let newSelected = []

    if (multi) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }

      setSelected(newSelected)
    } else {
      const tableDataRow = find(tableData, (item) => item.id === id)

      if (selectedIndex === -1 && !tableDataRow.removed) {
        newSelected = [id]
        setSelected(newSelected)
      }

      if (selectedIndex !== -1) {
        setSelected(newSelected)
      }
    }
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage)
  const currentRecords = page * rowsPerPage
  const currentRows = slice(
    tableData,
    currentRecords,
    currentRecords + rowsPerPage
  )

  const getTableData = () => {
    return map(currentRows, (tableDataRow) => {
      const isItemSelected = indexOf(selected, tableDataRow.id) !== -1
      const labelId = `enhanced-table-checkbox-${tableDataRow.id}`

      return (
        <TableRow
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={uniqueId()}
          selected={isItemSelected}
          role="checkbox"
          hover
        >
          {isSelectable && (
            <TableCell padding="checkbox">
              <Checkbox
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
                onClick={
                  isSelectable
                    ? (event) => handleSelectRow(event, tableDataRow.id)
                    : null
                }
                disabled={tableDataRow.removed}
                className={classes.checkbox}
              />
            </TableCell>
          )}
          <Cells cellsConfig={cellsConfig} tableDataRow={tableDataRow} />
        </TableRow>
      )
    })
  }

  return (
    <TableBody>
      {getTableData()}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  )
}

export default withStyles(styles)(Body)
