export type EVENT_TYPES_CATEGORY =
  | 'HTTP'
  | 'POWER'
  | 'COUNTDOWN'
  | 'CYCLE'
  | 'FIRMWARE_UPDATE'
  | 'STATUS'
  | 'FAULT'
  | 'SENSOR'
  | 'STS'
  | 'INI'

export type EventTypeCategorized = {
  category: EVENT_TYPES_CATEGORY
  color: string
}

export const classifyEventType = (
  requestType: string
): EventTypeCategorized | null => {
  const regexEVT = /^EVT([0-9])/i
  const regexINI = /^INI/i
  const regexSTS = /^STS/i
  const result = regexEVT.exec(requestType)
  if (result) {
    const firstDigit = result[1]
    switch (firstDigit) {
      case '0':
        return { category: 'HTTP', color: 'blue' }
      case '1':
        return { category: 'POWER', color: 'green' }
      case '2':
        return { category: 'COUNTDOWN', color: 'green' }
      case '3':
        return { category: 'CYCLE', color: 'green' }
      case '4':
        return { category: 'FIRMWARE_UPDATE', color: 'green' }
      case '5':
        return { category: 'STATUS', color: 'blue' }
      case '6':
        return { category: 'FAULT', color: 'red' }
      case '7':
        return { category: 'SENSOR', color: 'green' }
    }
  } else if (regexSTS.exec(requestType)) {
    return { category: 'STS', color: 'blue' }
  } else if (regexINI.exec(requestType)) {
    return { category: 'INI', color: 'blue' }
  }
  return null
}
