import { useEffect, useState } from 'react'
import {
  anotherTypeCountData,
  DEVICE_DETAILS,
  FIRMWARE_TYPES_ARRAY,
  FIRMWARE_TYPES_LABELS,
} from '../services/deviceDetails-constants'

import Typography from '@material-ui/core/Typography'
import map from 'lodash/map'
import uniqueId from 'lodash/uniqueId'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'utils/reduxHelpers'
import NetworkError from '../../../../../components/_shared/errors/NetworkError'
import LoaderComponent from '../../../../../components/_shared/loader/Loader'
import { DEVICE_TYPE } from '../../../../../services/constants'
import { checkConnection } from '../../../../../services/root/root-service'
import actions from '../../../services/devices-actions'
import devicesListActions from '../../List/services/devicesList-actions'
import deviceDetailsActions from '../services/deviceDetails-actions'
import AllowList from './AllowList/AllowList'
import allowListActions from './AllowList/services/AllowList-actions'
import DeviceDetailsData from './DeviceDetailsData'
import FirmwareTypeTable from './FirmwareTypeTable'
import HistoryTable from './HistoryTable'
import { useStyles } from './style'

const DeviceDetailsPage = () => {
  const style = useStyles()
  const [loaded, setLoaded] = useState(false)
  const dispatch = useDispatch()
  const { id, imei, serialNumber } = useParams<{
    id: string
    imei: string
    serialNumber: string
  }>()

  const actualDetails = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.actualDeviceDetails
  )
  const historyDetails = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.historyDeviceDetails
  )
  const anotherTypeCount = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.anotherTypeCount
  )
  const networkError = useAppSelector((state) => state.rootReducer.networkError)
  const deviceType = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.deviceType
  )
  const isLoading = useAppSelector(
    (state) => state.devicesReducer.deviceDetailsReducer.isLoading
  )

  const firmwareTypes = FIRMWARE_TYPES_ARRAY[deviceType]

  const getActualDeviceDetails = (id: string, imei: string) =>
    dispatch(
      checkConnection(() =>
        deviceDetailsActions.getActualDeviceDetails(id, imei)
      )
    )
  const getAllowList = (id: string) =>
    dispatch(checkConnection(() => allowListActions.getAllowList(id)))
  const getDeviceRSSI = (id: string) =>
    dispatch(checkConnection(() => deviceDetailsActions.getDeviceRSSI(id)))
  const resetAnotherTypeCount = () => dispatch(actions.resetAnotherTypeCount())
  const getAbilities = () =>
    dispatch(checkConnection(devicesListActions.getCleanupAbility))

  const isNetwork = [
    DEVICE_TYPE.NETWORK_DEVICE.type,
    DEVICE_TYPE.HUB.type,
    DEVICE_TYPE.HUB_RELAY.type,
  ].includes(deviceType)

  useEffect(() => {
    getActualDeviceDetails(id, imei)
    getDeviceRSSI(id)
    if (isNetwork) {
      getAllowList(id)
    }
    getAbilities()

    return () => {
      resetAnotherTypeCount()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isLoading && !loaded) {
      setLoaded(true)
    }
  }, [isLoading])

  return (
    <LoaderComponent isLoading={!loaded && !networkError}>
      <div className={style.pageContainer}>
        <Typography variant="h3">{DEVICE_DETAILS.pageTitle}</Typography>
        {networkError ? (
          <NetworkError />
        ) : (
          <>
            <DeviceDetailsData
              imei={imei}
              serialNumber={serialNumber}
              deviceId={id}
            />
            {firmwareTypes?.length && (
              <Typography variant="h4" className={style.firmwares}>
                {DEVICE_DETAILS.firmwares}
              </Typography>
            )}
            {firmwareTypes?.length &&
              map(firmwareTypes, (firmwareType) => (
                <div
                  key={uniqueId()}
                  id={`firmware-${firmwareType.toLowerCase()}`}
                >
                  <Typography variant="h4" className={style.type}>
                    {
                      FIRMWARE_TYPES_LABELS[
                        firmwareType as keyof typeof FIRMWARE_TYPES_LABELS
                      ]
                    }
                  </Typography>
                  <div className={style.typeContainer}>
                    <FirmwareTypeTable
                      tableData={
                        actualDetails &&
                        actualDetails[
                          firmwareType as keyof typeof actualDetails
                        ]
                      }
                    />
                    <HistoryTable
                      tableData={
                        historyDetails &&
                        historyDetails[
                          firmwareType as keyof typeof actualDetails
                        ]
                      }
                    />
                  </div>
                </div>
              ))}
            {anotherTypeCount ? (
              <Typography variant="subtitle2" className={style.notification}>
                {anotherTypeCountData(anotherTypeCount, deviceType)}
              </Typography>
            ) : null}
            <AllowList hidden={!isNetwork} hubId={id} />
          </>
        )}
      </div>
    </LoaderComponent>
  )
}

export default DeviceDetailsPage
