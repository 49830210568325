import { ON_OFF_LABELS } from '../../../services/constants'
import dashboardActions from './dashboard-actions'
import { noInformation } from './dashboard-constants'
import { prepareStatusText } from './dashboard-service'

export const dashboardReducer = (
  state = {
    isLoading: true,
    mainPageValues: {},
    customerPortalStatus: ON_OFF_LABELS.off,
    devicePortalStatus: ON_OFF_LABELS.off,
    numberOfInstances: noInformation,
    error: '',
  },
  action
) => {
  switch (action.type) {
    case dashboardActions.actionTypes.CHECK_CUSTOMER_PORTAL_SUCCEEDED: {
      return {
        ...state,
        customerPortalStatus: ON_OFF_LABELS.on,
      }
    }
    case dashboardActions.actionTypes.CHECK_CUSTOMER_PORTAL_FAILED: {
      return {
        ...state,
        customerPortalStatus: ON_OFF_LABELS.off,
      }
    }
    case dashboardActions.actionTypes.CHECK_DEVICE_PORTAL_SUCCEEDED: {
      return {
        ...state,
        devicePortalStatus: ON_OFF_LABELS.on,
        mainPageValues: {
          ...state.mainPageValues,
          status: prepareStatusText(
            state.customerPortalStatus,
            ON_OFF_LABELS.on
          ),
        },
      }
    }
    case dashboardActions.actionTypes.CHECK_DEVICE_PORTAL_FAILED: {
      return {
        ...state,
        mainPageValues: {
          ...state.mainPageValues,
          status: prepareStatusText(
            state.customerPortalStatus,
            ON_OFF_LABELS.off
          ),
        },
      }
    }
    case dashboardActions.actionTypes.GET_NUMBER_OF_INSTANCES_SUCCEEDED: {
      const { numberOfInstances } = action.payload

      return {
        ...state,
        mainPageValues: {
          ...state.mainPageValues,
          numberOfInstances,
        },
      }
    }
    case dashboardActions.actionTypes.LOAD_MAIN_PAGE_VALUES: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case dashboardActions.actionTypes.LOAD_MAIN_PAGE_VALUES_SUCCEEDED: {
      const { values } = action.payload

      return {
        ...state,
        mainPageValues: values,
        isLoading: false,
        error: '',
      }
    }
    case dashboardActions.actionTypes.LOAD_MAIN_PAGE_VALUES_FAILED: {
      const { message } = action.payload

      return {
        ...state,
        error: message,
      }
    }
    default:
      return state
  }
}
