import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import logo from '../../../assets/images/logo.jpg'
import { IMG_ALT } from '../../../services/constants'
import { withMediaQuery } from '../../../services/root/root-service'
import styles from './style'

const ErrorPageComponent = withStyles(styles)((props) => {
  const {
    classes,
    isMobile,
    pageTitle,
    backFunction,
    errorDescriptionPart1,
    errorDescriptionPart2,
    goToBackText,
    Icon,
    hideLogo,
  } = props

  return (
    <div className={classes.mainContainer}>
      {!hideLogo && (
        <div className={classes.logoContainer}>
          <img className={classes.logoImage} src={logo} alt={IMG_ALT.LOGO} />
        </div>
      )}
      <div className={classes.iconWrapper}>
        <Icon width={isMobile ? 90 : 150} height={isMobile ? 90 : 150} />
      </div>
      <Typography
        className={classes.mainText}
        variant={isMobile ? 'h5' : 'h4'}
        color="textPrimary"
      >
        {pageTitle}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {errorDescriptionPart1}
        <br />
        {errorDescriptionPart2}
      </Typography>
      {!isMobile && backFunction && (
        <div className={classes.backToPreviousPageContainer}>
          <Button
            startIcon={
              <ArrowBackIcon
                color="secondary"
                classes={{
                  colorSecondary: classes.colorSecondary,
                }}
              />
            }
            onClick={backFunction}
          >
            <Typography variant="subtitle2" color="textSecondary">
              {goToBackText}
            </Typography>
          </Button>
        </div>
      )}
    </div>
  )
})

export default withMediaQuery(ErrorPageComponent)
