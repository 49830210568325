import { COLORS } from './services/constants'

const styles = (theme) => ({
  authWrapper: {
    display: 'block',
    overflowY: 'auto !important',
  },
  '@media (min-width: 1224px)': {
    pageContainer: {
      backgroundColor: COLORS.COLD_GRAY,
      height: 'calc(100vh - 81px)',
      overflow: 'auto',
    },
  },
})

export default styles
