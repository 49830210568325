import { useCallback, useEffect, useMemo, useState } from 'react'

import { Switch } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import CardWithDescriptionEditable from 'components/_shared/CardWithDescriptionEditable/CardWithDescriptionEditable'
import moment from 'moment'
import CardWithDescription from '../../../../../../components/_shared/CardWithDescription/CardWithDescription'
import { EditDropdownModal } from '../../../../../../components/_shared/EditDropdownModal/EditDropdownModal'
import { ORAGNIZATION_TYPES } from '../../../../../../services/constants'
import { MMDDYYFormat } from '../../../../../../services/dateFormat-service'
import { CONTAINER_TITLES } from '../../services/organizationDetails-constants'
import { OrganizationDetails } from '../../services/organizationDetails-types'
import ContainerWithTitle from '../ContainerWithTitle/ContainerWithTitle'
import { useStyles } from './style'

type OrganizationDetailsCardProps = {
  organization: OrganizationDetails
  onChangeStatus: (newState: boolean) => void
  onChangeSalesforceId: (newSalesforceId: string) => void
  onChangeType: (newType: string) => void
  isReadOnly: boolean
}

export const OrganizationDetailsCard = ({
  organization,
  onChangeStatus,
  onChangeSalesforceId,
  onChangeType,
  isReadOnly,
}: OrganizationDetailsCardProps) => {
  const styles = useStyles()
  const [statusPrev, setStatusPrev] = useState<boolean>(false)
  const [salesforceIdPrev, setSalesforceIdPrev] = useState<string>('')
  const [isEditTypeModalOpen, setIsEditTypeModalOpen] = useState<boolean>(false)
  const [organizationTypePrev, setOrganizationTypePrev] = useState<string>('')

  useEffect(() => {
    setStatusPrev(!!organization.active)
  }, [organization])

  useEffect(() => {
    setSalesforceIdPrev(organization.salesForceId || '')
    setOrganizationTypePrev(organization.type || '')
  }, [organization])

  const formattedCreated = useMemo(
    () => moment(organization.created).format(MMDDYYFormat),
    [organization]
  )
  const phoneFormatted = useMemo(() => {
    const extension = organization.phoneExtension
      ? `x${organization.phoneExtension}`
      : ''
    return `${organization.secondaryPhone} ${extension}`
  }, [organization])

  const statusDescription = useMemo(() => {
    if (statusPrev) return 'Active'

    if (organization.deactivationTime) {
      return `Deactivated on ${moment(organization.deactivationTime).format(
        MMDDYYFormat
      )}`
    }
    return 'Deactivated'
  }, [organization.deactivationTime, statusPrev])

  const handleStatusChange = useCallback(() => {
    setStatusPrev(!organization.active)
    onChangeStatus(!organization.active)
  }, [onChangeStatus, organization, setStatusPrev])

  const handleSalesforceIdChange = useCallback(
    (newSalesforceId: string) => {
      setSalesforceIdPrev(newSalesforceId)
      onChangeSalesforceId(newSalesforceId)
    },
    [setSalesforceIdPrev, onChangeSalesforceId]
  )

  const handleOrganizationTypeChange = useCallback(
    (newType: string) => {
      setOrganizationTypePrev(newType)
      onChangeType(newType)
      setIsEditTypeModalOpen(false)
    },
    [setIsEditTypeModalOpen, setSalesforceIdPrev, onChangeSalesforceId]
  )

  const orgType = useMemo(() => {
    return ORAGNIZATION_TYPES.find((type) => type.id === organizationTypePrev)
  }, [organizationTypePrev])

  return (
    <>
      <ContainerWithTitle title={CONTAINER_TITLES.information}>
        <CardWithDescription
          title={organization.id?.toString()}
          description="ID"
        />
        <CardWithDescriptionEditable
          title={salesforceIdPrev}
          description="Salesforce ID"
          onSubmit={handleSalesforceIdChange}
          isReadOnly={isReadOnly}
        />
        <CardWithDescription
          title={`${organization.address}, ${organization.city}, ${organization.state}`}
          description="Address"
        />
        <CardWithDescription
          title={orgType?.description || 'N/A'}
          description="Organization Type"
          actionComponent={
            isReadOnly ? null : (
              <IconButton
                className={styles.edit}
                size="small"
                onClick={() => setIsEditTypeModalOpen(true)}
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
            )
          }
        />
      </ContainerWithTitle>
      <ContainerWithTitle title="&nbsp;">
        <CardWithDescription
          title={formattedCreated}
          description="Date Created"
        />
        <CardWithDescription
          title={phoneFormatted}
          description="Phone (+Extension)"
        />
        <CardWithDescription
          title={statusDescription}
          description="Status"
          actionComponent={
            isReadOnly ? null : (
              <Switch
                checked={statusPrev}
                color="primary"
                onChange={handleStatusChange}
                name={statusPrev ? 'Active' : 'Deactivated'}
              />
            )
          }
        />
      </ContainerWithTitle>
      {!isReadOnly && (
        <EditDropdownModal
          modalTitle="Edit Organization Type"
          label="Organization Type"
          isOpen={isEditTypeModalOpen}
          onSubmit={handleOrganizationTypeChange}
          defaultValue={organizationTypePrev}
          options={ORAGNIZATION_TYPES}
          onClose={() => setIsEditTypeModalOpen(false)}
        />
      )}
    </>
  )
}
