import { call, fork, put, takeEvery } from 'redux-saga/effects'

import { HttpClient } from '../../../../services/HttpClient'
import sharedEmulatorActions from './emulator-actions'

function* getOrganizations() {
  try {
    const data = yield call(() => {
      return HttpClient.get('/api/secured/organizations/scroll?order.name=asc')
    })

    if (data.message) {
      yield put(sharedEmulatorActions.getOrganizationsFailed(data.message))
    } else {
      yield put(sharedEmulatorActions.getOrganizationsSucceeded(data))
    }
  } catch (e) {
    yield put(sharedEmulatorActions.getOrganizationsFailed(e.message))
  }
}

function* getOrganizationsSaga() {
  yield takeEvery(
    sharedEmulatorActions.actionTypes.GET_ORGANIZATIONS,
    getOrganizations
  )
}

export default function* eventsEmulatorSagas() {
  yield fork(getOrganizationsSaga)
}
