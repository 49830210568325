const emulatorActions = {}

emulatorActions.actionTypes = {
  LOAD_ORGANIZATIONS: '{Emulator} Load Organizations',
  LOAD_ORGANIZATIONS_SUCCEEDED: '{Emulator} Load Organizations Succeeded',
  LOAD_ORGANIZATIONS_FAILED: '{Emulator} Load Organizations Failed',

  GET_DEVICE_PORTAL_URL: '{Emulator} Get Device Portal URL',
  GET_DEVICE_PORTAL_URL_SUCCEEDED: '{Emulator} Get Device Portal URL Succeeded',
  GET_DEVICE_PORTAL_URL_FAILED: '{Emulator} Get Device Portal URL Failed',

  LOAD_UVGI_DEVICES: '{Emulator} Load Devices',
  LOAD_UVGI_DEVICES_SUCCEEDED: '{Emulator} Load Devices Succeeded',
  LOAD_UVGI_DEVICES_FAILED: '{Emulator} Load Devices Failed',

  LOAD_COMMAND_TYPES: '{Emulator} Load Command Types',
  LOAD_COMMAND_TYPES_SUCCEEDED: '{Emulator} Load Command Types Succeeded',
  LOAD_COMMAND_TYPES_FAILED: '{Emulator} Load Command Types Failed',

  LOAD_COMMAND_NAMES: '{Emulator} Load Command Names',
  LOAD_COMMAND_NAMES_SUCCEEDED: '{Emulator} Load Command Names Succeeded',
  LOAD_COMMAND_NAMES_FAILED: '{Emulator} Load Command Names Failed',

  SEND_COMMANDS: '{Emulator} Send Commands',
  SEND_COMMANDS_SUCCEEDED: '{Emulator} Send Commands Succeeded',
  SEND_COMMANDS_FAILED: '{Emulator} Send Commands Failed',

  GET_COMMANDS: '{Emulator} Get Commands',
  GET_COMMANDS_SUCCEEDED: '{Emulator} Get Commands Succeeded',
  GET_COMMANDS_FAILED: '{Emulator} Get Commands Failed',

  APPLY_COMMANDS: '{Emulator} Apply Commands',
  APPLY_COMMANDS_SUCCEEDED: '{Emulator} Apply Commands Succeeded',
  APPLY_COMMANDS_FAILED: '{Emulator} Apply Commands Failed',
}

emulatorActions.loadOrganizations = function () {
  return {
    type: emulatorActions.actionTypes.LOAD_ORGANIZATIONS,
  }
}

emulatorActions.loadOrganizationsSuccess = function (organizations) {
  return {
    type: emulatorActions.actionTypes.LOAD_ORGANIZATIONS_SUCCEEDED,
    payload: {
      organizations,
    },
  }
}

emulatorActions.loadOrganizationsFailed = function (message) {
  return {
    type: emulatorActions.actionTypes.LOAD_ORGANIZATIONS_FAILED,
    payload: {
      message,
    },
  }
}

emulatorActions.getDevicePortalURL = () => ({
  type: emulatorActions.actionTypes.GET_DEVICE_PORTAL_URL,
})

emulatorActions.getDevicePortalURLSuccess = (url) => ({
  type: emulatorActions.actionTypes.GET_DEVICE_PORTAL_URL_SUCCEEDED,
  payload: {
    url,
  },
})

emulatorActions.getDevicePortalURLFailed = (message) => ({
  type: emulatorActions.actionTypes.GET_DEVICE_PORTAL_URL_FAILED,
  payload: {
    message,
  },
})

emulatorActions.loadUVGIDevices = (org) => ({
  type: emulatorActions.actionTypes.LOAD_UVGI_DEVICES,
  payload: {
    org,
  },
})

emulatorActions.loadUVGIDevicesSuccess = (devices) => ({
  type: emulatorActions.actionTypes.LOAD_UVGI_DEVICES_SUCCEEDED,
  payload: {
    devices,
  },
})

emulatorActions.loadUVGIDevicesFailed = (message) => ({
  type: emulatorActions.actionTypes.LOAD_UVGI_DEVICES_FAILED,
  payload: {
    message,
  },
})

emulatorActions.loadCommandNames = () => ({
  type: emulatorActions.actionTypes.LOAD_COMMAND_NAMES,
})

emulatorActions.loadCommandNamesSuccess = (commandNames) => ({
  type: emulatorActions.actionTypes.LOAD_COMMAND_NAMES_SUCCEEDED,
  payload: {
    commandNames,
  },
})

emulatorActions.loadCommandNamesFailed = (message) => ({
  type: emulatorActions.actionTypes.LOAD_COMMAND_NAMES_FAILED,
  payload: {
    message,
  },
})

emulatorActions.loadCommandTypes = () => ({
  type: emulatorActions.actionTypes.LOAD_COMMAND_TYPES,
})

emulatorActions.loadCommandTypesSuccess = (commandTypes) => ({
  type: emulatorActions.actionTypes.LOAD_COMMAND_TYPES_SUCCEEDED,
  payload: {
    commandTypes,
  },
})

emulatorActions.loadCommandTypesFailed = (message) => ({
  type: emulatorActions.actionTypes.LOAD_COMMAND_TYPES_FAILED,
  payload: {
    message,
  },
})

emulatorActions.sendCommands = (body, deviceId) => ({
  type: emulatorActions.actionTypes.SEND_COMMANDS,
  payload: {
    body,
    deviceId,
  },
})

emulatorActions.sendCommandsSucceeded = (data) => ({
  type: emulatorActions.actionTypes.SEND_COMMANDS_SUCCEEDED,
  payload: {
    data,
  },
})

emulatorActions.sendCommandsFailed = (message) => ({
  type: emulatorActions.actionTypes.SEND_COMMANDS_FAILED,
  payload: {
    message,
  },
})

emulatorActions.getCommands = (deviceId) => ({
  type: emulatorActions.actionTypes.GET_COMMANDS,
  payload: {
    deviceId,
  },
})

emulatorActions.getCommandsSucceeded = (data) => ({
  type: emulatorActions.actionTypes.GET_COMMANDS_SUCCEEDED,
  payload: {
    data,
  },
})

emulatorActions.getCommandsFailed = (message) => ({
  type: emulatorActions.actionTypes.GET_COMMANDS_FAILED,
  payload: {
    message,
  },
})

emulatorActions.applyCommands = (
  body,
  deviceImei,
  deviceId,
  devicePortalURL
) => ({
  type: emulatorActions.actionTypes.APPLY_COMMANDS,
  payload: {
    body,
    deviceImei,
    deviceId,
    devicePortalURL,
  },
})

emulatorActions.applyCommandsSucceeded = (data, deviceId) => ({
  type: emulatorActions.actionTypes.APPLY_COMMANDS_SUCCEEDED,
  payload: {
    data,
    deviceId,
  },
})

emulatorActions.applyCommandsFailed = (message) => ({
  type: emulatorActions.actionTypes.APPLY_COMMANDS_FAILED,
  payload: {
    message,
  },
})

export default emulatorActions
