import { encodeString, escapeQuotes } from '../../../services/root/root-service'

import filterQuery from '../../../services/filterQuery-service'
import { UsersFilterState } from '../components/types'
import { USERS_PAGE_LABELS } from './users-constants'

const sendCode = (url: string, code: string) => {
  fetch(`${url}/api/secured/user-profiles/select/${code}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.ok) {
      const error = document.getElementById('error')

      if (error) {
        if (response.redirected && response.url) {
          error.innerHTML = USERS_PAGE_LABELS.customerPortalLoginError
        } else {
          error.innerHTML = ''
        }
      }

      const win = window.open(`${url}/#/dashboard`, '_blank')
      win?.focus()
    } else {
      response.text().then((text) => {
        const error = document.getElementById('error')

        if (error) {
          if (text) {
            error.innerHTML = JSON.parse(text).message
          } else {
            error.innerHTML = response.statusText
          }
        }
      })
    }
  })
}

const prepareQuery = (filterState: UsersFilterState) => {
  const queryParams = []
  const { organizationName, roleName, email } = filterState

  const encodedOrganizationName = encodeString(
    escapeQuotes(organizationName?.trim())
  )
  const encodedEmail = encodeString(escapeQuotes(email?.trim()))

  if (encodedOrganizationName) {
    queryParams.push(
      filterQuery.getQueryString(
        `*${encodedOrganizationName}*`,
        filterQuery.templates.default,
        'organizationName',
        'contains'
      )
    )
  }

  if (encodedEmail) {
    queryParams.push(
      filterQuery.getQueryString(
        `*${encodedEmail}*`,
        filterQuery.templates.default,
        'email',
        'contains'
      )
    )
  }

  if (roleName) {
    queryParams.push(
      filterQuery.getQueryString(
        roleName,
        filterQuery.templates.default,
        'roleName',
        'eq'
      )
    )
  }

  const queryForFilter = filterQuery.createQueryStringFromArray(
    queryParams,
    'and'
  )

  return queryParams.length ? `query=${queryForFilter}&` : ''
}

export { sendCode, prepareQuery }
