import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import { useEffect, useState } from 'react'

import Typography from '@material-ui/core/Typography'
import { Info } from '@material-ui/icons'
import { GET_DEVICE_SETTINGS_HISTORY } from 'containers/Devices/services/devices-actions'
import { DEVICE_COMMAND_MAPPING } from 'containers/Devices/services/devices-constants'
import capitalize from 'lodash/capitalize'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { checkConnection } from 'services/root/root-service'
import { useAppSelector } from 'utils/reduxHelpers'
import { yyyyMMDDHHmmFormat } from '../../../../../../services/dateFormat-service'
import { DEVICE_SETTINGS_HISTORY_TITLE } from '../../services/deviceDetails-constants'
import { DEVICE_LOG_LEVELS_MAPPING } from '../types'
import { useStyles } from './style'

type DeviceSettingsHistoryTableProps = {
  deviceId: string
}

const DeviceSettingsTooltip = () => {
  const classes = useStyles()

  return (
    <ul className={classes.tooltip}>
      <li>
        <b>Unknown</b>: Device hasn&apos;t acknowledged the setting yet.
      </li>
      <li>
        <b>Cancelled</b>: Cancelled by a new command.
      </li>
      <li>
        <b>Success</b>: The command was successfully applied.
      </li>
      <li>
        <b>Failed</b>: There was an issue applying the setting.
      </li>
      <li>
        <b>Unsupported</b>: This setting is not supported by the device.
      </li>
    </ul>
  )
}

export const DeviceSettingsHistoryTable = ({
  deviceId,
}: DeviceSettingsHistoryTableProps) => {
  const classes = useStyles()

  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)

  const dispatch = useDispatch()

  const deviceSettingsHistory = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.deviceSettingsHistory
  )

  const deviceSettingsError = useAppSelector(
    (state) => state.devicesReducer.sharedDevicesReducer.deviceSettingsError
  )

  useEffect(() => {
    if (deviceId) {
      dispatch(checkConnection(() => GET_DEVICE_SETTINGS_HISTORY(deviceId)))
    }
  }, [deviceId])

  if (
    (!deviceSettingsHistory || deviceSettingsHistory.length === 0) &&
    !deviceSettingsError
  ) {
    return null
  }

  if (deviceSettingsError) {
    return (
      <div className={classes.tableContainer}>
        <Typography variant="h4" className={classes.tableTitle}>
          {DEVICE_SETTINGS_HISTORY_TITLE}
        </Typography>
        <Typography variant="h6" color="error">
          {deviceSettingsError}
        </Typography>
      </div>
    )
  }

  return (
    <div className={classes.tableContainer}>
      <div className={classes.tableHeader}>
        <Typography variant="h4" className={classes.tableTitle}>
          {DEVICE_SETTINGS_HISTORY_TITLE}
        </Typography>
      </div>
      <Table aria-label="simple table" id="settings-history-table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Setting</TableCell>
            <TableCell>Changed By</TableCell>
            <TableCell>New Value</TableCell>
            <TableCell>Previous Value</TableCell>
            <TableCell className={classes.tableHeaderTooltip}>
              <div>Status</div>
              <Tooltip title={<DeviceSettingsTooltip />}>
                <IconButton aria-label="show more">
                  <Info />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(
            rowsPerPage > 0
              ? deviceSettingsHistory.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : deviceSettingsHistory,
            (setting) => {
              const previousSetting = sortBy(
                deviceSettingsHistory,
                ({ date }) => -moment(date).utc().unix()
              ).find(
                ({ type, date }) =>
                  setting.type === type &&
                  moment(date).unix() < moment(setting.date).unix()
              )

              return (
                <TableRow key={`device-setting-${setting.id}`}>
                  <TableCell
                    component="th"
                    scope="row"
                    id={`setting-${setting.id}`}
                  >
                    {moment.utc(setting.date).format(yyyyMMDDHHmmFormat)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {DEVICE_COMMAND_MAPPING[setting.type] || setting.type}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {setting.changedByName?.trim().length
                      ? setting.changedByName
                      : setting.changedBy || 'Unknown'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {setting.value && setting.value[setting.type]
                      ? setting.value[setting.type]
                      : setting.type === 'LOG_CTRL'
                      ? `${setting.value.CATEGORY}: ${
                          DEVICE_LOG_LEVELS_MAPPING[Number(setting.value.LEVEL)]
                        }`
                      : 'Unknown'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {previousSetting?.value &&
                    previousSetting?.value[previousSetting?.type]
                      ? previousSetting?.value[previousSetting?.type]
                      : 'Unknown'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {capitalize(setting.status || 'Unknown')}
                  </TableCell>
                </TableRow>
              )
            }
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 30, 50]}
              colSpan={6}
              count={deviceSettingsHistory.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => {
                setPage(newPage)
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10))
                setPage(0)
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  )
}

export default DeviceSettingsHistoryTable
