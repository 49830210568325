export const isVersionString = (text: string) => !!text.match(/\d+\.\d+\.\d+/)

export const isAtLeastVersion = (
  minimumVersion: string,
  version: string | null
) => {
  const actualVersion = (version || '').replaceAll(/-test/g, '')

  return (
    isVersionString(actualVersion) &&
    actualVersion.localeCompare(minimumVersion, undefined, {
      numeric: true,
      sensitivity: 'base',
    }) >= 0
  )
}
