import { Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { classifyEventType } from '../../../../../../utils/deviceEventsHelpers'
import { useStyles } from './styles/deviceEventTypeBulbStyles'

type DeviceEventTypeBulbProps = { requestType: string }

export const DeviceEventTypeBulb = ({
  requestType,
}: DeviceEventTypeBulbProps) => {
  const classes = useStyles()

  const COLOR_MAPPER = {
    green: classes.green,
    red: classes.red,
    yellow: classes.yellow,
    blue: classes.blue,
  }

  const CATEGORY_MAPPER = {
    HTTP: 'Http event',
    POWER: 'Power event',
    COUNTDOWN: 'Countdown event',
    CYCLE: 'Cycle event',
    FIRMWARE_UPDATE: 'Firmware event',
    STATUS: 'Status event',
    FAULT: 'Fault event',
    SENSOR: 'Sensor event',
    STS: 'Statistics event',
    INI: 'Init event',
  }

  const eventCategorizedInfo = classifyEventType(requestType)
  let title = 'Unknown'

  let color = classes.black
  if (eventCategorizedInfo) {
    color =
      COLOR_MAPPER[eventCategorizedInfo.color as keyof typeof COLOR_MAPPER]
    title =
      CATEGORY_MAPPER[
        eventCategorizedInfo.category as keyof typeof CATEGORY_MAPPER
      ]
  }

  return (
    <Tooltip title={title}>
      <div className={classNames(classes.bulb, color)}> </div>
    </Tooltip>
  )
}
