import { TableCell } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import TableRow from '@material-ui/core/TableRow'
import CloseIcon from '@material-ui/icons/Close'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import { useState } from 'react'
import { connect } from 'react-redux'
import ConfirmModal from '../../../../../../components/_shared/confirmModal/ConfirmModal'
import { checkConnection } from '../../../../../../services/root/root-service'
import { useStyles } from '../style'
import allowListActions from './services/AllowList-actions'
import { MODAL_TEXT } from './services/AllowList-constants'

const AllowListTableRow = (props) => {
  const [open, setOpen] = useState(false)
  const { allowListRemoveDevice, device, hubId, index } = props

  const style = useStyles()

  const onClick = () => {}

  return (
    <>
      <TableRow>
        <TableCell align="left">{index}</TableCell>
        <TableCell align="left">{device.serialNumber}</TableCell>
        <TableCell align="left">
          {toUpper(startCase(toLower(device.deviceType)))}
        </TableCell>
        <TableCell align="left">
          {toUpper(startCase(toLower(device.deviceSubType)))}
        </TableCell>
        <TableCell align="center">
          <IconButton
            className={style.deleteIcon}
            color="secondary"
            onClick={() => setOpen(true)}
            aria-label="show more"
          >
            <CloseIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <ConfirmModal
        isOpen={open}
        onClose={() => setOpen(false)}
        onSubmit={() => allowListRemoveDevice(hubId, device.id)}
        confirmText={MODAL_TEXT.description}
        modalTitle={MODAL_TEXT.title}
        actionLabel={MODAL_TEXT.confirm}
        closeLabel={MODAL_TEXT.cancel}
      />
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  allowListRemoveDevice: (hubId, deviceId) =>
    dispatch(
      checkConnection(() =>
        allowListActions.allowListRemoveDevice(hubId, deviceId)
      )
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AllowListTableRow)
