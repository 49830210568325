import allowListActions from './AllowList-actions'

export const allowListReducer = (
  state = {
    error: null,
    isLoading: false,
    allowList: [],
    searchResults: [],
    bulkAddModalShown: false,
    addDeviceError: null,
    bulkAddDeviceError: null,
    removeDeviceError: null,
  },
  action
) => {
  switch (action.type) {
    case allowListActions.actionTypes.GET_ALLOW_LIST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      }
    }
    case allowListActions.actionTypes.GET_ALLOW_LIST_SUCCEEDED: {
      const { data } = action.payload

      return {
        ...state,
        allowList: data || [],
        isLoading: false,
      }
    }
    case allowListActions.actionTypes.GET_ALLOW_LIST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
        allowList: [],
      }
    }
    case allowListActions.actionTypes.ALLOW_LIST_REMOVE_DEVICE: {
      return {
        ...state,
        removeDeviceError: null,
        isLoading: true,
      }
    }
    case allowListActions.actionTypes.ALLOW_LIST_REMOVE_DEVICE_SUCCEEDED: {
      return {
        ...state,
        removeDeviceError: null,
        isLoading: false,
      }
    }
    case allowListActions.actionTypes.ALLOW_LIST_REMOVE_DEVICE_FAILED: {
      return {
        ...state,
        removeDeviceError: action.payload.message,
        isLoading: false,
      }
    }
    case allowListActions.actionTypes.ALLOW_LIST_ADD_DEVICE: {
      return {
        ...state,
        addDeviceError: null,
        isLoading: true,
      }
    }
    case allowListActions.actionTypes.ALLOW_LIST_ADD_DEVICE_SUCCEEDED: {
      return {
        ...state,
        addDeviceError: null,
        isLoading: false,
      }
    }
    case allowListActions.actionTypes.ALLOW_LIST_ADD_DEVICE_FAILED: {
      return {
        ...state,
        addDeviceError: action.payload.message,
        isLoading: false,
      }
    }
    case allowListActions.actionTypes.ALLOW_LIST_BULK_ADD_DEVICES: {
      return {
        ...state,
        bulkAddDeviceError: null,
        isLoading: true,
      }
    }
    case allowListActions.actionTypes.ALLOW_LIST_BULK_ADD_DEVICES_SUCCEEDED: {
      return {
        ...state,
        bulkAddDeviceError: null,
        isLoading: false,
        bulkAddModalShown: false,
      }
    }
    case allowListActions.actionTypes.ALLOW_LIST_BULK_ADD_DEVICES_FAILED: {
      return {
        ...state,
        bulkAddDeviceError: action.payload.message,
        isLoading: false,
      }
    }
    case allowListActions.actionTypes.TOGGLE_BULK_ADD_MODAL_SUCCESS: {
      return {
        ...state,
        bulkAddModalShown: !state.bulkAddModalShown,
      }
    }
    case allowListActions.actionTypes
      .ALLOW_LIST_CLEAR_BULK_ADD_DEVICES_CLEAR_ERROR_SUCCESS: {
      return {
        ...state,
        bulkAddDeviceError: null,
      }
    }
    case allowListActions.actionTypes.SEARCH_AVAILABLE_DEVICE: {
      return {
        ...state,
        error: null,
        isLoading: true,
      }
    }
    case allowListActions.actionTypes.SEARCH_AVAILABLE_DEVICE_SUCCEEDED: {
      const { data } = action.payload
      return {
        ...state,
        error: null,
        isLoading: false,
        searchResults: data || [],
      }
    }
    case allowListActions.actionTypes.SEARCH_AVAILABLE_DEVICE_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
        searchResults: [],
      }
    }
    default:
      return state
  }
}
