import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import {
  DEVICE_EVENT_TITLE,
  EVENTS_SYNC,
} from '../../services/deviceDetails-constants'
import { jsonTableStyles, useStyles } from './styles/style'

import Typography from '@material-ui/core/Typography'
import map from 'lodash/map'
import moment from 'moment'
import { useEffect } from 'react'
import { JsonTable } from 'react-json-to-html'
import { ExpandableTableRow } from '../../../../../../components/_shared/expandableTableRow/ExpandableTableRow'
import { yyyyMMDDTHHmmssFormat } from '../../../../../../services/dateFormat-service'
import { DeviceEvent } from '../../../../services/devices-types'
import { DeviceEventTypeBulb } from './DeviceEventTypeBulb'

type DeviceEventsTableProps = {
  deviceEvents: DeviceEvent[]
}

type ExpandedContentProps = { event: DeviceEvent }

const IMAGE_OUT_OF_SYNC = '/public/assets/images/time-expired.png'
const IMAGE_IN_SYNC = '/public/assets/images/time-correct.png'

export const ExpandedContent = ({ event }: ExpandedContentProps) => {
  const classes = useStyles()

  const filteredEvent = Object.keys(event)
    .filter((key) => ['certificatesInfo', 'id'].indexOf(key) === -1)
    .reduce((acc, key) => {
      acc[key] = event[key as keyof DeviceEvent]
      return acc
    }, {} as any)

  return (
    <TableCell colSpan={6} className={classes.extendedTableContainer}>
      <JsonTable css={jsonTableStyles} json={filteredEvent} />
    </TableCell>
  )
}

const getServerTimeDiff = (event: DeviceEvent): number => {
  return Math.abs(
    moment
      .utc(event.deviceDateTime)
      .diff(moment(event.serverTimestamp), 'minutes')
  )
}

export const DeviceEventsTable = ({ deviceEvents }: DeviceEventsTableProps) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      document
        .getElementById(location.hash.substring(1))
        ?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location.hash, deviceEvents])

  if (!deviceEvents || deviceEvents.length === 0) {
    return null
  }

  return (
    <div className={classes.tableContainer}>
      <Typography variant="h4" className={classes.tableTitle}>
        {DEVICE_EVENT_TITLE}
      </Typography>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="10">Type</TableCell>
            <TableCell width="100" align="center">
              Device time vs. server time
            </TableCell>
            <TableCell>Event ID</TableCell>
            <TableCell>Device Time</TableCell>
            <TableCell>Server Time</TableCell>
            <TableCell padding="checkbox" />
          </TableRow>
        </TableHead>
        <TableBody>
          {map(deviceEvents, (event, i) => (
            <ExpandableTableRow
              key={i}
              expandComponent={<ExpandedContent event={event} />}
              onClick={() =>
                history.push(
                  location.hash === `#event-${event.id}`
                    ? '#_'
                    : `#event-${event.id}`
                )
              }
              isExpanded={location.hash === `#event-${event.id}`}
            >
              <TableCell component="th" scope="row" id={`event-${event.id}`}>
                <DeviceEventTypeBulb requestType={event.requestType} />
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                <Tooltip
                  title={
                    getServerTimeDiff(event) > 15
                      ? EVENTS_SYNC.OUT_OF_SYNC
                      : EVENTS_SYNC.IN_SYNC
                  }
                >
                  <img
                    src={
                      getServerTimeDiff(event) > 15
                        ? IMAGE_OUT_OF_SYNC
                        : IMAGE_IN_SYNC
                    }
                    width="30"
                  />
                </Tooltip>
              </TableCell>
              <TableCell component="th" scope="row">
                {event.requestType}
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(event.deviceDateTime).format(yyyyMMDDTHHmmssFormat)}
              </TableCell>
              <TableCell component="th" scope="row">
                {moment(event.serverTimestamp)
                  .utc()
                  .format(yyyyMMDDTHHmmssFormat)}
              </TableCell>
            </ExpandableTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default DeviceEventsTable
