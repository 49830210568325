import { COLORS } from '../../../services/constants'

const style = () => ({
  selectedTextColor: {
    '& > .MuiSelect-select *': {
      color: COLORS.BLACK,
    },
  },
})

export default style
