import { Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import filter from 'lodash/filter'
import map from 'lodash/map'
import uniqueId from 'lodash/uniqueId'
import { useState } from 'react'
import { LIMIT_ERROR } from '../services/emulator-constants'
import styles from './style'

const ExpandableTableComponent = withStyles(styles)((props) => {
  const {
    classes,
    columnNames,
    CustomTableRow,
    tableData,
    changeValue,
    propName,
    tableTitle,
    defaultValue,
    isSubmitted,
    limit,
  } = props
  const [limitError, setLimitError] = useState(false)

  const changeArrayElement = (value, name, index) => {
    tableData[index][name] = value

    changeValue(tableData, propName)
  }

  const addNewEmptyElement = () => {
    const newArray = [...tableData, defaultValue]

    if (limit) {
      if (tableData.length < limit) {
        changeValue(newArray, propName)
        setLimitError(false)
      } else {
        setLimitError(true)
      }
    } else {
      changeValue(newArray, propName)
    }
  }

  const deleteArrayElement = (index) => {
    delete tableData[index]
    const newArray = filter(tableData)

    changeValue(newArray, propName)
    setLimitError(limit && newArray.length > limit)
  }

  return (
    <>
      {limitError && (
        <Typography color="error" className={classes.error} variant="h5">
          {LIMIT_ERROR}
        </Typography>
      )}
      <Toolbar>
        <Typography className={classes.title} variant="h5" component="div">
          {tableTitle}
        </Typography>

        <Tooltip title="Add new record">
          <IconButton aria-label="delete" onClick={addNewEmptyElement}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>

      <Table size="small" className={classes.tableWrapper}>
        <TableHead>
          <TableRow>
            {map(columnNames, (key) => (
              <TableCell
                classes={{ root: classes.headerCell }}
                key={uniqueId()}
              >
                {key}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(tableData, (item, i) => (
            <CustomTableRow
              changeValue={changeArrayElement}
              isSubmitted={isSubmitted}
              index={i}
              rowCount={tableData.length}
              deleteArrayElement={deleteArrayElement}
              key={i}
              rowData={item}
            />
          ))}
        </TableBody>
      </Table>
    </>
  )
})

export default ExpandableTableComponent
