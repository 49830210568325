import { COLORS } from '../../../../../../services/constants'

const style = () => ({
  value: {
    marginLeft: '10px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLORS.WHITE,
    marginBottom: '5px',
    padding: '5px',
  },
  item: {
    display: 'flex',
  },
  portalActivitiesList: {
    display: 'flex',
    flexDirection: 'column',
  },
  listTitle: {
    margin: '10px 0',
  },
})

export default style
