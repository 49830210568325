import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import { useState } from 'react'
import { FIRMWARES_LABELS } from '../../services/firmwares-constants'
import ChangeDefaultVersionModal from './ChangeDefaultVersionModal'
import style from './style'

const ChangeDefaultFirmwareButton = (props) => {
  const { deviceType, deviceSubType, firmware, classes } = props
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        className={classNames(
          classes.changeDefaultButton,
          'change-default-version-btn'
        )}
        variant="contained"
        color="primary"
        onClick={openModal}
      >
        {FIRMWARES_LABELS.changeVersion}
      </Button>
      {open && (
        <ChangeDefaultVersionModal
          open={open}
          onClose={onClose}
          deviceType={deviceType}
          deviceSubType={deviceSubType}
          firmware={firmware}
        />
      )}
    </>
  )
}

export default withStyles(style)(ChangeDefaultFirmwareButton)
