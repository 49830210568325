import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import map from 'lodash/map'
import uniqueId from 'lodash/uniqueId'
import { useDispatch } from 'react-redux'
import { checkConnection } from 'services/root/root-service'
import { useAppSelector } from 'utils/reduxHelpers'
import { GET_LOCATIONS_LIST } from '../../../services/organizationDetails-actions'
import { Location } from '../../../services/organizationDetails-types'
import AddLocationButton from '../addLocation/AddLocationButton'
import DeleteLocationButton from '../deleteLocation/DeleteLocationButton'
import EditLocationButton from '../EditLocation/EditLocationButton'
import { SHRINK_NODE } from './services/customTree-actions'
import { useStyles } from './style'

export type TreeNodeProps = Omit<Location, 'children'> & {
  children?: React.ReactNode
  isReadOnly: boolean
}

const TreeNode = ({
  id,
  children,
  hasChild,
  expanded,
  locationType,
  parentId,
  name,
  city,
  streetAddress,
  state,
  zip,
  isReadOnly,
  width,
  height,
  length,
}: TreeNodeProps) => {
  const styles = useStyles()

  const isProperty = locationType === 'property'
  const dispatch = useDispatch()

  const treeMargins = useAppSelector(
    (state) => state.organizationDetailsReducer.treeMargins
  )
  const possibleChildren = useAppSelector(
    (state) => state.organizationDetailsReducer.possibleChildren
  )

  const getLocationsList = (parentId: string) =>
    dispatch(checkConnection(() => GET_LOCATIONS_LIST(parentId, undefined)))
  const shrinkNode = (id: string) => dispatch(SHRINK_NODE(id))

  const margin = treeMargins[locationType]

  const renderChildren = () => {
    if (!expanded) {
      return null
    }

    return children
  }

  const onExpandList = () => {
    if (expanded) {
      shrinkNode(id)
    } else {
      getLocationsList(id)
    }
  }

  return (
    <>
      <div
        className={styles.treeNodeContainer}
        style={{
          marginLeft: margin,
          borderLeft: isProperty ? '4px solid rgba(0, 0, 0, 0.3)' : '',
        }}
      >
        <div className={styles.labelContainer}>
          <div className={styles.fillX}>
            <Typography variant="h4">{name}</Typography>
          </div>
          <div className={styles.fillX}>
            <Typography variant="subtitle1">
              Width: {width} inches, Length: {length} inches, Height: {height}{' '}
              inches
            </Typography>
          </div>
        </div>
        <div className={styles.actionPartWrapper}>
          {!isReadOnly &&
            map(possibleChildren[locationType], (child) => (
              <AddLocationButton
                key={uniqueId()}
                parentId={id}
                locationType={child}
              />
            ))}
          <div className={styles.buttonsWrapper}>
            {!isReadOnly && (
              <DeleteLocationButton id={id} parentId={parentId} name={name} />
            )}
            {!isReadOnly && (
              <EditLocationButton
                id={id}
                name={name}
                locationType={locationType}
                parentId={parentId}
                city={city}
                state={state}
                zip={zip}
                streetAddress={streetAddress}
                width={width}
                height={height}
                length={length}
              />
            )}
            {hasChild && (
              <div className={styles.arrowColumn}>
                <IconButton
                  onClick={onExpandList}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </div>
      {renderChildren()}
    </>
  )
}

export default TreeNode
