import * as moment from 'moment'

import {
  TREE_LEVELS,
  UNKNOWN_ID,
  UNKNOWN_LOCATION,
} from '../../../../services/constants'
import {
  yyyyMMDD,
  yyyyMMDDTHHmmssFormat,
} from '../../../../services/dateFormat-service'

import concat from 'lodash/concat'
import every from 'lodash/every'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import repeat from 'lodash/repeat'
import toString from 'lodash/toString'
import { DATE_FIELDS } from '../../UVGI/services/uvgiEmulator-constants'
import { TYPES } from './emulator-constants'

const isAllValuesFilledAndPositive = (fields, values) => {
  return every(fields, (item) => {
    return values[item] >= 0
  })
}

const getPlacementsFromTree = (tree) => {
  const children = concat([], tree.children)
  const placements = []

  var traverse = function (current, deep) {
    map(current, (item) => {
      item.name = repeat('- ', deep) + item.name
      item.subheader = !item.selected

      placements.push(item)

      if (item.children && !!item.children.length) {
        let nextDeep = deep + 1

        traverse(item.children, nextDeep)
      }
    })
  }

  traverse(children, 0)

  placements.push({ id: UNKNOWN_ID, name: UNKNOWN_LOCATION, children: [] })

  return placements
}

const _deviceNameTemplate = (item) => item.serialNumber

const _messageTypeTemplate = (item) => item.label

const _locationLabelTemplateMobile = (item) => `${item.name} (id: ${item.id})`

const _locationLabelTemplateDesktop = (item) => `${item.name} (id: ${item.id})`

const isTransactionIdValid = (value, isUVGI) => {
  const regExp = isUVGI
    ? /^[A-Za-z]{5}:\d{5}:((\d{5})|(\d{9}))$/
    : /^[A-Za-z]{5}:\d{5}:\d{5}$/

  return value.search(regExp) !== -1
}

const getDeviceId = (devices, serialNumber) => {
  let device = find(devices, ['serialNumber', serialNumber])

  return device.id
}

const durationFieldCondition = ['countdown start', 'cycle start']

const getImeiByDevice = (devices, serialNumber) => {
  const device = find(devices, ['serialNumber', serialNumber])

  return device.imei
}

const isSpecialModeFieldsValid = (...values) => {
  const regExp = /[^0-9]/

  return every(values, (value) => value && value.search(regExp) === -1)
}

const validateNumericFields = (...values) => {
  const regExp = /[^0-9]/

  return every(
    values,
    (value) => value === 0 || (value && toString(value).search(regExp) === -1)
  )
}

const getRoomsListFromTree = (tree) => {
  const children = concat([], tree.children)
  const placements = []

  var traverse = function (current) {
    forEach(current, (item) => {
      item.deep = item.locationType

      if (item.locationType !== TREE_LEVELS.PLACEMENT) {
        placements.push(item)
      }

      if (item.children && !!item.children.length) {
        traverse(item.children)
      }
    })
  }

  traverse(children)

  return placements
}

const prepareMetaInfoRequestBody = (data, message) => {
  const metaInfo = {}

  const isStartEvent = message === TYPES.cycleStart

  forEach(data, (item) => {
    const isStartTime = item.key === 'start_time'

    if (item.key && !(isStartTime && isStartEvent)) {
      metaInfo[item.key] =
        item.type === 'number' ? parseInt(item.value) : item.value
    }
  })

  return isEmpty(metaInfo) ? null : metaInfo
}

const getOrganizationIdLabel = (orgId) => `Selected organization id: ${orgId}`

const isRequiredFieldsFilled = (fields, values) => {
  return every(fields, (item) => {
    return !!values[item] || values[item] === 0 || values[item] === false
  })
}

const pushParams = (fields, values, params = []) => {
  const updatedParams = [...params]

  forEach(fields, (item) => {
    let currentValue

    if (values[item] !== UNKNOWN_ID) {
      if (DATE_FIELDS.indexOf(item) !== -1) {
        currentValue = moment(values[item]).format(yyyyMMDD)
      }

      if (item === 'deviceDateTime') {
        currentValue = values.useCurrentTime
          ? moment().utc().format(yyyyMMDDTHHmmssFormat)
          : moment(values[item]).utc().format(yyyyMMDDTHHmmssFormat)
      }

      updatedParams.push(`${item}=${currentValue || values[item]}`)
    }
  })

  updatedParams.push(`comment=${values.comment}`)

  return updatedParams
}

export {
  getPlacementsFromTree,
  isTransactionIdValid,
  _locationLabelTemplateDesktop,
  _locationLabelTemplateMobile,
  _messageTypeTemplate,
  _deviceNameTemplate,
  getDeviceId,
  durationFieldCondition,
  getImeiByDevice,
  isSpecialModeFieldsValid,
  validateNumericFields,
  getRoomsListFromTree,
  isAllValuesFilledAndPositive,
  prepareMetaInfoRequestBody,
  getOrganizationIdLabel,
  isRequiredFieldsFilled,
  pushParams,
}
